import { useDispatch, useSelector } from 'react-redux'
import { ImHome } from 'react-icons/im'
import { MdBusinessCenter, MdOutlineAddShoppingCart } from 'react-icons/md'
import { IoIosListBox } from 'react-icons/io'
import { AiOutlineDatabase } from 'react-icons/ai'
import { FaBoxOpen, FaTable, FaEnvelopeOpenText, FaUsers, FaCog, FaClipboardCheck, FaVoteYea, FaBoxes, FaFolderPlus } from 'react-icons/fa'
import Link from 'elements/sidebar/Link'
import LinkWithSubmenu from 'elements/sidebar/LinkWithSubmenu'

function Links() {

    const userRoles = useSelector(state => state.user.user.roles)

    const auth = {
        procurement: userRoles.find(roles => roles.code === 'procurements') !== undefined ? true : false,
        dep_procurement: userRoles.find(roles => roles.code === 'department_procurement_requests') !== undefined ? true : false,
        product_demand: userRoles.find(roles => roles.code === 'product_demands') !== undefined ? true : false,
        warehouse_product_demand: userRoles.find(roles => roles.code === 'warehouse_product_demands') !== undefined ? true : false,
        warehouse_stock: userRoles.find(roles => roles.code === 'warehouse_stocks') !== undefined ? true : false,
        warehouse_procurement_request: userRoles.find(roles => roles.code === 'warehouse_procurement_request') !== undefined ? true : false,
        supplier: userRoles.find(roles => roles.code === 'suppliers') !== undefined ? true : false,
        product: userRoles.find(roles => roles.code === 'products') !== undefined ? true : false,
        report: userRoles.find(roles => roles.code === 'reports') !== undefined ? true : false,
        settings_user: userRoles.find(roles => roles.code === 'settings_users') !== undefined ? true : false,
        settings_procurement: userRoles.find(roles => roles.code === 'settings_procurements') !== undefined ? true : false,
        settings_department: userRoles.find(roles => roles.code === 'settings_departments') !== undefined ? true : false,
        settings_position: userRoles.find(roles => roles.code === 'settings_positions') !== undefined ? true : false,
        settings_warehouse: userRoles.find(roles => roles.code === 'settings_warehouses') !== undefined ? true : false,
        settings_supplier: userRoles.find(roles => roles.code === 'settings_suppliers') !== undefined ? true : false,
        settings_product: userRoles.find(roles => roles.code === 'settings_products') !== undefined ? true : false,
        settings_cost_type: userRoles.find(roles => roles.code === 'settings_cost_types') !== undefined ? true : false,
    }
    
    
    
    const productLinks = [
        {   
            id: 1,
            title: 'Ürünler/Hizmetler',
            path: '/company/products'
        },
        // {
        //     id: 2,
        //     title: 'Ayarlar',
        //     path: '/company/products/settings'
        // },
        {
            id: 3,
            title: 'Toplu İşlemler',
            path: '/company/products/uploads'
        },
    ]
    const supplierLinks = [
        {   
            id: 1,
            title: 'Tedarikçiler',
            path: '/company/suppliers'
        },
        {
            id: 2,
            title: 'Davetiyeler',
            path: '/company/suppliers/invitations'
        },
        {
            id: 3,
            title: 'Toplu İşlemler',
            path: '/company/suppliers/uploads'
        },
    ]
    const reportsLinks = [
        {   
            id: 1,
            title: 'Tedarikçi Raporu',
            path: '/company/reports/suppliers'
        },
        {   
            id: 2,
            title: 'Sektör Raporu',
            path: '/company/reports/business_areas'
        },
        {
            id: 3,
            title: 'Ürün Raporu',
            path: '/company/reports/products'
        },
        {
            id: 4,
            title: 'Ürün Türü Raporu',
            path: '/company/reports/product_types'
        },
    ]
    // const productDemandLinks = [
    //     {   
    //         id: 1,
    //         title: 'Talepler',
    //         path: '/company/product_demands'
    //     },
        // {   
        //     id: 2,
        //     title: 'Onaylar',
        //     path: '/company/product_demand_approvals'
        // },
    // ]
    // const departmentProcurementRequestLinks = [
    //     {   
    //         id: 1,
    //         title: 'Talepler',
    //         path: '/company/departments/procurement_requests'
    //     },
        // {   
        //     id: 2,
        //     title: 'Onaylar',
        //     path: '/company/product_demand_approvals'
        // },
    // ]

    let demandLinks = []
    if(auth.product_demand) {
        demandLinks.push({   
            id: 1,
            title: 'Ürün Talepleri',
            path: '/company/product_demands'
        })
    }
    if(auth.dep_procurement) {
        demandLinks.push({   
            id: 2,
            title: 'Satın Alma Talepleri',
            path: '/company/departments/procurement_requests'
        })
    }
    // let warehousesLinks = [
    //     {   
    //         id: 1,
    //         title: 'Talepler',
    //         path: '/company/warehouses/demands'
    //     },
    //     {   
    //         id: 2,
    //         title: 'Stok İşlemleri',
    //         path: '/company/warehouses/stocks'
    //     },
    //     {   
    //         id: 3,
    //         title: 'Transfer Onayları',
    //         path: '/company/warehouses/transfer_approvals'
    //     },
    //     {   
    //         id: 4,
    //         title: 'Satın Alma Talepleri',
    //         path: '/company/warehouses/procurement_requests'
    //     },
    // ]
    let warehousesLinks = []

    if(auth.warehouse_product_demand) {
        warehousesLinks.push({   
            id: 1,
            title: 'Talepler',
            path: '/company/warehouses/demands'
        })
    }
    if(auth.warehouse_stock) {
        warehousesLinks.push({   
            id: 2,
            title: 'Stok İşlemleri',
            path: '/company/warehouses/stocks'
        })
        warehousesLinks.push({   
            id: 3,
            title: 'Transfer Onayları',
            path: '/company/warehouses/transfer_approvals'
        })
    }
    if(auth.warehouse_procurement_request) {
        warehousesLinks.push({   
            id: 4,
            title: 'Satın Alma Talepleri',
            path: '/company/warehouses/procurement_requests'
        })
    }
    

    const procurementLinks = [
        {   
            id: 1,
            title: 'Teklif Yönetimi',
            path: '/company/procurements'
        },
        // {   
        //     id: 2,
        //     title: 'Onaylar',
        //     path: '/company/procurement_approvals'
        // },
        {   
            id: 3,
            title: 'Depo Talepleri',
            path: '/company/procurements/warehouse_requests'
        },
        {   
            id: 4,
            title: 'Departman Talepleri',
            path: '/company/procurements/department_requests'
        },
    ]
    // const companySettingLinks = [
    //     {   
    //         id: 1,
    //         title: 'Kullanıcılar',
    //         path: '/company/settings/users'
    //     },
    //     {   
    //         id: 2,
    //         title: 'Departmanlar',
    //         path: '/company/settings/departments'
    //     },
    //     {   
    //         id: 3,
    //         title: 'Pozisyonlar',
    //         path: '/company/settings/positions'
    //     },
    //     {   
    //         id: 4,
    //         title: 'Depolar',
    //         path: '/company/settings/warehouses'
    //     },
    //     {   
    //         id: 5,
    //         title: 'Tedarikçiler',
    //         path: '/company/suppliers/settings'
    //     },
    //     {   
    //         id: 6,
    //         title: 'Ürün ve Hizmetler',
    //         path: '/company/products/settings'
    //     },
    //     {   
    //         id: 7,
    //         title: 'Gider Türleri',
    //         path: '/company/settings/cost_types'
    //     },
    // ]

    let companySettingLinks = []

    if(auth.settings_user) {
        companySettingLinks.push({   
            id: 1,
            title: 'Kullanıcılar',
            path: '/company/settings/users'
        })
    }
    if(auth.settings_procurement) {
        companySettingLinks.push({   
            id: 8,
            title: 'Satın Alma',
            path: '/company/settings/procurements'
        })
    }
    if(auth.settings_department) {
        companySettingLinks.push({   
            id: 2,
            title: 'Departmanlar',
            path: '/company/settings/departments'
        })
    }
    if(auth.settings_position) {
        companySettingLinks.push({   
            id: 3,
            title: 'Pozisyonlar',
            path: '/company/settings/positions'
        })
    }
    if(auth.settings_warehouse) {
        companySettingLinks.push({   
            id: 4,
            title: 'Depolar',
            path: '/company/settings/warehouses'
        })
    }
    if(auth.settings_supplier) {
        companySettingLinks.push({   
            id: 5,
            title: 'Tedarikçiler',
            path: '/company/suppliers/settings'
        })
    }
    if(auth.settings_product) {
        companySettingLinks.push({   
            id: 6,
            title: 'Ürün ve Hizmetler',
            path: '/company/products/settings'
        })
    }
    if(auth.settings_cost_type) {
        companySettingLinks.push({   
            id: 7,
            title: 'Gider Türleri',
            path: '/company/settings/cost_types'
        })
    }

    const approvalLinks = [
        {   
            id: 1,
            title: 'Satın Alma',
            path: '/company/procurement_approvals'
        },
        {   
            id: 2,
            title: 'Depo Talepleri',
            path: '/company/procurement_request_approvals/warehouses'
        },
        {   
            id: 3,
            title: 'Departman Talepleri',
            path: '/company/procurement_request_approvals/departments'
        },
        {   
            id: 4,
            title: 'Ürün Talebi',
            path: '/company/product_demand_approvals'
        },
    ]
    return (
        <ul className="block overflow-y-auto flex-1 pb-3 mt-3">
            <Link title="Ana Sayfa" icon={<ImHome />} path="/" />
            {demandLinks.length > 0 ? 
            <LinkWithSubmenu title="Talep İşlemleri" icon={<FaFolderPlus />} items={demandLinks} />           
            : null
            }
            {warehousesLinks.length > 0 ? 
            <LinkWithSubmenu title="Depo İşlemleri" icon={<FaBoxes />} items={warehousesLinks} />          
            : null
            }
            {auth.procurement ? 
            <LinkWithSubmenu title="Satın Alma" icon={<FaEnvelopeOpenText />} items={procurementLinks} />            
            : null
            }
            {/* {auth.dep_procurement ? 
            <LinkWithSubmenu title="Satın Alma Talebi" icon={<FaFolderPlus />} items={departmentProcurementRequestLinks} />           
            : null
            }
            {auth.product_demand ? 
            <LinkWithSubmenu title="Ürün Talebi" icon={<FaVoteYea />} items={productDemandLinks} />           
            : null
            } */}
            
            {auth.supplier ? 
            <LinkWithSubmenu title="Tedarikçiler" icon={<FaUsers />} items={supplierLinks} />          
            : null
            }        
            {auth.product ? 
            <LinkWithSubmenu title="Ürün/Hizmet" icon={<FaBoxOpen />} items={productLinks} />         
            : null
            }        
            {auth.report ? 
            <LinkWithSubmenu title="Raporlar" icon={<FaTable />} items={reportsLinks} />        
            : null
            }        
            <LinkWithSubmenu title="Onay İşlemleri" icon={<FaClipboardCheck />} items={approvalLinks} />
            {companySettingLinks.length > 0 ? 
            <LinkWithSubmenu title="Sistem Ayarları" icon={<FaCog />} items={companySettingLinks} />        
            : null
            }
            
        </ul>
    )
}

export default Links