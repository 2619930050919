import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenDepartmentProcurementScenario } from 'stores/DrawersSlice' 
import EditDepartmentProcurementRequestsForm from 'modules/companyModule/pages/companies/settings/departments/EditDepartmentProcurementRequestsForm'

function EditDepartmentProcurementRequests({update, setUpdate, department, users}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenDepartmentProcurementScenario)
    const setIsOpenCreate = () => dispatch(setIsOpenDepartmentProcurementScenario())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Satın Alma Talebi Onay Sürecini Düzenle" maxWidth='max-w-2xl'>
            <EditDepartmentProcurementRequestsForm 
                update={update} 
                setUpdate={setUpdate} 
                department={department}
                users={users}
            />
        </FormDrawer> 
    )
}

export default EditDepartmentProcurementRequests