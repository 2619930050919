import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Select } from 'elements/inputs'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'

function Warehouses() {
    const user = useSelector(state => state.user.user)
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [warehouses, setWarehouses] = useState(null)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getWarehouses = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/users/${user.id}/warehouses`)
                .then(response => {
                    if(response.status === 200) {
                        setWarehouses(response.data.data)
                        const warehouseId = localStorage.getItem('warehouseId')
                        if(response.data.data.length > 1 && warehouseId) {
                            let filteredWarehouse = response.data.data.filter(wh => {
                                return wh.id.toString() == warehouseId.toString()
                            })
                            
                            if(filteredWarehouse.length) {
                                setSelectedWarehouse(filteredWarehouse[0])
                            }
                        }
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getWarehouses()
    }, [])

    const WarehouseSelect = () => {
        return (
        <div className='min-w-[15rem] flex items-center justify-end gap-x-2'>
            <span className={['text-gray-700 text-sm whitespace-nowrap'].join(' ')}>Seçilen Depo:</span>
            <select 
                value={selectedWarehouse != null ? selectedWarehouse.id.toString() : '0'}
                name='warehouses'
                className={['form-input w-full rounded-sm px-1 py-[0.3rem] text-xs border border-gray-400'].join(' ')} 
                onChange={(e) => handleWarehouseChange(e)}
            >
                <option key="0" value="0">Seçiniz</option>
                {(warehouses !== null && warehouses !== undefined) && warehouses.map(data => {
                    return <option key={data.id} value={data.id}>{data.warehouse_name}</option>
                })}
            </select>
        </div>
        )
    }

    const handleWarehouseChange = (e) => {
        let selectedId = e.target.value
        if(selectedId == '0') {
            setSelectedWarehouse(null)
        } else {
            let filteredWarehouse = warehouses.filter(w => w.id.toString() == selectedId.toString())
            if(filteredWarehouse.length > 0) {
                setSelectedWarehouse(filteredWarehouse[0])
                localStorage.setItem('warehouseId', selectedId.toString())
            } else {
                setSelectedWarehouse(null)
            }
        }
    }

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex flex-col lg:flex-row justify-between items-center gap-y-2 lg:gap-y-0">
                <h2 className="text-2xl text-gray-800 font-semibold">Depo İşlemleri</h2>
                <WarehouseSelect />
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                {(selectedWarehouse !== null && Object.keys(selectedWarehouse).length > 0) 
                    ? 
                        <Outlet context={[selectedWarehouse, setSelectedWarehouse]} />
                    : 
                        <div>
                            <p className='text-lg font-semibold text-red-600'>Devam etmek için işlem yapmak istediğiniz depoyu yukarıda yer alan listeden seçiniz.</p>
                        </div>
                }
            </div>            
            {loading && <Loader />}
        </div>
    )
}

export default Warehouses