import { useState, useEffect } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' 
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice'
import { ButtonFilter, ButtonNew, ButtonExcel, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import Create from 'modules/companyModule/pages/procurementProcesses/procurements/create'
import Detail from 'modules/companyModule/pages/procurementProcesses/procurements/Detail'
import UpdateStatus from 'modules/companyModule/pages/procurementProcesses/procurements/UpdateStatus'
import UpdateEnddate from 'modules/companyModule/pages/procurementProcesses/procurements/UpdateEnddate'
import Issue from 'modules/companyModule/pages/procurementProcesses/procurements/Issue'
import Cancel from 'modules/companyModule/pages/procurementProcesses/procurements/Cancel'
import Table from 'modules/companyModule/pages/procurementProcesses/procurements/Table'
import TableFilter from 'modules/companyModule/pages/procurementProcesses/procurements/TableFilter'
import TopCards from 'modules/companyModule/pages/procurementProcesses/procurements/TopCards'

function Procurements() {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [usersForApproval, setUsersForApproval] = useState([])
    const [procurements, setProcurements] = useState([])
    const [procurementForStatusUpdate, setProcurementForStatusUpdate] = useState(null)
    const [procurementForEnddateUpdate, setProcurementForEnddateUpdate] = useState(null)
    const [procurementForIssue, setProcurementForIssue] = useState(null)
    const [procurementForCancel, setProcurementForCancel] = useState(null)
    const [procurementForDetail, setProcurementForDetail] = useState(null)
    const [procurementForCopy, setProcurementForCopy] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)

    const isOpenDetail = useSelector(state => state.drawers.isOpenProcurementDetail)

    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null, procurement_status: null})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation();

    useEffect(() => {
        const getUsers = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/users?no_pagination=true&is_active=true&role=procurements`)
                .then(response => {
                    if(response.status === 200) {
                        setUsersForApproval(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getUsers()
    }, [])

    useEffect(() => {
        const getProcurements = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/procurements?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setProcurements(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProcurements()
    }, [updateData, filters, search])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Teklif Yönetimi</h2>
                <ButtonNew label="Yeni Teklif Toplama" onClick={() => {setProcurementForCopy(null); dispatch(setIsOpenProcurementCreate(true))}} >
                </ButtonNew>
            </div>
            <div className="w-full mb-4">
                <TopCards updateData={updateData} />
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                />

                <Table data={procurements} 
                        actions={{
                            setProcurementForDetail: setProcurementForDetail,
                            setProcurementForStatusUpdate: setProcurementForStatusUpdate,
                            setProcurementForEnddateUpdate: setProcurementForEnddateUpdate,
                            setProcurementForIssue: setProcurementForIssue,
                            setProcurementForCancel: setProcurementForCancel,
                            setProcurementForCopy: setProcurementForCopy,
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            <Create update={updateData} setUpdate={setUpdateData} users={usersForApproval} procurement={procurementForCopy} />
            <UpdateStatus update={updateData} setUpdate={setUpdateData} procurement={procurementForStatusUpdate} />
            <UpdateEnddate update={updateData} setUpdate={setUpdateData} procurement={procurementForEnddateUpdate} />
            <Issue update={updateData} setUpdate={setUpdateData} procurement={procurementForIssue} />
            <Cancel update={updateData} setUpdate={setUpdateData} procurement={procurementForCancel} />
            <Detail procurement={procurementForDetail} />
            {loading && <Loader />}
        </div>
    )
}

export default Procurements