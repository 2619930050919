import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import {ButtonSave, ButtonClose, ButtonDeleteSmall, ButtonNewSmall, ButtonOk, ButtonEditSmall} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, Checkbox} from 'elements/inputs'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import Loader from 'elements/Loader'

const EditProcurementScenarioForm = ({setEditedScenarioId, setUpdateScenarios, scenario, users, department}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)

    const formDefaults = {scenario_name: null}
    const userDefaults = {user_id: null, approval_sequence: "1"}

    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue } = useForm({
        defaultValues: {formDefaults, users: [userDefaults]}
    })

    const { 
        fields: fields, 
        append: append, 
        remove: remove, 
        } = useFieldArray({
        control,
        name: "users"
    })

    const watchUsers = watch("users");
    const controlledUserFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchUsers[index]
        }
    })

    const userInputs = [
        {
            key: "procurementApprovalInput1",
            type: "select",
            nameLast: "user_id",
            placeholder: "Kullanıcı",
            label: null,
            validator: {},
            data: users,
            cols: 'col-span-7', 
        },
        {
            key: "procurementApprovalInput2",
            type: "text",
            nameLast: "approval_sequence",
            placeholder: "Miktar",
            label: null,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^\b([0-9]|[1-9][0-9])\b$/,
                            message: "İki haneli sayı olmalı"},
                validate: {
                    positive: v => parseInt(v) > 0 || 'Sıfırdan büyük olmalı',
                    lessThan99: v => parseInt(v) < 100 || '99 ve daha az olmalı',
                }
            },
            cols: 'col-span-2', 
        },
    ]
    
    const scenarioInputs = [
        {
            key: "procurementScenarioInput1",
            type: "text",
            name: "scenario_name",
            placeholder: "Senaryo Adı *",
            label: "Senaryo Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.scenario_name?.message,
            cols: 'col-span-12', 
        },
    ]

    
    useEffect(() => {
        if(scenario != null && Object.keys(scenario).length > 0) {
            let userItems = scenario.approval_users.map(user => {
                return {user_id: user.user.id, approval_sequence: user.approval_sequence}
            })
            reset({scenario_name: scenario.scenario_name, users: userItems})
        } else {
            reset({formDefaults, users: [userDefaults]})
        }
          
    }, [department, scenario])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/departments/${department.id}/department_procurement_request_approval_scenarios/${scenario.id}`, data)
                .then(res => {
                    if(res.status === 201) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setUpdateScenarios(prev => !prev)
                        setEditedScenarioId(0)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err)
                    setLoading(false)
                })
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        setEditedScenarioId(0)
    }

    return (
        <>
        
        <div className="mb-4 border border-gray-400 rounded-md p-1">
            <div className="text-red-500 mt-4">
                {error && <ServerError errorData={error} />}
            </div>
            {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
            <form onSubmit={handleSubmit(handleForm, handleError)}>
                <div className="grid grid-cols-12 gap-2">
                {scenarioInputs.map((input) => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <InputSmall 
                                type={input.type}
                                name={input.name}
                                register={register}
                                label={input.label}
                                placeholder={input.placeholder}
                                validator={input.validator}
                                error={input.error}
                            />
                        </div>
                        )
                })}
                </div>
                <div>
                    <div className='lg:grid lg:grid-cols-12 gap-2 pb-1 border-b border-gray-700 mt-4'>
                        <div className='col-span-1'></div>
                        <div className='col-span-7'>Onaylayacak Kullanıcı</div>
                        <div className='col-span-2'>Onay Sırası</div>
                        <div className='col-span-2'></div>
                    </div>
                </div>
                <div>
                {controlledUserFields.map((field, index) => {
                        return (
                            <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${index}`}>
                                <div className='col-span-1 mt-2'>
                                    <div>
                                        {index+1}.
                                    </div>
                                </div>
                                <div className={userInputs[0].cols}>
                                    <SelectSmall 
                                        {...userInputs[0]}
                                        register={register} 
                                        name={`users.${index}.${userInputs[0].nameLast}`} 
                                        error={errors?.users?.[index]?.[userInputs[0].nameLast]?.message} 
                                    />                                
                                </div>
                                <div className={userInputs[1].cols}>
                                    <InputSmall 
                                        {...userInputs[1]}
                                        register={register} 
                                        name={`users.${index}.${userInputs[1].nameLast}`} 
                                        error={errors?.users?.[index]?.[userInputs[1].nameLast]?.message} 
                                    />                              
                                </div>
                                <div className='col-span-2 mt-0.5'>
                                    <div>
                                        {
                                        index === 0 
                                        ? <ButtonNewSmall label="Ekle" addClass='w-full' type="button" onClick={() => append(userDefaults)} ></ButtonNewSmall>
                                        : <ButtonDeleteSmall addClass='w-full' type="button" onClick={() => remove(index)} ></ButtonDeleteSmall>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonSave type="submit">
                    </ButtonSave>
                    <ButtonClose onClick={() => close()}>
                    </ButtonClose>
                </div>                
            </form>  
        </div>
         
        {loading && <Loader />}
        </>
    )
}

export default EditProcurementScenarioForm