import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenCompanyUserCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/companies/settings/users/CreateForm'

function Create({update, setUpdate, roles, departments, positions}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenCompanyUserCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenCompanyUserCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Kullanıcı Ekle" maxWidth='max-w-3xl'>
            <CreateForm update={update} setUpdate={setUpdate} roles={roles} departments={departments} positions={positions} />
        </FormDrawer> 
    )
}

export default Create