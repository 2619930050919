import { utils, writeFileXLSX } from 'xlsx'
import FindCategory from 'utils/FindCategory'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

function ExportXslx(data, categories, fileName = 'ekstre') {

    const categoryCell = (selectedCategories) => {
        if(categories.length > 0 && selectedCategories !== null) {
            let catJson = new Map(Object.entries(selectedCategories))
            if(catJson.size > 0) {
                return [...catJson].map(cat => {     
                    if(cat[1]) {
                        let foundCategory = FindCategory(categories, cat[0])
                        if(foundCategory) {
                            let optionSelected = foundCategory.category_items.find(item => {
                                return item.id == cat[1]
                            })
                            return (`${foundCategory.category_name}: ${optionSelected?.item_name}`)
                        }                       
                    }                          
                }).join(', ')
            } else {
                return ''
            }
        } else {
            return ''
        }
        
    }

    const array = data.map(item => {
        return {
            name: item.company_name,
            business_area: item.business_area.name,
            taxNo: item.tax_number,
            taxDepartment: item.tax_department,
            phone: item.phone,
            email: item.email,
            address: item.address,
            area: item.district.name,
            city: item.city.name,
            categories: categoryCell(item.categories.categories),
            total_proposals: item.total_proposals,
            accepted_proposals: item.accepted_proposals,
            proposal_price: item.proposals.map(i => FNWS(i.price) + ' ' + i.currency.code).join(' + ')
        }
    })

    const header = [[
        "Şirket Adı", 
        "Sektör", 
        "Vergi No", 
        "Vergi Dairesi", 
        "Telefon", 
        "E-Posta", 
        "Adres", 
        "İlçe", 
        "İl", 
        "Kategoriler",
        "Verilen Teklif",
        "Kabul Edilen Teklif",
        "Kabul Edilen Teklif Tutarı"
    ]]

    const exportXsl = () => {
        const ws = utils.json_to_sheet(array)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, fileName+".xlsx")
    }

    exportXsl()
}

export default ExportXslx