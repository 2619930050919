

function ServiceAgreement() {
    return (
        <div className="w-full p-1 text-xs text-gray-800">
            <p className="mb-2">1. GİRİŞ</p>
            <p className="mb-2">1.1. FAVERA Yazılım ve Bilişim Teknolojileri Limited Şirketi (“MARLAPORT”), alıcı şirket (“Alıcı”) ile tedarikçiler (“Tedarikçi”) arasında, işletmeler arası web tabanlı ve güvenli satın alma faaliyetlerini ve iletişimlerini içeren e-satınalma ve e-ihale platformunu (“Platform”) hizmete sunmaktadır.</p>
            <p className="mb-2">1.2. Platform, Tedarikçiler’in, mal ve hizmet tedariki hakkında fiyat teklifi talebinde bulunmasına bağlı olarak Fiyat Talebi (“RFQ”) ve çeşitli e-ihale (“E-İhale”) etkinliklerini içermektedir. Alıcı, Tedarikçi’nin söz konusu etkinliklere katılımı için güvenli platform üzerinden istek göndermektedir. Tedarikçi sistem üzerinden gelen istek üzerine teklif (“Teklif”) göndererek çevrimiçi satınalma ve ihale sürecine (“Süreç”) katılım sağlayabilmektedir.</p>
            <p className="mb-2">Alıcı ve Tedarikçi, Platform’a kayıt suretiyle işbu Kullanıcı Sözleşmesi ("Sözleşme") ile bağlı olduğunu kabul eder. İşbu Sözleşme kapsamında MARLAPORT 'a bilgi veren bütün çalışanların, Alıcı ve Tedarikçi'yi temsil etmek amacıyla yetkiye sahip olması gerekmektedir.</p>
            <p className="mb-2">2. HİZMET KULLANIMI</p>
            <p className="mb-2">2.1. İki adet hizmet modeli vardır. Modeller aşağıda belirtildiği gibi birbirinden ayrılır:</p>
            <p className="mb-2">(a) Hizmet Kredisi: Alıcı, MARLAPORT’un kredi modeliyle sunacağı hizmetleri ön ödemeli olarak kullanacaktır.</p>
            <p className="mb-2">(b) Üyelik Paketi: Alıcı, kullanım sıklığı ve diğer gereksinimleri doğrultusunda aylık ve yıllık olarak sunulan üyelik paketlerinden seçecektir. Üyelik paketi modelinde Alıcı, kendi isteğine bağlı olarak üyelik paketini yükseltebilecek veya düşürebilecektir.</p>
            <p className="mb-2">2.2. Her iki hizmet modelinde de karar ve politikaları belirleyen Alıcı’dır. Alıcı kullanıcılar, Süreç detaylarına ve Süreçler’in yönetimine kendileri karar verir. MARLAPORT, Platform sağlayıcı olarak hareket eder ve iletişimlerden, sözleşmelerden ve Alıcı ve Tedarikçi arasındaki işten sorumlu değildir.</p>
            <p className="mb-2">2.3. Her iki hizmet modeli ayrı olarak ya da birlikte kullanılabilir. Süreçler’in devamlılığı MARLAPORT 'un sorumluluğunun dışındadır. Süreç’i oluşturan Alıcı, Süreç’ten sorumludur. Süreçler’den kaynaklanan üçüncü tarafın tüm iddiaları için MARLAPORT, Alıcı tarafından tazmin edilecektir.</p>
            <p className="mb-2">2.4. Alıcı, MARLAPORT’un kurumsal web sitesindeki (https://www.marlaport.com) kayıt formunu doldurarak Platform'a kayıt olur. Alıcı, kayıt formunda talep edilen bilgileri tam ve doğru olarak sağlayacağını ve bu bilgileri güncel tutacağını taahhüt eder.</p>
            <p className="mb-2">2.5. Tedarikçiler, Alıcı tarafından Platform'da tayin edilen mallar ve hizmetler için Teklif sunar. Alıcı, Süreç’in hüküm ve şartlarını belirler ve Platform'a iştirak eden Tedarikçiler bu hüküm ve şartları üstlendiklerini kabul eder.</p>
            <p className="mb-2">2.6. Alıcı, E-İhaleyi yeniden açma, sona erdirme ya da iptal etme hakkını saklı tutar. Eğer herhangi bir Tedarikçi Teklif’ini geri çekmek isterse veya Süreç’ten geri çekilmek isterse Tedarikçi Alıcı ile iletişime geçmelidir. Alıcı, Teklif’i iptal etme ya da Tedarikçi’yi Süreç’ten çıkarma hakkına sahiptir.</p>
            <p className="mb-2">2.7. Alıcılar'ın ve Tedarikçiler'in kullanıcıları kişisel kullanıcı adı ve parolalarını güvenli ve gizli tutacaklarını taahhüt ederler. Bir kuruluşun bünyesinde veya dışında oturum açma detaylarının paylaşımı kesin suretle yasaklanmıştır ve bu tüm taraflar için önemli risklere yol açar. Oturum açma bilgisinin gizliliğinin ihlalinden doğan bütün zararlar bu oturum açma bilgisinden sorumlu taraf tarafından tazmin edilir.</p>
            <p className="mb-2">2.8. MARLAPORT, Tedarikçiler nezdinde mal ve hizmetlerin tedarikinden sorumlu değildir. MARLAPORT, Alıcı ve Tedarikçiler’in arasındaki herhangi bir ticari şartın ya da sözleşmenin tarafı değildir. Ek olarak, Alıcı, Tedarikçiler’in seçimini, onaylanmasını ve denetlemesini üstlenir.</p>
            <p className="mb-2">2.9. Alıcı, Teklif’leri fiyata, performansa ve kalite kriterlerine göre değerlendirecektir. Alıcı en düşük Teklif’i veren Tedarikçi’yle anlaşmak zorunda değildir.</p>
            <p className="mb-2">2.10. MARLAPORT muhtelif zamanlarda ihbarsız ve kendi takdirinde işbu Sözleşme’yi güncelleme ve değiştirme hakkını saklı tutar. Alıcı ve Tedarikçiler değişiklikler için MARLAPORT 'un kurumsal web sitesi olan (https://www.marlaport.com) kontrol etmek zorundadırlar.</p>
            <p className="mb-2">2.11. MARLAPORT Platform'a kesintisiz erişim için çalışmaktadır. Ancak internetin doğası gereği her an Platform'a erişim askıya alınabilir, sınırlandırılabilir ya da sona erdirilebilir. Alıcı ya da Tedarikçi kullanıcılarının MARLAPORT vasıtasıyla gönderdiği iletimlere ilişkin MARLAPORT’un herhangi bir sorumluluğu olmayacaktır ve kullanıcılar bu iletimleri, riski tamamen kendi üzerlerinde olacak şekilde yaparlar.</p>
            <p className="mb-2">2.12. Süreç zamanında anlaşmazlık olduğu herhangi bir zamanda, MARLAPORT sunucu zamanı referans olacaktır. Tedarikçi bunun bilincinde olmalıdır ve MARLAPORT 'un sunucu zamanına göre teklifte bulunmalıdır.</p>
            <p className="mb-2">2.13. Alıcı, MARLAPORT’a Alıcı’nın faaliyetlerinden elde edilen verileri istatistik ve pazarlama amacıyla kullanabilmesi için onay verir. Gizli bilgiler MARLAPORT tarafından yayınlanamayacaktır.</p>
            <p className="mb-2">3. ÜCRETSİZ SÜRÜM</p>
            <p className="mb-2">3.1. Alıcı, Platform’un ücretsiz sürümü için MARLAPORT kurumsal web sitesi olan https://www.marlaport.com adresinden kayıt olabilir. Bir ya da daha fazla MARLAPORT hizmeti ücretsiz sürüm kapsamında Alıcı için kullanılabilir olacaktır. Alıcı, ücretsiz sürüm kullanımı süresince Platform üzerinden dilediği üyelik paketi alımını gerçekleştirilebilir.</p>
            <p className="mb-2">3.2. Ücretsiz sürüm kullanımında Alıcı tarafından girilen veriler Platform'dan silinmeyecektir. Eğer Alıcı üyelik alımına karar verirse, ücretsiz sürüm kullanımında girilen veriler erişilebilir olacaktır.</p>
            <p className="mb-2">4. DESTEK</p>
            <p className="mb-2">4.1. Destek, MARLAPORT destek ekibi tarafından e-posta ve telefon üzerinden verilecektir.</p>
            <p className="mb-2">4.2. Alıcı ve Tedarikçi kullanıcıları her zaman MARLAPORT destek ekibi ile teknik altyapı ayarlamaları ve Platform'un kullanımı sırasında meydana gelen Platform sunucu sorunları hakkında iletişime geçebilirler ve arızaları bildirebilirler. MARLAPORT her türlü problem ve hata durumda hafta içi 09.00 – 18.00 (GMT+3) çalışma saatleri içinde yardım servisi vermektedir.</p>
            <p className="mb-2">4.3. Alıcı ve Tedarikçi kullanıcılarına kendi hizmet yöntemlerine ve kullanıcı rollerine göre kullanım kılavuzları sağlanacaktır.</p>
            <p className="mb-2">4.4. Talep edilen uyarlama ve değişiklikler ayrı ayrı değerlendirilecektir, sistemdeki önemli değişikliklere neden olan talepler ayrı şekilde fiyatlandırılabilecektir.</p>
            <p className="mb-2">4.5. MARLAPORT Platform'un önceden belirlenen güncelleme ve planlı bakım dönemlerini duyuracaktır.</p>
            <p className="mb-2">5. ÜCRETLER VE ÖDEMELER</p>
            <p className="mb-2">5.1. MARLAPORT Platform fiyatlandırması hizmet modeline göre belirlenir. Hizmet kredisi modeli, talep edilen kredi miktarına göre ücretlendirilecektir. Üyelik paketi modeli, talep edilen üyelik paketi seviyesine göre aylık veya yıllık olarak ücretlendirilecektir. Platform’a ilişkin hizmet ücretleri, ödeme koşulları, ücretlerin yürürlük tarihleri MARLAPORT kurumsal web sitesinin ilgili bölümlerinde ilan edilecektir.</p>
            <p className="mb-2">5.2. Hizmet ücreti, her hizmet modelinde Platform'un bakım ve barındırma hizmetini içerir.</p>
            <p className="mb-2">5.3. Üyelik paketi modelinde Alıcı, kendi isteğine bağlı olarak üyelik paketini yükseltebilecek veya düşürebilecektir. Buna ilişkin talepler, MARLAPORT tarafından aksi öngörülmedikçe ilgili üyelik döneminin sonunda gerçekleştirilecektir. Alıcı’nın üyelik süresi boyunca üyelik paketine ilişkin ücret ve ödeme koşullarında yapılacak değişiklikler, Alıcı’nın üyelik döneminin sona ermesine dek uygulanmayacak, yeni ücretler ve ödeme koşulları yeni üyelik döneminin başlamasıyla geçerli olacaktır. Üyelik dönemi boyunca Sözleşme’nin feshi de dahil olmak üzere üyeliğin herhangi bir nedenle sona ermesi halinde geri ödeme yapılmayacaktır.</p>
            <p className="mb-2">5.4. Alıcı tarafından üyelik dönemi bitiminden on dört (14) gün öncesine kadar aksi talep edilmediği sürece her üyelik döneminin bitiminde Alıcı’nın üyeliği otomatik olarak yenilenecektir.</p>
            <p className="mb-2">5.5. MARLAPORT, Alıcı tarafından iletilen iletişim adresine üyelik döneminin başlangıcında hizmet ücretlerine ilişkin faturayı iletecektir. Alıcı, faturadaki ilgili tutarı fatura tarihini takip eden 14 (on dört) gün içinde ödeyecektir. İlgili ücretlere ilişkin vergi ve harçların ödenmesinden Alıcı sorumludur.</p>
            <p className="mb-2">5.6. Ödemeler havale yöntemiyle veya Online Ödeme Sistemi ile banka kartı, kredi kartı veya benzer bir ödeme aracı ile yapılacaktır. Online Ödeme Sistemi ile ödeme seçeneği kullanılarak yapılan ödemelerde, kartın hamili haricinde bir başkası tarafından hukuka aykırı şekilde kullanılması halinde 23.02.2006 tarihli 5464 sayılı Banka Kartları ve Kredi Kartları Kanunu ve 10.03.2007 tarihli ve 26458 sayılı Resmi Gazete’de yayımlanan Banka Kartları ve Kredi Kartları Hakkında Yönetmelik hükümlerine göre işlem yapılır.</p>
            <p className="mb-2">5.7. Alıcı, MARLAPORT veya MARLAPORT tarafından onaylanmış üçüncü kişiler üyeliğe ve ödemeye ilişkin işlemler veya banka entegrasyonunu ve ilgili güncellemeleri gerçekleştirmek için Alıcı’nın kredi kartı ve ödeme bilgilerini saklayabilecektir.</p>
            <p className="mb-2">6. YÜKÜMLÜLÜKLER</p>
            <p className="mb-2">6.1. Taraflar işbu Sözleşme’de düzenlenen yükümlülükleri tamamıyla yerine getireceğini üstlenir ve kabul eder. Karşı taraf yükümlülüklerini yerine getirmeme ya da uygulamama durumunda tarafların Sözleşme’yi askıya alma ya da sona erdirme hakkı vardır.</p>
            <p className="mb-2">6.2. Alıcı’nın izin verdiği kendi iş alanındaki dahili kullanıcılar ("Dahili Kullanıcı") ve Tedarikçiler Platform'a giriş yapar ve kullanır. MARLAPORT dahili kullanıcı olmayan gerçek ya da tüzel kişiyi ya da Tedarikçi'yi Platform'dan derhal çıkartma ve Platform’u kullanmalarını yasaklama hakkına sahiptir. Alıcı kendi iş alanındaki kullanıcıların kullanıcı hesaplarını gizli ve özel tutmaktan sorumludur ve bu kullanıcı hesaplarını hiçbir üçüncü partiye vermeyeceğini ve yayınlamayacağını kabul eder.</p>
            <p className="mb-2">Alıcı’nın kullanıcı hesaplarının kullanıldığı süre boyunca verilen her ifade ve uygulanan her davranış ve ihmal için Alıcı sorumludur. MARLAPORT, Alıcı’nın kullanıcı hesaplarını gizli tutmamasından meydana gelen herhangi bir güvenlik ihlalinden sorumlu tutulamaz. Alıcı kullanıcı hesaplarının kaybolması veya çalınması ya da bir şekilde kullanıcı hesaplarının gizliliğinin ihlaline inanırsa ya da Platform’un kullanıldığını anlarsa ya da bunlar için yetkisiz bir şekilde kullanılması ihtimali olması durumunda, Alıcı derhal MARLAPORT’i bilgilendireceğini kabul eder. MARLAPORT, Alıcı’nın kullanıcı hesaplarını ön bildirim ile iptal etme hakkını saklı tutar.</p>
            <p className="mb-2">6.3. MARLAPORT tarafından yerine getirilen işbu Sözleşme’deki faaliyetler ve hizmetler için gereken tüm yetki ve izinleri Alıcı verir ve MARLAPORT’in bu amaca ilişkin bütün gerekli ve uygun kararları alacağını kabul eder. Alıcı, Platform’da kullanılmak üzere adını, logosunu, markalarını çoğaltma ve kullanma hakkını herhangi bir ücrete tabi olmadan MARLAPORT’e verir.</p>
            <p className="mb-2">6.4. Alıcı, Platform’a (i) üçüncü tarafların her türlü fikri mülkiyet hakkını ihlal edecek nitelikte (ii) iftira, hakaret ya da tehdit içeren ahlak ve örf ve adetlere karşı veya kanuna aykırı veya (iii) virüsler, “solucanlar”, “Trojan” veya diğer zarar verici özellikleri içeren herhangi bir program – veri yüklemeyeceğini kabul eder. Alıcı, Platform’u kanuna aykırı amaçlarla kullanmamayı veya bu tür amaçları fark edeceğini kabul eder. MARLAPORT, kendi takdirinde olarak bu hükümlere karşı olan Süreç’leri iptal etme, silme ve değiştirme hakkını saklı tutar.</p>
            <p className="mb-2">7. GİZLİLİK</p>
            <p className="mb-2">7.1. Taraflar, üçüncü taraflardan gizlenmesi gereken tüm bilgileri gizli tutacağını taahhüt eder. Tarafların bu yükümlülüğü, işbu Sözleşme’nin sona ermesinden itibaren 5 yıl boyunca devam eder. Hukuken yanlarında bulundurmaları gereken belgeler hariç olmak üzere, taraflar Sözleşme’nin sona ermesiyle beraber, talep üzerine kendilerine verilmiş belgeleri gecikmeksizin iade veya imha etmeyi taahhüt ederler. Ayrıca, taraflar işbu Sözleşme’nin konusuyla ilişkili olan çalışanlarının ve bağlı şirketlerinin de işbu Sözleşme kapsamındaki gizlilik yükümlülüğüne ve karşılıklı gizlilik hükmüne riayet etmelerini sağlar. Aksi halde, taraflar karşılıklı gizliliğe dair bu hükmü ihlal eden çalışanları/bağlı şirketleriyle beraber müştereken ve müteselsilen sorumlu olmayı kabul ederler.</p>
            <p className="mb-2">7.2. Gizlilik yükümlülüğü ve gizli bilgilerin kullanımı hükmü aşağıda belirtilen bilgileri kapsamaz:</p>
            <p className="mb-2">(i) Bilgiyi edinen tarafın ilgili bilgiyi karşı tarafın açıklamasından önce bildiğini ispatladığı bilgiler,</p>
            <p className="mb-2">(ii) Karşı tarafın hakları ihlal edilmeksizin, bilgiyi edinen tarafa üçüncü kişilerce sağlanan bilgiler,</p>
            <p className="mb-2">(iii) Bilgiyi edinen tarafın herhangi bir müdahalesi olmaksızın kamusal alana düşen bilgiler,</p>
            <p className="mb-2">(iv) Bilgiyi edinen tarafın yetkili hukuk gereği açıklamak zorunda olduğu bilgiler,</p>
            <p className="mb-2">(v) Menkul Kıymetler Hukuku kuralları kapsamında kamuya sunulan bilgiler ve gerekli veya tavsiye niteliğindeki ürün bilgileri.</p>
            <p className="mb-2">7.3. İşbu Sözleşme kapsamında taraflar arasındaki bilgi alışverişi, işbu Sözleşme’nin uygulanması için gerekli bilgi ile sınırlıdır. Taraflar, fiyat ve pazarlama politikaları, kar marjı veya kullanım kapasitesi gibi rekabet açısından hassas nitelikte olan bilgi alışverişinde bulunmamalıdırlar.</p>
            <p className="mb-2">8. MÜLKİYET HAKLARI</p>
            <p className="mb-2">8.1. Platform üzerindeki fikri mülkiyet haklarına ilişkin tüm haklar MARLAPORT’a aittir. Kullanıcıların bu hizmetin doğası gereği kullanmak zorunda oldukları haklar hariç, işbu Sözleşme fikri haklara dair herhangi bir hak veya lisans tanımaz.</p>
            <p className="mb-2">8.2. MARLAPORT, Platform ve MARLAPORT markaları üzerindeki tüm mülkiyet ve tasarruf haklarına sahiptir. İşbu Sözleşme’deki hiçbir hüküm, Alıcı’ya mülkiyet, bağlantılı hak veya yetki sağlamaz. Alıcı, sadece Platform’a münhasır olmayan ve sınırlı bir erişim hakkı elde eder. Bu erişim hakkı, alım işlemlerine dair kurum içi kullanım amacına dairdir ve bunları kullanım hakkını da ihtiva eder. Keza, bu hak, işbu Sözleşme süresince geçerlidir ve fakat başka kişilere temlik edilemez. Aksi kararlaştırılmadığı müddetçe, Alıcı, Platform ve MARLAPORT markalarını veyahut bunların bir kısmını kullanamaz, çoğaltamaz, taklit edemez, geliştiremez, teşhir amaçlı kullanamaz, dağıtamaz, yayınlayamaz, dönüştüremez, değiştiremez ve bunlardan işleme eserler oluşturamaz. Dahası, Alıcı, bunlara dair alt-lisans veremez, bunları iletemez, devredemez, ticari amaçlarla kullanamaz veya başka benzer eylemlerde bulunamaz. Alıcı, Platform’dan yetkili olarak aldığı tüm nüshaları, MARLAPORT’in telif hakları, ticari markası ve diğer mülkiyet haklarına dair olacak şekilde bandroller. Alıcı, bu bandrolleri silmemeli, ortadan kaldırmamalı, başka bandrollerle kaplamamalı veyahut bu bandrollerin üzerine herhangi bir işaret veya uyarı koymamalıdır. Alıcı, üçüncü tarafların, Platform’dan gelen malzemeleri çoğaltmasına, kullanmasına veya teşhir amaçlı kullanmasına izin vermemelidir.</p>
            <p className="mb-2">8.3. MARLAPORT, Alıcı ve Tedarikçi’nin gizlilik mühürleri altında, satınalmaya dair bilgi nüshalarını saklamalıdır. MARLAPORT, bu bilgileri Platform’un yüklenmesi ve çalışması ve de MARLAPORT’un hizmetlere dair yükümlülüklerinin ve sorumluluklarının yürütülmesi ve yerine getirilmesi amacıyla kullanabilir. Bu kullanıma çoğaltma, dağıtma, yayınlama ve işleme de dahildir.</p>
            <p className="mb-2">9. MÜCBİR SEBEP</p>
            <p className="mb-2">Mücbir sebepten kaynaklandığı ölçüde, tarafların edimlerini geç veya hiç ifa etmemesi durumu mazur görülür. İşbu Sözleşme kapsamında mücbir sebep, mücbir sebep iddiasında bulunan tarafça öngörülmesi mümkün olmayan, bu kişi tarafından sebep olunmayan veyahut bu kişinin kontrolünde gerçekleşmeyen durumlara denilir. Mücbir sebep kavramı kapsamına, MARLAPORT’un ve Alıcı’ların hareketleri dışında kalan ve MARLAPORT’u hizmet vermekten alıkoyan doğa olayları, yangın, sel, patlama, isyan, savaş, kasırga, terör saldırısı, vandalizm, kaza, hükümet sınırlandırmaları, hükümet tasarrufları, internet sistemleri arızaları, mahkeme kararları, grev; etkilenen tarafın – bu tarafın ilgili olayların, hareketlerin etkilerini engelleme, bu etkilerinden kaçınma, bu etkileri geciktirme veya azaltma çabalarına rağmen – makul tahmini ve kontrolünün ötesinde olan olaylar ve de meydana gelmeleri veyahut etkileri taraflardan birinin işbu Sözleşme kapsamındaki yükümlülüklerini yerine getirmemesinden ötürü taraflara atfedilemeyen olayları girer.</p>
            <p className="mb-2">10. SÖZLEŞMENİN FESHİ</p>
            <p className="mb-2">10.1.1. Alıcı, MARLAPORT’un bu hükümleri değiştirmesi halinde, işbu Sözleşme’yi feshedebilir. Bununla birlikte Alıcı, bu hakkını sadece hükümlerin esaslı ölçüde değiştirilmesi halinde kullanabilir. Esaslı olmayan değişiklikler haklı bir fesih sebebi oluşturmaz.</p>
            <p className="mb-2">10.1.2. MARLAPORT, Alıcı’nın herhangi bir bedeli vadesinden itibaren 14 gün içerisinde ödememesi durumunda, işbu Sözleşme’yi feshedebilir.</p>
            <p className="mb-2">10.1.3. MARLAPORT bu hükümlerin ihlali durumunda, herhangi bir uygulamayı derhal sona erdirebilir ve Alıcı kullanıcılarının Platform’u kullanmasını yasaklayabilir.</p>
            <p className="mb-2">10.1.4. MARLAPORT, Platform’a kayıtları veya erişimi reddetme ve geçici veyahut belirsiz bir süre için kayıtlı bir kullanıcının hesabını askıya almak hakkına sahiptir. Herhangi bir haklı sebep olmaksızın askıya alma durumunda, MARLAPORT, hesabın geri kalan kullanım süresine denk bir bedel ödemek zorundadır.</p>
            <p className="mb-2">10.1.5. MARLAPORT, Platform’u her an ve hiçbir bildirimde bulunmaksızın değiştirebilir, geçici olarak askıya alabilir veya sonlandırabilir.</p>
            <p className="mb-2">10.1.6. MARLAPORT kullanıcı adlarının ve/veya şifrelerinin tehlikeye girdiği kanısına varırsa, kullanıcı hesaplarını her an ve hiçbir bildirimde bulunmaksızın askıya alabilir.</p>
            <p className="mb-2">10.2. Alıcı, borçlu olduğu hizmet ücretlerini tümüyle ödediği müddetçe Sözleşme’yi fesih hakkını haizdir.</p>
            <p className="mb-2">10.3. Bu hükümlerin sona erdirilmesi halinde, Platform’a erişim sona erer ve diğer tarafa dair tüm bilgiler ya iade edilir veyahut tamamen imha edilir. Bilgilerin iadesi için gerekli hükümler ve zaman çizelgesi hazırlanacaktır.</p>
            <p className="mb-2">11. HUKUK VE YETKİ</p>
            <p className="mb-2">İşbu Sözleşme Türk kanunlarına tabi olacak ve Türk kanunlarına göre yorumlanacaktır. Ayrıca, taraflar, işbu Sözleşme’ye dair TR İstanbul Mahkemelerinin ve İcra Dairelerinin münhasıran yetkili olduğunu kabul etmektedirler.</p>
            <p className="mb-2">12. DİĞER</p>
            <p className="mb-2">12.1. Bu hükümlere yönelik değişiklikler, derhal yürürlüğe girer. Platform’un değişikliklerden sonra Alıcı ve Tedarikçiler tarafından kullanılması durumunda, bu, Alıcı ve Tedarikçiler’in yeni hükümlerle bağlı olmak istediği anlamına gelir.</p>
            <p className="mb-2">12.2. Alıcı, MARLAPORT’un yazılı onayı olmaksızın, hiçbir bir hakkını veya yükümlülüğünü devredemez.</p>
            <p className="mb-2">12.3. Alıcı, kayıt esnasında kendisi tarafından sunulan adreslerin kendisinin kalıcı adresleri olduğunu ve bu adreslerde meydana gelen değişiklikleri derhal MARLAPORT’a bildireceğini kabul eder. Aksi halde, sunulan adreslere yapılan bildirimler geçerli olacaktır.</p>
            <p className="mb-2">12.4. Tarafların işbu Sözleşme kapsamındaki haklarını, yetkilerini veyahut ayrıcalıklarını kullanmaması veya bunları geç kullanması, kendilerinin bunlardan feragat ettiği anlamına gelmez. Aynı şekilde, bu hak, yetki veyahut ayrıcalıkların kısmen kullanılması, kullanılmayan kısmın veya kullanılmayan diğer hak, yetki ve ayrıcalıkların artık kullanılamayacağı anlamını taşımaz. İşbu Sözleşme kapsamındaki hüküm ve şartlara dair hiçbir feragat, bu hüküm ve şartlardan sürekli feragat edildiği anlamını taşıyacak veya feragatin kapsamını genişletecek şekilde yorumlanamaz.</p>
            <p className="mb-2">12.5. İşbu Sözleşme, tarafların üzerinde anlaştığı her hususu kapsar. İşbu Sözleşme, taraflar arasında daha önce işbu Sözleşme’nin konusuna giren diğer tüm sözlü ve yazılı sözleşmelerin yerine geçer.</p>
            <p className="mb-2">12.6. İşbu Sözleşme’nin herhangi bir hükmünün geçersiz veya hükümsüz olması halinde, söz konusu hüküm etkisiz hale gelecek (geçersiz veya uygulanamaz olduğu ölçüde) ve işbu Sözleşme’de yer alan diğer hükümlerin geçerliliğini etkilemeksizin bu Sözleşme’ye dâhil edilmemiş sayılacaktır. Söz konusu geçersiz Sözleşme hükmü, kendisine anlamsal ve amaçsal açıdan en yakın bir hükümle değiştirilir.</p>
        </div>
    )
}

export default ServiceAgreement