import { useSelector } from 'react-redux'
import { ExpenditureTypes } from 'data'

export function useDeliveryDescriptionInput(errors) {
    return [
        {
            key: "descriptionInput1",
            type: "textarea",
            name: "description",
            placeholder: "Genel Açıklamalar",
            label: "Genel Açıklamalar",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },
    ]
}

export function useDeliveryLineInput(errors) {
    

    return [
        {
            key: "lineInput1",
            type: "hidden",
            nameLast: "product_demand_line_id",
            placeholder: "",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            cols: 'col-span-2', 
        },
        {
            key: "lineInput2",
            type: "text",
            nameLast: "quantity",
            placeholder: "Miktar",
            label: null,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) >= 0 || 'Sıfırdan büyük olmalı'
                }                 
            },
            cols: 'col-span-3', 
        },
        {
            key: "lineInput3",
            type: "text",
            nameLast: "description_text",
            placeholder: "Ürün Açıklaması",
            label: null,
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            cols: 'col-span-3', 
        },
        {
            key: "lineInput4",
            type: "select",
            nameLast: "unit_code_id",
            placeholder: "Birim",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: [],
            cols: 'col-span-1', 
        },
        
    ]
}

export function useProcurementInput(errors, expenditureTypes, scenarios) {
    const userDepartments = useSelector(state => state.user.user.departments)
    const departmentArray = userDepartments.map(item => {
        return {key: item.department.id, value: item.department.department_name}
    })
    const currencies = useSelector(state => state.sharedData.currencies)
    const expenditureTypesArray = expenditureTypes.map(item => {
        return {key: item.id, value: item.cost_name}
    })
    const scenariosArray = scenarios.map(item => {
        return {key: item.id, value: item.scenario_name}
    })
    
    return [
        {
            key: "requestInput1",
            type: "select",
            name: "department_id",
            placeholder: "İşlem Yapılacak Departman *",
            label: "İşlem Yapılacak Departman *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: departmentArray,
            error: errors?.department_id?.message,
            cols: 'col-span-12', 
        },  
        {
            key: "requestInput2",
            type: "select",
            name: "procurement_type_id",
            placeholder: "Satın Alma Türü *",
            label: "Satın Alma Türü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: scenariosArray,
            error: errors?.procurement_type_id?.message,
            cols: 'col-span-12', 
        },  
        {
            key: "requestInput3",
            type: "date",
            name: "requirement_date",
            placeholder: "İhtiyaç Tarihi *",
            label: "İhtiyaç Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
                            message: "Geçerli bir tarih girmelisiniz."}
            },
            error: errors?.requirement_date?.message,
            cols: 'col-span-4',  
        },
        {
            key: "requestInput4",
            type: "text",
            name: "expenditure",
            placeholder: "Harcama Tutarı *",
            label: "Harcama Tutarı *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) >= 0 || 'Sıfırdan büyük olmalı'
                }                 
            },
            error: errors?.expenditure?.message,
            cols: 'col-span-4', 
        },
        {
            key: "requestInput5",
            type: "select",
            name: "currency_id",
            placeholder: "Para Birimi",
            label: "Para Birimi *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.currency_id?.message,
            data: currencies,
            cols: 'col-span-4', 
        },      
        {
            key: "requestInput6",
            type: "select",
            name: "expenditure_type_id",
            placeholder: "Gider Türü *",
            label: "Gider Türü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data:expenditureTypesArray,
            error: errors?.expenditure_type_id?.message,
            cols: 'col-span-12', 
        },   
    ]
}

export function useDescriptionInput(errors) {
    return [
        {
            key: "descriptionInput1",
            type: "textarea",
            name: "description",
            placeholder: "Talep Gerekçesi *",
            label: "Talep Gerekçesi *",
            validator: {
                required: "Bu alan zorunludur.",
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },
    ]
}

export function useLineInput(errors, products) {
    const unitCodes = useSelector(state => state.sharedData.unitCodes)

    const productArray = products.map(item => {
        return {key: item.id, value: item.name}
    })

    return [
        {
            key: "lineInput1",
            type: "select",
            nameLast: "product_id",
            placeholder: "Ürün",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: productArray,
            cols: 'col-span-4', 
        },
        {
            key: "lineInput3",
            type: "text",
            nameLast: "description_text",
            placeholder: "Ürün Açıklaması",
            label: null,
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            cols: 'col-span-4', 
        },
        {
            key: "lineInput4",
            type: "text",
            nameLast: "quantity",
            placeholder: "Miktar",
            label: null,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) > 0 || 'Sıfırdan büyük olmalı'
                }
            },
            cols: 'col-span-2', 
        },
        {
            key: "lineInput5",
            type: "select",
            nameLast: "unit_code_id",
            placeholder: "Birim",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: [],
            cols: 'col-span-1', 
        },
    ]
}

export function useStatusInputs(errors) {
    const statusArrayForUpdate = [
        {key: 'completed', value: 'Teslim Alındı'}, 
        {key: 'archived', value: 'Arşivlendi'},
    ]
    
    return [
        {
            key: "statusInput1",
            type: "select",
            name: "status",
            placeholder: "Ürün Talep Durumu",
            label: "Ürün Talep Durumu *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.status?.message,
            data: statusArrayForUpdate,
            cols: 'col-span-12', 
        }, 
    ]
}

export function useTechnicalSpecInputs(errors, technicalSpecificationFileAttributes, setTechnicalSpecificationFileAttributes) {
    const statusArrayForUpdate = [
        {key: 'completed', value: 'Teslim Alındı'}, 
        {key: 'archived', value: 'Arşivlendi'},
    ]
    
    return [
        {
            key: "technicalSpecInput1",
            type: "file",
            name: "technical_specification_file",
            placeholder: "Dosya Seç",
            label: "Teknik Şartname Dosyası *",
            validator: {
                required: "Bu alan zorunludur.",
                validate: (value) => {
                    if(value[0].type !== "application/pdf") {
                        return 'PDF dosyası seçmelisiniz'
                    }
                    return true
                },
                onChange: (e) => {
                    let files = e.target.files
                    if(files.length > 0) {
                        setTechnicalSpecificationFileAttributes({
                            filename: files[0].name,
                            size: files[0].size,
                            type: files[0].type,
                        })
                    } else {
                        setTechnicalSpecificationFileAttributes({
                            filename: null,
                            size: null,
                            type: null,
                        })
                    }
                }
            },
            error: errors?.technical_specification_file?.message,
            notSelectedText: 'Dosya Seçilmedi',
            fileAttributes: technicalSpecificationFileAttributes,
            cols: 'col-span-6', 
        }, 
    ]
}

