import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import SendRequest from 'utils/SendRequest'
import {ButtonClose, ButtonSave, ButtonNext, ButtonPrevious} from 'elements/buttons/actionButtons'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import { StepList, steps } from 'modules/companyModule/pages/procurementProcesses/procurements/create/Steps'
import GeneralInfos from './GeneralInfos'
import PaymentAndDelivery from './PaymentAndDelivery'
import Lines from './Lines'
import DescriptionAndTechSpec from './DescriptionAndTechSpec'
import SupplierSelection from './SupplierSelection'
import ApprovalList from './ApprovalList'
import Confirmation from './Confirmation'

const CreateForm = ({update, setUpdate, procurement}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)    
    const selectedCompany = useSelector((state) => state.company.selectedCompany)   
    const user = useSelector((state) => state.user.user) 
    const isOpen = useSelector((state) => state.drawers.isOpenProcurementCreate) 
    const [currentStep, setCurrentStep] = useState(1)
    const [status, setStatus] = useState(false)
    const [procurementData, setProcurementData] = useState({})
    const [costTypes, setCostTypes] = useState([])
    const [products, setProducts] = useState([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {        
        if(user != null && Object.keys(user).length > 0) {
            const getCostTypeUsers = async () => {
                setLoading(true)
                await SendRequest('GET', `api/companies/${companyId}/users/${user.id}/procurement_cost_types`)
                    .then(response => {
                        if(response.data.data.length > 0) {
                            setCostTypes(response.data.data.sort((a,b) => a.cost_type.cost_name.localeCompare(b.cost_type.cost_name)))
                        } 
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }    
            getCostTypeUsers()
        } 
    }, [])

    useEffect(() => {
        const getProducts = async () => {
            if(procurementData.cost_type_id) {
                const cti = procurementData.cost_type_id + ',null'
                setLoading(true)
                await SendRequest('GET', `api/companies/${companyId}/products?no_pagination=true&cost_type_ids=${cti}`)
                    .then(response => {
                        if(response.status === 200) {
                            setProducts(response.data.data)
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
            
        }
        getProducts()
    }, [procurementData.cost_type_id])

    useEffect(() => {
        if(!isOpen) {
            setProcurementData({})
            setCurrentStep(1)
        } else {
            if(procurement !==null && Object.keys(procurement).length > 0) {
                const dataObj = {
                    title: procurement?.title || null,
                    business_area_id: procurement?.business_area.id || null,
                    cost_type_id: procurement?.cost_type.id || null,
                    proposal_type: procurement?.proposal_type || null,   
                    is_proposal_increase_not_allowed: procurement?.is_proposal_increase_not_allowed || false,   
                    is_proposal_cancel_not_allowed: procurement?.is_proposal_cancel_not_allowed || false,   
                    currency_id: procurement?.currency.id || 1,
                    payment_type: procurement?.payment_type ||null,    
                    deferral_days: procurement?.deferral_days || "1",    
                    is_currency_flexible: procurement?.is_currency_flexible || false,    
                    is_payment_type_flexible: procurement?.is_payment_type_flexible || false,    
                    delivery_type: procurement?.delivery_type || null,    
                    delivery_date: procurement?.delivery_date ? DateTime.fromISO(procurement?.delivery_date).toFormat("yyyy-LL-dd'T'T") : DateTime.local().plus({ days: 7 }).toFormat("yyyy-LL-dd'T'T"),
                    delivery_address: procurement?.delivery_address || `${selectedCompany.address} ${selectedCompany.district.name}/${selectedCompany.city.name}`,  
                    lines: (procurement?.lines && Array.isArray(procurement.lines)) ? 
                            procurement.lines.map(line => {
                                return {
                                    product_id: line.product.id,
                                    description_type: line.description_type,
                                    description_text: line.description_text,
                                    quantity: line.quantity,
                                    unit_code_id: line.unit_code.id,
                                    tech_spec_type: line.tech_spec_type,
                                    technical_specification_file: null,
                                }
                            }) : [{
                                product_id: null,
                                description_type: "2",
                                description_text: null,
                                quantity: null,
                                unit_code_id: null,
                                tech_spec_type: "no_file",
                                technical_specification_file: null,
                            }],
                    description: procurement?.description || null,
                    technical_specification_file: null,    
                    supplier_selection_type: procurement?.supplier_selection_type || null,   
                    supplier_business_areas: procurement?.supplier_business_areas ? 
                                                procurement.supplier_business_areas.map(ba => ba.business_area.id) :
                                                [],
                    suppliers: procurement?.suppliers ? 
                                procurement.suppliers.map(sp => sp.supplier.id) :
                                [],
                }
                setProcurementData(dataObj)
            }
        }
        

    }, [procurement, isOpen])

    const close = () => {
        dispatch(setIsOpenProcurementCreate())
    }

    const CurrentForm = () => {
        switch(currentStep) {
            case 1:
                return (
                    <GeneralInfos 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        procurementData={procurementData}
                        setProcurementData={setProcurementData}
                        costTypes={costTypes}      
                        setProducts={setProducts}                  
                    />
                )
            case 2:
                return (
                    <PaymentAndDelivery 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        procurementData={procurementData}
                        setProcurementData={setProcurementData}
                    />
                )
            case 3:
                return (
                    <Lines 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        procurementData={procurementData}
                        setProcurementData={setProcurementData}
                        products={products}                        
                    />
                )
            case 4:
                return (
                    <DescriptionAndTechSpec 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        procurementData={procurementData}
                        setProcurementData={setProcurementData}
                    />
                )
            case 5:
                return (
                    <SupplierSelection 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        procurementData={procurementData}
                        setProcurementData={setProcurementData}
                    />
                )
            case 6:
                return (
                    <ApprovalList 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        procurementData={procurementData}
                        setProcurementData={setProcurementData}
                    />
                )
            case 7:
                return (
                    <Confirmation 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        procurementData={procurementData}
                        setProcurementData={setProcurementData}
                        costTypes={costTypes}
                        products={products}
                        update={update}
                        setUpdate={setUpdate}
                        status={status}
                        setStatus={setStatus}
                    />
                )
            default: 
                return (
                    <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-8rem)]">
                        <div className="w-full overflow-y-auto">
                            {JSON.stringify(procurementData, null, 2)}
                            {console.log(procurementData)}
                        </div>
                        <div className="w-full flex justify-end mt-5 gap-x-2">
                            <ButtonClose onClick={() => close()}>
                            </ButtonClose>
                            {currentStep !== 1 ? 
                                <ButtonPrevious onClick={() => setCurrentStep(prev => prev-1)}>
                                </ButtonPrevious>
                            : null}
                            {currentStep < steps.length ? 
                                <ButtonNext onClick={() => setCurrentStep(prev => prev+1)}>
                                </ButtonNext>
                            : null}                    
                        </div>         
                    </div>
                )
        }
    }

    return (
        <div className="w-full h-full">
            <StepList currentStep={currentStep} />
            <CurrentForm />            
        </div>
        
    )
}

export default CreateForm