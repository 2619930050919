import { useSelector } from 'react-redux'
import { IconContext } from "react-icons"
import { FaUserAlt, FaUsers, FaCog } from 'react-icons/fa'
import companyAvatar from 'assets/img/company-avatar.jpeg'
import NavbarDropdown from 'elements/dropdowns/NavbarDropdown'
import NavbarLink from 'elements/dropdowns/NavbarLink'

function CompanyMenu() {
    const companyName = useSelector((state) => state.company.selectedCompany?.company_name)
    const userType = useSelector((state) => state.user.userType)

    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-4 w-4" }}>
                <NavbarLink path='/companies' title='Şirket Bilgileri' icon={<FaUserAlt />}/>
                </IconContext.Provider>
            </div>
        )
    } 

    return (
        <NavbarDropdown title={companyName} avatar={companyAvatar}>
            <Content />
        </NavbarDropdown>
    )
}

export default CompanyMenu