import { useContactTypes } from 'data'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonEditSmall, ButtonDeleteSmall } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierContactEdit, setIsOpenSupplierContactDelete } from 'stores/DrawersSlice'

function ContactTable({contacts, actions}) {
    const dispatch = useDispatch()
    const types = useContactTypes()
    if(contacts !== null && Array.isArray(contacts)) {
        return (         
            <table className="w-full text-xs">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-1 text-left">Yetkili Türü</th>
                        <th className="py-1 px-1">Ad Soyad</th>
                        <th className="py-1 px-1">Görevi</th>
                        <th className="py-1 px-1">Cep Tel.</th>
                        <th className="py-1 px-1">İş Tel.</th>
                        <th className="py-1 px-1">E-Posta</th>
                        <th className="py-1 px-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.length > 0 ?
                        contacts.map(contact => {
                            return (
                                <tr key={contact.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">
                                        {types.find(type => type.key === contact.type)?.value}
                                    </td>
                                    <td className="px-2 py-3 text-center">{contact.name}</td>
                                    <td className="px-2 py-3 text-center">{contact.position}</td>
                                    <td className="px-2 py-3 text-center">{contact.mobile_phone}</td>
                                    <td className="px-2 py-3 text-center">{contact.office_phone}</td>
                                    <td className="px-2 py-3 text-center">{contact.email}</td>
                                    <td className="px-2 py-3">
                                        <div className="flex items-center justify-end gap-x-1">
                                            <ButtonEditSmall 
                                                label={null} 
                                                onClick={() => {
                                                    actions.setContactForEdit(contact)
                                                    dispatch(setIsOpenSupplierContactEdit(true))
                                                }}    
                                            />
                                            <ButtonDeleteSmall 
                                                label={null} 
                                                onClick={() => {
                                                    actions.setContactForDelete(contact)
                                                    dispatch(setIsOpenSupplierContactDelete(true))
                                                }}    
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan={6}>Yetkili Kişi bulunamadı</td>
                        </tr>
                    }
                </tbody>
            </table>       
        )
    }
    return null
    
}

export default ContactTable