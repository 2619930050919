import { useLocation, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Loader from 'elements/Loader'

const CheckEmailVerified = ({children}) => {
    let location = useLocation()
    const emailVerified = useSelector(state => state.user.emailVerified)
    
    if(emailVerified === true) {   
        return children
    } else if(emailVerified === false) {
        return <Navigate to="/email-verification-notification" state={{ from: location }} replace />
    } else {
        return <Loader />
    }
    
}

export default CheckEmailVerified