import StatusCell from '../StatusCell'
import CompanyTypeCell from '../CompanyTypeCell'

function CompanyInfo({supplier}) {
    return (
        <table className="w-full">
            <tbody>
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">Şirket Adı:</td>
                    <td className="px-2 py-3">{supplier.company_name}</td>
                </tr>
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">Durum:</td>
                    <td className="px-2 py-3"> <StatusCell is_active={supplier.is_active} is_approved={supplier.is_approved} is_blacklisted={supplier.is_blacklisted} /> </td>
                </tr>
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">Sektörler:</td>
                    <td className="px-2 py-3">
                        {supplier.business_areas && Array.isArray(supplier.business_areas) && supplier.business_areas.length > 0 ? 
                            supplier.business_areas.map(ba => ba.name).join(', ')
                        : 'Sektör tanımlanmamış'}
                    </td>
                </tr>
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">Şirket Türü:</td>
                    <td className="px-2 py-3"> <CompanyTypeCell type={supplier.company_type} /> </td>
                </tr>
                {supplier.company_type.toString() === '1' ? 
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">TC Kimlik No:</td>
                    <td className="px-2 py-3">{supplier.identity_number}</td>
                </tr>
                : null}
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">Vergi No:</td>
                    <td className="px-2 py-3">{supplier.tax_number}</td>
                </tr>
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">Vergi Dairesi:</td>
                    <td className="px-2 py-3">{supplier.tax_department}</td>
                </tr>
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">Adres:</td>
                    <td className="px-2 py-3">{supplier.address} {supplier.district.name}/{supplier.city.name} </td>
                </tr>
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">Telefon:</td>
                    <td className="px-2 py-3">{supplier.phone}</td>
                </tr>
                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3 font-semibold">E-Posta:</td>
                    <td className="px-2 py-3">{supplier.email}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default CompanyInfo