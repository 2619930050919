import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { IconContext } from "react-icons"
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'
import {ButtonNewSmall, ButtonEditSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import { setIsOpenCreate, setIsOpenEdit, setIsOpenDelete } from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/DrawersSlice'

const TaxonCards = ({taxonomy, actions}) => {
    const dispatch = useDispatch()

    const [expandedNodes, setExpandedNodes] = useState([])

    const handleNodeToggle = (key) => {
        if (expandedNodes.includes(key)) {
        setExpandedNodes(expandedNodes.filter(nodeId => nodeId !== key))
        } else {
        setExpandedNodes([...expandedNodes, key])
        }
    }

    const ExpandButton = ({subNodes, isExpanded, nodeId}) => {
        if(subNodes && subNodes.length > 0 ) {
            return (
                <button onClick={() => handleNodeToggle(nodeId)} type="button" className="flex-none border border-gray-400 rounded text-gray-500 bg-white">
                    <IconContext.Provider value={{ className: "h-5 w-5" }}>
                    <span>
                        {isExpanded ? <HiChevronRight /> : <HiChevronDown/>}                        
                    </span>
                    </IconContext.Provider>
                </button>
            )
        } else {
            return <span className='inline-block w-[22px]'></span>
        }
    } 

    const ActionCard = ({parent, taxon}) => {
        return (
            <div className='w-full flex flex-col lg:flex-row justify-end gap-y-1 lg:gap-x-1'>
                <ButtonNewSmall 
                    label={null}
                    onClick={() => {
                        actions.setParent(taxon)
                        actions.setTaxonomyForTaxon(taxonomy)
                        dispatch(setIsOpenCreate(true))
                    }} 
                />
                <ButtonEditSmall 
                    label={null}
                    onClick={() => {
                        actions.setParent(parent)
                        actions.setTaxonomyForTaxon(taxonomy)
                        actions.setTaxonForEdit(taxon)
                        dispatch(setIsOpenEdit(true))
                    }} 
                />
                <ButtonDeleteSmall 
                    label={null}
                    onClick={() => {
                        actions.setParent(parent)
                        actions.setTaxonomyForTaxon(taxonomy)
                        actions.setTaxonForDelete(taxon)
                        dispatch(setIsOpenDelete(true))
                    }} 
                />
            </div>
        )        
    }

    const Card = ({taxa, parent}) => {
        return taxa.sort((a,b) => a.taxon_name.localeCompare(b.taxon_name)).map(taxon => {
            const leftMargin = parent !== null ? "ml-6 lg:ml-6" : ''
            const isNodeExpanded = expandedNodes.includes(taxon.id)
            return (
                <div key={`taxon_${taxon.id}`} className={leftMargin}>
                    <div className="flex items-center justify-start gap-x-2">
                        <div className="flex-none">
                            <ExpandButton subNodes={taxon?.all_sub_taxa} isExpanded={isNodeExpanded} nodeId={taxon.id} />
                        </div> 
                        <div className="px-2 py-1 my-1 border border-sky-200 bg-sky-50 min-w-[12rem] flex-grow">
                            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-y-2">
                                <p className='lg:whitespace-nowrap font-semibold text-sm text-gray-700'>
                                    {taxon.taxon_name}
                                </p>
                                <ActionCard parent={parent} taxon={taxon} />
                            </div>                        
                        </div>
                    </div>
                    {(taxon?.all_sub_taxa && Array.isArray(taxon.all_sub_taxa) && taxon.all_sub_taxa.length > 0 && isNodeExpanded) ? 
                        <Card parent={taxon} taxa={taxon.all_sub_taxa}/>
                        : null}
                </div>
            )
        })
    }
    if(taxonomy?.taxa) {
        return <Card taxa={taxonomy.taxa} parent={null} />
    }
    return null
}

export default TaxonCards