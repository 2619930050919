import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import SupplierInfo from 'modules/companyModule/pages/suppliers/supplier/SupplierInfo'
import ProposalSummary from 'modules/companyModule/pages/suppliers/supplier/ProposalSummary'
import Products from 'modules/companyModule/pages/suppliers/supplier/Products'
import { useRandomColors } from 'data/colors'

function Supplier() {
    let params = useParams()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const systemCurrencies = useSelector((state) => state.sharedData.currencies)
    const [supplier, setSupplier] = useState(null)
    const [loading, setLoading] = useState(false)
    const colors = useRandomColors()

    useEffect(() => {
        const getSupplier = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/suppliers/${params.id}`)
                .then(res => {
                    if(res.status === 200) {
                        setSupplier(res.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        
        getSupplier()            
    }, [])

    return (
        <>
            <div className="w-full mb-4">
                <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                    <h2 className="text-2xl text-gray-800 font-semibold">{supplier?.company_name} Detayları</h2>
                </div>
                <div className="w-full flex flex-col lg:flex-row gap-4">
                    <SupplierInfo supplier={supplier} />
                    <ProposalSummary supplier={supplier} />
                </div>
                <div>
                    <Products supplier={supplier} />
                </div>
                {/* <pre>
                {JSON.stringify(supplier, null, 2)}
                </pre> */}
                {/* <div>
                    {colors.map((value, index) => {
                        return <p key={index} className="w-[2rem] h-[1rem]" style={{backgroundColor: value}}></p>
                    })}
                </div> */}
                {loading && <Loader />}
            </div>
        </>
    )

}

export default Supplier