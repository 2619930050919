import { useState, useEffect } from 'react'
import { IconContext } from "react-icons"
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'

function RadioTree({type = 'radio', name, register, label, validator, error = null, selected = 0, data}){
    const [selectedNode, setSelectedNode] = useState(null)
    const [expandedNodes, setExpandedNodes] = useState([])

    type = type == 'radio_tree' ? 'radio' : type

    const handleNodeToggle = (key) => {
        if (expandedNodes.includes(key)) {
        setExpandedNodes(expandedNodes.filter(nodeId => nodeId !== key))
        } else {
        setExpandedNodes([...expandedNodes, key])
        }
    }

    const TreeNode = ({ node }) => {
        const handleToggle = () => {
            handleNodeToggle(node.key);
        }
      
        const isNodeExpanded = expandedNodes.includes(node.key);
        
        return (
          <div>
            <div className='flex items-center justify-start py-px'>
                {node.children && node.children.length > 0 ? 
                <button onClick={handleToggle} type="button">
                    <IconContext.Provider value={{ className: "h-5 w-5" }}>
                    <span>
                        {isNodeExpanded ? <HiChevronRight /> : <HiChevronDown/>}                        
                    </span>
                    </IconContext.Provider>
                </button>
                : <span className='inline-block w-5'></span> }
              <label className='flex text-gray-700 items-center justify-start cursor-pointer'>
                <input
                    className="form-radio mr-2 text-gray-700 border-2 border-gray-500 rounded-full focus:outline-none focus:shadow-none focus:ring-0 focus:ring-transparent"
                    type={type}
                    {...register(name, validator)}
                    value={node.key}
                />
                {node.value}
              </label>
            </div>
            {isNodeExpanded && node.children && (
              <ul className='pl-4'>
                {node.children.map(child => (
                  <li key={child.key}>
                    <TreeNode
                      node={child}
                      onNodeToggle={handleNodeToggle}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
    }
    
    
    
    return (
        <div className="flex flex-col gap-y-0.5 items-start">
        <p className="text-gray-700 text-xs">{label}</p>
        {data.map(d => {
            return (
                <TreeNode 
                    key={d.key}
                    node={d} 
                />
            )
        })}
        <span className="text-xs text-red-600">{error}</span>
        </div>
    )
}

export default RadioTree

