import TableActions from 'modules/companyModule/pages/reports/suppliers/TableActions'
import TableLayout from 'elements/tables'
import FindCategory from 'utils/FindCategory'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

const Table = ({data, actions, progressPending, pagination, pageMeta, categories = null}) => {

    const CategoryCell = ({selectedCategories}) => {
        if(categories.length > 0 && selectedCategories !== null && selectedCategories !== undefined) {
            let catJson = new Map(Object.entries(selectedCategories))
            if(catJson.size > 0) {
                return [...catJson].map(cat => {     
                    if(cat[1]) {
                        let foundCategory = FindCategory(categories, cat[0])
                        if(foundCategory) {
                            let optionSelected = foundCategory.category_items.find(item => {
                                return item.id == cat[1]
                            })
                            return (<p key={foundCategory.id} className="whitespace-nowrap mb-0.5">
                                    <span className="font-semibold">{foundCategory.category_name}:</span> {optionSelected?.item_name}
                                </p>)
                        }                       
                    }                          
                })
            }
        } else {
            return <p></p>
        }
        
    }

    const columns = [
        {
            name: 'Şirket Adı',
            selector: (row) => {return row.company_name},
            headerMobileHidden: false,
        },
        {
            name: 'Sektör',
            selector: (row) => {return row.business_area.name},
            headerMobileHidden: false,
        },
        {
            name: 'Kategori',
            selector: (row) => {return <CategoryCell selectedCategories={row.categories.categories}/>},
            headerMobileHidden: false,
        },
        {
            name: 'Verilen Teklif',
            selector: (row) => {return row.total_proposals},
            headerMobileHidden: false,
        },
        {
            name: 'Kabul Edilen Teklif',
            selector: (row) => {return row.accepted_proposals},
            headerMobileHidden: false,
        },
        {
            name: 'Kabul Edilen Teklif Tutarı',
            selector: (row) => {return row.proposals.map(item => FNWS(item.price) + ' ' + item.currency.code).join(' + ')},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return row.company_name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
