import { useDispatch } from 'react-redux' 
import { ButtonDetailSmall, ButtonNewSmall, ButtonListSmall } from 'elements/buttons/actionButtons'
import { setIsOpenProcurementProposalDetail, setIsOpenSupplierDetail } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    
    return (
        <div className='w-full flex items-center justify-center gap-x-1'>
            <ButtonDetailSmall 
                label='Teklif Detayı' 
                onClick={() => {
                    actions.setProposalForDetail(data)
                    dispatch(setIsOpenProcurementProposalDetail(true))
                    }} 
            />
            <ButtonListSmall 
                label='Tedarikçi Detayı' 
                onClick={() => {
                    actions.setSupplierIdForDetail(data.supplier.id)
                    dispatch(setIsOpenSupplierDetail(true))
                    }} 
            />
        </div>
    )
};

export default TableActions
