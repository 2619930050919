import { IconContext } from "react-icons"
import { FaBookOpen, FaPlusCircle, FaRegFileExcel, FaRegEdit, FaTrashAlt, FaRedoAlt, FaSave, FaTimesCircle, FaCheckCircle, FaUpload, FaSearchPlus, FaList, FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import { HiOutlineFilter, HiOutlineDocumentText, HiPrinter, HiRefresh } from 'react-icons/hi'
import { MdSettingsBackupRestore } from 'react-icons/md'

const ButtonDetail = ({children, type = 'button', label='Detaylar', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-teal-700 bg-teal-600 text-white hover:bg-teal-700 ${addClass}`} {...props}>
                <FaBookOpen />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonDetailSmall = ({children, type = 'button', label='Detaylar', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-1.5 rounded border border-teal-700 bg-teal-600 text-white hover:bg-teal-700 ${addClass}`} {...props}>
                <FaBookOpen />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonFilter = ({children, type = 'button', label='Filtrele', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-cyan-700 bg-cyan-600 text-white hover:bg-cyan-700 ${addClass}`} {...props}>
                <HiOutlineFilter />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonRefresh = ({children, type = 'button', label='Yenile', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-gray-700 bg-transparent text-gray-700 hover:bg-gray-200 ${addClass}`} {...props}>
                <HiRefresh />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonFilterSmall = ({children, type = 'button', label='Filtrele', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-1.5 rounded border border-cyan-700 bg-cyan-600 text-white hover:bg-cyan-700 ${addClass}`} {...props}>
                <HiOutlineFilter />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonNew = ({children, type = 'button', label='Yeni Ekle', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-green-800 bg-green-700 text-white hover:bg-green-800 ${addClass}`} {...props}>
                <FaPlusCircle />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonNewSmall = ({children, type = 'button', label='Yeni Ekle', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-1.5 rounded border border-amber-700 bg-amber-600 text-white hover:bg-amber-700 ${addClass}`} {...props}>
                <FaPlusCircle />
                {label !== null ? 
                <span className="leading-none">{label}</span> :
                null }
            </button>
        </IconContext.Provider>
    )
}

const ButtonExcel = ({children, type = 'button', label='Excele Aktar', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-gray-400 bg-white text-gray-600 hover:bg-gray-100 ${addClass}`} {...props}>
                <FaRegFileExcel />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonStatement = ({children, type = 'button', label='Hesap Ekstresi', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-gray-400 bg-white text-gray-600 hover:bg-gray-100 ${addClass}`} {...props}>
                <HiOutlineDocumentText />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonEdit = ({children, type = 'button', label='Düzenle', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-sky-700 bg-sky-600 text-white hover:bg-sky-700 ${addClass}`} {...props}>
                <FaRegEdit />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonEditSmall = ({children, type = 'button', label='Düzenle', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-1.5 rounded border border-sky-700 bg-sky-600 text-white hover:bg-sky-700 ${addClass}`} {...props}>
                <FaRegEdit />
                {label !== null ? 
                <span className="leading-none">{label}</span> :
                null }
            </button>
        </IconContext.Provider>
    )
}

const ButtonDelete = ({children, type = 'button', label='Sil', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-red-700 bg-red-600 text-white hover:bg-red-700 ${addClass}`} {...props}>
                <FaTrashAlt />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonDeleteSmall = ({children, type = 'button', label='Sil', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-1.5 rounded border border-red-700 bg-red-600 text-white hover:bg-red-700 ${addClass}`} {...props}>
                <FaTrashAlt />
                {label !== null ? 
                <span className="leading-none">{label}</span> :
                null }
            </button>
        </IconContext.Provider>
    )
}

const ButtonCancel = ({children, type = 'button', label='Vazgeç', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-red-700 bg-red-600 text-white hover:bg-red-700 ${addClass}`} {...props}>
                <FaTimesCircle />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonCancelAlt = ({children, type = 'button', label='Vazgeç', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-red-700 bg-red-50 text-red-700 hover:bg-red-100 ${addClass}`} {...props}>
                <FaTimesCircle />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonCancelSmall = ({children, type = 'button', label='Vazgeç', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-1.5 rounded border border-red-700 bg-red-600 text-white hover:bg-red-700 ${addClass}`} {...props}>
                <FaTimesCircle />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonReset = ({children, type = 'button', label='Sıfırla', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-red-700 bg-red-600 text-white hover:bg-red-700 ${addClass}`} {...props}>
                <FaRedoAlt />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonSave = ({children, type = 'button', label='Kaydet', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-emerald-700 bg-emerald-600 text-white hover:bg-emerald-700 ${addClass}`} {...props}>
                <FaSave />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonSaveSmall = ({children, type = 'button', label='Kaydet', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-1.5 rounded border border-emerald-700 bg-emerald-600 text-white hover:bg-emerald-700 ${addClass}`} {...props}>
                <FaSave />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonClose = ({children, type = 'button', label='Kapat', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-red-700 bg-red-600 text-white hover:bg-red-700 ${addClass}`} {...props}>
                <FaTimesCircle />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonOk = ({children, type = 'button', label='Tamam', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-emerald-700 bg-emerald-600 text-white hover:bg-emerald-700 ${addClass}`} {...props}>
                <FaCheckCircle />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonUpload = ({children, type = 'button', label='Yükle', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-sky-700 bg-sky-600 text-white hover:bg-sky-700 ${addClass}`} {...props}>
                <FaUpload />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonPrint = ({children, type = 'button', label='Yazdır', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-teal-700 bg-teal-600 text-white hover:bg-teal-700 ${addClass}`} {...props}>
                <HiPrinter />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonShowDetail = ({children, type = 'button', label='Yazdır', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-1 py-1 rounded text-gray-700 ${addClass}`} {...props}>
                <span className="leading-none">{label}</span>
                <FaSearchPlus />
            </button>
        </IconContext.Provider>
    )
}

const ButtonList = ({children, type = 'button', label='Hepsini Gör', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-sky-700 bg-sky-600 text-white hover:bg-sky-700 ${addClass}`} {...props}>
                <FaList />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonListSmall = ({children, type = 'button', label='Hepsini Gör', addClass='', ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-1.5 rounded border border-sky-700 bg-sky-600 text-white hover:bg-sky-700 ${addClass}`} {...props}>
                <FaList />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

const ButtonNext = ({children, type = 'button', label='İleri', addClass='', disabled=false, ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-sky-700 bg-sky-600 text-white hover:bg-sky-700 ${addClass} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`} {...props}>
                <span className="leading-none">{label}</span>
                <FaChevronRight />
            </button>
        </IconContext.Provider>
    )
}

const ButtonPrevious = ({children, type = 'button', label='Geri', addClass='', disabled=false, ...props}) => {
    return (
        <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
            <button type={type} className={`overflow-hidden flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-sky-700 bg-sky-600 text-white hover:bg-sky-700 ${addClass} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`} {...props}>
                <FaChevronLeft />
                <span className="leading-none">{label}</span>
            </button>
        </IconContext.Provider>
    )
}

export {
    ButtonDetail,
    ButtonDetailSmall,
    ButtonFilter,
    ButtonFilterSmall,
    ButtonRefresh,
    ButtonNew,
    ButtonNewSmall,
    ButtonExcel,
    ButtonStatement,
    ButtonEdit,
    ButtonEditSmall,
    ButtonDelete,
    ButtonDeleteSmall,
    ButtonCancel,
    ButtonCancelSmall,
    ButtonCancelAlt,
    ButtonReset,
    ButtonSave,
    ButtonSaveSmall,
    ButtonClose,
    ButtonOk,
    ButtonUpload,
    ButtonPrint,
    ButtonShowDetail,
    ButtonList,
    ButtonListSmall,
    ButtonNext,
    ButtonPrevious,
} 