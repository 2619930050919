import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import AccountTable from 'modules/supplierModule/pages/companies/bankAccounts/AccountTable'
import { ButtonNew, ButtonNewSmall } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierBankAccountCreate } from 'stores/DrawersSlice'
import Create from 'modules/supplierModule/pages/companies/bankAccounts/Create'
import Edit from 'modules/supplierModule/pages/companies/bankAccounts/Edit'
import Delete from 'modules/supplierModule/pages/companies/bankAccounts/Delete'
import Loader from 'elements/Loader'
import Header from 'modules/supplierModule/pages/companies/Header'

const BankAccounts = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [accounts, setAccounts] = useState([])
    const [accountForEdit, setAccountForEdit] = useState([])
    const [accountForDelete, setAccountForDelete] = useState([])

    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)

    useEffect(() => {
        const getContacts = async () => {
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/bank_accounts`)
                .then(response => {
                    if(response.status === 200) {
                        setAccounts(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getContacts()
    }, [updateData])

    const CreateButton = () => {
        return (
            <ButtonNewSmall label="Yeni" onClick={() => {dispatch(setIsOpenSupplierBankAccountCreate(true))}} >
            </ButtonNewSmall>
        )
    }

    return (
        <div className="w-full mb-4">            
            <div className="">
                <Header title="Banka Hesap Bilgileri" button={<CreateButton />} />
                <AccountTable 
                    accounts={accounts} 
                    actions={{
                        setAccountForEdit: setAccountForEdit,
                        setAccountForDelete: setAccountForDelete
                    }}
                />
            </div>
            <Create update={updateData} setUpdate={setUpdateData} />
            <Edit account={accountForEdit} update={updateData} setUpdate={setUpdateData} />
            <Delete account={accountForDelete} update={updateData} setUpdate={setUpdateData} />
            {loading && <Loader />}
        </div>
    )
}

export default BankAccounts