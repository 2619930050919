import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currencies: null,
    currenciesWithDesc: null,
    exchangeRates: null,
    unitCodes: null,
    businessAreas: null,
    cities: null,
    districts: null,
    productTypes: null,
}

export const sharedDataSlice = createSlice({
    name: 'sharedData',
    initialState,
    reducers: {
        setCurrencies: (state, action) => {
            state.currencies = action.payload
        },
        setCurrenciesWithDesc: (state, action) => {
            state.currenciesWithDesc = action.payload
        },
        setExchangeRates: (state, action) => {
            state.exchangeRates = action.payload
        },
        setUnitCodes: (state, action) => {
            state.unitCodes = action.payload
        },
        setBusinessAreas: (state, action) => {
            state.businessAreas = action.payload
        },
        setCities: (state, action) => {
            state.cities = action.payload
        },
        setDistricts: (state, action) => {
            state.districts = action.payload
        },
        setProductTypes: (state, action) => {
            state.productTypes = action.payload
        },
    }
})

export const {
        setCurrencies, 
        setCurrenciesWithDesc, 
        setExchangeRates, 
        setUnitCodes, 
        setBusinessAreas,
        setCities,
        setDistricts,
        setProductTypes,
    } = sharedDataSlice.actions

export default sharedDataSlice.reducer