import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import { DateTime } from 'luxon'
import { IconContext } from "react-icons"
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { ButtonOk, ButtonSave} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FormatNumber from 'utils/FormatNumber'
import { setIsOpenMakeProposal } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useDescriptionInput, useLineInput, usePaymentTermInput } from 'modules/supplierModule/pages/procurements/InputArray'
import DetailInfo from './DetailInfo'

const MakeProposalForm = ({update, setUpdate, procurement}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)   
    const currencies = useSelector(state => state.sharedData.currencies) 
    const linesDefaults = {
        procurement_line_id: null,
        description_text: null,
        price: null,
    }
    const formDefaults = {
        description: null,
        procurement_id: null,
        currency_id: 1,
        payment_type: null,    
        deferral_days: "1",
        lines: [linesDefaults]  
    }
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showProcurementData, setShowProcurementData] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setError: setFormError, setValue, getValues } = useForm({
        defaultValues: formDefaults
    })

    const watchLines = watch("lines")
    const watchPaymentType = watch("payment_type")
    const watchCurrencyId = watch("currency_id")
    
    const descirptionInputs = useDescriptionInput(errors)
    const lineInputs = useLineInput(errors, procurement?.proposal_type)
    const paymentTermInputs = usePaymentTermInput(errors, getValues)

    useEffect(() => {
        setError(null)
        setStatus(false)
        const madeProposal = () => {
            if(procurement.proposals && Array.isArray(procurement.proposals) && procurement.proposals.length > 0) {
                let activeProposal = procurement.proposals.filter(proposal => {
                    return proposal.is_cancelled === false && proposal.is_revised === false
                })
                if(activeProposal.length > 0) {
                    if(activeProposal[0].is_accepted === false) {
                        return activeProposal[0]
                    }
                    return false    
                }
    
                return false
            } 
    
            return false
        }

        if(procurement !==null && Object.keys(procurement).length > 0) {
            const lineItems = procurement.lines.map(line => {
                if(madeProposal()) {
                    const p = madeProposal()
                    const filteredProposal = p.lines.filter(l => l.procurement_line.id == line.id)
                    return {
                        procurement_line_id: line.id,
                        description_text: filteredProposal.length > 0 ? filteredProposal[0].description_text : null,
                        price: filteredProposal.length > 0 ? filteredProposal[0].price : null,
                    }
                } else {
                    return {
                        procurement_line_id: line.id,
                        description_text: null,
                        price: null,
                    }
                }
                
            })

            const procurementItems = madeProposal() ? 
            {
                description: null,
                procurement_id: procurement.id,
                currency_id: madeProposal().currency.id,
                payment_type: madeProposal().payment_type,    
                deferral_days: madeProposal().deferral_days,
            } 
            : {
                description: null,
                procurement_id: procurement.id,
                currency_id: procurement.currency.id,
                payment_type: procurement.payment_type,    
                deferral_days: procurement.deferral_days,
            } 
            
            reset({...procurementItems, lines : lineItems}, {keepDirty: true})
        } else {
            reset(formDefaults)
        }
    }, [procurement])

    const handleForm = async (data) => {
        const totalAmount = data.lines.reduce( (previous, current) => {
            return previous + (current.price ? parseFloat(current.price.toString().replace(",", ".")) : 0)
         }, 0)

        if(totalAmount > 0) {
            setLoading(true)
            await SendRequest('POST', `api/suppliers/${companyId}/procurements/${procurement.id}/make_proposal`, data)
                    .then(res => {
                        if(res.status === 204) {
                            reset()
                            setStatus(true)
                            setError(null)
                            setUpdate(!update)
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setError(err.response.data)
                        setLoading(false)
                    })
        } else {
            data.lines.forEach((line, index) => {
                setFormError(`lines.${index}.price`, {type: 'manuel', message: 'En az bir ürün için teklif vermelisiniz'})
            })
            
        }             
    }    

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenMakeProposal())
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const totalPrice = () => {
        let total = 0
        watchLines.map( line => {
           total += line.price ? parseFloat(line.price.toString().replace(",", ".")) : 0
        })
        return FormatNumber(total)
    }

    const ExpandButton = () => {
        return (
            <button onClick={() => setShowProcurementData(prev => !prev)} type="button" className="flex-none border border-gray-400 rounded text-gray-500 bg-white">
                <IconContext.Provider value={{ className: "h-4 w-4" }}>
                <span>
                    {showProcurementData ? <HiChevronUp /> : <HiChevronDown/>}                        
                </span>
                </IconContext.Provider>
            </button>
        )
    }
    
    const ProposalCurrencyCode = () => {
        if(procurement && Object.keys(procurement).length > 0) {
            if(procurement.is_currency_flexible) {
                const curr = currencies.filter(c => c.key.toString() === watchCurrencyId.toString())
                if(curr.length) return curr[0].value
            }
            return procurement.currency.code
        }

        return 'UKN'
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            (procurement && Object.keys(procurement).length > 0) ?
                
                <form onSubmit={handleSubmit(handleForm, handleError)}>
                    <div className="border border-gray-400 bg-gray-100 p-1 flex justify-between items-center">            
                        <h2 className="text-xs text-gray-800 font-semibold">Talep Detayları</h2>
                        <ExpandButton />
                    </div>
                    <div className={`border border-t-0 border-gray-400 bg-transparent p-1 ${showProcurementData ? '' : 'hidden'}`}>
                        <DetailInfo procurement={procurement} />
                    </div>
                    <div className="border border-gray-400 bg-gray-100 p-1 flex justify-between items-center mt-2">            
                        <h2 className="text-xs text-gray-800 font-semibold">Teklif Detayları</h2>
                        <button></button>
                    </div>
                    <div className="border border-t-0 border-gray-400 bg-transparent p-1">
                        {(procurement.is_currency_flexible || procurement.is_payment_type_flexible) ? 
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ödeme Bilgileri</p>
                        : null }
                        <div className="lg:grid lg:grid-cols-12 gap-2">
                        {paymentTermInputs.map(input => {
                            if((input.key === 'paymentTermInput1' && procurement.is_currency_flexible) || ((input.key === 'paymentTermInput2' || input.key === 'paymentTermInput3') && procurement.is_payment_type_flexible)) {
                                return (
                                    <div className={input.cols} key={input.key}>
                                        {input.type === 'select' ? 
                                            <SelectSmall register={register} {...input} />
                                            : null
                                        }
                                        {input.type === 'text' ? 
                                            input.key === 'paymentTermInput3' && watchPaymentType === 'deferred' ?
                                            <InputSmall register={register} {...input} /> : null
                                            : null
                                        }
                                    </div>
                                    )
                            } else {
                                return null
                            }
                            
                        })}
                        </div> 
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Fiyat Teklifleri</p>   
                        <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                            <div className='col-span-3 flex flex-col gap-y-1 items-start'>
                                <span className='text-[12px] line-clamp-1'>Ürün</span>
                            </div>
                            <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                                <span className='text-[12px] line-clamp-1'>Miktar</span>
                            </div>
                            <div className='col-span-6 flex flex-col gap-y-1 items-start'>
                                <span className='text-[12px] line-clamp-1'>Açıklama</span>
                            </div>
                            <div className='col-span-2 flex flex-col gap-y-1 items-start'>
                                <span className='text-[12px] line-clamp-1'>Fiyat (KDV Hariç)</span>
                            </div>                        
                        </div>
                        {procurement.lines.map((line, index) => {
                            return (
                                <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${index}`}>
                                    <div className='col-span-3 flex items-center'>
                                        <span>{line.product.name}</span>                                    
                                    </div>
                                    <div className='col-span-1 flex items-center'>
                                        <span>{line.quantity} {line.unit_code.description}</span>                                    
                                    </div>
                                    <div className='col-span-6 flex items-center'>
                                        <InputSmall 
                                            {...lineInputs[2]}
                                            register={register} 
                                            name={`lines.${index}.${lineInputs[2].nameLast}`} 
                                            error={errors?.lines?.[index]?.[lineInputs[2].nameLast]?.message} 
                                        />
                                    </div>
                                    <div className='col-span-2 flex items-center'>
                                        <InputSmall 
                                            {...lineInputs[1]}
                                            register={register} 
                                            name={`lines.${index}.${lineInputs[1].nameLast}`} 
                                            error={errors?.lines?.[index]?.[lineInputs[1].nameLast]?.message} 
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        <div className='lg:grid lg:grid-cols-12 gap-2 mt-2 border-t border-gray-700 text-gray-700 text-sm'>
                            <div className='col-span-7'></div>
                            <div className='col-span-3 text-right'>
                                <span className='font-bold'>Toplam: </span>
                            </div>
                            <div className='col-span-2'>
                                <span className='font-bold'>{totalPrice()} <ProposalCurrencyCode /></span>
                            </div>
                            
                        </div>
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Açıklamalar</p>
                        <div className="grid grid-cols-12 gap-2">
                        {descirptionInputs.map(input => {
                            return (
                                <div className={input.cols} key={input.key}>
                                    <TextareaSmall register={register} {...input} rows="5" /> 
                                </div>
                                )
                        })}
                        </div>  
                    </div>
                    
                                 
                    <div className="w-full flex justify-end mt-5">
                        <ButtonSave type="submit">
                        </ButtonSave>
                    </div>          
                </form> 
                
                : null
            
            
        }   
        {loading && <Loader />}
        </>
    )
}

export default MakeProposalForm