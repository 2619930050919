import { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import ProductTable from 'modules/companyModule/pages/suppliers/supplier/ProductTable'

function Products({supplier}) {
    const companyId = useSelector((state) => state.company.selectedCompany.id)

    const [products, setProducts] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getProducts = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/suppliers/${supplier.id}/product_accepted`)
                .then(res => {
                    if(res.status === 200) {
                        setProducts(res.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if(supplier) getProducts()                     
    }, [supplier])

    return (
        <div>
            <div className="pb-2 my-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-xl text-gray-800 font-semibold">Satın Alınan Ürünler</h2>            
            </div>
            <ProductTable data={products} progressPending={loading} />
        </div>
    )
}

export default Products