import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import { DateTime } from 'luxon'
import { ButtonOk, ButtonSave} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FormatNumber from 'utils/FormatNumber'
import { setIsOpenWarehouseDemandDeliver } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useDescriptionInput, useLineInput } from 'modules/companyModule/pages/warehouses/demands/InputArray'

const DeliverForm = ({productDemand, update, setUpdate, selectedWarehouse}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)    
    const linesDefaults = {
        product_demand_line_id: null,
        description_text: null,
        quantity: null,
        unit_code_id: null,
    }
    const formDefaults = {
        description: null,
        product_demand_id: null,
        confirmation_code: null,
        lines: [linesDefaults]  
    }
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [unitCodes, setUnitCodes] = useState([])
    const { register, handleSubmit, formState: { errors }, reset, watch, control, trigger, setValue, getValues } = useForm({
        defaultValues: formDefaults
    })

    const watchLines = watch("lines");
    
    const descirptionInputs = useDescriptionInput(errors)
    const lineInputs = useLineInput(errors)

    useEffect(() => {
        setError(null)
        setStatus(false)
        const getUnitCodes = (lines) => {
            let unitCodeArray = []
            lines.forEach(line => {
                unitCodeArray.push([{
                    key: line.unit_code.id,
                    value: `${line.unit_code.description} (${line.unit_code.code})`
                }])                
            })
            setUnitCodes(unitCodeArray)
        }
        if(productDemand !==null && Object.keys(productDemand).length > 0) {
            let lineItems = productDemand.lines.map(line => {
                return {
                    product_demand_line_id: line.id,
                    description_text: null,
                    quantity: line.quantity,
                    unit_code_id: line.unit_code.id,
                }                
            })
            
            reset(
                {
                    product_demand_id: productDemand.id, 
                    description: null, 
                    confirmation_code: null,
                    lines : lineItems
                }, 
                {
                    keepDirty: true
                }
                )
            const productIds = productDemand.lines.map(line => line.product.id)
            if(productIds.length > 0) {
                getUnitCodes(productDemand.lines)
            }
        } else {
            reset(formDefaults)
        }
    }, [productDemand])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/product_demands/${productDemand.id}/deliver`, data)
                .then(res => {
                    if(res.status === 204) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenWarehouseDemandDeliver())
    }

    const handleError = (error, e) => {
        console.log(error)
        console.log(getValues('lines'))
    }

    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy T')
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            (productDemand && Object.keys(productDemand).length > 0) ?
                
                <form onSubmit={handleSubmit(handleForm, handleError)}>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                    <table className="w-full">
                        <tbody>
                            <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Talep No:</td>
                                <td className="px-2 py-3">{productDemand.demand_no}</td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Departman: </td>
                                <td className="px-2 py-3">{productDemand.department.department_name}</td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Talebi Oluşturan: </td>
                                <td className="px-2 py-3">{productDemand.user.name}</td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Oluşturulma Zamanı: </td>
                                <td className="px-2 py-3">{formatDate(productDemand.created_at)}</td>
                            </tr>
                        </tbody>
                    </table>     
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Talep Açıklamaları</p>
                    <p className="whitespace-pre-line">{productDemand.description}</p>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>   
                    <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                        <div className='col-span-2 flex flex-col gap-y-1 items-start'>
                            <span className='text-[12px] line-clamp-1'>Ürün</span>
                        </div>
                        <div className='col-span-2 flex flex-col gap-y-1 items-start'>
                            <span className='text-[12px] line-clamp-1'>Ürün Açıklaması</span>
                        </div>
                        <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                            <span className='text-[12px] line-clamp-1'>Toplam Stok</span>
                        </div>
                        <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                            <span className='text-[12px] line-clamp-1'>Depodaki Stok</span>
                        </div>
                        <div className='col-span-2 flex flex-col gap-y-1 items-start'>
                            <span className='text-[12px] line-clamp-1'>Talep Edilen Miktar</span>
                        </div>
                        <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                            <span className='text-[12px] line-clamp-1'>Teslim Edilen Miktar</span>
                        </div>
                        <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                            <span className='text-[12px] line-clamp-1'>Birim</span>
                        </div> 
                        <div className='col-span-2 flex flex-col gap-y-1 items-start'>
                            <span className='text-[12px] line-clamp-1'>Açıklama</span>
                        </div>                                               
                    </div>
                    {productDemand.lines.map((line, index) => {
                        return (
                            <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${index}`}>
                                <div className='col-span-2 flex items-center'>
                                    <span>{line.product.name}</span>                                    
                                </div>
                                <div className='col-span-2 flex items-center'>
                                    <span>{line.description_text}</span>                                    
                                </div>
                                <div className='col-span-1 flex flex-col justify-center'>
                                    {line.product.stocks.length > 0 ? 
                                        line.product.stocks.map(s => {
                                            return <p key={s.id}>{s.quantity} {s.unit.description}</p>
                                        })
                                    : 
                                        <span>Stok Yok</span>
                                    }                               
                                </div>
                                <div className='col-span-1 flex flex-col justify-center'>
                                    {line.product.warehouse_stocks.length > 0 ? 
                                        line.product.warehouse_stocks.map(s => {
                                            return <p key={s.id}>{s.quantity} {s.unit.description}</p>
                                        })
                                    : 
                                        <span>Stok Yok</span>
                                    }                                       
                                </div>
                                <div className='col-span-2 flex items-center'>
                                    <span>{line.quantity} {line.unit_code.description}</span>                                    
                                </div>
                                <div className='col-span-1 flex items-center'>
                                    <InputSmall 
                                        {...lineInputs[1]}
                                        register={register} 
                                        name={`lines.${index}.${lineInputs[1].nameLast}`} 
                                        error={errors?.lines?.[index]?.[lineInputs[1].nameLast]?.message} 
                                    />
                                </div>
                                <div className='col-span-1 flex items-center'>
                                    <SelectSmall 
                                        {...lineInputs[3]}
                                        data={unitCodes?.[index]}
                                        register={register} 
                                        name={`lines.${index}.${lineInputs[3].nameLast}`} 
                                        error={errors?.lines?.[index]?.[lineInputs[3].nameLast]?.message} 
                                    />
                                </div>
                                <div className='col-span-2 flex items-center'>
                                    <InputSmall 
                                        {...lineInputs[2]}
                                        register={register} 
                                        name={`lines.${index}.${lineInputs[2].nameLast}`} 
                                        error={errors?.lines?.[index]?.[lineInputs[2].nameLast]?.message} 
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Diğer Bilgiler</p>
                    <div className="grid grid-cols-12 gap-2">
                    {descirptionInputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                {input.type === 'textarea' ? 
                                <TextareaSmall register={register} {...input} rows="5" /> 
                                : <InputSmall register={register} {...input} /> 
                                }
                            </div>
                            )
                    })}
                    </div>               
                    <div className="w-full flex justify-end mt-5">
                        <ButtonSave type="submit">
                        </ButtonSave>
                    </div>          
                </form> 
                
                : null
            
            
        }   
        {loading && <Loader />}
        </>
    )
}

export default DeliverForm