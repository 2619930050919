import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import { GreenNormal, OrangeNormal, RedNormal, BlueNormal } from "elements/infoCards" 
import { ProcurementStatus } from 'data'

function TopCards({updateData}) {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/statistics/procurements`)
                .then(response => {
                    if(response.status === 200) {
                        setData(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getData()
    }, [updateData])

    const procurementStatusObj = {}

    ProcurementStatus().forEach(ds => {
        procurementStatusObj[ds.key] = ds.value
    })

    const placeholderData = {
        waiting_approvals: data?.waiting_approvals || 0,
        waiting_issuance: data?.waiting_issuance || 0,
        uncompleted: data?.uncompleted || 0,
    }

    const remainingData = Object.entries(data).filter(d => {
        return !Object.keys(placeholderData).includes(d[0])
    }).sort((a, b) => b[1] - a[1])

    const RemainingContentTable = () => {
        return (
            <table className="w-full text-xs font-normal">
                <tbody>
                    {remainingData !== null && Array.isArray(remainingData) && remainingData.length > 0 ?
                        remainingData.map(rd => {
                            return (
                                <tr key={rd[0]} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">
                                        {procurementStatusObj[rd[0]]}
                                    </td>
                                    <td className="px-2 py-0.5 text-center">
                                        {rd[1]} Adet
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan={3}>Diğer bilgiler bulunamadı</td>
                        </tr>
                    }
                </tbody>
            </table>
        )
    }
    
    return (
        <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-y-4 lg:gap-x-4">
            <GreenNormal 
                title="Onay Bekleyenler"
                content={`${placeholderData.waiting_approvals} Adet`}
            />
            <OrangeNormal 
                title="Yayınlanmayı Bekleyenler"
                content={`${placeholderData.waiting_issuance} Adet`}
            />
            <RedNormal 
                title="Süreci Devam Edenler"
                content={`${placeholderData.uncompleted} Adet`}
            />
            <BlueNormal 
                title=""
                content={<RemainingContentTable />}
            />
        </div>
    )
}

export default TopCards