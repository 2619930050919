import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementUserCostType } from 'stores/DrawersSlice' 
import UserCostTypesForm from 'modules/companyModule/pages/companies/settings/procurements/UserCostTypesForm'

function UserCostTypes({update, setUpdate, costTypes, user}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenProcurementUserCostType)
    const setIsOpenCreate = () => dispatch(setIsOpenProcurementUserCostType())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Kullanıcı Gider Türlerini Düzenle" maxWidth='max-w-2xl'>
            <UserCostTypesForm 
                update={update} 
                setUpdate={setUpdate} 
                costTypes={costTypes}
                user={user}
            />
        </FormDrawer> 
    )
}

export default UserCostTypes