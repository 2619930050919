import { DateTime } from 'luxon'
import CompanyInfo from './CompanyInfo'
import Documents from './Documents'
import ContactUsers from './ContactUsers'
import BankAccounts from './BankAccounts'
import References from './References'
import Projects from './Projects'

function DetailInfo({supplier, documentList}) {
    

    return (
        <div className="">
                {(supplier !==null && Object.keys(supplier).length > 0) &&
                <>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                <CompanyInfo supplier={supplier} />
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold mt-4">Dokümanlar</p>
                <Documents supplierId={supplier.id} documents={supplier.documents} documentList={documentList} />
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold mt-4">Yetkili Kişiler</p>
                <ContactUsers users={supplier.contact_users} />
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold mt-4">Banka Hesapları</p>
                <BankAccounts accounts={supplier.bank_accounts} />
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold mt-4">Referanslar</p>
                <References references={supplier.references} />
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold mt-4">Yapılan Önemli Projeler</p>
                <Projects projects={supplier.projects} />
                </>}
            </div>  
    )
}

export default DetailInfo