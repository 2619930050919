import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenEdit } from 'modules/companyModule/pages/suppliers/settings/taxonomies/DrawersSlice' 
import Loader from 'elements/Loader'
import { useTaxonomyInput } from 'modules/companyModule/pages/suppliers/settings/taxonomies/InputArray'

const EditForm = ({taxonomy, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenCreate = useSelector(state => state.companySupplierTaxonomyDrawers.isOpenEdit)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            taxonomy_name: null,
            type: 'supplier',
        }
    })

    const inputs = useTaxonomyInput(errors)

    useEffect(() => {
        if(taxonomy !== null && Object.keys(taxonomy).length > 0) {
            setValue('taxonomy_name', taxonomy.taxonomy_name)          
            setValue('type', taxonomy.type)    
            setError(null)
            setStatus(false)
        } else {
            reset()
        }
    }, [taxonomy, isOpenCreate])

    useEffect(() => {
        if(!isOpenCreate) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenCreate])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/taxonomies/${taxonomy.id}`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenEdit(false))
    }   

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <div className="grid grid-cols-12 gap-2">
            {inputs.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        <InputSmall register={register} {...input} />
                    </div>
                    )
            })}                
            </div>
            <div className="w-full flex justify-end gap-x-1 mt-5">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose type="button" onClick={() => close()}>
                </ButtonClose>
            </div>                
        </form> 
        {loading && <Loader />}
        </>
    )
}

export default EditForm