export function useDocumentInputs(errors, docFileAttributes, setDocFileAttributes) {
    
    return [
        {
            key: "docInput1",
            type: "file",
            name: 'document',
            placeholder: "Dosya Seç",
            label: '',
            validator: {
                required: "Bu alan zorunludur.",
                validate: (value) => {
                    if(value[0].type !== "application/pdf") {
                        return 'PDF dosyası seçmelisiniz'
                    }
                    return true
                },
                onChange: (e) => {
                    let files = e.target.files
                    if(files.length > 0) {
                        setDocFileAttributes({
                            filename: files[0].name,
                            size: files[0].size,
                            type: files[0].type,
                        })
                    } else {
                        setDocFileAttributes({
                            filename: null,
                            size: null,
                            type: null,
                        })
                    }
                }
            },
            error: errors?.document?.message,
            notSelectedText: 'Dosya Seçilmedi',
            fileAttributes: docFileAttributes,
        }, 
    ]
}