import { useRoutes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NotFound from 'modules/commonModule/pages/notFound'
import ApprovalWithToken from 'modules/companyModule/pages/procurementProcesses/approvalWithToken'
import ProductDemandApprovalWithToken from 'modules/companyModule/pages/productDemands/approvalWithToken'
import WarehouseDemandApprovalWithToken from 'modules/companyModule/pages/warehouses/transferApprovals/ApprovalWithToken'
import DeliveryApprovalWithToken from 'modules/companyModule/pages/productDemands/demands/DeliveryApprovalWithToken'
import WarehouseProcurementRequestApprovalWithToken from 'modules/companyModule/pages/procurementRequestApprovals/warehouseRequests/AppovalWithToken'
import DepartmentProcurementRequestApprovalWithToken from 'modules/companyModule/pages/procurementRequestApprovals/departmentRequests/AppovalWithToken'
import CheckAuth from 'utils/CheckAuth'
import CheckEmailVerified from 'utils/CheckEmailVerified'
import CheckCompanySelected from 'utils/CheckCompanySelected'
import Main from 'layouts/Main'
import CompanyRoutes from 'routes/CompanyRoutes'
import SupplierRoutes from 'routes/SupplierRoutes'
import CompanyCompanyRoutes from 'routes/CompanyCompanyRoutes'
import SupplierCompanyRoutes from 'routes/SupplierCompanyRoutes'
import AuthRoutes from 'routes/AuthRoutes'


function App() {

  const userType = useSelector(state => state.user.userType)
  const user = useSelector(state => state.user)
  const authRoutes = AuthRoutes()
  let userRoutes = []
  let compRoutes = []

  if(userType === 'company') {
    userRoutes = CompanyRoutes()
    compRoutes = CompanyCompanyRoutes()
  }
  if(userType === 'supplier') {
    userRoutes = SupplierRoutes()
    compRoutes = SupplierCompanyRoutes()
  }
  if(userType !== 'company' && userType !== 'supplier') {
    userRoutes = []
    compRoutes = []
  }

  // useEffect(() => {
  //   if(userType === 'company') userRoutes = CompanyRoutes()
  //   if(userType === 'supplier') userRoutes = SupplierRoutes()
  // }, [userType])

  const notFoundRoute = {
      path: '*',
      element: <NotFound />
    }

  let routes = useRoutes([
    
    {
      path: '/',
      element:  <CheckAuth>
                  <CheckEmailVerified>
                    <CheckCompanySelected>
                      <Main />
                    </CheckCompanySelected>
                  </CheckEmailVerified>
                </CheckAuth>,
      children: [notFoundRoute, ...userRoutes]
    }, 
    {
      path: 'companies',
      element:  <CheckAuth>
                  <CheckEmailVerified>
                    <Main />
                  </CheckEmailVerified>
                </CheckAuth>,
      children: [...compRoutes]
    },     
    {
      path: '/approvals/:uuid',
      element: <ApprovalWithToken />
    },
    {
      path: '/product_demand_approvals/:uuid',
      element: <ProductDemandApprovalWithToken />
    },
    {
      path: '/warehouse_delivery_approvals/:uuid',
      element: <DeliveryApprovalWithToken />
    },
    {
      path: '/warehouse_transfer_approvals/:uuid',
      element: <WarehouseDemandApprovalWithToken />
    },
    {
      path: '/warehouse_procurement_request_approvals/:uuid',
      element: <WarehouseProcurementRequestApprovalWithToken />
    },
    {
      path: '/department_procurement_request_approvals/:uuid',
      element: <DepartmentProcurementRequestApprovalWithToken />
    },
    ...authRoutes,
  ])
  

  return routes
  
}

export default App;
