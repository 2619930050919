import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import { IconContext } from "react-icons"
import { FaSearch, FaTrashAlt, FaRegEdit, FaBusinessTime, FaCalendarCheck } from 'react-icons/fa'
import { setIsOpenProcurementDetail, setIsOpenProcurementStatusUpdate, setIsOpenProcurementEnddateUpdate, setIsOpenProcurementCancel, setIsOpenProcurementIssue } from 'stores/DrawersSlice'

function Actions({data}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const Button = ({label = 'Detaylar', icon = null, ...props}) => {
        return (
            <IconContext.Provider value={{ className: "h-3.5 w-3.5 flex-none" }}>
                <button type='button' className={`overflow-hidden font-semibold flex items-center justify-center gap-2 text-xs text-center px-2 py-2 rounded border border-teal-700 bg-white text-teal-700 hover:bg-teal-100`} {...props}>
                    {icon}
                    <span className="leading-none">{label}</span>
                </button>
            </IconContext.Provider>
        )
    }

    const CustomLinks = () => {
        if(data.status === 'rejected') return null
        if(data.status === 'waiting_approvals') {
            return (
                <> 
                <Button label='İptal Et' icon={<FaTrashAlt />} onClick={() => {dispatch(setIsOpenProcurementCancel(true))}}/>
                </>
            )
        }
        if(data.status === 'waiting_issuance') {
            return (
                <> 
                <Button label='İptal Et' icon={<FaTrashAlt />} onClick={() => {dispatch(setIsOpenProcurementCancel(true))}}/>
                <Button label='Yayına Al' icon={<FaCalendarCheck />} onClick={() => {dispatch(setIsOpenProcurementIssue(true))}}/>
                </>
            )
        }
        if(data.status === 'uncompleted') {
            return (
                <> 
                <Button label='Tarihleri Güncelle' icon={<FaBusinessTime />} onClick={() => {dispatch(setIsOpenProcurementEnddateUpdate(true))}}/>
                <Button label='İptal Et' icon={<FaTrashAlt />} onClick={() => {dispatch(setIsOpenProcurementCancel(true))}}/>
                </>
            )
        }
        if(data.status === 'completed') {
            return (
                <> 
                <Button label='Durumu Güncelle' icon={<FaRegEdit />} onClick={() => {dispatch(setIsOpenProcurementStatusUpdate(true))}}/>
               </>
            )
        }
        if(data.status === 'cancelled') return null
        if(data.status === 'archived') {
            return (
                <> 
                <Button label='Durumu Güncelle' icon={<FaRegEdit />} onClick={() => {dispatch(setIsOpenProcurementStatusUpdate(true))}}/>
                </>
            )
        }
    }



    return (
        <div className="w-full lg:w-auto lg:min-w-[10rem] flex flex-col gap-y-2">
            <Button label='Talep Detayları' icon={<FaSearch />} onClick={() => {dispatch(setIsOpenProcurementDetail(true))}}/>
            <CustomLinks />
        </div>
    )
}

export default Actions