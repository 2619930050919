import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenDepartmentEdit } from 'stores/DrawersSlice' 
import EditDepartmentForm from 'modules/companyModule/pages/companies/settings/departments/EditDepartmentForm'

function EditDepartment({update, setUpdate, department, setDepatment, parentId = null, departments}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenDepartmentEdit)
    const setIsOpenCreate = () => dispatch(setIsOpenDepartmentEdit())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Departmanı Düzenle">
            <EditDepartmentForm 
                update={update} 
                setUpdate={setUpdate} 
                department={department}
                setDepatment={setDepatment}
                departments={departments} 
                parentId={parentId}
            />
        </FormDrawer> 
    )
}

export default EditDepartment