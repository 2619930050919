import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ButtonEditSmall, ButtonNewSmall } from 'elements/buttons/actionButtons'
import { setIsOpenCostTypeCreate, setIsOpenProcurementApprovalScenario } from 'stores/DrawersSlice'
import CreateCostType from 'modules/companyModule/pages/companies/settings/costTypes/CreateCostType'
import CostTypeScenario from "./CostTypeScenario"

function CostTypes({costTypes, users, update, setUpdate}) {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [costTypeForScenario, setCostTypeForScenario] = useState(null) 

    const CostTypeCard = ({costType}) => {
        return (
        <div className='px-2 py-1 my-1 border border-sky-200 bg-sky-50 min-w-[12rem]'>
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-y-2">
                <div className="flex items-end justify-start gap-x-2">
                    <p className='lg:whitespace-nowrap font-semibold text-sm text-gray-700'>{costType.cost_name}</p>
                </div>                
                <div className='w-full flex flex-col lg:flex-row justify-end gap-y-1 lg:gap-x-1'>
                    <ButtonEditSmall label="Onay Listesi" onClick={() => { setCostTypeForScenario(costType); dispatch(setIsOpenProcurementApprovalScenario(true))}} />
                </div>
            </div>
        </div>
        )
    }

    const procurementUsers = users.filter(user => {
        return user.roles.filter(role => role.code === "procurements").length > 0
    })

    return (
        <div className="w-full mb-4">
            <div className="h-9 py-1 px-2 mb-4 border border-gray-500 bg-white font-semibold flex justify-between items-center">
                <h2 className="text-base text-gray-800">Gider Türü Onay Kullanıcıları</h2>
                <ButtonNewSmall label="Yeni Gider Türü" onClick={() => {dispatch(setIsOpenCostTypeCreate(true))}}>
                </ButtonNewSmall>
            </div>
            <div>
                {costTypes.map(ct  => {
                    return (
                        <div className="mb-2" key={ct.id}>
                            <CostTypeCard costType={ct} />
                        </div>
                    )
                })} 
            </div>
            <CreateCostType 
                update={update} 
                setUpdate={setUpdate} 
            />
            <CostTypeScenario 
                update={update} 
                setUpdate={setUpdate} 
                users={users}
                costType={costTypeForScenario}
            />
            
        </div>
    )
}

export default CostTypes