import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementCancel } from 'stores/DrawersSlice' 
import CancelForm from 'modules/companyModule/pages/procurementProcesses/procurements/CancelForm'

function Cancel({procurement, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCancel = useSelector(state => state.drawers.isOpenProcurementCancel)
    const setIsOpenCancel = () => dispatch(setIsOpenProcurementCancel())
    
    return(
        <FormDrawer isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} title="Satın Alma Talebini İptal Et" maxWidth="max-w-4xl">
            <CancelForm procurement={procurement} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Cancel