import { useSelector } from 'react-redux'

export function useChangePasswordInput(errors, getValues) {
    

    return [
        {
            key: "passwordInput1",
            type: "password",
            name: "current_password",
            placeholder: "Mevcut Şifre",
            label: "Mevcut Şifre *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},                
            },
            error: errors?.current_password?.message,
            cols: 'col-span-12',
        },
        {
            key: "passwordInput2",
            type: "password",
            name: "password",
            placeholder: "Şifre",
            label: "Şifre *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},                
            },
            error: errors?.password?.message,
            cols: 'col-span-12',
        },
        {
            key: "passwordInput3",
            type: "password",
            name: "password_confirmation",
            placeholder: "Şifre Tekrar",
            label: "Şifre Tekrar*",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},   
                validate: {
                    confirmation: v => v === getValues("password") || 'Girmiş olduğunuz şifreler eşleşmiyor'
                }              
            },
            error: errors?.password_confirmation?.message,
            cols: 'col-span-12',
        },
    ]
}

