const Input = ({type, name, placeholder, label, register, validator, error, addClass = null, readOnly = false}) => {
    const borderColor = error ? 'border-red-500' : 'border-gray-400'
    return (    
        <div className='w-full flex flex-col gap-y-0.5 items-start'>
            <label htmlFor={name} className={['text-gray-700 text-sm', addClass].join(' ')}>{label}</label>
            <input type={type} 
                name={name}
                placeholder={placeholder} 
                {...register(name, validator)}
                className={['form-input w-full rounded-sm px-2 py-2 text-sm border', borderColor].join(' ')} 
                readOnly={readOnly && 'readOnly'}/>
            <span className="text-xs text-red-600">{error}</span>
        </div>
    )
}

const InputSmall = ({type, name, placeholder, label, register, validator, error, addClass = null, readOnly = false}) => {
    const borderColor = error ? 'border-red-500' : 'border-gray-400'
    return (    
        <div className='w-full flex flex-col gap-y-0.5 items-start'>
            <label htmlFor={name} className={['text-gray-700 text-xs', addClass].join(' ')}>{label}</label>
            <input type={type} 
                name={name}
                placeholder={placeholder} 
                {...register(name, validator)}
                className={['form-input w-full rounded-sm px-1 py-1 text-xs border', borderColor].join(' ')} 
                readOnly={readOnly && 'readOnly'}/>
            <span className="text-xs text-red-600">{error}</span>
        </div>
    )
}

const InputFloatingLabel = ({type, name, register, label, validator, error = null}) => {
    return (
      <>
          <div className="relative z-0 w-full mt-6">
          <input type={type} 
              name={name} 
              {...register(name, validator)}
              placeholder=" " 
              className="pb-1 block w-full px-0 py-px mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-sky-500 border-sky-300" 
              
          />
          <label htmlFor={name} className="absolute duration-300 top-1 -z-1 origin-0 text-gray-500 mb-3">{label}</label>
          <p className="text-sm text-red-600">{error}</p>
          </div>
          
      </>
    )
}

const SelectFloatingLabel = ({type, name, placeholder, label, register, validator, error, addClass = null, readOnly = false, data}) => {
    return (    
        <div className='flex flex-col gap-y-0.5 items-start'>
            <label htmlFor={name} className={['text-gray-500 focus:text-black text-xs', addClass].join(' ')}>{label}</label>
            <select 
                name={name}
                placeholder={placeholder} 
                {...register(name, validator)}
                className='form-input w-full px-0 py-px bg-transparent border-0 border-b-2 focus:outline-none focus:ring-0 focus:border-sky-500 border-sky-300'
                readOnly={readOnly && 'readOnly'}>
                  <option key="0" value="">Seçiniz</option>  
                  {(data !== null && data !== undefined) && data.map(data => {
                    return <option key={data.key} value={data.key}>{data.value}</option>
                  })}
              </select>
            <span className="text-xs text-red-600">{error}</span>
        </div>
    )
}

const SelectSmall = ({type, name, placeholder, label, register, validator, error, addClass = null, readOnly = false, defaultOption = 'Seçiniz', data}) => {
    const borderColor = error ? 'border-red-500' : 'border-gray-400'
    return (    
        <div className='flex flex-col gap-y-0.5 items-start'>
            <label htmlFor={name} className={['text-gray-700 text-xs', addClass].join(' ')}>{label}</label>
            <select 
                name={name}
                placeholder={placeholder} 
                {...register(name, validator)}
                className={['form-input w-full rounded-sm px-1 py-[0.3rem] text-xs border', borderColor].join(' ')} 
                readOnly={readOnly && 'readOnly'}>
                    {defaultOption !== null ? 
                        <option key="0" value="">{defaultOption}</option> 
                    :
                    null
                    }
                   
                  {(data !== null && data !== undefined) && data.map(data => {
                    return <option key={data.key} value={data.key}>{data.value}</option>
                  })}
              </select>
            <span className="text-xs text-red-600">{error}</span>
        </div>
    )
}

const Checkbox = ({type = 'checkbox', name, register, label, validator, error = null}) => {    
    return (
        <div className="block pt-3 pb-2">
            <label htmlFor={name} className="flex text-gray-700 items-center justify-start cursor-pointer">
                <input 
                type={type}
                {...register(name, validator)}
                id={name}
                name={name}
                className="form-checkbox mr-2 text-sky-500 border-2 border-sky-300 rounded focus:outline-none focus:shadow-none focus:ring-0 focus:ring-transparent"
                />
                <span>{label}</span>                
            </label>
            <span className="text-xs text-red-600">{error}</span>
        </div>
    )
}

const CheckboxSmall = ({type = 'checkbox', name, register, label, validator, error = null, addClass = null}) => {    
    return (
        <div className="block pt-px pb-px">
            <label htmlFor={name} className={["flex text-gray-700 items-center justify-start cursor-pointer", addClass].join(' ')}>
                <input 
                type={type}
                {...register(name, validator)}
                id={name}
                name={name}
                className="form-checkbox mr-2 text-sky-500 border-2 border-sky-300 rounded focus:outline-none focus:shadow-none focus:ring-0 focus:ring-transparent"
                />
                <span>{label}</span>
                
            </label>
        </div>
    )
}

const Radio = ({type = 'radio', name, register, label, validator, error = null, data}) => {    
    return (
        <div className="block pt-3 pb-2 space-x-4">
            <p className="ml-4 mb-1 border-b-2 border-sky-300">{label}</p>
            {(data && Array.isArray(data)) ? 
            data.map(item => {
                return (
                    <label key={item.key} htmlFor={name + '_' + item.key} className="flex text-gray-700 items-center justify-start cursor-pointer">
                        <input 
                        type={type}
                        {...register(name, validator)}
                        id={name + '_' + item.key}
                        name={name}
                        value={item.key}
                        className="form-radio mr-2 text-sky-500 border-2 border-sky-300 rounded-full focus:outline-none focus:shadow-none focus:ring-0 focus:ring-transparent"
                        />
                        <span>{item.value}</span>
                        
                    </label>
                )
            })            
            : null}     
            <span className="text-xs text-red-600">{error}</span>       
        </div>
    )
}

const TextareaSmall = ({type, name, placeholder, label, register, validator, error, addClass = null, readOnly = false, rows = 3}) => {
    const borderColor = error ? 'border-red-500' : 'border-gray-400'
    return (    
        <div className='w-full flex flex-col gap-y-0.5 items-start'>
            <label htmlFor={name} className={['text-gray-700 text-xs', addClass].join(' ')}>{label}</label>
            <textarea type={type} 
                rows={rows}
                name={name}
                placeholder={placeholder} 
                {...register(name, validator)}
                className={['form-input w-full rounded-sm px-1 py-1 text-xs border', borderColor].join(' ')} 
                readOnly={readOnly && 'readOnly'}>
            </textarea>
            <span className="text-xs text-red-600">{error}</span>
        </div>
    )
}

const FileSmall = ({type, 
                    name, 
                    placeholder = 'Dosya Seç', 
                    label, 
                    register, 
                    validator, 
                    error, 
                    notSelectedText = 'Dosya Seçilmedi', 
                    fileAttributes = null, 
                    addClass = null, 
                    readOnly = false}) => {
    const borderColor = error ? 'border-red-500' : 'border-gray-400'
    return (   
        <>
            <div className="w-full">
                <span className="text-gray-700 text-xs">{label}</span>
                <div className="w-full flex mt-1">
                    <label className="block w-full flex items-center cursor-pointer">
                        <p className={['flex-none bg-gray-300 border px-2 py-1 rounded-l', borderColor].join(' ')}>
                            {placeholder}
                        </p>
                        <p className={["flex-grow bg-white border px-2 py-1 rounded-r", borderColor].join(' ')}>
                        {fileAttributes !== null && fileAttributes.filename !== null ? 
                            'Dosya: ' + fileAttributes.filename
                            : 
                            <span className="text-red-600 font-semibold">{notSelectedText}</span>
                        }
                        </p>
                        <input type={type} 
                                name={name}
                                {...register(name, validator)}
                                className="hidden"
                        />
                    </label>
                </div>
                <span className="text-xs text-red-600">{error}</span>
            </div>   
            
        </> 
        
    )
}

export {
    Input,
    InputSmall,
    InputFloatingLabel,
    SelectFloatingLabel,
    SelectSmall,
    Checkbox,
    CheckboxSmall,
    Radio,
    TextareaSmall,
    FileSmall
}