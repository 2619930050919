import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false
}

export const mobileMenuSlice = createSlice({
    name: 'mobileMenu',
    initialState,
    reducers: {
        toggle: (state, action) => {
            if(action.payload === false) {
                state.isOpen = false
            } else if(action.payload === true) {
                state.isOpen = true
            } else {
                state.isOpen = !state.isOpen
            }
        }
    }
})

export const {toggle} = mobileMenuSlice.actions

export default mobileMenuSlice.reducer