import React from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import { IconContext } from "react-icons"
import { FaSearch, FaEnvelopeOpenText, FaSlidersH } from 'react-icons/fa'
import { setIsOpenProcurementRequestWarehouseDetail, setIsOpenProcurementRequestWarehouseUpdateProcurement } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector(state => state.user.user.id)
    const acceptedStatus = ['waiting_procurement', 'procurement_in_progress', 'waiting_delivery']

    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton 
                    title='Talep Detayları' icon={<FaSearch />} 
                    onClick={() => {
                        actions.setProcurementRequestsForDetail(data)
                        dispatch(setIsOpenProcurementRequestWarehouseDetail(true))
                        }}
                />
                {(acceptedStatus.includes(data.status)) ?
                <> 
                <TableButton 
                    title='Satın Alma İşlemleri' 
                    icon={<FaEnvelopeOpenText />} 
                    borderTop={true} 
                    onClick={() => {
                        actions.setProcurementRequestsForUpdateProcurement(data)
                        dispatch(setIsOpenProcurementRequestWarehouseUpdateProcurement(true))
                        }}
                    />
                </>
                : null }
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
