import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import { FaFolderPlus } from 'react-icons/fa'
import TopCards from 'modules/companyModule/pages/home/warehouses/procurementRequests/TopCards'
import StatusChart from 'modules/companyModule/pages/home/warehouses/procurementRequests/StatusChart'
import WarehouseLogs from 'modules/companyModule/pages/home/warehouses/procurementRequests/WarehouseLogs'
import LatestRequests from 'modules/companyModule/pages/home/warehouses/procurementRequests/LatestRequests'
import Header from 'modules/companyModule/pages/home/Header'

function ProcuementRequests({selectedWarehouse, selectOptions}) {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/statistics/warehouse_procurement_requests/${selectedWarehouse}`)
                .then(response => {
                    if(response.status === 200) {
                        setData(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if(selectedWarehouse) getData()        
    }, [updateData, selectedWarehouse])

    return (
        <div className="w-full">
            <Header title="Depo - Satın Alma Talebi" logo={<FaFolderPlus />} selectOptions={selectOptions} />
            {selectOptions.value !== '' ? 
            <>
            <div className="mb-2">
                <TopCards 
                    data={data}
                    update={updateData}
                    setUpdate={setUpdateData}  
                    loading={loading}                  
                />
            </div>
            <div>
                <StatusChart 
                    data={data}
                    update={updateData}
                    setUpdate={setUpdateData}  
                    loading={loading}                  
                />
            </div>     
            </>
            : 
            <div className="">
                <p className="text-lg font-semibold text-red-600">Lütfen verileri görebilmek için bir depo seçin</p>
            </div>
            }       
        </div>
    )
}

export default ProcuementRequests