import { useSearchParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { DateTime } from 'luxon'
import { ButtonFilter, ButtonExcel } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import Table from 'modules/companyModule/pages/reports/businessAreas/Table'
import TableFilter from 'modules/companyModule/pages/reports/businessAreas/TableFilter'
import ExportXslx from 'modules/companyModule/pages/reports/businessAreas/ExportXslx'
import Loader from 'elements/Loader'

function BusinessAreas(){
    const dispatch = useDispatch()
    const [businessAreas, setBusinessAreas] = useState([])
    const [loading, setLoading] = useState(false)

    const companyId = useSelector(state => state.company.selectedCompany.id)

    const initalFilter = {
        start_date: DateTime.now().minus({ months: 1 }).toISODate(),
        end_date: DateTime.now().toISODate(),
    }
        
    const [updateData, setUpdateData] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState(initalFilter)

    useEffect(() => {
        const getSuppliers = async () => {
            let filterParam = new URLSearchParams(filters).toString()
            
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/reports/business_areas?${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setBusinessAreas(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getSuppliers()
    }, [updateData, filters])

    const handleExport = async () => {
        ExportXslx(businessAreas, `Sektör Raporu ${dateCell(filters.start_date)} - ${dateCell(filters.end_date)}`)       
    }

    const dateCell = (date) => {
        let parsedDate = DateTime.fromISO(date)
        
        return parsedDate.isValid ? parsedDate.toFormat('dd.LL.yyyy') : null
    }

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">
                    Satın Alma Sektörü Raporu ({dateCell(filters.start_date)} - {dateCell(filters.end_date)})
                </h2>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonExcel addClass='w-full md:w-auto' onClick={handleExport}>
                </ButtonExcel>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                />

                <Table data={businessAreas} 
                        progressPending={loading}
                        pagination={null}
                        pageMeta={null}
                    /> 

            </div>
            {loading && <Loader />}
        </div>
    )
  }
  
  export default BusinessAreas