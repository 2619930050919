import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductDemandCancel } from 'stores/DrawersSlice' 
import CancelForm from 'modules/companyModule/pages/productDemands/demands/CancelForm'

function Cancel({productDemand, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCancel = useSelector(state => state.drawers.isOpenProductDemandCancel)
    const setIsOpenCancel = () => dispatch(setIsOpenProductDemandCancel())
    
    return(
        <FormDrawer isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} title="Ürün Talebini İptal Et" maxWidth="max-w-4xl">
            <CancelForm productDemand={productDemand} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Cancel