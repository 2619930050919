import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Tree, TreeNode } from 'react-organizational-chart'
import { IconContext } from "react-icons"
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'
import { ButtonEditSmall, ButtonNewSmall, ButtonNew, ButtonDetailSmall } from 'elements/buttons/actionButtons'
import { setIsOpenDepartmentCreate, 
        setIsOpenDepartmentEdit, 
        setIsOpenDepartmentAddDemandAuth, 
        setIsOpenDepartmentAddUsers,
        setIsOpenDepartmentProcurementScenario
        } from '../../../../../../stores/DrawersSlice'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import CreateDepartment from 'modules/companyModule/pages/companies/settings/departments/CreateDepartment'
import EditDepartment from 'modules/companyModule/pages/companies/settings/departments/EditDepartment'
import EditAuthDepartment from 'modules/companyModule/pages/companies/settings/departments/EditAuthDepartment'
import EditDepartmentUsers from 'modules/companyModule/pages/companies/settings/departments/EditDepartmentUsers'
import EditDepartmentProcurementRequests from 'modules/companyModule/pages/companies/settings/departments/EditDepartmentProcurementRequests'
import TableActions from 'modules/companyModule/pages/companies/settings/departments/TableActions'

const DepartmentSettings = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [departments, setDepartments] = useState([])
    const [positions, setPositions] = useState([])
    const [users, setUsers] = useState([])
    const [parent, setParent] = useState(null)
    const [parentIdForEdit, setParentIdForEdit] = useState(null)
    const [loading, setLoading] = useState(false)
    const [departmentForEdit, setDepartmentForEdit] = useState(null)
    const [departmentFlatList, setDepartmentFlatList] = useState(null)
    const [departmentForAuth, setDepartmentForAuth] = useState(null)
    const [departmentForUsers, setDepartmentForUsers] = useState(null)
    const [departmentForProcurementRequestScenario, setDepartmentForProcurementRequestScenario] = useState(null)

    const [expandedNodes, setExpandedNodes] = useState([])

    const handleNodeToggle = (key) => {
        if (expandedNodes.includes(key)) {
        setExpandedNodes(expandedNodes.filter(nodeId => nodeId !== key))
        } else {
        setExpandedNodes([...expandedNodes, key])
        }
    }

    useEffect(() => {
        const getDepartments = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/departments`)
                .then(response => {
                    if(response.status === 200) {
                        setDepartments(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getDepartments()
    }, [updateData])

    useEffect(() => {
        const getPositions = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/positions`)
                .then(response => {
                    if(response.status === 200) {
                        const porsitionArray = response.data.data.map(item => {
                            return {key: item.id, value: `${item.position_name} (Seviye: ${item.level})`}
                        })
                        setPositions(porsitionArray)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        
        const getUsers = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/users?no_pagination=true&is_active=true`)
                .then(response => {
                    if(response.status === 200) {
                        const userArray = response.data.data.map(item => {
                            return {key: item.id, value: item.name === '' ? item.email : item.name}
                        })
                        setUsers(userArray)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getPositions()
        getUsers()
    }, [])

    useEffect(() => {
        let flat_list = []
        const add_list = (dep) => {
            flat_list.push({id: dep.id, department_name: dep.department_name})
            if(dep?.all_sub_departments && dep.all_sub_departments.length > 0) {
                dep.all_sub_departments.forEach(d => {
                    add_list(d)
                })                
            }
        }
        departments.forEach(d => {
            add_list(d)
        })
        setDepartmentFlatList(flat_list)
    }, [departments])

    const ExpandButton = ({subNodes, isExpanded, nodeId}) => {
        if(subNodes && subNodes.length > 0 ) {
            return (
                <button onClick={() => handleNodeToggle(nodeId)} type="button" className="flex-none border border-gray-400 rounded text-gray-500 bg-white">
                    <IconContext.Provider value={{ className: "h-5 w-5" }}>
                    <span>
                        {isExpanded ? <HiChevronRight /> : <HiChevronDown/>}                        
                    </span>
                    </IconContext.Provider>
                </button>
            )
        } else {
            return <span className='inline-block w-5'></span>
        }
    } 

    const DepartmenCards = ({deps, parentId}) => {
        
        return deps.map(dep => {
            const isNodeExpanded = expandedNodes.includes(dep.id)
            const leftMargin = parentId !== null ? "ml-6 lg:ml-6" : ''
            
            return (
                <div key={dep.id} className={leftMargin}>
                    <div className="flex items-center justify-start gap-x-2">
                        <div className="flex-none">
                            <ExpandButton subNodes={dep?.all_sub_departments} isExpanded={isNodeExpanded} nodeId={dep.id} />
                        </div>                        
                        <div className="px-2 py-1 my-1 border border-sky-200 bg-sky-50 min-w-[12rem] flex-grow">
                            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-y-2">
                                <p className='lg:whitespace-nowrap font-semibold text-sm text-gray-700'>{dep.department_name}</p>
                                <div className='w-full flex flex-col lg:flex-row justify-end gap-y-1 lg:gap-x-1'>
                                    <TableActions 
                                        data={dep}
                                        actions = {{
                                            setDepartmentForProcurementRequestScenario: setDepartmentForProcurementRequestScenario,
                                            setDepartmentForAuth: setDepartmentForAuth,
                                            setDepartmentForUsers: setDepartmentForUsers,
                                            setParentIdForEdit: setParentIdForEdit,
                                            setDepartmentForEdit: setDepartmentForEdit,
                                            setParent: setParent
                                        }}
                                        parentId={parentId}
                                    />
                                </div>
                            </div>                        
                        </div>
                    </div>
                    {(dep?.all_sub_departments && dep.all_sub_departments.length > 0 && isNodeExpanded) ? 
                        <DepartmenCards parentId={dep.id} deps={dep.all_sub_departments} key={dep.id}/>
                        : null}
                </div>
            )
        })
    }

    const data = []

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800">Departmanlar</h2>
                <ButtonNew label="Yeni Ana Departman" onClick={() => {setParent(null); dispatch(setIsOpenDepartmentCreate(true))}}>
                </ButtonNew>
            </div>
            <div className="w-full pb-4">
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-2 md:gap-x-4">
                    <div className="col-span-1">
                        <DepartmenCards deps={departments} parentId={null} />
                    </div>
                    <div className="col-span-1">
                        
                    </div>
                </div>                
            </div>
            
            <CreateDepartment 
                update={updateData} 
                setUpdate={setUpdateData} 
                parent={parent} 
                setParent={setParent} 
            />
            <EditDepartment 
                update={updateData} 
                setUpdate={setUpdateData} 
                department={departmentForEdit} 
                setDepartment={setDepartmentForEdit} 
                departments={departmentFlatList} 
                parentId={parentIdForEdit}
            />
            <EditAuthDepartment 
                update={updateData} 
                setUpdate={setUpdateData} 
                department={departmentForAuth}
            />
            <EditDepartmentUsers 
                department={departmentForUsers}
                users={users}
                positions={positions}
                update={updateData} 
                setUpdate={setUpdateData} 
                
            />
            <EditDepartmentProcurementRequests 
                department={departmentForProcurementRequestScenario}
                users={users}
                positions={positions}
                update={updateData} 
                setUpdate={setUpdateData} 
                
            />
            {loading && <Loader />}
        </div>
    )
}

export default DepartmentSettings