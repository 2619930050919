import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { login } from 'stores/UserSlice'
import { setCompanies, setSelectedCompany, setIsSelected } from 'stores/CompanySlice'
import {Checkbox, Input} from 'elements/inputs'
import {ButtonBlue} from 'elements/buttons'
import {ServerError} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import {LinkGray, LinkBlue} from 'elements/links'
import googleLogo from 'assets/img/google_48x48.png'
import msofficeLogo from 'assets/img/office_48x48.png'

const LoginForm = () => {

    const dispatch = useDispatch()
    const company = useSelector(state => state.company)
    const [loginError, setLoginError] = useState(null)
    const [googleRedirectUrl, setGoogleRedirectUrl] = useState(null)
    const [azureRedirectUrl, setAzureRedirectUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: null,
            password: null,
            remember: false,
        }
    })

    const loginInputs = [
        {
            key: "userInput1",
            type: "text",
            name: "email",
            placeholder: "E-Posta Adresi",
            label: "E-Posta Adresi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message,
            cols: 'col-span-12', 
        },
        {
            key: "userInput2",
            type: "password",
            name: "password",
            placeholder: "Şifre",
            label: "Şifre *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"}
            },
            error: errors?.password?.message,
            cols: 'col-span-12',
        },
    ]

    useEffect(() => {
        const getGoogleUrl = async () => {
            setLoading(true)
            await SendRequest('GET', `login/google/get_redirect_link`)
                .then(response => {
                    if(response.status === 200) {
                        setGoogleRedirectUrl(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        const getAzureUrl = async () => {
            setLoading(true)
            await SendRequest('GET', `login/azure/get_redirect_link`)
                .then(response => {
                    if(response.status === 200) {
                        setAzureRedirectUrl(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getGoogleUrl()
        getAzureUrl()
    }, [])

    const handleUserForm = async (data) => {
        await SendRequest('POST', 'login', data)
        .then(res => {
            if (res.status === 204) {                
                SendRequest('GET', 'api/user')
                .then(res => {
                    dispatch(login({
                        user: res.data.data, 
                        authenticated: true, 
                        emailVerified: res.data.data.verified, 
                        userType: res.data.data.type
                    }))                  
                })                
            }
        })
        .catch(err => {
            setLoginError(err.response.data)
        })
    }

    return (
        <div className="px-4 pb-4">
            <div className="text-red-500 mt-4">
                {loginError && <ServerError errorData={loginError} />}
            </div>
            <form onSubmit={handleSubmit(handleUserForm)}>
                <div className="grid grid-cols-12 gap-3">
                {loginInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <Input 
                                type={input.type}
                                name={input.name}
                                register={register}
                                label={input.label}
                                placeholder={input.placeholder}
                                validator={input.validator}
                                error={input.error}
                            />
                        </div>
                        )
                })}
                </div>
                <div className="flex justify-between items-center">
                    <Checkbox name="remember" label="Beni Hatırla" register={register} />                    
                </div>
                <div className="w-full flex flex-col mb-2">
                    <ButtonBlue type="submit">Giriş Yap</ButtonBlue>
                </div>                
                <div className="w-full flex flex-col items-center gap-y-2">
                    <LinkGray route="/forgot-password" title="Şifremi Unuttum" />
                </div>    
                <div className="w-full h-px border-t border-gray-300 my-4"></div>
                <div className="w-full flex flex-col gap-y-2">
                    <a href={googleRedirectUrl} className="w-full flex items-center justify-center gap-x-2 p-1 border border-gray-300">
                        <img src={googleLogo} className="h-5" />
                        <span className="text-sm text-gray-700 font-semibold">
                        Google İle Giriş Yap    
                        </span>
                    </a>  
                    <a href={azureRedirectUrl} className="w-full flex items-center justify-center gap-x-2 p-1 border border-gray-300">
                        <img src={msofficeLogo} className="h-5" />
                        <span className="text-sm text-gray-700 font-semibold">
                        MS Office İle Giriş Yap    
                        </span>
                    </a>  
                </div>         
            </form>             
        </div>
    )

}

export default LoginForm