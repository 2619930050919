import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import ProjectTable from 'modules/supplierModule/pages/companies/projects/ProjectTable'
import { ButtonNew, ButtonNewSmall } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierProjectCreate } from 'stores/DrawersSlice'
import Create from 'modules/supplierModule/pages/companies/projects/Create'
import Edit from 'modules/supplierModule/pages/companies/projects/Edit'
import Delete from 'modules/supplierModule/pages/companies/projects/Delete'
import Loader from 'elements/Loader'
import Header from 'modules/supplierModule/pages/companies/Header'

const Projects = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [projects, setProjects] = useState([])
    const [projectForEdit, setProjectForEdit] = useState([])
    const [projectForDelete, setProjectForDelete] = useState([])

    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)

    useEffect(() => {
        const getProjects = async () => {
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/projects`)
                .then(response => {
                    if(response.status === 200) {
                        setProjects(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProjects()
    }, [updateData])

    const CreateButton = () => {
        return (
            <ButtonNewSmall label="Yeni" onClick={() => {dispatch(setIsOpenSupplierProjectCreate(true))}} >
            </ButtonNewSmall>
        )
    }

    return (
        <div className="w-full mb-4">            
            <div className="">
                <Header title="Yapılan Önemli Proje Bilgileri" button={<CreateButton />} />
                <ProjectTable 
                    projects={projects} 
                    actions={{
                        setProjectForEdit: setProjectForEdit,
                        setProjectForDelete: setProjectForDelete
                    }}
                />
            </div>
            <Create update={updateData} setUpdate={setUpdateData} />
            <Edit project={projectForEdit} update={updateData} setUpdate={setUpdateData} />
            <Delete project={projectForDelete} update={updateData} setUpdate={setUpdateData} />
            {loading && <Loader />}
        </div>
    )
}

export default Projects