import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import ContactTable from 'modules/supplierModule/pages/companies/contacts/ContactTable'
import { ButtonNew, ButtonNewSmall } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierContactCreate } from 'stores/DrawersSlice'
import Create from 'modules/supplierModule/pages/companies/contacts/Create'
import Edit from 'modules/supplierModule/pages/companies/contacts/Edit'
import Delete from 'modules/supplierModule/pages/companies/contacts/Delete'
import Loader from 'elements/Loader'
import Header from 'modules/supplierModule/pages/companies/Header'

const Contacts = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [contacts, setContacts] = useState([])
    const [contactForEdit, setContactForEdit] = useState([])
    const [contactForDelete, setContactForDelete] = useState([])

    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)

    useEffect(() => {
        const getContacts = async () => {
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/contacts`)
                .then(response => {
                    if(response.status === 200) {
                        setContacts(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getContacts()
    }, [updateData])

    const CreateButton = () => {
        return (
            <ButtonNewSmall label="Yeni" onClick={() => {dispatch(setIsOpenSupplierContactCreate(true))}} >
            </ButtonNewSmall>
        )
    }

    return (
        <div className="w-full mb-4">            
            <div className="">
                <Header title="Yetkili Kişi Bilgileri" button={<CreateButton />} />
                <ContactTable 
                    contacts={contacts} 
                    actions={{
                        setContactForDelete: setContactForDelete,
                        setContactForEdit: setContactForEdit
                    }}
                />
            </div>
            <Create update={updateData} setUpdate={setUpdateData} />
            <Edit contact={contactForEdit} update={updateData} setUpdate={setUpdateData} />
            <Delete contact={contactForDelete} update={updateData} setUpdate={setUpdateData} />
            {loading && <Loader />}
        </div>
    )
}

export default Contacts