import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import ReferenceTable from 'modules/supplierModule/pages/companies/references/ReferenceTable'
import { ButtonNew, ButtonNewSmall } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierReferenceCreate } from 'stores/DrawersSlice'
import Create from 'modules/supplierModule/pages/companies/references/Create'
import Edit from 'modules/supplierModule/pages/companies/references/Edit'
import Delete from 'modules/supplierModule/pages/companies/references/Delete'
import Loader from 'elements/Loader'
import Header from 'modules/supplierModule/pages/companies/Header'

const References = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [references, setReferences] = useState([])
    const [referenceForEdit, setReferenceForEdit] = useState([])
    const [referenceForDelete, setReferenceForDelete] = useState([])

    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)

    useEffect(() => {
        const getReferences = async () => {
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/references`)
                .then(response => {
                    if(response.status === 200) {
                        setReferences(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getReferences()
    }, [updateData])

    const CreateButton = () => {
        return (
            <ButtonNewSmall label="Yeni" onClick={() => {dispatch(setIsOpenSupplierReferenceCreate(true))}} >
            </ButtonNewSmall>
        )
    }

    return (
        <div className="w-full mb-4">            
            <div className="">
                <Header title="Referans Firma Bilgileri" button={<CreateButton />} />
                <ReferenceTable 
                    references={references} 
                    actions={{
                        setReferenceForEdit: setReferenceForEdit,
                        setReferenceForDelete: setReferenceForDelete
                    }}
                />
            </div>
            <Create update={updateData} setUpdate={setUpdateData} />
            <Edit reference={referenceForEdit} update={updateData} setUpdate={setUpdateData} />
            <Delete reference={referenceForDelete} update={updateData} setUpdate={setUpdateData} />
            {loading && <Loader />}
        </div>
    )
}

export default References