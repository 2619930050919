import { useDispatch } from 'react-redux' 
import { IconContext } from "react-icons"
import { MdOutlineConfirmationNumber } from 'react-icons/md'
import { BsCardHeading } from 'react-icons/bs'
import { BiCategoryAlt, BiListOl, BiFile, BiBarcodeReader } from 'react-icons/bi'
import { GrMapLocation } from 'react-icons/gr'
import { ButtonDetailSmall } from 'elements/buttons/actionButtons'
import { setIsOpenProductDetail } from 'stores/DrawersSlice'

function ProductInfo({product}) {
    const dispatch = useDispatch()
    if(product) {
        return (
            <IconContext.Provider value={{ className: "h-4 w-4" }}>
                <div className="lg:flex flex-col justify-between gap-0 bg-white text-gray-700 px-4 py-2 flex-grow-0 text-xs border border-gray-400">
                    <p className="flex items-center justify-start gap-x-2">
                        <BsCardHeading />
                        <span className="font-semibold whitespace-nowrap">Ürün Adı:</span> 
                        <span>{product.name}</span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <BiCategoryAlt />
                        <span className="font-semibold whitespace-nowrap">Ürün Türü:</span> 
                        <span className=" whitespace-nowrap">{product.product_type.name}</span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <BiCategoryAlt />
                        <span className="font-semibold whitespace-nowrap">Gider Türü:</span> 
                        <span>{product.cost_type?.cost_name || 'Belirlenmedi'}</span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <MdOutlineConfirmationNumber />
                        <span className="font-semibold whitespace-nowrap">Ürün Kodu:</span> 
                        <span>{product.product_code}</span>
                    </p>
                    <div className='w-full flex justify-end'>
                        <ButtonDetailSmall onClick={() => dispatch(setIsOpenProductDetail(true))} />
                    </div>
                </div>
            </IconContext.Provider>
        )
    }

    return null
    
}

export default ProductInfo