import { useLocation, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Loader from 'elements/Loader'

const CheckAuth = ({children}) => {
    let location = useLocation()
    const authenticated = useSelector(state => state.user.authenticated)
    const emailVerified = useSelector(state => state.user.emailVerified)
    
    if(authenticated === true) {   
        return children
    } else if(authenticated === false) {
        return <Navigate to="/login" state={{ from: location }} replace />
    } else {
        return <Loader />
    }
    
}

export default CheckAuth