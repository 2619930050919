import InputMask from "react-input-mask"

function PhoneWithFloatingLabel({type, name, register, label, validator, error = null}) {
    
    const CustomInput = () => {
        return null
    }

    return (
        <>
            <div className="relative z-0 w-full mt-6">
            <InputMask 
                mask="+\90 (999) 999-99-99" 
                name={name} 
                {...register(name, validator)}
                placeholder=" " 
                className="pb-1 block w-full px-0 py-px mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-sky-500 border-sky-300" 
            />
            <label htmlFor={name} className="absolute duration-300 top-1 -z-1 origin-0 text-gray-500 mb-3">{label}</label>
            <p className="text-sm text-red-600">{error}</p>
            </div>
          
      </>
        

    )
}

const PhoneSmall = ({type, name, placeholder, label, register, validator, error, addClass = null, readOnly = false}) => {
    const borderColor = error ? 'border-red-500' : 'border-gray-400'
    return (    
        <div className='w-full flex flex-col gap-y-0.5 items-start'>
            <label htmlFor={name} className={['text-gray-700 text-xs', addClass].join(' ')}>{label}</label>
            <InputMask
                mask="+\90 (999) 999-99-99"
                name={name}
                placeholder={placeholder} 
                {...register(name, validator)}
                className={['form-input w-full rounded-sm px-1 py-1 text-xs border', borderColor].join(' ')} 
                readOnly={readOnly && 'readOnly'}/>
            <span className="text-xs text-red-600">{error}</span>
        </div>
    )
}

export {PhoneWithFloatingLabel, PhoneSmall}