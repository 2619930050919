import SupplierCompanies from 'modules/supplierModule/pages/companies'
import SupplierCompanySettings from 'modules/supplierModule/pages/companies/settings'

function SupplierCompanyRoutes()
{
    return [
        {
          path: '',
          element: <SupplierCompanies />
        }
      ]
}

export default SupplierCompanyRoutes