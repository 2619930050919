import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'

function ExchangeRates() {
    const rates = useSelector(state => state.sharedData.exchangeRates)
    const rateSelect = rates ? rates.map(r => {
        return {key: r.id, value: r.code}
    }) : null
    const [currencyId, setCurrencyId] = useState('1')

    const [selectedCurrency, setSelectedCurrency] = useState(null)

    useEffect(() => {
        if(rates) setSelectedCurrency(rates.find(r => r.id.toString() === currencyId.toString()))           
    }, [currencyId, rates])

    const handleChangeFilter = (e) => {
        const {value} = e.target
        setCurrencyId(value)     
    }    

    return (
        <div className="w-full h-28 bg-white border border-slate-800 p-2">
            <div className="w-full flex items-center justify-between pb-1 border-b border-gray-300">
                <p className="font-semibold">Döviz Kurları</p>
                <div className='flex flex-col gap-y-0.5 items-start'>
                    <select 
                        className='form-input w-full min-w-[4rem] rounded-sm px-1 py-[0.3rem] text-xs border border-gray-400'
                        name='currency'
                        value={currencyId}                            
                        onChange={(e) => handleChangeFilter(e)} 
                    >
                        { (rateSelect !== null && rateSelect !== undefined) && rateSelect.map(data => {
                            return <option key={data.key} value={data.key}>{data.value}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="mt-2">
                <table className="w-full text-gray-700">
                    <thead>
                        <tr className="border-b border-gray-200 text-center">
                            <td>Döviz Alış</td>
                            <td>Döviz Satış</td>
                            <td>Efektif Alış</td>
                            <td>Efektif Satış</td>
                        </tr>
                    </thead>
                    <tbody>
                    {selectedCurrency ? 
                        <tr className="text-center">                            
                            <td className="py-1">{selectedCurrency.forexBuying}</td>
                            <td className="py-1">{selectedCurrency.forexSelling}</td>
                            <td className="py-1">{selectedCurrency.banknoteBuying}</td>
                            <td className="py-1">{selectedCurrency.banknoteSelling}</td>                            
                        </tr>
                        : null }
                    </tbody>
                </table>
            </div>
            <div>
                {selectedCurrency ? 
                <p className="text-gray-400 text-[0.6rem] ml-2 mt-1">* {DateTime.fromISO(selectedCurrency.currencyDate).setLocale("tr").toLocaleString(DateTime.DATE_MED)} tarihli, TCMB döviz kurudur.</p>
                : null }
            </div>
        </div>
    )

}

export default ExchangeRates