import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProcurementRequestCancel } from 'stores/DrawersSlice' 
import CancelForm from 'modules/companyModule/pages/warehouses/procurementRequests/CancelForm'

function Cancel({procurementRequest, selectedWarehouse, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCancel = useSelector(state => state.drawers.isOpenWarehouseProcurementRequestCancel)
    const setIsOpenCancel = () => dispatch(setIsOpenWarehouseProcurementRequestCancel())
    
    return(
        <FormDrawer isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} title="Satın Alma Talebini İptal Et" maxWidth="max-w-4xl">
            <CancelForm procurementRequest={procurementRequest} selectedWarehouse={selectedWarehouse} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Cancel