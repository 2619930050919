import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const Pagination = ({pagination, pageMeta}) => {

    const [searchParams, setSearchParams] = useSearchParams()

    const getSearchString = (url) => {
        if(url != null) {
            const pageUrl = new URL(url)
            const existingUrl = searchParams.get('search')
            return pageUrl.search + '&search=' + existingUrl
        }
        return null
    }

    const PreviousPageMobile = () => {
        if(pagination) {
            const ppl = pagination.filter(page => {
                return page.label.includes('Geri')
            })     
            if(ppl[0].url) {
                return <Link to={getSearchString(ppl[0].url)} className="relative inline-flex items-center px-3 py-2 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Geri </Link>
            }       
            
        }
        return <span className="relative inline-flex items-center px-3 py-2 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-not-allowed"> Geri </span>
    }

    const NextPageMobile = () => {
        if(pagination) {
            const npl = pagination.filter(page => {
                return page.label.includes('İleri')
            })     
            if(npl[0].url) {
                return <Link to={getSearchString(npl[0].url)} className="relative inline-flex items-center px-3 py-1 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> İleri </Link>
            }       
            
        }
        return <span className="relative inline-flex items-center px-3 py-1 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-not-allowed"> İleri </span>
    }

    const PreviosPage = ({link}) => {        
        if(link != null) {
            return (<Link to={link} className="relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50">
                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
            </Link>)
        } else {
            return (<span className="relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 cursor-not-allowed">
                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
            </span>)
        }
    }
    const NextPage = ({link}) => {        
        if(link != null) {
            return (<Link to={link} className="relative inline-flex items-center px-2 py-1 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50">
                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
            </Link>)
        } else {
            return (<span to={link} className="relative inline-flex items-center px-2 py-1 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 cursor-not-allowed">
                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
            </span>)
        }
    }
    const PageLink = ({link, active, label}) => {
        if(active) {
            return <Link to={link} className="z-[2] bg-sky-50 border-sky-500 text-sky-600 relative inline-flex items-center px-3 py-1 border text-xs font-medium"> {label} </Link>
        } else {
            if(link != null) {
                return <Link to={link} className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-3 py-1 border text-xs font-medium"> {label}  </Link>
            } else {
                return <span className="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-xs font-medium text-gray-700"> {label} </span>
            }
            
        }
    } 
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between items-center sm:hidden">
            <PreviousPageMobile />
            <span className='text-gray-700'>Sayfa {pageMeta?.currentPage ?? null}</span>
            <NextPageMobile />
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                {pageMeta && <p className="text-xs text-gray-700">
                Toplam <span className="font-medium">{pageMeta.total}</span> sonuç içerisinde <span className="font-medium">{pageMeta.from}-{pageMeta.to}</span> arası</p>}
            </div>
            <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                    {   pagination &&
                        pagination.map((page, index) => {
                            if(page.label.includes('Geri')){
                                return <PreviosPage link={getSearchString(page.url)} key={`pagePrevious`}/>
                            }
                            if(page.label.includes('İleri')){
                                return <NextPage link={getSearchString(page.url)} key={`pageNext`} />
                            }
                            return <PageLink link={getSearchString(page.url)} active={page.active} label={page.label} key={`${page.label}_${index}`} />
                        })
                    }
                    
                </nav>
            </div>
        </div>
    </div>
  );
};

export default Pagination;
