import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpenCreate: false,
    isOpenEdit: false,
    isOpenDelete: false,
}

export const companyProductTaxonomyDrawerSlice = createSlice({
    name: 'companyProductTaxonomyDrawers',
    initialState,
    reducers: {
        setIsOpenCreate: (state, action) => {
            state.isOpenCreate = action.payload
        },
        setIsOpenEdit: (state, action) => {
            state.isOpenEdit = action.payload
        },
        setIsOpenDelete: (state, action) => {
            state.isOpenDelete = action.payload
        },
    }
})

export const {
                setIsOpenCreate,
                setIsOpenEdit,
                setIsOpenDelete,
            } = companyProductTaxonomyDrawerSlice.actions

export default companyProductTaxonomyDrawerSlice.reducer