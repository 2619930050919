import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useOutletContext, useSearchParams, useLocation } from 'react-router-dom'
import { ButtonFilter, ButtonNew, ButtonExcel, ButtonRefresh, ButtonNewSmall } from 'elements/buttons/actionButtons'
import Loader from 'elements/Loader'
import SendRequest from 'utils/SendRequest'
import Table from 'modules/companyModule/pages/warehouses/demands/Table'
import TableFilter from 'modules/companyModule/pages/warehouses/demands/TableFilter'
import Detail from 'modules/companyModule/pages/warehouses/demands/Detail'
import Reject from 'modules/companyModule/pages/warehouses/demands/Reject'
import Deliver from 'modules/companyModule/pages/warehouses/demands/Deliver'

function WarehouseDemands() {

    const [selectedWarehouse, setSelectedWarehouse] = useOutletContext()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [productDemands, setProductDemands] = useState([])
    const [productDemandForDetail, setProductDemandForDetail] = useState(null)
    const [productDemandForDeliver, setProductDemandForDeliver] = useState(null)    
    const [productDemandForReject, setProductDemandForReject] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)

    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation()

    useEffect(() => {
        
        const getProductDemands = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/product_demands?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setProductDemands(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProductDemands()
    }, [selectedWarehouse, updateData, filters, search])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800 font-semibold">Ürün Talepleri</h2>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                />

                <Table data={productDemands} 
                    actions={{
                        setProductDemandForDetail: setProductDemandForDetail,
                        setProductDemandForDeliver: setProductDemandForDeliver,
                        setProductDemandForReject: setProductDemandForReject,
                    }} 
                    progressPending={loading}
                    pagination={pagination}
                    pageMeta={pageMeta}
                /> 
            </div>
            <Reject 
                update={updateData} 
                setUpdate={setUpdateData} 
                productDemand={productDemandForReject} 
                selectedWarehouse={selectedWarehouse}
            />
            <Deliver 
                update={updateData} 
                setUpdate={setUpdateData} 
                productDemand={productDemandForDeliver} 
                selectedWarehouse={selectedWarehouse}
            />
            <Detail productDemand={productDemandForDetail} />
            {loading && <Loader />}
        </div>
    )
}

export default WarehouseDemands