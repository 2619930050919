import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductDemandApproval } from 'stores/DrawersSlice' 
import ApproveForm from 'modules/companyModule/pages/productDemands/approvals/ApproveForm'

function Approve({productDemand, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenApproval = useSelector(state => state.drawers.isOpenProductDemandApproval)
    const setIsOpenApproval = () => dispatch(setIsOpenProductDemandApproval())
    
    return(
        <FormDrawer isOpen={isOpenApproval} setIsOpen={setIsOpenApproval} title="Onay İşlemleri" maxWidth="max-w-4xl">
            <ApproveForm productDemand={productDemand} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Approve