import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenDepartmentProcurementRequestCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/departmentProcurementRequests/requests/CreateForm'

function Create({update, setUpdate, procurementRequest}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenDepartmentProcurementRequestCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenDepartmentProcurementRequestCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Satın Alma Talebi Ekle" maxWidth="max-w-6xl">
            <CreateForm 
                update={update} 
                setUpdate={setUpdate} 
                procurementRequest={procurementRequest} 
            />
        </FormDrawer> 
    )
}

export default Create