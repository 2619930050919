import { FaChartLine } from 'react-icons/fa'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'
import TableActions from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/TableActions'
import Card from 'elements/cards'

function BestProposals({proposals, procurementStatus, actions}) {
    const activeProposals = proposals.filter(proposal => {
        return proposal.is_revised === false && proposal.is_cancelled === false
    }).sort((a, b) => a.total_price - b.total_price).slice(0, 5)

    const isAcceptedAny = activeProposals.filter(proposal => {
        return proposal.is_accepted === true
    }).length

    const CardBody = () => {
        return (
            <div className='w-full overflow-y-auto'>
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-600">
                            <th className="py-1 px-1">Firma</th>
                            <th className="py-1 px-1">Toplam Tutar</th>
                            <th className="py-1 px-1"></th>
                            <th className="py-1 px-1">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeProposals.map(proposal => {
                            return (
                                <tr key={proposal.id} className="border-b border-t border-gray-300 even:bg-gray-200 text-center">
                                    <td className="px-2 py-3">{proposal.supplier.company_name}</td>
                                    <td className="px-2 py-3">{FNWS(proposal.total_price)}</td>
                                    <td className="px-2 py-3">
                                        {proposal.is_accepted ? 
                                        <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Kabul Edildi.</span>
                                        : null}
                                    </td>
                                    <td className="px-2 py-3">
                                        <TableActions rowId={proposal.id} actions={actions} data={proposal} isAcceptedAny={isAcceptedAny} procurementStatus={procurementStatus} />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <Card title='En İyi Teklifler' icon={<FaChartLine />} >  
            <CardBody />
        </Card>
    )
}

export default BestProposals