import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProductInitialStock } from 'stores/DrawersSlice' 
import InitialStockForm from 'modules/companyModule/pages/warehouses/stocks/InitialStockForm'

function InitialStock({product, update, setUpdate, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCancel = useSelector(state => state.drawers.isOpenWarehouseProductInitialStock)
    const setIsOpenCancel = () => dispatch(setIsOpenWarehouseProductInitialStock())
    
    return(
        <FormDrawer isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} title="Başlangıç Stoğu" >
            <InitialStockForm product={product} update={update} setUpdate={setUpdate} selectedWarehouse={selectedWarehouse} />
        </FormDrawer> 
    )
}

export default InitialStock