import { useSelector } from 'react-redux'
import ApprovalCards from 'modules/companyModule/pages/home/approvalCards'
import ProcurementHome from 'modules/companyModule/pages/home/procurements'
import ProductDemandHome from 'modules/companyModule/pages/home/productDemands'
import WarehouseHome from 'modules/companyModule/pages/home/warehouses'
import DepartmentProcurementHome from 'modules/companyModule/pages/home/departmentProcurements'

const Home = () => {

    const userRoles = useSelector(state => state.user.user.roles)

    const auth = {
        procurement: userRoles.find(roles => roles.code === 'procurements') !== undefined ? true : false,
        dep_procurement: userRoles.find(roles => roles.code === 'department_procurement_requests') !== undefined ? true : false,
        product_demand: userRoles.find(roles => roles.code === 'product_demands') !== undefined ? true : false,
        warehouse_product_demand: userRoles.find(roles => roles.code === 'warehouse_product_demands') !== undefined ? true : false,
        warehouse_stock: userRoles.find(roles => roles.code === 'warehouse_stocks') !== undefined ? true : false,
        warehouse_procurement_request: userRoles.find(roles => roles.code === 'warehouse_procurement_request') !== undefined ? true : false,
    }
    
    return (
        <div className="w-full mb-4">
            
            <div className="mb-4">
                <ApprovalCards />
            </div>
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-4">
                
                {auth.procurement ? 
                <ProcurementHome />           
                : null
                }            
                {auth.product_demand ? 
                <ProductDemandHome />           
                : null
                }  
                {auth.dep_procurement ? 
                <DepartmentProcurementHome />           
                : null
                }  
                
                {(auth.warehouse_product_demand || auth.warehouse_stock || auth.warehouse_procurement_request) ? 
                <WarehouseHome />           
                : null
                }                
            </div>
            
            
        </div>
    )
}

export default Home