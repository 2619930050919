

const FindCategory = (categories, id) => {
    if(Array.isArray(categories)) {
        for(let category of categories) {
            const foundCategory = FindCategory(category, id)
            if(foundCategory) {
                return foundCategory
            } 
        }            
    } else {
        if(categories.id == id) return categories
        if(categories.all_sub_categories.length > 0) {
            for(let category of categories.all_sub_categories) {
                const foundCategory = FindCategory(category, id)
                if(foundCategory) {
                    return foundCategory
                }
            }
        }
    }
}

export default FindCategory