import { useSearchParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { ButtonFilter, ButtonNew, ButtonExcel, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import { setIsOpenProductCreate } from 'stores/DrawersSlice'
import Create from 'modules/companyModule/pages/products/Create'
import Edit from 'modules/companyModule/pages/products/Edit'
import Delete from 'modules/companyModule/pages/products/Delete'
import Detail from 'modules/companyModule/pages/products/Detail'
import Table from 'modules/companyModule/pages/products/Table'
import TableFilter from 'modules/companyModule/pages/products/TableFilter'
import ExportXslx from 'modules/companyModule/pages/products/ExportXslx'
import Loader from 'elements/Loader'

function Products(){
    const dispatch = useDispatch()
    const [products, setProducts] = useState([])
    const [costTypes, setCostTypes] = useState([])
    const [taxonomies, setTaxonomies] = useState([])
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [productForEdit, setProductForEdit] = useState(null)
    const [productForDelete, setProductForDelete] = useState(null)
    const [productForDetail, setProductForDetail] = useState(null)
    const [loading, setLoading] = useState(false)

    const companyId = useSelector(state => state.company.selectedCompany.id)
        
    const [updateData, setUpdateData] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null, taxonomies: {}})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation();

    useEffect(() => {
        const getProducts = async () => {
            let page = searchParams.get("page")
            let {taxonomies, ...filterWithoutCategory} = filters 
            let filterParam = '&' + new URLSearchParams(filterWithoutCategory).toString()
            let categoryFilter = Object.fromEntries(Object.entries(filters.taxonomies).
                                filter(([key, val]) => val !== null && val !== ''))

            if(categoryFilter) {
                filterParam = filterParam + '&taxonomies=' + JSON.stringify(categoryFilter)
            }
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/products?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setProducts(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProducts()
    }, [updateData, filters, search])

    useEffect(() => {
        const getCostTypes = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/cost_types`)
                .then(response => {
                    if(response.status === 200) {
                        setCostTypes(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getCostTypes()        
    }, [])

    useEffect(() => {
        const getTaxonomies = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/taxonomies?type=product`)
                .then(response => {
                    if(response.status === 200) {
                        setTaxonomies(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTaxonomies()
    }, [])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Ürünler/Hizmetler</h2>
                <ButtonNew label="Yeni Ürün/Hizmet" onClick={() => dispatch(setIsOpenProductCreate(true))} >
                </ButtonNew>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                            taxonomies={taxonomies}
                            costTypes={costTypes}
                />

                <Table data={products} 
                        actions={{
                            setProductForEdit: setProductForEdit,
                            setProductForDelete: setProductForDelete,
                            setProductForDetail: setProductForDetail,
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            
            
            
            <Detail product={productForDetail} />
            <Create update={updateData} setUpdate={setUpdateData} costTypes={costTypes} taxonomies={taxonomies} />
            <Edit product={productForEdit} update={updateData} setUpdate={setUpdateData} costTypes={costTypes} taxonomies={taxonomies} />
            <Delete product={productForDelete} update={updateData} setUpdate={setUpdateData} />
            {loading && <Loader />}
        </div>
    )
  }
  
  export default Products