import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    companies: null,
    selectedCompany: null,
    isSelected: null,
}

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompanies: (state, action) => {
            state.companies = action.payload
        },
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload
            if(action.payload !== null) {
                state.isSelected = true
            }
        },
        setIsSelected: (state, action) => {
            state.isSelected = action.payload
        },
    }
})

export const {setCompanies, setSelectedCompany, setIsSelected} = companySlice.actions

export default companySlice.reducer