import { useSelector } from 'react-redux' 
import LatestProcurements from 'modules/supplierModule/pages/home/LatestProcurements'
import StatisticsChart from 'modules/supplierModule/pages/home/StatisticsChart'
import BusinessAreaStatistics from 'modules/supplierModule/pages/home/BusinessAreaStatistics'
import Companies from 'modules/supplierModule/pages/home/companies'

function Home() {
    const userRoles = useSelector(state => state.user.user.roles)
    const auth = {
        procurement: userRoles.find(roles => roles.code === 'supplier_procurements') !== undefined ? true : false,
    }

    return (
        <div className="w-full mb-4">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                {auth.procurement ? 
                    <>
                    <div className="col-span-1 lg:col-span-2">
                        <LatestProcurements />                    
                    </div>
                    <div className="col-span-1">
                        <BusinessAreaStatistics />
                    </div>
                    <div className="col-span-2">
                        <Companies />
                    </div>
                    </>
                : 
                <div className="col-span-1 lg:col-span-3">
                    <p className="text-lg text-rose-500 font-semibold">Satın alma ilanlarını görüntülemek için yetkiniz bulunmamaktadır. Yetki almak için sistem yöneticinizle görüşebilirsiniz.</p>
                </div>
                }
                
                <div className="col-span-1 lg:col-span-3">
                {/* <StatisticsChart /> */}
                </div>
            </div>
        </div>
    )

}

export default Home