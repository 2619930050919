import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProductStockCorrectionAdd } from 'stores/DrawersSlice' 
import StockCorrectionForm from 'modules/companyModule/pages/warehouses/stocks/StockCorrectionForm'

function StockCorrection({product, update, setUpdate, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenWarehouseProductStockCorrectionAdd)
    const setIsOpen = () => dispatch(setIsOpenWarehouseProductStockCorrectionAdd())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Stok Düzeltme Ekle" >
            <StockCorrectionForm product={product} update={update} setUpdate={setUpdate} selectedWarehouse={selectedWarehouse} />
        </FormDrawer> 
    )
}

export default StockCorrection