import { useEffect, useState } from 'react'
import SendRequest from 'utils/SendRequest'
import { useDispatch, useSelector } from 'react-redux'
import { setCompanies, setSelectedCompany, setIsSelected } from 'stores/CompanySlice'

const CompanyWrapper = ({children}) => {

    const companyData = useSelector(state => state.company)
    const isAuth = useSelector(state => state.user.authenticated)
    const emailVerified = useSelector(state => state.user.emailVerified)
    const userType = useSelector(state => state.user.userType)
    const dispatch = useDispatch()

    useEffect(() => {
        const getCompanyData = async () => {
            await SendRequest('GET', 'api/companies')
            .then(res => {
                if(res.status === 200) {
                    const companyId = localStorage.getItem('companyId')
                    dispatch(setCompanies(res.data.data))
                    if(res.data.data.length === 0) {
                        dispatch(setSelectedCompany(null))
                        dispatch(setIsSelected(false))
                    }
                    if(res.data.data.length === 1) {
                        dispatch(setSelectedCompany(res.data.data[0]))
                        dispatch(setIsSelected(true))
                    }
                    if(res.data.data.length > 1 && companyId) {
                        let filteredCompany = res.data.filter(company => {
                            return company.id == companyId
                        })
                        
                        if(filteredCompany.length) {
                            dispatch(setSelectedCompany(filteredCompany[0]))
                            dispatch(setIsSelected(true))
                        } else {
                            dispatch(setSelectedCompany(null))
                            dispatch(setIsSelected(false))
                        }
                    }
                    if(res.data.data.length > 1 && !companyId) {
                        dispatch(setSelectedCompany(null))
                        dispatch(setIsSelected(false))
                    }
                }
            })
            .catch(err => {
                dispatch(setCompanies(null))
                dispatch(setSelectedCompany(null))
                dispatch(setIsSelected(false))
            })
            
        } 

        const getSupplierData = async () => {
            await SendRequest('GET', 'api/suppliers')
            .then(res => {
                if(res.status === 200) {
                    const companyId = localStorage.getItem('companyId')
                    dispatch(setCompanies(res.data.data))
                    if(res.data.data.length === 0) {
                        dispatch(setSelectedCompany(null))
                        dispatch(setIsSelected(false))
                    }
                    if(res.data.data.length === 1) {
                        dispatch(setSelectedCompany(res.data.data[0]))
                        dispatch(setIsSelected(true))
                    }
                    if(res.data.data.length > 1 && companyId) {
                        let filteredCompany = res.data.filter(company => {
                            return company.id == companyId
                        })
                        
                        if(filteredCompany.length) {
                            dispatch(setSelectedCompany(filteredCompany[0]))
                            dispatch(setIsSelected(true))
                        } else {
                            dispatch(setSelectedCompany(null))
                            dispatch(setIsSelected(false))
                        }
                    }
                    if(res.data.data.length > 1 && !companyId) {
                        dispatch(setSelectedCompany(null))
                        dispatch(setIsSelected(false))
                    }
                }
            })
            .catch(err => {
                dispatch(setCompanies(null))
                dispatch(setSelectedCompany(null))
                dispatch(setIsSelected(false))
            })
            
        } 
        
        if(isAuth === true && emailVerified === true && companyData.isSelected === null) {
            if(userType === 'company') {
                getCompanyData()
                .catch(err => {
                    dispatch(setCompanies(null))
                    dispatch(setSelectedCompany(null))
                    dispatch(setIsSelected(false))
                })
            } 
            if(userType === 'supplier') {
                getSupplierData()
                .catch(err => {
                    dispatch(setCompanies(null))
                    dispatch(setSelectedCompany(null))
                    dispatch(setIsSelected(false))
                })
            } 
            
        }
    }, [isAuth, emailVerified])

    return children
}

export default CompanyWrapper