import { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import {ButtonSave, ButtonClose, ButtonDeleteSmall, ButtonNew, ButtonOk} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, CheckboxSmall} from 'elements/inputs'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenWarehouseAddProductCategory } from 'stores/DrawersSlice' 
import { usePositionLevels } from 'data'
import Loader from 'elements/Loader'

const EditWarehouseCategoryForm = ({update, setUpdate, warehouse}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenEdit = useSelector(state => state.drawers.isOpenWarehouseAddProductCategory)
    const [categories, setCategories] = useState([])
    const [taxonomies, setTaxonomies] = useState([])
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue } = useForm({
        defaultValues: {}
    })

    const levels = usePositionLevels()

    useEffect(() => {
        if(!isOpenEdit) {
            setStatus(false)
            setError(null)
            reset()
        }
        if(isOpenEdit) {
            if(warehouse) {
                const getLevels = async () => {
                    setLoading(true)
                    await SendRequest('GET', `api/companies/${companyId}/warehouses/${warehouse.id}/product_taxonomies`)
                        .then(response => {
                            if(response.status === 200 && response.data.data.length > 0) {
                                response.data.data.forEach(d => {
                                    setValue(`${d.taxon.id}.selected`, true)
                                })
                            }
                            setLoading(false)
                        })
                        .catch(err => {
                            setLoading(false)
                        })
                }    
                getLevels()
            }
        }
    }, [isOpenEdit])

    useEffect(() => {
        const getCategories = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/product_categories`)
                .then(response => {
                    if(response.status === 200) {
                        setCategories(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }    
        getCategories()
        
    }, [])

    useEffect(() => {
        const getTaxonomies = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/taxonomies?type=product`)
                .then(response => {
                    if(response.status === 200) {
                        setTaxonomies(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTaxonomies()
    }, [])

    const handleForm = async (data) => {
        const filteredData = Object.entries(data)
        .filter(([key, value]) => value.selected === true ) 
        .map(([key, value]) => {
            return {
                taxon_id: key,
                taxonomy_id: value.taxonomyId,
            }
        })
        // console.log(dataArray)
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/warehouses/${warehouse.id}/product_taxonomies`, filteredData)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setStatus(false)
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenWarehouseAddProductCategory())
    }

    const TaxonItemInput = ({item, taxonomyId, onChangeCheckbox}) => {
        return (
            <div className="flex items-start justify-start gap-x-2 mb-1">
                <input 
                    type="hidden"
                    name={`${item.id}.taxonomyId`}
                    {...register(`${item.id}.taxonomyId`, { value: taxonomyId })}
                />
                <CheckboxSmall 
                    type="checkbox"
                    name={`${item.id}.selected`}
                    register={register}
                    label={item.taxon_name}
                    validator={{
                        onChange: (e) => onChangeCheckbox(item.id, e.target.checked)
                    }}
                />                                        
            </div>
        )
    }

    const TaxonList = ({taxa, taxonomyId}) => {
        const onChangeCheckbox = (id, isChecked, selectedTaxa) => {
            let processedTaxa = selectedTaxa ? selectedTaxa : taxa
            
            const selectedItem = processedTaxa.filter(t => t.id === id)
            setValue(`${selectedItem[0].id}.selected`, isChecked)
            if(selectedItem && selectedItem?.[0] && selectedItem?.[0]?.all_sub_taxa) {
                selectedItem[0].all_sub_taxa.forEach(taxon => {
                    onChangeCheckbox(taxon.id, isChecked, selectedItem[0].all_sub_taxa)
                })
            }
        }        

        return taxa.sort((a, b) => a.taxon_name.localeCompare(b.taxon_name)).map(t => 
            { 
                return (
                <Fragment key={t.id}>
                    <TaxonItemInput item={t} taxonomyId={taxonomyId} onChangeCheckbox={onChangeCheckbox} />
                    {(t.all_sub_taxa && Array.isArray(t.all_sub_taxa) && t.all_sub_taxa.length > 0) ?
                    <div className={`ml-8`}>
                        <TaxonList taxa={t.all_sub_taxa} taxonomyId={taxonomyId} />
                    </div> 
                    : null}
                </Fragment>
                )
            })
        
    }
    
    return (
        <>
        <div className="">
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-6rem)]">
                    <div className='w-full'>
                        <div className="text-red-500 mt-4">
                            {error && <ServerError errorData={error} />}
                        </div>
                        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
                    </div>
                    <div className="w-full flex-1 overflow-y-auto">
                        {(taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) ?
                            taxonomies.sort((a,b) => a.taxonomy_name.localeCompare(b.taxonomy_name)).map(taxonomy => {
                                return (
                                    <div key={`taxonomy_${taxonomy.id}`} className='mb-2'>
                                        <p className='w-full text-sm font-semibold pb-0.5 border-b border-gray-400 text-gray-700 mb-1'>{taxonomy.taxonomy_name}</p>
                                        <TaxonList taxa={taxonomy.taxa} taxonomyId={taxonomy.id} />
                                    </div>
                                )
                            })
                        : null
                        }
                        {/* <CategoryList cats={categories} /> */}
                    </div>
                    <div className="w-full flex justify-end mt-5 gap-x-2">
                        <ButtonSave type="submit">
                        </ButtonSave>
                        <ButtonClose onClick={() => close()}>
                        </ButtonClose>
                    </div>
                </div>         
            </form>  
        </div>
        
         
        {loading && <Loader />}
        </>
    )
}

export default EditWarehouseCategoryForm