import { useSelector } from 'react-redux'
import { StockCorrectionType } from 'data'

export function useInitialStockInput(errors, unitCodes) {
    

    return [
        {
            key: "initialStockInput1",
            type: "text",
            name: "quantity",
            placeholder: "Miktar *",
            label: "Miktar *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) >= 0 || 'Sıfırdan büyük olmalı'
                }                 
            },
            error: errors?.quantity?.message,
            cols: 'col-span-5', 
        },
        {
            key: "initialStockInput2",
            type: "select",
            name: "unit_code_id",
            placeholder: "Birim *",
            label: "Birim *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: unitCodes,
            error: errors?.unit_code_id?.message,
            cols: 'col-span-3', 
        },
        {
            key: "initialStockInput3",
            type: "date",
            name: "stock_date",
            placeholder: "Başlangıç Stok Tarihi *",
            label: "Başlangıç Stok Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
                            message: "Geçerli bir tarih girmelisiniz."}
            },
            error: errors?.stock_date?.message,
            cols: 'col-span-4', 
        },
        {
            key: "initialStockInput4",
            type: "text",
            name: "description",
            placeholder: "Açıklama",
            label: "Açıklama",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },       
    ]
}

export function useStockCorrectionInput(errors, unitCodes) {
    
    return [
        {
            key: "stockCorrectionInput1",
            type: "select",
            name: "correction_type",
            placeholder: "Stok Düzeltme Türü *",
            label: "Stok Düzeltme Türü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data:StockCorrectionType(),
            error: errors?.correction_type?.message,
            cols: 'col-span-12', 
        },
        {
            key: "stockCorrectionInput2",
            type: "text",
            name: "quantity",
            placeholder: "Miktar *",
            label: "Miktar *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) >= 0 || 'Sıfırdan büyük olmalı'
                }                 
            },
            error: errors?.quantity?.message,
            cols: 'col-span-5', 
        },
        {
            key: "stockCorrectionInput3",
            type: "select",
            name: "unit_code_id",
            placeholder: "Birim *",
            label: "Birim *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: unitCodes,
            error: errors?.unit_code_id?.message,
            cols: 'col-span-3', 
        },
        {
            key: "stockCorrectionInput4",
            type: "date",
            name: "correction_date",
            placeholder: "Düzeltme Tarihi *",
            label: "Düzeltme Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
                            message: "Geçerli bir tarih girmelisiniz."}
            },
            error: errors?.correction_date?.message,
            cols: 'col-span-4', 
        },
        {
            key: "stockCorrectionInput5",
            type: "text",
            name: "description",
            placeholder: "Açıklama",
            label: "Açıklama",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },       
    ]
}

export function useStockTransferInput(errors, transferableWarehouses, unitCodes) {
        
    return [
        {
            key: "stockTransferInput1",
            type: "select",
            name: "transferred_warehouse_id",
            placeholder: "Transfer Edilecek Depo *",
            label: "Transfer Edilecek Depo *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: transferableWarehouses,
            error: errors?.transferred_warehouse_id?.message,
            cols: 'col-span-12', 
        },
        {
            key: "stockTransferInput2",
            type: "text",
            name: "quantity",
            placeholder: "Miktar *",
            label: "Miktar *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) >= 0 || 'Sıfırdan büyük olmalı'
                }                 
            },
            error: errors?.quantity?.message,
            cols: 'col-span-5', 
        },
        {
            key: "stockTransferInput3",
            type: "select",
            name: "unit_code_id",
            placeholder: "Birim *",
            label: "Birim *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: unitCodes,
            error: errors?.unit_code_id?.message,
            cols: 'col-span-3', 
        },
        {
            key: "stockTransferInput4",
            type: "date",
            name: "transfer_date",
            placeholder: "Transfer Tarihi *",
            label: "Transfer Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/,
                            message: "Geçerli bir tarih girmelisiniz."}
            },
            error: errors?.transfer_date?.message,
            cols: 'col-span-4', 
        },
        {
            key: "stockTransferInput5",
            type: "text",
            name: "description",
            placeholder: "Açıklama",
            label: "Açıklama",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },       
    ]
}