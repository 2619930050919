import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductEdit } from 'stores/DrawersSlice' 
import EditForm from 'modules/companyModule/pages/products/EditForm'

function Edit({product, update, setUpdate, costTypes, taxonomies}) {
    
    const dispatch = useDispatch()
    const isOpenEdit = useSelector(state => state.drawers.isOpenProductEdit)
    const setIsOpenEdit = () => dispatch(setIsOpenProductEdit())
    
    return(
        <FormDrawer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} title="Ürün/Hizmet Bilgilerini Düzenle">
            <EditForm product={product} update={update} setUpdate={setUpdate} costTypes={costTypes} taxonomies={taxonomies} />
        </FormDrawer> 
    )
}

export default Edit