import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Loader from 'elements/Loader'

const RedirectIfAuth = ({children}) => {
    const authenticated = useSelector(state => state.user.authenticated)
    const emailVerified = useSelector(state => state.user.emailVerified)
    
    if(authenticated === true) {    
        return <Navigate to="/" replace />
    } else if(authenticated === false) {
        return children
    } else {
        return <Loader />
    }
    
}

export default RedirectIfAuth