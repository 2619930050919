import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductDetail } from 'stores/DrawersSlice' 
import DetailForm from 'modules/companyModule/pages/products/DetailForm'

function Detail({product}) {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenProductDetail)
    const setIsOpenDetail = () => dispatch(setIsOpenProductDetail())
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Ürün Detayı" >
            <DetailForm product={product} />
        </FormDrawer> 
    )
}

export default Detail