import { useRef, useState } from 'react'
import { read, utils, writeFileXLSX } from 'xlsx'
import { IconContext } from "react-icons"
import { FaCloudUploadAlt, FaCloudDownloadAlt } from 'react-icons/fa'
import { ButtonUpload } from 'elements/buttons/actionButtons'

function UploadForm({setData, setDataStatus}) {

    const excelRef = useRef(null)
    const [uploadError, setUploadError] = useState({excelFile: null})
    const [fileName, setFileName] = useState('Dosya Seçilmedi')

    const checkFile = () => {
        let files = excelRef.current.files
        if(files.length === 0) {
          setFileName('Dosya Seçilmedi')        
          setUploadError({excelFile: true})
        } else {
          setFileName(files[0].name)
          if(files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files[0].type === 'application/vnd.ms-excel') {
            setUploadError({excelFile: false})
          } else {
            setUploadError({excelFile: true})
          }
        }
    }
  
    const readFile = async () => {
        if(excelRef.current.files.length > 0) {
          const f = await excelRef.current.files[0].arrayBuffer()
          const wb = read(f)
          const ws = wb.Sheets[wb.SheetNames[0]]
          let filedata = utils.sheet_to_json(ws, {header: "A", range: 1})
          let dummyStatus = []
          filedata.forEach((line, index) => {
            dummyStatus[index] = 'pending'
          })
          setData(filedata)
          setDataStatus(dummyStatus)
        }
    }

    const handleUpload = () => {
        if(uploadError.excelFile === false) {
            readFile()
        } else if(uploadError.excelFile === null) {
            setUploadError({excelFile: true})
        }
    }

    const downloadSampleExcel = () => {
        const sampleData = [
            {
                name: 'Ürün 1',
                product_type_id: 5,
                product_code: 'ABC1234',
                barcode: '111111111111',
                description: null,
                unit_code_id: 1,
            },
            {
                name: 'Hizmet 1',
                product_type_id: 7,
                product_code: '',
                barcode: '',
                description: 'Yeni hizmet tanımlama açıklaması',
                unit_code_id: 1,
            },
        ]
        const header = [[
            "Ürün/Hizmet Adı", 
            "Ürün Tür Kodu", 
            "Ürün Kodu", 
            "Barkod", 
            "Ürün Açıklaması", 
            "Birim Kodu",
        ]]
        const ws = utils.json_to_sheet(sampleData)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, "ornek_urun_tablosu.xlsx")
    }


    return (
        <div className='flex flex-col gap-y-0.5 items-start'>
            <div className="flex w-full items-start justify-between mt-4">
                <button onClick={() => downloadSampleExcel()} type="button" className="w-64 mr-4 flex flex-col items-center text-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                    <IconContext.Provider value={{ className: "h-10 w-10" }}>
                        <FaCloudDownloadAlt />
                    </IconContext.Provider>                    
                    <span className="mt-2 text-base leading-normal">Örnek Excel Dosyasını İndir</span>
                </button>
                <label className="w-64 flex flex-col items-center text-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                    <IconContext.Provider value={{ className: "h-10 w-10" }}>
                        <FaCloudUploadAlt />
                    </IconContext.Provider>
                    <span className="mt-2 text-base leading-normal">Excel Dosyasını Yükle</span>
                    <input type='file' className="hidden" onChange={(e) => checkFile()} ref={excelRef}/>
                    <span className="text-xs text-gray-700 leading-normal">Dosya: {fileName}</span>
                    <span className="text-xs text-red-600">{uploadError.excelFile && 'Seçtiğiniz dosya excel dosyası olmalı'}</span>
                </label>
            </div>
            <div className="flex w-full items-center justify-end mt-4">
                <ButtonUpload onClick={handleUpload}>
                Yükle
                </ButtonUpload>
            </div>
        </div>
    )
}

export default UploadForm