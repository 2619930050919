import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierProjectCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/supplierModule/pages/companies/projects/CreateForm'

function Create({update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenSupplierProjectCreate)
    const setIsOpen = () => dispatch(setIsOpenSupplierProjectCreate())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Proje Ekle">
            <CreateForm update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Create