import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierUserRoleEdit } from 'stores/DrawersSlice' 
import EditForm from 'modules/supplierModule/pages/companies/settings/users/EditForm'

function Edit({user, update, setUpdate, roles}) {
    
    const dispatch = useDispatch()
    const isOpenEdit = useSelector(state => state.drawers.isOpenSupplierUserRoleEdit)
    const setIsOpenEdit = () => dispatch(setIsOpenSupplierUserRoleEdit())
    
    return(
        <FormDrawer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} title="Kullanıcı Bilgilerini Düzenle">
            <EditForm user={user} update={update} setUpdate={setUpdate} roles={roles} />
        </FormDrawer> 
    )
}

export default Edit