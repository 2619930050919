import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonPrint } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierInfoForm } from 'stores/DrawersSlice'
import CompanyInfos from 'modules/supplierModule/pages/companies/companyInfos'
import CompanyDocuments from 'modules/supplierModule/pages/companies/documents'
import Contacts from 'modules/supplierModule/pages/companies/contacts'
import BankAccounts from 'modules/supplierModule/pages/companies/bankAccounts'
import References from 'modules/supplierModule/pages/companies/references'
import Projects from 'modules/supplierModule/pages/companies/projects'
import InfoForm from 'modules/supplierModule/pages/companies/infoForm'

const Companies = () => {
    const companies = useSelector(state => state.company)
    const dispatch = useDispatch()
    const [update, setUpdate] = useState(true)

    const StatusWarning = ({status}) => {
        if(status === 'waiting_documents') {
            return (
                <div className="p-2 bg-red-500 text-white text-sm">
                    <p>Sistemi kullanmaya başlamak için şirketinize ait ilgili dokümanları yüklemeniz gerekmektedir.</p>
                    <p>Dokümanların yüklenmesi ve onaylanması akabinde sisteminiz kullanıma açılacaktır.</p>
                </div>
            )
        }
        if(status === 'waiting_documents_approval') {
            return (
                <div className="p-2 bg-red-500 text-white text-sm">
                    <p>Sistemi kullanmaya başlamak için şirketinize ait ilgili dokümanların onaylanması gerekmektedir.</p>
                    <p>Dokümanların onaylanması akabinde sisteminiz kullanıma açılacaktır.</p>
                </div>
            )
        }
    }

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Şirket Ayarları</h2>    
                <ButtonPrint 
                    label="Tedarikçi Bilgi Formu" 
                    onClick={() => dispatch(setIsOpenSupplierInfoForm(true))}    
                />            
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4">
                <div className="col-span-2">
                    <StatusWarning status={companies.selectedCompany?.status} />
                </div>
                <div className="col-span-1 bg-white p-4 border border-gray-300">
                    <CompanyInfos />
                </div>
                <div className="col-span-1 bg-white p-4 border border-gray-300">
                    <CompanyDocuments />
                </div>
                <div className="col-span-2 bg-white p-4 border border-gray-300">
                    <Contacts />
                </div>
                <div className="col-span-2 bg-white p-4 border border-gray-300">
                    <BankAccounts />
                </div>
                <div className="col-span-2 bg-white p-4 border border-gray-300">
                    <References />
                </div>
                <div className="col-span-2 bg-white p-4 border border-gray-300">
                    <Projects />
                </div>
            </div>
            <InfoForm />
        </div>
    )
}

export default Companies