import { utils, writeFileXLSX } from 'xlsx'

function ExportXslx(data, fileName = 'liste') {
    
    const array = data.map(item => {
        return {
            name: item.name,
            email: item.email,
            phone: item.phone,
            status: item.is_active ? 'Aktif' : 'Pasif',
            role: item.role.description,
        }
    })

    const header = [[
        "Ad Soyad", "E-Posta", "Telefon", "Durum", "Kullanıcı Türü" 
    ]]

    const exportXsl = () => {
        const ws = utils.json_to_sheet(array)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, fileName+".xlsx")
    }

    exportXsl()
}

export default ExportXslx