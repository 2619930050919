import { useEffect, useState } from 'react'
import { FaChartLine, FaChartPie } from 'react-icons/fa'
import { IconContext } from "react-icons"
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'

function BusinessAreaStatistics() {
    const companyBusinessAreas = useSelector(state => state.company.selectedCompany.business_areas)
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const businessAreaNames = (companyBusinessAreas && Array.isArray(companyBusinessAreas) && companyBusinessAreas.length > 0)
                                ? companyBusinessAreas.map(ba => ba.name).join(', ')
                                : 'Sektör bilgisi bulunamadı'
    const placeholderData = {
        "active_procurements": null,
        "last_month": {
            "procurements": null,
            "proposals": null
        },
        "last_year": {
            "procurements": null,
            "proposals": null
        },
        "total": {
            "procurements": null,
            "proposals": null
        }
    }
    const [data, setData] = useState(placeholderData)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/statistics/business_area`)
                .then(response => {
                    if(response.status === 200) {
                        setData(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getData()
    }, [updateData])

    return (
        <div className="w-full h-full flex flex-col gap-y-4">
            <div className="w-full flex-none flex items-center justify-start gap-2 bg-slate-800 px-4 py-2 text-white font-bold">
                <IconContext.Provider value={{ className: "h-4 w-4" }}>
                    <FaChartLine />
                </IconContext.Provider>
                <span>{(businessAreaNames.length > 35) ? businessAreaNames.slice(0, 34) + ' ...' : businessAreaNames}</span>
            </div>
            <div className="flex-grow flex items-center justify-start gap-x-4 bg-cyan-200 border border-cyan-500 text-cyan-600 p-4">
                <IconContext.Provider value={{ className: "h-12 w-12" }}>
                    <FaChartPie />
                </IconContext.Provider>
                <p className="text-xl">
                    <span>Aktif Satın Almalar:</span> <br />
                    <span className="text-2xl font-semibold">{data.active_procurements} Adet</span>
                </p>
            </div>
            <div className="flex-grow flex items-center justify-start gap-x-4 bg-rose-200 border border-rose-500 text-rose-600 p-4">
                <IconContext.Provider value={{ className: "h-12 w-12" }}>
                    <FaChartPie />
                </IconContext.Provider>
                <p className="text-lg">
                    <span>Son 1 Ay:</span> <br />
                    <span className="text-xl font-semibold">{data.last_month.proposals} Teklif / {data.last_month.procurements} İlan </span>
                </p>
            </div>
            <div className="flex-grow flex items-center justify-start gap-x-4 bg-teal-200 border border-teal-500 text-teal-600 p-4">
                <IconContext.Provider value={{ className: "h-12 w-12" }}>
                    <FaChartPie />
                </IconContext.Provider>
                <p className="text-lg">
                    <span>Son 1 Yıl:</span> <br />
                    <span className="text-xl font-semibold">{data.last_year.proposals} Teklif / {data.last_year.procurements} İlan </span>
                </p>    
            </div>
            <div className="flex-grow flex items-center justify-start gap-x-4 bg-blue-200 border border-blue-500 text-blue-600 p-4">
                <IconContext.Provider value={{ className: "h-12 w-12" }}>
                    <FaChartPie />
                </IconContext.Provider>
                <p className="text-lg">
                    <span>Toplam:</span> <br />
                    <span className="text-xl font-semibold">{data.total.proposals} Teklif / {data.total.procurements} İlan </span>
                </p>
            </div>
        </div>
    )
}

export default BusinessAreaStatistics