import { CompanyType } from 'data'

function CompanyTypeCell({type}) {
    if(type) {
        const filteredType = CompanyType().filter(ct => ct.key.toString() === type.toString())
        if(filteredType) return filteredType[0].value
        return 'Belirtilmemiş' 
    }
    return 'Belirtilmemiş'
}

export default CompanyTypeCell