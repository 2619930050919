import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { DateTime } from 'luxon'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import { DetailStatus } from 'modules/companyModule/pages/procurementProcesses/commonParts/Status'
import { ProcurementLineTextType, 
        ProcurementSupplierSelectionTypes, 
        ProcurementProposalTypes, 
        ProcurementPaymentTypes,
        ProcurementDeliveryTypes } from 'data'

function DetailInfo({procurement, downloadToken = null}) {
    const companyId = useSelector(state => state.company?.selectedCompany?.id)
    const [loading, setLoading] = useState(false)

    const formatDate = (date) => {
        let parsedDate = DateTime.fromISO(date)
        if(parsedDate.isValid) return parsedDate.toFormat('dd.LL.yyyy T')
        return 'Belirlenmedi'
    }

    const lineTextTypeDesc = (code) => {
        let selectedCode = ProcurementLineTextType().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }

    const supplierSelectionDesc = (code) => {
        let selectedCode = ProcurementSupplierSelectionTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }

    const proposalTypeDesc = (code) => {
        let selectedCode = ProcurementProposalTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value.slice(0,25)+'...'
        }
        return ''
    }

    const paymentTypeDesc = (code) => {
        let selectedCode = ProcurementPaymentTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value.slice(0,25)
        }
        return ''
    }

    const deliveryTypeDesc = (code) => {
        let selectedCode = ProcurementDeliveryTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value.slice(0,25)
        }
        return ''
    }

    const proposalRevisionDesc = (code) => {
        if(code) {
            return 'Tedarikçi revize tekliflerinde fiyat arttıramaz.'
        }
        return 'Tedarikçi revize tekliflerinde fiyat arttırabilir.'
    }
    const proposalCancelDesc = (code) => {
        if(code) {
            return 'Tedarikçi verdiği teklifi iptal edemez.'
        }
        return 'Tedarikçi verdiği teklifi iptal edebilir.'
    }

    const SupplerSelectionRows = () => {
        if(['all_suppliers_selected_business_areas', 'approved_suppliers_selected_business_areas'].includes(procurement.supplier_selection_type)) {
            return (
                <tr className="border-b border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3">Seçilen Sektörler: </td>
                    <td className="px-2 py-3">{procurement.supplier_busines_areas.map(spa => spa.business_area.name).join(', ')}</td>
                </tr>
            )
        }
        if(procurement.supplier_selection_type === 'suppliers_by_categories') {
            return (
                <tr className="border-b border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3">Seçilen Kategoriler: </td>
                    <td className="px-2 py-3">
                        {procurement.supplier_categories.map(sc => `${sc.supplier_taxonomy.taxonomy_name}: ${sc.supplier_taxon.taxon_name}`).join(', ')}
                    </td>
                </tr>
            )
            
        }
        if(procurement.supplier_selection_type === 'suppliers_by_ids') {
            return (
                <tr className="border-b border-gray-300 even:bg-gray-200">
                    <td className="px-2 py-3">Seçilen Tedarikçiler: </td>
                    <td className="px-2 py-3">
                        {procurement.suppliers.map(supplier => supplier.supplier.company_name).join(', ')}
                    </td>
                </tr>
            )            
        }

        return null
    }

    const approvalStatus = (approval) => {
        if(approval.is_accepted) return <span className="text-emerald-500 font-semibold">Kabul Edildi</span>
        if(approval.is_rejected) return <span className="text-red-500 font-semibold">Reddedildi</span>
        return <span className="text-orange-500 font-semibold">Onay bekleniyor</span>
    }

    const downloadTechSpec = () => {
        const getTechSpec = async () => {
            let downloadUrl = ''
            if(downloadToken === null) {
                downloadUrl = `api/companies/${companyId}/procurements/${procurement.id}/download_technical_specification`
            } else {
                downloadUrl = `api/approval_with_token/${downloadToken}/download_technical_specification`
            }
            setLoading(true)
            await SendRequest('DOWNLOAD', downloadUrl)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${procurement.procurement_no}.pdf`)
                        document.body.appendChild(link)
                        link.click()
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTechSpec()
    }

    const downloadLineTechSpec = (lineId) => {
        const getTechSpec = async () => {
            let downloadUrl = ''
            if(downloadToken === null) {
                downloadUrl = `api/companies/${companyId}/procurements/${procurement.id}/lines/${lineId}/download_technical_specification`
            } else {
                downloadUrl = `api/approval_with_token/${downloadToken}/lines/${lineId}/download_technical_specification`
            }
            setLoading(true)
            await SendRequest('DOWNLOAD', downloadUrl)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${procurement.procurement_no}-${lineId}.pdf`)
                        document.body.appendChild(link)
                        link.click()
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTechSpec()
    }

    return (
        <div className="">
                {(procurement !==null && Object.keys(procurement).length > 0) &&
                <>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
                    <div className="col-span-1">
                        <table className="w-full">
                            <tbody>
                                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Talep No:</td>
                                    <td className="px-2 py-3">{procurement.procurement_no}</td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Talep Başlığı:</td>
                                    <td className="px-2 py-3">{procurement.title}</td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Sektör: </td>
                                    <td className="px-2 py-3">{procurement.business_area.name}</td>
                                </tr>                                
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Gider Türü: </td>
                                    <td className="px-2 py-3">{procurement.cost_type?.cost_name}</td>
                                </tr>                                                                       
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Teklif Revizesi: </td>
                                    <td className="px-2 py-3">{proposalRevisionDesc(procurement.is_proposal_increase_not_allowed)}</td>
                                </tr>                                                                       
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Teklif İptali: </td>
                                    <td className="px-2 py-3">{proposalCancelDesc(procurement.is_proposal_cancel_not_allowed)}</td>
                                </tr>                                                                       
                                                   
                            </tbody>
                        </table>
                    </div>
                    <div className="col-span-1">
                        <table className="w-full">
                            <tbody>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Teklif Toplama Türü: </td>
                                    <td className="px-2 py-3">{proposalTypeDesc(procurement.proposal_type)}</td>
                                </tr>                                                                   
                                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Talebi Oluşturan: </td>
                                    <td className="px-2 py-3">{procurement.user?.name || 'Bulunamadı'}</td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Oluşturulma Zamanı: </td>
                                    <td className="px-2 py-3">{formatDate(procurement.created_at)}</td>
                                </tr>   
                                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Bitiş Zamanı: </td>
                                    <td className="px-2 py-3">{formatDate(procurement.end_date)}</td>
                                </tr>
                                
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Tedarikçi Seçimi: </td>
                                    <td className="px-2 py-3">{supplierSelectionDesc(procurement.supplier_selection_type)}</td>
                                </tr>
                                <SupplerSelectionRows />
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Durum: </td>
                                    <td className="px-2 py-3">
                                        <DetailStatus status={procurement.status} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>                                
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ödeme Bilgileri</p>
                <table className="w-full">
                    <tbody>                                                                
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Para Birimi: </td>
                            <td className="px-2 py-3">
                                {procurement.currency.code} ({procurement.currency.description}) 
                                {procurement.is_currency_flexible ? ' (Tedarikçi değiştirebilir)' : ' (Tedarikçi değiştiremez)'}
                            </td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Ödeme Şekli: </td>
                            <td className="px-2 py-3">
                                {paymentTypeDesc(procurement.payment_type)} {procurement.payment_type === 'deferred' ? ` - ${procurement.deferral_days} Gün` : null}
                                {procurement.is_payment_type_flexible ? ' (Tedarikçi değiştirebilir)' : ' (Tedarikçi değiştiremez)'}
                            </td>
                        </tr>   
                    </tbody>
                </table>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Sevkiyat Bilgileri</p>
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Sevkiyat Yöntemi: </td>
                            <td className="px-2 py-3">
                                {deliveryTypeDesc(procurement.delivery_type)} 
                            </td>
                        </tr> 
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Teslim Zamanı: </td>
                            <td className="px-2 py-3">{formatDate(procurement.delivery_date)}</td>
                        </tr>  
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Teslim Adresi: </td>
                            <td className="px-2 py-3">{procurement.delivery_address}</td>
                        </tr>  
                    </tbody>
                </table>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-300 text-left">
                            <th className="px-1 py-0.5">Ürün</th>
                            <th className="px-1 py-0.5">Ürün Açıklaması</th>
                            <th className="px-1 py-0.5">Miktar</th>
                            <th className="px-1 py-0.5">Birim</th>
                            <th className="px-1 py-0.5">Şartname</th>
                        </tr>
                    </thead>
                    <tbody>
                        {procurement.lines.map(line => {
                            return (
                                <tr key={line.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5 whitespace-nowrap">{line.product.name}</td>
                                    <td className="px-1 py-0.5 whitespace-pre-line">{line.description_text === 'null' ? '' : line.description_text}</td>
                                    <td className="px-1 py-0.5">{line.quantity}</td>
                                    <td className="px-1 py-0.5">{line.unit_code.description}</td>
                                    <td className="px-1 py-0.5">
                                        {line.tech_spec_type === 'use_product_file' || line.tech_spec_type === 'use_uploaded_file' ? 
                                            <button type="button" className="text-md text-sky-500 hover:text-sky-800 underline whitespace-nowrap" onClick={() => downloadLineTechSpec(line.id)}>
                                                İndirmek için tıklayın.
                                            </button>
                                            : 'Şartname Yok'                                             
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Açıklamalar</p>
                <p className="whitespace-pre-line">{procurement.description}</p>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Teknik Şartname</p>
                {procurement.is_tech_spec_available ? 
                    <p className="whitespace-pre-line">
                        <button type="button" className="text-md text-sky-500 hover:text-sky-800 underline" onClick={downloadTechSpec}>
                            İndirmek için tıklayın.
                        </button>                    
                    </p>
                    :
                    <p className="whitespace-pre-line">
                        Şartname yüklenmemiş                   
                    </p>
                }
                
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Onay Süreci</p>
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-300 text-left">
                            <th className="px-1 py-0.5">#</th>
                            <th className="px-1 py-0.5">Onaylayacak Yönetici</th>
                            <th className="px-1 py-0.5">Durum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {procurement.approvals.map((approval, index) => {
                            return (
                                <tr key={approval.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5">{index + 1}</td>
                                    <td className="px-1 py-0.5">{approval.user?.name ?? 'Bilinmiyor'}</td>
                                    <td className="px-1 py-0.5">{approvalStatus(approval)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </>}
                {loading && <Loader />}
            </div>  
    )
}

export default DetailInfo