import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenCreate } from 'modules/companyModule/pages/products/settings/taxonomies/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/products/settings/taxonomies/CreateForm'

function Create({update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.companyProductTaxonomyDrawers.isOpenCreate)
    const setIsOpen = () => dispatch(setIsOpenCreate())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Sınıflandırma Ekle">
            <CreateForm update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Create