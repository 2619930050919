import { useSearchParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { ButtonFilter, ButtonNew, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import { setIsOpenSupplierCreate } from 'stores/DrawersSlice'
import Create from 'modules/companyModule/pages/suppliers/Create'
import Edit from 'modules/companyModule/pages/suppliers/Edit'
import Delete from 'modules/companyModule/pages/suppliers/Delete'
import Detail from 'modules/companyModule/pages/suppliers/details/Detail'
import Approve from 'modules/companyModule/pages/suppliers/Approve'
import AddBlacklist from 'modules/companyModule/pages/suppliers/AddBlacklist'
import RemoveBlacklist from 'modules/companyModule/pages/suppliers/RemoveBlacklist'
import Table from 'modules/companyModule/pages/suppliers/Table'
import TableFilter from 'modules/companyModule/pages/suppliers/TableFilter'
import TopCards from 'modules/companyModule/pages/suppliers/TopCards'
import ExportXslx from 'modules/companyModule/pages/suppliers/ExportXslx'
import Loader from 'elements/Loader'

function Suppliers(){
    const dispatch = useDispatch()
    const [suppliers, setSuppliers] = useState([])
    const [categories, setCategories] = useState([])
    const [taxonomies, setTaxonomies] = useState([])
    const [documentList, setDocumentList] = useState([])
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [supplierForEdit, setSupplierForEdit] = useState(null)
    const [supplierForDelete, setSupplierForDelete] = useState(null)
    const [supplierForDetail, setSupplierForDetail] = useState(null)
    const [supplierForApprove, setSupplierForApprove] = useState(null)
    const [supplierForAddBlacklist, setSupplierForAddBlacklist] = useState(null)
    const [supplierForRemoveBlacklist, setSupplierForRemoveBlacklist] = useState(null)
    const [loading, setLoading] = useState(false)

    const companyId = useSelector(state => state.company.selectedCompany.id)
        
    const [updateData, setUpdateData] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null, taxonomies: {}})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation()

    useEffect(() => {
        const getSuppliers = async () => {
            let page = searchParams.get("page")
            // let filterParam = '&' + new URLSearchParams(filters).toString()
            let {taxonomies, ...filterWithoutCategory} = filters 
            let filterParam = '&' + new URLSearchParams(filterWithoutCategory).toString()
            let categoryFilter = Object.fromEntries(Object.entries(filters.taxonomies).
                                filter(([key, val]) => val !== null && val !== ''))

            if(categoryFilter) {
                filterParam = filterParam + '&taxonomies=' + JSON.stringify(categoryFilter)
            }
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/suppliers?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setSuppliers(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getSuppliers()
    }, [updateData, filters, search])

    useEffect(() => {
        const getDocumentList = async () => {
            setLoading(true)
            await SendRequest('GET', `api/supplier_document_lists`)
                .then(response => {
                    if(response.status === 200) {
                        setDocumentList(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getDocumentList()
    }, [])

    useEffect(() => {
        const getTaxonomies = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/taxonomies?type=supplier`)
                .then(response => {
                    if(response.status === 200) {
                        setTaxonomies(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTaxonomies()
    }, [])

    const handleExport = async () => {
        const getSupplierList = async () => {
            console.log(filters)
            return new Promise(async (resolve) => {
                let {categories, ...filterWithoutCategory} = filters 
                let filterParam = '&no_pagination=true&' + new URLSearchParams(filterWithoutCategory).toString()
                let categoryFilter = Object.fromEntries(Object.entries(filters.categories).
                                    filter(([key, val]) => val !== null && val !== ''))

                if(categoryFilter) {
                    filterParam = filterParam + '&categories=' + JSON.stringify(categoryFilter)
                }            
                await SendRequest('GET', `api/companies/${companyId}/suppliers?${filterParam}`)
                    .then(response => {
                        if(response.status === 200) {
                           resolve(response.data) 
                        } else {
                            resolve(null)
                        }                       
                    })
                    .catch(err => {
                        resolve(null)
                    })
            })
        }
        setLoading(true)
        await getSupplierList()
                .then(result => {
                    if(result !== null) {
                        ExportXslx(result, categories, 'Tedarikçi Listesi')
                    }
                })
                .finally(() => {
                    setLoading(false)
                })        
    }

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Tedarikçiler</h2>
                {/* <ButtonNew label="Yeni Tedarikçi" onClick={() => dispatch(setIsOpenSupplierCreate(true))} >
                </ButtonNew> */}
            </div>
            <div className="w-full mb-4">
                <TopCards updateData={updateData} />
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            categories={categories}
                            setSearchParams={setSearchParams}
                            taxonomies={taxonomies}
                />

                <Table data={suppliers} 
                        actions={{
                            setSupplierForEdit: setSupplierForEdit,
                            setSupplierForDelete: setSupplierForDelete,
                            setSupplierForDetail: setSupplierForDetail,
                            setSupplierForApprove: setSupplierForApprove,
                            setSupplierForAddBlacklist: setSupplierForAddBlacklist,
                            setSupplierForRemoveBlacklist: setSupplierForRemoveBlacklist,
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                        categories={categories}
                        taxonomies={taxonomies}
                    /> 

            </div>
            
            
            
            <Create update={updateData} setUpdate={setUpdateData} categories={categories} taxonomies={taxonomies} />
            <Edit supplier={supplierForEdit} update={updateData} setUpdate={setUpdateData} categories={categories} taxonomies={taxonomies} />
            <Delete supplier={supplierForDelete} update={updateData} setUpdate={setUpdateData} />
            <Approve supplier={supplierForApprove} update={updateData} setUpdate={setUpdateData} />
            <AddBlacklist supplier={supplierForAddBlacklist} update={updateData} setUpdate={setUpdateData} />
            <RemoveBlacklist supplier={supplierForRemoveBlacklist} update={updateData} setUpdate={setUpdateData} />
            <Detail supplier={supplierForDetail} documentList={documentList} />
            {loading && <Loader />}
        </div>
    )
  }
  
  export default Suppliers