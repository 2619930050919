import React from 'react'
import { useDispatch } from 'react-redux' 
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import { IconContext } from "react-icons"
import { FaUsers, FaUserShield, FaUsersCog, FaRegEdit, FaSlidersH } from 'react-icons/fa'
import { setIsOpenWarehouseEdit, 
            setIsOpenWarehouseAddUser, 
            setIsOpenWarehouseAddProductCategory,
            setIsOpenWarehouseProcurementRequestWorkflow,
        } from '../../../../../../stores/DrawersSlice'

const TableActions = ({actions, data}) => {
    const dispatch = useDispatch()

    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton 
                    title='Düzenle' 
                    icon={<FaRegEdit />} 
                    onClick={() => {
                        actions.setWarehouseForEdit(data)
                        dispatch(setIsOpenWarehouseEdit(true))}
                    }
                />
                <TableButton 
                    title='Depo Kullanıcıları' 
                    icon={<FaUsers />} 
                    onClick={() => {
                        actions.setWarehouseForUser(data); 
                        dispatch(setIsOpenWarehouseAddUser(true))}
                    }
                />
                <TableButton 
                    title='Ürün Kategorileri' 
                    icon={<FaUserShield />} 
                    onClick={() => {
                        actions.setWarehouseForCategory(data); 
                        dispatch(setIsOpenWarehouseAddProductCategory(true))}
                    }
                />
                <TableButton 
                    title='Satın Alma Talebi Onay Süreci' 
                    icon={<FaUsersCog />} 
                    onClick={() => {
                        actions.setWarehouseForProcurementRequest(data); 
                        dispatch(setIsOpenWarehouseProcurementRequestWorkflow(true))}
                    }
                />
                
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
