import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonNew, ButtonOk, ButtonSave} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall, FileSmall} from 'elements/inputs'
import DropdownTree from 'elements/inputs/DropdownTree'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FindCategory from 'utils/FindCategory'
import { setIsOpenProductCreate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useProductInput } from 'modules/companyModule/pages/products/InputArray'

const CreateForm = ({update, setUpdate, costTypes, taxonomies}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [taxonomySelectArray, setTaxonomySelectArray] = useState([])
    const [techSpecFileAttributes, setTechSpecFileAttributes] = useState({filename: null, size: null, type: null})
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            name: null,
            product_type_id: null,
            cost_type_id: null,
            product_code: null,
            barcode: null,
            description: null,
            unit_code_id: null,
            technical_specification_file: null,
            taxonomies: {},      
        }
    })

    useEffect(() => {
        const modifyTaxArray = (taxArray) => {
            if(taxArray && Array.isArray(taxArray)) {
                return taxArray.sort((a,b) => a.taxon_name.localeCompare(b.taxon_name)).map(item => {
                            return {
                                key: item.id,
                                value: item.taxon_name,
                                children: modifyTaxArray(item.all_sub_taxa)
                            }
                        })
            }
            return null
        }
    
        
        const taxArray = (taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) ?
                            taxonomies.sort((a,b) => a.taxonomy_name.localeCompare(b.taxonomy_name)).map(t => {
                                setValue(`taxonomies.${t.id}`, null)
                                return {
                                    id: t.id,
                                    name: t.taxonomy_name,
                                    nodes: modifyTaxArray(t.taxa)
                                }
                            }) :
                            []
        setTaxonomySelectArray(taxArray)
    }, [taxonomies])

    const productInputs = useProductInput(errors, costTypes, techSpecFileAttributes, setTechSpecFileAttributes)
    
    const handleForm = async (data) => {
        const formData = new FormData()
        Object.keys(data).forEach((key) => {
            if(data[key] instanceof FileList) {
                formData.append(key, data[key][0])
            } else if (Array.isArray(data[key])) {
                data[key].forEach((item, index) => {
                    Object.keys(item).forEach((itemkey) => {
                        formData.append(`${key}[${index}][${itemkey}]`, item[itemkey] === null ? '' : item[itemkey])
                    })
                    
                })
            } else if (data[key] instanceof Object) {
                Object.keys(data[key]).forEach((subkey) => {
                    formData.append(`${key}[${subkey}]`, data[key][subkey])
                })
            } else {
              formData.append(key, data[key] === null ? '' : data[key])
            }
        })

        setLoading(true)        
        await SendRequest('POST', `api/companies/${companyId}/products`, formData)
                .then(res => {
                    if(res.status === 204) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
        
    }    

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenProductCreate())
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <form onSubmit={handleSubmit(handleForm)}>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürün/Hizmet Bilgileri</p>
                <div className="grid grid-cols-12 gap-2">
                {productInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            {input.type == 'select' ? 
                                <SelectSmall register={register} {...input} /> :
                                null
                            }
                            {input.type == 'text' ? 
                                <InputSmall register={register} {...input} /> :
                                null
                            }
                            {input.type == 'textarea' ? 
                                <TextareaSmall register={register} {...input} /> :
                                null
                            }
                            {input.type == 'file' ? 
                                <FileSmall register={register} {...input} /> :
                                null
                            }
                        </div>
                        )
                })}
                </div>
                <div className='my-4'>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Kategoriler</p>
                    {(taxonomySelectArray && Array.isArray(taxonomySelectArray) && taxonomySelectArray.length > 0) ?
                        taxonomySelectArray.map(ta => {
                            return (
                                <div key={`taxonomy_${ta.id}`} className="mb-2">
                                    <DropdownTree                                         
                                        data = {ta.nodes}
                                        control = {control} 
                                        name = {`taxonomies.${ta.id}`} 
                                        validator = {{
                                            
                                        }}
                                        error = {errors?.taxonomies?.[ta.id]?.message}
                                        actions = {(e) => null}
                                        placeholder = "Seçiniz"                                    
                                        label = {ta.name}
                                        nullOption = {true}
                                    />
                                </div>
                            )
                        })
                    : null}
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonSave type="submit">
                    </ButtonSave>
                </div>          
            </form> 
        }   
        {loading && <Loader />}
        </>
    )
}

export default CreateForm