import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {ButtonFilter, ButtonReset} from 'elements/buttons/actionButtons'

function TableFilter({isFilterOpen, setIsFilterOpen, filters, setFilters, categories, setSearchParams}) {
    const [resetFields, setResetFields] = useState(false)

    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            start_date: filters.start_date,
            end_date: filters.end_date,            
        }
    })

    const handleForm = async (data) => {
        setSearchParams('page=1')
        setIsFilterOpen(false)
        setFilters(data)
    }

    const handleReset = () => {
        reset()
        setResetFields(!resetFields)
        handleSubmit(handleForm)()
    }

    return (
        <div className={`flex-none border border-gray-200 bg-white overflow-hidden transition-[width] duration-300 ease-linear ${isFilterOpen ? 'w-full md:w-[250px] h-auto p-3' : 'w-0 h-0 p-0'}`}>
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12">
                        <InputSmall 
                            type='date'
                            name='start_date'
                            register={register}
                            label= 'Başlangıç Tarihi'
                            placeholder='Başlangıç Tarihi'
                            validator={{}}
                            error={errors?.start_created_at?.message}
                        />                     
                        <InputSmall 
                            type='date'
                            name='end_date'
                            register={register}
                            label= 'Bitiş Tarihi'
                            placeholder='Bitiş Tarihi'
                            validator={{}}
                            error={errors?.end_created_at?.message}
                        />                             
                    </div>
                </div>
                <div className="w-full flex justify-end gap-x-2 mt-5">
                    <ButtonReset type="button" onClick={() => handleReset()}>
                    </ButtonReset>
                    <ButtonFilter type="submit">
                    </ButtonFilter>
                </div>
            </form>   
        </div>
    )
}

export default TableFilter