const ServerError = (errorData) => {
    const err = errorData.errorData.errors

    let errorItems = null

    if(err) {
        errorItems = Object.keys(err).map((value, index) => {
            return (
                <li key={value.toString()}>{err[value][0]}</li>
            )
        })
    }
    
    
    return (
        <div className='text-sm p-2 mb-2 bg-red-600 text-red-50 border border-red-800'>
            <p>{errorData?.errorData?.message}</p>   
            <ul className='list-[circle] list-inside'>
                {errorItems}
            </ul>                     
        </div>
    )
}

const ServerSuccess = ({message}) => {
    return (
        <div className='text-sm p-2 mb-2 bg-green-600 text-green-50 border border-green-800'>
            <p>{message}</p>
        </div>
    )
}

export {
    ServerError,
    ServerSuccess
}