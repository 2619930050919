import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { useSearchParams, useLocation } from 'react-router-dom'
import SendRequest from 'utils/SendRequest'
import { ButtonFilter, ButtonNew, ButtonRefresh } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierInvitationCreate } from 'stores/DrawersSlice'
import Create from 'modules/companyModule/pages/suppliers/invitations/Create'
import Table from 'modules/companyModule/pages/suppliers/invitations/Table'
import TableFilter from 'modules/companyModule/pages/suppliers/invitations/TableFilter'

function SupplierInvitations() {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)

    const [invitations, setInvitations] = useState([])
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)

    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation()

    useEffect(() => {
        const getInvitations = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/supplier_invitations?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setInvitations(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getInvitations()
    }, [updateData, filters, search])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Tedarikçi Davetleri</h2>
                <ButtonNew label="Yeni Tedarikçi Davet Et" onClick={() => dispatch(setIsOpenSupplierInvitationCreate(true))} >
                </ButtonNew>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                />

                <Table data={invitations} 
                        actions={{}} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            <Create update={updateData} setUpdate={setUpdateData} />
        </div>
    )
}

export default SupplierInvitations