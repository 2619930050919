import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import TableActions from 'modules/companyModule/pages/warehouses/transferApprovals/TableActions'
import TableLayout from 'elements/tables'
import FindCategory from 'utils/FindCategory'
import FormatNumber from 'utils/FormatNumber'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'


const Table = ({data, actions, progressPending, pagination, pageMeta, categories = null}) => {

    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy')
    }

    const formatStatus = (item) => {
        if(item.is_approved === true) {
            return (
                <>
                    <p className="text-emerald-500 font-semibold">Onaylandı</p>
                    <p className="text-gray-700">(Onaylayan: {item.transferred_user.name})</p>
                </>
            )            
        }
        if(item.is_rejected === true) return <span className="text-red-500 font-semibold">Reddedildi</span>
        return <span className="text-orange-500 font-semibold">Onay bekleniyor</span>
    }
    
    const columns = [
        {
            name: 'Ürün Adı',
            selector: (row) => {return row.product.name},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Kodu',
            selector: (row) => {return row.product.product_code},
            headerMobileHidden: false,
        },
        {
            name: 'Transfer Edilen Depo',
            selector: (row) => {return `${row.warehouse.warehouse_name} - ${row.user.name}`},
            headerMobileHidden: false,
        },
        {
            name: 'Transfer Tarihi',
            selector: (row) => {return formatDate(row.transfer_date)},
            headerMobileHidden: false,
        },
        {
            name: 'Miktar',
            selector: (row) => {return `${FNWS(row.quantity, 2)} ${row.unit_code.description}`},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return formatStatus(row)},
            headerMobileHidden: false,
        },
        
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return `${row.product.name} (${DateTime.fromISO(row.transfer_date).toFormat('dd.LL.yyyy')})`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
