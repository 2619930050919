import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseDemandReject } from 'stores/DrawersSlice' 
import RejectForm from 'modules/companyModule/pages/warehouses/demands/RejectForm'

function Reject({productDemand, update, setUpdate, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCancel = useSelector(state => state.drawers.isOpenWarehouseDemandReject)
    const setIsOpenCancel = () => dispatch(setIsOpenWarehouseDemandReject())
    
    return(
        <FormDrawer isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} title="Ürün Talebini Reddet" maxWidth="max-w-4xl">
            <RejectForm productDemand={productDemand} update={update} setUpdate={setUpdate} selectedWarehouse={selectedWarehouse} />
        </FormDrawer> 
    )
}

export default Reject