import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {ButtonNew, ButtonCancel, ButtonOk, ButtonClose} from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenProcurementProposalAccept } from 'stores/DrawersSlice' 
import DetailInfoForProposal from 'modules/companyModule/pages/procurementProcesses/commonParts/DetailInfoForProposal'
import Loader from 'elements/Loader'

const AcceptForm = ({procurement, proposalData, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenAccept = useSelector(state => state.drawers.isOpenProcurementProposalAccept)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)

    const [proposal, setProposal] = useState(null)
   
    const handleAccept = async () => {
        let data = {}
        if(procurement.proposal_type === 'block') {
            const proposalDataLines = Object.keys(proposalData).filter(key => {
                return key.startsWith('line_')
            }).map(line => {
                return {[line.slice(-3)]: proposalData[line]}
            })            
            data = {proposal: proposalData.proposal, lines: proposalDataLines}            
        } else if(procurement.proposal_type === 'partial') {
            let lineData = {}
            const proposalDataLines = Object.keys(proposalData).filter(key => {
                return key.startsWith('line_')
            }).forEach(line => {
                lineData[line.slice(-3)] = proposalData[line]
            })            
            data = {proposal: proposalData.proposal, lines: lineData}
        } else {
            data = {}
        }
        
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/procurements/${procurement.id}/accept_proposal`, data)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    useEffect(() => {             

        if(!isOpenAccept) {
            setStatus(false)
            setError(null)
        }
        if(isOpenAccept) {
            if(procurement && procurement.proposal_type === 'block') {
                const acceptedProposal = procurement.proposals.filter(p => {
                    return p.id.toString() === proposalData.proposal.toString()
                })
                if(acceptedProposal.length > 0) {
                    setProposal(acceptedProposal)
                }
            }
            if(procurement && procurement.proposal_type === 'partial') {
                const proposalDataLines = Object.keys(proposalData).filter(key => {
                    return key.startsWith('line_')
                }).map(line => {
                    return proposalData[line]
                }) 
                
                const acceptedProposal = procurement.proposals.filter((p) => {
                    return p.lines.map(l => l.id.toString()).filter(v => proposalDataLines.includes(v)).length
                }).map(pr => {
                    return {
                        ...pr,
                        lines: pr.lines.filter(li => {
                            return proposalDataLines.includes(li.id.toString())
                        })
                    }
                })
                
                if(acceptedProposal.length > 0) {
                    setProposal(acceptedProposal)
                }
            }
        }
    }, [isOpenAccept, proposalData])
    

    const close = () => {
        setStatus(false)
        setError(null)
        dispatch(setIsOpenProcurementProposalAccept())
    }

    return (
        <>
        {status ? 
            <>
            <ServerSuccess message='İşlem başarıyla tamamlandı. İlgili tedarikçiye teklifin kabul edildi bilgisi e-posta olarak iletilmiştir.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-6rem)]">
                <div className='w-full'>
                    <div className="text-red-500 mt-4">
                        {error && <ServerError errorData={error} />}
                    </div>
                </div>
            <div className="w-full flex-1 overflow-y-auto px-1">
                <p className="text-sm font-semibold text-gray-700 mb-0.5">Aşağıda detayları verilen teklifi kabul etmek istediğinizden emin misiniz? </p>
                <DetailInfoForProposal proposal={proposal} procurement={procurement} />
            </div>            
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonClose type="button" onClick={close}>
                </ButtonClose>
                <ButtonOk label="Teklifi Kabul Et" type="button" onClick={handleAccept}>
                </ButtonOk>                
            </div>  
            </div>       
        }   
        {loading && <Loader />}
        </>
    )
}

export default AcceptForm