import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import {ButtonEditSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import { setIsOpenSupplierUserRoleEdit, setIsOpenSupplierUserStatusEdit } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    return (
        <div className='w-full flex items-center justify-center gap-x-3'>
            <ButtonEditSmall label='Düzenle' 
                            onClick={() => {actions.setUserForRoleChange(data)
                                            dispatch(setIsOpenSupplierUserRoleEdit(true))}}
            >                
            </ButtonEditSmall>
            <ButtonEditSmall label='Durumu Değiştir' 
                            onClick={() => {
                                            actions.setUserForStatusChange(data)
                                            dispatch(setIsOpenSupplierUserStatusEdit(true))}}
            >
            </ButtonEditSmall>
        </div>
    )
};

export default TableActions
