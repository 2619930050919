import CompanyCompanies from 'modules/companyModule/pages/companies'


function CompanyCompanyRoutes()
{
    return [
        {
          path: '',
          element: <CompanyCompanies />
        }
      ]
}

export default CompanyCompanyRoutes