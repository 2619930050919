import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import {ButtonClose, ButtonNext, ButtonPrevious} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall, FileSmall, CheckboxSmall} from 'elements/inputs'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import { useDescriptionInput, useTechSpecInputs } from 'modules/companyModule/pages/procurementProcesses/procurements/InputArray'

function DescriptionAndTechSpec({currentStep, setCurrentStep, procurementData, setProcurementData}) {
    const dispatch = useDispatch()
    const isOpen = useSelector((state) => state.drawers.isOpenProcurementCreate)   
    
    const formDefaults = {
        description: procurementData?.description || null,
        technical_specification_file: null,          
    }
    
    const [techSpecFileAttributes, setTechSpecFileAttributes] = useState({filename: null, size: null, type: null})
    
    const { register, handleSubmit, formState: { errors }, control, getValues, watch } = useForm({
        defaultValues: formDefaults
    })

    const descirptionInputs = useDescriptionInput(errors)
    const techSpecInputs = useTechSpecInputs(errors, techSpecFileAttributes, setTechSpecFileAttributes)

    const close = () => {
        dispatch(setIsOpenProcurementCreate())
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const handleForm = async (data) => {
        setProcurementData(prev => {
            return {...prev, ...data}
        })
        setCurrentStep(prev => prev+1)
    }    

    return (  
        <>      
        <form onSubmit={handleSubmit(handleForm, handleError)}>            
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-8rem)]">
                <div className="w-full overflow-y-auto px-0.5">
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Açıklamalar</p>
                    <div className="grid grid-cols-12 gap-2">
                    {descirptionInputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                <TextareaSmall register={register} {...input} rows="5" /> 
                            </div>
                            )
                    })}
                    </div>     
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Şartname</p>
                    <div className="grid grid-cols-12 gap-2">
                        {techSpecInputs.map(input => {
                            return (
                                <div className={input.cols} key={input.key}>
                                    <FileSmall register={register} {...input} /> 
                                </div>
                                )
                        })}
                        
                    </div>
                </div>
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonClose onClick={() => close()}>
                    </ButtonClose>
                    <ButtonPrevious onClick={() => setCurrentStep(prev => prev-1)}>
                    </ButtonPrevious>
                    <ButtonNext type='submit'>
                    </ButtonNext>                  
                </div>         
            </div>
        </form> 
        </>
    )

}

export default DescriptionAndTechSpec