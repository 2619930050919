import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserTable from 'modules/authModule/pages/profile/UserTable'
import DepartmentsTable from 'modules/authModule/pages/profile/DepartmentsTable'
import { ButtonEdit } from 'elements/buttons/actionButtons'
import { setIsOpenUserProfileEdit } from 'stores/DrawersSlice'
import Edit from 'modules/authModule/pages/profile/Edit'

const Companies = () => {
    const user = useSelector(state => state.user.user)
    const dispatch = useDispatch()
    const [update, setUpdate] = useState(true)

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-start items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Kullanıcı Bilgileri</h2>                
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4">
                <div className="col-span-1 bg-white p-4">
                    <UserTable user={user} />
                    <div className="p-4 flex justify-end items-center">
                        <ButtonEdit type="button" onClick={() => {dispatch(setIsOpenUserProfileEdit(true))}} />
                    </div>
                </div>
                {user.type === 'company' ? 
                <div className="col-span-1 bg-white p-4">
                    <DepartmentsTable user={user} />
                </div>
                : null
                }
            </div>
            <Edit update={update} setUpdate={setUpdate} />
        </div>
    )
}

export default Companies