import { utils, writeFileXLSX } from 'xlsx'
import FindCategory from 'utils/FindCategory'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

function ExportXslx(data, categories, fileName = 'ekstre') {

    const categoryCell = (selectedCategories) => {
        if(categories.length > 0 && selectedCategories !== null) {
            let catJson = new Map(Object.entries(selectedCategories))
            if(catJson.size > 0) {
                return [...catJson].map(cat => {     
                    if(cat[1]) {
                        let foundCategory = FindCategory(categories, cat[0])
                        if(foundCategory) {
                            let optionSelected = foundCategory.category_items.find(item => {
                                return item.id == cat[1]
                            })
                            return (`${foundCategory.category_name}: ${optionSelected?.item_name}`)
                        }                       
                    }                          
                }).join(', ')
            } else {
                return ''
            }
        } else {
            return ''
        }
        
    }

    const array = data.map(item => {
        return {
            name: item.name,
            product_code: item.product_code,
            barcode: item.barcode,
            description: item.description,
            unit: item.unit.description + ' (' + item.unit.code + ')',
            product_type: item.product_type.name,
            categories: categoryCell(item.categories),
            procurement_count: item.procurement_count,
            quantity: item.quantity.map(i => FNWS(i.quantity) + ' ' + i.unit_code.description).join(' + '),
            price: item.price.map(i => FNWS(i.price) + ' ' + i.currency.code).join(' + ')
        }
    })

    const header = [[
        "Ürün/Hizmet Adı", 
        "Ürün Kodu", 
        "Barkod", 
        "Açıklama", 
        "Birim", 
        "Ürün Türü", 
        "Kategori", 
        "Satın Alma Sayısı", 
        "Satın Alınan Miktar", 
        "Satın Alınan Tutar",
    ]]

    const exportXsl = () => {
        const ws = utils.json_to_sheet(array)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, fileName+".xlsx")
    }

    exportXsl()
}

export default ExportXslx