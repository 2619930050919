import React from 'react'
import { Outlet } from 'react-router-dom'

const CompanySettings = () => {
    
    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex flex-col lg:flex-row justify-between items-center gap-y-2 lg:gap-y-0">
                <h2 className="text-2xl text-gray-800 font-semibold">Şirket Ayarları</h2>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <Outlet />
            </div>     
        </div>
    )
}

export default CompanySettings