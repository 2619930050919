import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import {ButtonSave, ButtonClose, ButtonDeleteSmall, ButtonNew, ButtonOk} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenDepartmentEdit } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

const EditDepartmentForm = ({update, setUpdate, department, setDepatment, parentId = null, departments}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenEdit = useSelector(state => state.drawers.isOpenDepartmentEdit)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [departmentSelectArray, setDepartmentSelectArray] = useState([])
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue } = useForm({
        defaultValues: {
            department_name: null,
            parent_id: parentId ?? null,
            company_id: companyId
        }
    })

    useEffect(() => {
        let selectArray = [
            {
                key: 0,
                value: 'Yok'
            }
        ]
        if(departments && departments.length > 0) {
            departments.forEach((d) => {
                selectArray.push(
                    {
                        key: d.id,
                        value: d.department_name
                    }
                )
            })
        }        
        setDepartmentSelectArray(selectArray)
    }, [departments])

    useEffect(() => {
        if(department !== null && department?.department_name !== undefined) {
            setValue('department_name', department.department_name)
            setValue('parent_id', parentId)
        }        
    }, [department, parentId])

    useEffect(() => {
        if(!isOpenEdit) {
            reset()
        }
    }, [isOpenEdit])

    const inputs = [
        {
            key: "input1",
            type: "select",
            name: "parent_id",
            placeholder: "Üst Departman",
            label: "Üst Departman *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.parent_id?.message,
            data: departmentSelectArray,
            cols: 'col-span-12', 
        }, 
        {
            key: "input2",
            type: "text",
            name: "department_name",
            placeholder: "Departman Adı",
            label: "Departman Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.department_name?.message,
            cols: 'col-span-12', 
        },
    ]

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/departments/${department.id}`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenDepartmentEdit())
    }
    
    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <div className="grid grid-cols-12 gap-2">
            {inputs.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        {input.type == 'select' ? 
                            <SelectSmall register={register} {...input} /> :
                            <InputSmall register={register} {...input} />
                        }
                    </div>
                    )
            })}                
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div>                
        </form>   
        {loading && <Loader />}
        </>
    )
}

export default EditDepartmentForm