import { DateTime } from 'luxon'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

function DetailInfo({transfer}) {
    const formatDate = (date) => {
        let parsedDate = DateTime.fromISO(date)
        if(parsedDate.isValid) return parsedDate.toFormat('dd.LL.yyyy T')
        return 'Belirlenmedi'
    }

    const formatStatus = (item) => {
        if(item.is_approved === true) return <span className="text-emerald-500 font-semibold">Onaylandı</span>
        if(item.is_rejected === true) return <span className="text-red-500 font-semibold">Reddedildi</span>
        return <span className="text-orange-500 font-semibold">Onay bekleniyor</span>
    }

    return (
        <div className="">
                {(transfer !==null && Object.keys(transfer).length > 0) &&
                <>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Transfer Eden Depo: </td>
                            <td className="px-2 py-3">{transfer.warehouse.warehouse_name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Transferi Oluşturan: </td>
                            <td className="px-2 py-3">{transfer.user.name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Oluşturulma Zamanı: </td>
                            <td className="px-2 py-3">{formatDate(transfer.created_at)}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Transfer Tarihi: </td>
                            <td className="px-2 py-3">{formatDate(transfer.transfer_date)}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Durum: </td>
                            <td className="px-2 py-3">{formatStatus(transfer)}</td>
                        </tr>
                        {transfer.transferred_user !== null ? 
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Onaylayan: </td>
                            <td className="px-2 py-3">{transfer.transferred_user.name}</td>
                        </tr>
                    
                        : null}
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Ürün Adı: </td>
                            <td className="px-2 py-3">{transfer.product.name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Ürün Kodu: </td>
                            <td className="px-2 py-3">{transfer.product.product_code}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Barkod: </td>
                            <td className="px-2 py-3">{transfer.product.barcode}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Miktar: </td>
                            <td className="px-2 py-3">{`${FNWS(transfer.quantity, 2)} ${transfer.unit_code.description}`}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Açıklama: </td>
                            <td className="px-2 py-3">{transfer.description}</td>
                        </tr>
                    </tbody>
                </table>
                </>}
            </div>  
    )
}

export default DetailInfo