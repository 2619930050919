import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import {ButtonNew, ButtonOk, ButtonSave, ButtonNewSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall} from 'elements/inputs'
import SelectWithSearch from 'elements/inputs/SelectWithSearch'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenProductDemandCreate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useDescriptionInput, useLineInput, useDepartmentInput } from 'modules/companyModule/pages/productDemands/demands/InputArray'

const CreateForm = ({update, setUpdate, productDemand}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)  
    const isOpen = useSelector((state) => state.drawers.isOpenProductDemandCreate)  
    const [products, setProducts] = useState([])  
    const [approvalData, setApprovalData] = useState(null)
    const [unitCodes, setUnitCodes] = useState([])
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)

    const linesDefaults = {
        product_id: "",
        description_text: null,
        quantity: null,
        unit_code_id: "",
    }
    const formDefaults = {
        department_id: null,
        description: null,
        lines: [linesDefaults],
    }
    
    const { register, handleSubmit, formState: { errors }, reset, resetField, watch, control, setValue, getValues } = useForm({
        defaultValues: formDefaults
    })

    const { 
        fields: lineFields, 
        append: lineAppend, 
        remove: lineRemove, 
        } = useFieldArray({
        control,
        name: "lines"
    })

    const watchLines = watch("lines")
    const controlledItemFields = lineFields.map((field, index) => {
        return {
            ...field,
            ...watchLines[index]
        }
    })

    const watchDepartment = watch('department_id')

    const descirptionInputs = useDescriptionInput(errors)
    const lineInputs = useLineInput(errors, products)
    const departmentInputs = useDepartmentInput(errors)

    useEffect(() => {
        const getApprovalData = async (ids = null, departmentId = null) => {
            const data = {
                department_id: departmentId,
                product_ids: JSON.stringify(ids)
            }
            await SendRequest('POST', `api/companies/${companyId}/product_demands/approval_list`, data)
                .then(response => {
                    if(response.status === 200) {
                        if(response.data !== null && Object.keys(response.data).length !== 0 && response.data != []) {
                            setApprovalData(response.data)
                        } else {
                            setApprovalData(null)
                        }
                        
                    }
                })
                .catch(err => {
                    console.log(error)
                })
        }
        const getUnitCodes = (lines) => {
            let unitCodeArray = []
            lines.forEach(line => {
                unitCodeArray.push([{
                    key: line.unit_code.id,
                    value: `${line.unit_code.description} (${line.unit_code.code})`
                }])                
            })
            setUnitCodes(unitCodeArray)
        }
        if(productDemand !==null && Object.keys(productDemand).length > 0) {
            let defaults = {
                description: productDemand.description,
                department_id: productDemand.department.id
            }
            let lineItems = productDemand.lines.map(line => {
                return {
                    product_id: line.product.id,
                    description_text: line.description_text,
                    quantity: line.quantity,
                    unit_code_id: line.unit_code.id,
                }
            })
            reset({...defaults, lines : lineItems})
            const productIds = productDemand.lines.map(line => line.product.id)
            if(productIds.length > 0) {
                getApprovalData(productIds, productDemand.department.id)
                getUnitCodes(productDemand.lines)
            }
        } else {
            reset(formDefaults)
        }
    }, [productDemand])
    
    useEffect(() => {
        if(watchDepartment !== null && watchDepartment !== '') {
            const getProducts = async () => {
                setLoading(true)
                let categories = []
                await SendRequest('GET', `api/companies/${companyId}/departments/${watchDepartment}/products?no_pagination=true`)
                    .then(response => {                    
                        if(response.status === 200) {
                            setProducts(response.data.data)
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
            getProducts()
        } else {
            setProducts([])
        }
        resetField('lines')
    }, [watchDepartment])

    useEffect(() => {
        let unitCodeArray = []
        if(products && products.length > 0) {
            lineFields.forEach((line, index) => {
                let product = products.find(p => {
                    return p.id == line.product_id
                })
                if(product) {
                    unitCodeArray.push([{
                        key: product.unit.id,
                        value: `${product.unit.description} (${product.unit.code})`
                    }])
                } else {
                    unitCodeArray.push([])
                }         
            })
            setUnitCodes(unitCodeArray)
        }
        
    }, [lineFields, products]) 

    useEffect(() => {
        unitCodes.forEach((uc, index) => {
            if(uc && Array.isArray(uc) && uc.length > 0) {
                setValue(`lines.${index}.unit_code_id`, uc[0].key, { shouldDirty: true })
            }            
        })
    }, [unitCodes])
    
    const handleForm = async (data) => {        
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/product_demands`, data)
                .then(res => {
                    if(res.status === 204) {
                        reset()
                        setProducts([])
                        setStatus(true)
                        setError(null)
                        setApprovalData(null)
                        setUnitCodes([])
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setStatus(false)
                    setLoading(false)
                })
    }    

    const redo = () => {
        reset()
        setProducts([])
        setError(null)
        setStatus(false)
        setApprovalData(null)
        setUnitCodes([])
    }

    const close = () => {
        dispatch(setIsOpenProductDemandCreate())
    }

    useEffect(() => {
        if(!isOpen) {
            reset()
            setProducts([])
            setStatus(false)
            setError(null)
            setApprovalData(null)
            setUnitCodes([])
        }
    }, [isOpen])

    const handleError = (error, e) => {
        console.log(error)
        console.log(getValues('lines'))
    }

    const handleLineProductChange = async (e, index) => {
        let product = products.find(p => {
            return p.id == e.value
        })

        setUnitCodes(prev => {
            let newCodes = [...prev]
            newCodes[index] = [{
                key: product.unit.id,
                value: `${product.unit.description} (${product.unit.code})`
            }]
            return newCodes
        })
        
        
        const productIds = getValues('lines').map(line => line.product_id)
        const data = {
            department_id: watchDepartment,
            product_ids: JSON.stringify(productIds)
        }
        if(productIds.length > 0) {
            await SendRequest('POST', `api/companies/${companyId}/product_demands/approval_list`, data)
            .then(response => {
                if(response.status === 200) {
                    setApprovalData(response.data)
                }
            })
            .catch(err => {
                console.log(err)
                console.log(data)
            })
        }
        
    }

    const productArray = products.map(item => {
        return {value: item.id, label: item.name}
    })

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <form onSubmit={handleSubmit(handleForm, handleError)}>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Departman</p>
                <div className="grid grid-cols-12 gap-2">
                {departmentInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <SelectSmall register={register} {...input} /> 
                        </div>
                        )
                })}
                </div>    
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>   
                <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                    <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Ürün</span>
                    </div>
                    <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Ürün Açıklaması</span>
                    </div>
                    <div className='col-span-2 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Miktar</span>
                    </div>
                    <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Birim</span>
                    </div>
                </div>
                {controlledItemFields.map((field, index) => {
                    return (
                        <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${field.id}`}>
                            <div className={lineInputs[0].cols}>
                                <SelectWithSearch 
                                    dataArray={productArray}
                                    control={control}
                                    name={`lines.${index}.${lineInputs[0].nameLast}`}
                                    rules={{...lineInputs[0].validator}}
                                    error={errors?.lines?.[index]?.[lineInputs[0].nameLast]?.message}
                                    actions={(e) => handleLineProductChange(e, index)}
                                />
                                
                            </div>
                            <div className={lineInputs[1].cols}>
                                <InputSmall 
                                    {...lineInputs[1]}
                                    register={register} 
                                    name={`lines.${index}.${lineInputs[1].nameLast}`} 
                                    error={errors?.lines?.[index]?.[lineInputs[1].nameLast]?.message} 
                                />
                            </div>
                            <div className={lineInputs[2].cols}>
                                <InputSmall 
                                    {...lineInputs[2]}
                                    register={register} 
                                    name={`lines.${index}.${lineInputs[2].nameLast}`} 
                                    error={errors?.lines?.[index]?.[lineInputs[2].nameLast]?.message} 
                                 />
                            </div>
                            <div className={lineInputs[3].cols}>
                                <SelectSmall 
                                    {...lineInputs[3]}
                                    data={unitCodes?.[index]}
                                    register={register} 
                                    name={`lines.${index}.${lineInputs[3].nameLast}`} 
                                    error={errors?.lines?.[index]?.[lineInputs[3].nameLast]?.message} 
                                />
                            </div>                            
                            <div className='col-span-1 mt-0.5'>
                                <div>
                                    {
                                    index === 0 
                                    ? <ButtonNewSmall label="Ekle" addClass='w-full' type="button" onClick={() => lineAppend(linesDefaults)} ></ButtonNewSmall>
                                    : <ButtonDeleteSmall addClass='w-full' type="button" onClick={() => lineRemove(index)} ></ButtonDeleteSmall>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    )
                })} 
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Açıklamalar</p>
                <div className="grid grid-cols-12 gap-2">
                {descirptionInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <TextareaSmall register={register} {...input} rows="5" /> 
                        </div>
                        )
                })}
                </div>     
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Onay Listesi</p>   
                <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                    <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>#</span>
                    </div>
                    <div className='col-span-5 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Onaylayacak Yönetici</span>
                    </div>
                    <div className='col-span-6 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Departman</span>
                    </div>
                </div>          
                {(approvalData !== null && approvalData !== undefined && typeof approvalData === 'object' && Object.keys(approvalData).length > 0) ? 
                approvalData.max_approval_level <= approvalData.user_position_level ?
                (
                    <div className='lg:grid lg:grid-cols-12 gap-2 mt-2'>
                        <div className="col-span-12">
                            Seçilen ürünler onaya gerek olmadan talep edilebilir.
                        </div>
                    </div>
                )
                :
                approvalData.approvals.map((user, index) => {
                    return (
                        <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`approval${user.id}`}>
                            <div className='col-span-1'>
                                {index+1}.
                            </div>
                            <div className="col-span-5">
                                {user.name} 
                            </div>
                            <div className="col-span-6">
                                {user.departments.map(dep => {
                                    return <span className='block' key={dep.id}> {dep.department.department_name} - {dep.position.position_name} </span>
                                })}
                            </div>
                        </div>
                    )
                })
                : null}
                <div className="w-full flex justify-end mt-5">
                    <ButtonSave type="submit">
                    </ButtonSave>
                </div>          
            </form> 
        }   
        {loading && <Loader />}
        </>
    )
}

export default CreateForm