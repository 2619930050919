import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonNew, ButtonOk, ButtonSave} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {PhoneSmall} from 'elements/inputs/Phone'
import DropdownTree from 'elements/inputs/DropdownTree'
import MultiSelectWithSearch from 'elements/inputs/MultiSelectWithSearch'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FindCategory from 'utils/FindCategory'
import { setIsOpenSupplierCreate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useSupplierInput, useUserInput } from 'modules/companyModule/pages/suppliers/InputArray'

const CreateForm = ({update, setUpdate, categories, taxonomies}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const cities = useSelector(state => state.sharedData.cities)
    const districts = useSelector(state => state.sharedData.districts)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filteredDistricts, setFilteredDistricts] = useState([]) 
    // const [categorySelectArray, setCategorySelectArray] = useState([])
    const [taxonomySelectArray, setTaxonomySelectArray] = useState([])
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            supplier: {
                business_areas: null,
                company_name: null,
                tax_number: null,
                tax_department: null,
                address: null,
                district_id: null,
                city_id: null,
                phone: null,
                email: null
            },
            user: {
                name: null,
                phone: null,
                email: null,
                password: null,
                password_confirmation: null
            },
            // categories: {},
            taxonomies: {}, 
            
        }
    })

    // const watchCategoriesArray = watch("categories")
    
    // useEffect(() => {
    //     const getArrayRecursive = (cats, catArray) => {
    //         cats.forEach((cat, index) => {
    //             catArray.push({
    //                 id: cat.id, 
    //                 name: cat.category_name, 
    //                 parentId: cat.parent_id,
    //                 parentItemId: cat.parent_item_id,
    //                 options: cat.category_items.map(option => {
    //                 return {key: option.id,
    //                 value: option.item_name}
    //             })})
    //             setValue(`categories.${cat.id}`, null)
    //             if(cat.all_sub_categories.length > 0) {
    //                 getArrayRecursive(cat.all_sub_categories, catArray)
    //             }
    //         })
    //         return catArray
    //     }

    //     const getCategories = async () => {
    //         if(categories) {
    //             let catArray = []
    //             getArrayRecursive(categories, catArray)
    //             setCategorySelectArray(catArray)
    //         }
    //     }    

    //     getCategories()
    // }, [categories])

    useEffect(() => {
        const modifyTaxArray = (taxArray) => {
            if(taxArray && Array.isArray(taxArray)) {
                return taxArray.map(item => {
                            return {
                                key: item.id,
                                value: item.taxon_name,
                                children: modifyTaxArray(item.all_sub_taxa)
                            }
                        })
            }
            return null
        }
    
        
        const taxArray = (taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) ?
                            taxonomies.map(t => {
                                setValue(`taxonomies.${t.id}`, null)
                                return {
                                    id: t.id,
                                    name: t.taxonomy_name,
                                    nodes: modifyTaxArray(t.taxa)
                                }
                            }) :
                            []
        setTaxonomySelectArray(taxArray)
    }, [taxonomies])

    const handleChangeCity = (id) => {
        setValue('supplier.district_id', null)
        if(id) {
            let filteredData = districts.filter(item => {
                return item.cityId === parseInt(id)
            })
            setFilteredDistricts(filteredData)
        }
    }

    const supplierInputs = useSupplierInput(errors, businessAreas, cities, filteredDistricts, handleChangeCity)
    const userInputs = useUserInput(errors, getValues)

    const handleForm = async (data) => {
        // console.log(data)
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/suppliers`, data)
                .then(res => {
                    if(res.status === 201) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenSupplierCreate())
    }

    // const resetSubCategories = (category) => {
    //     category.all_sub_categories.forEach(subcat => {
    //         setValue(`categories.${subcat.id}`, null)
    //         if(subcat.all_sub_categories.length > 0) {
    //             resetSubCategories(subcat)               
    //         }
    //     })
    // }

    // const handleChangeCategory = (id) => {
    //     let foundCategory = FindCategory(categories, id)
    //     if(foundCategory != null) {
    //         resetSubCategories(foundCategory)
    //     }        
    // }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <form onSubmit={handleSubmit(handleForm)}>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Tedarikçi Firma Bilgileri</p>
                <div className="grid grid-cols-12 gap-2">
                {supplierInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            {input.type === 'select' ? 
                                <SelectSmall register={register} {...input} /> :
                                null
                            }
                            {input.type === 'text' ? 
                                <InputSmall register={register} {...input} /> :
                                null
                            }
                            {input.type === 'phone' ? 
                                <PhoneSmall register={register} {...input} /> :
                                null
                            }
                            {input.type === 'multiselect' ? 
                                <MultiSelectWithSearch 
                                    control={control}
                                    {...input}
                                />
                            : null
                            }
                        </div>
                        )
                })}
                </div>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Kullanıcı Bilgileri</p>
                <div className="grid grid-cols-12 gap-2">
                {userInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            {input.type == 'select' ? 
                                <SelectSmall register={register} {...input} /> :
                                null
                            }
                            {input.type == 'text' ||  input.type == 'password'? 
                                <InputSmall register={register} {...input} /> :
                                null
                            }
                            {input.type == 'phone'? 
                                <PhoneSmall register={register} {...input} /> :
                                null
                            }
                        </div>
                        )
                })}
                </div>
                {/* <div>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Kategoriler</p>
                    {categorySelectArray.map(category => {
                        if(category.parentId == null || watchCategoriesArray[category.parentId] == category.parentItemId) {
                            return (
                                <div key={category.id}>
                                    <SelectSmall                                 
                                        type='select'
                                        name={`categories.${category.id}`}
                                        register={register}
                                        label={category.name}
                                        placeholder={category.name}
                                        validator={{
                                            onChange: (e) => handleChangeCategory(category.id)
                                        }}
                                        error={null}
                                        data={category.options}
                                    />
                                </div>
                            )
                        }                        
                    })
                    }                    
                </div> */}
                <div className='my-4'>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Kategoriler</p>
                    {(taxonomySelectArray && Array.isArray(taxonomySelectArray) && taxonomySelectArray.length > 0) ?
                        taxonomySelectArray.map(ta => {
                            return (
                                <div key={`taxonomy_${ta.id}`} className="mb-2">
                                    <DropdownTree                                         
                                        data = {ta.nodes}
                                        control = {control} 
                                        name = {`taxonomies.${ta.id}`} 
                                        validator = {{
                                            
                                        }}
                                        error = {errors?.taxonomies?.[ta.id]?.message}
                                        actions = {(e) => null}
                                        placeholder = "Seçiniz"                                    
                                        label = {ta.name}
                                        nullOption = {true}
                                    />
                                </div>
                            )
                        })
                    : null}
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonSave type="submit">
                    </ButtonSave>
                </div>          
            </form> 
        }   
        {loading && <Loader />}
        </>
    )
}

export default CreateForm