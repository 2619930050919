import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { DateTime } from 'luxon'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import { ProcurementLineTextType, 
    ProcurementSupplierSelectionTypes, 
    ProcurementProposalTypes, 
    ProcurementPaymentTypes,
    ProcurementDeliveryTypes } from 'data'

function DetailInfo({procurement}) {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [loading, setLoading] = useState(false)

    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy T')
    }

    const lineTextTypeDesc = (code) => {
        let selectedCode = ProcurementLineTextType().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }

    const proposalTypeDesc = (code) => {
        let selectedCode = ProcurementProposalTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }

    const paymentTypeDesc = (code) => {
        let selectedCode = ProcurementPaymentTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value.slice(0,25)
        }
        return ''
    }

    const deliveryTypeDesc = (code) => {
        let selectedCode = ProcurementDeliveryTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value.slice(0,25)
        }
        return ''
    }

    const proposalRevisionDesc = (code) => {
        if(code) {
            return 'Tedarikçi revize tekliflerinde fiyat arttıramaz.'
        }
        return 'Tedarikçi revize tekliflerinde fiyat arttırabilir.'
    }
    const proposalCancelDesc = (code) => {
        if(code) {
            return 'Tedarikçi verdiği teklifi iptal edemez.'
        }
        return 'Tedarikçi verdiği teklifi iptal edebilir.'
    }

    const formatStatus = (status) => {
        let color = ''
        switch (status) {
            case 'uncompleted': 
                color = 'text-orange-500'
                break
            case 'completed': 
                color = 'text-emerald-500'
                break
            case 'cancelled': 
                color = 'text-rose-500'
                break
            case 'archived': 
                color = 'text-indigo-500'
                break
            default:
                color = 'text-gray-500'
        }

        return <span className={`${color} font-semibold whitespace-nowrap`}>Süreç Devam Ediyor</span>
                   
    }

    

    const downloadTechSpec = () => {
        const getTechSpec = async () => {
            setLoading(true)
            await SendRequest('DOWNLOAD', `api/suppliers/${companyId}/procurements/${procurement.id}/download_technical_specification`)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${procurement.procurement_no}.pdf`)
                        document.body.appendChild(link)
                        link.click()
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTechSpec()
    }

    const downloadLineTechSpec = (lineId) => {
        const getTechSpec = async () => {
            const downloadUrl = `api/suppliers/${companyId}/procurements/${procurement.id}/lines/${lineId}/download_technical_specification`
            setLoading(true)
            await SendRequest('DOWNLOAD', downloadUrl)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${procurement.procurement_no}-${lineId}.pdf`)
                        document.body.appendChild(link)
                        link.click()
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTechSpec()
    }

    return (
        <div className="">
                {(procurement !==null && Object.keys(procurement).length > 0) &&
                <>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 gap-y-2">
                    <div className="col-span-1">
                        <table className="w-full">
                            <tbody>
                                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Talep No:</td>
                                    <td className="px-2 py-0.5">{procurement.procurement_no}</td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Talep Başlığı:</td>
                                    <td className="px-2 py-0.5">{procurement.title}</td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Sektör: </td>
                                    <td className="px-2 py-0.5">{procurement.business_area.name}</td>
                                </tr>                      
                            </tbody>
                        </table>
                    </div>
                    <div className="col-span-1">
                        <table className="w-full">
                            <tbody>                                                                                                      
                                <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Bitiş Zamanı: </td>
                                    <td className="px-2 py-0.5">{formatDate(procurement.end_date)}</td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Durum: </td>
                                    <td className="px-2 py-0.5">
                                    {formatStatus(procurement.status)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-span-1 lg:col-span-2">
                        <table className="w-full">
                            <tbody>                                                                                                      
                                <tr className="border-y border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Teklif Toplama Türü: </td>
                                    <td className="px-2 py-0.5">{proposalTypeDesc(procurement.proposal_type)}</td>
                                </tr> 
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Teklif Revizesi: </td>
                                    <td className="px-2 py-0.5">{proposalRevisionDesc(procurement.is_proposal_increase_not_allowed)}</td>
                                </tr>                                                                       
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Teklif İptali: </td>
                                    <td className="px-2 py-0.5">{proposalCancelDesc(procurement.is_proposal_cancel_not_allowed)}</td>
                                </tr> 
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 gap-y-2">
                    <div className="col-span-1">
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ödeme Bilgileri</p>
                        <table className="w-full">
                            <tbody>                                                                
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Para Birimi: </td>
                                    <td className="px-2 py-0.5">
                                        {procurement.currency.code} ({procurement.currency.description}) 
                                        {procurement.is_currency_flexible ? ' (Tedarikçi değiştirebilir)' : ' (Tedarikçi değiştiremez)'}
                                    </td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Ödeme Şekli: </td>
                                    <td className="px-2 py-0.5">
                                        {paymentTypeDesc(procurement.payment_type)} {procurement.payment_type === 'deferred' ? ` - ${procurement.deferral_days} Gün` : null}
                                        {procurement.is_payment_type_flexible ? ' (Tedarikçi değiştirebilir)' : ' (Tedarikçi değiştiremez)'}
                                    </td>
                                </tr>   
                            </tbody>
                        </table>
                    </div>
                    <div className="col-span-1">
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Sevkiyat Bilgileri</p>
                        <table className="w-full">
                            <tbody>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Sevkiyat Yöntemi: </td>
                                    <td className="px-2 py-0.5">
                                        {deliveryTypeDesc(procurement.delivery_type)} 
                                    </td>
                                </tr> 
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Teslim Zamanı: </td>
                                    <td className="px-2 py-0.5">{formatDate(procurement.delivery_date)}</td>
                                </tr>  
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-0.5">Teslim Adresi: </td>
                                    <td className="px-2 py-0.5">{procurement.delivery_address}</td>
                                </tr>  
                            </tbody>
                        </table>
                    </div>
                </div>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-300 text-left">
                            <th className="px-1 py-0.5">Ürün</th>
                            <th className="px-1 py-0.5 lg:w-[50%]">Ürün Açıklaması</th>
                            <th className="px-1 py-0.5">Miktar</th>
                            <th className="px-1 py-0.5">Şartname</th>
                        </tr>
                    </thead>
                    <tbody>
                        {procurement.lines.map(line => {
                            return (
                                <tr key={line.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5 whitespace-nowrap">{line.product.name}</td>
                                    <td className="px-1 py-0.5 lg:w-[50%]">
                                        <div className="whitespace-pre-line lg:max-w-[500px]">
                                        {line.description_text === 'null' ? '' : line.description_text}
                                        </div>                                        
                                    </td>
                                    <td className="px-1 py-0.5">{line.quantity} {line.unit_code.description}</td>
                                    <td className="px-1 py-0.5">
                                        {line.tech_spec_type === 'use_product_file' || line.tech_spec_type === 'use_uploaded_file' ? 
                                            <button type="button" className="text-md text-sky-500 hover:text-sky-800 underline whitespace-nowrap" onClick={() => downloadLineTechSpec(line.id)}>
                                                İndirmek için tıklayın.
                                            </button>
                                            : 'Şartname Yok'                                             
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Açıklamalar</p>
                {procurement.description ? 
                    <p className="whitespace-pre-line">{procurement.description}</p>
                : 
                    <p className="whitespace-pre-line">Açıklama yapılmamış</p>
                }                
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Teknik Şartname</p>
                {procurement.is_tech_spec_available ? 
                    <p className="whitespace-pre-line">
                        <button type="button" className="text-md text-sky-500 hover:text-sky-800 underline" onClick={downloadTechSpec}>
                            İndirmek için tıklayın.
                        </button>                    
                    </p>
                    :
                    <p className="whitespace-pre-line">
                        Şartname yüklenmemiş                   
                    </p>
                }
                </>}
                {loading && <Loader />}
            </div>  
    )
}

export default DetailInfo