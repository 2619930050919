import { DateTime } from 'luxon'
import TableActions from 'modules/companyModule/pages/productDemands/approvals/TableActions'
import TableLayout from 'elements/tables'
import { useSelector } from 'react-redux'
import CustomPopover from 'elements/popovers'
import { ButtonShowDetail } from 'elements/buttons/actionButtons'
import FormatNumberWithSeperator from 'utils/FormatNumberWithSeperator'

const Table = ({data, actions, progressPending, pagination, pageMeta}) => {

    const DateCell = ({createdDate, deliveryDate}) => {
        let parsedCreatedDate = DateTime.fromISO(createdDate)
        let parsedDeliveryDate = DateTime.fromISO(deliveryDate)
        
        return (
            <div className="grid grid-cols-1 gap-y-1">
                {parsedCreatedDate.isValid ? 
                    <p className="whitespace-nowrap">
                        <span className="font-semibold">Oluşturulma: </span> 
                        <span>{parsedCreatedDate.toFormat('dd.LL.yyyy T')}</span>
                    </p>
                    : null
                }
                {parsedDeliveryDate.isValid ? 
                    <p className="whitespace-nowrap">
                        <span className="font-semibold">Teslim: </span> 
                        <span>{parsedDeliveryDate.toFormat('dd.LL.yyyy T')}</span>
                    </p>
                    : null
                }
            </div>
        )        
    }

    const StatusCell = ({status, approvals}) => {
        const content = (
            <div className='text-xs'>
                <ol className="list-decimal list-inside">
                    {approvals.map(approval => {
                        return (
                            <li key={approval.id} className='whitespace-nowrap my-1'>
                                <span className='font-semibold'>
                                    {approval?.user?.name}: 
                                </span>
                                {approval.is_accepted ? ' Kabul Etti' : null}
                                {approval.is_rejected ? ' RedEtti' : null}
                                {!approval.is_rejected && !approval.is_accepted ? ' Bekleniyor' : null}
                            </li>
                        )
                        
                    })}
                </ol>
            </div>
        )

        switch (status) {
            case 'rejected': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Reddedildi</span>
                break
            case 'delivery_rejected': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Depo Teslimi Reddedildi</span>
                break
            case 'rejected_by_warehouse': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Depo Tarafından Reddedildi</span>
                break
            case 'waiting_approvals': 
                return <div className="flex items-center justify-center gap-x-0">
                            <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">
                                Onaylar Bekleniyor                            
                            </span>
                            <CustomPopover content={content} >
                                <ButtonShowDetail label={null} />
                            </CustomPopover>
                        </div>
                break
            case 'waiting_delivery_approval': 
                return <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">Teslim Onayı Bekleniyor</span>
                break
            case 'waiting_delivery': 
                return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Teslim Alınmayı Bekliyor</span>
                break
            case 'completed': 
                return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Teslim Alındı</span>
                break
            case 'cancelled': 
                return <span className="py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap">İptal Edildi</span>
                break
            case 'archived': 
                return <span className="py-0.5 px-2 rounded bg-indigo-500 text-indigo-50 whitespace-nowrap">Arşivlendi</span>
                break
            default:
                return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">Bilinmiyor</span>
        }
                   
    }

    const LinesCell = ({lines}) => {
        const content = (
            <div className='text-xs'>
                <ol className="list-decimal list-inside">
                    {lines.map(line => {
                        return (
                            <li key={line.id} className='whitespace-nowrap my-1'>
                                <span className='font-semibold'>
                                    {line.product.name} {line.description_text ? `- ${line.description_text}` : null} : 
                                </span>
                                {line.quantity} {line.unit_code.description}
                            </li>
                        )
                        
                    })}
                </ol>
            </div>
        )

        return (
            <CustomPopover content={content} >
                <ButtonShowDetail label={lines.length} />
            </CustomPopover>
        ) 
        
    }

    const columns = [
        {
            name: 'Talep No',
            selector: (row) => {return row.demand_no},
            headerMobileHidden: false,
        },
        {
            name: 'Tarih',
            selector: (row) => {return <DateCell createdDate={row.created_at} deliveryDate={row.delivery_date} />},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return <StatusCell status={row.status} approvals={row.approvals} />},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Sayısı',
            selector: (row) => {return <LinesCell lines={row.lines} />},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return `${row.demand_no} (${DateTime.fromISO(row.created_at).toFormat('dd.LL.yyyy T')})`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
