import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IconContext } from "react-icons"
import { FaArrowCircleRight, FaClipboardCheck, FaBell, FaCheckCircle } from 'react-icons/fa'
import SendRequest from 'utils/SendRequest'
import Header from 'modules/companyModule/pages/home/Header'

function ApprovalCards() {
    const navigate = useNavigate()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const placeholderData = {
        procurements: 0,
        warehouse_requests: 0,
        department_requests: 0,
        product_demands: 0,
    }
    const [data, setData] = useState(placeholderData)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/statistics/waiting_approvals`)
                .then(response => {
                    if(response.status === 200) {
                        setData(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getData()
    }, [updateData])

    const Bell = () => {
        return (
            <IconContext.Provider value={{ className: "h-6 w-6 flex-none text-rose-600 bell-shake" }}>
                <FaBell />
            </IconContext.Provider>
        )
    }

    const Check = () => {
        return (
            <IconContext.Provider value={{ className: "h-6 w-6 flex-none text-emerald-600" }}>
                <FaCheckCircle />
            </IconContext.Provider>
        )
    }

    return (
        <div className="w-full">
            <Header title="Onay İşlemleri" logo={<FaClipboardCheck />} />
            <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-y-4 lg:gap-x-4">
                <div className="w-full h-24 flex flex-col justify-start items-center gap-x-4 border border-gray-400 bg-white">
                    <div className="w-full flex-grow text-gray-800 px-4 py-1 flex items-center justify-start gap-x-2">
                        <div className="flex-none">
                            {data.procurements > 0 ? 
                            <Bell />
                            : 
                            <Check />
                            }
                        </div>
                        <div className="flex-grow">
                            <p className="text-base whitespace-nowrap">Satın Alma</p>
                            <p className="text-xl font-semibold whitespace-nowrap">
                                {data.procurements > 0 ?
                                `${data.procurements} Adet Bekleyen Onay`
                                :
                                `Bekleyen Onay Yok`
                                }
                            </p>
                        </div>                        
                    </div>
                    <div className="w-full flex-none bg-gray-300 border-t border-gray-400 px-4 py-2">
                        <button 
                            onClick={() => navigate('/company/procurement_approvals')}
                            className="text-gray-600 w-full text-right block flex justify-end items-center gap-x-1" 
                            type="button"
                        >
                            <span>Hepsini Gör</span>
                            <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
                                <FaArrowCircleRight />
                            </IconContext.Provider>
                        </button>
                    </div>
                </div>
                <div className="w-full h-24 flex flex-col justify-start items-center gap-x-4 border border-gray-400 bg-white">
                    <div className="w-full flex-grow text-gray-800 px-4 py-1 flex items-center justify-start gap-x-2">
                        <div className="flex-none">
                            {data.warehouse_requests > 0 ? 
                            <Bell />
                            : 
                            <Check />
                            }
                        </div>
                        <div className="flex-grow">
                            <p className="text-base whitespace-nowrap">Depo Satın Alma Talebi</p>
                            <p className="text-xl font-semibold whitespace-nowrap">
                                {data.warehouse_requests > 0 ?
                                `${data.warehouse_requests} Adet Bekleyen Onay`
                                :
                                `Bekleyen Onay Yok`
                                }
                            </p>
                        </div>                        
                    </div>   
                    <div className="w-full flex-none bg-gray-300 border-t border-gray-400 px-4 py-2">
                        <button 
                            onClick={() => navigate('/company/procurement_request_approvals/warehouses')}
                            className="text-gray-600 w-full text-right block flex justify-end items-center gap-x-1" 
                            type="button"
                        >
                            <span>Hepsini Gör</span>
                            <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
                                <FaArrowCircleRight />
                            </IconContext.Provider>
                        </button>
                    </div>
                </div>
                <div className="w-full h-24 flex flex-col justify-start items-center gap-x-4 border border-gray-400 bg-white">
                    <div className="w-full flex-grow text-gray-800 px-4 py-1 flex items-center justify-start gap-x-2">
                        <div className="flex-none">
                            {data.department_requests > 0 ? 
                            <Bell />
                            : 
                            <Check />
                            }
                        </div>
                        <div className="flex-grow">
                            <p className="text-base whitespace-nowrap">Departman Satın Alma Talebi</p>
                            <p className="text-xl font-semibold whitespace-nowrap">
                                {data.department_requests > 0 ?
                                `${data.department_requests} Adet Bekleyen Onay`
                                :
                                `Bekleyen Onay Yok`
                                }
                            </p>
                        </div>                        
                    </div> 
                    <div className="w-full flex-none bg-gray-300 border-t border-gray-400 px-4 py-2">
                        <button 
                            onClick={() => navigate('/company/procurement_request_approvals/departments')}
                            className="text-gray-600 w-full text-right block flex justify-end items-center gap-x-1" 
                            type="button"
                        >
                            <span>Hepsini Gör</span>
                            <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
                                <FaArrowCircleRight />
                            </IconContext.Provider>
                        </button>
                    </div>
                </div>
                <div className="w-full h-24 flex flex-col justify-start items-center gap-x-4 border border-gray-400 bg-white">
                    <div className="w-full flex-grow text-gray-800 px-4 py-1 flex items-center justify-start gap-x-2">
                        <div className="flex-none">
                            {data.product_demands > 0 ? 
                            <Bell />
                            : 
                            <Check />
                            }
                        </div>
                        <div className="flex-grow">
                            <p className="text-base whitespace-nowrap">Ürün Talebi</p>
                            <p className="text-xl font-semibold whitespace-nowrap">
                                {data.product_demands > 0 ?
                                `${data.product_demands} Adet Bekleyen Onay`
                                :
                                `Bekleyen Onay Yok`
                                }
                            </p>
                        </div>                        
                    </div> 
                    <div className="w-full flex-none bg-gray-300 border-t border-gray-400 px-4 py-2">
                        <button 
                            onClick={() => navigate('/company/product_demand_approvals')}
                            className="text-gray-600 w-full text-right block flex justify-end items-center gap-x-1" 
                            type="button"
                        >
                            <span>Hepsini Gör</span>
                            <IconContext.Provider value={{ className: "h-3 w-3 flex-none" }}>
                                <FaArrowCircleRight />
                            </IconContext.Provider>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default ApprovalCards