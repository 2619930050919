import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProductStockTransferAdd } from 'stores/DrawersSlice' 
import StockTransferForm from 'modules/companyModule/pages/warehouses/stocks/StockTransferForm'

function StockTransfer({product, update, setUpdate, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenWarehouseProductStockTransferAdd)
    const setIsOpen = () => dispatch(setIsOpenWarehouseProductStockTransferAdd())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Stok Transferi Ekle" >
            <StockTransferForm product={product} update={update} setUpdate={setUpdate} selectedWarehouse={selectedWarehouse} />
        </FormDrawer> 
    )
}

export default StockTransfer