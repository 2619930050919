import Home from 'modules/companyModule/pages/home'
import Suppliers from 'modules/companyModule/pages/suppliers'
import Supplier from 'modules/companyModule/pages/suppliers/supplier'
import SupplierUploads from 'modules/companyModule/pages/suppliers/uploads'
import SupplierSettings from 'modules/companyModule/pages/suppliers/settings'
import SupplierInvitations from 'modules/companyModule/pages/suppliers/invitations'
import Products from 'modules/companyModule/pages/products'
import Product from 'modules/companyModule/pages/products/product'
import ProductUploads from 'modules/companyModule/pages/products/uploads'
import ProductSettings from 'modules/companyModule/pages/products/settings'
import Procurements from 'modules/companyModule/pages/procurementProcesses/procurements'
import Procurement from 'modules/companyModule/pages/procurementProcesses/procurements/procurement'
import Approvals from 'modules/companyModule/pages/procurementProcesses/approvals'
import UserProfile from 'modules/authModule/pages/profile'
import UserSetting from 'modules/authModule/pages/settings'
import CompanyUserSettings from 'modules/companyModule/pages/companies/settings/users'
import CompanyProcurementSettings from 'modules/companyModule/pages/companies/settings/procurements'
import DepartmentSettings from 'modules/companyModule/pages/companies/settings/departments'
import PositionSettings from 'modules/companyModule/pages/companies/settings/positions'
import WarehouseSettings from 'modules/companyModule/pages/companies/settings/warehouses'
import CostTypeSettings from 'modules/companyModule/pages/companies/settings/costTypes'
import CompanySettings from 'modules/companyModule/pages/companies/settings'
import ProductDemands from 'modules/companyModule/pages/productDemands/demands'
import ProductDemandApprovals from 'modules/companyModule/pages/productDemands/approvals'
import {default as SupplierReports} from 'modules/companyModule/pages/reports/suppliers'
import {default as ProductReports} from 'modules/companyModule/pages/reports/products'
import {default as ProductTypeReports} from 'modules/companyModule/pages/reports/productTypes'
import {default as BusinessAreaReports} from 'modules/companyModule/pages/reports/businessAreas'
import Warehouses from 'modules/companyModule/pages/warehouses'
import WarehouseDemands from 'modules/companyModule/pages/warehouses/demands'
import WarehouseStocks from 'modules/companyModule/pages/warehouses/stocks'
import WarehouseTransferApprovals from 'modules/companyModule/pages/warehouses/transferApprovals'
import WarehouseProcurementRequests from 'modules/companyModule/pages/warehouses/procurementRequests'
import ProcurementRequestApprovals from 'modules/companyModule/pages/procurementRequestApprovals'
import DepartmentProcurementRequestApprovals from 'modules/companyModule/pages/procurementRequestApprovals/departmentRequests'
import WarehouseProcurementRequestApprovals from 'modules/companyModule/pages/procurementRequestApprovals/warehouseRequests'
import ProcurementWarehouseRequests from 'modules/companyModule/pages/procurementProcesses/warehouseRequests'
import ProcurementDepartmentRequests from 'modules/companyModule/pages/procurementProcesses/departmentRequests'
import DepartmentProcurementRequests from 'modules/companyModule/pages/departmentProcurementRequests/requests'

function CompanyRoutes()
{
    return [
        {
          index: true,
          element: <Home key={Date.now()} />
        },
        {
          path: 'company/suppliers',
          element: <Suppliers />,
        },
        {
          path: 'company/suppliers/:id',
          element: <Supplier />,
        },
        {
          path: 'company/suppliers/uploads',
          element: <SupplierUploads />,
        },
        {
          path: 'company/suppliers/invitations',
          element: <SupplierInvitations />,
        },
        {
          path: 'company/suppliers/settings',
          element: <SupplierSettings />,
        },
        {
          path: 'company/products',
          element: <Products />,
        },
        {
          path: 'company/products/:id',
          element: <Product />,
        },
        {
          path: 'company/products/uploads',
          element: <ProductUploads />,
        },
        {
          path: 'company/products/settings',
          element: <ProductSettings />,
        },
        {
          path: 'company/procurements',
          element: <Procurements />,
        },
        {
          path: 'company/procurements/:id',
          element: <Procurement />,
        },
        {
          path: 'company/procurement_approvals',
          element: <Approvals />,
        },
        {
          path: 'company/procurements/warehouse_requests',
          element: <ProcurementWarehouseRequests />,
        },
        {
          path: 'company/procurements/department_requests',
          element: <ProcurementDepartmentRequests />,
        },
        {
          path: 'company/product_demands',
          element: <ProductDemands />,
        },
        {
          path: 'company/departments/procurement_requests',
          element: <DepartmentProcurementRequests />,
        },
        {
          path: 'company/product_demand_approvals',
          element: <ProductDemandApprovals />,
        },
        {
          path: 'company/warehouses',
          element: <Warehouses />,
          children: [
            {
              path: 'demands',
              element: <WarehouseDemands />
            },
            {
              path: 'stocks',
              element: <WarehouseStocks />
            },
            {
              path: 'transfer_approvals',
              element: <WarehouseTransferApprovals />
            },
            {
              path: 'procurement_requests',
              element: <WarehouseProcurementRequests />
            }
          ]
        },
        {
          path: 'company/procurement_request_approvals',
          element: <ProcurementRequestApprovals />,
          children: [
            {
              path: 'departments',
              element: <DepartmentProcurementRequestApprovals />
            },
            {
              path: 'warehouses',
              element: <WarehouseProcurementRequestApprovals />
            }
          ]
        },
        {
          path: 'company/settings',
          element: <CompanySettings />,
          children: [
            {
              path: 'users',
              element: <CompanyUserSettings />
            },
            {
              path: 'procurements',
              element: <CompanyProcurementSettings />
            },
            {
              path: 'departments',
              element: <DepartmentSettings />
            },
            {
              path: 'positions',
              element: <PositionSettings />
            },
            {
              path: 'warehouses',
              element: <WarehouseSettings />
            },
            {
              path: 'cost_types',
              element: <CostTypeSettings />
            }
          ]
        },
        {
          path: 'company/reports/suppliers',
          element: <SupplierReports />,
        },
        {
          path: 'company/reports/business_areas',
          element: <BusinessAreaReports />,
        },
        {
          path: 'company/reports/products',
          element: <ProductReports />,
        },
        {
          path: 'company/reports/product_types',
          element: <ProductTypeReports />,
        },        
        {
          path: 'user-setting',
          element: <UserSetting />
        },
        {
          path: 'user-profile',
          element: <UserProfile />
        }
      ]
}

export default CompanyRoutes