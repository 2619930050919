import { utils, writeFileXLSX } from 'xlsx'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

function ExportXslx(data, fileName = 'ekstre') {

    const array = data.map(item => {
        return {
            product_type: item.name,
            total_procurements: item.total_procurements,
            total_proposal_price: item.price.map(i => FNWS(i.price) + ' ' + i.currency.code).join(' + '),            
        }
    })

    const header = [[
        "Ürün Türü", 
        "Tamamlanan Satın Alma", 
        "Tamamlanan Satın Alma Tutarı", 
    ]]

    const exportXsl = () => {
        const ws = utils.json_to_sheet(array)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, fileName+".xlsx")
    }

    exportXsl()
}

export default ExportXslx