import { useSelector } from 'react-redux'

export function useCompanyInput(errors, businessAreas, cities, filteredDistricts, handleChangeCity) {
    

    return [
        {
            key: "companyInput1",
            type: "select",
            name: "business_area_id",
            placeholder: "Sektör",
            label: "Sektör *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.business_area_id?.message,
            data: businessAreas,
            cols: 'col-span-12',
        },
        {
            key: "companyInput2",
            type: "text",
            name: "company_name",
            placeholder: "Firma İsmi",
            label: "Firma İsmi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.company_name?.message,
            cols: 'col-span-12',
        },
        {
            key: "companyInput3",
            type: "text",
            name: "tax_number",
            placeholder: "Vergi No",
            label: "Vergi No *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]{10,11}$/,
                            message: "10 veya 11 haneli rakam olmalı"},
            },
            error: errors?.tax_number?.message,
            cols: 'col-span-6',
        },
        {
            key: "companyInput4",
            type: "text",
            name: "tax_department",
            placeholder: "Vergi Dairesi",
            label: "Vergi Dairesi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.tax_department?.message,
            cols: 'col-span-6',
        },
        {
            key: "companyInput7",
            type: "select",
            name: "city_id",
            placeholder: "İl",
            label: "İl *",
            validator: {
                required: "Bu alan zorunludur.", 
                onChange: (e) => handleChangeCity(e.target.value)
            },
            error: errors?.city_id?.message,
            data: cities,
            cols: 'col-span-6',
        },
        {
            key: "companyInput6",
            type: "select",
            name: "district_id",
            placeholder: "İlçe",
            label: "İlçe *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.district_id?.message,
            data: filteredDistricts,
            cols: 'col-span-6',
        },        
        {
            key: "companyInput5",
            type: "text",
            name: "address",
            placeholder: "Firma Adresi",
            label: "Firma Adresi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.address?.message,
            cols: 'col-span-12',
        },        
        {
            key: "companyInput8",
            type: "phone",
            name: "phone",
            placeholder: "Firma Telefonu",
            label: "Firma Telefonu *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},
                pattern: {value: /^\+90 \((?!0|1)\d{3}\) \d{3}-\d{2}-\d{2}$/,
                            message: "Geçerli bir telefon numarası olmalı"}
            },
            error: errors?.phone?.message,
            cols: 'col-span-6',
        },
        {
            key: "companyInput9",
            type: "text",
            name: "email",
            placeholder: "Firma E-Posta Adresi",
            label: "Firma E-Posta Adresi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message,
            cols: 'col-span-6',
        },   
    ]
}

