import React from 'react'
import ResetPasswordForm from 'modules/authModule/pages/resetPassword/ResetPasswordForm'
import logo from 'assets/img/marlaport-white.png'

const ForgotPassword = () => {

    return (
        <div className="w-full py-10 bg-login2 bg-no-repeat bg-cover px-2 flex justify-center items-center">
            <div className="max-w-md bg-gray-100 shadow-lg rounded-lg md:max-w-lg lg:min-w-[28rem]">
                <div className="w-full h-full flex flex-col justify-start lg:min-h-[45vh]">
                    <p className="text-xl flex justify-between items-center h-16 w-full bg-blue-900 text-white rounded-t-lg px-8">
                        <span>Şifre Sıfırlama</span>
                        <img src={logo} className="h-6"/>
                    </p>
                    <ResetPasswordForm />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword