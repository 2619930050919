import { useSelector } from 'react-redux' 

function usePartyValidation() {
    const productTypeLength = useSelector(state => state.sharedData.productTypes.length)
    const unitCodeLength = useSelector(state => state.sharedData.unitCodes.length)
    return {
        name: {
            required: "Bu alan zorunludur.", 
            minLength: {value: 4, 
                message: "En az 4 karakter olmalı"}, 
            maxLength: {value: 255, 
                        message:"En fazla 255 karakter olmalı"},
        },
        product_type_id: {
            setValueAs: v => parseInt(v),
            required: "Bu alan zorunludur.", 
            min: {value: 1, 
                message: "En az 1 olmalı"}, 
            max: {value: productTypeLength, 
                        message: `En fazla ${productTypeLength} olmalı`},
        },
        product_code: {
            minLength: {value: 4, 
                message: "En az 4 karakter olmalı"}, 
            maxLength: {value: 255, 
                        message:"En fazla 255 karakter olmalı"},
        },
        barcode: {
            minLength: {value: 4, 
                message: "En az 4 karakter olmalı"}, 
            maxLength: {value: 255, 
                        message:"En fazla 255 karakter olmalı"},
        },
        description: {
            minLength: {value: 4, 
                message: "En az 4 karakter olmalı"}, 
            maxLength: {value: 255, 
                        message:"En fazla 255 karakter olmalı"},
        },
        unit_code_id: {
            setValueAs: v => parseInt(v),
            required: "Bu alan zorunludur.", 
            min: {value: 1, 
                message: "En az 1 olmalı"}, 
            max: {value: unitCodeLength, 
                        message: `En fazla ${unitCodeLength} olmalı`},
        },
    }
}

export {usePartyValidation}