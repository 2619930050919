export default function Header({title, button = null}) {
   
    return ( 
        <div className="border border-gray-400 bg-gray-100 mb-1 p-1 flex justify-between items-center">            
            <h2 className="text-base text-gray-800 font-semibold">{title}</h2>
            {button}
        </div>
    ) 
}

