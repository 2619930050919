import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/suppliers/CreateForm'

function Create({update, setUpdate, categories, taxonomies}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenSupplierCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenSupplierCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Tedarikçi Ekle">
            <CreateForm update={update} setUpdate={setUpdate} categories={categories} taxonomies={taxonomies} />
        </FormDrawer> 
    )
}

export default Create