import { Link } from 'react-router-dom'

function TableButtonRed({title, icon = null, borderTop = false, ...props}) {
    return (
        <button className={`w-full flex items-center px-3 py-1.5 leading-5 text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:bg-red-500 transition duration-150 ease-in-out ${borderTop ? 'border-t border-1 border-white' : undefined}`} {...props}>
            {icon !== null && icon}
            <span className="ml-3 whitespace-nowrap">{title}</span>
        </button>
    )
    
}

export default TableButtonRed