import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierProjectEdit } from 'stores/DrawersSlice' 
import EditForm from 'modules/supplierModule/pages/companies/projects/EditForm'

function Edit({project, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenSupplierProjectEdit)
    const setIsOpen = () => dispatch(setIsOpenSupplierProjectEdit())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Proje Bilgilerini Düzenle">
            <EditForm project={project} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Edit