
const steps = [
    {
        key: 1,
        value: 'Genel Bilgiler',
    },
    {
        key: 2,
        value: 'Ödeme ve Sevkiyat',
    },
    {
        key: 3,
        value: 'Ürünler',
    },
    {
        key: 4,
        value: 'Açıklamalar ve Şartname',
    },
    {
        key: 5,
        value: 'Tedarikçi Seçimi',
    },
    {
        key: 6,
        value: 'Onay Listesi',
    },
    {
        key: 7,
        value: 'Özet',
    },
]

function StepList({currentStep}) {
    return (
        <div className="w-full">
            <div className="lg:flex justify-between items-center lg:gap-x-2 border-b border-sky-300">
                {steps.map(step => {
                    return (
                        <div key={step.key} 
                            className={`grow text-center rounded-t p-2 border-x border-t border-sky-400 lg:block ${currentStep === step.key ? 'text-white bg-sky-400 shadow-[2px_0px_2px_0px_rgba(0,0,0,0.3)] block' : 'text-gray-700 bg-white hidden'}`}
                        >
                            {step.value}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export {steps, StepList}