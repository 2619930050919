import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenEdit } from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/DrawersSlice' 
import Loader from 'elements/Loader'
import { useTaxonInput } from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/InputArray'

const EditForm = ({taxonomy, parent, taxon, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenCreate = useSelector(state => state.companySupplierTaxonDrawers.isOpenEdit)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            taxon_name: null,
            type: 'supplier',
            parent_id: null,
            taxonomy_id: null,
        }
    })

    const inputs = useTaxonInput(errors)

    useEffect(() => {
        if(taxon !== null && Object.keys(taxon).length > 0) {
            setValue('taxon_name', taxon.taxon_name)          
            setValue('type', taxon.type)    
            setValue('taxonomy_id', taxonomy?.id ?? null)
            setValue('parent_id', parent?.id ?? null)
            setError(null)
            setStatus(false)
        } else {
            reset()
        }
    }, [taxon, isOpenCreate])

    useEffect(() => {
        if(!isOpenCreate) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenCreate])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/taxa/${taxon.id}`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenEdit(false))
    }   

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <p className="text-gray-700 text-xs">
                Sınıflandırma
            </p>
            <p className="mb-1 w-full rounded-sm px-1 py-1 text-xs border border-gray-400 cursor-not-allowed">
                    {taxonomy?.taxonomy_name}
            </p>
            <p className="text-gray-700 text-xs">
                Üst Sınıf
            </p>
            <p className="mb-1 w-full rounded-sm px-1 py-1 text-xs border border-gray-400 cursor-not-allowed">
                    {parent?.taxon_name ?? 'Yok'}
            </p>
            <div className="grid grid-cols-12 gap-2">
            {inputs.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        <InputSmall register={register} {...input} />
                    </div>
                    )
            })}                
            </div>
            <div className="w-full flex justify-end gap-x-1 mt-5">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose type="button" onClick={() => close()}>
                </ButtonClose>
            </div>                
        </form> 
        {loading && <Loader />}
        </>
    )
}

export default EditForm