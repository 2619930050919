import { utils, writeFileXLSX } from 'xlsx'
import { DateTime } from 'luxon'

function ExportXslx(data, fileName = 'ekstre') {

    const dateCell = (date) => {
        let parsedDate = DateTime.fromISO(date)
        
        return parsedDate.isValid ? parsedDate.toFormat('dd.LL.yyyy T') : null
    }

    const array = data.map(item => {
        return {
            supplier_name: item.supplier.company_name,
            supplier_tax_dep: item.supplier.tax_department,
            supplier_tax_no: item.supplier.tax_number,
            supplier_business_area: item.supplier.business_area.name,
            supplier_address: item.supplier.address + ' ' + item.supplier.district.name + '/' + item.supplier.city.name,
            supplier_email: item.supplier.email,
            supplier_phone: item.supplier.phone,
            procurement_no: item.procurement.procurement_no,
            procurement_title: item.procurement.title,
            procurement_start_date: dateCell(item.procurement.start_date),
            procurement_end_date: dateCell(item.procurement.end_date),
            procurement_delivery_date: dateCell(item.procurement.delivery_date),
            procurement_business_area: item.procurement.business_area.name,
            procurement_description_text: item.procurement_line.description_text,
            procurement_quantity: item.procurement_line.quantity + ' ' + item.procurement_line.unit_code.description + ' (' + item.procurement_line.unit_code.code + ')',
            proposal_description_text: item.description_text,
            price: item.price + ' ' + item.procurement.currency.code,
        }
    })

    const header = [[
        "Firma Adı", 
        "Firma Vergi Dairesi", 
        "Firma Vergi Numarası", 
        "Firma Sektör", 
        "Firma Adres", 
        "Firma E-Posta", 
        "Firma Telefon", 
        "Satın Alma Talep No", 
        "Satın Alma Başlık", 
        "Satın Alma Başlangıç", 
        "Satın Alma Bitiş", 
        "Satın Alma Teslim", 
        "Satın Alma Ürün Notu", 
        "Satın Alma Miktar", 
        "Teklif Ürün Notu", 
        "Teklif Tutarı", 
    ]]

    const exportXsl = () => {
        const ws = utils.json_to_sheet(array)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, fileName+".xlsx")
    }

    exportXsl()
}

export default ExportXslx