import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import CostTypes from "./CostTypes"
import ProcurementUsers from "./ProcurementUsers"

function CompanyProcurementSettings() {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [costTypes, setCostTypes] = useState([])
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getUsers = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/users?no_pagination=true&is_active=true`)
                .then(response => {
                    if(response.status === 200) {
                        setUsers(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getUsers()
    }, [])

    useEffect(() => {
        const getCostTypes = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/cost_types`)
                .then(response => {
                    if(response.status === 200) {
                        setCostTypes(response.data.data.sort((a,b) => a.cost_name.localeCompare(b.cost_name)))
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getCostTypes()
    }, [updateData])
    
    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800">Satın Alma</h2>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-2 md:gap-x-4">
                <div className="col-span-1">
                    <CostTypes 
                        update={updateData}
                        setUpdate={setUpdateData}
                        costTypes={costTypes}
                        users={users}
                    />
                </div>
                <div className="col-span-1">
                    <ProcurementUsers 
                        update={updateData}
                        setUpdate={setUpdateData}
                        costTypes={costTypes}
                        users={users}
                    />
                </div>
            </div>  
            {loading && <Loader />}
        </div>
    )
}

export default CompanyProcurementSettings