import { useSelector } from "react-redux"
import { FaRegUserCircle } from 'react-icons/fa'
import { TiBusinessCard } from 'react-icons/ti'
import { MdOutlineFactCheck } from 'react-icons/md'
import { BsUiChecksGrid, BsCardChecklist } from 'react-icons/bs'

const Steps = () => {
    const activeStep = useSelector(state => state.register.step)

    const steps = [
        {
            key: 1,
            label: 'Kullanıcı Bilgileri',
            icon: <FaRegUserCircle size={'2.5em'}/>
        },
        {
            key: 2,
            label: 'Firma Bilgileri',
            icon: <TiBusinessCard size={'2.5em'}/>
        },
        // {
        //     key: 3,
        //     label: 'Kayıt Türü',
        //     icon: <BsUiChecksGrid size={'2.5em'}/>
        // },
        {
            key: 4,
            label: 'Sözleşmeler',
            icon: <BsCardChecklist size={'2.5em'}/>
        },
        {
            key: 5,
            label: 'Onay',
            icon: <MdOutlineFactCheck size={'2.5em'}/>
        },
    ]

    const fixClasses = 'w-24 h-24 flex-col items-center gap-y-2 p-4 rounded-full border-2 border-blue-700'
    const activeClasses = 'bg-blue-700 text-white flex'
    const passiveClasses = 'bg-transparent text-blue-700 hidden lg:flex'

    return (
        <div className="flex items-center justify-center lg:justify-between m-4">
            {steps.map(step => {
                return (
                    <div key={step.key} 
                        className={`${fixClasses} ${step.key == activeStep ? activeClasses : passiveClasses}`}>
                        {step.icon}
                        <p className="text-center leading-none text-xs">{step.label}</p>
                    </div>
                )
            })}            
        </div>
    )
}

export default Steps