import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import {ButtonEditSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import { setIsOpenCompanyUserRoleEdit, setIsOpenCompanyUserStatusEdit, setIsOpenCompanyUserDepartmentEdit } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    return (
        <div className='w-full flex items-center justify-center gap-x-3'>
            <ButtonEditSmall label='Yetkiler' 
                            onClick={() => {actions.setUserForRoleChange(data); dispatch(setIsOpenCompanyUserRoleEdit(true))}}>                
            </ButtonEditSmall>
            <ButtonEditSmall label='Departmanlar' 
                            onClick={() => {actions.setUserForDepartmentChange(data); dispatch(setIsOpenCompanyUserDepartmentEdit(true))}}>                
            </ButtonEditSmall>
            <ButtonEditSmall label='Durum' 
                            onClick={() => {actions.setUserForStatusChange(data); dispatch(setIsOpenCompanyUserStatusEdit(true))}}>
            </ButtonEditSmall>
        </div>
    )
};

export default TableActions
