import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProcurementRequestApprovalApprove } from 'stores/DrawersSlice' 
import ApproveForm from 'modules/companyModule/pages/procurementRequestApprovals/warehouseRequests/ApproveForm'

function Approve({procurementRequest, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenApproval = useSelector(state => state.drawers.isOpenWarehouseProcurementRequestApprovalApprove)
    const setIsOpenApproval = () => dispatch(setIsOpenWarehouseProcurementRequestApprovalApprove())
    
    return(
        <FormDrawer isOpen={isOpenApproval} setIsOpen={setIsOpenApproval} title="Onay İşlemleri" maxWidth="max-w-4xl">
            <ApproveForm procurementRequest={procurementRequest} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Approve