import { useState, useEffect } from 'react'
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import { setCompanyData, setStep } from 'stores/RegisterSlice'
import {ButtonBlue, ButtonWhite} from 'elements/buttons'
import {IoArrowForwardSharp, IoArrowBackSharp} from "react-icons/io5"
import {InputSmall, SelectSmall} from 'elements/inputs'
import {PhoneSmall} from 'elements/inputs/Phone'
import MultiSelectWithSearch from 'elements/inputs/MultiSelectWithSearch'
import  { CompanyType } from 'data'

const CompanyForm = ({cities, districts, businessAreas, invitation}) => {

    const companyData = useSelector(state => state.register.company)
    const [filteredDistricts, setFilteredDistricts] = useState([]) 

    const { register, control, handleSubmit, formState: { errors }, setValue, watch, getValues } = useForm({
        defaultValues: {
            business_areas: companyData?.business_areas || null,
            company_name: companyData?.company_name || (invitation?.supplier_name || null),
            company_type: companyData?.company_type || null,
            tax_number: companyData?.tax_number || null,
            identity_number: companyData?.identity_number || null,
            tax_department: companyData?.tax_department || null,
            address: companyData?.address || null,
            district_id: companyData?.district_id || null,
            city_id: companyData?.city_id || null,
            phone: companyData?.phone || null,
            email: companyData?.email || null            
        }
    })

    const dispatch = useDispatch()

    const watchCompanyType = watch('company_type')

    const handleCompanyForm = (data) => {
        dispatch(setCompanyData(data))
        dispatch(setStep(4))    
    }

    const previous = () => {
        dispatch(setStep(1))
    }

    const handleChangeCity = (id) => {
        setValue('district_id', null)
        if(id) {
            let filteredData = districts.filter(item => {
                return item.cityId === parseInt(id)
            })
            setFilteredDistricts(filteredData)
        }
    }

    useEffect(() => {
        if(companyData?.city_id) {
            let filteredData = districts.filter(item => {
                return item.cityId === parseInt(companyData.city_id)
            })
            setFilteredDistricts(filteredData)
        }
    }, [])

    const baArray = businessAreas.map(item => {
        return {value: item.key, label: item.value}
    })

    const companyInputs = [
        {
            key: "companyInput1",
            type: "multiselect",
            name: "business_areas",
            placeholder: "Seçiniz",
            label: "Sektör * (Birden fazla seçebilirsiniz) *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.business_areas?.message,
            limitOptions: false,
            data: baArray,
            actions: (e) => null,
            cols: 'col-span-12',
        },
        {
            key: "companyInput2",
            type: "text",
            name: "company_name",
            placeholder: "Firma İsmi",
            label: "Firma İsmi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.company_name?.message,
            cols: 'col-span-12',
        },
        {
            key: "companyInput3",
            type: "select",
            name: "company_type",
            placeholder: "Şirket Türü",
            label: "Şirket Türü *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.company_type?.message,
            data: CompanyType(),
            cols: 'col-span-12',
        },
        {
            key: "companyInput4",
            type: "text",
            name: "identity_number",
            placeholder: "TC Kimlik No",
            label: "TC Kimlik No *",
            validator: {
                required: {value: getValues('company_type') === '1', message: "Bu alan zorunludur."}, 
                pattern: {value: /^[0-9]{11}$/,
                            message: "11 haneli rakam olmalı"},
            },
            error: errors?.identity_number?.message,
            cols: watchCompanyType === '1' ? 'col-span-4' : 'col-span-6',
        },
        {
            key: "companyInput5",
            type: "text",
            name: "tax_number",
            placeholder: "Vergi No",
            label: "Vergi No *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]{10}$/,
                            message: "10 haneli rakam olmalı"},
            },
            error: errors?.tax_number?.message,
            cols: watchCompanyType === '1' ? 'col-span-4' : 'col-span-6',
        },
        {
            key: "companyInput6",
            type: "text",
            name: "tax_department",
            placeholder: "Vergi Dairesi",
            label: "Vergi Dairesi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.tax_department?.message,
            cols: watchCompanyType === '1' ? 'col-span-4' : 'col-span-6',
        },
        {
            key: "companyInput7",
            type: "select",
            name: "city_id",
            placeholder: "İl",
            label: "İl *",
            validator: {
                required: "Bu alan zorunludur.", 
                onChange: (e) => handleChangeCity(e.target.value)
            },
            error: errors?.city_id?.message,
            data: cities,
            cols: 'col-span-6',
        },
        {
            key: "companyInput8",
            type: "select",
            name: "district_id",
            placeholder: "İlçe",
            label: "İlçe *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.district_id?.message,
            data: filteredDistricts,
            cols: 'col-span-6',
        },        
        {
            key: "companyInput9",
            type: "text",
            name: "address",
            placeholder: "Firma Adresi",
            label: "Firma Adresi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.address?.message,
            cols: 'col-span-12',
        },        
        {
            key: "companyInput10",
            type: "phone",
            name: "phone",
            placeholder: "Firma Telefonu",
            label: "Firma Telefonu *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},
                pattern: {value: /^\+90 \((?!0|1)\d{3}\) \d{3}-\d{2}-\d{2}$/,
                            message: "Geçerli bir telefon numarası olmalı"}
            },
            error: errors?.phone?.message,
            cols: 'col-span-6',
        },
        {
            key: "companyInput11",
            type: "text",
            name: "email",
            placeholder: "Firma E-Posta Adresi",
            label: "Firma E-Posta Adresi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message,
            cols: 'col-span-6',
        },        
    ]

    const filteredInput = watchCompanyType === '1' 
                            ? companyInputs 
                            : companyInputs.filter(ci => ci.key !== 'companyInput4')

    return (
        <div className="px-4 pb-4">
            <form onSubmit={handleSubmit(handleCompanyForm)}>
                <div className="grid grid-cols-12 gap-3 text-xs">
                {filteredInput.map(input => {
                     return (
                        <div className={input.cols} key={input.key}>
                            {input.type === 'select' ? 
                                <SelectSmall register={register} {...input} /> :
                                null
                            }
                            {input.type === 'text' ? 
                                <InputSmall register={register} {...input} /> :
                                null
                            }
                            {input.type === 'phone' ? 
                                <PhoneSmall register={register} {...input} /> :
                                null
                            }
                            {input.type === 'multiselect' ? 
                                <MultiSelectWithSearch
                                    control={control}
                                    {...input}
                                />
                            : null
                            }                        
                        </div>
                        )
                })}
                </div>
                <div className="w-full flex justify-between mt-5">
                    <ButtonWhite type="button" onClick={previous}> <IoArrowBackSharp/> Geri</ButtonWhite>
                    <ButtonBlue type="submit">İleri <IoArrowForwardSharp/> </ButtonBlue>
                </div>                
            </form> 
        </div>
    )
}

export default CompanyForm