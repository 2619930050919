import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonEditSmall, ButtonDeleteSmall, ButtonNewSmall } from 'elements/buttons/actionButtons'
import { setIsOpenEdit, setIsOpenDelete } from 'modules/companyModule/pages/products/settings/taxonomies/DrawersSlice'
import { setIsOpenCreate } from 'modules/companyModule/pages/products/settings/taxonomies/taxa/DrawersSlice'
import Taxa from 'modules/companyModule/pages/products/settings/taxonomies/taxa'

function TaxonomyTable({taxonomies, parent, actions, update, setUpdate}) {
    const dispatch = useDispatch()
    
    if(taxonomies !== null && Array.isArray(taxonomies)) {
        return (     
            <div className="w-full flex flex-col gap-y-2">
                {taxonomies.length > 0 ?
                    taxonomies.sort((a,b) => a.taxonomy_name.localeCompare(b.taxonomy_name)).map(taxonomy => {
                        return (
                            <div key={taxonomy.id} className="border border-gray-300 ">
                                <div className="border-b border-gray-300 bg-gray-200 px-2 py-1 flex items-center justify-between">
                                    <p className="text-sm font-semibold">{taxonomy.taxonomy_name}</p>
                                    <div className="">
                                        <div className="flex items-center justify-end gap-x-1">
                                            <ButtonNewSmall 
                                                label="Yeni Ana Sınıf"
                                                onClick={() => {
                                                    actions.setTaxonomyForTaxon(taxonomy)
                                                    actions.setParent(null)
                                                    dispatch(setIsOpenCreate(true))
                                                }} 
                                            />
                                            <ButtonEditSmall 
                                                onClick={() => {
                                                    actions.setTaxonomyForEdit(taxonomy)
                                                    dispatch(setIsOpenEdit(true))
                                                }}    
                                            />
                                            <ButtonDeleteSmall 
                                                onClick={() => {
                                                    actions.setTaxonomyForDelete(taxonomy)
                                                    dispatch(setIsOpenDelete(true))
                                                }}    
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='p-2 bg-white'>
                                    <Taxa taxonomy={taxonomy} parent={parent} update={update} setUpdate={setUpdate} actions={actions} />
                                </div>
                            </div>
                        )
                    })
                    :
                    <p className="text-rose-500 font-bold text-base">
                        Sınıf bilgisi bulunamadı
                    </p>
                }
        </div> 
        )
    }
    return null
    
}

export default TaxonomyTable