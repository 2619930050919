function StatusFormatFilled({status = null}) {
    switch (status) {
        case 'rejected': 
            return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Reddedildi</span>
            break
        case 'waiting_approvals': 
            return <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">Onaylar Bekleniyor</span>
            break
        case 'waiting_issuance': 
            return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Yayınlanmayı Bekliyor</span>
            break
        case 'uncompleted': 
            return <span className="py-0.5 px-2 rounded bg-orange-500 text-orange-50 whitespace-nowrap">Süreç Devam Ediyor</span>
            break
        case 'completed': 
            return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Süreç Tamamlandı</span>
            break
        case 'cancelled': 
            return <span className="py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap">İptal Edildi</span>
            break
        case 'archived': 
            return <span className="py-0.5 px-2 rounded bg-indigo-500 text-indigo-50 whitespace-nowrap">Arşivlendi</span>
            break
        default:
            return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">Bilinmiyor</span>
    } 
}

function StatusFormatText({status = null}) {
    switch (status) {
        case 'rejected': 
            return <span className="text-red-500 whitespace-nowrap">Reddedildi</span>
            break
        case 'waiting_approvals': 
            return <span className="text-yellow-500 whitespace-nowrap">Onaylar Bekleniyor</span>
            break
        case 'waiting_issuance': 
            return <span className="text-teal-500 whitespace-nowrap">Yayınlanmayı Bekliyor</span>
            break
        case 'uncompleted': 
            return <span className="text-orange-500 whitespace-nowrap">Süreç Devam Ediyor</span>
            break
        case 'completed': 
            return <span className="text-emerald-500 whitespace-nowrap">Süreç Tamamlandı</span>
            break
        case 'cancelled': 
            return <span className="text-rose-500 whitespace-nowrap">İptal Edildi</span>
            break
        case 'archived': 
            return <span className="text-indigo-500 whitespace-nowrap">Arşivlendi</span>
            break
        default:
            return <span className="text-gray-500 whitespace-nowrap">Bilinmiyor</span>
    } 
}

export {StatusFormatFilled, StatusFormatText}