import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import SendRequest from 'utils/SendRequest'
import {ButtonClose, ButtonNext, ButtonPrevious, ButtonNewSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall, FileSmall} from 'elements/inputs'
import SelectWithSearch from 'elements/inputs/SelectWithSearch'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import { useLineInput } from 'modules/companyModule/pages/procurementProcesses/procurements/InputArray'

function Lines({currentStep, setCurrentStep, procurementData, setProcurementData, products}) {
    const dispatch = useDispatch()
    const isOpen = useSelector((state) => state.drawers.isOpenProcurementCreate) 
    const companyId = useSelector((state) => state.company.selectedCompany.id)     
    
    const linesDefaults = {
        product_id: null,
        description_type: "2",
        description_text: null,
        quantity: null,
        unit_code_id: null,
        tech_spec_type: "no_file",
        technical_specification_file: null,
    }

    const formDefaults = {
        lines: [linesDefaults],
    }

    const [unitCodes, setUnitCodes] = useState([])
    const [itemTechSpecAttributes, setItemTechSpecAttributes] = useState({})
    const [loading, setLoading] = useState(false)
    
    const { register, handleSubmit, formState: { errors }, control, getValues, watch, setValue, reset } = useForm({
        defaultValues: formDefaults
    })

    const { 
        fields: lineFields, 
        append: lineAppend, 
        remove: lineRemove, 
        } = useFieldArray({
        control,
        name: "lines"
    })

    const watchLines = watch("lines");
    const controlledItemFields = lineFields.map((field, index) => {
        return {
            ...field,
            ...watchLines[index]
        }
    })

    const lineInputs = useLineInput(errors, products, itemTechSpecAttributes, setItemTechSpecAttributes)

    useEffect(() => {
        const getUnitCodes = (lines) => {
            let unitCodeArray = []
            if(products && products.length > 0) {
                lines.forEach((line, index) => {
                    let product = products.find(p => {
                        return p.id == line.product_id
                    })
                    if(product) {
                        unitCodeArray.push([{
                            key: product.unit.id,
                            value: `${product.unit.description}`
                        }])
                    } else {
                        unitCodeArray.push([])
                    }         
                })
                setUnitCodes(unitCodeArray)
            }
            setUnitCodes(unitCodeArray)
        }
        if(procurementData && procurementData instanceof Object && Object.keys(procurementData).length > 0) {
            if(procurementData?.lines && procurementData.lines.length > 0) {
                let lineItems = procurementData.lines.map(line => {
                    return {
                        product_id: line.product_id,
                        description_type: line.description_type,
                        description_text: line.description_text,
                        quantity: line.quantity,
                        unit_code_id: line.unit_code_id,
                        tech_spec_type: line.tech_spec_type,
                        technical_specification_file: null,
                    }
                })
                reset({lines : lineItems})
                getUnitCodes(procurementData.lines)
            } 
        }
    }, [procurementData])

    useEffect(() => {
        let unitCodeArray = []
        if(products && products.length > 0) {
            lineFields.forEach((line, index) => {
                let product = products.find(p => {
                    return p.id == line.product_id
                })
                if(product) {
                    unitCodeArray.push([{
                        key: product.unit.id,
                        value: `${product.unit.description}`
                    }])
                } else {
                    unitCodeArray.push([])
                }         
            })
            setUnitCodes(unitCodeArray)
        }
        
    }, [lineFields, products]) 

    useEffect(() => {
        unitCodes.forEach((uc, index) => {
            if(uc && Array.isArray(uc) && uc.length > 0) {
                setValue(`lines.${index}.unit_code_id`, uc[0].key, { shouldDirty: true })
            }            
        })
    }, [unitCodes])

    const downloadItemTechSpec = (productId, productName) => {
        const getProductTechSpecs = async () => {
            setLoading(true)
            await SendRequest('DOWNLOAD', `api/companies/${companyId}/products/${productId}/download_technical_specification`)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${productName}_tech_spec.pdf`)
                        document.body.appendChild(link)
                        link.click()
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProductTechSpecs()
    }

    const handleLineProductChange = (e, index) => {
        let product = products.find(p => {
            return p.id == e.value
        })
        setUnitCodes(prev => {
            let newCodes = [...prev]
            newCodes[index] = [{
                key: product.unit.id,
                value: `${product.unit.description}`
            }]
            return newCodes
        })
        if(product.description) {
            setValue(`lines.${index}.description_text`, product.description)
        } else {
            setValue(`lines.${index}.description_text`, null)
        }
    }

    const ItemTechSpecProductFile = ({type, productId, index}) => {
        
        const product = products.find(p => {
            return p.id === productId
        })
        if(product && product.is_tech_spec_available) {
            return (
                <button type="button" 
                        className="text-md text-sky-500 hover:text-sky-800 underline mt-6" 
                        onClick={() => downloadItemTechSpec(productId, product.name)}
                >
                    Şartnameyi indirmek için tıklayın.
                </button>   
            )
        }
        return (
            <p className="text-md text-rose-600 font-semibold mt-6" >
                Ürüne Ait Şartname Bulunamadı
            </p>
            
        )         
    }

    const productArray = products.map(item => {
        return {value: item.id, label: item.name}
    })

    const close = () => {
        dispatch(setIsOpenProcurementCreate())
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const handleForm = async (data) => {
        setProcurementData(prev => {
            return {...prev, ...data}
        })
        setCurrentStep(prev => prev+1)
    }    

    return (  
        <>      
        <form onSubmit={handleSubmit(handleForm, handleError)}>            
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-8rem)]">
                <div className="w-full grow overflow-y-auto px-0.5">
                {controlledItemFields.map((field, index) => {
                    return (
                        <div className='lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-300 py-2' key={`lineInput${field.id}`}>
                            <div className='col-span-11 lg:grid lg:grid-cols-12 gap-2'>
                                <div className={lineInputs[0].cols}>
                                    <SelectWithSearch 
                                        label = {lineInputs[0].label}
                                        dataArray={productArray}
                                        control={control}
                                        name={`lines.${index}.${lineInputs[0].nameLast}`}
                                        rules={{...lineInputs[0].validator}}
                                        error={errors?.lines?.[index]?.[lineInputs[0].nameLast]?.message}
                                        actions={(e) => handleLineProductChange(e, index)}
                                    />
                                    
                                </div>                            
                                <div className={lineInputs[1].cols}>
                                    <InputSmall 
                                        {...lineInputs[1]}
                                        register={register} 
                                        name={`lines.${index}.${lineInputs[1].nameLast}`} 
                                        error={errors?.lines?.[index]?.[lineInputs[1].nameLast]?.message} 
                                    />
                                </div>
                                <div className={lineInputs[2].cols}>
                                    <SelectSmall 
                                        {...lineInputs[2]}
                                        data={unitCodes?.[index]}
                                        register={register} 
                                        name={`lines.${index}.${lineInputs[2].nameLast}`} 
                                        error={errors?.lines?.[index]?.[lineInputs[2].nameLast]?.message} 
                                    />
                                </div>  
                                <div className={lineInputs[3].cols}>
                                    <TextareaSmall 
                                        {...lineInputs[3]}
                                        register={register} 
                                        name={`lines.${index}.${lineInputs[3].nameLast}`} 
                                        error={errors?.lines?.[index]?.[lineInputs[3].nameLast]?.message} 
                                    />
                                </div>                          
                                <div className={lineInputs[4].cols}>
                                    <SelectSmall 
                                        {...lineInputs[4]}
                                        register={register} 
                                        name={`lines.${index}.${lineInputs[4].nameLast}`} 
                                        error={errors?.lines?.[index]?.[lineInputs[4].nameLast]?.message} 
                                    />
                                </div>       
                                <div className="col-span-6">
                                    <div className={`w-full ${field.tech_spec_type !== 'use_product_file' ? 'hidden' : ''}`}>
                                        <ItemTechSpecProductFile type={field.tech_spec_type} productId={field.product_id} index={index} />
                                    </div>
                                    <div className={`w-full ${field.tech_spec_type !== 'use_uploaded_file' ? 'hidden' : ''}`}>
                                    <FileSmall 
                                            {...lineInputs[5]}
                                            register={register}                    
                                            name={`lines.${index}.${lineInputs[5].nameLast}`}
                                            error={errors?.lines?.[index]?.[lineInputs[5].nameLast]?.message}
                                            validator={{
                                                ...lineInputs[5].validator,
                                                required: { value: field.tech_spec_type === 'use_uploaded_file', message: 'Bu alan zorunlu'},
                                                validate: (value) => {
                                                    if(value && value[0]?.type !== "application/pdf") {
                                                        return 'PDF dosyası seçmelisiniz'
                                                    }
                                                    return true
                                                },
                                                onChange: (e) => {
                                                    let files = e.target.files       
                                                    setItemTechSpecAttributes(prev => {
                                                        let newFiles = {...prev}
                                                        newFiles[index] = {
                                                            filename: files.length > 0 ? files[0].name : null,
                                                            size: files.length > 0 ? files[0].size : null,
                                                            type: files.length > 0 ? files[0].type : null,
                                                        }
                                                        return newFiles
                                                    })
                                                }}
                                            }
                                            fileAttributes= {itemTechSpecAttributes[index]}
                                        />
                                    </div>
                                    
                                </div>      
                            </div>               
                            <div className='col-span-1 flex items-center'>
                                <div className="w-full">
                                    {
                                    index === 0 
                                    ? <ButtonNewSmall label="Ekle" addClass='w-full' type="button" onClick={() => lineAppend(linesDefaults)} ></ButtonNewSmall>
                                    : <ButtonDeleteSmall addClass='w-full' type="button" onClick={() => lineRemove(index)} ></ButtonDeleteSmall>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    )
                })} 
                </div>
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonClose onClick={() => close()}>
                    </ButtonClose>
                    <ButtonPrevious onClick={() => setCurrentStep(prev => prev-1)}>
                    </ButtonPrevious>
                    <ButtonNext type='submit'>
                    </ButtonNext>                  
                </div>         
            </div>
        </form> 
        </>
    )

}

export default Lines