import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from 'App'
import UserWrapper from 'wrappers/UserWrapper'
import CompanyWrapper from 'wrappers/CompanyWrapper'
import SharedDataWrapper from 'wrappers/SharedDataWrapper'
import {Provider} from 'react-redux'
import {store} from 'stores/'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <UserWrapper>
          <SharedDataWrapper>
            <CompanyWrapper>
              <App />
            </CompanyWrapper>
          </SharedDataWrapper>
        </UserWrapper>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);


