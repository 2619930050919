import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {ButtonDelete, ButtonOk} from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenProcurementCancel } from 'stores/DrawersSlice' 
import DetailInfo from 'modules/companyModule/pages/procurementProcesses/commonParts/DetailInfo'
import Loader from 'elements/Loader'

const CancelForm = ({procurement, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
   
    const handleDelete = async () => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/procurements/${procurement.id}/cancel`)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        setStatus(false)
        setError(null)
        dispatch(setIsOpenProcurementCancel())
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Satın alma talebi başarıyla iptal edildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <>
            <div className="">
                {(procurement !==null && Object.keys(procurement).length > 0) &&
                <>
                <p className="text-sm font-semibold text-gray-700 mb-0.5">Aşağıda detayları verile satın alma talebini silmek istediğinizden emin misiniz? </p>
                <p className="text-sm font-semibold text-red-500 mb-2">Not: İptal Etme İşlemi Geri Alınamaz! </p>
                <DetailInfo procurement={procurement} />
                </>}
            </div>            
            <div className="w-full flex justify-end mt-5">
                <ButtonDelete label="Talebi İptal Et" type="button" onClick={handleDelete}>
                </ButtonDelete>
            </div>  
            </>       
        }   
        {loading && <Loader />}
        </>
    )
}

export default CancelForm