import { GreenSmall, OrangeSmall, RedSmall } from "elements/infoCards" 


function TopCards({data, update, setUpdate, loading}) {
    const placeholderData = {
        waiting_approvals: data?.waiting_approvals || 0,
        waiting_delivery_approval: data?.waiting_delivery_approval || 0,
        waiting_delivery: data?.waiting_delivery || 0,
    }
    
    return (
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-4">
            <GreenSmall 
                title="Onay Bekleyenler"
                content={`${placeholderData.waiting_approvals} Adet`}
            />
            <OrangeSmall 
                title="Teslim Alınmayı Bekleyenler"
                content={`${placeholderData.waiting_delivery} Adet`}
            />
            <RedSmall 
                title="Teslim Onayı Bekleyenler"
                content={`${placeholderData.waiting_delivery_approval} Adet`}
            />
        </div>
    )
}

export default TopCards