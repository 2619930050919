import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import TableActions from 'modules/supplierModule/pages/procurements/TableActions'
import TableLayout from 'elements/tables'
import CustomPopover from 'elements/popovers'
import { ButtonShowDetail } from 'elements/buttons/actionButtons'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

const Table = ({data, actions, progressPending, pagination, pageMeta, withProcurementStatus = false}) => {

    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const cities = useSelector(state => state.sharedData.cities)
    const districts = useSelector(state => state.sharedData.districts)

    const DateCell = ({createdDate, endDate, deliveryDate}) => {
        return (
            <div className="grid grid-cols-1 gap-y-1">
                <p className="whitespace-nowrap"><span className="font-semibold">Bitiş:</span> {DateTime.fromISO(endDate).toFormat('dd.LL.yyyy T')}</p>
                <p className="whitespace-nowrap"><span className="font-semibold">Teslim:</span> {DateTime.fromISO(deliveryDate).toFormat('dd.LL.yyyy T')}</p>
            </div>
        )        
    }

    const LinesCell = ({lines}) => {
        const content = (
            <div className='text-xs'>
                <ol className="list-decimal list-inside">
                    {lines.map(line => {
                        return (
                            <li key={line.id} className='whitespace-nowrap my-1'>
                                <span className='font-semibold'>
                                    {line.product.name}: &nbsp;
                                </span>
                                {line.quantity} {line.unit_code.description}
                            </li>
                        )
                        
                    })}
                </ol>
            </div>
        )

        return (
            <CustomPopover content={content} >
                <ButtonShowDetail label={lines.length} />
            </CustomPopover>
        ) 
        
    }

    const ProcurementStatusCell = ({status}) => {
        switch (status) {
            case 'uncompleted': 
                return <span className="py-0.5 px-2 rounded bg-orange-500 text-orange-50 whitespace-nowrap">Süreç Devam Ediyor</span>
                break
            case 'completed': 
                return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Süreç Tamamlandı</span>
                break
            case 'cancelled': 
                return <span className="py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap">İptal Edildi</span>
                break
            case 'archived': 
                return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Süreç Tamamlandı</span>
                break
            default:
                return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">Bilinmiyor</span>
        }
                   
    }

    const ProposalCell = ({proposals, status}) => {
        if(proposals && Array.isArray(proposals) && proposals.length > 0) {
            let activeProposal = proposals.filter(proposal => {
                return proposal.is_cancelled === false && proposal.is_revised === false
            })
            if(activeProposal.length > 0) {
                if(activeProposal[0].is_accepted === true) {
                    if(activeProposal[0].total_price === activeProposal[0].accepted_price) {
                        return (
                            <span className="text-emerald-500 font-bold">
                                Teklifiniz kabul edildi. <br />(Tutar: {FNWS(activeProposal[0].accepted_price)} {activeProposal[0].currency.code})
                            </span>
                        )
                    }
                    return (
                        <span className="text-orange-500 font-bold">
                            Teklifiniz kısmi olarak kabul edildi. <br />(Tutar: {FNWS(activeProposal[0].accepted_price)} {activeProposal[0].currency.code})
                        </span>
                    )
                    
                }
                if(status === 'completed') {
                    return (
                        <span className="text-rose-500 font-bold">
                            Teklif verildi. Teklifiniz kabul edilmedi. <br />(Tutar: {FNWS(activeProposal[0].total_price)} {activeProposal[0].currency.code})
                        </span>
                    )
                }

                return (
                    <span className="text-blue-500 font-bold">
                        Teklif verildi. Süreç devam ediyor. <br />(Tutar: {FNWS(activeProposal[0].total_price)} {activeProposal[0].currency.code})
                    </span>
                )
            }

            return <span className="text-red-500 font-bold">Aktif teklif verilmedi.</span>
        } 

        return <span className="text-red-500 font-bold">Teklif verilmedi.</span>
    }

    const columns = [
        {
            name: 'Firma',
            selector: (row) => {return row.company.company_name},
            headerMobileHidden: false,
        },
        {
            name: 'Talep No',
            selector: (row) => {return row.procurement_no},
            headerMobileHidden: false,
        },
        {
            name: 'Başlık',
            selector: (row) => {return row.title},
            headerMobileHidden: false,
        },
        {
            name: 'Sektör',
            selector: (row) => {return row.business_area.name},
            headerMobileHidden: false,
        },
        {
            name: 'Tarih',
            selector: (row) => {return <DateCell endDate={row.end_date} deliveryDate={row.delivery_date} />},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Sayısı',
            selector: (row) => {return <LinesCell lines={row.lines} />},
            headerMobileHidden: false,
        },
        withProcurementStatus ?
        {
            name: 'Talep Durumu',
            selector: (row) => {return <ProcurementStatusCell status={row.status} />},
            headerMobileHidden: false,
        } : null,
        {
            name: 'Teklif Durumu',
            selector: (row) => {return <ProposalCell proposals={row.proposals} status={row.status} />},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return `${row.title} (${row.procurement_no})`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
