import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Loader from 'elements/Loader'

function ProcurementRequestApprovals() {
    const user = useSelector(state => state.user.user)
    const companyId = useSelector(state => state.company.selectedCompany.id)

    const [loading, setLoading] = useState(false)

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex flex-col lg:flex-row justify-between items-center gap-y-2 lg:gap-y-0">
                <h2 className="text-2xl text-gray-800 font-semibold">Satın Alma Talebi Onay İşlemleri</h2>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <Outlet  />
            </div>            
            {loading && <Loader />}
        </div>
    )

}

export default ProcurementRequestApprovals