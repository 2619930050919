import CustomPopover from 'elements/popovers'
import { ButtonShowDetail } from 'elements/buttons/actionButtons'

function DetailStatus({status}) {
    switch (status) {
        case 'rejected': 
            return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Reddedildi</span>
            break
        case 'waiting_approvals': 
            return <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">Onaylar Bekleniyor</span>
            break
        case 'waiting_issuance': 
            return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Yayınlanmayı Bekliyor</span>
            break
        case 'uncompleted': 
            return <span className="py-0.5 px-2 rounded bg-orange-500 text-orange-50 whitespace-nowrap">Süreç Devam Ediyor</span>
            break
        case 'completed': 
            return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Süreç Tamamlandı</span>
            break
        case 'cancelled': 
            return <span className="py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap">İptal Edildi</span>
            break
        case 'archived': 
            return <span className="py-0.5 px-2 rounded bg-indigo-500 text-indigo-50 whitespace-nowrap">Arşivlendi</span>
            break
        default:
            return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">Bilinmiyor</span>
    }           
}

function TableStatus({status, approvals}) {
    const content = (
        <div className='text-xs'>
            <ol className="list-decimal list-inside">
                {approvals.map(approval => {
                    return (
                        <li key={approval.id} className='whitespace-nowrap my-1'>
                            <span className='font-semibold'>
                                {approval?.user?.name}: 
                            </span>
                            {approval.is_accepted ? ' Kabul Etti' : null}
                            {approval.is_rejected ? ' RedEtti' : null}
                            {!approval.is_rejected && !approval.is_accepted ? ' Bekleniyor' : null}
                        </li>
                    )
                    
                })}
            </ol>
        </div>
    )

    switch (status) {
        case 'rejected': 
            return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Reddedildi</span>
            break
        case 'waiting_approvals': 
            return <div className="flex items-center justify-center gap-x-0">
                        <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">
                            Onaylar Bekleniyor                            
                        </span>
                        <CustomPopover content={content} >
                            <ButtonShowDetail label={null} />
                        </CustomPopover>
                    </div>
            break
        case 'waiting_issuance': 
            return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Yayınlanmayı Bekliyor</span>
            break
        case 'uncompleted': 
            return <span className="py-0.5 px-2 rounded bg-orange-500 text-orange-50 whitespace-nowrap">Süreç Devam Ediyor</span>
            break
        case 'completed': 
            return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Süreç Tamamlandı</span>
            break
        case 'cancelled': 
            return <span className="py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap">İptal Edildi</span>
            break
        case 'archived': 
            return <span className="py-0.5 px-2 rounded bg-indigo-500 text-indigo-50 whitespace-nowrap">Arşivlendi</span>
            break
        default:
            return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">Bilinmiyor</span>
    }
}

export {DetailStatus, TableStatus}