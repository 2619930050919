import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductDemandDeliveryApproval } from 'stores/DrawersSlice' 
import DeliveryApproveForm from 'modules/companyModule/pages/productDemands/demands/DeliveryApproveForm'

function DeliveryApprove({productDemand, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenApproval = useSelector(state => state.drawers.isOpenProductDemandDeliveryApproval)
    const setIsOpenApproval = () => dispatch(setIsOpenProductDemandDeliveryApproval())
    
    return(
        <FormDrawer isOpen={isOpenApproval} setIsOpen={setIsOpenApproval} title="Onay İşlemleri" maxWidth="max-w-4xl">
            <DeliveryApproveForm productDemand={productDemand} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default DeliveryApprove