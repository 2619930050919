import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, CheckboxSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenCompanyUserRoleEdit } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

const EditForm = ({user, update, setUpdate, roles}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenEdit = useSelector((state) => state.drawers.isOpenCompanyUserRoleEdit)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [categorySelectArray, setCategorySelectArray] = useState([])
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            newroles: null,
        }
    })

    useEffect(() => {
        roles.forEach(r => setValue(`newroles.${r.id}`, false))
        if(user !== null && Object.keys(user).length > 0) {
            if(user.roles && Array.isArray(user.roles) && user.roles.length > 0) {
                user.roles.map(role => {
                    setValue(`newroles.${role.id}`, true)
                })
            }            
            setError(null)
            setStatus(false)
        }
    }, [user])

    const handleForm = async (data) => {
        // console.log(data)
        const filteredRoles = Object.keys(data.newroles).filter(key => data.newroles[key])
        const filteredData = {roles: filteredRoles}
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/users/${user.id}`, filteredData)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    useEffect(() => {
        if(!isOpenEdit) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenEdit])

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenCompanyUserRoleEdit(false))
    }
    
    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Yetkiler</p>
            <div className="grid grid-cols-12 gap-2">
                {roles.map(role => {
                    return (
                        <div key={role.id} className="col-span-12 flex items-start justify-start gap-x-2">
                        <CheckboxSmall 
                            type="checkbox"
                            name={`newroles.${role.id}`}
                            register={register}
                            label={role.description}
                            validator={{}}
                        />
                        </div>
                    )
                })}
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div> 
        </form> 
         
        {loading && <Loader />}
        </>
    )
}

export default EditForm