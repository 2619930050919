import { configureStore } from "@reduxjs/toolkit"
import userReducer  from 'stores/UserSlice'
import registerSlice from 'stores/RegisterSlice'
import companySlice from 'stores/CompanySlice'
import mobileMenuSlice from 'stores/MobileMenuSlice'
import drawersSlice from 'stores/DrawersSlice'
import sharedDataSlice from 'stores/SharedDataSlice'
import companyProductTaxonomyDrawerSlice from 'modules/companyModule/pages/products/settings/taxonomies/DrawersSlice'
import companyProductTaxonDrawerSlice from 'modules/companyModule/pages/products/settings/taxonomies/taxa/DrawersSlice'
import companySupplierTaxonomyDrawerSlice from 'modules/companyModule/pages/suppliers/settings/taxonomies/DrawersSlice'
import companySupplierTaxonDrawerSlice from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/DrawersSlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        register: registerSlice,
        company: companySlice,
        mobileMenu: mobileMenuSlice,
        drawers: drawersSlice,
        sharedData: sharedDataSlice,
        companyProductTaxonomyDrawers: companyProductTaxonomyDrawerSlice,
        companyProductTaxonDrawers: companyProductTaxonDrawerSlice,
        companySupplierTaxonomyDrawers: companySupplierTaxonomyDrawerSlice,
        companySupplierTaxonDrawers: companySupplierTaxonDrawerSlice,
    }
})