import { useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'
import { FaRegBell, FaRegClock } from 'react-icons/fa'
import { SiGooglemessages } from 'react-icons/si'
import { IconContext } from "react-icons"
import SendRequest from 'utils/SendRequest'
import { useSelector } from 'react-redux'

function Alerts() {
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState([])
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)

    const dropdownRef = useRef()
    const buttonRef = useRef()

    const getData = async () => {
        return new Promise(async (resolve, reject) => {
            if(companyId === undefined) return
            await SendRequest('GET', `api/companies/${companyId}/procurement_logs/unreads`)
            .then(response => {
                if(response.status === 200) {
                    resolve(response.data)
                    setData(response.data.data)
                }
                reject()
            })
            .catch(err => {
                reject()
            })
        })        
    }

    const getCount = () => {
        return new Promise(async (resolve, reject) => {
            if(companyId === undefined) return
            await SendRequest('GET', `api/companies/${companyId}/procurement_logs/unread_counts`)
            .then(response => {
                if(response.status === 200) {
                    resolve(response.data)
                    setCount(response.data)
                }
                reject()
            })
            .catch(err => {
                reject()
            })
        })        
    }

    useEffect(() => {   
        getCount()  
        const interval = setInterval(() => {
            getCount()
        }, 60*1000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {   
        if(isOpen) {
            setLoading(true)   
            getData()
            .then(response => {
                if(response.data.length) {
                    let ids = response.data.map(d => {
                        return d.id
                    })
                    SendRequest('POST', `api/companies/${companyId}/procurement_logs/set_read`, {procurement_log_ids: ids})
                    .then(res => {
                        if(res.status === 204) {
                            setCount(0)
                        }
                    })
                }               
            })
            .finally(() => {
                setLoading(false)
            })
        } else {
            setData(null)
        }        
    }, [isOpen])

    useEffect(() => {
        if(isOpen) {
            dropdownRef.current.classList.add("block")
            dropdownRef.current.classList.remove("hidden")
        } else {
            dropdownRef.current.classList.add("hidden")
            dropdownRef.current.classList.remove("block")
        }

        const eventFunction = (e) => {
            if(!e.composedPath().includes(dropdownRef.current) && !e.composedPath().includes(buttonRef.current)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('click', eventFunction )
        return () => {
            document.removeEventListener('click', eventFunction)
        }
    })

    const handleOpen = (e) => {
        e.preventDefault()
        setIsOpen(prev => !prev)
    }

    const DateColumn = ({date}) => {
        return DateTime.fromISO(date).setLocale("tr").toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    }

    return (
        <div className="relative">
            <div>
                <button ref={buttonRef} onClick={handleOpen} className="px-3 m-0 flex flex-row items-center h-full">
                    <div className="relative rounded cursor-pointer">
                        <IconContext.Provider value={{ className: "h-6 w-6 text-slate-500" }}>
                        <FaRegBell />
                        </IconContext.Provider>
                        {count > 0 ? 
                        <small className="bg-red-500 text-white flex items-center justify-center rounded-full h-4 w-4 font-bold text-[10px] absolute top-[-3px] right-[-3px]">{count}</small>
                        : null }
                    </div>
                </button>
            </div>
            <div ref={dropdownRef} className="z-50 translate-x-1/2 lg:translate-x-0 absolute right-0 mt-2 min-w-[20rem] max-w-full max-h-[600px] overflow-y-scroll rounded-md shadow-lg py-1 bg-white focus:outline-none divide-y divide-gray-300">
                {loading ? 
                <div className="block w-full text-left px-4 py-2 text-gray-700 bg-orange-200">
                    <p className="flex items-start justify-start gap-x-2">
                        <IconContext.Provider value={{ className: "h-3 w-3 flex-none mt-0.5" }}>
                        <SiGooglemessages />
                        </IconContext.Provider>
                        <span>
                        Mesajlarınız yükleniyor ...
                        </span>
                    </p>
                </div>
                : 
                 (data && data.length > 0) ? 
                    data.map(item => {
                        return (
                            <div key={item.id} className="block w-full text-left px-4 py-2 text-gray-700 even:bg-gray-200">
                                <p className="flex items-start justify-start gap-x-2">
                                    <IconContext.Provider value={{ className: "h-3 w-3 flex-none mt-0.5" }}>
                                    <SiGooglemessages />
                                    </IconContext.Provider>
                                    <span>
                                        {item.message}
                                    </span>
                                </p>
                                <p className="flex items-start justify-start gap-x-2 text-gray-400">
                                    <IconContext.Provider value={{ className: "h-3 w-3 flex-none mt-0.5" }}>
                                    <FaRegClock />
                                    </IconContext.Provider>
                                    <span><DateColumn date={item.created_at} /></span>
                                </p>
                            </div>
                        )
                    })
                    :
                    <div className="block w-full text-left px-4 py-2 text-gray-700 bg-green-300">
                        <p className="flex items-start justify-start gap-x-2">
                            <IconContext.Provider value={{ className: "h-3 w-3 flex-none mt-0.5" }}>
                            <SiGooglemessages />
                            </IconContext.Provider>
                            <span>
                            Okunmayan mesajınız bulunmamaktadır.
                            </span>
                        </p>
                    </div>
                }                
            </div>
        </div>
    )
}

export default Alerts