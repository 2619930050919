import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierCompanyEdit } from 'stores/DrawersSlice' 
import EditForm from 'modules/supplierModule/pages/companies/companyInfos/EditForm'

function Edit({update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenEdit = useSelector(state => state.drawers.isOpenSupplierCompanyEdit)
    const setIsOpenEdit = () => dispatch(setIsOpenSupplierCompanyEdit())
    
    return(
        <FormDrawer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} title="Şirket Bilgilerini Düzenle">
            <EditForm update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Edit