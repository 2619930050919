import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import SendRequest from 'utils/SendRequest'
import {ButtonClose, ButtonSave, ButtonPrevious, ButtonNew, ButtonOk} from 'elements/buttons/actionButtons'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { ProcurementSupplierSelectionTypes, 
    ProcurementProposalTypes, 
    ProcurementPaymentTypes,
    ProcurementDeliveryTypes } from 'data'

function Confirmation({currentStep, 
                        setCurrentStep, 
                        procurementData, 
                        setProcurementData, 
                        costTypes, 
                        products, 
                        update, 
                        setUpdate,
                        status,
                        setStatus
                    }) {
    const dispatch = useDispatch()
    const isOpen = useSelector((state) => state.drawers.isOpenProcurementCreate) 
    const companyId = useSelector((state) => state.company.selectedCompany.id)     

    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const currencies = useSelector(state => state.sharedData.currenciesWithDesc)
    const unitCodes = useSelector(state => state.sharedData.unitCodes)
    
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const { handleSubmit } = useForm()

    const formatDate = (date) => {
        let parsedDate = DateTime.fromISO(date)
        if(parsedDate.isValid) return parsedDate.toFormat('dd.LL.yyyy T')
        return 'Belirlenmedi'
    }

    const BusinessAreaDesc = () => {
        let selectedCode = businessAreas.filter(a => {
            return a.key.toString() === procurementData.business_area_id.toString()
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }
    const CostTypeDesc = () => {
        let selectedCode = costTypes.filter(a => {
            return a.cost_type.id.toString() === procurementData.cost_type_id.toString()
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].cost_type.cost_name
        }
        return ''
    }
    const ProposalTypeDesc = () => {
        let selectedCode = ProcurementProposalTypes().filter(a => {
            return a.key.toString() === procurementData.proposal_type.toString()
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }
    const ProposalRevisionDesc = () => {
        if(procurementData.is_proposal_increase_not_allowed) {
            return 'Tedarikçi revize tekliflerinde fiyat arttıramaz.'
        }
        return 'Tedarikçi revize tekliflerinde fiyat arttırabilir.'
    }
    const ProposalCancelDesc = () => {
        if(procurementData.is_proposal_cancel_not_allowed) {
            return 'Tedarikçi verdiği teklifi iptal edemez.'
        }
        return 'Tedarikçi verdiği teklifi iptal edebilir.'
    }
    const CurrencyDesc = () => {
        let selectedCode = currencies.filter(a => {
            return a.key.toString() === procurementData.currency_id.toString()
        })
        if(selectedCode.length > 0 ) {
            return `${selectedCode[0].value} (${procurementData.is_currency_flexible ? 'Tedarikçi değiştirebilir' : 'Tedarikçi değiştiremez'})`
        }
        return ''
    }
    const PaymentTypeDesc = () => {
        let selectedCode = ProcurementPaymentTypes().filter(a => {
            return a.key.toString() === procurementData.payment_type.toString()
        })
        if(selectedCode.length > 0 ) {
            return `${selectedCode[0].value} 
                    ${procurementData.payment_type.toString() === 'deferred' ? procurementData.deferral_days + ' Gün' : ''}
                    (${procurementData.is_payment_type_flexible ? 'Tedarikçi değiştirebilir' : 'Tedarikçi değiştiremez'})`
        }
        return ''
    }
    const DeliveryTypeDesc = () => {
        let selectedCode = ProcurementDeliveryTypes().filter(a => {
            return a.key.toString() === procurementData.delivery_type.toString()
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }
    const ProductDesc = ({product_id}) => {
        let selectedCode = products.filter(a => {
            return a.id.toString() === product_id.toString()
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].name
        }
        return ''
    }
    const UnitCodeDesc = ({unitCodeId}) => {
        let selectedCode = unitCodes.filter(a => {
            return a.key.toString() === unitCodeId.toString()
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }
    const ProductTechSpecDesc = ({type, files}) => {
        if(type === 'no_file' ) {
            return 'Şartname Olmasın'
        }
        if(type === 'use_product_file' ) {
            return 'Ürün Tanımındakini Kullan'
        }
        if(type === 'use_uploaded_file' ) {
            return files[0].name
        }
        return ''
    }
    const SupplierSelectionDesc = () => {
        let selectedCode = ProcurementSupplierSelectionTypes().filter(a => {
            return a.key.toString() === procurementData.supplier_selection_type.toString()
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }
    
    const close = () => {
        setProcurementData({})
        setCurrentStep(1)
        setError(null)
        setStatus(false)
        dispatch(setIsOpenProcurementCreate())
    }

    const redo = () => {
        setProcurementData({})
        setCurrentStep(1)
        setError(null)
        setStatus(false)
    }

    const handleForm = async () => {
        procurementData.lines.forEach((line, index) => {
            if(line.tech_spec_type !== 'use_uploaded_file') {
                procurementData.lines[index].technical_specification_file = null
            }
        })
        procurementData.is_currency_flexible ? procurementData.is_currency_flexible = 1 : procurementData.is_currency_flexible = 0
        procurementData.is_payment_type_flexible ? procurementData.is_payment_type_flexible = 1 : procurementData.is_payment_type_flexible = 0
        procurementData.is_proposal_increase_not_allowed ? procurementData.is_proposal_increase_not_allowed = 1 : procurementData.is_proposal_increase_not_allowed = 0
        procurementData.is_proposal_cancel_not_allowed ? procurementData.is_proposal_cancel_not_allowed = 1 : procurementData.is_proposal_cancel_not_allowed = 0
        if(procurementData.supplier_selection_type === 'suppliers_by_categories') {
            const filteredCategories = Object.entries(procurementData.supplier_categories)
                                        .filter(([key, value]) => value.selected === true ) 
                                        .map(([key, value]) => {
                                            return {
                                                taxon_id: key,
                                                taxonomy_id: value.taxonomyId,
                                            }
                                        })

            procurementData.supplier_categories = filteredCategories
        }
        const formData = new FormData()
        Object.keys(procurementData).forEach((key) => {
            if(procurementData[key] instanceof FileList) {
                formData.append(key, procurementData[key][0])
            } else if (Array.isArray(procurementData[key])) {
                procurementData[key].forEach((item, index) => {
                    if(item instanceof Object) {
                        Object.keys(item).forEach((itemkey) => {
                            if(item[itemkey] instanceof FileList) {
                                formData.append(`${key}[${index}][${itemkey}]`, item[itemkey][0])
                            } else {
                                formData.append(`${key}[${index}][${itemkey}]`, item[itemkey] === null ? '' : item[itemkey])
                            }                        
                        })
                    } else {
                        formData.append(`${key}[${index}]`, item)
                    }
                })
            } else {
              formData.append(key, procurementData[key] === null ? '' : procurementData[key])
            }
        })
        
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/procurements`, formData)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const InfoList = () => {
        return (
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-8rem)]">
                <div className='w-full'>
                    <div className="text-red-500 mt-4">
                        {error && <ServerError errorData={error} />}
                    </div>
                </div>
                <div className="w-full flex-1 overflow-y-auto px-0.5 mt-2">
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                    <table className="w-full">
                        <tbody>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Talep Başlığı:</td>
                                <td className="px-1 py-0.5">{procurementData.title}</td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Sektör: </td>
                                <td className="px-1 py-0.5"><BusinessAreaDesc /></td>
                            </tr>                                
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Gider Türü: </td>
                                <td className="px-1 py-0.5"><CostTypeDesc /></td>
                            </tr>                                                                       
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Teklif Toplama Türü: </td>
                                <td className="px-1 py-0.5"><ProposalTypeDesc /></td>
                            </tr>                      
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Teklif Revizesi: </td>
                                <td className="px-1 py-0.5"><ProposalRevisionDesc /></td>
                            </tr>                      
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Teklif İptali: </td>
                                <td className="px-1 py-0.5"><ProposalCancelDesc /></td>
                            </tr>                      
                        </tbody>
                    </table>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ödeme Bilgileri</p>
                    <table className="w-full">
                        <tbody>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Para Birimi:</td>
                                <td className="px-1 py-0.5">
                                    <CurrencyDesc />
                                </td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Ödeme Şekli: </td>
                                <td className="px-1 py-0.5">
                                    <PaymentTypeDesc />
                                </td>
                            </tr>                     
                        </tbody>
                    </table>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Sevkiyat Bilgileri</p>
                    <table className="w-full">
                        <tbody>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Sevkiyat Yöntemi:</td>
                                <td className="px-1 py-0.5">
                                    <DeliveryTypeDesc />
                                </td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Teslim Tarihi: </td>
                                <td className="px-1 py-0.5">
                                    {formatDate(procurementData.delivery_date)}
                                </td>
                            </tr>                     
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Teslimat Adresi: </td>
                                <td className="px-1 py-0.5">
                                    {procurementData.delivery_address}
                                </td>
                            </tr>                     
                        </tbody>
                    </table>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>
                    <table className="w-full">
                        <thead>
                            <tr className="border-b border-gray-300 font-normal">
                                <th className="px-1 py-0.5 font-normal text-left">Ürün</th>
                                <th className="px-1 py-0.5 font-normal text-left">Miktar</th>
                                <th className="px-1 py-0.5 font-normal text-left">Açıklama</th>                                
                                <th className="px-1 py-0.5 font-normal text-left">Şartname</th>
                            </tr>
                        </thead>
                        <tbody>
                            {procurementData.lines !== null && Array.isArray(procurementData.lines) && procurementData.lines.length > 0 ?
                                procurementData.lines.map((line, index) => {
                                    return (
                                        <tr key={`line_${line.product_id}_${index}`} className="border-b border-gray-300 even:bg-gray-200">
                                            <td className="px-1 py-0.5">
                                                <ProductDesc product_id={line.product_id} />
                                            </td>
                                            <td className="px-1 py-0.5">{line.quantity} <UnitCodeDesc unitCodeId={line.unit_code_id} /></td>
                                            <td className="px-1 py-0.5">{line.description_text}</td>
                                            <td className="px-1 py-0.5">
                                                <ProductTechSpecDesc type={line.tech_spec_type} files={line.technical_specification_file} />
                                            </td>
                                        </tr>
                                    )
                                })
                            : 
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5" colSpan={4}>Ürün eklenmemiş</td>
                                </tr>                            
                            }               
                        </tbody>
                    </table>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Açıklamalar</p>
                    <p>{procurementData.description}</p>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Şartname</p>
                    <p>{procurementData.technical_specification_file[0].name}</p>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Tedarikçi Seçimi</p>
                    <table className="w-full">
                        <tbody>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Tedarikçi Seçimi:</td>
                                <td className="px-1 py-0.5">
                                    <SupplierSelectionDesc />
                                </td>
                            </tr>
                            {/* <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Sektör: </td>
                                <td className="px-1 py-0.5">
                                    {procurementData.supplier_business_areas}
                                </td>
                            </tr>                     
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Kategori: </td>
                                <td className="px-1 py-0.5">
                                    {procurementData.supplier_categories}
                                </td>
                            </tr>                     
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-1 py-0.5">Tedarikçiler: </td>
                                <td className="px-1 py-0.5">
                                    {procurementData.suppliers}
                                </td>
                            </tr>                      */}
                        </tbody>
                    </table>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Onay Listesi</p>
                    <table className="w-full">
                        <thead>
                            <tr className="border-b border-gray-300 font-normal">
                                <th className="px-1 py-0.5 font-normal text-left">#</th>
                                <th className="px-1 py-0.5 font-normal text-left">Onay Sırası</th>
                                <th className="px-1 py-0.5 font-normal text-left">Onaylayacak Yönetici</th>                                
                                <th className="px-1 py-0.5 font-normal text-left">Pozisyon</th>
                            </tr>
                        </thead>
                        <tbody>
                            {procurementData.approvalData !== null && Array.isArray(procurementData.approvalData) && procurementData.approvalData.length > 0 ?
                                procurementData.approvalData
                                .sort((a,b) => parseInt(a.approval_sequence) - parseInt(b.approval_sequence))
                                .map((approval, index) => {
                                    return (
                                        <tr key={`approval_${approval.id}`} className="border-b border-gray-300 even:bg-gray-200">
                                            <td className="px-1 py-0.5">{index+1}.</td>
                                            <td className="px-1 py-0.5">{approval.approval_sequence}</td>
                                            <td className="px-1 py-0.5">{approval.approval_user.name}</td>
                                            <td className="px-1 py-0.5 whitespace-pre-line">
                                            {approval.approval_user.departments
                                            .map(department => 
                                                `${department.position.position_name} - ${department.department.department_name}`).join(', \r\n')} 
                                            </td>
                                        </tr>
                                    )
                                })
                            : 
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5" colSpan={4}>Onaylaycak yönetici bulunmuyor</td>
                                </tr>                            
                            }               
                        </tbody>
                    </table>
                </div>
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonClose onClick={() => close()}>
                    </ButtonClose>
                    <ButtonPrevious onClick={() => setCurrentStep(prev => prev-1)}>
                    </ButtonPrevious>
                    <form onSubmit={handleSubmit(handleForm)}>
                        <ButtonSave type="submit">
                        </ButtonSave>
                    </form>         
                </div>         
            </div>
        )
    }
    
    return (  
            <> 
            {status ? 
            <div className="w-full mt-2">
                <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonNew onClick={() => redo()}>
                    </ButtonNew>
                    <ButtonOk onClick={() => close()}>
                    </ButtonOk>
                </div>  
            </div>
            :
            <InfoList />
            }
            {loading && <Loader />}
            </>
    )

}

export default Confirmation