import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {ButtonFilter, ButtonReset} from 'elements/buttons/actionButtons'
import { UserRoles } from 'data'

function TableFilter({isFilterOpen, setIsFilterOpen, filters, setFilters, setSearchParams, roles}) {
    const [resetFields, setResetFields] = useState(false)
    let userRoles = []
    if(roles && roles !== null && Array.isArray(roles) && roles.length > 0) {
        userRoles = roles.map(role => {
            return {key: role.code, value: role.description}
        })
    } 

    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            search: null,
            role: null,
            is_active: null
        }
    })

    const handleForm = async (data) => {
        setSearchParams('page=1')
        setIsFilterOpen(false)
        setFilters(data)
    }

    const handleReset = () => {
        reset()
        setResetFields(!resetFields)
        handleSubmit(handleForm)()
    }

    return (
        <div className={`border border-gray-200 bg-white overflow-hidden transition-[width] duration-300 ease-linear ${isFilterOpen ? 'w-full md:w-[250px] h-auto p-3' : 'w-0 h-0 p-0'}`}>
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12">
                        <InputSmall 
                            type='search'
                            name='search'
                            register={register}
                            label= 'Ara'
                            placeholder='Ad Soyad, E-Posta'
                            validator={{}}
                            error={errors?.search?.message}
                        />                                            
                    </div>
                    <div className="col-span-12">
                        <SelectSmall                                 
                            type='select'
                            name='role'
                            register={register}
                            label='Kullanıcı Yetkisi'
                            placeholder='Kullanıcı Yetkisi'
                            validator={{}}
                            error={errors?.role?.message}
                            data={userRoles}
                        />    
                    </div>
                </div>
                <div className="w-full flex justify-end gap-x-2 mt-5">
                    <ButtonReset type="button" onClick={() => handleReset()}>
                    </ButtonReset>
                    <ButtonFilter type="submit">
                    </ButtonFilter>
                </div>
            </form>   
        </div>
    )
}

export default TableFilter