import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import TableButtonRed from 'elements/dropdowns/TableButtonRed'
import { IconContext } from "react-icons"
import { FaSearch, FaTrashAlt, FaRegEdit, FaBookOpen, FaSlidersH, FaRegCopy, FaBusinessTime, FaCalendarCheck } from 'react-icons/fa'
import { setIsOpenProcurementDetail, setIsOpenProcurementStatusUpdate, setIsOpenProcurementEnddateUpdate, setIsOpenProcurementCancel, setIsOpenProcurementCreate, setIsOpenProcurementIssue } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const CustomLinks = () => {
        if(data.status === 'rejected') return null
        if(data.status === 'waiting_approvals') {
            return (
                <> 
                <TableButton title='İptal Et' icon={<FaTrashAlt />} onClick={() => {actions.setProcurementForCancel(data); dispatch(setIsOpenProcurementCancel(true))}}/>
                </>
            )
        }
        if(data.status === 'waiting_issuance') {
            return (
                <> 
                <TableButton title='İptal Et' icon={<FaTrashAlt />} onClick={() => {actions.setProcurementForCancel(data); dispatch(setIsOpenProcurementCancel(true))}}/>
                <TableButton title='Yayına Al' icon={<FaCalendarCheck />} onClick={() => {actions.setProcurementForIssue(data); dispatch(setIsOpenProcurementIssue(true))}}/>
                </>
            )
        }
        if(data.status === 'uncompleted') {
            return (
                <> 
                <TableButton title='Tarihleri Güncelle' icon={<FaBusinessTime />} onClick={() => {actions.setProcurementForEnddateUpdate(data); dispatch(setIsOpenProcurementEnddateUpdate(true))}}/>
                <TableButton title='İptal Et' icon={<FaTrashAlt />} onClick={() => {actions.setProcurementForCancel(data); dispatch(setIsOpenProcurementCancel(true))}}/>
                <TableButtonRed title='Teklifleri Görüntüle' icon={<FaBookOpen />} borderTop={true} onClick={() => navigate('/company/procurements/' + data.id)} />
                </>
            )
        }
        if(data.status === 'completed') {
            return (
                <> 
                <TableButton title='Durumu Güncelle' icon={<FaRegEdit />} borderTop={true} onClick={() => {actions.setProcurementForStatusUpdate(data); dispatch(setIsOpenProcurementStatusUpdate(true))}}/>
                <TableButtonRed title='Teklifleri Görüntüle' icon={<FaBookOpen />} borderTop={true} onClick={() => navigate('/company/procurements/' + data.id)} />
                </>
            )
        }
        if(data.status === 'cancelled') {
            return (
                <> 
                <TableButton title='Teklifleri Görüntüle' icon={<FaBookOpen />} borderTop={true} onClick={() => navigate('/company/procurements/' + data.id)} />
                </>
            )
        }
        if(data.status === 'archived') {
            return (
                <> 
                <TableButton title='Durumu Güncelle' icon={<FaRegEdit />} borderTop={true} onClick={() => {actions.setProcurementForStatusUpdate(data); dispatch(setIsOpenProcurementStatusUpdate(true))}}/>
                <TableButton title='Teklifleri Görüntüle' icon={<FaBookOpen />} borderTop={true} onClick={() => navigate('/company/procurements/' + data.id)} />
                </>
            )
        }
    }
    
    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton title='Talep Detayları' icon={<FaSearch />} onClick={() => {actions.setProcurementForDetail(data); dispatch(setIsOpenProcurementDetail(true))}}/>
                <CustomLinks />
                <TableButton title='Taslak Olarak Kullan' icon={<FaRegCopy />} borderTop={true} onClick={() => {actions.setProcurementForCopy(data); dispatch(setIsOpenProcurementCreate(true))}}/>
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
