import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseAddProductCategory } from 'stores/DrawersSlice' 
import EditWarehouseCategoryForm from 'modules/companyModule/pages/companies/settings/warehouses/EditWarehouseCategoryForm'

function EditWarehouseCategory({update, setUpdate, warehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenWarehouseAddProductCategory)
    const setIsOpenCreate = () => dispatch(setIsOpenWarehouseAddProductCategory())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Depo Ürün Kategorilerini Düzenle">
            <EditWarehouseCategoryForm 
                update={update} 
                setUpdate={setUpdate} 
                warehouse={warehouse}
            />
        </FormDrawer> 
    )
}

export default EditWarehouseCategory