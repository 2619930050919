function debtActionTypes() {
    return [
        {
            key: 1,
            value: 'Cariyi Borçlandırma'
        },
        {
            key: 2,
            value: 'Cariyi Alacaklandırma'
        },
    ]
}

function debtTypes() {
    return [
        {
            key: 1,
            value: 'E-Arşiv Fatura'
        },
        {
            key: 2,
            value: 'Kağıt Fatura'
        },
        {
            key: 3,
            value: 'Diğer'
        },
    ]
}

function paymentActionType() {
    return [
        {
            key: 1,
            value: 'Cariden Tahsilat'
        },
        {
            key: 2,
            value: 'Cariye Ödeme'
        },
    ]
}

function paymentType() {
    return [
        {
            key: 1,
            value: 'Nakit'
        },
        {
            key: 2,
            value: 'Kredi Kartı'
        },
        {
            key: 3,
            value: 'Senet'
        },
        {
            key: 4,
            value: 'Çek'
        },
        {
            key: 5,
            value: 'Diğer'
        },
    ]
}

function exchangeRateTypes() {
    return [
        {
            key: 1,
            value: 'TCMB Satış'
        },
        {
            key: 2,
            value: 'TCMB Alış'
        },
        {
            key: 3,
            value: 'Ortalama'
        }
    ]
}

function vatRatios() {
    return [
        {
            key: 0,
            value: '0'
        },
        {
            key: 1,
            value: '1'
        },
        {
            key: 8,
            value: '8'
        },
        {
            key: 18,
            value: '18'
        }
    ]
}

function ProcurementStatus() {
    return [
        {key: 'rejected', value: 'Reddedilenler'}, 
        {key: 'waiting_approvals', value: 'Onay Bekleyenler'}, 
        {key: 'waiting_issuance', value: 'Yayınlanmayı Bekleyenler'}, 
        {key: 'uncompleted', value: 'Süreci Devam Edenler'}, 
        {key: 'completed', value: 'Süreci Bitenler'}, 
        {key: 'cancelled', value: 'İptal Edilenler'},
        {key: 'archived', value: 'Arşivlenenler'},
    ]
}

function ApprovalStatus() {
    return [
        {key: 'notprocessed', value: 'İşlem Yapmadıklarım'}, 
        {key: 'accepted', value: 'Onayladıklarım'}, 
        {key: 'rejected', value: 'Reddediklerim'}, 
    ]
}

function ProcurementLineTextType() {
    return [
        {key: 1, value: 'Tedarikçi Girsin (Zorunlu)'}, 
        {key: 2, value: 'Tedarikçi Girsin (İsteğe Bağlı)'}, 
        {key: 3, value: 'Alıcı Girsin (Değiştirilemez)'}, 
        {key: 4, value: 'Alıcı Girsin (Değiştirilebilir)'},
    ]
}

function UserRoles() {
    return [
        {key: 'admin', value: 'Sistem Yöneticisi', id: 1},
        {key: 'manager', value: 'Yönetici', id: 2},
        {key: 'purchasing_personel', value: 'Satın Alma Personeli', id:3},
    ]
}

function useMonths() {
    return [
        {key: '1', value: 'Ocak'},
        {key: '2', value: 'Şubat'},
        {key: '3', value: 'Mart'},
        {key: '4', value: 'Nisan'},
        {key: '5', value: 'Mayıs'},
        {key: '6', value: 'Haziran'},
        {key: '7', value: 'Temmuz'},
        {key: '8', value: 'Ağustos'},
        {key: '9', value: 'Eylül'},
        {key: '10', value: 'Ekim'},
        {key: '11', value: 'Kasım'},
        {key: '12', value: 'Aralık'},
    ]
}

function useYears() {
    return [
        {key: '2023', value: '2023'},
        {key: '2024', value: '2024'},
        {key: '2025', value: '2025'},
        {key: '2026', value: '2026'},
        {key: '2027', value: '2027'},
        {key: '2028', value: '2028'},
        {key: '2029', value: '2029'},
        {key: '2030', value: '2030'},
    ]
}

function usePositionLevels() {
    return [
        {key: '1', value: '1'},
        {key: '2', value: '2'},
        {key: '3', value: '3'},
        {key: '4', value: '4'},
        {key: '5', value: '5'},
        {key: '6', value: '6'},
        {key: '7', value: '7'},
        {key: '8', value: '8'},
        {key: '9', value: '9'},
    ]
}

function DemandStatus() {
    return [
        {key: 'rejected', value: 'Reddedilenler'}, 
        {key: 'rejected_by_warehouse', value: 'Depo Tarafından Reddedilenler'}, 
        {key: 'delivery_rejected', value: 'Teslimi Reddedilenler'}, 
        {key: 'waiting_approvals', value: 'Onay Bekleyenler'}, 
        {key: 'waiting_delivery_approval', value: 'Teslim Onayı Bekleyenler'}, 
        {key: 'waiting_delivery', value: 'Teslim Alınmayı Bekleyenler'}, 
        {key: 'completed', value: 'Teslim Alınanlar'}, 
        {key: 'cancelled', value: 'İptal Edilenler'},
        {key: 'archived', value: 'Arşivlenenler'},
    ]
}

function StockCorrectionType() {
    return [
        {key: 1, value: 'Stok Eksikliği'},
        {key: 2, value: 'Stok Fazlalığı'},
    ]
}

function WarehouseTransferStatus() {
    return [
        {key: 'waiting', value: 'Onay bekleyenler'},
        {key: 'approved', value: 'Onaylananlar'},
        {key: 'rejected', value: 'Reddedilenler'},
    ]
}

function ExpenditureTypes() {
    return [
        {key: '1', value: 'Eğitim Öğretim Giderleri'},
        {key: '2', value: 'Fakülte Laboratuvar Giderleri'},
        {key: '3', value: 'Bilimsel Çalışma Ve Araştırma Giderleri'},
        {key: '4', value: 'Kütüphane Giderleri'},
        {key: '5', value: 'Basın Yayın Giderleri'},
        {key: '6', value: 'Etkinlik Giderleri'},
        {key: '7', value: 'Tanıtım Giderleri'},
        {key: '8', value: 'Personele İlişkin Giderler'},
        {key: '9', value: 'İletişim Giderleri'},
        {key: '10', value: 'Ofis Ve Kırtasiye Giderleri'},
        {key: '11', value: 'Basılı Malzeme Giderleri'},
        {key: '12', value: 'Ağırlama Giderleri'},
        {key: '13', value: 'İşletme Giderleri'},
        {key: '14', value: 'Seyahat Giderleri'},
        {key: '15', value: 'Ulaşım Giderleri'},
        {key: '16', value: 'Lojistik Ve Depolama Giderleri'},
        {key: '17', value: 'Diğer Yürütme Giderleri'},
        {key: '18', value: 'Tüketime Yönelik Malzeme Alım Giderleri'},
        {key: '19', value: 'Atık Yönetimi  Giderleri'},
        {key: '20', value: 'Dışarıdan Sağlanan Hizmet Giderleri'},
        {key: '21', value: 'Bakım Ve Onarım Giderleri'},
        {key: '22', value: 'Bilgi Teknolojileri Giderleri'},
        {key: '23', value: 'Demirbaş Alım Giderleri'},
        {key: '24', value: 'Genetik Tanı Merkezi Giderleri'},
        {key: '25', value: 'Sağlık Hizmetleri İle İlgili Giderler'},
        {key: '26', value: 'Yatırım Giderleri'},
    ]
}

function ProcurementRequestStatus() {
    return [
        {key: 'rejected', value: 'Reddedilenler'}, 
        {key: 'delivery_rejected', value: 'Teslimi Reddedilenler'}, 
        {key: 'waiting_approvals', value: 'Onay Bekleyenler'}, 
        {key: 'waiting_delivery', value: 'Teslim Alınmayı Bekleyenler'}, 
        {key: 'waiting_procurement', value: 'Satın Alınmayı Bekleyenler'}, 
        {key: 'procurement_in_progress', value: 'Satın Alma Süreci Devam Edenler'}, 
        {key: 'completed', value: 'Teslim Alınanlar'}, 
        {key: 'cancelled', value: 'İptal Edilenler'},
        {key: 'archived', value: 'Arşivlenenler'},
    ]
}

function useContactTypes() {
    return [
        {key: 'general', value: 'Şirket Yetkilisi'},
        {key: 'accounting', value: 'Muhasebe Yetkilisi'},
    ]
}

function ProcurementSupplierSelectionTypes() {
    return [
        {key: 'all_suppliers_all_business_areas', value: 'Tüm Tedarikçiler - Tüm Sektörler'},
        {key: 'approved_suppliers_all_business_areas', value: 'Onaylı Tedarikçiler - Tüm Sektörler'},
        {key: 'all_suppliers_selected_business_areas', value: 'Tüm Tedarikçiler - Seçilen Sektörler'},
        {key: 'approved_suppliers_selected_business_areas', value: 'Onaylı Tedarikçiler - Seçilen Sektörler'},
        {key: 'suppliers_by_categories', value: 'Kategoriye Göre Tedarikçiler'},
        {key: 'suppliers_by_ids', value: 'Sadece Seçilen Tedarikçiler'},
    ]
}

function ProcurementProductTechSpecTypes() {
    return [
        {key: 'no_file', value: 'Şartname Olmasın'},
        {key: 'use_product_file', value: 'Ürün Tanımındakini Kullan'},
        {key: 'use_uploaded_file', value: 'Yeni Şartname Yükle'},
    ]
}

function ProcurementProposalTypes() {
    return [
        {key: 'partial', value: 'Kısmi - Tedarikçiler tüm ürünler için teklif vermek zorunda değil, farklı tedarikçilerden ürünler alınabilir'},
        {key: 'block', value: 'Blok - Tedarikçiler tüm ürünler için teklif vermek zorunda, tek bir tedarikçiden teklif kabul edilebilir'},
    ]
}

function ProcurementPaymentTypes() {
    return [
        {key: 'in_advance', value: 'Peşin (Nakit veya Havale/EFT)'},
        {key: 'credit_card', value: 'Kredi Kartı'},
        {key: 'deferred', value: 'Vadeli'},
    ]
}
function ProcurementDeliveryTypes() {
    return [
        {key: 'delivery_to_address', value: 'Adrese Teslim'},
        {key: 'supplier_warehouse', value: 'Depo Teslim - Gönderici Deposu'},
        {key: 'shipment_buyer_paid', value: 'Kargo - Alıcı Ödemeli'},
        {key: 'shipment_supplier_paid', value: 'Kargo - Peşin Ödemeli'},
    ]
}

function CompanyType() {
    return [
        {key: 1, value: 'Şahıs Şirketi'},
        {key: 2, value: 'Limited Şirket'},
        {key: 3, value: 'Anonim Şirket'},
        {key: 4, value: 'Komandit Şirket'},
    ]
}

function SupplierStatusType() {
    return [
        {key: 'approved', value: 'Onaylı'},
        {key: 'waiting_approval', value: 'Onay Bekleyen'},
        {key: 'blacklisted', value: 'Kara Listede'},
    ]
}

export {
    debtActionTypes,
    debtTypes,
    paymentActionType,
    paymentType,
    exchangeRateTypes,
    vatRatios,
    ProcurementStatus,
    ApprovalStatus,
    ProcurementLineTextType,
    UserRoles,
    useMonths,
    useYears,
    usePositionLevels,
    DemandStatus,
    StockCorrectionType,
    WarehouseTransferStatus,
    ExpenditureTypes,
    ProcurementRequestStatus,
    useContactTypes,
    ProcurementSupplierSelectionTypes,
    ProcurementProductTechSpecTypes,
    ProcurementProposalTypes,
    ProcurementPaymentTypes,
    ProcurementDeliveryTypes,
    CompanyType,
    SupplierStatusType,
}