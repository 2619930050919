import { useContactTypes } from 'data'

export function useContactInput(errors) {
    
    return [
        {
            key: "contactInput1",
            type: "select",
            name: "type",
            placeholder: "Yetkili Türü *",
            label: "Yetkili Türü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: useContactTypes(),
            error: errors?.type?.message,
            cols: 'col-span-12', 
        },  
        {
            key: "contactInput2",
            type: "text",
            name: "name",
            placeholder: "Ad Soyad",
            label: "Ad Soyad *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.name?.message,
            cols: 'col-span-12',
        },
        {
            key: "contactInput3",
            type: "text",
            name: "position",
            placeholder: "Görevi",
            label: "Görevi",
            validator: {
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.position?.message,
            cols: 'col-span-12',
        }, 
        {
            key: "contactInput4",
            type: "phone",
            name: "mobile_phone",
            placeholder: "Cep Telefonu",
            label: "Cep Telefonu *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},
                pattern: {value: /^\+90 \((?!0|1)\d{3}\) \d{3}-\d{2}-\d{2}$/,
                            message: "Geçerli bir telefon numarası olmalı"}
            },
            error: errors?.mobile_phone?.message,
            cols: 'col-span-12',
        },
        {
            key: "contactInput5",
            type: "phone",
            name: "office_phone",
            placeholder: "İş Telefonu",
            label: "İş Telefonu",
            validator: {
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},
                pattern: {value: /^\+90 \((?!0|1)\d{3}\) \d{3}-\d{2}-\d{2}$/,
                            message: "Geçerli bir telefon numarası olmalı"}
            },
            error: errors?.office_phone?.message,
            cols: 'col-span-12',
        },
        {
            key: "contactInput6",
            type: "text",
            name: "email",
            placeholder: "E-Posta Adresi",
            label: "E-Posta Adresi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message,
            cols: 'col-span-12',
        },   
    ]
}

