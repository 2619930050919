import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'
import Logo from 'elements/sidebar/Logo'
import Links from 'elements/sidebar/Links'
import { toggle } from 'stores/MobileMenuSlice'

function Sidebar() {
    const openSidemenu = useSelector((state) => state.mobileMenu.isOpen)
    const year = DateTime.local().setLocale('tr').toFormat('yyyy')

    const dispatch = useDispatch()
    const { pathname } = useLocation()

    useEffect(() => {
        dispatch(toggle(false))
    }, [pathname])

    return (
        <div className={`flex-none md:w-[250px] overflow-hidden transition-[width] duration-300 ease-linear  ${openSidemenu ? 'w-[210px]' : 'w-0'}`}>
            <div className="z-40 md:z-40 w-full shadow-lg h-screen md:h-screen">
                <div className="w-full h-full flex flex-col items-start justify-between w-full bg-slate-800 transition-all duration-150 ease-in-out">
                    <div className="w-full">
                        <Logo />
                    </div>
                    <div className="w-full flex-1 overflow-y-auto">
                        <Links />
                    </div>
                    <div className="w-full p-2 text-center text-slate-500">
                        <p><a href="https://favera.com.tr" target="_blank">Favera</a> © 2011-{year}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar