import TableActions from 'modules/companyModule/pages/products/TableActions'
import TableLayout from 'elements/tables'
import FindCategory from 'utils/FindCategory'
import { useSelector } from 'react-redux'

const Table = ({data, actions, progressPending, pagination, pageMeta}) => {

    const columns = [
        {
            name: 'Ürün Adı',
            selector: (row) => {return row.name},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Türü',
            selector: (row) => {return row.product_type.name},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Kodu',
            selector: (row) => {return row.product_code},
            headerMobileHidden: false,
        },
        {
            name: 'Barkod',
            selector: (row) => {return row.barcode},
            headerMobileHidden: false,
        },
        {
            name: 'Açıklama',
            selector: (row) => {return row.description?.length > 35 ? row.description.slice(0, 34) + '...' : row.description},
            headerMobileHidden: false,
        },
        {
            name: 'Birim',
            selector: (row) => {return `${row.unit.description}`},
            headerMobileHidden: false,
        },      {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return row.name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
