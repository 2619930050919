export function activeProposals(procurement) {
    return procurement.proposals.filter(proposal => {
        return proposal.is_revised === false && proposal.is_cancelled === false
    })
}

export function sortedProposals(procurement, usedRate, exchangeRates) {

    const activeProposals = procurement.proposals.filter(proposal => {
        return proposal.is_revised === false && proposal.is_cancelled === false
    })
    const revisedCurrencyProposals = procurement.is_currency_flexible ? 
                                        activeProposals.map(p => {
                                            if(p.currency.id === procurement.currency.id) {
                                                return {...p, derived_price: p.total_price}
                                            } else {
                                                if(procurement.currency.id === 1) {
                                                    const foundCurrency = exchangeRates.filter(er => er.code === p.currency.code)
                                                    if(foundCurrency.length > 0) {
                                                        return {...p, derived_price: p.total_price*parseFloat(foundCurrency[0][usedRate].replace(',', '.'))}
                                                    }
                                                    return {...p, derived_price: p.total_price}                                                
                                                }
                                                const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                                                const foundCurrencyProposal = exchangeRates.filter(er => er.code === p.currency.code)
                                                if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                                                    return {...p, derived_price: p.total_price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.'))}
                                                }
                                                return {...p, derived_price: p.total_price}   
                                            }
                                        }) :
                                        activeProposals.map(p => {
                                            return {...p, derived_price: p.total_price}
                                        })

    const sortedProposalsNotSliced = revisedCurrencyProposals.sort((a, b) => a.derived_price - b.derived_price)

    return sortedProposalsNotSliced
}

export function slicedProposals(proposals, length) {
    return proposals.slice(0,length)
}

export function tableLabels(proposals) {
    return proposals.map(ap => { 
        return {
            id: ap.id,
            supplierId: ap.supplier.id,
            name: ap.supplier.company_name.length > 15 ? 
                    ap.supplier.company_name.slice(0, 14) + ' ...' :
                    ap.supplier.company_name
        }
    })
}

export function procurementLines(procurement) {
    return procurement.lines.map(line => {
        return {name: line.product.name, id: line.id}
    })
}

export function transformedDataForBlock(procurement, proposals, slicedProposals, procurementLines, usedRate, exchangeRates) {

    return procurementLines.map((stack, index) => {
        return {
            id: stack.id,
            label: stack.name,
            data: slicedProposals.map(proposal => {
                const proposalLine = proposal.lines.find(line => line.procurement_line.id === stack.id)
                if(proposalLine) {
                    if(proposal.currency.id === procurement.currency.id) {
                        return {
                            id: proposalLine.id,
                            proposalId: proposal.id,
                            price: proposalLine.price,
                            derived_price: proposalLine.price,
                            currencyId: proposal.currency.id,
                            currencyCode: proposal.currency.code
                        }
                    } 
                    if(procurement.currency.id === 1) {
                        const foundCurrency = exchangeRates.filter(er => er.code === proposal.currency.code)
                        if(foundCurrency.length > 0) {
                            return {
                                id: proposalLine.id,
                                proposalId: proposal.id,
                                price: proposalLine.price,
                                derived_price: proposalLine.price*parseFloat(foundCurrency[0][usedRate].replace(',', '.')),
                                currencyId: proposal.currency.id,
                                currencyCode: proposal.currency.code
                            }
                        }
                        return {
                            id: proposalLine.id,
                            proposalId: proposal.id,
                            price: proposalLine.price,
                            derived_price: proposalLine.price,
                            currencyId: proposal.currency.id,
                            currencyCode: proposal.currency.code
                        }                                              
                    }
                    const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                    const foundCurrencyProposal = exchangeRates.filter(er => er.code === proposal.currency.code)
                    if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                        return {
                            id: proposalLine.id,
                            proposalId: proposal.id,
                            price: proposalLine.price,
                            derived_price: proposalLine.price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.')),
                            currencyId: proposal.currency.id,
                            currencyCode: proposal.currency.code
                        }
                    }
                    return {
                        id: proposalLine.id,
                        proposalId: proposal.id,
                        price: proposalLine.price,
                        derived_price: proposalLine.price,
                        currencyId: proposal.currency.id,
                        currencyCode: proposal.currency.code
                    }  
                    
                }
                return null
            }),
            statistics: {
                min: proposals.map(rcp => {
                    const rcpLine = rcp.lines.find(line => line.procurement_line.id === stack.id)
                    if(rcpLine) {
                        if(rcp.currency.id === procurement.currency.id) {
                            return rcpLine.price
                        } 
                        if(procurement.currency.id === 1) {
                            const foundCurrency = exchangeRates.filter(er => er.code === rcp.currency.code)
                            if(foundCurrency.length > 0) {
                                return rcpLine.price*parseFloat(foundCurrency[0][usedRate].replace(',', '.'))
                            }
                            return rcpLine.price                                            
                        }
                        const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                        const foundCurrencyProposal = exchangeRates.filter(er => er.code === rcp.currency.code)
                        if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                            return rcpLine.price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.'))
                        }
                        return rcpLine.price                        
                    }
                    return Infinity
                })
                .sort((a, b) => a - b)[0],
                max: proposals.map(rcp => {
                    const rcpLine = rcp.lines.find(line => line.procurement_line.id === stack.id)
                    if(rcpLine) {
                        if(rcp.currency.id === procurement.currency.id) {
                            return rcpLine.price
                        } 
                        if(procurement.currency.id === 1) {
                            const foundCurrency = exchangeRates.filter(er => er.code === rcp.currency.code)
                            if(foundCurrency.length > 0) {
                                return rcpLine.price*parseFloat(foundCurrency[0][usedRate].replace(',', '.'))
                            }
                            return rcpLine.price                                            
                        }
                        const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                        const foundCurrencyProposal = exchangeRates.filter(er => er.code === rcp.currency.code)
                        if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                            return rcpLine.price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.'))
                        }
                        return rcpLine.price                        
                    }
                    return Infinity
                })
                .sort((a, b) => b - a)[0],
                avg: proposals.map(rcp => {
                    const rcpLine = rcp.lines.find(line => line.procurement_line.id === stack.id)
                    if(rcpLine) {
                        if(rcp.currency.id === procurement.currency.id) {
                            return rcpLine.price
                        } 
                        if(procurement.currency.id === 1) {
                            const foundCurrency = exchangeRates.filter(er => er.code === rcp.currency.code)
                            if(foundCurrency.length > 0) {
                                return rcpLine.price*parseFloat(foundCurrency[0][usedRate].replace(',', '.'))
                            }
                            return rcpLine.price                                            
                        }
                        const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                        const foundCurrencyProposal = exchangeRates.filter(er => er.code === rcp.currency.code)
                        if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                            return rcpLine.price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.'))
                        }
                        return rcpLine.price                        
                    }
                    return 0
                })
                .reduce((prev, current, _, {length}) => {
                    return prev + current / length 
                }, 0),
            }
            }
    })
}

export function transformedDataForPartial(procurement, proposals, procurementLines, usedRate, exchangeRates, length) {
    return procurementLines.map((stack, index) => {
        return {
            id: stack.id,
            label: stack.name,
            data: proposals.map(proposal => {
                const proposalLine = proposal.lines.find(line => line.procurement_line.id === stack.id)
                if(proposalLine) {
                    if(proposal.currency.id === procurement.currency.id) {
                        return {
                            id: proposalLine.id,
                            proposalId: proposal.id,
                            price: proposalLine.price,
                            derived_price: proposalLine.price,
                            currencyId: proposal.currency.id,
                            currencyCode: proposal.currency.code,
                            supplierId: proposal.supplier.id,
                            supplierName: proposal.supplier.company_name.length > 15 ? 
                                    proposal.supplier.company_name.slice(0, 14) + ' ...' :
                                    proposal.supplier.company_name,
                            paymentType: proposal.payment_type,
                            deferralDays: proposal.deferral_days,
                        }
                    } 
                    if(procurement.currency.id === 1) {
                        const foundCurrency = exchangeRates.filter(er => er.code === proposal.currency.code)
                        if(foundCurrency.length > 0) {
                            return {
                                id: proposalLine.id,
                                proposalId: proposal.id,
                                price: proposalLine.price,
                                derived_price: proposalLine.price*parseFloat(foundCurrency[0][usedRate].replace(',', '.')),
                                currencyId: proposal.currency.id,
                                currencyCode: proposal.currency.code,
                                supplierId: proposal.supplier.id,
                                supplierName: proposal.supplier.company_name.length > 15 ? 
                                        proposal.supplier.company_name.slice(0, 14) + ' ...' :
                                        proposal.supplier.company_name,
                                paymentType: proposal.payment_type,
                                deferralDays: proposal.deferral_days,
                            }
                        }
                        return {
                            id: proposalLine.id,
                            proposalId: proposal.id,
                            price: proposalLine.price,
                            derived_price: proposalLine.price,
                            currencyId: proposal.currency.id,
                            currencyCode: proposal.currency.code,
                            supplierId: proposal.supplier.id,
                            supplierName: proposal.supplier.company_name.length > 15 ? 
                                    proposal.supplier.company_name.slice(0, 14) + ' ...' :
                                    proposal.supplier.company_name,
                            paymentType: proposal.payment_type,
                            deferralDays: proposal.deferral_days,
                        }                                              
                    }
                    const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                    const foundCurrencyProposal = exchangeRates.filter(er => er.code === proposal.currency.code)
                    if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                        return {
                            id: proposalLine.id,
                            proposalId: proposal.id,
                            price: proposalLine.price,
                            derived_price: proposalLine.price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.')),
                            currencyId: proposal.currency.id,
                            currencyCode: proposal.currency.code,
                            supplierId: proposal.supplier.id,
                            supplierName: proposal.supplier.company_name.length > 15 ? 
                                    proposal.supplier.company_name.slice(0, 14) + ' ...' :
                                    proposal.supplier.company_name,
                            paymentType: proposal.payment_type,
                            deferralDays: proposal.deferral_days,
                        }
                    }
                    return {
                        id: proposalLine.id,
                        proposalId: proposal.id,
                        price: proposalLine.price,
                        derived_price: proposalLine.price,
                        currencyId: proposal.currency.id,
                        currencyCode: proposal.currency.code,
                        supplierId: proposal.supplier.id,
                        supplierName: proposal.supplier.company_name.length > 15 ? 
                                proposal.supplier.company_name.slice(0, 14) + ' ...' :
                                proposal.supplier.company_name,
                        paymentType: proposal.payment_type,
                        deferralDays: proposal.deferral_days,
                    }  
                    
                }
                return null
            })
            .filter(derivedData => derivedData !== null)
            .sort((a,b) => a.derived_price - b.derived_price)
            .slice(0,length),
            statistics: {
                min: proposals.map(rcp => {
                    const rcpLine = rcp.lines.find(line => line.procurement_line.id === stack.id)
                    if(rcpLine) {
                        if(rcp.currency.id === procurement.currency.id) {
                            return rcpLine.price
                        } 
                        if(procurement.currency.id === 1) {
                            const foundCurrency = exchangeRates.filter(er => er.code === rcp.currency.code)
                            if(foundCurrency.length > 0) {
                                return rcpLine.price*parseFloat(foundCurrency[0][usedRate].replace(',', '.'))
                            }
                            return rcpLine.price                                            
                        }
                        const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                        const foundCurrencyProposal = exchangeRates.filter(er => er.code === rcp.currency.code)
                        if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                            return rcpLine.price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.'))
                        }
                        return rcpLine.price                        
                    }
                    return null
                })
                .filter(derivedData => derivedData !== null)
                .sort((a, b) => a - b)[0],
                max: proposals.map(rcp => {
                    const rcpLine = rcp.lines.find(line => line.procurement_line.id === stack.id)
                    if(rcpLine) {
                        if(rcp.currency.id === procurement.currency.id) {
                            return rcpLine.price
                        } 
                        if(procurement.currency.id === 1) {
                            const foundCurrency = exchangeRates.filter(er => er.code === rcp.currency.code)
                            if(foundCurrency.length > 0) {
                                return rcpLine.price*parseFloat(foundCurrency[0][usedRate].replace(',', '.'))
                            }
                            return rcpLine.price                                            
                        }
                        const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                        const foundCurrencyProposal = exchangeRates.filter(er => er.code === rcp.currency.code)
                        if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                            return rcpLine.price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.'))
                        }
                        return rcpLine.price                        
                    }
                    return null
                })
                .filter(derivedData => derivedData !== null)
                .sort((a, b) => b - a)[0],
                avg: proposals.map(rcp => {
                    const rcpLine = rcp.lines.find(line => line.procurement_line.id === stack.id)
                    if(rcpLine) {
                        if(rcp.currency.id === procurement.currency.id) {
                            return rcpLine.price
                        } 
                        if(procurement.currency.id === 1) {
                            const foundCurrency = exchangeRates.filter(er => er.code === rcp.currency.code)
                            if(foundCurrency.length > 0) {
                                return rcpLine.price*parseFloat(foundCurrency[0][usedRate].replace(',', '.'))
                            }
                            return rcpLine.price                                            
                        }
                        const foundCurrencyProcurement = exchangeRates.filter(er => er.code === procurement.currency.code)
                        const foundCurrencyProposal = exchangeRates.filter(er => er.code === rcp.currency.code)
                        if(foundCurrencyProcurement.length > 0 && foundCurrencyProposal.length > 0) {
                            return rcpLine.price/parseFloat(foundCurrencyProposal[0][usedRate].replace(',', '.'))*parseFloat(foundCurrencyProcurement[0][usedRate].replace(',', '.'))
                        }
                        return rcpLine.price                        
                    }
                    return 0
                })
                .reduce((prev, current, _, {length}) => {
                    return prev + current / length 
                }, 0),
            }
            }
    })
}