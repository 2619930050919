function useRandomColors() {
    return [
        'rgb(7, 73, 7)',
        'rgb(255, 99, 132)',
        'rgb(118, 119, 118)',
        'rgb(90, 2, 112)',
        'rgb(168, 173, 221)',
        'rgb(26, 51, 10)',
        'rgb(85, 74, 86)',
        'rgb(24, 56, 17)',
        'rgb(143, 188, 193)',
        'rgb(27, 114, 10)',
        'rgb(76, 45, 32)',
        'rgb(20, 20, 20)',
        'rgb(27, 163, 99)',
        'rgb(198, 190, 193)',
        'rgb(42, 33, 63)',
        'rgb(22, 12, 16)',
        'rgb(175, 160, 139)',
        'rgb(168, 167, 136)',
        'rgb(9, 5, 28)',
        'rgb(5, 45, 63)',
        'rgb(102, 67, 165)',
        'rgb(122, 96, 90)',
        'rgb(188, 145, 151)',
        'rgb(89, 82, 79)',
        'rgb(73, 57, 53)',
        'rgb(247, 220, 237)',
        'rgb(34, 14, 45)',
        'rgb(86, 45, 26)',
        'rgb(137, 123, 150)',
        'rgb(90, 90, 112)',
        'rgb(197, 104, 216)',
    ]
}

export {
    useRandomColors,
}