export function useSupplierInvitaitonInput(errors) {
    
    return [
        {
            key: "supplierInvitationInput1",
            type: "text",
            name: "supplier_name",
            placeholder: "Tedarikçi Adı/Ünvanı",
            label: "Tedarikçi Adı/Ünvanı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.supplier_name?.message,
            cols: 'col-span-12', 
        },
        {
            key: "supplierInvitationInput2",
            type: "text",
            name: "tax_number",
            placeholder: "Vergi No",
            label: "Vergi No *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]{10,11}$/,
                            message: "10 veya 11 haneli rakam olmalı"},
            },
            error: errors?.tax_number?.message,
            cols: 'col-span-12',
        },   
        {
            key: "supplierInvitationInput3",
            type: "text",
            name: "supplier_email",
            placeholder: "E-Posta",
            label: "E-Posta *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.supplier_email?.message,
            cols: 'col-span-12',
        },        
    ]
}

