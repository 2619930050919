import { useSelector } from 'react-redux'

export function useAccountInput(errors) {
    const currencies = useSelector(state => state.sharedData.currenciesWithDesc)

    return [        
        {
            key: "accountInput1",
            type: "text",
            name: "bank_name",
            placeholder: "Banka Adı",
            label: "Banka Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.bank_name?.message,
            cols: 'col-span-12',
        },
        {
            key: "contactInput2",
            type: "text",
            name: "branch_name",
            placeholder: "Şube Adı",
            label: "Şube Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.branch_name?.message,
            cols: 'col-span-12',
        }, 
        {
            key: "contactInput3",
            type: "text",
            name: "iban",
            placeholder: "IBAN",
            label: "IBAN *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 100, 
                            message:"En fazla 100 karakter olmalı"}
            },
            error: errors?.iban?.message,
            cols: 'col-span-12',
        },
        {
            key: "contactInput4",
            type: "text",
            name: "account_no",
            placeholder: "Hesap Numarası",
            label: "Hesap Numarası *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 100, 
                            message:"En fazla 100 karakter olmalı"}
            },
            error: errors?.account_no?.message,
            cols: 'col-span-12',
        },
        {
            key: "contactInput5",
            type: "select",
            name: "currency_id",
            placeholder: "Para Birimi *",
            label: "Para Birimi *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: currencies,
            error: errors?.currency_id?.message,
            cols: 'col-span-12', 
        },  
    ]
}

