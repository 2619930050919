import { useSelector } from 'react-redux'

export function useProductInput(errors, costTypes, techSpecFileAttributes, setTechSpecFileAttributes) {
    const productTypes = useSelector(state => state.sharedData.productTypes)
    const unitCodes = useSelector(state => state.sharedData.unitCodes)
    const costTypeArray = costTypes.map(ct => {
        return {key: ct.id, value: ct.cost_name}
    })

    return [
        {
            key: "productInput1",
            type: "text",
            name: "name",
            placeholder: "Ürün/Hizmet Adı",
            label: "Ürün/Hizmet Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.name?.message,
            cols: 'col-span-12', 
        },
        {
            key: "productInput2",
            type: "select",
            name: "product_type_id",
            placeholder: "Ürün/Hizmet Türü",
            label: "Ürün/Hizmet Türü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.product_type_id?.message,
            data: productTypes,
            cols: 'col-span-12', 
        },        
        {
            key: "productInput3",
            type: "select",
            name: "cost_type_id",
            placeholder: "Gider Türü",
            label: "Gider Türü",
            validator: {
                               
            },
            error: errors?.cost_type_id?.message,
            data: costTypeArray,
            cols: 'col-span-12', 
        },        
        {
            key: "productInput4",
            type: "text",
            name: "product_code",
            placeholder: "Ürün Kodu",
            label: "Ürün Kodu",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.product_code?.message,
            cols: 'col-span-5',
        },
        {
            key: "productInput5",
            type: "text",
            name: "barcode",
            placeholder: "Barkod",
            label: "Barkod",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.barcode?.message,
            cols: 'col-span-4',
        },   
        {
            key: "productInput6",
            type: "select",
            name: "unit_code_id",
            placeholder: "Birim Kodu",
            label: "Birim Kodu *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.unit_code_id?.message,
            data: unitCodes,
            cols: 'col-span-3', 
        },   
        {
            key: "productInput7",
            type: "textarea",
            name: "description",
            placeholder: "Açıklama",
            label: "Açıklama",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.description?.message,
            cols: 'col-span-12',
        },    
        {
            key: "productInput8",
            type: "file",
            name: "technical_specification_file",
            placeholder: "Dosya Seç",
            label: "Teknik Şartname Dosyası",
            validator: {
                validate: (value) => {
                    if(value?.[0] && value[0].type !== "application/pdf") {
                        return 'PDF dosyası seçmelisiniz'
                    }
                    return true
                },
                onChange: (e) => {
                    let files = e.target.files
                    if(files.length > 0) {
                        setTechSpecFileAttributes({
                            filename: files[0].name,
                            size: files[0].size,
                            type: files[0].type,
                        })
                    } else {
                        setTechSpecFileAttributes({
                            filename: null,
                            size: null,
                            type: null,
                        })
                    }
                }
            },
            error: errors?.technical_specification_file?.message,
            notSelectedText: 'Dosya Seçilmedi',
            fileAttributes: techSpecFileAttributes,
            cols: 'col-span-12', 
        }, 
    ]
}

