import React from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { DateTime } from 'luxon'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import TableButtonRed from 'elements/dropdowns/TableButtonRed'
import { IconContext } from "react-icons"
import { FaSearch, FaEnvelopeOpenText, FaSlidersH, FaTimesCircle, FaSearchPlus, FaFileInvoice } from 'react-icons/fa'
import { setIsOpenProcurementDetail, 
            setIsOpenMakeProposal, 
            setIsOpenSupplierProposalInfo, 
            setIsOpenSupplierProposalCancel,
            setIsOpenOrderForm } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()

    const userRoles = useSelector(state => state.user.user.roles)
    const auth = {
        make_proposal: userRoles.find(roles => roles.code === 'supplier_make_proposals') !== undefined ? true : false,
        proposal: userRoles.find(roles => roles.code === 'supplier_proposals') !== undefined ? true : false,
    }
    
    const isProposalMade = () => {
        if(data.proposals && Array.isArray(data.proposals) && data.proposals.length > 0) {
            let activeProposal = data.proposals.filter(proposal => {
                return proposal.is_cancelled === false && proposal.is_revised === false
            })
            if(activeProposal.length > 0) {
                if(activeProposal[0].is_accepted === true) return 'accepted'
                if(data.status !== 'uncompleted' || DateTime.fromISO(data.end_date) < DateTime.now()) return 'notaccepted' 
                return 'inprogress'    
            }

            return 'noactiveproposal'
        } 

        return 'noproposal'
    }
    
    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton title='Talep Detayları' icon={<FaSearch />} onClick={() => {actions.setProcurementForDetail(data); dispatch(setIsOpenProcurementDetail(true))}}/>
                {isProposalMade() !== 'noproposal' ?
                <TableButton title='Teklif Detayları' icon={<FaSearchPlus />} onClick={() => {actions.setProcurementForProposalInfo(data); dispatch(setIsOpenSupplierProposalInfo(true))}}/>
                : null }
                { (isProposalMade() === 'accepted' || isProposalMade() === 'notaccepted') ?
                null
                :  auth.make_proposal ? 
                    <TableButton title={isProposalMade() === 'inprogress' ? 'Teklifi Revize Et' : 'Teklif Ver'} icon={<FaEnvelopeOpenText />} onClick={() => {actions.setProcurementForProposal(data); dispatch(setIsOpenMakeProposal(true))}}/>
                    : null
                }
                {(auth.make_proposal && isProposalMade() === 'inprogress' && !data.is_proposal_cancel_not_allowed) ? 
                <TableButton title='Teklifi İptal Et' icon={<FaTimesCircle />} onClick={() => {actions.setProcurementForCancelProposal(data); dispatch(setIsOpenSupplierProposalCancel(true))}}/>
                : null }
                {isProposalMade() === 'accepted' ? 
                <TableButtonRed title='Sipariş Formu' icon={<FaFileInvoice />} onClick={() => {actions.setProcurementForOrder(data); dispatch(setIsOpenOrderForm(true))}}/>
                : null }
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
