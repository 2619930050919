import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {ButtonNew} from 'elements/buttons/actionButtons'
import CreateCategory from 'modules/companyModule/pages/products/settings/categories/CreateCategory'
import EditCategory from 'modules/companyModule/pages/products/settings/categories/EditCategory'
import { setIsOpenProductCategoryCreate } from 'stores/DrawersSlice'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import CategoryCard from 'modules/companyModule/pages/products/settings/categories/CategoryCard'

function Categories(){
    const dispatch = useDispatch()
    const [updateData, setUpdateData] = useState(false)
    const [categories, setCategories] = useState([])
    const [parent, setParent] = useState(null)
    const [loading, setLoading] = useState(false)
    const [categoryForEdit, setCategoryForEdit] = useState(null)
    const companyId = useSelector(state => state.company.selectedCompany.id)
    
    useEffect(() => {
          const getCategories = async () => {
              setLoading(true)
              await SendRequest('GET', `api/companies/${companyId}/product_categories`)
                  .then(response => {
                      if(response.status === 200) {
                        setCategories(response.data)
                      }
                      setLoading(false)
                  })
                  .catch(err => {
                      setLoading(false)
                  })
          }
          getCategories()
    }, [updateData])

    const CategoryList = ({cats}) => {
      return (
        <>
          {cats.map(category => (
            <div key={category.id}>
              <CategoryCard 
                  category={category}
                  setParent={setParent}
                  setCategoryForEdit={setCategoryForEdit}
              />
              {category.all_sub_categories?.length > 0 && <div className="w-full pl-10"><CategoryList cats={category.all_sub_categories} /></div> }
            </div>
          ))}
        </>
      )
    }

    return (
      <div className="w-full mb-4">
          <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
              <h2 className="text-lg text-gray-800">Kategoriler</h2>
              <ButtonNew label="Yeni Ana Kategori" onClick={() => {setParent(null); dispatch(setIsOpenProductCategoryCreate(true))}}>
              </ButtonNew>
          </div>          
          <div>
              <CategoryList cats={categories} />
          </div>
          <CreateCategory update={updateData} setUpdate={setUpdateData} parent={parent} setParent={setParent} />
          <EditCategory update={updateData} setUpdate={setUpdateData} category={categoryForEdit} setCategory={setCategoryForEdit} />
          {loading && <Loader />}
      </div>
    )
  }
  
  export default Categories