import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenEdit } from 'modules/companyModule/pages/products/settings/taxonomies/taxa/DrawersSlice'
import EditForm from 'modules/companyModule/pages/products/settings/taxonomies/taxa/EditForm'

function Edit({taxonomy, parent, taxon, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.companyProductTaxonDrawers.isOpenEdit)
    const setIsOpen = () => dispatch(setIsOpenEdit())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Sınıf Bilgisini Düzenle">
            <EditForm taxonomy={taxonomy} parent={parent} taxon={taxon} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Edit