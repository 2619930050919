import { FaRegFolderOpen } from 'react-icons/fa'
import { FaTimesCircle } from 'react-icons/fa'

function FormDrawer({ children, isOpen, setIsOpen, maxWidth = 'max-w-lg', title = '#null' }) {

    const closeDrawer = () => setIsOpen(false)
    return (
        <main
          className={
            " fixed overflow-hidden z-20 bg-gray-900 bg-opacity-75 inset-0 transform ease-in-out " +
            (isOpen
              ? " transition-opacity opacity-100 duration-500 translate-x-0  "
              : " transition-all delay-500 opacity-0 translate-x-full  ")
          }
        >
          <section
            className={
              maxWidth + " w-screen right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
              (isOpen ? " translate-x-0 " : " translate-x-full ")
            }
          >
            <article className="relative w-full flex flex-col space-y-6 overflow-y-scroll h-full">
                <header className="flex items-center justify-between py-4 bg-sky-800 text-sky-100 px-4 font-bold text-lg">
                    <span className="flex items-center gap-x-2">
                    <FaRegFolderOpen />
                    {title}
                    </span>
                    <button onClick={() => closeDrawer()}><FaTimesCircle /></button>
                </header>
                <div className="px-4">
                {children}
                </div>
                
            </article>
          </section>
          <section
            className=" w-screen h-full cursor-pointer "
            onClick={() => {closeDrawer()}}
          ></section>
        </main>
      )
}

export default FormDrawer