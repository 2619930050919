

function SupplierUserAgreement() {
    return (
        <div className="w-full p-1 text-xs text-gray-800">
            <p className="mb-2">Favera Yazılım ve Bilişim Teknolojileri Ltd. Şti. ('Favera'), Üyelerini elektronik ticaret yapmaları için internet ortamında buluşturan, MARLAPORT.COM'un kurucusu ve işleticisidir.</p>
            <p className="mb-2">FAVERA ile Üye Firma arasındaki ticari ve hukuki ilişki, sadece MARLAPORT.COM Elektronik Ticaret platformdaki e-ticaret hizmetlerinden FAVERA'nın sistem/platform sağlayıcısı sıfatı ile Üye Firma'yı faydalandırmasından ibaret olup, FAVERA Üye Firma'nın Üyeliği sonrası yapacağı elektronik ticarette herhangi bir taraf değildir. MARLAPORT.COM Üyeliği, kullanım Ücretleri ve esasları FAVERA ile Üye Firma arasında yapılan sözleşme ile belirlenir.</p>
            <p className="mb-2">Üye Firma, MARLAPORT.COM hizmetlerini kullanımına ilişkin gerçekleştireceği tüm faaliyetlerinde, yürürlükteki kanun, yönetmelik ve ilgili tüm mevzuata, taraflar arasındaki Sözleşme hükümlerine, işbu Kullanım Koşullarına ve gelenek, görenek ve adetlere uygun davranmakla mükelleftir.</p>
            <p className="mb-2">MARLAPORT.COM Web Sitesi ile Üye Firma arasında paylaşılan veriler gizlilik ve bilgi güvenliği esasları uyarınca koruma altındadır. Belirtilen bu amaç kapsamında, FAVERA, Web Sitesi'nde sunulan hizmetler bakımından bilgi güvenliğinin korunabilmesi amacı ile sistemlerinde ve internet altyapısında gerekli önlemleri almaktadır.</p>
            <p className="mb-2">Bilgi güvenliği kapsamında alınan tedbirler gereği belirli zamanlarda siteden duyurular yapılabilecek olup, Üye Firma'nın şifre değiştirmesi yahut bir başka güvenlik tedbirini uygulaması talep edilebilecektir.</p>
            <p className="mb-2">MARLAPORT.COM internet sitesindeki tüm içerik ve dokümanlar FAVERA'nın mülkiyetindedir. MARLAPORT.COM ziyaretçileri, kod ve yazılım da dahil olmak üzere, bu sitedeki hiç bir malzemeyi/içeriği değiştiremez, kopyalayamaz, çoğaltamaz, kiralayamaz, yeniden yayımlayamaz, başka bir bilgisayara yükleyemez, herhangi bir yöntemle iletemez ya da dağıtamaz.</p>
            <p className="mb-2">MARLAPORT.COM internet sitesinin işlerliği için teknolojik olarak her tür tedbir alınmış olmakla birlikte, internet sitesinin ve dolayısıyla MARLAPORT.COM 'un elinde olmayan nedenlerden dolayı örn: internet servis sağlayıcı sistemlerindeki genel arıza, elektrik kesintileri vb. geçici olarak hizmet verememesinden FAVERA sorumlu olmayıp, bu hususta oluşabilecek herhangi bir zarardan, yayınlanan içeriğin kesintisinden, gecikmesinden veya hatalı olmasından ve bunların yol açabileceği zararlardan mesul değildir. FAVERA her ne kadar MARLAPORT.COM internet sitesinin işlerliği açısından sitenin virüs vb. saldırılardan korunması amaçlı gerekli tedbirleri almış olsa da, bu hususta asıl sorumluluk Üye Firma/ziyaretçiye aittir. Bu amaçla ziyaretçi/Üye Firma gerekli koruma tedbirlerini kendi sistemleri açısından da sağlamakla mükelleftir.</p>
            <p className="mb-2">ÜYE, işbu Sözleşmeyi imzaladığı tarihten itibaren en geç 7 (yedi) gün içinde, Platform içerisinde açılmış her hangi bir teklif/ihaleye katılım teyidi ve/veya teklif vermemiş, sipariş almamış olmak koşulu ile üyeliğini sona erdirebilir. ÜYE, üyeliğini sona erdirme talebini MARLAPORT.COM’a yazılı olarak ve/veya elektronik ortamda iletir. MARLAPORT.COM, ÜYE’nin belirtilen koşulları sağladığına ilişkin onay verirse; ödemiş olduğu Hizmet Bedeli ÜYE’ye [30] işgünü içerisinde geri ödenir. ÜYE, MARLAPORT.COM’dan herhangi bir nam altında zarar, ziyan tazmini talebinde bulunmayacağını beyan kabul ve taahhüt etmektedir. MARLAPORT.COM, ÜYE’nin belirtilen koşulları sağladığına onay vermez ise Hizmet Bedeli’nin iadesi talebini reddeder.</p>
            <p className="mb-2">FAVERA, MARLAPORT.COM internet sitesinde yayınlamakta olduğu işbu Kullanım Koşullarının hüküm ve koşullarını dilediği zaman önceden haber vermek zorunda olmaksızın tek taraflı olarak değiştirmek ve güncellemek hakkına sahiptir. Değiştirilen, yenilenen ya da yürürlükten kaldırılan her hüküm, yayın tarihinde tüm kullanıcılar bakımından hüküm ifade eder.</p>
            <p className="mb-2">FAVERA YAZILIM VE BİLİŞİM TEKNOLOJİLERİ LTD. ŞTİ.</p>
            <p className="mb-2">Bu Kullanım Anlaşması'nı kabul ediyorum ve yukarıda belirtilen şirketin veya kurumun bir çalışanı olduğumu ve bu Yasal Anlaşma'nın şartlarını kabul etme yetkisine sahip olduğumu teyit ediyorum.</p>
        </div>
    )
}

export default SupplierUserAgreement