import { useState, useEffect } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' 
import { ButtonFilter, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import MakeProposal from 'modules/supplierModule/pages/procurements/MakeProposal'
import Detail from 'modules/supplierModule/pages/procurements/Detail'
import ProposalInfo from 'modules/supplierModule/pages/procurements/ProposalInfo'
import CancelProposal from 'modules/supplierModule/pages/procurements/CancelProposal'
import Table from 'modules/supplierModule/pages/procurements/Table'
import TableFilter from 'modules/supplierModule/pages/procurements/TableFilter'

function Procurements() {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [procurements, setProcurements] = useState([])
    const [procurementForDetail, setProcurementForDetail] = useState(null)
    const [procurementForProposal, setProcurementForProposal] = useState(null)
    const [procurementForProposalInfo, setProcurementForProposalInfo] = useState(null)
    const [procurementForCancelProposal, setProcurementForCancelProposal] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)

    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation();

    useEffect(() => {
        const getProcurements = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/procurements?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setProcurements(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProcurements()
    }, [updateData, filters, search])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-start items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Satın Alma Talepleri</h2>                
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                />

                <Table data={procurements} 
                        actions={{
                            setProcurementForDetail: setProcurementForDetail,
                            setProcurementForProposal: setProcurementForProposal,
                            setProcurementForProposalInfo: setProcurementForProposalInfo,
                            setProcurementForCancelProposal: setProcurementForCancelProposal
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            <Detail procurement={procurementForDetail} />
            <ProposalInfo procurement={procurementForProposalInfo} />
            <MakeProposal update={updateData} setUpdate={setUpdateData} procurement={procurementForProposal} />
            <CancelProposal update={updateData} setUpdate={setUpdateData} procurement={procurementForCancelProposal} />
            {/* <div>
                <pre>
                {JSON.stringify(procurements, null, 2)}
                </pre>
            </div> */}
        </div>
    )
}

export default Procurements