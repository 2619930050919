import { useSearchParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { ButtonFilter, ButtonNew, ButtonExcel } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import { setIsOpenSupplierUserCreate } from 'stores/DrawersSlice'
import Create from 'modules/supplierModule/pages/companies/settings/users/Create'
import Edit from 'modules/supplierModule/pages/companies/settings/users/Edit'
import Delete from 'modules/supplierModule/pages/companies/settings/users/Delete'
import Table from 'modules/supplierModule/pages/companies/settings/users/Table'
import TableFilter from 'modules/supplierModule/pages/companies/settings/users/TableFilter'
import ExportXslx from 'modules/supplierModule/pages/companies/settings/users/ExportXslx'
import Loader from 'elements/Loader'

function SupplierUserSettings(){
    const dispatch = useDispatch()
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [userForRoleChange, setUserForRoleChange] = useState(null)
    const [userForStatusChange, setUserForStatusChange] = useState(null)
    const [loading, setLoading] = useState(false)

    const companyId = useSelector(state => state.company.selectedCompany.id)
        
    const [updateData, setUpdateData] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation();

    useEffect(() => {
        const getUsers = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/users?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setUsers(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getUsers()
    }, [updateData, filters, search])

    useEffect(() => {
        const getRoles = async () => {
            setLoading(true)
            await SendRequest('GET', `api/roles`)
                .then(response => {
                    if(response.status === 200) {
                        setRoles(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getRoles()
    }, [])

    const handleExport = async () => {
        const getUserList = async () => {
            return new Promise(async (resolve) => {
                let filterParam = '&no_pagination=true&' + new URLSearchParams(filters).toString()
                           
                await SendRequest('GET', `api/companies/${companyId}/users?${filterParam}`)
                    .then(response => {
                        if(response.status === 200) {
                           resolve(response.data.data) 
                        } else {
                            resolve(null)
                        }                       
                    })
                    .catch(err => {
                        resolve(null)
                    })
            })
        }
        setLoading(true)
        await getUserList()
                .then(result => {
                    if(result !== null) {
                        ExportXslx(result, 'Kullanıcı Listesi')
                    }
                })
                .finally(() => {
                    setLoading(false)
                })        
    }

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800">Kullanıcılar</h2>
                <ButtonNew label="Yeni Kullanıcı Ekle" onClick={() => dispatch(setIsOpenSupplierUserCreate(true))} >
                </ButtonNew>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonExcel addClass='w-full md:w-auto' onClick={handleExport}>
                </ButtonExcel>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                            roles={roles}
                />

                <Table data={users} 
                        actions={{
                            setUserForRoleChange: setUserForRoleChange,
                            setUserForStatusChange: setUserForStatusChange
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            
            
            
            <Create update={updateData} setUpdate={setUpdateData} roles={roles} />
            <Edit user={userForRoleChange} update={updateData} setUpdate={setUpdateData} roles={roles} />
            <Delete user={userForStatusChange} update={updateData} setUpdate={setUpdateData} />
            {loading && <Loader />}
        </div>
    )
  }
  
  export default SupplierUserSettings