export function useReferenceInput(errors) {
    
    return [        
        {
            key: "referenceInput1",
            type: "text",
            name: "company_name",
            placeholder: "Firma Adı",
            label: "Firma Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.company_name?.message,
            cols: 'col-span-12',
        },
        {
            key: "referenceInput2",
            type: "text",
            name: "business_area",
            placeholder: "Faaliyet Alanı",
            label: "Faaliyet Alanı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.business_area?.message,
            cols: 'col-span-12',
        }, 
        {
            key: "referenceInput3",
            type: "text",
            name: "duration",
            placeholder: "Çalışılan Süre (Yıl)",
            label: "Çalışılan Süre (Yıl) *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,2})?$/,
                            message: "12345,67 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) > 0 || 'Sıfırdan büyük olmalı'
                } 
            },
            error: errors?.duration?.message,
            cols: 'col-span-12',
        },
    ]
}

