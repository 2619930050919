import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementProposalDetail } from 'stores/DrawersSlice' 
import DetailForm from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/DetailForm'

function Detail({proposal, procurement}) {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenProcurementProposalDetail)
    const setIsOpenDetail = () => dispatch(setIsOpenProcurementProposalDetail())
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Teklif Detayı" maxWidth="max-w-4xl">
            <DetailForm proposal={proposal} procurement={procurement} />
        </FormDrawer> 
    )
}

export default Detail