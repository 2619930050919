import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenUserProfileEdit } from 'stores/DrawersSlice' 
import EditForm from 'modules/authModule/pages/profile/EditForm'

function Edit({update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenEdit = useSelector(state => state.drawers.isOpenUserProfileEdit)
    const setIsOpenEdit = () => dispatch(setIsOpenUserProfileEdit())
    
    return(
        <FormDrawer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} title="Kullanıcı Bilgilerini Düzenle">
            <EditForm update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Edit