import { IconContext } from "react-icons"
import { FaRegQuestionCircle, FaRegCheckCircle, FaExclamationTriangle } from 'react-icons/fa'
import { MdDownloading } from 'react-icons/md'

const Table = ({data, dataStatus, errors}) => {

    const StatusCell = ({status}) => {
        if(status === 'pending') {
            return (
                <IconContext.Provider value={{ className: "h-3.5 w-3.5 text-gray-700" }}>
                    <FaRegQuestionCircle />
                </IconContext.Provider>
            )
        }
        if(status === 'processing') {
            return (
                <IconContext.Provider value={{ className: "h-4 w-4 text-amber-700" }}>
                    <MdDownloading />
                </IconContext.Provider>
            )
        }
        if(status === 'success') {
            return (
                <IconContext.Provider value={{ className: "h-3.5 w-3.5 text-emerald-700" }}>
                    <FaRegCheckCircle />
                </IconContext.Provider>
            )
        }
        if(status === 'error') {
            return (
                <IconContext.Provider value={{ className: "h-3.5 w-3.5 text-red-700" }}>
                    <FaExclamationTriangle />
                </IconContext.Provider>
            )
        }
    }

    return (
        <div className='w-full relative'>
            <table className='w-full text-xs'>
                <thead className='text-gray-100'>
                    <tr className='bg-gray-700'>
                        <th className='px-2 py-2'>Ürün/Hizmet Adı</th>
                        <th className='px-2 py-2'>Ürün Tür Kodu</th>
                        <th className='px-2 py-2'>Ürün Kodu</th>
                        <th className='px-2 py-2'>Barkod</th>
                        <th className='px-2 py-2'>Ürün Açıklaması</th>
                        <th className='px-2 py-2'>Birim Kodu</th>
                        <th className='px-2 py-2'>Durum</th>                    
                    </tr>
                </thead>
                <tbody>
                        {(data != null && data?.length > 0) ? data.map((line, index) => {
                            return (
                            <tr key={index} className='border-b border-gray-500 even:bg-gray-200 hover:bg-sky-300'>
                                <td className={`px-2 py-1 text-left ${errors?.products?.[index]?.name && 'text-red-600'}`}>
                                    {(errors?.products?.[index]?.name && (line.A === undefined || (line.A && line.A.toString().trim() == '') )) 
                                    ? '[Boş olamaz]' 
                                    : line.A}
                                </td>
                                <td className={`px-2 py-1 text-left ${errors?.products?.[index]?.product_type_id && 'text-red-600'}`}>
                                    {(errors?.products?.[index]?.product_type_id && (line.B === undefined || (line.B && line.B.toString().trim() == '') )) 
                                    ? '[Boş olamaz]' 
                                    : line.B}
                                </td>
                                <td className={`px-2 py-1 text-left ${errors?.products?.[index]?.product_code && 'text-red-600'}`}>{line.C}</td>
                                <td className={`px-2 py-1 text-left ${errors?.products?.[index]?.barcode && 'text-red-600'}`}>{line.D}</td>
                                <td className={`px-2 py-1 text-left ${errors?.products?.[index]?.description && 'text-red-600'}`}>{line.E}</td>
                                <td className={`px-2 py-1 text-left ${errors?.products?.[index]?.unit_code_id && 'text-red-600'}`}>
                                    {(errors?.products?.[index]?.unit_code_id && (line.F === undefined || (line.F && line.F.toString().trim() == '') )) 
                                    ? '[Boş olamaz]' 
                                    : line.F}
                                </td>                              
                                <td className='px-2 py-1 text-center flex justify-center'>
                                    <StatusCell status={dataStatus[index]} />
                                </td>
                            </tr>)
                        })
                        : (<tr><td>Gösterilecek veri bulunamadı.</td></tr>)
                        }
                </tbody>          
            </table>
        </div>
    )
}

export default Table