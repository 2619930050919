const GreenNormal = ({title, content}) => {
    return (
        <div className="w-full h-24 flex justify-start items-center gap-x-4 border border-emerald-500 bg-white">
            <div className="h-full w-4 bg-emerald-500"></div>
            <div className="flex-1 text-gray-800 mr-1">
                <p className="text-base font-semibold">{title}</p>
                <div className="text-2xl font-semibold">{content}</div>
            </div>
        </div>
    )
}

const GreenSmall = ({title, content}) => {
    return (
        <div className="w-full h-20 flex justify-start items-center gap-x-4 border border-emerald-500 bg-white">
            <div className="h-full w-4 bg-emerald-500"></div>
            <div className="flex-1 text-gray-800 mr-1">
                <p className="text-sm font-semibold">{title}</p>
                <div className="text-xl font-semibold">{content}</div>
            </div>
        </div>
    )
}

const OrangeNormal = ({title, content}) => {
    return (
        <div className="w-full h-24 flex justify-start items-center gap-x-4 border border-amber-500 bg-white">
            <div className="h-full w-4 bg-amber-500"></div>
            <div className="flex-1 text-gray-800 mr-1">
                <p className="text-base font-semibold">{title}</p>
                <div className="text-2xl font-semibold">{content}</div>
            </div>
        </div>
    )
}

const OrangeSmall = ({title, content}) => {
    return (
        <div className="w-full h-20 flex justify-start items-center gap-x-4 border border-amber-500 bg-white">
            <div className="h-full w-4 bg-amber-500"></div>
            <div className="flex-1 text-gray-800 mr-1">
                <p className="text-sm font-semibold">{title}</p>
                <div className="text-xl font-semibold">{content}</div>
            </div>
        </div>
    )
}

const RedNormal = ({title, content}) => {
    return (
        <div className="w-full h-24 flex justify-start items-center gap-x-4 border border-rose-500 bg-white">
            <div className="h-full w-4 bg-rose-500"></div>
            <div className="flex-1 text-gray-800 mr-1">
                <p className="text-base font-semibold">{title}</p>
                <div className="text-2xl font-semibold">{content}</div>
            </div>
        </div>
    )
}

const RedSmall = ({title, content}) => {
    return (
        <div className="w-full h-20 flex justify-start items-center gap-x-4 border border-rose-500 bg-white">
            <div className="h-full w-4 bg-rose-500"></div>
            <div className="flex-1 text-gray-800 mr-1">
                <p className="text-sm font-semibold">{title}</p>
                <div className="text-xl font-semibold">{content}</div>
            </div>
        </div>
    )
}

const BlueNormal = ({title, content}) => {
    return (
        <div className="w-full h-24 flex justify-start items-center gap-x-4 border border-sky-500 bg-white">
            <div className="h-full w-4 bg-sky-500"></div>
            <div className="flex-1 text-gray-800 mr-1">
                <p className="text-base font-semibold">{title}</p>
                <div className="text-2xl font-semibold">{content}</div>
            </div>
        </div>
    )
}

const BlueSmall = ({title, content}) => {
    return (
        <div className="w-full h-20 flex justify-start items-center gap-x-4 border border-sky-500 bg-white">
            <div className="h-full w-4 bg-sky-500"></div>
            <div className="flex-1 text-gray-800 mr-1">
                <p className="text-sm font-semibold">{title}</p>
                <div className="text-xl font-semibold">{content}</div>
            </div>
        </div>
    )
}

export {
    GreenNormal,
    GreenSmall,
    OrangeNormal,
    OrangeSmall,
    RedNormal,
    RedSmall,
    BlueNormal,
    BlueSmall,
}