import { useLocation, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Loader from 'elements/Loader'
import NotFound from 'modules/commonModule/pages/notFound'

const CheckCompanySelected = ({children}) => {
    let location = useLocation()
    const isCompanySelected = useSelector(state => state.company.isSelected)
    const selectedCompany = useSelector(state => state.company.selectedCompany)
    const userType = useSelector(state => state.user.userType)
    if(userType === 'company') {
        if(isCompanySelected === true) {        
            return children
        } else if(isCompanySelected === false) {
            return <Navigate to="/companies" state={{ from: location }} replace />
        } else {
            return <Loader />
        }
    } else if(userType == 'supplier') {
        if(isCompanySelected === true) {   
            if(selectedCompany.status !== 'active') {
                return <Navigate to="/companies" state={{ from: location }} replace />
            }     
            return children
        } else if(isCompanySelected === false) {
            return <Navigate to="/companies" state={{ from: location }} replace />
        } else {
            return <Loader />
        }
    } else {
        return <NotFound />
    }
    
}

export default CheckCompanySelected