import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ButtonEditSmall } from 'elements/buttons/actionButtons'
import { setIsOpenProcurementUserCostType } from 'stores/DrawersSlice'
import UserCostTypes from "./UserCostTypes"

function ProcurementUsers({costTypes, users, update, setUpdate}) {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [userForCostTypes, setUserForCostTypes] = useState(null) 

    const UserCard = ({user}) => {
        return (
        <div className='px-2 py-1 my-1 border border-sky-200 bg-sky-50 min-w-[12rem]'>
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-y-2">
                <div className="flex items-end justify-start gap-x-2">
                    <p className='lg:whitespace-nowrap font-semibold text-sm text-gray-700'>{user.name === '' ? user.email : user.name}</p>
                </div>                
                <div className='w-full flex flex-col lg:flex-row justify-end gap-y-1 lg:gap-x-1'>
                    <ButtonEditSmall label="Gider Türleri" onClick={() => { setUserForCostTypes(user); dispatch(setIsOpenProcurementUserCostType(true))}} />
                </div>
            </div>
        </div>
        )
    }

    const procurementUsers = users.filter(user => {
        return user.roles.filter(role => role.code === "procurements").length > 0
    })

    return (
        <div className="w-full mb-4">
            <div className="h-9 py-1 px-2 mb-4 border border-gray-500 bg-white font-semibold flex justify-between items-center">
                <h2 className="text-base text-gray-800">Teklif Toplama Kullanıcıları</h2>
            </div>
            <div>
                {procurementUsers.map(user  => {
                    return (
                        <div className="mb-2" key={user.id}>
                            <UserCard user={user} />
                        </div>
                    )
                })} 
            </div>
            <UserCostTypes 
                update={update} 
                setUpdate={setUpdate} 
                user={userForCostTypes}
                costTypes={costTypes}
            />
            
        </div>
    )
}

export default ProcurementUsers