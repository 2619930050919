import { useState } from 'react'
import { Outlet } from "react-router-dom"
import Sidebar from 'elements/sidebar'
import SupplierSidebar from 'modules/supplierModule/elements/sidebar'
import Navbar from 'elements/navbar'
import { useDispatch, useSelector } from 'react-redux'
import ErrorBoundary from 'utils/ErrorBoundry'

function Main() {
    
    const [openSidemenu, setOpenSidemenu] = useState(false)
    const userType = useSelector(state => state.user.userType)
    return (
        <div className="text-xs flex flex-col w-full h-full md:h-screen bg-gray-100 z-0">
            <div className="relative flex flex-row">
                {userType === 'company' ? 
                <Sidebar openSidemenu={openSidemenu} setOpenSidemenu={setOpenSidemenu} />
                :
                userType === 'supplier' ? 
                <SupplierSidebar openSidemenu={openSidemenu} setOpenSidemenu={setOpenSidemenu} />
                :
                null                    
                }
                <div className="flex flex-col flex-auto min-w-0 h-screen">
                    <Navbar openSidemenu={openSidemenu} setOpenSidemenu={setOpenSidemenu} />
                    <div className="w-full flex-1 pt-4 pr-4 pl-4 overflow-y-scroll">
                        <ErrorBoundary>
                        <Outlet />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main