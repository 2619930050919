import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenDepartmentProcurementRequestStatusUpdate } from 'stores/DrawersSlice' 
import UpdateStatusForm from 'modules/companyModule/pages/departmentProcurementRequests/requests/UpdateStatusForm'

function UpdateStatus({procurementRequest, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenEdit = useSelector(state => state.drawers.isOpenDepartmentProcurementRequestStatusUpdate)
    const setIsOpenEdit = () => dispatch(setIsOpenDepartmentProcurementRequestStatusUpdate())
    
    return(
        <FormDrawer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} title="Satın Alma Talebi Durumunu Değiştir">
            <UpdateStatusForm procurementRequest={procurementRequest} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default UpdateStatus