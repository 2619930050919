import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierBankAccountDelete } from 'stores/DrawersSlice' 
import DeleteForm from 'modules/supplierModule/pages/companies/bankAccounts/DeleteForm'

function Delete({account, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenSupplierBankAccountDelete)
    const setIsOpen = () => dispatch(setIsOpenSupplierBankAccountDelete())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Banka Bilgilerini Sil">
            <DeleteForm account={account} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Delete