import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementRequestWarehouseUpdateProcurement } from 'stores/DrawersSlice' 
import UpdateProcurementForm from 'modules/companyModule/pages/procurementProcesses/warehouseRequests/UpdateProcurementForm'

function UpdateProcurement({procurementRequest, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenProcurementRequestWarehouseUpdateProcurement)
    const setIsOpen = () => dispatch(setIsOpenProcurementRequestWarehouseUpdateProcurement())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Satın Alma İşlemi Ekle">
            <UpdateProcurementForm procurementRequest={procurementRequest} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default UpdateProcurement