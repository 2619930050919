import { useRef, useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { IconContext } from "react-icons"
import { FaEnvelopeOpenText } from 'react-icons/fa'
import { RiHandCoinFill } from 'react-icons/ri'
import { useRandomColors } from 'data/colors'
import SendRequest from 'utils/SendRequest'
import { default as FNWS } from 'utils/FormatNumberWithSeperator'

function ProposalSummary({supplier}) {
    ChartJS.register(ArcElement, Tooltip, Legend)

    const chartRef = useRef(null)
    const randomColors = useRandomColors()
    const companyId = useSelector((state) => state.company.selectedCompany.id)

    const initialData = {
        "all_proposals": [{
            "currency": {
                "id": 1,
                "code": "TRY",
                "description": "Türk Lirası"
            },
            "total_proposal":0,
            "total_price":0
        }],
        "accepted_proposals":[{
            "currency": {
                "id": 1,
                "code": "TRY",
                "description": "Türk Lirası"
            },
            "total_proposal":0,
            "total_price":0
        }]}
    const [proposalData, setProposalData] = useState(initialData)
    const [loading, setLoading] = useState(false)

    const options = {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false
          }
        },
        responsive: true,        
    }

    const data = {
        labels: ['Kabul Edilmeyen', 'Kabul Edilen'],
        datasets: [
            {
            label: 'Teklif Sayısı',
            data: [proposalData.all_proposals.reduce((acc, obj) => {return acc + obj.total_proposal}, 0) - proposalData.accepted_proposals.reduce((acc, obj) => {return acc + obj.total_proposal}, 0), proposalData.accepted_proposals.reduce((acc, obj) => {return acc + obj.total_proposal}, 0)],
            backgroundColor: randomColors.slice(0,2),
            borderWidth: 1,
            },
        ],
    }
    
    const plugins = [{
        id: 'nodata',
        afterDraw: function(chart, a, b) {
            if (chart.data.datasets[0].data.every(item => item === 0)) {
                let width = chart.chartArea.right
                let height = chart.height
                let ctx = chart.ctx

                chart.clear()
      
                ctx.save()
                ctx.textBaseline = 'middle'
                let fontSize = (height / 114).toFixed(2)
                ctx.font = fontSize + "em sans-serif"
                let text = 'Verilen teklif yok'
                let textX = Math.round((width - ctx.measureText(text).width) / 2)
                let textY = height / 2
                ctx.fillText(text, textX, textY)
                ctx.restore()
            }
        }
    }]

    useEffect(() => {
        const getProposalData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/suppliers/${supplier.id}/proposal_summary`)
                .then(res => {
                    if(res.status === 200) {
                        setProposalData(res.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if(supplier) getProposalData()                     
    }, [supplier])
    
    return (
        <div className="flex-grow flex flex-col lg:flex-row gap-y-4 lg:gap-x-4">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-y-4 lg:gap-x-4 bg-white px-4 py-2 border border-gray-400">
                <div className="w-[10rem] h-[10rem] ">
                    <Pie data={data} options={options} plugins={plugins} ref={chartRef} />
                </div>
                <div className="">
                    <p>
                        <span className="inline-block w-[0.75rem] h-[0.75rem] rounded-full" style={{backgroundColor: randomColors[0]}}></span>
                        <span className="text-base text-gray-700 font-bold"> {proposalData.all_proposals.reduce((acc, obj) => {return acc + obj.total_proposal}, 0)}</span> 
                        <span className="text-sm text-gray-700"> Verilen Teklif</span>
                    </p>
                    <p>
                        <span className="inline-block w-[0.75rem] h-[0.75rem] rounded-full" style={{backgroundColor: randomColors[1]}}></span>
                        <span className="text-base text-gray-700 font-bold"> {proposalData.accepted_proposals.reduce((acc, obj) => {return acc + obj.total_proposal}, 0)}</span> 
                        <span className="text-sm text-gray-700"> Kabul Edilen</span>
                    </p>
                </div>
            </div>
            <div className="flex-grow bg-white px-4 py-2 border border-gray-400">
                <p className="text-base text-gray-700 pb-1 border-b border-gray-700 font-semibold">Kabul Edilen Teklifler</p>
                <div className="py-4 flex flex-col divide-y divide-gray-200">
                    <IconContext.Provider value={{ className: "h-8 w-8 text-gray-500 mr-3" }}>
                        <p className="flex items-center justify-start gap-x-2 pb-2">
                            <FaEnvelopeOpenText />
                            <span className="text-3xl font-semibold">{FNWS(proposalData.accepted_proposals.reduce((acc, obj) => {return acc + obj.total_proposal}, 0), 0)}</span> 
                            <span className="text-base pt-2">Adet</span>
                        </p>
                        <p className="flex items-center justify-start gap-x-2 pt-2">
                            <RiHandCoinFill />
                            {proposalData.accepted_proposals.map((ap, index) => {
                                return (
                                    <Fragment key={index}>
                                        <span className="text-3xl font-semibold">
                                            {FNWS(ap.total_price, 0)}
                                        </span> 
                                        <span className="text-base pt-2">{ap.currency.code}</span>
                                        {index < proposalData.accepted_proposals.length -1 ?
                                        <span className="text-3xl">/</span>
                                        : null}
                                    </Fragment>
                                )
                            })}
                            
                        </p>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    )
}

export default ProposalSummary
