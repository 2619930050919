import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierApprove } from 'stores/DrawersSlice' 
import ApproveForm from 'modules/companyModule/pages/suppliers/ApproveForm'

function Approve({supplier, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenApprove = useSelector(state => state.drawers.isOpenSupplierApprove)
    const setIsOpenApprove = () => dispatch(setIsOpenSupplierApprove())
    
    return(
        <FormDrawer isOpen={isOpenApprove} setIsOpen={setIsOpenApprove} title="Tedarikçiyi Onayla">
            <ApproveForm supplier={supplier} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Approve