import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import {ButtonSave, ButtonClose, ButtonNewSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import DropdownTree from 'elements/inputs/DropdownTree'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenCompanyUserDepartmentEdit } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useDepartmentEditInput } from 'modules/companyModule/pages/companies/settings/users/InputArray'

const EditDepartmentForm = ({user, update, setUpdate, departments, positions}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenEdit = useSelector((state) => state.drawers.isOpenCompanyUserDepartmentEdit)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [categorySelectArray, setCategorySelectArray] = useState([])

    const departmentDefaults = {department_id: null, position_id: null}
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {departments: [departmentDefaults]}
    })

    const { 
        fields: fields, 
        append: append, 
        remove: remove, 
        } = useFieldArray({
        control,
        name: "departments"
    })

    const watchDepartments = watch("departments");
    const controlledDepartmentFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchDepartments[index]
        }
    })

    useEffect(() => {
        if(user !== null && Object.keys(user).length > 0) {
            if(user.departments && Array.isArray(user.departments) && user.departments.length > 0) {
                const depItems = user.departments.map(dep => {
                    return {department_id: dep.department.id, position_id: dep.position.id}
                })
                reset({departments: depItems})
            } else {
                reset({departments: [departmentDefaults]})
            }
        }
    }, [user])

    const departmentInputs = useDepartmentEditInput(positions, departments)

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/users/${user.id}/departments`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    useEffect(() => {
        if(!isOpenEdit) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenEdit])

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenCompanyUserDepartmentEdit(false))
    }
    
    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <div>
                <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                    <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>#</span>
                    </div>
                    <div className='col-span-6 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Departman</span>
                    </div>
                    <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Pozisyon</span>
                    </div>
                    <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'></span>
                    </div>
                </div>
                {controlledDepartmentFields.map((field, index) => {
                        return (
                            <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${field.id}`}>
                                <div className='col-span-1 mt-2'>
                                    <div>
                                        {index+1}.
                                    </div>
                                </div>
                                <div className={departmentInputs[0].cols}>
                                    <DropdownTree       
                                        {...departmentInputs[0]}
                                        control={control}
                                        name={`departments.${index}.${departmentInputs[0].nameLast}`} 
                                        error = {errors?.departments?.[index]?.[departmentInputs[0].nameLast]?.message}
                                        actions = {(e) => null}
                                    />
                                </div>
                                <div className={departmentInputs[1].cols}>
                                    <SelectSmall 
                                        {...departmentInputs[1]}
                                        register={register} 
                                        name={`departments.${index}.${departmentInputs[1].nameLast}`} 
                                        error={errors?.departments?.[index]?.[departmentInputs[1].nameLast]?.message} 
                                    />
                                    
                                </div>
                                <div className='col-span-1 mt-0.5'>
                                    <div>
                                        {
                                        index === 0 
                                        ? <ButtonNewSmall label={null} addClass='w-full' type="button" onClick={() => append(departmentDefaults)} ></ButtonNewSmall>
                                        : <ButtonDeleteSmall label={null} addClass='w-full' type="button" onClick={() => remove(index)} ></ButtonDeleteSmall>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div> 
        </form> 
         
        {loading && <Loader />}
        </>
    )
}

export default EditDepartmentForm