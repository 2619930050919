import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import { DateTime } from 'luxon'
import { ButtonOk, ButtonSave} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FormatNumber from 'utils/FormatNumber'
import { setIsOpenWarehouseProductInitialStock } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useInitialStockInput } from 'modules/companyModule/pages/warehouses/stocks/InputArray'

const InitialStockForm = ({product, update, setUpdate, selectedWarehouse}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)    
    const isOpenForm = useSelector(state => state.drawers.isOpenWarehouseProductInitialStock)
    
    const [initialStocks, setInitialStocks] = useState(null)
    const [currentInitialStock, setCurrentInitialStock] = useState(null)
    const [unitCodes, setUnitCodes] = useState([])
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const formDefaults = {
        stock_date: DateTime.local().toFormat("yyyy-LL-dd"),
        description: null,
        quantity: null,
        unit_code_id: null,
    }
    const { register, handleSubmit, formState: { errors }, reset, watch, control, trigger, setValue, getValues } = useForm({
        defaultValues: formDefaults
    })

    const inputs = useInitialStockInput(errors, unitCodes)

    useEffect(() => {
        setError(null)
        setStatus(false)
        if(product !==null && Object.keys(product).length > 0) {
            const getInitialStocks = async () => {
                setLoading(true)
                await SendRequest('GET', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/products/${product.id}/initial_stocks`)
                    .then(response => {
                        if(response.status === 200) {
                            setInitialStocks(response.data.data)                            
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
            const getUnitCodes = () => {
                let unitCodeArray = [{
                    key: product.unit.id,
                    value: `${product.unit.description} (${product.unit.code})`
                }]
                
                setUnitCodes(unitCodeArray)
            }
            getInitialStocks()
            getUnitCodes()            
        } else {
            setInitialStocks(null)
            setCurrentInitialStock(null)
        }
    }, [product])

    useEffect(() => {
        if(unitCodes && Array.isArray(unitCodes) && unitCodes.length > 0) {
            setValue('unit_code_id', product.unit.id.toString())
        }        
    }, [unitCodes])

    useEffect(() => {
        if(initialStocks !== null && Array.isArray(initialStocks) && initialStocks.length > 0) {
            let current = initialStocks.filter(d => d.is_revised === false)
            if(current.length > 0) {
                setCurrentInitialStock(current[0])
            } else {
                setCurrentInitialStock(null)
            }
        } else {
            setCurrentInitialStock(null)
        }
    }, [initialStocks])

    useEffect(() => {
        if(!isOpenForm) {
            reset()
            setError(null)
            setStatus(false)
            setInitialStocks(null)
            setCurrentInitialStock(null)
        }        
    }, [isOpenForm])

    const handleForm = async (data) => {
        // console.log(data)
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/products/${product.id}/initial_stocks`, data)
                .then(response => {
                    if(response.status === 201) {
                        reset({...formDefaults, unit_code_id: product.unit.id})
                        setStatus(true)
                        setError(null)
                        setInitialStocks(response.data.data)
                        setUpdate(prev => !prev)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenWarehouseProductInitialStock())
    }

    const handleError = (error, e) => {
        
    }

    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy')
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />            
            </>
            : null }
            {(product && Object.keys(product).length > 0) ?
                
                <form onSubmit={handleSubmit(handleForm, handleError)}>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Başlangıç Stoğu Bilgileri</p>
                    <table className="w-full">
                        <tbody>
                            <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">İşlem Yapılan Depo:</td>
                                <td className="px-2 py-3">{selectedWarehouse.warehouse_name} ({selectedWarehouse.warehouse_code})</td>
                            </tr>
                            {currentInitialStock != null ? 
                            <>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Başlangıç Stok Miktarı:</td>
                                    <td className="px-2 py-3">{currentInitialStock.quantity} {currentInitialStock.unit_code.description}</td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Açıklama:</td>
                                    <td className="px-2 py-3">{currentInitialStock.description}</td>
                                </tr>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Tarih: </td>
                                    <td className="px-2 py-3">{formatDate(currentInitialStock.stock_date)}</td>
                                </tr>
                            </>
                            : 
                            <>
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">Başlangıç Stoğu:</td>
                                    <td className="px-2 py-3">Tanımlanmamış</td>
                                </tr>
                            </>
                            }
                        </tbody>
                    </table>     
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Mevcut Başlangıç Stoğunu Güncelle</p>
                    <div className="grid grid-cols-12 gap-2">
                    {inputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                {input.type === 'select' ? 
                                <SelectSmall register={register} {...input} rows="5" /> 
                                : <InputSmall register={register} {...input} /> 
                                }
                            </div>
                            )
                    })}
                    </div>               
                    <div className="w-full flex justify-end mt-5">
                        <ButtonSave type="submit">
                        </ButtonSave>
                    </div>          
                </form> 
                : null }
        {loading && <Loader />}
        </>
    )
}

export default InitialStockForm