import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import SendRequest from 'utils/SendRequest'
import {FileSmall} from 'elements/inputs'
import { ButtonUpload } from 'elements/buttons/actionButtons'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { useDocumentInputs } from 'modules/supplierModule/pages/companies/documents/InputArray'

function UploadForm({document, label, setUpdate}) {
    const companyId = useSelector((state) => state.company.selectedCompany?.id) 

    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [docFileAttributes, setDocFileAttributes] = useState({filename: null, size: null, type: null})

    const formDefaults = {        
        document: null,
    }
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: formDefaults
    }) 

    const techSpecInputs = useDocumentInputs(errors, docFileAttributes, setDocFileAttributes)

    const handleForm = async (data) => {
        // console.log(data)
        const formData = new FormData()
        Object.keys(data).forEach((key) => {
            if(data[key] instanceof FileList) {
                formData.append(key, data[key][0])
            } else if (Array.isArray(data[key])) {
                data[key].forEach((item, index) => {
                    Object.keys(item).forEach((itemkey) => {
                        formData.append(`${key}[${index}][${itemkey}]`, item[itemkey])
                    })
                    
                })
            } else {
              formData.append(key, data[key])
            }
        })
        // console.log(formData)
        setLoading(true)
        await SendRequest('POST', `api/suppliers/${companyId}/documents/${document}`, formData)
                .then(res => {
                    if(res.status === 204) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setDocFileAttributes({filename: null, size: null, type: null})
                        setUpdate(prev => !prev)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    return (
        <>
            {error && <ServerError errorData={error} />}
            {status && <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
            <form onSubmit={handleSubmit(handleForm, handleError)}>
                <div className="flex items-center justify-end gap-x-2">
                    <FileSmall register={register} {...techSpecInputs[0]} /> 
                    <ButtonUpload type="submit">
                    </ButtonUpload>
                </div>
                
            </form>
        </>
    )
}

export default UploadForm