import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementApprovalScenario } from 'stores/DrawersSlice' 
import CostTypeScenarioForm from 'modules/companyModule/pages/companies/settings/procurements/CostTypeScenarioForm'

function CostTypeScenario({update, setUpdate, costType, users}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenProcurementApprovalScenario)
    const setIsOpenCreate = () => dispatch(setIsOpenProcurementApprovalScenario())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Teklif Toplama Onay Sürecini Düzenle" maxWidth='max-w-2xl'>
            <CostTypeScenarioForm 
                update={update} 
                setUpdate={setUpdate} 
                costType={costType}
                users={users}
            />
        </FormDrawer> 
    )
}

export default CostTypeScenario