import { IconContext } from "react-icons"

function Card({children, title = '', icon = null, footer = null, titleButton = null}) {
    return (
        <div className="w-full h-full flex flex-col border border-gray-600">
            <div className="w-full flex-none flex items-center justify-start gap-2 bg-gray-600 px-4 py-2 text-white font-semibold">
                <IconContext.Provider value={{ className: "h-4 w-4 flex-none" }}>
                    {icon}
                </IconContext.Provider>
                <span className="flex-grow flex justify-between items-center">
                    <span>{title}</span>
                    {titleButton}
                </span>
            </div>
            <div className="p-4 flex-grow bg-white overflow-y-auto">
                {children}
            </div>
            {footer ? 
            <div className='flex-none flex items-start justify-end bg-slate-200 border-t border-slate-800 p-4'>
                {footer}
            </div>
            : null }
        </div>
    )
}

export default Card