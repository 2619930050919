export function useProjectInput(errors) {
    
    return [        
        {
            key: "projectInput1",
            type: "text",
            name: "project_name",
            placeholder: "Proje/Firma Adı",
            label: "Proje/Firma Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.project_name?.message,
            cols: 'col-span-12',
        },
        {
            key: "projectInput2",
            type: "textarea",
            name: "description",
            placeholder: "Proje Açıklaması",
            label: "Proje Açıklaması *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.description?.message,
            cols: 'col-span-12',
        }, 
        {
            key: "projectInput3",
            type: "text",
            name: "year",
            placeholder: "Projeye Başlanan Yıl",
            label: "Projeye Başlanan Yıl *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d{2}$/,
                            message: "1900-2099 arasında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) > 1900 || 'Sıfırdan büyük olmalı'
                } 
            },
            error: errors?.year?.message,
            cols: 'col-span-12',
        },
    ]
}

