import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/products/CreateForm'

function Create({update, setUpdate, costTypes, taxonomies}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenProductCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenProductCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Ürün/Hizmet Ekle">
            <CreateForm update={update} setUpdate={setUpdate} costTypes={costTypes} taxonomies={taxonomies} />
        </FormDrawer> 
    )
}

export default Create