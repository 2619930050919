import { useSelector } from 'react-redux'
import { UserRoles } from 'data'

export function useUserInput(errors, getValues) {

    const roles = UserRoles()

    return [
        {
            key: "userInput1",
            type: "text",
            name: "name",
            placeholder: "İsim-Soyisim",
            label: "İsim-Soyisim *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.name?.message,
            cols: 'col-span-12', 
        },
        {
            key: "userInput2",
            type: "phone",
            name: "phone",
            placeholder: "Telefon",
            label: "Telefon *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},
                pattern: {value: /^\+90 \((?!0|1)\d{3}\) \d{3}-\d{2}-\d{2}$/,
                            message: "Geçerli bir telefon numarası olmalı"}
            },
            error: errors?.phone?.message,
            cols: 'col-span-12',
        },
        {
            key: "userInput3",
            type: "text",
            name: "email",
            placeholder: "E-Posta",
            label: "E-Posta *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message,
            cols: 'col-span-12', 
        },
        {
            key: "passwordInput1",
            type: "password",
            name: "password",
            placeholder: "Şifre",
            label: "Şifre *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},                
            },
            error: errors?.password?.message,
            cols: 'col-span-12',
        },
        {
            key: "passwordInput2",
            type: "password",
            name: "password_confirmation",
            placeholder: "Şifre Tekrar",
            label: "Şifre Tekrar*",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},   
                validate: {
                    confirmation: v => v === getValues("password") || 'Girmiş olduğunuz şifreler eşleşmiyor'
                }              
            },
            error: errors?.password_confirmation?.message,
            cols: 'col-span-12',
        },    
    ]
}

export function useUserEditInput(errors) {

    const roles = UserRoles()

    return [
        {
            key: "userInput1",
            type: "select",
            name: "role",
            placeholder: "Kullanıcı Türü",
            label: "Kullanıcı Türü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.role?.message,
            data: roles,
            cols: 'col-span-12', 
        }  
    ]
}