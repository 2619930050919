import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import {ButtonClose, ButtonNext, ButtonPrevious} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall, FileSmall, CheckboxSmall} from 'elements/inputs'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import { usePaymentTermInput, useDeliveryTermInput } from 'modules/companyModule/pages/procurementProcesses/procurements/InputArray'

function PaymentAndDelivery({currentStep, setCurrentStep, procurementData, setProcurementData}) {
    const dispatch = useDispatch()
    const isOpen = useSelector((state) => state.drawers.isOpenProcurementCreate) 
    const selectedCompany = useSelector((state) => state.company.selectedCompany)    
    
    const formDefaults = {
        currency_id: procurementData?.currency_id || 1,
        payment_type: procurementData?.payment_type ||null,    
        deferral_days: procurementData?.deferral_days || "1",    
        is_currency_flexible: procurementData?.is_currency_flexible || false,    
        is_payment_type_flexible: procurementData?.is_payment_type_flexible || false,    
        delivery_type: procurementData?.delivery_type || null,    
        delivery_date: procurementData?.delivery_date || DateTime.local().plus({ days: 7 }).toFormat("yyyy-LL-dd'T'T"),
        delivery_address: procurementData?.delivery_address || `${selectedCompany.address} ${selectedCompany.district.name}/${selectedCompany.city.name}`,          
    }
    
    
    const { register, handleSubmit, formState: { errors }, control, getValues, watch } = useForm({
        defaultValues: formDefaults
    })

    const watchPaymentType = watch("payment_type")

    const paymentTermInputs = usePaymentTermInput(errors, getValues)
    const deliveryTermInputs = useDeliveryTermInput(errors)

    const close = () => {
        dispatch(setIsOpenProcurementCreate())
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const handleForm = async (data) => {
        setProcurementData(prev => {
            return {...prev, ...data}
        })
        setCurrentStep(prev => prev+1)
    }    

    return (  
        <>      
        <form onSubmit={handleSubmit(handleForm, handleError)}>            
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-8rem)]">
                <div className="w-full overflow-y-auto px-0.5">
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ödeme Bilgileri</p>
                    <div className="lg:grid lg:grid-cols-12 gap-2">
                    {paymentTermInputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                {input.type === 'select' ? 
                                    <SelectSmall register={register} {...input} /> :
                                    null
                                }
                                {input.type === 'text' ? 
                                    input.key === 'paymentTermInput3' && watchPaymentType === 'deferred' ?
                                    <InputSmall register={register} {...input} /> : null
                                    : null
                                }
                                {input.type === 'checkbox' ? 
                                    <CheckboxSmall register={register} {...input} /> :
                                    null
                                }
                            </div>
                            )
                    })}
                    </div> 
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Sevkiyat Bilgileri</p>
                    <div className="lg:grid lg:grid-cols-12 gap-2">
                    {deliveryTermInputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                {input.type === 'select' ? 
                                    <SelectSmall register={register} {...input} /> :
                                    null
                                }
                                {input.type === 'datetime-local' ? 
                                    <InputSmall register={register} {...input} />
                                    : null
                                }
                                {input.type === 'textarea' ? 
                                    <TextareaSmall register={register} {...input} /> :
                                    null
                                }
                            </div>
                            )
                    })}
                    </div>
                </div>
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonClose onClick={() => close()}>
                    </ButtonClose>
                    <ButtonPrevious onClick={() => setCurrentStep(prev => prev-1)}>
                    </ButtonPrevious>
                    <ButtonNext type='submit'>
                    </ButtonNext>                  
                </div>         
            </div>
        </form> 
        </>
    )

}

export default PaymentAndDelivery