import TableActions from 'modules/companyModule/pages/procurementProcesses/approvals/TableActions'
import TableLayout from 'elements/tables'
import { useSelector } from 'react-redux'
import { TableStatus } from 'modules/companyModule/pages/procurementProcesses/commonParts/Status'
import LinesCell from 'modules/companyModule/pages/procurementProcesses/commonParts/LinesCell'

const Table = ({data, actions, progressPending, pagination, pageMeta}) => {

    const userId = useSelector(state => state.user.user.id)

    const ApprovalCell = ({approvals}) => {
        let myApproval = approvals.filter(approval => {
            return approval.user.id == userId
        })
        if(myApproval.length > 0) {
            if(myApproval[0].is_accepted) return <span className='text-emerald-500 font-semibold'>Kabul Edildi</span>
            if(myApproval[0].is_rejected) return <span className='text-red-500 font-semibold'>Reddedildi</span>
            return <span className='text-orange-500 font-semibold'>İşlem Yapılmadı</span>
        }
    }
    
    const columns = [
        {
            name: 'Talep No',
            selector: (row) => {return row.procurement_no},
            headerMobileHidden: false,
        },
        {
            name: 'Başlık',
            selector: (row) => {return row.title},
            headerMobileHidden: false,
        },
        {
            name: 'Sektör',
            selector: (row) => {return row.business_area.name},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return <TableStatus status={row.status} approvals={row.approvals} />},
            headerMobileHidden: false,
        },
        {
            name: 'Onay Durumum',
            selector: (row) => {return <ApprovalCell approvals={row.approvals} />},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Sayısı',
            selector: (row) => {return <LinesCell lines={row.lines} />},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return `${row.title} (${row.procurement_no})`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
