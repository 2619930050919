import { FaBoxes } from 'react-icons/fa'
import MinStocks from 'modules/companyModule/pages/home/warehouses/stocks/MinStocks'
import MaxStocks from 'modules/companyModule/pages/home/warehouses/stocks/MaxStocks'
import LatestTransfers from 'modules/companyModule/pages/home/warehouses/stocks/LatestTransfers'
import Header from 'modules/companyModule/pages/home/Header'

function Stocks({selectedWarehouse, selectOptions}) {
    return (
        <div className="w-full">
            <Header title="Depo - Stok" logo={<FaBoxes />} selectOptions={selectOptions} />
            {selectOptions.value !== '' ? 
            <div className="grid grid-cols-1 lg:grid-cols-6 gap-y-4 lg:gap-x-4 mb-4">
                <div className="col-span-1 lg:col-span-3">
                    <MinStocks selectedWarehouse={selectedWarehouse} />
                </div>
                <div className="col-span-1 lg:col-span-3">
                    <MaxStocks selectedWarehouse={selectedWarehouse} />
                </div>
            </div> 
            : 
            <div className="">
                <p className="text-lg font-semibold text-red-600">Lütfen verileri görebilmek için bir depo seçin</p>
            </div>
            }       
        </div>
        
    )
}

export default Stocks