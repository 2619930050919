import FormatNumber from 'utils/FormatNumber'

function ReferenceInfo({reference}) {

    return (
        <div className="">
                {(reference !==null && reference !== undefined && Object.keys(reference).length > 0) &&
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Firma Adı:</td>
                            <td className="px-2 py-3">{reference.company_name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Faaliyet Alanı:</td>
                            <td className="px-2 py-3">{reference.business_area}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Çalışılan Süre (Yıl):</td>
                            <td className="px-2 py-3">{FormatNumber(reference.duration)}</td>
                        </tr>
                    </tbody>
                </table>}
            </div>  
    )
}

export default ReferenceInfo