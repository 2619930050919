import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {PhoneSmall} from 'elements/inputs/Phone'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenSupplierContactEdit } from 'stores/DrawersSlice' 
import { setCompanies, setSelectedCompany, setIsSelected } from 'stores/CompanySlice'
import Loader from 'elements/Loader'
import { useContactTypes } from 'data'
import { useContactInput } from 'modules/supplierModule/pages/companies/contacts/InputArray'

const EditForm = ({contact, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany?.id)
    const isOpenCreate = useSelector(state => state.drawers.isOpenSupplierContactEdit)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            type: null,
            name: null,
            position: null,
            mobile_phone: null,
            office_phone: null,
            email: null,
        }
    })

    const types = useContactTypes()

    const inputs = useContactInput(errors)

    useEffect(() => {
        if(contact !== null && Object.keys(contact).length > 0) {
            setValue('type', contact.type)          
            setValue('name', contact.name)          
            setValue('position', contact.position)          
            setValue('mobile_phone', contact.mobile_phone)          
            setValue('office_phone', contact.office_phone)          
            setValue('email', contact.email)          
            setError(null)
            setStatus(false)
        } else {
            reset()
        }
    }, [contact, isOpenCreate])

    useEffect(() => {
        if(!isOpenCreate) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenCreate])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/suppliers/${companyId}/contacts/${contact.id}`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenSupplierContactEdit(false))
    }   

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <div className="grid grid-cols-12 gap-2">
            {inputs.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        {input.type == 'select' ? 
                                <SelectSmall register={register} {...input} /> :
                                null
                            }
                            {input.type == 'text' ? 
                                <InputSmall register={register} {...input} /> :
                                null
                            }
                            {input.type == 'phone' ? 
                                <PhoneSmall register={register} {...input} /> :
                                null
                            }
                    </div>
                    )
            })}                
            </div>
            <div className="w-full flex justify-end gap-x-1 mt-5">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose type="button" onClick={() => close()}>
                </ButtonClose>
            </div>                
        </form> 
        {loading && <Loader />}
        </>
    )
}

export default EditForm