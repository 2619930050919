import { useDispatch, useSelector } from 'react-redux'
import { ImHome } from 'react-icons/im'
import { FaEnvelopeOpenText, FaThList, FaCog } from 'react-icons/fa'
import Link from 'elements/sidebar/Link'
import LinkWithSubmenu from 'elements/sidebar/LinkWithSubmenu'

function Links() {

    const authenticated = useSelector(state => state.user.authenticated)
    const userRoles = useSelector(state => state.user.user.roles)
    const auth = {
        procurement: userRoles.find(roles => roles.code === 'supplier_procurements') !== undefined ? true : false,
        make_proposal: userRoles.find(roles => roles.code === 'supplier_make_proposals') !== undefined ? true : false,
        proposal: userRoles.find(roles => roles.code === 'supplier_proposals') !== undefined ? true : false,
        settings_user: userRoles.find(roles => roles.code === 'supplier_settings_users') !== undefined ? true : false,
    }

    let companySettingLinks = []

    if(auth.settings_user) {
        companySettingLinks.push({   
            id: 1,
            title: 'Kullanıcılar',
            path: '/supplier/settings/users'
        })
    }
    
    return (
        <ul className="block overflow-y-auto flex-1 pb-3 mt-3">
            <Link title="Ana Sayfa" icon={<ImHome />} path="/" />
            {auth.procurement ? 
            <Link title="Satın Alma İlanları" icon={<FaThList />} path="/supplier/procurements" />
            : null}
            {(auth.procurement && auth.proposal) ? 
            <Link title="Verilen Teklifler" icon={<FaEnvelopeOpenText />} path="/supplier/proposals" />
            : null}
            {companySettingLinks.length > 0 ? 
            <LinkWithSubmenu title="Sistem Ayarları" icon={<FaCog />} items={companySettingLinks} />        
            : null
            }
        </ul>
    )
}

export default Links