import { Link } from 'react-router-dom'

function NavbarLink({path, title, icon = null, borderTop = false}) {
    return (
        <Link to={path} className={`flex items-center px-5 py-3 leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out ${borderTop ? 'border-t border-1 border-gray-100' : undefined}`}>
            {icon !== null && icon}
            <span className="ml-3 whitespace-nowrap">{title}</span>
        </Link>
    )
    
}

export default NavbarLink