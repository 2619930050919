import Login from 'modules/authModule/pages/login'
import RegisterSupplier from 'modules/authModule/pages/register/supplier'
import ForgotPassword from 'modules/authModule/pages/forgotPassword'
import VerifyEmail from 'modules/authModule/pages/verifyEmail'
import ResetPassword from 'modules/authModule/pages/resetPassword'
import RedirectIfAuth from 'utils/RedirectIfAuth'
import CheckAuth from 'utils/CheckAuth'
import SocialLogin from 'modules/authModule/pages/social'

function AuthRoutes()
{
    return [
        {
            path: '/login',
            element: <RedirectIfAuth><Login /></RedirectIfAuth>
        },
        {
            path: '/register/supplier',
            element: <RedirectIfAuth><RegisterSupplier /></RedirectIfAuth>
        },
        {
            path: '/forgot-password',
            element: <RedirectIfAuth><ForgotPassword /></RedirectIfAuth>
        },
        {
            path: '/email-verification-notification',
            element: <CheckAuth><VerifyEmail /></CheckAuth>
        },
        {
            path: '/password-reset/:token',
            element: <RedirectIfAuth><ResetPassword /></RedirectIfAuth>
        },
        {
            path: '/login/:provider/callback',
            element: <RedirectIfAuth><SocialLogin /></RedirectIfAuth>
        },
      ]
}

export default AuthRoutes