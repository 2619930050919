import { useSelector } from 'react-redux'

export function useDescriptionInput(errors) {
    return [
        {
            key: "descriptionInput1",
            type: "textarea",
            name: "description",
            placeholder: "Genel Açıklamalar",
            label: "Genel Açıklamalar",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },
        {
            key: "descriptionInput2",
            type: "text",
            name: "confirmation_code",
            placeholder: "Kullanıcı Onay Kodu",
            label: "Kullanıcı Onay Kodu",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 10, 
                    message: "En fazla 10 karakter olmalı"}, 
            },
            error: errors?.confirmation_code?.message,
            cols: 'col-span-12', 
        },
    ]
}

export function useLineInput(errors) {
    const unitCodes = useSelector(state => state.sharedData.unitCodes)

    return [
        {
            key: "lineInput1",
            type: "hidden",
            nameLast: "product_demand_line_id",
            placeholder: "",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            cols: 'col-span-2', 
        },
        {
            key: "lineInput2",
            type: "text",
            nameLast: "quantity",
            placeholder: "Miktar",
            label: null,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) >= 0 || 'Sıfırdan büyük olmalı'
                }                 
            },
            cols: 'col-span-3', 
        },
        {
            key: "lineInput3",
            type: "text",
            nameLast: "description_text",
            placeholder: "Ürün Açıklaması",
            label: null,
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            cols: 'col-span-3', 
        },
        {
            key: "lineInput4",
            type: "select",
            nameLast: "unit_code_id",
            placeholder: "Birim",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: [],
            cols: 'col-span-1', 
        },
        
    ]
}

