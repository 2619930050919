const ButtonAmber = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-2 py-2 rounded bg-amber-600 text-white hover:bg-amber-700 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

const ButtonBlue = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-6 py-2 bg-blue-600 text-white hover:bg-blue-700 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

const ButtonRed = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-2 py-2 rounded bg-red-600 text-white hover:bg-red-700 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

const ButtonRedSmall = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-2 py-1.5 rounded bg-red-600 text-white hover:bg-red-700 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

const ButtonSky = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-2 py-2 rounded bg-sky-600 text-white hover:bg-sky-700 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

const ButtonSkySmall = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-2 py-1.5 rounded bg-sky-600 text-white hover:bg-sky-700 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

const ButtonTeal = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-2 py-2 rounded bg-teal-600 text-white hover:bg-teal-700 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

const ButtonTealSmall = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-2 py-1.5 rounded bg-teal-600 text-white hover:bg-teal-700 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

const ButtonWhite = ({children, type = 'button', label='', addClass='', ...props}) => {
    return (
        <button type={type} className={`flex items-center justify-center gap-2 text-center px-6 py-2 bg-white text-gray-600 hover:bg-gray-300 border border-gray-400 ${addClass}`} {...props}>
            {children}
        </button>
    )
}

export {
    ButtonAmber,
    ButtonBlue,
    ButtonRed,
    ButtonRedSmall,
    ButtonSky,
    ButtonSkySmall,
    ButtonTeal,
    ButtonTealSmall,
    ButtonWhite
}