import CustomPopover from 'elements/popovers'
import { ButtonShowDetail } from 'elements/buttons/actionButtons'

function LinesCell({lines}) {
    const content = (
        <div className='text-xs'>
            <ol className="list-decimal list-inside">
                {lines.map(line => {
                    return (
                        <li key={line.id} className='whitespace-nowrap my-1'>
                            <span className='font-semibold'>
                                {line.product.name}: 
                            </span>
                            {line.quantity} {line.unit_code.description}
                        </li>
                    )
                    
                })}
            </ol>
        </div>
    )

    return (
        <CustomPopover content={content} >
            <ButtonShowDetail label={lines.length} />
        </CustomPopover>
    ) 
}

export default LinesCell