import { useEffect, useState } from 'react'
import { FaUsers } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { IconContext } from "react-icons"
import { TbRefresh } from 'react-icons/tb'
import SendRequest from 'utils/SendRequest'
import { ButtonList } from 'elements/buttons/actionButtons'
import { TablePlaceholder } from 'elements/loaders'
import Card from 'elements/cards'

function Companies() {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        const getCompanies = async () => {
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/companies`)
                .then(response => {
                    if(response.status === 200) {
                        setCompanies(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getCompanies()
    }, [updateData])

    const RefreshButton = () => {
        return (
            <IconContext.Provider value={{ className: "h-4 w-4 flex-none" }}>
            <button onClick={() => setUpdateData(prev => !prev)}><TbRefresh /></button>
            </IconContext.Provider>
        )
    }

    const StatusCell = ({status}) => {
        if(status) {
            return <span className='py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap'>Onaylandı</span>
        }
        return <span className="py-0.5 px-2 rounded bg-orange-500 text-orange-50 whitespace-nowrap">Onay bekleniyor</span>
    }

    return (
        <Card 
            title='Firmalarım' 
            icon={<FaUsers />} 
            footer={null}
            titleButton={<RefreshButton />}
        >        
            <table className="w-full">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-2 text-left">Firma Adı/Ünvanı</th>
                        <th className="py-1 px-2 text-left">Vergi No</th>
                        <th className="py-1 px-2 text-left">Sektör</th>
                        <th className="py-1 px-2 text-left">Durum</th>
                    </tr>
                </thead>
                {loading ? 
                    <TablePlaceholder lineCount={10} colspan={4} />
                : 
                <tbody>
                    {companies.map(company => {
                        return (
                            <tr key={company.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">{company.company_name}</td>
                                <td className="px-2 py-3">{company.tax_number}</td>
                                <td className="px-2 py-3">{company.business_area.name}</td>
                                <td className="px-2 py-3"><StatusCell status={company.is_approved} /></td>
                            </tr>
                        )
                    })}
                </tbody>
                }
            </table>
        </Card>
    )
}

export default Companies