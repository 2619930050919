import { useEffect, useState } from 'react'
import { BiFile } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { IconContext } from "react-icons"
import { TbRefresh } from 'react-icons/tb'
import SendRequest from 'utils/SendRequest'
import { ButtonList } from 'elements/buttons/actionButtons'
import { TablePlaceholder } from 'elements/loaders'
import Card from 'elements/cards'
import CustomPopover from 'elements/popovers'
import { ButtonShowDetail } from 'elements/buttons/actionButtons'

function LatestRequests() {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [procurementRequests, setProcurementRequests] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        const getProcurementRequests = async () => {
            let page = '1'
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/department_procurement_requests?page=${page}`)
                .then(response => {
                    if(response.status === 200) {
                        setProcurementRequests(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProcurementRequests()
    }, [updateData])

    const RefreshButton = () => {
        return (
            <IconContext.Provider value={{ className: "h-4 w-4 flex-none" }}>
            <button onClick={() => setUpdateData(prev => !prev)}><TbRefresh /></button>
            </IconContext.Provider>
        )
    }

    useEffect(() => {   
        const interval = setInterval(() => {
            setUpdateData(prev => !prev)
        }, 15*60*1000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    const DateCell = ({createdDate, requirementDate}) => {
        let parsedCreatedDate = DateTime.fromISO(createdDate)
        let parsedRequirementDate = DateTime.fromISO(requirementDate)
        
        return (
            <div className="grid grid-cols-1 gap-y-1">
                {parsedCreatedDate.isValid ? 
                    <p className="whitespace-nowrap">
                        <span className="font-semibold">Oluşturulma: </span> 
                        <span>{parsedCreatedDate.toFormat('dd.LL.yyyy T')}</span>
                    </p>
                    : null
                }
                {parsedRequirementDate.isValid ? 
                    <p className="whitespace-nowrap">
                        <span className="font-semibold">İhtiyaç: </span> 
                        <span>{parsedRequirementDate.toFormat('dd.LL.yyyy')}</span>
                    </p>
                    : null
                }
            </div>
        )        
    }

    const StatusCell = ({status, approvals}) => {
        const content = (
            <div className='text-xs'>
                <ol className="list-decimal list-inside">
                    {approvals
                        .sort((a, b) => a.approval_sequence - b.approval_sequence)
                        .map(approval => {
                            return (
                                <li key={approval.id} className='whitespace-nowrap my-1'>
                                    <span className='font-semibold'>
                                        {approval?.user?.name}: 
                                    </span>
                                    {approval.is_accepted ? ' Kabul Etti' : null}
                                    {approval.is_rejected ? ' RedEtti' : null}
                                    {!approval.is_rejected && !approval.is_accepted ? ' Bekleniyor' : null}
                                    <span>
                                        - Sıra: {approval.approval_sequence}
                                    </span>
                                </li>
                            )
                        
                    })}
                </ol>
            </div>
        )

        switch (status) {
            case 'rejected': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Reddedildi</span>
                break
            case 'delivery_rejected': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Depo Teslimi Reddedildi</span>
                break
            case 'waiting_approvals': 
                return <div className="flex items-center justify-center gap-x-0">
                            <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">
                                Onaylar Bekleniyor                            
                            </span>
                            <CustomPopover content={content} >
                                <ButtonShowDetail label={null} />
                            </CustomPopover>
                        </div>
                break
            case 'waiting_delivery': 
                return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Teslim Alınmayı Bekliyor</span>
                break
            case 'waiting_procurement': 
                return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Satın Alınmayı Bekliyor</span>
                break
            case 'procurement_in_progress': 
                return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Satın Alma Süreci Devam Ediyor</span>
                break
            case 'completed': 
                return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Teslim Alındı</span>
                break
            case 'cancelled': 
                return <span className="py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap">İptal Edildi</span>
                break
            case 'archived': 
                return <span className="py-0.5 px-2 rounded bg-indigo-500 text-indigo-50 whitespace-nowrap">Arşivlendi</span>
                break
            default:
                return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">Bilinmiyor</span>
        }
                   
    }

    const LinesCell = ({lines}) => {
        const content = (
            <div className='text-xs'>
                <ol className="list-decimal list-inside">
                    {lines.map(line => {
                        return (
                            <li key={line.id} className='whitespace-nowrap my-1'>
                                <span className='font-semibold'>
                                    {line.product.name} {line.description_text ? `- ${line.description_text}` : null} : 
                                </span>
                                {line.quantity} {line.unit.description}
                            </li>
                        )
                        
                    })}
                </ol>
            </div>
        )

        return (
            <CustomPopover content={content} >
                <ButtonShowDetail label={lines.length} />
            </CustomPopover>
        ) 
        
    }

    const InfoCell = ({department, procurementType}) => {
        return (
            <div className="grid grid-cols-1 gap-y-1">
                {department?.department_name ? 
                    <p className="whitespace-nowrap">
                        <span className="font-semibold">Departman: </span> 
                        <span>{department.department_name}</span>
                    </p>
                    : null
                }
                {procurementType?.scenario_name ? 
                    <p className="whitespace-nowrap">
                        <span className="font-semibold">Satın Alma Türü: </span> 
                        <span>{procurementType.scenario_name}</span>
                    </p>
                    : null
                }
            </div>
        )
    }

    return (
        <Card 
            title='Son Yapılan Talepler' 
            icon={<BiFile />} 
            footer={<ButtonList onClick={() => navigate('/company/departments/procurement_requests')} />}
            titleButton={<RefreshButton />}
        >        
            <table className="w-full">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-1">Talep No</th>
                        <th className="py-1 px-1">Talep Bilgileri</th>
                        <th className="py-1 px-1">Tarih</th>
                        <th className="py-1 px-1">Durum</th>
                        <th className="py-1 px-1">Ürün Sayısı</th>
                    </tr>
                </thead>
                {loading ? 
                    <TablePlaceholder lineCount={10} colspan={5} />
                : 
                <tbody>
                    {procurementRequests !== null && Array.isArray(procurementRequests) && procurementRequests.length > 0 ?
                        procurementRequests.map(req => {
                            return (
                                <tr key={req.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">{req.procurement_request_no}</td>
                                    <td className="px-2 py-3 text-center"><InfoCell department={req.department} procurementType={req.procurement_type} /></td>
                                    <td className="px-2 py-3 text-center"><DateCell createdDate={req.created_at} requirementDate={req.requirement_date} /></td>
                                    <td className="px-2 py-3 text-center"><StatusCell status={req.status} approvals={req.approvals} /></td>
                                    <td className="px-2 py-3 text-center"><LinesCell lines={req.lines} /></td>
                                </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan={5}>Yapılan talep bulunamadı</td>
                        </tr>
                    }
                </tbody>
                }
            </table>
        </Card>
    )
}

export default LatestRequests