import { Controller } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import { components, MultiValueRemoveProps } from 'react-select'
import { IconContext } from "react-icons"
import { FaTimesCircle } from 'react-icons/fa'

function MultiSelectWithSearch(
    {
        data, 
        control, 
        name, 
        validator, 
        error, 
        actions, 
        placeholder = 'Seçiniz', 
        addClass = null, 
        label = null, 
        limitOptions = true
    }) {

    const MultiValueRemove = (props) => {
        return (
          
            <components.MultiValueRemove {...props}>
              <IconContext.Provider value={{ className: "h-3 w-3 text-gray-800" }}>
                <FaTimesCircle />
              </IconContext.Provider>
            </components.MultiValueRemove>
        )
    }
      

    const styles = {
        container: (baseStyles, state) => ({
            ...baseStyles,
            width: '100%'
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: 'auto',
            padding: '0.25rem .30rem',
            border: error ? '1px solid #ef4444' : '1px solid #9ca3af',
            borderRadius: '0.125rem',
            marginTop: '0.10rem',
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: '#9ca3af',
            margin: '0',
            padding: '0',
            height: '1rem',
            width: '1rem',
        }),
        indicatorsContainer: (baseStyles, state) => ({
            ...baseStyles,
            color: '#9ca3af',
            margin: '0',
            padding: '0',
            height: '1rem',
            width: '1rem',
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            color: '#9ca3af',
            margin: '0',
        }),
                                    
        input: (baseStyles, state) => ({
            ...baseStyles,
            outline: 'none',
            boxShadow: '0',
            padding: '0',
            margin: '0',
        }),
        valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            outline: 'none',
            boxShadow: '0',
            padding: '0'
        }),
        menuList: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0'
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0.3rem 0.7rem'
        }),
        multiValueRemove: (baseStyles, state) => ({
            ...baseStyles,
            '&:hover' : {
                backgroundColor: '#ccc',
                color: '#1f2937'
            }
            
        }),
    }

    const filterProducts = (inputValue) => {
        if(limitOptions) {
            return data.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            ).slice(0, 20)
        }
        return data.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
    };

    const loadOptions = (inputValue, callback) => {
        callback(filterProducts(inputValue))
    }

    return (
        <div className='flex flex-col gap-y-0.5 items-start'>
            <label htmlFor={name} className={['text-gray-700 text-xs', addClass].join(' ')}>{label}</label>
            <Controller 
                control={control}
                render={({field:{onChange, value, name, ref}}) => {
                // console.log(value)
                return (                    
                    <AsyncSelect
                        isMulti
                        inputRef={ref}
                        isClearable={false}
                        value={data.filter(c => value && Array.isArray(value) && value.includes(c.value))} 
                        defaultOptions={limitOptions ? data.slice(0, 20) : data}
                        defaultInputValue=''
                        loadOptions={loadOptions}
                        placeholder = {placeholder}
                        onChange = {(e) => {
                            onChange(e.map(item => item.value))
                            actions(e)
                        }}
                        styles={styles}
                        components={{ MultiValueRemove }}
                    />
                )}}
                name={name}
                rules={validator}
            />
            {<span className="text-xs text-red-600">{error}</span>}
        </div>
    )
}

export default MultiSelectWithSearch