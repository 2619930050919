import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonNew, ButtonOk, ButtonSave} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenSupplierInvitationCreate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useSupplierInvitaitonInput } from 'modules/companyModule/pages/suppliers/invitations/InputArray'

const CreateForm = ({update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            supplier_name: null,
            tax_number: null,
            supplier_email: null,            
        }
    })

    const invitationInputs = useSupplierInvitaitonInput(errors)

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/supplier_invitations`, data)
                .then(res => {
                    if(res.status === 201) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenSupplierInvitationCreate())
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <form onSubmit={handleSubmit(handleForm)}>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Tedarikçi Firma Bilgileri</p>
                <div className="grid grid-cols-12 gap-2">
                {invitationInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <InputSmall register={register} {...input} />
                        </div>
                        )
                })}
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonSave type="submit">
                    </ButtonSave>
                </div>          
            </form> 
        }   
        {loading && <Loader />}
        </>
    )
}

export default CreateForm