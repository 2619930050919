import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {ButtonClose} from 'elements/buttons/actionButtons'
import { setIsOpenSupplierDetail } from 'stores/DrawersSlice' 
import DetailInfo from 'modules/companyModule/pages/suppliers/details/DetailInfo'

const DetailForm = ({supplier, documentList}) => {
    const dispatch = useDispatch()
    
    
    
    const close = () => {
        dispatch(setIsOpenSupplierDetail())
    }

    return (
        <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-6rem)]">   
            <div className="w-full flex-1 overflow-y-auto">
                <DetailInfo supplier={supplier} documentList={documentList} />
            </div>                
            <div className="w-full flex justify-end mt-5">
                <ButtonClose type="button" onClick={close}>
                </ButtonClose>
            </div>               
        </div>
    )
}

export default DetailForm