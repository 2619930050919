import { useSelector } from 'react-redux'

export function useDepartmentInput(errors) {
    const userDepartments = useSelector(state => state.user.user.departments)
    const departmentArray = userDepartments.map(item => {
        return {key: item.department.id, value: item.department.department_name}
    })
    return [
        {
            key: "departmentInput1",
            type: "select",
            name: "department_id",
            placeholder: "İşlem Yapılacak Departman *",
            label: "İşlem Yapılacak Departman *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: departmentArray,
            error: errors?.department_id?.message,
            cols: 'col-span-12', 
        },   
    ]
}

export function useDescriptionInput(errors) {
    return [
        {
            key: "descriptionInput1",
            type: "textarea",
            name: "description",
            placeholder: "Açıklamalar",
            label: "Açıklamalar",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },
    ]
}

export function useLineInput(errors, products) {
    const unitCodes = useSelector(state => state.sharedData.unitCodes)

    const productArray = products.map(item => {
        return {key: item.id, value: item.name}
    })

    return [
        {
            key: "lineInput1",
            type: "select",
            nameLast: "product_id",
            placeholder: "Ürün",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: productArray,
            cols: 'col-span-4', 
        },
        {
            key: "lineInput3",
            type: "text",
            nameLast: "description_text",
            placeholder: "Ürün Açıklaması",
            label: null,
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            cols: 'col-span-4', 
        },
        {
            key: "lineInput4",
            type: "text",
            nameLast: "quantity",
            placeholder: "Miktar",
            label: null,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) > 0 || 'Sıfırdan büyük olmalı'
                }
            },
            cols: 'col-span-2', 
        },
        {
            key: "lineInput5",
            type: "select",
            nameLast: "unit_code_id",
            placeholder: "Birim",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: [],
            cols: 'col-span-1', 
        },
    ]
}

export function useStatusInputs(errors) {
    const statusArrayForUpdate = [
        {key: 'completed', value: 'Teslim Alındı'}, 
        {key: 'archived', value: 'Arşivlendi'},
    ]
    
    return [
        {
            key: "statusInput1",
            type: "select",
            name: "status",
            placeholder: "Ürün Talep Durumu",
            label: "Ürün Talep Durumu *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.status?.message,
            data: statusArrayForUpdate,
            cols: 'col-span-12', 
        }, 
    ]
}

export function useEnddateInputs(errors) {
    return [
        {
            key: "enddateInput1",
            type: "datetime-local",
            name: "end_date",
            placeholder: "Teklif Sona Erme Tarihi",
            label: "Teklif Sona Erme Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.end_date?.message,
            cols: 'col-span-12', 
        },   
        {
            key: "enddateInput2",
            type: "datetime-local",
            name: "delivery_date",
            placeholder: "Teslim Tarihi",
            label: "Teslim Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.delivery_date?.message,
            cols: 'col-span-12', 
        },    
    ]
}

export function useIssueInputs(errors) {
    return [
        {
            key: "issueInput1",
            type: "datetime-local",
            name: "start_date",
            placeholder: "Teklif Yayın Tarihi",
            label: "Teklif Yayın Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.start_date?.message,
            cols: 'col-span-12', 
        },    
        {
            key: "issueInput2",
            type: "datetime-local",
            name: "end_date",
            placeholder: "Teklif Sona Erme Tarihi",
            label: "Teklif Sona Erme Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.end_date?.message,
            cols: 'col-span-12', 
        },    
        {
            key: "issueInput3",
            type: "datetime-local",
            name: "delivery_date",
            placeholder: "Teslim Tarihi",
            label: "Teslim Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.delivery_date?.message,
            cols: 'col-span-12', 
        },    
    ]
}

