import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import { DateTime } from 'luxon'
import { ButtonOk, ButtonSave, ButtonNew} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FormatNumber from 'utils/FormatNumber'
import { setIsOpenWarehouseProductStockTransferAdd } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useStockTransferInput } from 'modules/companyModule/pages/warehouses/stocks/InputArray'

const StockTransferForm = ({product, update, setUpdate, selectedWarehouse}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)    
    const isOpenForm = useSelector(state => state.drawers.isOpenWarehouseProductStockTransferAdd)
    
    const [transferableWarehouses, setTransferableWarehouses] = useState([])
    const [unitCodes, setUnitCodes] = useState([])
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const formDefaults = {
        transferred_warehouse_id: null,
        transfer_date: DateTime.local().toFormat("yyyy-LL-dd"),
        description: null,
        quantity: null,
        unit_code_id: null,
    }
    const { register, handleSubmit, formState: { errors }, reset, watch, control, trigger, setValue, getValues } = useForm({
        defaultValues: formDefaults
    })

    const inputs = useStockTransferInput(errors, transferableWarehouses, unitCodes)

    useEffect(() => {
        if(product && Object.keys(product).length > 0) {
            let unitCodeArray = [{
                key: product.unit.id,
                value: `${product.unit.description} (${product.unit.code})`
            }]            
            setUnitCodes(unitCodeArray)
        }        
    }, [product])

    useEffect(() => {
        if(unitCodes && Array.isArray(unitCodes) && unitCodes.length > 0) {
            setValue('unit_code_id', product.unit.id.toString())
        }        
    }, [unitCodes])

    useEffect(() => {
        if(!isOpenForm) {
            setTransferableWarehouses([])
            reset()
            setError(null)
            setStatus(false)
        }        
        if(isOpenForm) {
            const getTransferableWarehouses = async () => {
                setLoading(true)
                await SendRequest('GET', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/products/${product.id}/transferable_warehouses`)
                    .then(response => {
                        if(response.status === 200) {
                            if(response.data.data.length > 0) {
                                let warehousesArray = response.data.data.map(w => {
                                    return {
                                        key: w.id,
                                        value: `${w.warehouse_name} (${w.warehouse_code})`
                                    }
                                })
                                setTransferableWarehouses(warehousesArray)
                            }                     
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
            getTransferableWarehouses()
        }
    }, [isOpenForm])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/products/${product.id}/transfers`, data)
                .then(response => {
                    if(response.status === 201) {
                        reset({...formDefaults, unit_code_id: product.unit.id})
                        setStatus(true)
                        setError(null)
                        setUpdate(prev => !prev)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenWarehouseProductStockTransferAdd())
    }

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
    }

    const handleError = (error, e) => {
        
    }

    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy')
    }

    const StockCell = ({stocks}) => {
        return (
            <div className='flex flex-col justify-center'>
                {Array.isArray(stocks) && stocks.length > 0 ? 
                    stocks.map(s => {
                        return <p key={s.id}>{s.quantity} {s.unit.description}</p>
                    })
                : 
                    <span>Stok Yok</span>
                }                                       
            </div>
        )
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />     
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>         
            </>
            :
            (product && Object.keys(product).length > 0) ?                
                <form onSubmit={handleSubmit(handleForm, handleError)}>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Mevcut Stok Bilgileri</p>
                    <table className="w-full">
                        <tbody>
                            <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">İşlem Yapılan Ürün:</td>
                                <td className="px-2 py-3">{product.name}</td>
                            </tr>
                            <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">İşlem Yapılan Depo:</td>
                                <td className="px-2 py-3">{selectedWarehouse.warehouse_name} ({selectedWarehouse.warehouse_code})</td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Depodaki Stok Miktarı:</td>
                                <td className="px-2 py-3">
                                    <StockCell stocks={product.warehouse_stocks} />
                                </td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Toplam Stok Miktarı:</td>
                                <td className="px-2 py-3">
                                    <StockCell stocks={product.stocks} />
                                </td>
                            </tr>
                        </tbody>
                    </table>     
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Yeni Stok Transferi Ekle</p>
                    
                    {Array.isArray(transferableWarehouses) && transferableWarehouses.length > 0 ?
                    <>
                    <div className="grid grid-cols-12 gap-2">
                    {inputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                {input.type === 'select' ? 
                                <SelectSmall register={register} {...input} rows="5" /> 
                                : <InputSmall register={register} {...input} /> 
                                }
                            </div>
                            )
                    })}
                    </div>               
                    <div className="w-full flex justify-end mt-5">
                        <ButtonSave type="submit">
                        </ButtonSave>
                    </div>  
                    </>
                    : 
                        <p className="text-red-600 text-base">Transfer edilebilecek kategoride depo bulunamadı.</p>
                    }
                            
                </form> 
                : null }
            
        {loading && <Loader />}
        </>
    )
}

export default StockTransferForm