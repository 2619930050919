import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { ProcurementRequestStatus } from 'data'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {ButtonFilter, ButtonReset} from 'elements/buttons/actionButtons'

function TableFilter({isFilterOpen, setIsFilterOpen, setFilters, setSearchParams}) {
    const [resetFields, setResetFields] = useState(false)
    const productTypes = useSelector(state => state.sharedData.productTypes)
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            search: null,
            procurement_request_status: null,
            start_created_at: null,
            end_created_at: null,
        }
    })

    const handleForm = async (data) => {
        Object.keys(data).forEach(k => data[k] = data[k] === '' ? null : data[k])
        setSearchParams('page=1')
        setIsFilterOpen(false)
        setFilters(data)
    }

    const handleReset = () => {
        reset()
        setResetFields(!resetFields)
        handleSubmit(handleForm)()
    }

    return (
        <div className={`flex-none border border-gray-200 bg-white overflow-hidden transition-[width] duration-300 ease-linear ${isFilterOpen ? 'w-full md:w-[250px] h-auto p-3' : 'w-0 h-0 p-0'}`}>
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 flex flex-col gap-y-2">
                        <InputSmall 
                            type='search'
                            name='search'
                            register={register}
                            label= 'Ara'
                            placeholder='Talep No'
                            validator={{}}
                            error={errors?.search?.message}
                        />
                        <SelectSmall                                 
                            type='select'
                            name='procurement_request_status'
                            register={register}
                            label='Talep Durumu'
                            placeholder='Talep Durumu'
                            validator={{}}
                            error={errors?.procurement_request_status?.message}
                            data={ProcurementRequestStatus()}
                            defaultOption='Tümü'
                        />   
                        <InputSmall 
                            type='date'
                            name='start_created_at'
                            register={register}
                            label= 'Başlangıç Tarihi'
                            placeholder='Başlangıç Tarihi'
                            validator={{}}
                            error={errors?.start_created_at?.message}
                        />                     
                        <InputSmall 
                            type='date'
                            name='end_created_at'
                            register={register}
                            label= 'Bitiş Tarihi'
                            placeholder='Bitiş Tarihi'
                            validator={{}}
                            error={errors?.end_created_at?.message}
                        />   
                    </div>
                </div>
                <div className="w-full flex justify-end gap-x-2 mt-5">
                    <ButtonReset type="button" onClick={() => handleReset()}>
                    </ButtonReset>
                    <ButtonFilter type="submit">
                    </ButtonFilter>
                </div>
            </form>   
        </div>
    )
}

export default TableFilter