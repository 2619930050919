import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonNew, ButtonOk} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenPositionCreate } from 'stores/DrawersSlice' 
import { usePositionLevels } from 'data'
import Loader from 'elements/Loader'

const CreatePositionForm = ({update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenCreate = useSelector(state => state.drawers.isOpenPositionCreate)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, watch, control } = useForm({
        defaultValues: {
            position_name: null,
            level: '1',
        }
    })

    const levels = usePositionLevels()

    const inputs = [
        {
            key: "input1",
            type: "text",
            name: "position_name",
            placeholder: "Pozisyon Adı",
            label: "Pozisyon Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.position_name?.message,
            cols: 'col-span-12', 
        },
        {
            key: "input2",
            type: "select",
            name: "level",
            placeholder: "Seviye",
            label: "Seviye *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.level?.message,
            data: levels,
            cols: 'col-span-12', 
        }, 
    ]

    useEffect(() => {
        if(!isOpenCreate) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenCreate])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/positions`, data)
                .then(res => {
                    if(res.status === 201) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenPositionCreate())
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="grid grid-cols-12 gap-2">
                {inputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            {input.type == 'select' ? 
                                <SelectSmall register={register} {...input} /> :
                                <InputSmall register={register} {...input} />
                            }
                        </div>
                        )
                })}                
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonSave type="submit">
                    </ButtonSave>
                </div>                
            </form> 
        }   
        {loading && <Loader />}
        </>
    )
}

export default CreatePositionForm