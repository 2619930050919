import { useMemo, useState, useEffect, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { IconContext } from "react-icons"
import { FaChartLine, FaPlus, FaMinus } from 'react-icons/fa'
import { HiOutlineDotsVertical, HiOutlineQuestionMarkCircle, HiOutlineInformationCircle } from 'react-icons/hi'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'
import Card from 'elements/cards'
import CustomPopover from 'elements/popovers'
import { ButtonShowDetail, ButtonSave, ButtonOk } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierDetail, setIsOpenProcurementProposalAccept } from 'stores/DrawersSlice' 
import { ProcurementPaymentTypes } from 'data'
import { sortedProposals, slicedProposals, tableLabels, procurementLines, transformedDataForBlock, transformedDataForPartial } from './utils'

function TopNTable({procurement, 
                    datalength = 15, 
                    setDatalength, 
                    setLoading, 
                    supplierIdForDetail, 
                    setSupplierIdForDetail,
                    proposalData,
                    setProposalData
                }) {
    const dispatch = useDispatch()
    const usedRate = 'forexSelling'
    const exchangeRates = useSelector((state) => state.sharedData.exchangeRates)

    const [isAcceptAllowed, setIsAcceptAllowed] = useState(false)

    const userRoles = useSelector(state => state.user.user.roles)
    const userId = useSelector(state => state.user.user.id)

    const auth = {
        procurement_accept_owned: userRoles.find(roles => roles.code === 'procurements_accept_proposal_owned') !== undefined ? true : false,        
        procurement_accept_all: userRoles.find(roles => roles.code === 'procurements_accept_proposal_all') !== undefined ? true : false,        
    }

    useEffect(() => {
        if(auth.procurement_accept_all) setIsAcceptAllowed(true)
        if(auth.procurement_accept_owned && userId === procurement.user?.id) setIsAcceptAllowed(true)

    }, [auth, userId, procurement])

    const sorted = useMemo(
        () => sortedProposals(procurement, usedRate, exchangeRates),
        [procurement, usedRate, exchangeRates]
    )

    const stacks =  useMemo(
        () => procurementLines(procurement, usedRate),
        [procurement]
    )

    const sliced = useMemo(
        () => slicedProposals(sorted, datalength),
        [sorted, datalength]
    )
    
    const labels = useMemo(
        () => tableLabels(sliced),
        [sliced]
    )

    const tableData = useMemo(
        () => transformedDataForBlock(procurement, sorted, sliced, stacks, usedRate, exchangeRates),
        [procurement, sorted, sliced, stacks, usedRate, exchangeRates]
    )

    const tableDataForPartial = useMemo(
        () => transformedDataForPartial(procurement, sorted, stacks, usedRate, exchangeRates, datalength),
        [procurement, sorted, stacks, usedRate, exchangeRates, datalength]
    )
    
    let formDefaultForLines = {}

    tableData.forEach(tdata => {
        formDefaultForLines['line_' + tdata.id] = null
    })

    const { register, reset, handleSubmit, formState: { errors }, control, watch, setValue, getValues } = useForm({
        defaultValues: {
            proposal: null,
            ...formDefaultForLines
        }
    })

    const SupplierDetailButton = ({supplierId}) => {
        return (
            <button type="button" 
                    className="flex-none rounded text-gray-700 bg-white"
                    onClick={() => {setSupplierIdForDetail(supplierId); dispatch(setIsOpenSupplierDetail(true))}} 
            >
                <IconContext.Provider value={{ className: "h-4 w-4" }}>
                <span>
                <HiOutlineInformationCircle />                        
                </span>
                </IconContext.Provider>
            </button>
        )
    }

    const paymentTypeDesc = (code) => {
        let selectedCode = ProcurementPaymentTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value
        }
        return ''
    }

    const PaymentTermsPopover = ({paymentType, deferralDays}) => {
        const payTypeDesc = paymentTypeDesc(paymentType)
        const content = <p className="text-xs">
                            <span className="font-semibold">Ödeme Şekli:</span> &nbsp;
                            {payTypeDesc} {paymentType === 'deferred' ? `(${deferralDays} Gün)` : null}
                        </p>
        return (
            <CustomPopover content={content} >
                <ButtonShowDetail label={null} />
            </CustomPopover>
        )
    }

    const SupplierNumberInput = () => {
        const incrementLength = () => {
            if(datalength < sorted.length) {
                reset()
                setDatalength(prev => prev + 1)
            }
        }
        const decrementLength = () => {
            if(datalength > 1) {
                reset()
                setDatalength(prev => prev - 1)
            }
        }
        return (            
            <div className="py-2 px-3 bg-white border border-gray-200 rounded-lg">
                <div className="w-full flex justify-between items-center gap-x-5">
                    <div className="grow">
                    <span className="block text-xs text-gray-500">
                        Gösterilen Teklif Sayısı
                    </span>
                    <p className="text-sm w-full p-0 bg-transparent border-0 text-gray-800 font-semibold">
                        {datalength}
                    </p>
                    </div>
                    <div className="flex justify-end items-center gap-x-1.5">
                    <IconContext.Provider value={{ className: "h-3 w-3 text-gray-700" }}>
                    <button 
                        disabled={datalength < 2 ? true : false}
                        type="button" 
                        className="inline-flex p-1 justify-center items-center rounded-full border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={decrementLength}
                    >
                        <FaMinus />
                    </button>
                    <button 
                        disabled={datalength > sorted.length -1 ? true : false}
                        type="button" 
                        className="inline-flex p-1 justify-center items-center rounded-full border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={incrementLength}
                    >
                        <FaPlus />
                    </button>
                    </IconContext.Provider>
                    </div>
                </div>
            </div>
        )
    }

    

    const RadioButton = ({name, value, disabled, required}) => {   
        const radioChangeEvent = (e) => {
            if(e.target.name === 'proposal') {
                tableData.forEach(td => {
                    const proposalline = td.data.find(d => d.proposalId.toString() === e.target.value)
                    setValue(`line_${td.id}`, proposalline.id.toString(), { shouldDirty: true, shouldValidate: true })
                })
            }            
        }

        return (
            <input 
                name={name}
                value={value}
                type="radio"
                disabled={disabled}
                {...register(name, {required: required, onChange: radioChangeEvent, shouldUnregister: true})}
                className="form-radio text-sky-500 border-2 border-sky-300 rounded-full focus:outline-none focus:shadow-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent disabled:opacity-50 disabled:cursor-not-allowed"
            />
        )
    }

    const handleForm = (data) => {
        setProposalData(data)
        dispatch(setIsOpenProcurementProposalAccept(true))
    }

    const handleError = (error) => {
        console.log(error)
    }
    
    const CardBody = () => {
        return (
            <div className="w-full min-w-[600px] overflow-hidden">
                <form onSubmit={handleSubmit(handleForm, handleError)}>
                <div className="mb-2 flex items-center justify-between">
                    <div>
                        {procurement.is_proposal_accepted === false && isAcceptAllowed ? 
                            <>
                            <ButtonOk type="submit" label="Seçilen Teklifleri Kabul Et" />
                            {Object.entries(errors).length > 0 && 
                            <p className="text-red-500">Tüm kalemler için bir teklif seçmelisiniz</p>
                            }
                            </> :
                            null
                        }                    
                    </div>
                    <SupplierNumberInput />
                </div>
                <div className="grid grid-cols-12">
                    <div className="col-span-2">
                        <div className="h-16 flex items-center justify-start border-b border-r border-gray-600">
                            <p className="font-semibold px-1">Ürün</p>                            
                        </div>
                        {tableData.map((tdata, index) => {
                            return (
                                <div key={tdata.id} className="h-16 flex items-center justify-start border-b border-r border-gray-600 even:bg-gray-200">
                                    <p className="px-1">{tdata.label}</p>
                                </div>
                            )
                        })}
                        <div className="h-8 flex items-center justify-start"></div>
                        <div className="h-16 flex items-center justify-start border-b border-t border-r border-gray-600 ">
                            <p className="font-semibold px-1">Toplam</p>
                        </div>
                    </div>
                    <div className="col-span-7 overflow-y-auto">
                        <div className="w-full flex">
                            {labels.map((l, lindex) => {
                                return (
                                    <div className="basis-full min-w-[8rem]" key={l.id}>
                                        <div className="h-16 flex flex-col items-center justify-center border-b border-gray-600">
                                            <div className="flex items-center justify-center">
                                                <p className="font-semibold px-1">{l.name}</p>
                                                <SupplierDetailButton supplierId={l.supplierId} />
                                            </div>
                                            {procurement.is_proposal_accepted === false && isAcceptAllowed ?
                                            <RadioButton 
                                                name={`proposal`} 
                                                value={l.id} 
                                                disabled={procurement.proposal_type === 'partial'} 
                                                required={procurement.proposal_type === 'block'}
                                            /> :
                                            null }
                                        </div>
                                        {tableData.map((tdata, tindex) => {
                                            let bgColor = 'even:bg-gray-200'
                                            if(tableData.length > 1 &&  Math.min(...tdata.data.map(dd => dd.derived_price)) === tdata.data[lindex].derived_price) bgColor = 'bg-green-400'
                                            if(tableData.length > 1 &&  Math.max(...tdata.data.map(dd => dd.derived_price)) === tdata.data[lindex].derived_price) bgColor = 'bg-red-400'
                                            return (
                                                <div key={tdata.data[lindex].id} className={`h-16 flex flex-col items-center justify-center border-b border-gray-600 ${bgColor}`}>
                                                    <p className='font-semibold'>
                                                        {FNWS(tdata.data[lindex].price)} {tdata.data[lindex].currencyCode}
                                                    </p> 
                                                    {procurement.currency.id !== tdata.data[lindex].currencyId &&
                                                    <p>
                                                        ({FNWS(tdata.data[lindex].derived_price)} {procurement.currency.code})
                                                    </p>                                                        
                                                    }
                                                    {procurement.is_proposal_accepted === false && isAcceptAllowed ?
                                                    <RadioButton 
                                                        name={`line_${tdata.id}`} 
                                                        value={tdata.data[lindex].id} 
                                                        disabled={procurement.proposal_type === 'block'} 
                                                        required={procurement.proposal_type === 'partial'}
                                                    /> :
                                                    null }
                                                </div>
                                            )
                                        })}  
                                        <div className="h-8 flex items-center justify-start"></div>
                                        <div className={`h-16 flex flex-col items-center justify-center border-b border-t border-gray-600`}>
                                            <p className='font-semibold flex items-center justify-center gap-x-0'>
                                                <span>
                                                    {FNWS(sliced[lindex].total_price)} {sliced[lindex].currency.code}
                                                </span>
                                                <PaymentTermsPopover 
                                                    paymentType={sliced[lindex].payment_type} 
                                                    deferralDays={sliced[lindex].deferral_days} 
                                                />
                                            </p> 
                                            {procurement.currency.id !== sliced[lindex].currency.id &&
                                            <p>
                                                ({FNWS(sliced[lindex].derived_price)} {procurement.currency.code})
                                            </p>
                                            }
                                        </div>                        
                                    </div>
                                    
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-span-3 w-full flex overflow-y-auto">
                        <div className="basis-full min-w-[8rem]">
                            <div className="h-16 flex items-center justify-center border-b border-l border-gray-600">
                                <p className="font-semibold px-1">En İyi</p>
                            </div>
                            {tableData.map((tdata, tindex) => {
                                return (
                                    <div key={tdata.id} className={`h-16 flex flex-col items-center justify-center border-b border-l border-gray-600 even:bg-gray-200`}>
                                        <p className=''>
                                            {FNWS(tdata.statistics.min)} {procurement.currency.code}
                                        </p> 
                                    </div>
                                    )
                            })}  
                            <div className="h-8 flex items-center justify-center"></div>
                            <div className="h-16 flex items-center justify-center border-b border-t border-l border-gray-600 ">
                                <p className="font-semibold">
                                    {FNWS(tableData.reduce((prev, current) => {
                                        return prev + current.statistics.min
                                    }, 0))} {procurement.currency.code}
                                </p>
                            </div>
                        </div>
                        <div className="basis-full min-w-[8rem]">
                            <div className="h-16 flex items-center justify-center border-b border-gray-600">
                                <p className="font-semibold px-1">Ortalama</p>
                            </div>
                            {tableData.map((tdata, tindex) => {
                                return (
                                    <div key={tdata.id} className={`h-16 flex flex-col items-center justify-center border-b border-gray-600 even:bg-gray-200`}>
                                        <p className=''>
                                            {FNWS(tdata.statistics.avg)} {procurement.currency.code}
                                        </p> 
                                    </div>
                                    )
                            })}  
                            <div className="h-8 flex items-center justify-center"></div>
                            <div className="h-16 flex items-center justify-center border-b border-t border-gray-600 ">
                                <p className="font-semibold">
                                    {FNWS(tableData.reduce((prev, current) => {
                                        return prev + current.statistics.avg
                                    }, 0))} {procurement.currency.code}
                                </p>
                            </div>
                        </div>
                        <div className="basis-full min-w-[8rem]">
                            <div className="h-16 flex items-center justify-center border-b border-gray-600">
                                <p className="font-semibold px-1">En Kötü</p>
                            </div>
                            {tableData.map((tdata, tindex) => {
                                return (
                                    <div key={tdata.id} className={`h-16 flex flex-col items-center justify-center border-b border-gray-600 even:bg-gray-200`}>
                                        <p className=''>
                                            {FNWS(tdata.statistics.max)} {procurement.currency.code}
                                        </p> 
                                    </div>
                                    )
                            })}  
                            <div className="h-8 flex items-center justify-center"></div>
                            <div className="h-16 flex items-center justify-center border-b border-t border-gray-600 ">
                                <p className="font-semibold">
                                    {FNWS(tableData.reduce((prev, current) => {
                                        return prev + current.statistics.max
                                    }, 0))} {procurement.currency.code}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        )
    }

    const CardBodyForPartial = () => {
        return (
            <div className="w-full min-w-[600px] overflow-hidden">
                <form onSubmit={handleSubmit(handleForm, handleError)}>
                <div className="mb-2 flex items-center justify-between">
                    <div>
                        {procurement.is_proposal_accepted === false && isAcceptAllowed ? 
                            <>
                            <ButtonOk type="submit" label="Seçilen Teklifleri Kabul Et" />
                            {Object.entries(errors).length > 0 && 
                            <p className="text-red-500">Tüm kalemler için bir teklif seçmelisiniz</p>
                            }
                            </> :
                            null
                        }                    
                    </div>
                    <SupplierNumberInput />
                </div>
                
                    {tableDataForPartial.map((tdata, tindex) => {
                        return (
                            <div key={tdata.id} className="grid grid-cols-12 border border-gray-600 mb-4">
                                <div className="col-span-2">
                                    <div className="h-8 flex items-center justify-start border-b border-r border-gray-600">
                                        <p className="font-semibold px-1">Ürün</p>                            
                                    </div>
                                    <div className="h-16 flex items-center justify-start border-r border-gray-600 even:bg-gray-200">
                                        <p className="px-1">{tdata.label}</p>
                                    </div>
                                </div>
                                <div className="col-span-7 overflow-y-auto">
                                    <div className="w-full flex">
                                        {tdata.data.map((udata, uindex) => {
                                            let bgColor = 'even:bg-gray-200'
                                            return (
                                                <div className="basis-full min-w-[8rem]" key={udata.id}>
                                                    <div className="h-8 flex flex-col items-center justify-center border-b border-gray-600">
                                                        <div className="flex items-center justify-center">
                                                            <p className="font-semibold px-1">{udata.supplierName}</p>
                                                            <SupplierDetailButton supplierId={udata.supplierId} />
                                                        </div>
                                                    </div>
                                                    <div className={`h-16 flex flex-col items-center justify-center border-gray-600 ${bgColor}`}>
                                                        <p className='font-semibold flex items-center justify-center gap-x-0'>
                                                            <span>
                                                                {FNWS(udata.price)} {udata.currencyCode}
                                                            </span>
                                                            <PaymentTermsPopover 
                                                                paymentType={udata.paymentType} 
                                                                deferralDays={udata.deferralDays} 
                                                            />
                                                        </p> 
                                                        {procurement.currency.id !== udata.currencyId &&
                                                        <p>
                                                            ({FNWS(udata.derived_price)} {procurement.currency.code})
                                                        </p>                                                        
                                                        }
                                                        {procurement.is_proposal_accepted === false && isAcceptAllowed ?
                                                        <RadioButton 
                                                            name={`line_${tdata.id}`} 
                                                            value={udata.id} 
                                                            required={procurement.proposal_type === 'partial'}
                                                        /> :
                                                        null }
                                                    </div>                                                                         
                                                </div>                                                
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-span-3 w-full flex overflow-y-auto">
                                    <div className="basis-full min-w-[8rem]">
                                        <div className="h-8 flex items-center justify-center border-b border-l border-gray-600">
                                            <p className="font-semibold px-1">En İyi</p>
                                        </div>
                                        <div className={`h-16 flex flex-col items-center justify-center border-l border-gray-600 even:bg-gray-200`}>
                                            <p className=''>
                                                {FNWS(tdata.statistics.min)} {procurement.currency.code}
                                            </p> 
                                        </div> 
                                    </div>
                                    <div className="basis-full min-w-[8rem]">
                                        <div className="h-8 flex items-center justify-center border-b border-gray-600">
                                            <p className="font-semibold px-1">Ortalama</p>
                                        </div>
                                        <div className={`h-16 flex flex-col items-center justify-center even:bg-gray-200`}>
                                            <p className=''>
                                                {FNWS(tdata.statistics.avg)} {procurement.currency.code}
                                            </p> 
                                        </div> 
                                    </div>
                                    <div className="basis-full min-w-[8rem]">
                                        <div className="h-8 flex items-center justify-center border-b border-gray-600">
                                            <p className="font-semibold px-1">En Kötü</p>
                                        </div>
                                        <div className={`h-16 flex flex-col items-center justify-center even:bg-gray-200`}>
                                            <p className=''>
                                                {FNWS(tdata.statistics.max)} {procurement.currency.code}
                                            </p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </form>
            </div>
        )
    }

    return (
        <Card title={`İlk ${datalength} Firmanın Teklifleri`} icon={<FaChartLine />} >  
            {procurement.proposal_type === 'block' ? 
            <CardBody /> :
            <CardBodyForPartial />
            }   
        </Card>
    )
    
}

export default TopNTable