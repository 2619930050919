import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import TableButtonRed from 'elements/dropdowns/TableButtonRed'
import { IconContext } from "react-icons"
import { FaPlusCircle, FaSearch, FaUsers, FaUserShield, FaUsersCog, FaRegEdit, FaSlidersH } from 'react-icons/fa'
import { setIsOpenDepartmentProcurementScenario, 
            setIsOpenDepartmentAddDemandAuth, 
            setIsOpenDepartmentAddUsers,
            setIsOpenDepartmentEdit,
            setIsOpenDepartmentCreate,
        } from '../../../../../../stores/DrawersSlice'

const TableActions = ({actions, data, parentId}) => {
    const dispatch = useDispatch()

    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton 
                    title='Alt Departman Ekle' 
                    icon={<FaPlusCircle />} 
                    onClick={() => {
                        actions.setParent(data)
                        dispatch(setIsOpenDepartmentCreate(true))}
                    }
                />
                <TableButton 
                    title='Düzenle' 
                    icon={<FaRegEdit />} 
                    onClick={() => {
                        actions.setParentIdForEdit(parentId)
                        actions.setDepartmentForEdit(data)
                        dispatch(setIsOpenDepartmentEdit(true))}
                    }
                />
                <TableButton 
                    title='Kullanıcılar' 
                    icon={<FaUsers />} 
                    onClick={() => {
                        actions.setDepartmentForUsers(data); 
                        dispatch(setIsOpenDepartmentAddUsers(true))}
                    }
                />
                <TableButton 
                    title='Talep Yetkileri' 
                    icon={<FaUserShield />} 
                    onClick={() => {
                        actions.setDepartmentForAuth(data); 
                        dispatch(setIsOpenDepartmentAddDemandAuth(true))}
                    }
                />
                <TableButton 
                    title='Satın Alma Onay İşlemleri' 
                    icon={<FaUsersCog />} 
                    onClick={() => {
                        actions.setDepartmentForProcurementRequestScenario(data); 
                        dispatch(setIsOpenDepartmentProcurementScenario(true))}
                    }
                />
                
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
