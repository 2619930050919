import { Controller, useController } from 'react-hook-form'
import { useState, useEffect, Fragment, useId, useRef } from 'react'
import { IconContext } from "react-icons"
import { HiChevronDown, HiChevronUp, HiChevronRight } from 'react-icons/hi'

function DropdownTree({data, control, name, validator, error, actions, placeholder = 'Seçiniz', label = 'Başlık', nullOption = true}) {
    const dropdownId = useId()
    const buttonId = useId()
    const dropdownRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)
    const [expandedNodes, setExpandedNodes] = useState([])

    const {
        field,
        fieldState: { invalid, isTouched, isDirty },
        formState: { touchedFields, dirtyFields }
      } = useController({
        name,
        control,
        rules: validator,
      })

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setOpen(false)
            }
          };
      
          document.addEventListener('mousedown', handleClickOutside)
      
          return () => {
            document.removeEventListener('mousedown', handleClickOutside)
          };
    }, [dropdownRef]) 

    useEffect(() => {
        const findItem = (data, id) => {
            if(Array.isArray(data)) {
                for(let item of data) {
                    const foundItem = findItem(item, id)
                    if(foundItem) {
                        return foundItem
                    } 
                }            
            } else {
                if(data.key === id) return data
                if(data.children.length > 0) {
                    for(let item of data.children) {
                        const foundItem = findItem(item, id)
                        if(foundItem) {
                            return foundItem
                        }
                    }
                }
            }
        }


        if(field.value === null || field.value === undefined) {
            setSelected(null)
        } else {
            let selectedItem = findItem(data, field.value)
            if(selectedItem) {
                setSelected(selectedItem)
            }
        }        

    }, [field.value])

    const closeDropdown = () => {setOpen(false)}
    const Chevron = () => {
        return open ? <HiChevronUp /> : <HiChevronDown />
    } 

    const handleNodeToggle = (key) => {
        if (expandedNodes.includes(key)) {
        setExpandedNodes(expandedNodes.filter(nodeId => nodeId !== key))
        } else {
        setExpandedNodes([...expandedNodes, key])
        }
    }

    

    const selectedValue = selected === null 
                            ? placeholder 
                            : selected.value

    const borderColor = error ? 'border-red-500' : 'border-gray-400'

    const TreeNode = ({ nodes, onChange, formValue }) => {
        return (
          <div>            
            {nodes && Array.isArray(nodes) && nodes.map(node => {
                const isNodeExpanded = expandedNodes.includes(node.key)
                // if(formValue && formValue.toString() === node.key.toString()) setSelected(node)
                return (
                    <Fragment key={node.key}>
                        <div className='flex items-center justify-start py-px'>
                            {node.children && node.children.length > 0 ? 
                            <button onClick={() => handleNodeToggle(node.key)} type="button">
                                <IconContext.Provider value={{ className: "h-4 w-4" }}>
                                <span>
                                    {isNodeExpanded ? <HiChevronRight /> : <HiChevronDown/>}                        
                                </span>
                                </IconContext.Provider>
                            </button>
                            : <span className='inline-block w-4'></span> }
                            <button 
                                type='button'
                                className='px-1 py-0.5 hover:bg-gray-200 cursor-pointer flex-grow text-left'
                                onClick={() => {onChange(node); setOpen(prev => !prev); }}
                            >
                            {node.value}
                            </button>
                        </div>
                        {node.children && (              
                            <div className={`ml-4 ${isNodeExpanded ? 'block' : 'hidden'}`}>
                                <TreeNode nodes={node.children} onChange={onChange} formValue={formValue} />
                            </div>
                        )}
                    </Fragment>
                )
            })}            
          </div>          
        )
    }

    const Dropdown = ({ onChange, formValue }) => {
        // if(formValue === null || formValue === undefined) setSelected(null)
        return (
            <div className="w-full inline-block relative h-full flex flex-col items-start gap-y-0.5">
                <label className="text-gray-700 text-xs">{label}</label>
                <button 
                    id={buttonId}
                    onClick={(e) => {e.preventDefault(); e.stopPropagation(); setOpen(prev => !prev)}} 
                    className={["w-full rounded-sm px-1 py-1 text-xs border flex items-center justify-between relative rounded cursor-pointer", borderColor].join(" ")}
                >
                    <div>{selectedValue}</div>
                    <div className='mx-1'>
                        <IconContext.Provider value={{ className: "h-[1.1rem] w-[1.1rem] text-gray-500" }}>
                            <Chevron/>
                        </IconContext.Provider>
                    </div>
                </button>
                <div ref={dropdownRef} id={dropdownId} className={`z-10 absolute bg-white border border-gray-200
                                    left-0 origin-top-right left-auto top-full
                                    w-full mt-1 p-1 shadow-md rounded-md max-h-52 overflow-y-scroll ${open ? 'block' : 'hidden'}`}>
                    {nullOption === true ? 
                        <div className='flex items-center justify-start py-px text-gray-700 border-b border-gray-200'>
                            <span className='inline-block w-4'></span>
                            <button 
                                type='button'
                                className='px-1 py-0.5 hover:bg-gray-200 cursor-pointer flex-grow text-left'
                                onClick={() => {setSelected(null); onChange(null); setOpen(prev => !prev)}}
                            >
                            {placeholder}
                            </button>
                        </div>
                    : null}
                    <TreeNode nodes={data} onChange={onChange} formValue={formValue} />
                </div>     
                {<span className="text-xs text-red-600">{error}</span>}      
            </div>
        )
    }

    return (
        <Dropdown
            onChange = {(e) => {
                field.onChange(e?.key ? e.key : null)     
                actions(e)
            }}
            formValue = {field.value}
            name={field.name}
        />
    )
}

export default DropdownTree