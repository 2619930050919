import { useContactTypes } from 'data'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonEditSmall, ButtonDeleteSmall } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierBankAccountEdit, setIsOpenSupplierBankAccountDelete } from 'stores/DrawersSlice'

function AccountTable({accounts, actions}) {
    const dispatch = useDispatch()
    if(accounts !== null && Array.isArray(accounts)) {
        return (         
            <table className="w-full text-xs">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-1 text-left">Banka Adı</th>
                        <th className="py-1 px-1">Şube Adı</th>
                        <th className="py-1 px-1">IBAN</th>
                        <th className="py-1 px-1">Hesap No</th>
                        <th className="py-1 px-1">Para Birimi</th>
                        <th className="py-1 px-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {accounts.length > 0 ?
                        accounts.map(account => {
                            return (
                                <tr key={account.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">{account.bank_name}</td>
                                    <td className="px-2 py-3 text-center">{account.branch_name}</td>
                                    <td className="px-2 py-3 text-center">{account.iban}</td>
                                    <td className="px-2 py-3 text-center">{account.account_no}</td>
                                    <td className="px-2 py-3 text-center">{account.currency.description} ({account.currency.code})</td>
                                    <td className="px-2 py-3 text-center">
                                        <div className="flex items-center justify-end gap-x-1">
                                            <ButtonEditSmall 
                                                label={null} 
                                                onClick={() => {
                                                    actions.setAccountForEdit(account)
                                                    dispatch(setIsOpenSupplierBankAccountEdit(true))
                                                }}    
                                            />
                                            <ButtonDeleteSmall 
                                                label={null} 
                                                onClick={() => {
                                                    actions.setAccountForDelete(account)
                                                    dispatch(setIsOpenSupplierBankAccountDelete(true))
                                                }}    
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan={6}>Banka hesap bilgisi bulunamadı</td>
                        </tr>
                    }
                </tbody>
            </table>       
        )
    }
    return null
    
}

export default AccountTable