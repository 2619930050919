import { useState, useEffect } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' 
import { ButtonFilter, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import Table from 'modules/companyModule/pages/productDemands/approvals/Table'
import TableFilter from 'modules/companyModule/pages/productDemands/approvals/TableFilter'
import Approve from 'modules/companyModule/pages/productDemands/approvals/Approve'
import Detail from 'modules/companyModule/pages/productDemands/approvals/Detail'

function ProductDemandApprovals() {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [products, setProducts] = useState([])
    const [productDemands, setProductDemands] = useState([])
    const [productDemandForDetail, setProductDemandForDetail] = useState(null)
    const [productDemandForAction, setProductDemandForAction] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)

    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null, procurement_status: 'waiting_approvals', approval_status: 'notprocessed'})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation()

    useEffect(() => {
        const getProductDemands = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/product_demand_approvals?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setProductDemands(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProductDemands()
    }, [updateData, filters, search])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Onayınıza Sunulan Ürün Talepleri</h2>
                <div></div>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                            products={products}
                />

                <Table data={productDemands} 
                        actions={{
                            setProductDemandForDetail: setProductDemandForDetail,
                            setProductDemandForAction: setProductDemandForAction,
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            <Detail productDemand={productDemandForDetail} />
            <Approve update={updateData} setUpdate={setUpdateData} productDemand={productDemandForAction} />
        </div>
    )
}

export default ProductDemandApprovals