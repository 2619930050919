import React from 'react'
import LoginForm from 'modules/authModule/pages/login/LoginForm'
import logo from 'assets/img/marlaport-white.png'
import marlaportLogo from 'assets/img/marlaport_logo.png'
import {LinkGray, LinkBlue} from 'elements/links'

const Login = () => {

    return (
        <div className="w-full py-10 bg-slate-100 bg-no-repeat bg-cover px-2 flex flex-col justify-center items-center gap-y-4">
            <div>
                <img src={marlaportLogo} className="h-12" />
            </div>
            <div className="w-full bg-white shadow-lg rounded-lg md:max-w-lg lg:w-[28rem]">                
                <div className="w-full h-full flex flex-col justify-between gap-y-1">
                    <p className="text-lg pt-8 px-4 text-gray-800">Giriş Yap</p>
                    <LoginForm />
                </div>
            </div>
            {/* <div className="w-md bg-white shadow-lg rounded-lg md:max-w-lg lg:w-[28rem]">                
                <div className="w-full h-full flex flex-col justify-between gap-y-1">
                    <p className="text-lg pt-8 px-4 text-gray-800 font-semibold">Tedarikçi mi olmak istiyorsunuz?</p>
                    <p className="text-sm px-4 text-gray-800">Siz de Marlaport'un tedarikçiler dünyasında hemen yerinizi alın</p>
                    <div className="py-4 text-center border-t border-gray-200">
                        <p className="text-black-700"><LinkBlue route="/register/supplier" title="Tedarikçi ağına katılın" /> </p>   
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Login