import Loader from 'elements/Loader'
import Pagination from 'elements/tables/Pagination'
import { HiChevronDown } from 'react-icons/hi'

const TableLayout = ({columns, data, mobileHeader, progressPending = null, pagination = null, pageMeta = null}) => {

    const handleMobileOpen = (id) => {
        const tds =  document.querySelectorAll(`[data-id='${id}']`)
        const chewron =  document.querySelectorAll(`[data-chewron-id='${id}']`)
        Array.from(tds).map(node => {
            node.classList.toggle('visible')
            node.classList.toggle('hidden')
        })
       
        Array.from(chewron).map(node => {
            node.classList.toggle('rotate-180')            
        })
    }
  return (
      <div className='w-full relative overflow-y-visible'>
        <table className='w-full text-xs'>
            <thead className='text-gray-100'>
                <tr className='bg-gray-700'>
                {columns.map((column, index) => {
                    if(column) {
                        return <th className={`px-2 py-2 hidden lg:table-cell whitespace-pre-line ${column?.borderLeft === true && ' lg:border-l lg:border-gray-100'} ${column?.borderRight === true && ' lg:border-r lg:border-gray-100'}`} key={`tableHead${index}`}>{column.name}</th>
                    }
                })}
                </tr>
            </thead>
            <tbody>
                    {(data != null && data?.length) ? data.map(item => {
                        return (<tr className='border lg:border-x-0 lg:border-t-0 border-gray-500 even:bg-gray-200 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-4 lg:mb-0 shadow-lg lg:shadow-none' key={`tableRow${item.id}`}>
                            <td className='w-full lg:w-auto lg:hidden text-left text-sm bg-gray-700 text-gray-100'>
                                <button onClick={(e) => handleMobileOpen(item.id)} className='px-2 py-1 w-full text-left flex justify-between items-center'>
                                    <span>{mobileHeader(item)}</span>
                                    <span data-chewron-id={item.id}>
                                        <HiChevronDown />
                                    </span>
                                </button>
                            </td>
                            {columns.map((column, index) => {
                                if(column) {
                                    return (
                                        <td className={`px-2 py-1 w-full lg:w-auto lg:table-cell text-left lg:text-center hidden lg:visible ${column?.borderLeft === true && ' lg:border-l lg:border-gray-300'} ${column?.borderRight === true && ' lg:border-r lg:border-gray-300'}`} data-id={item.id} key={`tableCell${item.id}_${index}`}>
                                            {column.headerMobileHidden === false && <span className="lg:hidden font-semibold">{column.name}: </span>}
                                            <span className='inline-block'>{column.selector(item)}</span>
                                        </td>
                                        )
                                }
                                
                            })}
                        </tr>)
                    })
                    : (<tr><td>Gösterilecek veri bulunamadı.</td></tr>)
                    }
            </tbody>          
        </table>
        {pagination !== null && 
            <Pagination pagination={pagination} pageMeta={pageMeta} />
        }
        {progressPending && <Loader />}
      </div>
  );
};

export default TableLayout