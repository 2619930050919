import Categories from 'modules/companyModule/pages/suppliers/settings/categories'
import Taxonomies from 'modules/companyModule/pages/suppliers/settings/taxonomies'

function SupplierSettings() {
    return (
        <div className="w-full mb-4">
          <h2 className="text-2xl text-gray-800 pb-2 mb-4 border-b border-gray-800 font-semibold">Tedarikçi Ayarları</h2>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-2 md:gap-x-4">
                <div className="col-span-1">
                    <Taxonomies />                    
                </div>
                <div className="col-span-1">
                    
                </div>
          </div>
      </div>
    )
}

export default SupplierSettings