import { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {InputFloatingLabel} from 'elements/inputs'
import {ButtonBlue} from 'elements/buttons'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import {LinkGray, LinkBlue} from 'elements/links'
import Loader from 'elements/Loader'

const ForgotPasswordForm = () => {

    const dispatch = useDispatch()
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: null,
        }
    })

    const loginInputs = [
        {
            key: "userInput1",
            type: "text",
            name: "email",
            placeholder: "E-Posta Adresi",
            label: "E-Posta Adresi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message,
            cols: 'col-span-12', 
        }
    ]

    const handleUserForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `forgot-password`, data)
                .then(res => {
                    if(res.status === 200) {
                        setStatus(res.data.status)
                        setError(null)
                    }                    
                })
                .catch(err => {
                    setError(err.response.data) 
                    setStatus(null)                   
                })
                .finally(() => {
                    setLoading(false)
                })
    }

    return (
        <div className="px-4 pb-4">
            <div className='mt-4'>
                <p className='text-gray-700 text-sm'>Parolanızı mı unuttunuz? Sorun değil. Sadece bize e-posta adresinizi bildirin ve size yeni bir tane seçmenize olanak verecek bir şifre sıfırlama bağlantısını e-posta ile gönderelim. </p>
            </div>
            <div className="text-red-500 mt-12">
                {error && <ServerError errorData={error} />}
            </div>
            {status &&  <ServerSuccess message={status} />}
            <form onSubmit={handleSubmit(handleUserForm)}>
                <div className="grid grid-cols-12 gap-3">
                {loginInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <InputFloatingLabel 
                                type={input.type}
                                name={input.name}
                                register={register}
                                label={input.label}
                                placeholder={input.placeholder}
                                validator={input.validator}
                                error={input.error}
                            />
                        </div>
                        )
                })}
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonBlue type="submit">Şifre Sıfırlama Linkini Gönder</ButtonBlue>
                </div>                
            </form> 
            <div className="mt-16 text-center">
                <p className="text-black-700">Giriş yapmak için <LinkBlue route="/login" title="tıklayın." /> </p>   
            </div>
            {loading && <Loader />}
        </div>
    )

}

export default ForgotPasswordForm