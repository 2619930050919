import { useState, useEffect } from 'react'
import { useOutletContext, useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' 
import { ButtonFilter, ButtonNew, ButtonExcel, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import Table from 'modules/companyModule/pages/warehouses/transferApprovals/Table'
import TableFilter from 'modules/companyModule/pages/warehouses/transferApprovals/TableFilter'
import Detail from 'modules/companyModule/pages/warehouses/transferApprovals/Detail'
import Approve from 'modules/companyModule/pages/warehouses/transferApprovals/Approve'
import Loader from 'elements/Loader'

function WarehouseTransferApprovals() {
    const dispatch = useDispatch()
    const [transfers, setTransfers] = useState([])
    const [taxonomies, setTaxonomies] = useState([])
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [transferForDetail, setTransferForDetail] = useState(null)
    const [transferForApproval, setTransferForApproval] = useState(null)
    const [loading, setLoading] = useState(false)

    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [selectedWarehouse, setSelectedWarehouse] = useOutletContext()

    const [updateData, setUpdateData] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null, taxonomies: {}})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation()

    useEffect(() => {
        const getTransfers = async () => {
            let page = searchParams.get("page")
            let {taxonomies, ...filterWithoutCategory} = filters 
            let filterParam = '&' + new URLSearchParams(filterWithoutCategory).toString()
            let categoryFilter = Object.fromEntries(Object.entries(filters.taxonomies).
                                filter(([key, val]) => val !== null && val !== ''))

            if(categoryFilter) {
                filterParam = filterParam + '&taxonomies=' + JSON.stringify(categoryFilter)
            }
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/transfer_approvals?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setTransfers(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTransfers()
    }, [selectedWarehouse, updateData, filters, search])

    useEffect(() => {
        const getTaxonomies = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/taxonomies?type=product`)
                .then(response => {
                    if(response.status === 200) {
                        setTaxonomies(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTaxonomies()
    }, [])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800 font-semibold">Stok Transfer İşlemleri</h2>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            taxonomies={taxonomies}
                            setSearchParams={setSearchParams}
                />

                <Table data={transfers} 
                        actions={{
                            setTransferForDetail: setTransferForDetail,
                            setTransferForApproval: setTransferForApproval,
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            <Detail 
                transfer={transferForDetail}
            />
            <Approve 
                transfer={transferForApproval}
                selectedWarehouse={selectedWarehouse}
                update={updateData}
                setUpdate={setUpdateData}
            />
        </div>
    )
}

export default WarehouseTransferApprovals