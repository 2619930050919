import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import { DateTime } from 'luxon'
import { ButtonOk, ButtonSave, ButtonNew} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FormatNumber from 'utils/FormatNumber'
import { setIsOpenWarehouseProductStockCorrectionAdd } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useStockCorrectionInput } from 'modules/companyModule/pages/warehouses/stocks/InputArray'

const StockCorrectionForm = ({product, update, setUpdate, selectedWarehouse}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)    
    const isOpenForm = useSelector(state => state.drawers.isOpenWarehouseProductStockCorrectionAdd)
    
    const [unitCodes, setUnitCodes] = useState([])
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const formDefaults = {
        correction_type: null,
        correction_date: DateTime.local().toFormat("yyyy-LL-dd"),
        description: null,
        quantity: null,
        unit_code_id: null,
    }
    const { register, handleSubmit, formState: { errors }, reset, watch, control, trigger, setValue, getValues } = useForm({
        defaultValues: formDefaults
    })

    const inputs = useStockCorrectionInput(errors, unitCodes)

    useEffect(() => {
        if(product && Object.keys(product).length > 0) {
            let unitCodeArray = [{
                key: product.unit.id,
                value: `${product.unit.description} (${product.unit.code})`
            }]            
            setUnitCodes(unitCodeArray)
        }        
    }, [product])

    useEffect(() => {
        if(unitCodes && Array.isArray(unitCodes) && unitCodes.length > 0) {
            setValue('unit_code_id', product.unit.id.toString())
        }        
    }, [unitCodes])

    useEffect(() => {
        if(!isOpenForm) {
            reset()
            setError(null)
            setStatus(false)
        }        
    }, [isOpenForm])

    const handleForm = async (data) => {
        // console.log(data)
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/products/${product.id}/stock_corrections`, data)
                .then(response => {
                    if(response.status === 201) {
                        reset({...formDefaults, unit_code_id: product.unit.id})
                        setStatus(true)
                        setError(null)
                        setUpdate(prev => !prev)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenWarehouseProductStockCorrectionAdd())
    }

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
    }

    const handleError = (error, e) => {
        
    }

    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy')
    }

    const StockCell = ({stocks}) => {
        return (
            <div className='flex flex-col justify-center'>
                {Array.isArray(stocks) && stocks.length > 0 ? 
                    stocks.map(s => {
                        return <p key={s.id}>{s.quantity} {s.unit.description}</p>
                    })
                : 
                    <span>Stok Yok</span>
                }                                       
            </div>
        )
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />     
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>         
            </>
            :
            (product && Object.keys(product).length > 0) ?                
                <form onSubmit={handleSubmit(handleForm, handleError)}>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Mevcut Stok Bilgileri</p>
                    <table className="w-full">
                        <tbody>
                            <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">İşlem Yapılan Ürün:</td>
                                <td className="px-2 py-3">{product.name}</td>
                            </tr>
                            <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">İşlem Yapılan Depo:</td>
                                <td className="px-2 py-3">{selectedWarehouse.warehouse_name} ({selectedWarehouse.warehouse_code})</td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Depodaki Stok Miktarı:</td>
                                <td className="px-2 py-3">
                                    <StockCell stocks={product.warehouse_stocks} />
                                </td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Toplam Stok Miktarı:</td>
                                <td className="px-2 py-3">
                                <StockCell stocks={product.stocks} />
                                </td>
                            </tr>
                        </tbody>
                    </table>     
                    <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Yeni Stok Düzenlemesi Ekle</p>
                    <div className="grid grid-cols-12 gap-2">
                    {inputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                {input.type === 'select' ? 
                                <SelectSmall register={register} {...input} rows="5" /> 
                                : <InputSmall register={register} {...input} /> 
                                }
                            </div>
                            )
                    })}
                    </div>               
                    <div className="w-full flex justify-end mt-5">
                        <ButtonSave type="submit">
                        </ButtonSave>
                    </div>          
                </form> 
                : null }
            
        {loading && <Loader />}
        </>
    )
}

export default StockCorrectionForm