import { useState } from "react"
import { useForm } from "react-hook-form"
import { useParams, useSearchParams } from 'react-router-dom'
import {InputFloatingLabel} from 'elements/inputs'
import {ButtonBlue} from 'elements/buttons'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import {LinkBlue} from 'elements/links'
import Loader from 'elements/Loader'

const ResetPasswordForm = () => {

    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const { register, reset, handleSubmit, getValues, formState: { errors } } = useForm({
        defaultValues: {
            password: null,
            password_confirmation: null
        }
    })

    const loginInputs = [
        {
            key: "userInput4",
            type: "password",
            name: "password",
            placeholder: "Şifre",
            label: "Şifre *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},                
            },
            error: errors?.password?.message,
            cols: 'col-span-12',
        },
        {
            key: "userInput5",
            type: "password",
            name: "password_confirmation",
            placeholder: "Şifre Tekrar",
            label: "Şifre Tekrar*",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},   
                validate: {
                    confirmation: v => v === getValues("password") || 'Girmiş olduğunuz şifreler eşleşmiyor'
                }              
            },
            error: errors?.password_confirmation?.message,
            cols: 'col-span-12',
        },
    ]

    const handleUserForm = async (data) => {
        setLoading(true)
        data.token = params.token
        data.email = searchParams.get("email")
        await SendRequest('POST', `reset-password`, data)
                .then(res => {
                    if(res.status === 200) {
                        setStatus(res.data.status)
                        setError(null)
                    }                    
                })
                .catch(err => {
                    setError(err.response.data) 
                    setStatus(null)                   
                })
                .finally(() => {
                    setLoading(false)
                    reset()
                })
    }

    return (
        <div className="px-4 pb-4">
            <div className="text-red-500 mt-12">
                {error && <ServerError errorData={error} />}
            </div>
            {status &&  <ServerSuccess message={status} />}
            <form onSubmit={handleSubmit(handleUserForm)}>
                <div className="grid grid-cols-12 gap-3">
                {loginInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <InputFloatingLabel 
                                type={input.type}
                                name={input.name}
                                register={register}
                                label={input.label}
                                placeholder={input.placeholder}
                                validator={input.validator}
                                error={input.error}
                            />
                        </div>
                        )
                })}
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonBlue type="submit">Şifreyi Sıfırla</ButtonBlue>
                </div>                
            </form> 
            <div className="mt-16 text-center">
                <p className="text-black-700">Giriş yapmak için <LinkBlue route="/login" title="tıklayın." /> </p>   
            </div>
            {loading && <Loader />}
        </div>
    )

}

export default ResetPasswordForm