import { useEffect, useState } from 'react'
import SendRequest from 'utils/SendRequest'
import { useDispatch, useSelector } from 'react-redux'
import { login } from 'stores/UserSlice'

const UserWrapper = ({children}) => {

    const [status, setStatus] = useState({authenticated: null, user: null})

    const dispatch = useDispatch()

    useEffect(() => {
        const getUserData = async () => {
            await SendRequest('GET', 'sanctum/csrf-cookie')
            .then(res => {
                if (res.status === 204) {                
                    SendRequest('GET', 'api/user')
                    .then(res => {
                        dispatch(login({
                                user: res.data.data, 
                                authenticated: true, 
                                emailVerified: res.data.data.verified, 
                                userType: res.data.data.type
                            }))
                    }) 
                    .catch(err => {
                        dispatch(login({
                            user: null, 
                            authenticated: false, 
                            emailVerified: false, 
                            userType: null
                        }))
                    })               
                }
            })
            .catch(err => {
                dispatch(login({
                    user: null, 
                    authenticated: false, 
                    emailVerified: false, 
                    userType: null
                }))
            })  
        }
        getUserData().catch(err => console.log(err)) 
    }, [])

    return children
}

export default UserWrapper