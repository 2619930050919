import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import { IconContext } from "react-icons"
import { FaSearch, FaTrashAlt, FaRegEdit, FaSlidersH, FaRegCopy } from 'react-icons/fa'
import { setIsOpenProductEdit, setIsOpenProductDelete, setIsOpenProductDetail } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton 
                    title='Düzenle' 
                    icon={<FaRegEdit />} 
                    onClick={() => {
                        actions.setProductForEdit(data)
                        dispatch(setIsOpenProductEdit(true))
                    }}
                />
                <TableButton 
                    title='Ürün Detayları' 
                    icon={<FaSearch />} 
                    onClick={() => {
                        actions.setProductForDetail(data)
                        dispatch(setIsOpenProductDetail(true))
                    }}
                />
                <TableButton 
                    title='İşlem Geçmişi' 
                    icon={<FaRegCopy />} 
                    onClick={() => {
                        navigate('/company/products/'+data.id)
                    }}
                />
                <TableButton 
                    title='Sil' 
                    icon={<FaTrashAlt />} 
                    onClick={() => {
                        actions.setProductForDelete(data)
                        dispatch(setIsOpenProductDelete(true))
                    }}
                />
                </IconContext.Provider>
            </div>
        )
    }    
    
    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
