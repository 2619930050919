import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import ProjectTable from 'modules/companyModule/pages/suppliers/settings/taxonomies/ProjectTable'
import { ButtonNew, ButtonNewSmall } from 'elements/buttons/actionButtons'
import { setIsOpenCreate } from 'modules/companyModule/pages/suppliers/settings/taxonomies/DrawersSlice'
import Create from 'modules/companyModule/pages/suppliers/settings/taxonomies/Create'
import Edit from 'modules/companyModule/pages/suppliers/settings/taxonomies/Edit'
import Delete from 'modules/companyModule/pages/suppliers/settings/taxonomies/Delete'
import {default as TaxonCreate} from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/Create'
import {default as TaxonEdit} from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/Edit'
import {default as TaxonDelete} from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/Delete'
import TaxonomyTable from 'modules/companyModule/pages/suppliers/settings/taxonomies/TaxonomyTable'
import Loader from 'elements/Loader'

const Taxonomies = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [taxonomies, setTaxonomies] = useState([])
    const [taxonomyForEdit, setTaxonomyForEdit] = useState(null)
    const [taxonomyForDelete, setTaxonomyForDelete] = useState(null)
    const [taxonomyForTaxon, setTaxonomyForTaxon] = useState(null)
    const [taxonForEdit, setTaxonForEdit] = useState(null)
    const [taxonForDelete, setTaxonForDelete] = useState(null)
    const [parent, setParent] = useState(null)

    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)

    useEffect(() => {
        const getTaxonomies = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/taxonomies?type=supplier`)
                .then(response => {
                    if(response.status === 200) {
                        setTaxonomies(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getTaxonomies()
    }, [updateData])

    return (
        <div className="w-full mb-4">   
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800">Sınıflandırmalar</h2>
                <ButtonNew label="Yeni Sınıflandırma" onClick={() => {dispatch(setIsOpenCreate(true))}}>
                </ButtonNew>
            </div>         
            <div className=""> 
                <TaxonomyTable 
                    taxonomies={taxonomies} 
                    parent={parent}
                    actions={{
                        setTaxonomyForEdit: setTaxonomyForEdit,
                        setTaxonomyForDelete: setTaxonomyForDelete,
                        setTaxonomyForTaxon: setTaxonomyForTaxon,
                        setTaxonForEdit: setTaxonForEdit,
                        setTaxonForDelete: setTaxonForDelete,
                        setParent: setParent,
                    }}
                    update={updateData} 
                    setUpdate={setUpdateData}
                />      
            </div>
            <Create update={updateData} setUpdate={setUpdateData} />
            <Edit taxonomy={taxonomyForEdit} update={updateData} setUpdate={setUpdateData} />
            <Delete taxonomy={taxonomyForDelete} update={updateData} setUpdate={setUpdateData} />
            <TaxonCreate parent={parent} taxonomy={taxonomyForTaxon} update={updateData} setUpdate={setUpdateData} />
            <TaxonEdit parent={parent} taxonomy={taxonomyForTaxon} taxon={taxonForEdit} update={updateData} setUpdate={setUpdateData} />
            <TaxonDelete parent={parent} taxonomy={taxonomyForTaxon} taxon={taxonForDelete} update={updateData} setUpdate={setUpdateData} />
            {loading && <Loader />}
        </div>
    )
}

export default Taxonomies