import { useState, useEffect } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' 
import { setIsOpenProductDemandCreate } from 'stores/DrawersSlice'
import { ButtonFilter, ButtonNew, ButtonExcel, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import Create from 'modules/companyModule/pages/productDemands/demands/Create'
import Cancel from 'modules/companyModule/pages/productDemands/demands/Cancel'
import Detail from 'modules/companyModule/pages/productDemands/demands/Detail'
import UpdateStatus from 'modules/companyModule/pages/productDemands/demands/UpdateStatus'
import DeliveryApprove from 'modules/companyModule/pages/productDemands/demands/DeliveryApprove'
import Table from 'modules/companyModule/pages/productDemands/demands/Table'
import TableFilter from 'modules/companyModule/pages/productDemands/demands/TableFilter'

function ProductDemands() {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const user = useSelector(state => state.user.user)
    const [updateData, setUpdateData] = useState(false)
    
    const [productDemands, setProductDemands] = useState([])
    const [productDemandForStatusUpdate, setProductDemandForStatusUpdate] = useState(null)
    const [productDemandForCancel, setProductDemandForCancel] = useState(null)
    const [productDemandForDetail, setProductDemandForDetail] = useState(null)
    const [productDemandForDeliveryApprove, setProductDemandForDeliveryApprove] = useState(null)
    const [productDemandForCopy, setProductDemandForCopy] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation()

    useEffect(() => {
        const getProductDemands = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/product_demands?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setProductDemands(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProductDemands()
    }, [updateData, filters, search])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Ürün Talepleri</h2>
                <ButtonNew label="Yeni Talep" onClick={() => {setProductDemandForCopy(null); dispatch(setIsOpenProductDemandCreate(true))}} >
                </ButtonNew>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                            products={[]}
                />

                <Table data={productDemands} 
                        actions={{
                            setProductDemandForStatusUpdate: setProductDemandForStatusUpdate,
                            setProductDemandForCancel: setProductDemandForCancel,
                            setProductDemandForDetail: setProductDemandForDetail,
                            setProductDemandForDeliveryApprove: setProductDemandForDeliveryApprove,
                            setProductDemandForCopy: setProductDemandForCopy,
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            <Create 
                update={updateData} 
                setUpdate={setUpdateData} 
                productDemand={productDemandForCopy}
            />
            <Cancel 
                update={updateData} 
                setUpdate={setUpdateData} 
                productDemand={productDemandForCancel} 
            />
            <DeliveryApprove 
                update={updateData} 
                setUpdate={setUpdateData} 
                productDemand={productDemandForDeliveryApprove} 
            />
            <UpdateStatus 
                update={updateData} 
                setUpdate={setUpdateData} 
                productDemand={productDemandForStatusUpdate} 
            />
            <Detail productDemand={productDemandForDetail} />
            {loading && <Loader />}
        </div>
    )
}

export default ProductDemands