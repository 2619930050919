import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonClose, ButtonPrint } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierInfoForm } from 'stores/DrawersSlice' 
import {ServerError} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { CardLoader } from 'elements/loaders'

const InfoFormForm = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const isOpenDetail = useSelector(state => state.drawers.isOpenSupplierInfoForm)
    const frameRef = useRef();
    const [height, setHeight] = useState("0px");
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const [htmlData, setHtmlData] = useState(false)
    const [updateData, setUpdateData] = useState(false)
    
    useEffect(() => {
        
    }, [updateData])

    useEffect(() => {
        if(isOpenDetail) {
            const getHtmlData = async () => {
                if(companyId) {
                    setLoading(true)
                        await SendRequest('GET', `api/suppliers/${companyId}/info_form`)
                        .then(response => {
                            if(response.status === 200) {
                                setHtmlData(response.data)
                                setStatus(true)
                                setError(null)
                            } else {
                                setHtmlData(null)
                                setError('Sunucu Hatası')
                                setStatus(null)
                            }                        
                        })
                        .catch(err => {
                            setHtmlData(null)
                            setError(err.response.data)
                            setStatus(null)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                } else {
                    setHtmlData(null)
                    setError('Tedarikçi bilgisi bulunamadı')
                    setStatus(null)
                }            
            }
            getHtmlData()
        } else {
            setHtmlData(null)
            setError(null)
            setStatus(null)
        }
    }, [isOpenDetail])

    const close = () => {
        dispatch(setIsOpenSupplierInfoForm())
    }

    const onLoadIframe = () => {
        setHeight((document.body.scrollHeight - 200) + "px")
    }

    const printDoc = () => {
        frameRef.current.contentWindow.print()
    }

    return (
        <div className="relative">  
            <div className="flex-grow">
                <div className="text-red-500 mt-4">
                    {error ? <ServerError errorData={error} /> : null}
                </div>
                {status ? 
                    <iframe ref={frameRef} 
                            onLoad={onLoadIframe}
                            srcDoc={htmlData} 
                            height={height}
                            width="100%"
                            style={{maxWidth: '900px', width: '100%'}}></iframe>
                : null}
                
            </div>
            <div className="flex-none w-full flex justify-end gap-x-4 mt-5">
                <ButtonPrint type="button" onClick={printDoc}>
                </ButtonPrint>
                <ButtonClose type="button" onClick={close}>
                </ButtonClose>
            </div>         
            {loading ? <CardLoader /> : null}      
        </div>
    )
}

export default InfoFormForm