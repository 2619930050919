import React from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import { IconContext } from "react-icons"
import { FaSearch, FaCheckSquare, FaSlidersH } from 'react-icons/fa'
import { setIsOpenDepartmentProcurementRequestApprovalDetail, setIsOpenDepartmentProcurementRequestApprovalApprove } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector(state => state.user.user.id)

    const actionMade = () => {
        let myApproval = data.approvals.filter(approval => {
            return approval.user.id == userId
        })
        if(myApproval.length > 0) {
            if(myApproval[0].is_accepted) return false
            if(myApproval[0].is_rejected) return false
            return true
        }
        return false
    }
    
    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton 
                    title='Talep Detayları' icon={<FaSearch />} 
                    onClick={() => {
                        actions.setProcurementRequestsForDetail(data)
                        dispatch(setIsOpenDepartmentProcurementRequestApprovalDetail(true))
                        }}
                />
                {(data.status === 'waiting_approvals' && actionMade()) ?
                <> 
                <TableButton 
                    title='Onay İşlemleri' 
                    icon={<FaCheckSquare />} 
                    borderTop={true} 
                    onClick={() => {
                        actions.setProcurementRequestsForAction(data)
                        dispatch(setIsOpenDepartmentProcurementRequestApprovalApprove(true))
                        }}
                    />
                </>
                : null }
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
