import { useContactTypes } from 'data'

function ContactInfo({contact}) {
    const types = useContactTypes()

    return (
        <div className="">
                {(contact !==null && contact !== undefined && Object.keys(contact).length > 0) &&
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Yetkili Türü:</td>
                            <td className="px-2 py-3">
                                {types.find(type => type.key === contact.type)?.value}
                            </td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Ad Soyad:</td>
                            <td className="px-2 py-3">{contact.name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Görevi:</td>
                            <td className="px-2 py-3">{contact.position}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Cep Telefonu:</td>
                            <td className="px-2 py-3">{contact.mobile_phone}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">İş Telefonu:</td>
                            <td className="px-2 py-3">{contact.office_phone}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">E-Posta:</td>
                            <td className="px-2 py-3">{contact.email}</td>
                        </tr>
                    </tbody>
                </table>}
            </div>  
    )
}

export default ContactInfo