function ProjectInfo({project}) {

    return (
        <div className="">
                {(project !==null && project !== undefined && Object.keys(project).length > 0) &&
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Proje/Firma Adı:</td>
                            <td className="px-2 py-3">{project.project_name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Proje Açıklaması:</td>
                            <td className="px-2 py-3">{project.description}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Projeye Başlanan Yıl:</td>
                            <td className="px-2 py-3">{project.year}</td>
                        </tr>
                    </tbody>
                </table>}
            </div>  
    )
}

export default ProjectInfo