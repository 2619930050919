import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import {ButtonClose, ButtonNext, ButtonPrevious} from 'elements/buttons/actionButtons'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

function ApprovalList({currentStep, setCurrentStep, procurementData, setProcurementData}) {
    const dispatch = useDispatch()
    const isOpen = useSelector((state) => state.drawers.isOpenProcurementCreate) 
    const companyId = useSelector((state) => state.company.selectedCompany.id)      
    
    const [approvalData, setApprovalData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(procurementData?.cost_type_id) {
            const getApprovalData = async () => {
                setLoading(true)
                await SendRequest('GET', `api/companies/${companyId}/cost_types/${procurementData.cost_type_id}/procurement_approval_users`)
                    .then(response => {
                        if(response.data.data.length > 0) {
                            setApprovalData(response.data.data)
                        } else {
                            setApprovalData([])
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setApprovalData([])
                        setLoading(false)
                    })
            }    
            getApprovalData()
        }
    }, [])

    const close = () => {
        dispatch(setIsOpenProcurementCreate())
    }

    const handleNext = async (data) => {
        setProcurementData(prev => {
            return {...prev, approvalData: approvalData}
        })
        setCurrentStep(prev => prev+1)
    }    

    return (  
            <> 
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-8rem)]">
                <div className="w-full overflow-y-auto px-0.5">
                <table className="w-full">
                        <thead>
                            <tr className="border-b border-gray-300 font-normal">
                                <th className="px-1 py-0.5 font-normal text-left">#</th>
                                <th className="px-1 py-0.5 font-normal text-left">Onay Sırası</th>
                                <th className="px-1 py-0.5 font-normal text-left">Onaylayacak Yönetici</th>                                
                                <th className="px-1 py-0.5 font-normal text-left">Pozisyon</th>
                            </tr>
                        </thead>
                        <tbody>
                            {approvalData != null && Array.isArray(approvalData) && approvalData.length > 0  ?
                                approvalData
                                .sort((a,b) => parseInt(a.approval_sequence) - parseInt(b.approval_sequence))
                                .map((approval, index) => {
                                    return (
                                        <tr key={`approval_${approval.id}`} className="border-b border-gray-300 even:bg-gray-200">
                                            <td className="px-1 py-0.5">{index+1}.</td>
                                            <td className="px-1 py-0.5">{approval.approval_sequence}</td>
                                            <td className="px-1 py-0.5">{approval.approval_user.name}</td>
                                            <td className="px-1 py-0.5 whitespace-pre-line">
                                            {approval.approval_user.departments
                                            .map(department => 
                                                `${department.position.position_name} - ${department.department.department_name}`).join(', \r\n')} 
                                            </td>
                                        </tr>
                                    )
                                })
                            : 
                                <tr className="border-b border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5" colSpan={4}>Onaylaycak yönetici bulunmuyor</td>
                                </tr>                            
                            }               
                        </tbody>
                    </table>
                </div>
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonClose onClick={() => close()}>
                    </ButtonClose>
                    <ButtonPrevious onClick={() => setCurrentStep(prev => prev-1)}>
                    </ButtonPrevious>
                    <ButtonNext onClick={handleNext}>
                    </ButtonNext>                  
                </div>         
            </div>
            {loading && <Loader />}
            </>
    )

}

export default ApprovalList