function DepartmentsTable({user}) {
    if(user !== null) {
        return (         
            <table className="w-full text-sm">
                <thead>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <th>Departman</th>
                        <th>Pozisyon</th>
                    </tr>
                </thead>
                <tbody>
                    {user.departments.length > 0 ? 
                        user.departments.map(department => {
                            return (
                            <tr key={department.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">{department.department.department_name}</td>
                                <td className="px-2 py-3">{department.position.position_name} (Seviye: {department.position.level})</td>
                            </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan="2">Kayıtlı departman bilgisi bulunamadı.</td>
                        </tr>
                    }
                </tbody>
            </table>       
        )
    }
    return null
    
}

export default DepartmentsTable