import { IconContext } from "react-icons"
import { FaRegQuestionCircle, FaRegCheckCircle, FaExclamationTriangle } from 'react-icons/fa'
import { MdDownloading } from 'react-icons/md'

function LoadingAlert({loadCount, total}) {
    return (
        <p className="mb-2 w-full flex items-center justify-start gap-x-2 p-2 border border-sky-500 text-base text-sky-700 rounded-md">
            <IconContext.Provider value={{ className: "h-5 w-5 animate-spin" }}>
                <MdDownloading />
            </IconContext.Provider>
            {`İşleminiz yapılıyor. Lütfen bekleyiniz. Kalan işlem: ${loadCount}/${total}`}
        </p>
    )
}

function UploadAlert() {
    return (
        <p className="mb-2 w-full flex items-center justify-start gap-x-2 p-2 border border-gray-500 text-base text-gray-700 rounded-md">
            <IconContext.Provider value={{ className: "h-5 w-5" }}>
                <FaRegQuestionCircle />
            </IconContext.Provider>
            Bilgiler excel dosyasından başarıyla alınmıştır. Kontrol ettikten sonra yüklemek için İçe Aktar butonunu kullanabilirsiniz.
        </p>
    )
}

function ErrorAlert() {
    return (
        <p className="mb-2 w-full flex items-center justify-start gap-x-2 p-2 border border-rose-500 text-base text-rose-700 rounded-md">
            <IconContext.Provider value={{ className: "h-5 w-5" }}>
                <FaExclamationTriangle />
            </IconContext.Provider>
            Yüklenen bilgilerde hata bulundu. Hatalı bilgiler aşağıda kırmızı renkle gösterilmiştir. Lütfen düzeltip tekrar yükleyiniz.
        </p>
    )
}

function SuccessAlert() {
    return (
        <p className="mb-2 w-full flex items-center justify-start gap-x-2 p-2 border border-emerald-500 text-base text-emerald-700 rounded-md">
            <IconContext.Provider value={{ className: "h-5 w-5" }}>
                <FaRegCheckCircle />
            </IconContext.Provider>
            Bilgiler başarıyla içeri aktarılmıştır.
        </p>
    )
}

export {
    LoadingAlert,
    UploadAlert,
    ErrorAlert,
    SuccessAlert
}