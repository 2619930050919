import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenCostTypeEdit } from 'stores/DrawersSlice' 
import EditCostTypeForm from 'modules/companyModule/pages/companies/settings/costTypes/EditCostTypeForm'

function EditCostType({update, setUpdate, costType}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenCostTypeEdit)
    const setIsOpenCreate = () => dispatch(setIsOpenCostTypeEdit())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Gider Türünü Düzenle">
            <EditCostTypeForm 
                update={update} 
                setUpdate={setUpdate} 
                costType={costType}
            />
        </FormDrawer> 
    )
}

export default EditCostType