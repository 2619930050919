import { useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { IconContext } from "react-icons"
import { HiChevronDown } from 'react-icons/hi'

function Dropdown({children, avatar, title}) {
    const [open, setOpen] = useState(false)
    const closeDropdown = () => {setOpen(false)}
    const ref = useDetectClickOutside({ onTriggered: closeDropdown })

    return (
        <div className="inline-block px-3 relative h-full flex items-center">
            <button onClick={(e) => {e.preventDefault(); e.stopPropagation(); setOpen(!open)}} className="flex items-center justify-between relative rounded cursor-pointer">
            <span className="flex items-center gap-x-2" >
                    <img src={avatar} alt="avatar" className="h-[36px] w-[36px] rounded-full" />
                    <span className="hidden md:inline">{title}</span>
                    <IconContext.Provider value={{ className: "h-4 w-4" }}>
                    <span className={open ? 'rotate-180' : 'rotate-0'}>
                        <HiChevronDown/>
                    </span>
                    </IconContext.Provider>
                </span>
            </button>
            { open &&
                <div ref={ref} className="z-10 absolute bg-white right-0 origin-top-right mt-1 w-auto rounded-lg shadow-lg left-auto min-w-[150px] top-full">
                    {children}
                </div>
            }
            
        </div>
    )
}

export default Dropdown