import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementProposalCancel } from 'stores/DrawersSlice' 
import ProposalCancelForm from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/ProposalCancelForm'

function ProposalCancel({procurement, proposalData, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCancel = useSelector(state => state.drawers.isOpenProcurementProposalCancel)
    const setIsOpenCancel = () => dispatch(setIsOpenProcurementProposalCancel())
    
    return(
        <FormDrawer isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} title="Kabulü İptal Et" maxWidth="max-w-4xl">
            <ProposalCancelForm procurement={procurement} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default ProposalCancel