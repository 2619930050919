import { useEffect, useState } from 'react'
import { BiFile } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { IconContext } from "react-icons"
import { TbRefresh } from 'react-icons/tb'
import SendRequest from 'utils/SendRequest'
import { ButtonList } from 'elements/buttons/actionButtons'
import { TablePlaceholder } from 'elements/loaders'
import Card from 'elements/cards'

function LatestProcurements() {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [procurements, setProcurements] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        const getProcurements = async () => {
            let page = '1'
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/procurements?page=${page}`)
                .then(response => {
                    if(response.status === 200) {
                        setProcurements(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProcurements()
    }, [updateData])

    const RefreshButton = () => {
        return (
            <IconContext.Provider value={{ className: "h-4 w-4 flex-none" }}>
            <button onClick={() => setUpdateData(prev => !prev)}><TbRefresh /></button>
            </IconContext.Provider>
        )
    }

    useEffect(() => {   
        const interval = setInterval(() => {
            setUpdateData(prev => !prev)
        }, 15*60*1000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <Card 
            title='Son Yayınlanan İlanlar' 
            icon={<BiFile />} 
            footer={<ButtonList onClick={() => navigate('/supplier/procurements/')} />}
            titleButton={<RefreshButton />}
        >        
            <table className="w-full">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-2 text-left">Firma</th>
                        <th className="py-1 px-2 text-left">İlan Başlığı</th>
                        <th className="py-1 px-2 text-left">Sektör</th>
                        <th className="py-1 px-2 text-left">Bitiş Tarihi</th>
                    </tr>
                </thead>
                {loading ? 
                    <TablePlaceholder lineCount={10} colspan={4} />
                : 
                <tbody>
                    {procurements.map(procurement => {
                        return (
                            <tr key={procurement.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">{procurement.company.company_name}</td>
                                <td className="px-2 py-3">{procurement.title}</td>
                                <td className="px-2 py-3">{procurement.business_area.name}</td>
                                <td className="px-2 py-3">{DateTime.fromISO(procurement.end_date).toFormat('dd.LL.yyyy T')}</td>
                            </tr>
                        )
                    })}
                </tbody>
                }
            </table>
        </Card>
    )
}

export default LatestProcurements