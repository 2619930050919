import { useContactTypes } from 'data'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonEditSmall, ButtonDeleteSmall } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierReferenceEdit, setIsOpenSupplierReferenceDelete } from 'stores/DrawersSlice'
import FormatNumber from 'utils/FormatNumber'

function ReferenceTable({references, actions}) {
    const dispatch = useDispatch()
    if(references !== null && Array.isArray(references)) {
        return (         
            <table className="w-full text-xs">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-1 text-left">Firma Adı</th>
                        <th className="py-1 px-1 text-left">Faaliyet Alanı</th>
                        <th className="py-1 px-1 text-left">Çalışılan Süre (Yıl)</th>
                        <th className="py-1 px-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {references.length > 0 ?
                        references.map(reference => {
                            return (
                                <tr key={reference.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">{reference.company_name}</td>
                                    <td className="px-2 py-3">{reference.business_area}</td>
                                    <td className="px-2 py-3">{FormatNumber(reference.duration)}</td>
                                    <td className="px-2 py-3">
                                        <div className="flex items-center justify-end gap-x-1">
                                            <ButtonEditSmall 
                                                label={null} 
                                                onClick={() => {
                                                    actions.setReferenceForEdit(reference)
                                                    dispatch(setIsOpenSupplierReferenceEdit(true))
                                                }}    
                                            />
                                            <ButtonDeleteSmall 
                                                label={null} 
                                                onClick={() => {
                                                    actions.setReferenceForDelete(reference)
                                                    dispatch(setIsOpenSupplierReferenceDelete(true))
                                                }}    
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan={4}>Referans bilgisi bulunamadı</td>
                        </tr>
                    }
                </tbody>
            </table>       
        )
    }
    return null
    
}

export default ReferenceTable