import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux' 
import SendRequest from 'utils/SendRequest'
import DocumentTable from 'modules/supplierModule/pages/companies/documents/DocumentTable'
import Loader from 'elements/Loader'
import Header from 'modules/supplierModule/pages/companies/Header'

function CompanyDocuments() {
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [documentList, setDocumentList] = useState([])
    const [documents, setDocuments] = useState([])

    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)
    
    useEffect(() => {
        const getDocumentList = async () => {
            setLoading(true)
            await SendRequest('GET', `api/supplier_document_lists`)
                .then(response => {
                    if(response.status === 200) {
                        setDocumentList(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getDocumentList()
    }, [])

    useEffect(() => {
        const getDocuments = async () => {
            setLoading(true)
            await SendRequest('GET', `api/suppliers/${companyId}/documents`)
                .then(response => {
                    if(response.status === 200) {
                        setDocuments(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getDocuments()
    }, [updateData])

    return (
        <div className="w-full mb-4">            
            <div className="">
                <Header title="Şirket Dokumanları" />
                <DocumentTable documentList={documentList} documents={documents} setUpdate={setUpdateData} />
            </div>
            {loading && <Loader />}
        </div>
    )
}

export default CompanyDocuments