import { GreenSmall, OrangeSmall, RedSmall } from "elements/infoCards" 

function TopCards({data, update, setUpdate, loading}) {
    const placeholderData = {
        waiting_approvals: data?.waiting_approvals || 0,
        waiting_issuance: data?.waiting_issuance || 0,
        uncompleted: data?.uncompleted || 0,
    }
    
    return (
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-4">
            <GreenSmall 
                title="Onay Bekleyenler"
                content={`${placeholderData.waiting_approvals} Adet`}
            />
            <OrangeSmall 
                title="Yayınlanmayı Bekleyenler"
                content={`${placeholderData.waiting_issuance} Adet`}
            />
            <RedSmall 
                title="Süreci Devam Edenler"
                content={`${placeholderData.uncompleted} Adet`}
            />
        </div>
    )
}

export default TopCards