import { Fragment } from 'react'
import { DateTime } from 'luxon'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'
import { ProcurementPaymentTypes } from 'data'

function DetailInfoForProposal({proposal, procurement}) {
    const formatDate = (date) => {
        let parsedDate = DateTime.fromISO(date)
        if(parsedDate.isValid) return parsedDate.toFormat('dd.LL.yyyy T')
        return 'Belirlenmedi'
    }

    const StatusCell = ({l}) => {
        if(l.is_accepted) {
            return <span className="text-emerald-600 font-semibold whitespace-nowrap">Kabul Edildi.</span>
        }
        if(l.is_cancelled) {
            return <span className="text-red-600 font-semibold whitespace-nowrap">İptal Edildi</span>
        }
        if(l.is_revised) {
            return <span className="text-orange-600 font-semibold whitespace-nowrap">Revize Edildi</span>
        }
        return ''
        // return <span className="text-gray-600 font-semibold whitespace-nowrap">İşlem Yapılmadı</span>
                   
    }

    const paymentTypeDesc = (paymentType, deferralDays) => {
        let desc = ''
        const selectedCode = ProcurementPaymentTypes().filter(a => {
            return a.key == paymentType
        })
        if(selectedCode.length > 0 ) {
            desc = selectedCode[0].value
        }
        return `${desc} ${paymentType === 'deferred' ? `(${deferralDays} Gün)` : ''}`
    }    

    const SupplierInfo = ({pr}) => {
        return (
            <table className="w-full">
                <tbody>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-1">Firma:</td>
                        <td className="px-2 py-1">{pr.supplier.company_name}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-1">Vergi No / Vergi Dairesi:</td>
                        <td className="px-2 py-1">{pr.supplier.tax_number} / {pr.supplier.tax_department}</td>
                    </tr>
                    <tr className="border-b border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-1">E-Posta/Telefon: </td>
                        <td className="px-2 py-1">{pr.supplier.email} / {pr.supplier.phone}</td>
                    </tr>                     
                </tbody>
            </table>
        )
    }

    const GeneralInfo = ({pr}) => {
        return (
            <table className="w-full">
                <tbody>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-1">Teklif Tarihi:</td>
                        <td className="px-2 py-1">{formatDate(pr.created_at)}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-1">Açıklama:</td>
                        <td className="px-2 py-1 whitespace-pre-line">{pr.description}</td>
                    </tr>                                          
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-1">Ödeme:</td>
                        <td className="px-2 py-1 whitespace-pre-line">{paymentTypeDesc(pr.payment_type, pr.deferral_days)}</td>
                    </tr>                                          
                </tbody>
            </table>
        )
    }

    
    return (
        <div className="">
                {(proposal !==null && Array.isArray(proposal) && proposal.length > 0) &&
                proposal.map(p => {
                    if(p !== null && typeof p === 'object' && Object.keys(p).length > 0) {
                        return (
                            <div key={p.id} className="p-2 border border-gray-600 mb-2">
                                <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4'>
                                    <div>
                                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Firma Bilgileri</p>
                                        <SupplierInfo pr={p} />
                                    </div>
                                    <div>
                                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Teklif Genel Bilgileri</p>
                                        <GeneralInfo pr={p} />
                                    </div>
                                </div>
                                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>
                                <table className="w-full">
                                    <thead>
                                        <tr className="border-b border-gray-300 text-left">
                                            <th className="px-1 py-0.5">Ürün</th>
                                            <th className="px-1 py-0.5">Miktar</th>
                                            <th className="px-1 py-0.5">Teklif Açıklaması</th>
                                            <th className="px-1 py-0.5">Fiyat</th>
                                            {p.is_accepted ? 
                                                <th className="px-1 py-0.5">Durum</th>
                                                : null
                                            }   
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {p.lines.map(line => {
                                            return (
                                                <tr key={line.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                                    <td className="px-1 py-0.5">{procurement.lines.filter(l => l.id == line.procurement_line.id)[0].product.name}</td>
                                                    <td className="px-1 py-0.5">{procurement.lines.filter(l => l.id == line.procurement_line.id)[0].quantity} {procurement.lines.filter(l => l.id == line.procurement_line.id)[0].unit_code.description}</td>
                                                    <td className="px-1 py-0.5">{line.description_text}</td>
                                                    <td className="px-1 py-0.5">{FNWS(line.price)} {p.currency.code}</td>
                                                    {p.is_accepted ? 
                                                        <td className="px-1 py-0.5">
                                                            <StatusCell l={line} />
                                                        </td>
                                                        : null
                                                    } 
                                                </tr>
                                            )
                                        })}
                                        <tr className="border-b border-t border-gray-300 even:bg-gray-200 text-amber-600">
                                            <td className="px-1 py-0.5" colSpan={2}></td>
                                            <td className="px-1 py-0.5 font-bold">Toplam</td>
                                            <td className="px-1 py-0.5 font-bold">{FNWS(p.lines.reduce((p,v) => {return p + v.price}, 0))} {p.currency.code}</td>
                                            {p.is_accepted ? 
                                                <td className="px-1 py-0.5"></td>
                                                : null
                                            } 
                                        </tr>
                                    </tbody>
                                </table>             
                            </div>
                        )
                    }                    
                })
                }
            </div>  
    )
}

export default DetailInfoForProposal