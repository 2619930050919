import TableActions from 'modules/companyModule/pages/reports/productTypes/TableActions'
import TableLayout from 'elements/tables'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

const Table = ({data, progressPending, pagination, pageMeta}) => {

    const columns = [
        {
            name: 'Ürün Türü',
            selector: (row) => {return row.name},
            headerMobileHidden: false,
        },
        {
            name: 'Tamamlanan Satın Alma',
            selector: (row) => {return row.total_procurements},
            headerMobileHidden: false,
        },
        {
            name: 'Tamamlanan Satın Alma Tutarı',
            selector: (row) => {return row.price.map(item => FNWS(item.price) + ' ' + item.currency.code).join(' + ')},
            headerMobileHidden: false,
        },        
    ];

    const mobileHeader = (row) => {return row.name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
