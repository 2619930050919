import {useEffect, useState, useRef} from 'react'
import { DateTime } from 'luxon'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { FaChartPie } from 'react-icons/fa'
import { useRandomColors } from 'data/colors'
import { useSelector } from 'react-redux'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'
import Card from 'elements/cards'
import SendRequest from 'utils/SendRequest'
import { CardLoader } from 'elements/loaders'
import { useMonths, useYears } from 'data'
import { DemandStatus } from 'data'

function StatusChart({data, update, setUpdate, loading}) {
    ChartJS.register(ArcElement, Tooltip, Legend)

    const randomColors = useRandomColors()
    
    const chartRef = useRef(null)

    const demandStatusObj = {}

    DemandStatus().forEach(ds => {
        demandStatusObj[ds.key] = ds.value
    })

    useEffect(() => {
        const chart = chartRef.current
        if(data !== null) {
            let updatedLabels = Object.entries(data)
            .sort((a, b) => b[1] - a[1])
            .map((value, key) => {
                return (demandStatusObj[value[0]].length > 45) ? 
                        demandStatusObj[value[0]].slice(0, 44) + ' ...' : 
                        demandStatusObj[value[0]]
            } )
            let updatedBaData = Object.entries(data)
            .sort((a, b) => b[1] - a[1])
            .map((value, key) => {
                return value[1] ?? 0
            })
            
            chart.data.labels.push(...updatedLabels)
            chart.data.datasets.forEach((dataset) => {
                dataset.data.push(...updatedBaData)
                dataset.backgroundColor.push(...randomColors.slice(0, updatedLabels.length))
            });
            chart.update();
        } 
    }, [data])

   const labels = []
    const baData = []
    const chartData = {
        labels: labels,
        datasets: [
            {
            label: 'Ürün Talebi',
            data:  baData,
            backgroundColor: randomColors.slice(0, labels.length),
            },
        ],
    }

    const options = {
        maintainAspectRatio: false,
        cutout: '80%',
        plugins: {
            title: {
            display: false,
            },
            legend: {
                display: false
            }
        },
    }

    const plugins = [{
        id: 'text',
        beforeDraw: function(chart, a, b) {
            let width = chart.chartArea.right
            let height = chart.height
            let ctx = chart.ctx

            ctx.restore()
            let fontSize = (height / 114).toFixed(2)
            ctx.font = fontSize + "em sans-serif"
            ctx.textBaseline = "middle"

            let text = FNWS(chart.data.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0), 0) + ' Adet'
            let textX = Math.round((width - ctx.measureText(text).width) / 2)
            let textY = height / 2

            ctx.fillText('Toplam:', textX, textY - fontSize*7)
            ctx.fillText(text, textX, textY + fontSize*7)
            ctx.save()
        }
    }]

    const ChartTable = () => {
        return (
            <div className="w-full max-h-[180px] overflow-y-auto">
                <table className="w-full">
                    <tbody>
                    {(data && Object.entries(data).length > 0) ? 
                        Object.entries(data)
                        .sort((a, b) => b[1] - a[1])
                        .map((d, key) => {
                            return (
                                <tr key={d[0]} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td>
                                        <span style={{backgroundColor: randomColors[key]}} className="inline-block h-4 w-4 rounded-full border border-gray-700 mt-px"></span>
                                    </td>
                                    <td className="truncate">{demandStatusObj[d[0]]}</td>
                                    <td className='text-right pr-1'>{FNWS(d[1], 0)}</td>
                                </tr>
                            )
                        })
                    :   
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td colSpan={3}>Ürün talebi bulunamadı</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        )
    }

    const CardBody = () => {
        return (
            <div className="w-full">
                <div className="relative">                
                    <div className="flex flex-col lg:flex-row items-start justify-start gap-x-4">
                        <div className="relative h-[200px] w-[200px] m-auto">
                            <Doughnut ref={chartRef} data={chartData} options={options} plugins={plugins} />
                        </div>
                        <div className="w-full mt-4">
                            <ChartTable />
                        </div>
                    </div>
                    {loading && <CardLoader />}
                </div>
            </div>
        )
    }

    return (
        <Card title={`Ürün Talepleri Dağılımı`} icon={<FaChartPie />} >  
            <CardBody />
        </Card>
    )
}

export default StatusChart