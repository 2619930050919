import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {PhoneSmall} from 'elements/inputs/Phone'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenCompanyCompanyEdit } from 'stores/DrawersSlice' 
import { setCompanies, setSelectedCompany, setIsSelected } from 'stores/CompanySlice'
import Loader from 'elements/Loader'
import { useCompanyInput } from 'modules/companyModule/pages/companies/InputArray'

const EditForm = ({update, setUpdate}) => {
    const dispatch = useDispatch()
    const companies = useSelector((state) => state.company.companies)
    const isOpenEdit = useSelector(state => state.drawers.isOpenCompanyCompanyEdit)
    const selectedCompany = useSelector((state) => state.company.selectedCompany)
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const cities = useSelector(state => state.sharedData.cities)
    const districts = useSelector(state => state.sharedData.districts)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filteredDistricts, setFilteredDistricts] = useState([]) 

    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm({
        defaultValues: {
            business_area_id: null,
            company_name: null,
            tax_number: null,
            tax_department: null,
            address: null,
            district_id: null,
            city_id: null,
            phone: null,
            email: null            
        }
    }) 

    const handleChangeCity = (id) => {
        if(id) {
            if(selectedCompany.city_id != id) {
                setValue('district_id', null)                
            }            
            let filteredData = districts.filter(item => {
                return item.cityId === parseInt(id)
            })
            setFilteredDistricts(filteredData)
        }
    }

    const productInputs = useCompanyInput(errors, businessAreas, cities, filteredDistricts, handleChangeCity)

    useEffect(() => {
        if(selectedCompany !== null && Object.keys(selectedCompany).length > 0) {
            handleChangeCity(selectedCompany.city_id)
            setValue('business_area_id', selectedCompany.business_area.id)
            setValue('company_name', selectedCompany.company_name)
            setValue('tax_number', selectedCompany.tax_number)
            setValue('tax_department', selectedCompany.tax_department)
            setValue('address', selectedCompany.address)
            setValue('district_id', selectedCompany.district.id)
            setValue('city_id', selectedCompany.city.id)
            setValue('phone', selectedCompany.phone)
            setValue('email', selectedCompany.email)            
            setError(null)
            setStatus(false)
        }
    }, [isOpenEdit])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${selectedCompany.id}`, data)
                .then(res => {
                    if(res.status === 200) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                        let remainingCompanies = companies.filter(comp => {
                            return comp.id !== res.data.id
                        })
                        dispatch(setCompanies([...remainingCompanies, res.data.data]))
                        dispatch(setSelectedCompany(res.data.data))
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setError(err?.response?.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenCompanyCompanyEdit(false))
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Firma Bilgileri</p>
            <div className="grid grid-cols-12 gap-2">
            {productInputs.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        {input.type == 'select' ? 
                            <SelectSmall register={register} {...input} /> :
                            null
                        }
                        {input.type == 'text' ? 
                            <InputSmall register={register} {...input} /> :
                            null
                        }
                        {input.type == 'phone' ? 
                            <PhoneSmall register={register} {...input} />:
                            null
                        }
                    </div>
                    )
            })}
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div> 
        </form> 
         
        {loading && <Loader />}
        </>
    )
}

export default EditForm