import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {ButtonFilter, ButtonReset} from 'elements/buttons/actionButtons'

function TableFilter({isFilterOpen, setIsFilterOpen, setFilters, setSearchParams}) {
    const [resetFields, setResetFields] = useState(false)
    const productTypes = useSelector(state => state.sharedData.productTypes)
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            search: null,
            business_area_id: null,
            product_type_id: null, 
        }
    })

    const handleForm = async (data) => {
        Object.keys(data).forEach(k => data[k] = data[k] === '' ? null : data[k])
        setSearchParams('page=1')
        setIsFilterOpen(false)
        setFilters(data)
    }

    const handleReset = () => {
        reset()
        setResetFields(!resetFields)
        handleSubmit(handleForm)()
    }

    return (
        <div className={`border border-gray-200 bg-white overflow-hidden transition-[width] duration-300 ease-linear ${isFilterOpen ? 'w-full md:w-[250px] h-auto p-3' : 'w-0 h-0 p-0'}`}>
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12 flex flex-col gap-y-2">
                        <InputSmall 
                            type='search'
                            name='search'
                            register={register}
                            label= 'Ara'
                            placeholder='Talep Başlığı, Talep No'
                            validator={{}}
                            error={errors?.search?.message}
                        />
                        <SelectSmall                                 
                            type='select'
                            name='business_area_id'
                            register={register}
                            label='Sektör'
                            placeholder='Sektör'
                            validator={{}}
                            error={errors?.business_area_id?.message}
                            data={businessAreas}
                        />                        
                        <SelectSmall                                 
                            type='select'
                            name='product_type_id'
                            register={register}
                            label='Ürün Türü'
                            placeholder='Ürün Türü'
                            validator={{}}
                            error={errors?.product_type_id?.message}
                            data={productTypes}
                        />                                     
                    </div>
                </div>
                <div className="w-full flex justify-end gap-x-2 mt-5">
                    <ButtonReset type="button" onClick={() => handleReset()}>
                    </ButtonReset>
                    <ButtonFilter type="submit">
                    </ButtonFilter>
                </div>
            </form>   
        </div>
    )
}

export default TableFilter