import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseDemandDeliver } from 'stores/DrawersSlice' 
import DeliverForm from 'modules/companyModule/pages/warehouses/demands/DeliverForm'

function Deliver({productDemand, update, setUpdate, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCancel = useSelector(state => state.drawers.isOpenWarehouseDemandDeliver)
    const setIsOpenCancel = () => dispatch(setIsOpenWarehouseDemandDeliver())
    
    return(
        <FormDrawer isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} title="Ürün Talebini Teslim Et" maxWidth="max-w-6xl">
            <DeliverForm productDemand={productDemand} update={update} setUpdate={setUpdate} selectedWarehouse={selectedWarehouse} />
        </FormDrawer> 
    )
}

export default Deliver