import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenDepartmentAddUsers } from 'stores/DrawersSlice' 
import EditDepartmentUsersForm from 'modules/companyModule/pages/companies/settings/departments/EditDepartmentUsersForm'

function EditDepartmentUsers({department, users, positions, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenDepartmentAddUsers)
    const setIsOpenCreate = () => dispatch(setIsOpenDepartmentAddUsers())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Departman Kullanıcılarını Düzenle" maxWidth='max-w-3xl'>
            <EditDepartmentUsersForm 
                department={department}
                users={users}
                positions={positions}
                update={update} 
                setUpdate={setUpdate} 
                
            />
        </FormDrawer> 
    )
}

export default EditDepartmentUsers