import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CompanyTable from 'modules/supplierModule/pages/companies/companyInfos/CompanyTable'
import { ButtonEdit } from 'elements/buttons/actionButtons'
import { setIsOpenSupplierCompanyEdit } from 'stores/DrawersSlice'
import Edit from 'modules/supplierModule/pages/companies/companyInfos/Edit'
import Header from 'modules/supplierModule/pages/companies/Header'

const CompanyInfos = () => {
    const companies = useSelector(state => state.company)
    const dispatch = useDispatch()
    const [update, setUpdate] = useState(true)

    return (
        <div className="w-full mb-4">            
            <div className="">
                <Header title="Şirket Bilgileri" />
                <CompanyTable company={companies.selectedCompany} />
                <div className="p-4 flex justify-end items-center">
                    <ButtonEdit type="button" onClick={() => {dispatch(setIsOpenSupplierCompanyEdit(true))}} />
                </div>
            </div>
            <Edit update={update} setUpdate={setUpdate} />
        </div>
    )
}

export default CompanyInfos