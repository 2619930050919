import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseCreate } from 'stores/DrawersSlice' 
import CreateWarehouseForm from 'modules/companyModule/pages/companies/settings/warehouses/CreateWarehouseForm'

function CreateWarehouse({update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenWarehouseCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenWarehouseCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Depo Ekle">
            <CreateWarehouseForm update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default CreateWarehouse