function ProjectInfo({taxonomy, parent, taxon}) {

    return (
        <div className="">
                {(taxon !==null && taxon !== undefined && Object.keys(taxon).length > 0) &&
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Sınıflandırma Adı:</td>
                            <td className="px-2 py-3">{taxonomy.taxonomy_name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Sınıf Adı:</td>
                            <td className="px-2 py-3">{taxon.taxon_name}</td>
                        </tr>
                    </tbody>
                </table>}
            </div>  
    )
}

export default ProjectInfo