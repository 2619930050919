import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams, useLocation } from 'react-router-dom'
import UserForm from 'modules/authModule/pages/register/supplier/UserForm'
import CompanyForm from 'modules/authModule/pages/register/supplier/CompanyForm'
import BuyerForm from 'modules/authModule/pages/register/supplier/BuyerForm'
import TermsAndConditionForm from 'modules/authModule/pages/register/supplier/TermsAndConditionForm'
import Confirmation from 'modules/authModule/pages/register/supplier/Confirmation'
import Steps from 'modules/authModule/pages/register/supplier/Steps'
import SendRequest from 'utils/SendRequest'
import {LinkBlue} from 'elements/links'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import logo from 'assets/img/marlaport-white.png'
import marlaportLogo from 'assets/img/marlaport_logo.png'

const RegisterSupplier = () => {

    const step = useSelector(state => state.register.step) || 1
    // const step = 3
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const cities = useSelector(state => state.sharedData.cities)
    const districts = useSelector(state => state.sharedData.districts)

    const [loading, setLoading] = useState(false)
    const [invitation, setInvitation] = useState(null)
    const [error, setError] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation()

    useEffect(() => {
        const getSuppliers = async () => {
            let code = searchParams.get("code")
            
            setLoading(true)
            await SendRequest('GET', `api/supplier_invitations/code/${code}`)
                .then(response => {
                    if(response.status === 200) {
                        setError(false)
                        setInvitation(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setInvitation(null)
                    setLoading(false)
                })
        }
        getSuppliers()
    }, [search])

    return (
        <div className="w-full py-10 bg-slate-100 bg-no-repeat bg-cover px-2 flex flex-col justify-center items-center gap-y-4">
            <div>
                <img src={marlaportLogo} className="h-12" />
            </div>
            <div className="w-full bg-white shadow-lg rounded-lg md:max-w-lg lg:w-[34rem]">
                <div className="w-full">
                    {<div className="text-red-500 mt-4 mx-4">
                        {error && <ServerError errorData={error} />}
                    </div>}
                    {invitation && Object.keys(invitation).length > 0 ?
                    <>
                    <Steps />
                    {step === 1 && <UserForm />}     
                    {step === 2 && <CompanyForm cities={cities} districts={districts} businessAreas={businessAreas} invitation={invitation} />}      
                    {/* {step === 3 && <BuyerForm  />} */}
                    {step === 4 && <TermsAndConditionForm  />}
                    {step === 5 && <Confirmation cities={cities} districts={districts} businessAreas={businessAreas} />}
                    </>
                    : null
                    }
                    
                </div>
                <div className="mt-4 text-center">
                <p className="text-black-700 text-sm mb-1">Hesabınız varsa giriş yapmak için <LinkBlue route="/login" title="tıklayın." /> </p>   
            </div>
            </div>
        </div>
    )
}

export default RegisterSupplier