import { read, utils, writeFileXLSX } from 'xlsx'
import { useSelector } from 'react-redux'

function Explanation() {
    const productTypes = useSelector(state => state.sharedData.productTypes)
    const unitCodes = useSelector(state => state.sharedData.unitCodes)

    const downloadProductTypeCode = () => {
        const ptData = productTypes.map(item => {
            return {id: item.key, name: item.value}
        })
        const header = [[
            "Kod", 
            "Tür", 
        ]]
        const ws = utils.json_to_sheet(ptData)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, "urun_tur_kodlari.xlsx")
    }

    const downloadUnitCode = () => {
        const ucData = unitCodes.map(item => {
            return {id: item.key, name: item.value}
        })
        const header = [[
            "Kod", 
            "Birim", 
        ]]
        const ws = utils.json_to_sheet(ucData)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, "birim_kodlari.xlsx")
    }

    return (
        <div className="">
            <p className="text-justify text-gray-800">Aşağıdaki kurallara göre oluşturacağınız excel dosyasını yan tarafta bulunan dosya yükleme alanından yükleyebilirsiniz.
            Excel dosyasını yüklendikten sonra ön izleme oluşacak. Kontrol ettikten sonra bilgileri içeri aktarabilirsiniz.</p>
            <ul className="list-disc list-outside text-justify text-gray-800 mt-2">
                <li><span className="font-semibold">Ürün/Hizmet Adı:</span> Zorunlu Alan</li>
                <li><span className="font-semibold">Ürün Tür Kodu:</span> Zorunlu Alan, kod listesini indirmek için <button onClick={() => downloadProductTypeCode()} className="underline text-blue-600">tıklayın.</button></li>
                <li><span className="font-semibold">Ürün Kodu:</span> Boş bırakılabilir.</li>
                <li><span className="font-semibold">Barkod:</span> Boş bırakılabilir.</li>
                <li><span className="font-semibold">Ürün Açıklaması:</span> Boş bırakılabilir.</li>
                <li><span className="font-semibold">Birim Kodu:</span> Zorunlu alan, birim kodu listesini indirmek için <button onClick={() => downloadUnitCode()} className="underline text-blue-600">tıklayın.</button></li>
            </ul>
        </div>
    )
}

export default Explanation