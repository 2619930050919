import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    company: null,
    module: null,
    buyer: null,
    termsAndCond: null,
    step: 1,
    userError: null,
    companyError: null,
}

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        reset: () => initialState,
        setUserData: (state, action) => {
            state.user = action.payload
        },
        setCompanyData: (state, action) => {
            state.company = action.payload
        },
        setModuleData: (state, action) => {
            state.module = action.payload
        },
        setBuyerData: (state, action) => {
            state.buyer = action.payload
        },
        setTermsAndCondData: (state, action) => {
            state.termsAndCond = action.payload
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setUserError: (state, action) => {
            state.userError = action.payload
        },
        setCompanyError: (state, action) => {
            state.companyError = action.payload
        },
    }
})

export const {reset, setUserData, setCompanyData, setModuleData, setBuyerData, setTermsAndCondData, setStep, setUserError, setCompanyError} = registerSlice.actions

export default registerSlice.reducer