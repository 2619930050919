import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductDelete } from 'stores/DrawersSlice' 
import DeleteForm from 'modules/companyModule/pages/products/DeleteForm'

function Delete({product, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenDelete = useSelector(state => state.drawers.isOpenProductDelete)
    const setIsOpenDelete = () => dispatch(setIsOpenProductDelete())
    
    return(
        <FormDrawer isOpen={isOpenDelete} setIsOpen={setIsOpenDelete} title="Ürünü Sil">
            <DeleteForm product={product} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Delete