import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import {ButtonEditSmall, ButtonDetailSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import { IconContext } from "react-icons"
import { FaSearch, FaTrashAlt, FaRegEdit, FaSlidersH, FaRegCopy, FaCheckCircle, FaEnvelopeOpenText, FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa'
import { BiCategoryAlt } from 'react-icons/bi'
import { setIsOpenSupplierEdit, 
    setIsOpenSupplierDelete, 
    setIsOpenSupplierDetail, 
    setIsOpenSupplierApprove,
    setIsOpenSupplierAddBlacklist,
    setIsOpenSupplierRemoveBlacklist } from 'stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const CustomLinks = () => {
        if(data.is_approved === false) {
            return (
                <TableButton 
                    title='Onayla' 
                    icon={<FaCheckCircle />} 
                    onClick={() => {
                        actions.setSupplierForApprove(data)
                        dispatch(setIsOpenSupplierApprove(true))
                        }}
                />
            )
        }
        if(data.is_blacklisted === true) {
            return (
                <TableButton 
                    title='Kara Listeden Çıkar' 
                    icon={<FaRegThumbsUp />} 
                    onClick={() => {
                        actions.setSupplierForRemoveBlacklist(data)
                        dispatch(setIsOpenSupplierRemoveBlacklist(true))
                        }}
                />
            )
        }
        if(data.is_blacklisted === false && data.is_approved === true) {
            return (
                <TableButton 
                    title='Kara Listeye Al' 
                    icon={<FaRegThumbsDown />} 
                    onClick={() => {
                        actions.setSupplierForAddBlacklist(data)
                        dispatch(setIsOpenSupplierAddBlacklist(true))
                        }}
                />
            )
        }
    }

    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <CustomLinks />
                <TableButton 
                    title='Detaylar' 
                    icon={<FaSearch />} 
                    borderTop={true}
                    onClick={() => {
                        actions.setSupplierForDetail(data)
                        dispatch(setIsOpenSupplierDetail(true))
                        }}
                />
                <TableButton 
                    title='Kategoriler' 
                    icon={<BiCategoryAlt />} 
                    onClick={() => {
                        actions.setSupplierForEdit(data)
                        dispatch(setIsOpenSupplierEdit(true))
                        }}
                />
                <TableButton 
                    title='Satın Alma İşlemleri' 
                    icon={<FaEnvelopeOpenText />} 
                    onClick={() => {
                        navigate('/company/suppliers/'+data.id)
                        }}
                />
                <TableButton 
                    title='Sil' 
                    icon={<FaTrashAlt />} 
                    onClick={() => {
                        actions.setSupplierForDelete(data)
                        dispatch(setIsOpenSupplierDelete(true))
                    }}
                />
                </IconContext.Provider>
            </div>
        )
    }    
    
    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
}

export default TableActions
