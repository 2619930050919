import TableLayout from 'elements/tables'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

const ProductTable = ({data, progressPending}) => {

    const columns = [
        {
            name: 'Ürün',
            selector: (row) => {return row.name},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Türü',
            selector: (row) => {return row.product_type.name},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Kodu',
            selector: (row) => {return row.product_code},
            headerMobileHidden: false,
        },
        {
            name: 'Barkod',
            selector: (row) => {return row.barcode},
            headerMobileHidden: false,
        },
        {
            name: 'Miktar',
            selector: (row) => {return FNWS(row.quantity) + ' ' + row.unit_code.code},
            headerMobileHidden: false,
        },        
        {
            name: 'Tutar',
            selector: (row) => {return FNWS(row.price) + ' ' + row.currency.code},
            headerMobileHidden: false,
        },        
    ];

    const mobileHeader = (row) => {return `${row.name}`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={null} pageMeta={null} mobileHeader={mobileHeader} />
        </>
    );
};

export default ProductTable;
