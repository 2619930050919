import TableActions from 'modules/companyModule/pages/warehouses/stocks/TableActions'
import TableLayout from 'elements/tables'
import { useSelector } from 'react-redux'

const Table = ({data, actions, progressPending, pagination, pageMeta}) => {

    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const cities = useSelector(state => state.sharedData.cities)
    const districts = useSelector(state => state.sharedData.districts)
    
    const StockCell = ({stocks}) => {
        return (
            <div className='flex flex-col justify-center'>
                {Array.isArray(stocks) && stocks.length > 0 ? 
                    stocks.map(s => {
                        return <p key={s.id}>{s.quantity} {s.unit.description}</p>
                    })
                : 
                    <span>Stok Yok</span>
                }                                       
            </div>
        )
    }

    const TaxonomyCell = ({taxonomies}) => {
        if(taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) {
            return taxonomies.map(taxonomy => {
                return (
                    <p key={taxonomy.id} className="whitespace-nowrap mb-0.5">
                        <span className="font-semibold">
                            {taxonomy.taxonomy.taxonomy_name}: &nbsp;
                        </span>
                        {taxonomy.taxon.taxon_name}
                    </p>
                )
            })
        } else {
            return <p></p>
        }
        
    }

    const columns = [
        {
            name: 'Ürün Adı',
            selector: (row) => {return row.name},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Kodu',
            selector: (row) => {return row.product_code},
            headerMobileHidden: false,
        },
        {
            name: 'Kategori',
            selector: (row) => {return <TaxonomyCell taxonomies={row.taxonomies}/>},
            headerMobileHidden: false,
        },
        {
            name: 'Depodaki Stok',
            selector: (row) => {return <StockCell stocks={row.warehouse_stocks}/>},
            headerMobileHidden: false,
        },
        {
            name: 'Toplam Stok',
            selector: (row) => {return <StockCell stocks={row.stocks}/>},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return row.name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
