import { useEffect } from 'react'
import SendRequest from 'utils/SendRequest'
import FormatNumber from 'utils/FormatNumber'
import { useDispatch, useSelector } from 'react-redux'
import { 
    setCurrencies, 
    setCurrenciesWithDesc, 
    setExchangeRates, 
    setUnitCodes, 
    setBusinessAreas,
    setCities,
    setDistricts,
    setProductTypes} from '../stores/SharedDataSlice'

const SharedDataWrapper = ({children}) => {
    const isAuth = useSelector(state => state.user.authenticated)
    const currencies = useSelector(state => state.sharedData.currencies)
    const exchangeRates = useSelector(state => state.sharedData.exchangeRates)
    const unitCodes = useSelector(state => state.sharedData.unitCodes)
    const productTypes = useSelector(state => state.sharedData.productTypes)
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const cities = useSelector(state => state.sharedData.cities)
    const districts = useSelector(state => state.sharedData.districts)
    
    const dispatch = useDispatch()

    useEffect(() => {
        const getCurrencies = async () => {
            await SendRequest('GET', 'api/currencies')
            .then(res => {
                if(res.status === 200) {
                    const currencyArray = res.data.map(currency => {
                        return {
                            key: currency.id,
                            value: currency.code
                        }
                    })
                    const currencyArrayWithDesc = res.data.map(currency => {
                        return {
                            key: currency.id,
                            value: currency.code + ' (' + currency.description + ')' 
                        }
                    })
                    dispatch(setCurrencies(currencyArray))
                    dispatch(setCurrenciesWithDesc(currencyArrayWithDesc))                                      
                }
            })
            .catch(err => {
                dispatch(setCurrencies([{key: 1, value: 'TRY'}]))
                dispatch(setCurrenciesWithDesc([{key: 1, value: 'TRY (Türk Lirası)'}]))
            })
            
        } 

        const getExchangeRates = async () => {
            await SendRequest('GET', 'api/exchange_rates')
            .then(res => {
                if(res.status === 200) {
                    const exchangeRateArray = res.data.map(rate => {
                        return {
                            id: rate.id,
                            code: rate.code,
                            rate: FormatNumber(rate.rate, 4),
                            currencyDate: rate.currency_date,
                            forexBuying: FormatNumber(rate.forex_buying, 4),
                            forexSelling: FormatNumber(rate.forex_selling, 4),
                            banknoteBuying: FormatNumber(rate.banknote_buying, 4),
                            banknoteSelling: FormatNumber(rate.banknote_selling, 4),
                        }
                    })
                    dispatch(setExchangeRates(exchangeRateArray))                                      
                }
            })
            .catch(err => {
                dispatch(setExchangeRates([]))
            })            
        } 

        const getUnitCodes = async () => {
            await SendRequest('GET', 'api/unit_codes')
            .then(res => {
                if(res.status === 200) {
                    const unitCodeArray = res.data.map(unitCode => {
                        return {
                            key: unitCode.id,
                            value: `${unitCode.description}`,
                        }
                    })
                    dispatch(setUnitCodes(unitCodeArray))                                      
                }
            })
            .catch(err => {
                dispatch(setUnitCodes([]))
            })            
        }

        const getProductTypes = async () => {
            await SendRequest('GET', 'api/product_types')
            .then(res => {
                if(res.status === 200) {
                    const productTypeArray = res.data.map(productType => {
                        return {
                            key: productType.id,
                            value: `${productType.name}`,
                        }
                    })
                    dispatch(setProductTypes(productTypeArray))                                      
                }
            })
            .catch(err => {
                dispatch(setProductTypes([]))
            })            
        }

        if(isAuth && (currencies === null || currencies.length === 1)) {
            getCurrencies()
                .catch(err => {
                    dispatch(setCurrencies([{key: 1, value: 'TRY'}]))
                    dispatch(setCurrenciesWithDesc([{key: 1, value: 'TRY (Türk Lirası)'}]))
                })
        }
        if(isAuth &&  (exchangeRates === null || exchangeRates.length === 0)) {
            getExchangeRates()
                .catch(err => {
                    dispatch(setExchangeRates([]))
                })
        }
        if(isAuth && (unitCodes === null || unitCodes.length === 0)) {
            getUnitCodes()
                .catch(err => {
                    dispatch(setUnitCodes([]))
                })
        }
        if(isAuth && (productTypes === null || productTypes.length === 0)) {
            getProductTypes()
                .catch(err => {
                    dispatch(setProductTypes([]))
                })
        }
        
    }, [isAuth])

    useEffect(() => {
        const getCities = async () => {            
            return new Promise(async (resolve) => {
                await SendRequest('GET', `api/cities`)
                    .then(res => {
                        if(res.status === 200) {
                            let selectArray = res.data.map(item => {
                                return {key: item.id, value: item.name}
                            })
                            resolve(selectArray)
                        } else {
                            resolve(null)
                        }
                    })
                    .catch(err => {
                        resolve(null)
                    })
            })
        }
        const getDistricts = async () => {            
            return new Promise(async (resolve) => {
                await SendRequest('GET', `api/districts`)
                    .then(res => {
                        if(res.status === 200) {
                            let selectArray = res.data.map(item => {
                                return {key: item.id, value: item.name, cityId: item.city_id}
                            })
                            resolve(selectArray)
                        } else {
                            resolve(null)
                        }
                    })
                    .catch(err => {
                        resolve(null)
                    })
            })
        }
        const getBusinessAreas = async () => {            
            return new Promise(async (resolve) => {
                await SendRequest('GET', `api/business_areas`)
                    .then(res => {
                        if(res.status === 200) {
                            let selectArray = res.data.map(item => {
                                return {key: item.id, value: item.name}
                            })
                            resolve(selectArray)
                        } else {
                            resolve(null)
                        }
                    })
                    .catch(err => {
                        resolve(null)
                    })
            })
        }
        
        
        Promise.all([
            getCities(),
            getDistricts(),
            getBusinessAreas(),
        ]).then(result => {
            dispatch(setCities(result[0]))
            dispatch(setDistricts(result[1]))
            dispatch(setBusinessAreas(result[2]))
        }).finally(() => {
            
        })

    }, [])

    return children
}

export default SharedDataWrapper