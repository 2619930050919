import { useEffect, useState, Fragment } from "react"
import { useDispatch, useSelector } from 'react-redux' 
import SendRequest from 'utils/SendRequest'
import UploadForm from 'modules/supplierModule/pages/companies/documents/UploadForm'
import Loader from 'elements/Loader'

function DocumentTable({documentList, documents, setUpdate}) {

    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [loading, setLoading] = useState(false)

    const downloadDocument = (doc_code) => {
        const getDocument = async () => {
            setLoading(true)
            await SendRequest('DOWNLOAD', `api/suppliers/${companyId}/documents/${doc_code}`)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${doc_code}.pdf`)
                        document.body.appendChild(link)
                        link.click()
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getDocument()
    }

    const filteredDocument = (doc) => {
        const filtered = documents.filter(document => document.document_code === doc.code)
        if(filtered.length > 0) return filtered[0]
        return null 
    }

    if(documentList !== null && Array.isArray(documentList) && documentList.length > 0) {
        return (         
            <table className="w-full text-xs">
                <tbody className="[&>*:nth-child(4n)]:bg-gray-200 [&>*:nth-child(4n-1)]:bg-gray-200">
                    {documentList.map(doc => {
                        return (
                            <Fragment key={doc.id}>
                            <tr className="border-b border-t border-gray-300">
                                <td className="px-2 py-3 font-semibold">
                                    {doc.description} 
                                    {/* {doc.is_required ? '(Zorunlu)' : '(Zorunlu Değil)'} */}
                                </td>
                                {filteredDocument(doc) !== null ? 
                                    <>
                                    {/* <td className="px-2 py-3">
                                        Durum: {filteredDocument(doc).is_approved ? 
                                        <span className="text-green-700">Onaylandı</span> : 
                                        <span className="text-amber-700">Onay bekleniyor</span>}
                                    </td>  */}
                                    <td className="px-2 py-3 text-right">                                    
                                        <button type="button" 
                                        className="text-md text-sky-500 hover:text-sky-800 underline" 
                                        onClick={() => downloadDocument(doc.code)}
                                        >
                                            İndir
                                        </button>                                                                               
                                    </td>             
                                    </>
                                    : 
                                    <td colSpan={2} className="px-2 py-3 text-right">
                                        <span className="text-red-700">Dosya yüklenmedi</span>
                                    </td>
                                }                   
                            </tr>
                            <tr className="border-b border-t border-gray-300">
                                <td colSpan={3} className="px-2 py-3">
                                    <UploadForm document={doc.code} label={doc.description} setUpdate={setUpdate} />
                                </td>
                            </tr>
                            </Fragment>
                        )
                    })}
                </tbody>
            </table>       
        )
    }
    return null
    
}

export default DocumentTable