import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import { IconContext } from "react-icons"
import { FaSearch, FaTrashAlt, FaRegEdit, FaSlidersH, FaRegCopy, FaCheckCircle } from 'react-icons/fa'
import { setIsOpenWarehouseProcurementRequestCancel, 
        setIsOpenWarehouseProcurementRequestStatusUpdate, 
        setIsOpenWarehouseProcurementRequestDetail, 
        setIsOpenWarehouseProcurementRequestCreate,
        setIsOpenWarehouseProcurementRequestDeliver } from '../../../../../stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const CustomLinks = () => {
        if(data.status === 'rejected') return null
        if(data.status === 'cancelled') return null
        if(data.status === 'waiting_approvals') {
            return (
                <> 
                    <TableButton 
                        title='İptal Et' 
                        icon={<FaTrashAlt />} 
                        onClick={() => {
                            actions.setProcurementRequestForCancel(data)
                            dispatch(setIsOpenWarehouseProcurementRequestCancel(true))
                        }}
                    />
                </>
            )
        }
        if(data.status === 'waiting_procurement') return null
        if(data.status === 'procurement_in_progress') return null
        if(data.status === 'waiting_delivery') {
            return (
                <> 
                    <TableButton 
                        title='Teslim İşlemleri' 
                        icon={<FaCheckCircle />} 
                        onClick={() => {
                            actions.setProcurementRequestForDeliver(data)
                            dispatch(setIsOpenWarehouseProcurementRequestDeliver(true))
                        }}
                    />
                </>
            )
        }
        if(data.status === 'delivery_rejected') return null
        if(data.status === 'completed') {
            return (
                <> 
                    <TableButton 
                        title='Durumu Güncelle' 
                        icon={<FaRegEdit />} 
                        borderTop={true} 
                        onClick={() => {
                            actions.setProcurementRequestForStatusUpdate(data)
                            dispatch(setIsOpenWarehouseProcurementRequestStatusUpdate(true))
                        }}
                    />
                </>
            )
        }
        
        if(data.status === 'archived') {
            return (
                <> 
                    <TableButton 
                        title='Durumu Güncelle' 
                        icon={<FaRegEdit />} 
                        borderTop={true} 
                        onClick={() => {
                            actions.setProcurementRequestForStatusUpdate(data)
                            dispatch(setIsOpenWarehouseProcurementRequestStatusUpdate(true))
                        }}
                    />
                </>
            )
        }
    }
    
    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton 
                    title='Talep Detayları' 
                    icon={<FaSearch />} 
                    onClick={() => {
                        actions.setProcurementRequestForDetail(data)
                        dispatch(setIsOpenWarehouseProcurementRequestDetail(true))
                        }}
                    />
                <CustomLinks />
                <TableButton 
                    title='Taslak Olarak Kullan' 
                    icon={<FaRegCopy />} 
                    borderTop={true} 
                    onClick={() => {
                        actions.setProcurementRequestForCopy(data)
                        dispatch(setIsOpenWarehouseProcurementRequestCreate(true))
                        }}
                    />
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
