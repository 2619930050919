import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenCostTypeCreate } from 'stores/DrawersSlice' 
import CreateCostTypeForm from 'modules/companyModule/pages/companies/settings/costTypes/CreateCostTypeForm'

function CreateCostType({update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenCostTypeCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenCostTypeCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Gider Türü Ekle">
            <CreateCostTypeForm update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default CreateCostType