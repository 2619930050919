import { useRef, useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'
import { IconContext } from "react-icons"
import { FaEnvelopeOpenText, FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa'
import { MdOutlineBusinessCenter } from 'react-icons/md'
import { RiHandCoinFill } from 'react-icons/ri'

function ProposalSummary({product}) {
    
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const initialData = {
        "last_month": {
            "price": [
                {
                "id": "price_1",
                "currency": {
                    "id": 1,
                    "code": "TRY",
                    "description": "Türk Lirası"
                },
                "price": 0
                }
            ],
            "quantity": [
                {
                "id": "quantity_1",
                "unit_code": {
                    "id": 1,
                    "code": "C62",
                    "description": "Adet"
                },
                "quantity": 0
                }
            ]
        },
        "last_year": {
            "price": [
                {
                "id": "price_1",
                "currency": {
                    "id": 1,
                    "code": "TRY",
                    "description": "Türk Lirası"
                },
                "price": 0
                }
            ],
            "quantity": [
                {
                "id": "quantity_1",
                "unit_code": {
                    "id": 1,
                    "code": "C62",
                    "description": "Adet"
                },
                "quantity": 0
                }
            ]
        },
        "total": {
            "price": [
                {
                "id": "price_1",
                "currency": {
                    "id": 1,
                    "code": "TRY",
                    "description": "Türk Lirası"
                },
                "price": 0
                }
            ],
            "quantity": [
                {
                "id": "quantity_1",
                "unit_code": {
                    "id": 1,
                    "code": "C62",
                    "description": "Adet"
                },
                "quantity": 0
                }
            ]
        }
    }
    const [proposalData, setProposalData] = useState(initialData)
    const [loading, setLoading] = useState(false)

    
      

    useEffect(() => {
        const getProposalData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/products/${product.id}/proposal_summary`)
                .then(res => {
                    if(res.status === 200) {
                        setProposalData(res.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if(product) getProposalData()                     
    }, [product])
    
    return (
        <div className="flex-grow flex flex-col lg:flex-row gap-y-4 lg:gap-x-4">
            {proposalData ?
            <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-4">
                <div className="w-full flex justify-start items-center gap-x-4 border border-emerald-500 bg-white">
                    <div className="h-full w-4 bg-emerald-500"></div>
                    <div className="text-gray-800">
                        <p className="text-base font-semibold">Son 1 Ay</p>
                        <p className="text-sm">Tamamlanan Satın Alma</p>
                        <p className="text-xl font-semibold">
                            {proposalData.last_month.quantity.length > 0 ? 
                                proposalData.last_month.quantity.map((item) => {
                                    return FNWS(item.quantity, 0) + ' ' + item.unit_code.description
                                }).join(' + ')
                            : '0 Adet'}
                        </p>
                        <p className="text-xl font-semibold">
                            {proposalData.last_month.price.length > 0 ?
                                proposalData.last_month.price.map((item) => {
                                    return FNWS(item.price, 0) + ' ' + item.currency.code 
                                }).join(' + ')
                            : '0 TRY'}
                        </p>
                    </div>
                </div>
                <div className="w-full flex justify-start items-center gap-x-4 border border-amber-500 bg-white">
                    <div className="h-full w-4 bg-amber-500"></div>
                    <div className="text-gray-800">
                        <p className="text-base font-semibold">Son 1 Yıl</p>
                        <p className="text-sm">Tamamlanan Satın Alma</p>
                        <p className="text-xl font-semibold">
                            {proposalData.last_year.quantity.length > 0 ? 
                                proposalData.last_year.quantity.map((item) => {
                                    return FNWS(item.quantity, 0) + ' ' + item.unit_code.description
                                }).join(' + ')
                            : '0 Adet'}
                        </p>
                        <p className="text-xl font-semibold">
                            {proposalData.last_year.price.length > 0 ?
                             proposalData.last_year.price.map((item) => {
                                return FNWS(item.price, 0) + ' ' + item.currency.code 
                            }).join(' + ')
                            : '0 TRY'}
                        </p>
                    </div>
                </div>
                <div className="w-full flex justify-start items-center gap-x-4 border border-rose-500 bg-white">
                    <div className="h-full w-4 bg-rose-500"></div>
                    <div className="text-gray-800">
                        <p className="text-base font-semibold">Toplam</p>
                        <p className="text-sm">Tamamlanan Satın Alma</p>
                        <p className="text-xl font-semibold">
                            {proposalData.total.quantity.length > 0 ? 
                                proposalData.total.quantity.map((item) => {
                                    return FNWS(item.quantity, 0) + ' ' + item.unit_code.description
                                }).join(' + ')
                            : '0 Adet'}
                        </p>
                        <p className="text-xl font-semibold">
                            {proposalData.total.price.length > 0 ?
                                proposalData.total.price.map((item) => {
                                    return FNWS(item.price, 0) + ' ' + item.currency.code 
                                }).join(' + ')
                            : '0 TRY'}
                        </p>
                    </div>
                </div>
            </div>
            : 
            <div className="w-full bg-white px-4 py-2 border border-gray-400 text-sm leading-none flex items-center justify-center">
                <p className="font-bold border-b border-gray-300 px-2 py-2 mb-1 text-base">Yapılmış herhangi bir teklif bulunamadı.</p>                
            </div>
            }
        </div>
    )
}

export default ProposalSummary