import { CompanyType } from "data" 

function CompanyTable({company}) {

    const statuses = {
        waiting_documents: 'Dokümanların yüklenmesi bekleniyor',
        waiting_documents_approval: 'Dokümanların onaylanması bekleniyor',
        waiting_payment: 'Ödeme yapılması bekleniyor',
        waiting_payment_approval: 'Ödemenin onaylanması bekleniyor',
        active: 'Aktif',
        passive: 'Pasif',
    }

    const StatusCell = ({status}) => {
        if(status === 'active') {
            return <span className="text-emerald-500 font-semibold">{statuses[status]}</span>
        }
        return <span className="text-rose-500 font-semibold">{statuses[status]}</span>
    }

    const CompanyTypeCell = ({type}) => {
        if(type && type !== 0) {
            const filteredType = CompanyType().filter(ct => ct.key.toString() === type.toString())
            if(filteredType.length > 0) {
                return filteredType[0].value
            }            
        }
        return 'Belirlenmemiş'
    }

    if(company !== null) {
        return (         
            <table className="w-full text-sm">
                <tbody>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Şirket Adı:</td>
                        <td className="px-2 py-3">{company.company_name}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Durum:</td>
                        <td className="px-2 py-3"> <StatusCell status={company.status} /> </td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Sektörler:</td>
                        <td className="px-2 py-3">
                            {company.business_areas && Array.isArray(company.business_areas) && company.business_areas.length > 0 ? 
                                company.business_areas.map(ba => ba.name).join(', ')
                            : 'Sektör tanımlanmamış'}
                        </td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Şirket Türü:</td>
                        <td className="px-2 py-3"> <CompanyTypeCell type={company.company_type} /> </td>
                    </tr>
                    {company.company_type.toString() === '1' ? 
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">TC Kimlik No:</td>
                        <td className="px-2 py-3">{company.identity_number}</td>
                    </tr>
                    : null}
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Vergi No:</td>
                        <td className="px-2 py-3">{company.tax_number}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Vergi Dairesi:</td>
                        <td className="px-2 py-3">{company.tax_department}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Adres:</td>
                        <td className="px-2 py-3">{company.address} {company.district.name}/{company.city.name} </td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Telefon:</td>
                        <td className="px-2 py-3">{company.phone}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">E-Posta:</td>
                        <td className="px-2 py-3">{company.email}</td>
                    </tr>
                </tbody>
            </table>       
        )
    }
    return null
    
}

export default CompanyTable