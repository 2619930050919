import { IconContext } from "react-icons"
import { FaFileAlt } from 'react-icons/fa'

export default function Header({title, logo, selectOptions = null}) {
    const defaultLogo = <FaFileAlt />
    return ( 
        <div className="w-full flex justify-between items-center mt-2 px-2 py-1 mb-2 border border-sky-700  bg-sky-700">
            <p className="flex items-center justify-start gap-x-2 text-lg font-bold text-sky-50">
                <IconContext.Provider value={{ className: "h-4 w-4 flex-none" }}>
                    {logo ? logo : <FaFileAlt />}
                    
                </IconContext.Provider>
                <span>{title}</span>
                
            </p>
            {(selectOptions !== null 
                && selectOptions !== undefined 
                && typeof selectOptions === 'object' 
                && Object.keys(selectOptions).length > 0 ) ? 
                <div>
                    <select 
                        name={selectOptions.name}
                        className="form-input w-full rounded-sm px-1 py-[0.1rem] text-sm border border-gray-400" 
                        onChange={selectOptions.onChange}
                        value={selectOptions.value}
                    >
                        <option key="0" value="">{selectOptions.label}</option>
                        {(selectOptions.options !== null 
                            && selectOptions.options !== undefined 
                            && Array.isArray(selectOptions.options) 
                            && selectOptions.options.length > 0) ?
                        selectOptions.options.map(option => {
                            return <option key={option.key} value={option.key}>{option.value}</option>
                        })
                        : null}
                    </select>
                </div>
            : null
            }
        </div>
    ) 
}

