function usePartyValidation() {
    return {
        supplier_name: {
            required: "Bu alan zorunludur.", 
            minLength: {value: 4, 
                message: "En az 4 karakter olmalı"}, 
            maxLength: {value: 255, 
                        message:"En fazla 255 karakter olmalı"},
        },
        tax_number: {
            required: "Bu alan zorunludur.", 
            pattern: {value: /^[0-9]{10,11}$/,
                        message: "10 veya 11 haneli rakam olmalı"},
        }, 
        supplier_email: {
            required: "Bu alan zorunludur.", 
            minLength: {value: 4, 
                message: "En az 4 karakter olmalı"}, 
            maxLength: {value: 255, 
                        message:"En fazla 255 karakter olmalı"},
            pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Geçerli bir e-posta adresi olmalı"}
        },
        
    }
}

export {usePartyValidation}