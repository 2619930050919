import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams, useLocation } from 'react-router-dom'
import SendRequest from 'utils/SendRequest'
import { ButtonExcel } from 'elements/buttons/actionButtons'
import ProposalTable from 'modules/companyModule/pages/products/product/ProposalTable'
import ExportXslx from 'modules/companyModule/pages/products/product/ExportXslx'

function AcceptedProposals({product}) {
    
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [proposalData, setProposalData] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const getProposalData = async () => {
            let proposalpage = searchParams.get("proposalpage")
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/products/${product.id}/proposals?page=${proposalpage}`)
                .then(response => {
                    if(response.status === 200) {
                        setProposalData(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if(product) getProposalData()                     
    }, [product])

    const handleExport = async () => {
        const getProductList = async () => {
            return new Promise(async (resolve) => {
                await SendRequest('GET', `api/companies/${companyId}/products/${product.id}/proposals?no_pagination=true`)
                    .then(response => {
                        if(response.status === 200) {
                           resolve(response.data.data) 
                        } else {
                            resolve(null)
                        }                       
                    })
                    .catch(err => {
                        resolve(null)
                    })
            })
        }
        setLoading(true)
        await getProductList()
                .then(result => {
                    if(result !== null) {
                        ExportXslx(result, 'Tamamlanan Satın Alımlar')
                    }
                })
                .finally(() => {
                    setLoading(false)
                })        
    }
    
    return (
        <div>
            <div className="pb-2 my-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-xl text-gray-800 font-semibold">Tamamlanan Satın Alımlar</h2>    
                <ButtonExcel addClass='w-full md:w-auto' onClick={handleExport}>
                </ButtonExcel>        
            </div>
            <ProposalTable data={proposalData} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 
        </div>
    )
}

export default AcceptedProposals