import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonOk, ButtonClose} from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenProductDemandDeliveryApproval } from 'stores/DrawersSlice' 
import DetailInfo from 'modules/companyModule/pages/productDemands/demands/DetailInfo'
import Loader from 'elements/Loader'

const DeliveryApproveForm = ({productDemand, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const userId = useSelector(state => state.user.user.id)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    
    const handleApprove = async () => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/product_demands/${productDemand.id}/accept_delivery`)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setUpdate(!update)
                        setSuccessMessage('Ürün teslimi başarıyla onaylandı.')
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const handleReject = async () => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/product_demands/${productDemand.id}/reject_delivery`)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setUpdate(!update)
                        setSuccessMessage('Ürün teslimi başarıyla reddedildi.')
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        setStatus(false)
        setError(null)
        setSuccessMessage('')
        dispatch(setIsOpenProductDemandDeliveryApproval())
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message={successMessage} />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <>
            <div className="">
                {(productDemand !==null && Object.keys(productDemand).length > 0) &&
                <>
                <DetailInfo productDemand={productDemand} />
                </>}
            </div>            
            <div className="w-full flex justify-end mt-5 gap-x-4">
                <ButtonClose label="Reddet" type="button" onClick={handleReject}>
                </ButtonClose>
                <ButtonOk label="Onayla" type="button" onClick={handleApprove}>
                </ButtonOk>
            </div>  
            </>       
        }   
        {loading && <Loader />}
        </>
    )
}

export default DeliveryApproveForm