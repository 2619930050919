import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonNew, ButtonNewSmall } from 'elements/buttons/actionButtons'
import { setIsOpenCreate } from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/DrawersSlice'
import TaxonCard from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/TaxonCard'
import Edit from 'modules/companyModule/pages/suppliers/settings/taxonomies/taxa/Edit'
import Loader from 'elements/Loader'

const Taxa = ({taxonomy, parent, actions, update, setUpdate}) => {
    const dispatch = useDispatch()
    
    
    return (
        <div className="w-full mb-4">   
            <TaxonCard actions={actions} taxonomy={taxonomy} />
            {/*  */}
            {/* <Edit taxonomy={taxonForEdit} update={update} setUpdate={setUpdate} />
            <Delete taxonomy={taxonForDelete} update={update} setUpdate={setUpdate} /> */}
        </div>
    )
}

export default Taxa