function UserTable({user}) {
    if(user !== null) {
        return (         
            <table className="w-full text-sm">
                <tbody>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Ad Soyad:</td>
                        <td className="px-2 py-3">{user.name}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">E-Posta:</td>
                        <td className="px-2 py-3">{user.email}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Telefon:</td>
                        <td className="px-2 py-3">{user.phone}</td>
                    </tr>
                </tbody>
            </table>       
        )
    }
    return null
    
}

export default UserTable