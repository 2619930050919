import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { IconContext } from "react-icons"
import { BsCardHeading } from 'react-icons/bs'
import { AiOutlineTag } from 'react-icons/ai'
import { BiCategoryAlt, BiCalendarPlus, BiCalendarMinus, BiInfoCircle } from 'react-icons/bi'
import { FaEnvelopeOpenText } from 'react-icons/fa'
import {StatusFormatText} from 'modules/companyModule/pages/procurementProcesses/procurements/StatusFormat'
import { ProcurementProposalTypes } from 'data'
import { activeProposals } from './utils'

function ProcurementSummaryInfo({procurement}) {
    const proposalTypeDesc = (code) => {
        let selectedCode = ProcurementProposalTypes().filter(a => {
            return a.key == code
        })
        if(selectedCode.length > 0 ) {
            return selectedCode[0].value.slice(0,25)+'...'
        }
        return ''
    }

    const sorted = useMemo(
        () => activeProposals(procurement),
        [procurement]
    )
    return (
        
                <IconContext.Provider value={{ className: "h-4 w-4" }}>
                    
                <div className="bg-white text-gray-700 px-4 py-2 flex-grow-0 pr-12 text-xs border border-gray-400">
                    <table className="w-full">
                        <tbody>
                            <tr>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    <p className="flex items-center justify-start gap-x-2">
                                        <AiOutlineTag />
                                        <span className="font-semibold whitespace-nowrap">Teklif Toplama No:</span> 
                                    </p>
                                </td>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    {procurement.procurement_no}
                                </td>
                            </tr>
                            <tr>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    <p className="flex items-center justify-start gap-x-2">
                                        <BsCardHeading />
                                        <span className="font-semibold">Başlık:</span> 
                                    </p>
                                </td>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    {procurement.title}
                                </td>
                            </tr>
                            <tr>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    <p className="flex items-center justify-start gap-x-2">
                                        <BiCalendarPlus />
                                        <span className="font-semibold">Başlangıç:</span> 
                                    </p>
                                </td>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    {DateTime.fromISO(procurement.start_date).toFormat('dd.LL.yyyy T')}
                                </td>
                            </tr>
                            <tr>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    <p className="flex items-center justify-start gap-x-2">
                                        <BiCalendarMinus />
                                        <span className="font-semibold">Bitiş:</span> 
                                    </p>
                                </td>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    {DateTime.fromISO(procurement.end_date).toFormat('dd.LL.yyyy T')}
                                </td>
                            </tr>
                            <tr>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    <p className="flex items-center justify-start gap-x-2">
                                        <BiCategoryAlt />
                                        <span className="font-semibold whitespace-nowrap">Teklif Türü:</span> 
                                    </p>
                                </td>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    {proposalTypeDesc(procurement.proposal_type)}
                                </td>
                            </tr>
                            <tr>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    <p className="flex items-center justify-start gap-x-2">
                                        <BiInfoCircle />
                                        <span className="font-semibold">Durum:</span> 
                                    </p>
                                </td>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    <StatusFormatText status={procurement.status} />
                                </td>
                            </tr>
                            <tr>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    <p className="flex items-center justify-start gap-x-2">
                                        <FaEnvelopeOpenText />
                                        <span className="font-semibold">Teklif Sayısı:</span> 
                                    </p>
                                </td>
                                <td className='px-1 py-2 whitespace-nowrap'>
                                    {sorted.length} Adet
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    
                    
                    
                </div>
                </IconContext.Provider>
    )
}

export default ProcurementSummaryInfo