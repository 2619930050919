import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import FindCategory from 'utils/FindCategory'
import {InputSmall, SelectSmall} from 'elements/inputs'
import DropdownTree from 'elements/inputs/DropdownTree'
import {ButtonFilter, ButtonReset} from 'elements/buttons/actionButtons'
import { SupplierStatusType } from 'data'

function TableFilter({isFilterOpen, setIsFilterOpen, filters, setFilters, setSearchParams, taxonomies}) {
    const [taxonomySelectArray, setTaxonomySelectArray] = useState([])
    const [filteredDistricts, setFilteredDistricts] = useState([])
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const cities = useSelector(state => state.sharedData.cities)
    const districts = useSelector(state => state.sharedData.districts)

    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            search: null,
            status: null,
            business_area_id: null,
            city_id: null,
            district_id: null,
            taxonomies: {},
        }
    })

    const watchCityId = watch("city_id")

    useEffect(() => {
        const modifyTaxArray = (taxArray) => {
            if(taxArray && Array.isArray(taxArray)) {
                return taxArray.sort((a,b) => a.taxon_name.localeCompare(b.taxon_name)).map(item => {
                            return {
                                key: item.id,
                                value: item.taxon_name,
                                children: modifyTaxArray(item.all_sub_taxa)
                            }
                        })
            }
            return null
        }
    
        
        const taxArray = (taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) ?
                            taxonomies.sort((a,b) => a.taxonomy_name.localeCompare(b.taxonomy_name)).map(t => {
                                setValue(`taxonomies.${t.id}`, null)
                                return {
                                    id: t.id,
                                    name: t.taxonomy_name,
                                    nodes: modifyTaxArray(t.taxa)
                                }
                            }) :
                            []
        setTaxonomySelectArray(taxArray)
    }, [taxonomies])

    const handleForm = async (data) => {
        setSearchParams('page=1')
        setIsFilterOpen(false)
        setFilters(data)
    }

    const handleReset = () => {
        reset()
        handleSubmit(handleForm)()
    }

    const handleChangeCity = (id) => {
        setValue('district_id', null)
        if(id) {
            let filteredData = districts.filter(item => {
                return item.cityId === parseInt(id)
            })
            setFilteredDistricts(filteredData)
        }
    }

    return (
        <div className={`flex-none border border-gray-200 bg-white overflow-hidden transition-[width] duration-300 ease-linear ${isFilterOpen ? 'w-full md:w-[250px] h-auto md:min-h-[500px] p-3' : 'w-0 h-0 p-0'}`}>
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12">
                        <InputSmall 
                            type='search'
                            name='search'
                            register={register}
                            label= 'Ara'
                            placeholder='Müşteri İsmi, Vergi No'
                            validator={{}}
                            error={errors?.search?.message}
                        />
                        <SelectSmall                                 
                            type='select'
                            name='status'
                            register={register}
                            label='Durum'
                            placeholder='Durum'
                            validator={{}}
                            error={errors?.status?.message}
                            data={SupplierStatusType()}
                            defaultOption='Tümü'
                        />
                        <SelectSmall                                 
                            type='select'
                            name='business_area_id'
                            register={register}
                            label='Sektör'
                            placeholder='Sektör'
                            validator={{}}
                            error={errors?.business_area_id?.message}
                            data={businessAreas}
                            defaultOption='Tümü'
                        />
                        <SelectSmall                                 
                            type='select'
                            name='city_id'
                            register={register}
                            label='İl'
                            placeholder='İl'
                            validator={{
                                onChange: (e) => handleChangeCity(e.target.value)
                            }}
                            error={errors?.city_id?.message}
                            data={cities}
                            defaultOption='Tümü'
                        />
                        {watchCityId ? 
                        <SelectSmall                                 
                            type='select'
                            name='district_id'
                            register={register}
                            label='İlçe'
                            placeholder='İlçe'
                            validator={{}}
                            error={errors?.district_id?.message}
                            data={filteredDistricts}
                            defaultOption='Tümü'
                        /> :
                        null
                        }
                    </div>
                </div>
                <div className='my-4'>
                    <p className="mt-2 pb-0.5 mb-2 border-b border-gray-700 text-gray-700">Kategoriler</p>
                    {(taxonomySelectArray && Array.isArray(taxonomySelectArray) && taxonomySelectArray.length > 0) ?
                        taxonomySelectArray.map(ta => {
                            return (
                                <div key={`taxonomy_${ta.id}`} className="mb-2">
                                    <DropdownTree                                         
                                        data = {ta.nodes}
                                        control = {control} 
                                        name = {`taxonomies.${ta.id}`} 
                                        validator = {{
                                            
                                        }}
                                        error = {errors?.taxonomies?.[ta.id]?.message}
                                        actions = {(e) => null}
                                        placeholder = "Seçiniz"                                    
                                        label = {ta.name}
                                        nullOption = {true}
                                    />
                                </div>
                            )
                        })
                    : null}
                </div>
                <div className="w-full flex justify-end gap-x-2 mt-5">
                    <ButtonReset type="button" onClick={() => handleReset()}>
                    </ButtonReset>
                    <ButtonFilter type="submit">
                    </ButtonFilter>
                </div>
            </form>   
        </div>
    )
}

export default TableFilter