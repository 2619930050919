import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IconContext } from "react-icons"
import { TbRefresh } from 'react-icons/tb'
import { FaChartArea } from 'react-icons/fa'
import SendRequest from 'utils/SendRequest'
import { TablePlaceholder } from 'elements/loaders'
import Card from 'elements/cards'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

function MinStocks({selectedWarehouse}) {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [stockData, setStockData] = useState([])

    useEffect(() => {
        const getProductDemands = async () => {
            let page = '1'
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/statistics/min_stocks/${selectedWarehouse}`)
                .then(response => {
                    if(response.status === 200) {
                        setStockData(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if(selectedWarehouse) getProductDemands()
    }, [updateData, selectedWarehouse])

    const RefreshButton = () => {
        return (
            <IconContext.Provider value={{ className: "h-4 w-4 flex-none" }}>
            <button onClick={() => setUpdateData(prev => !prev)}><TbRefresh /></button>
            </IconContext.Provider>
        )
    }

    return (
        <Card 
            title='Stoğu Azalan Ürünler' 
            icon={<FaChartArea />} 
            titleButton={<RefreshButton />}
        >        
            <table className="w-full">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-1">Ürün</th>
                        <th className="py-1 px-1">Stok</th>
                    </tr>
                </thead>
                {loading ? 
                    <TablePlaceholder lineCount={5} colspan={4} />
                : 
                <tbody>
                    {stockData !== null && Array.isArray(stockData) && stockData.length > 0 ?
                        stockData.map(stock => {
                            return (
                                <tr key={stock.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">
                                        {stock.product.name.length > 25 ? 
                                        stock.product.name.slice(0, 24) + ' ...' : 
                                        stock.product.name}
                                    </td>
                                    <td className="px-2 py-3 text-center">
                                        {FNWS(stock.quantity, 2)} {stock.unit.description}
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan={3}>Stok verisi bulunamadı</td>
                        </tr>
                    }
                </tbody>
                }
            </table>
            
        </Card>
    )
}

export default MinStocks