import axios from 'axios'

export default function SendRequest(method = 'GET', url, data = null) {
    const apiRoute = process.env.REACT_APP_API_BASE_URL
    axios.defaults.withCredentials = true;
    switch(method) {
        case 'GET':
            return axios.get(apiRoute + url)
            break
        case 'POST':
            return axios.post(apiRoute + url, data)
            break                
        case 'PUT':
            return axios.put(apiRoute + url, data)
            break                
        case 'DELETE':
            return axios.delete(apiRoute + url)
            break                
        case 'DOWNLOAD':
            return axios.get(apiRoute + url, {responseType: 'blob'})
            break                
    }
}