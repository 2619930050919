import logo from 'assets/img/favicon.png'

function TablePlaceholder({lineCount, lineheight= 'h-4', colspan = 1}) {
    const tablebody = Array.from({length: lineCount}, (v, i) => {
        return (
        <tr key={i} className="border-b border-t border-gray-300 even:bg-gray-200">
            <td className="px-2 py-3" colSpan={colspan}><div className={`animate-pulse w-full bg-slate-200 ${lineheight}`}></div></td>
        </tr>
        )
    })
    
    return (
        <tbody>{tablebody}</tbody>
    )
}

function CardLoader() {
    return (
        <div className="absolute inset-0 flex flex-col items-center justify-center z-40 bg-sky-300 opacity-50 text-sky-800 font-semibold">
            <img src={logo} className="animate-loading" />
            <p className="text-2xl">Yükleniyor<span className="loading-dots"></span></p>
        </div>
    )
}

export { TablePlaceholder, CardLoader }