import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonNew, ButtonOk} from 'elements/buttons/actionButtons'
import {InputSmall, TextareaSmall} from 'elements/inputs'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenCreate } from 'modules/companyModule/pages/products/settings/taxonomies/taxa/DrawersSlice'
import { useTaxonInput } from 'modules/companyModule/pages/products/settings/taxonomies/taxa/InputArray'
import Loader from 'elements/Loader'

const CreateForm = ({taxonomy, parent, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany?.id)
    const isOpenCreate = useSelector(state => state.companyProductTaxonDrawers.isOpenCreate)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, resetField, watch, control, setValue } = useForm({
        defaultValues: {
            taxon_name: null,
            type: 'product',
            parent_id: null,
            taxonomy_id: null,
        }
    })
    
    const inputs = useTaxonInput(errors)

    useEffect(() => {
        setValue('taxonomy_id', taxonomy?.id ?? null)
        setValue('parent_id', parent?.id ?? null)
    }, [taxonomy, parent])

    useEffect(() => {
        if(!isOpenCreate) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenCreate])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/taxa`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const redo = () => {
        resetField("taxon_name")
        setError(null)
        setStatus(false)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenCreate())
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <form onSubmit={handleSubmit(handleForm)}>
                <p className="text-gray-700 text-xs">
                    Sınıflandırma
                </p>
                <p className="mb-1 w-full rounded-sm px-1 py-1 text-xs border border-gray-400 cursor-not-allowed">
                     {taxonomy?.taxonomy_name}
                </p>
                <p className="text-gray-700 text-xs">
                    Üst Sınıf
                </p>
                <p className="mb-1 w-full rounded-sm px-1 py-1 text-xs border border-gray-400 cursor-not-allowed">
                     {parent?.taxon_name ?? 'Yok'}
                </p>
                
                <div className="grid grid-cols-12 gap-2">
                {inputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <InputSmall register={register} {...input} />
                        </div>
                        )
                })}                
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonSave type="submit">
                    </ButtonSave>
                </div>                
            </form> 
        }   
        {loading && <Loader />}
        </>
    )
}

export default CreateForm