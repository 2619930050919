import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import {ButtonSave, ButtonClose, ButtonDeleteSmall, ButtonNewSmall, ButtonOk} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, Checkbox} from 'elements/inputs'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenDepartmentAddUsers } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

const EditDepartmentUsersForm = ({department, users, positions, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenEdit = useSelector(state => state.drawers.isOpenDepartmentAddUsers)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)

    const userDefaults = {user_id: null, position_id: null}

    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue } = useForm({
        defaultValues: {users: [userDefaults]}
    })

    const { 
        fields: fields, 
        append: append, 
        remove: remove, 
        } = useFieldArray({
        control,
        name: "users"
    })

    const watchUsers = watch("users");
    const controlledUserFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchUsers[index]
        }
    })

    const userInputs = [
        {
            key: "userInput1",
            type: "select",
            nameLast: "user_id",
            placeholder: "Kullanıcı",
            label: null,
            validator: {
                required: "Bu alan zorunludur.", 
            },
            data: users,
            cols: 'col-span-6', 
        },
        {
            key: "userInput2",
            type: "select",
            nameLast: "position_id",
            placeholder: "Kullanıcı",
            label: null,
            validator: {
                required: "Bu alan zorunludur.", 
            },
            data: positions,
            cols: 'col-span-4', 
        },
    ]

    useEffect(() => {
        if(!isOpenEdit) {
            reset({users: [userDefaults]})
            setStatus(false)
            setError(null)
            dispatch(setIsOpenDepartmentAddUsers())
        }
        if(isOpenEdit) {
            if(department != null && Object.keys(department).length > 0) {
                const getDepartmentUsers = async () => {
                    setLoading(true)
                    await SendRequest('GET', `api/companies/${companyId}/departments/${department.id}/users`)
                        .then(response => {
                            if(response.status === 200 && response.data.data.length > 0) {
                                let userItems = response.data.data.map(user => {
                                    return {user_id: user.department_user.id, position_id: user.position.id}
                                })
                                reset({users: userItems})
                            }
                            setLoading(false)
                        })
                        .catch(err => {
                            setLoading(false)
                        })
                }    
                getDepartmentUsers()
            } else {
                reset({users: [userDefaults]})
            }
        }
    }, [isOpenEdit])    

    const handleForm = async (data) => {
        
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/departments/${department.id}/users`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenDepartmentAddUsers())
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <div className="grid grid-cols-12 gap-2">
                        
            </div>
            <div>
                <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                    <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>#</span>
                    </div>
                    <div className='col-span-6 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Kullanıcı</span>
                    </div>
                    <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Pozisyon</span>
                    </div>
                    <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'></span>
                    </div>
                </div>
                {controlledUserFields.map((field, index) => {
                        return (
                            <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${index}`}>
                                <div className='col-span-1 mt-2'>
                                    <div>
                                        {index+1}.
                                    </div>
                                </div>
                                <div className={userInputs[0].cols}>
                                    <SelectSmall 
                                        {...userInputs[0]}
                                        register={register} 
                                        name={`users.${index}.${userInputs[0].nameLast}`} 
                                        error={errors?.users?.[index]?.[userInputs[0].nameLast]?.message} 
                                    />
                                </div>
                                <div className={userInputs[1].cols}>
                                    <SelectSmall 
                                        {...userInputs[1]}
                                        register={register} 
                                        name={`users.${index}.${userInputs[1].nameLast}`} 
                                        error={errors?.users?.[index]?.[userInputs[1].nameLast]?.message} 
                                    />
                                </div>
                                <div className='col-span-1 mt-0.5'>
                                    <div>
                                        {
                                        index === 0 
                                        ? <ButtonNewSmall label="Ekle" addClass='w-full' type="button" onClick={() => append(userDefaults)} ></ButtonNewSmall>
                                        : <ButtonDeleteSmall addClass='w-full' type="button" onClick={() => remove(index)} ></ButtonDeleteSmall>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div>                
        </form>   
        {loading && <Loader />}
        </>
    )
}

export default EditDepartmentUsersForm