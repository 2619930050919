import { DateTime } from 'luxon'
import { IconContext } from "react-icons"
import { MdOutlineBusinessCenter } from 'react-icons/md'
import { BsCardHeading } from 'react-icons/bs'
import { BiCategoryAlt, BiPhone, BiEnvelope, BiCalendarCheck } from 'react-icons/bi'
import { GrMapLocation } from 'react-icons/gr'
import {CgListTree} from 'react-icons/cg'
import BusinessAreaCell from 'modules/companyModule/pages/suppliers/BusinessAreaCell'
import CompanyTypeCell from 'modules/companyModule/pages/suppliers/CompanyTypeCell'

function SupplierInfo({supplier}) {
    
    if(supplier) {
        
        return (
            <IconContext.Provider value={{ className: "h-4 w-4" }}>
                <div className="lg:flex flex-col justify-between gap-0 bg-white text-gray-700 px-4 py-2 flex-grow-0 pr-12 text-sm border border-gray-400">
                    <p className="flex items-center justify-start gap-x-2">
                        <MdOutlineBusinessCenter />
                        <span className="font-semibold">Firma Adı:</span> 
                        <span>{supplier.company_name}</span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <BiCategoryAlt />
                        <span className="font-semibold">Sektör:</span> 
                        <span><BusinessAreaCell businessAreas={supplier.business_areas} /></span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <CgListTree />
                        <span className="font-semibold">Şirket Türü:</span> 
                        <span><CompanyTypeCell type={supplier.company_type} /></span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <BsCardHeading />
                        {supplier.company_type === 1 ? 
                        <>
                        <span className="font-semibold">TC/Vergi No:</span> 
                        <span>{supplier.identity_number} /{supplier.tax_number} / {supplier.tax_department}</span>
                        </>
                        : 
                        <>
                        <span className="font-semibold">Vergi No:</span> 
                        <span>{supplier.tax_number} / {supplier.tax_department}</span>
                        </>
                        }
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <GrMapLocation />
                        <span className="font-semibold">Adres:</span> 
                        <span>{supplier.address} {supplier.district.name}/{supplier.city.name}</span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <BiPhone />
                        <span className="font-semibold">Telefon:</span> 
                        <span>{supplier.phone}</span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <BiEnvelope />
                        <span className="font-semibold">E-Posta:</span> 
                        <span>{supplier.email}</span>
                    </p>
                    <p className="flex items-center justify-start gap-x-2">
                        <BiCalendarCheck />
                        <span className="font-semibold">Kayıt Tarihi:</span> 
                        <span>{DateTime.fromISO(supplier.created_at).toFormat('dd.LL.yyyy')}</span>
                    </p>
                </div>
            </IconContext.Provider>
        )
    }

    return null
    
}

export default SupplierInfo