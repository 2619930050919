import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierInfoForm } from 'stores/DrawersSlice' 
import InfoFormForm from 'modules/supplierModule/pages/companies/infoForm/InfoFormForm'

function InfoForm() {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenSupplierInfoForm)
    const setIsOpenDetail = () => dispatch(setIsOpenSupplierInfoForm())
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Tedarikçi Bilgi Formu" maxWidth="max-w-[910px]">
            <InfoFormForm />
        </FormDrawer> 
    )
}

export default InfoForm