import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {ButtonCancelAlt, ButtonCancel, ButtonOk, ButtonDelete} from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenSupplierReferenceDelete } from 'stores/DrawersSlice' 
import ReferenceInfo from 'modules/supplierModule/pages/companies/references/ReferenceInfo'
import Loader from 'elements/Loader'

const DeleteForm = ({reference, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany?.id)
    const isOpenDelete = useSelector(state => state.drawers.isOpenSupplierReferenceDelete)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleDelete = async (data) => {
        setLoading(true)
        await SendRequest('DELETE', `api/suppliers/${companyId}/references/${reference.id}`, data)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    useEffect(() => {
        if(!isOpenDelete) {
            setStatus(false)
            setError(null)
        }
    }, [isOpenDelete])

    const close = () => {
        setStatus(false)
        setError(null)
        dispatch(setIsOpenSupplierReferenceDelete())
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla silindi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonOk onClick={close}>
                </ButtonOk>
            </div>  
            </>
            :
            <>
            <p className="text-sm font-semibold text-gray-700 mb-0.5">
                Aşağıda detayları verilen referans bilgisini silmek istediğinizden emin misiniz? 
            </p>
            <p className="text-sm font-semibold text-red-500 mb-2">Not: Silme İşlemi Geri Alınamaz! </p>
            <ReferenceInfo reference={reference} />
            <div className="w-full flex justify-end gap-x-1 mt-5">
                <ButtonDelete label="Referansı Sil" type="button" onClick={handleDelete}>
                </ButtonDelete>
                <ButtonCancelAlt onClick={close}>
                </ButtonCancelAlt>
            </div>  
            </>
        }   
        {loading && <Loader />}
        </>
    )
}

export default DeleteForm