import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {ButtonClose} from 'elements/buttons/actionButtons'
import { setIsOpenProcurementRequestWarehouseDetail } from 'stores/DrawersSlice' 
import DetailInfo from 'modules/companyModule/pages/procurementProcesses/warehouseRequests/DetailInfo'

const DetailForm = ({procurementRequest}) => {
    const dispatch = useDispatch()
    
    
    
    const close = () => {
        dispatch(setIsOpenProcurementRequestWarehouseDetail())
    }

    return (
        <>       
            <DetailInfo procurementRequest={procurementRequest} />
            <div className="w-full flex justify-end mt-5">
                <ButtonClose type="button" onClick={close}>
                </ButtonClose>
            </div>               
        </>
    )
}

export default DetailForm