import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProductStockCorrectionList } from 'stores/DrawersSlice' 
import StockCorrectionListForm from 'modules/companyModule/pages/warehouses/stocks/StockCorrectionListForm'

function StockCorrectionList({product, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenWarehouseProductStockCorrectionList)
    const setIsOpen = () => dispatch(setIsOpenWarehouseProductStockCorrectionList())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Stok Düzeltme Listesi" >
            <StockCorrectionListForm product={product} selectedWarehouse={selectedWarehouse} />
        </FormDrawer> 
    )
}

export default StockCorrectionList