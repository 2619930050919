import { useState } from 'react'
import { useSelector } from 'react-redux' 
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'

function Documents({supplierId, documents, documentList}) {
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [loading, setLoading] = useState(false)

    const downloadDocument = (doc_code) => {
        const getDocument = async () => {
            setLoading(true)
            await SendRequest('DOWNLOAD', `api/companies/${companyId}/suppliers/${supplierId}/documents/${doc_code}`)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${doc_code}.pdf`)
                        document.body.appendChild(link)
                        link.click()
                        setLoading(false)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getDocument()
    }

    const filteredDocument = (doc) => {
        const filtered = documents.filter(document => document.document_code === doc.code)
        if(filtered.length > 0) return filtered[0]
        return null 
    }

    if(documentList !== null && Array.isArray(documentList) && documentList.length > 0) {
        return (    
            <>     
            <table className="w-full">
                <tbody>
                    {documentList.map(doc => {
                        return (
                            <tr key={doc.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3 font-semibold">
                                    {doc.description} 
                                </td>
                                {filteredDocument(doc) !== null ? 
                                    <td className="px-2 py-3">                                    
                                        <button type="button" 
                                        className="text-md text-sky-500 hover:text-sky-800 underline" 
                                        onClick={() => downloadDocument(doc.code)}
                                        >
                                            İndir
                                        </button>                                                                               
                                    </td>
                                    : 
                                    <td colSpan={2} className="px-2 py-3">
                                        <span className="text-red-700">Dosya yüklenmedi</span>
                                    </td>
                                }                   
                            </tr>
                        )
                    })}
                </tbody>
            </table>    
            {loading && <Loader />}
            </>   
        )
    }
    return null
}

export default Documents