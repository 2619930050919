import { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { IconContext } from "react-icons"
import { FaEnvelopeOpenText, FaRegThumbsUp, FaRegThumbsDown, FaFileInvoice } from 'react-icons/fa'
import { MdOutlineBusinessCenter } from 'react-icons/md'
import { RiHandCoinFill } from 'react-icons/ri'
import { ButtonCancel } from 'elements/buttons/actionButtons'
import { ButtonSkySmall } from 'elements/buttons/index'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'
import { setIsOpenOrderForm, setIsOpenProcurementProposalCancel } from 'stores/DrawersSlice'
import Order from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/Order'
import { activeProposals } from './utils'

function ProposalSummary({procurement}) {
    const dispatch = useDispatch()
    const exchangeRates = useSelector((state) => state.sharedData.exchangeRates)
    const usedRate = 'forexSelling'

    const [proposalForOrder, setProposalForOrder] = useState(null)
    
    const sorted = useMemo(
        () => activeProposals(procurement),
        [procurement]
    )

    const [isAcceptAllowed, setIsAcceptAllowed] = useState(false)

    const userRoles = useSelector(state => state.user.user.roles)
    const userId = useSelector(state => state.user.user.id)

    const auth = {
        procurement_accept_owned: userRoles.find(roles => roles.code === 'procurements_accept_proposal_owned') !== undefined ? true : false,        
        procurement_accept_all: userRoles.find(roles => roles.code === 'procurements_accept_proposal_all') !== undefined ? true : false,        
    }

    useEffect(() => {
        if(auth.procurement_accept_all) setIsAcceptAllowed(true)
        if(auth.procurement_accept_owned && userId === procurement.user?.id) setIsAcceptAllowed(true)

    }, [auth, userId, procurement])
    
    return (
        <div className="flex-grow basis-full flex flex-col lg:flex-row gap-y-4 lg:gap-x-4">
            {sorted.length > 0 ?
            <div className="w-full bg-white px-4 py-2 border border-gray-400">
                <p className="text-base pb-1 border-b border-gray-700 font-semibold">Kabul Edilen Teklif</p>
                {procurement.is_proposal_accepted ? 
                    <>
                    {procurement.accepted_proposals.map(ap => {
                        return (
                            <div key={ap.id} className="py-3">                            
                                <div className="flex items-center justify-between pb-1 mb-1 border-b border-gray-300">
                                    <div className="flex items-center justify-start gap-x-2">
                                        <IconContext.Provider value={{ className: "h-5 w-5 text-gray-500 mr-3" }}>
                                            <RiHandCoinFill />
                                        </IconContext.Provider>
                                        <span className="text-sm">
                                            <span className=''>{ap.supplier.company_name}:</span>
                                        </span> 
                                        <span className="text-sm">
                                            <span className=''>
                                                {FNWS(ap.accepted_price)} {ap.currency.code}
                                            </span>
                                        </span> 
                                    </div>
                                    <div>
                                        <ButtonSkySmall onClick={() => {setProposalForOrder(ap); dispatch(setIsOpenOrderForm(true))}}>
                                            <FaFileInvoice />
                                            <span className="leading-none">Sipariş Formu</span>
                                        </ButtonSkySmall>
                                    </div>
                                </div>         
                            </div>
                        )
                    })}
                    {isAcceptAllowed ? 
                    <div className="flex justify-end">
                        <ButtonCancel label="Teklif Kabulünü İptal Et" onClick={() => dispatch(setIsOpenProcurementProposalCancel(true))} />
                    </div>    
                    : null }
                    </>             
                : 
                <p className="px-2 py-1">Kabul edilmiş teklif yok.</p>
                }
            </div>
            : 
            <div className="w-full bg-white px-4 py-2 border border-gray-400 text-sm leading-none flex items-center justify-center">
                <p className="font-bold border-b border-gray-300 px-2 py-2 mb-1 text-base">Yapılmış herhangi bir teklif bulunamadı.</p>                
            </div>            
            }
            <Order procurement={procurement} proposal={proposalForOrder} />
        </div>
    )
}

export default ProposalSummary