export function useTaxonomyInput(errors) {
    
    return [        
        {
            key: "taxonomyInput1",
            type: "text",
            name: "taxonomy_name",
            placeholder: "Sınıflandırma Adı",
            label: "Sınıflandırma Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.taxonomy_name?.message,
            cols: 'col-span-12',
        },
    ]
}

