import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import DropdownTree from 'elements/inputs/DropdownTree'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FindCategory from 'utils/FindCategory'
import { setIsOpenSupplierEdit } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

const EditForm = ({supplier, update, setUpdate, categories, taxonomies}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [categorySelectArray, setCategorySelectArray] = useState([])
    const [taxonomySelectArray, setTaxonomySelectArray] = useState([])
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            // categories: {},
            taxonomies: {},
        }
    })

    // const watchCategoriesArray = watch("categories")

    useEffect(() => {
        if(supplier !== null && Object.keys(supplier).length > 0) {
            if(taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) (
                taxonomies.forEach(t => {
                    let supplierTaxonomy = supplier?.taxonomies?.find(taxonomy => {
                                                return taxonomy.taxonomy.id.toString() === t.id.toString()
                                            })
                    if(supplierTaxonomy?.taxon) {
                        setValue(`taxonomies.${t.id}`, supplierTaxonomy.taxon.id)
                    } else {
                        setValue(`taxonomies.${t.id}`, null)
                    }
                })
            )
            
            setError(null)
            setStatus(false)
        }
    }, [supplier, taxonomies])

    // useEffect(() => {
    //     const getArrayRecursive = (cats, catArray) => {
    //         let catJson = null
    //         if(supplier && supplier.categories.categories !== null) {
    //             catJson = Object.fromEntries(Object.entries(supplier.categories.categories).map(([k, v]) => [k, v]))
    //         } 
    //         cats.forEach((cat, index) => {
    //             catArray.push({
    //                 id: cat.id, 
    //                 name: cat.category_name, 
    //                 parentId: cat.parent_id,
    //                 parentItemId: cat.parent_item_id,
    //                 options: cat.category_items.map(option => {
    //                 return {key: option.id,
    //                 value: option.item_name}
    //             })})
    //             if(catJson && catJson[cat.id]) {
    //                 setValue(`categories.${cat.id}`, catJson[cat.id])
    //             } else {
    //                 setValue(`categories.${cat.id}`, null)
    //             }
                
    //             if(cat.all_sub_categories.length > 0) {
    //                 getArrayRecursive(cat.all_sub_categories, catArray)
    //             }
    //         })
    //         return catArray
    //     }

    //     const getCategories = async () => {
    //         if(categories) {
    //             let catArray = []
    //             getArrayRecursive(categories, catArray)
    //             setCategorySelectArray(catArray)
    //         }
    //     }    

    //     getCategories()
    // }, [categories, supplier])

    useEffect(() => {
        const modifyTaxArray = (taxArray) => {
            if(taxArray && Array.isArray(taxArray)) {
                return taxArray.sort((a,b) => a.taxon_name.localeCompare(b.taxon_name)).map(item => {
                            return {
                                key: item.id,
                                value: item.taxon_name,
                                children: modifyTaxArray(item.all_sub_taxa)
                            }
                        })
            }
            return null
        }
    
        
        const taxArray = (taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) ?
                            taxonomies.sort((a,b) => a.taxonomy_name.localeCompare(b.taxonomy_name)).map(t => {                                                                
                                return {
                                    id: t.id,
                                    name: t.taxonomy_name,
                                    nodes: modifyTaxArray(t.taxa)
                                }
                            }) :
                            []
        
        setTaxonomySelectArray(taxArray)
    }, [taxonomies])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/suppliers/${supplier.id}`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenSupplierEdit(false))
    }

    // const resetSubCategories = (category) => {
    //     category.all_sub_categories.forEach(subcat => {
    //         setValue(`categories.${subcat.id}`, null)
    //         if(subcat.all_sub_categories.length > 0) {
    //             resetSubCategories(subcat)               
    //         }
    //     })
    // }

    // const handleChangeCategory = (id) => {
    //     let foundCategory = FindCategory(categories, id)
    //     if(foundCategory != null) {
    //         resetSubCategories(foundCategory)
    //     }        
    // }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            {/* <div>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Kategoriler</p>
                {categorySelectArray.map(category => {
                    if(category.parentId == null || watchCategoriesArray[category.parentId] == category.parentItemId) {
                        return (
                            <div key={category.id}>
                                <SelectSmall                                 
                                    type='select'
                                    name={`categories.${category.id}`}
                                    register={register}
                                    label={category.name}
                                    placeholder={category.name}
                                    validator={{
                                        onChange: (e) => handleChangeCategory(category.id)
                                    }}
                                    error={null}
                                    data={category.options}
                                />
                            </div>
                        )
                    }                        
                })
                }                
            </div> */}
            <div className='my-4'>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Kategoriler</p>
                {(taxonomySelectArray && Array.isArray(taxonomySelectArray) && taxonomySelectArray.length > 0) ?
                    taxonomySelectArray.map(ta => {
                        return (
                            <div key={`taxonomy_${ta.id}`} className="mb-2">
                                <DropdownTree                                         
                                    data = {ta.nodes}
                                    control = {control} 
                                    name = {`taxonomies.${ta.id}`} 
                                    validator = {{
                                        
                                    }}
                                    error = {errors?.taxonomies?.[ta.id]?.message}
                                    actions = {(e) => null}
                                    placeholder = "Seçiniz"                                    
                                    label = {ta.name}
                                    nullOption = {true}
                                />
                            </div>
                        )
                    })
                : null}
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div> 
        </form> 
         
        {loading && <Loader />}
        </>
    )
}

export default EditForm