import { useSelector, useDispatch } from 'react-redux'
import ChangePassword from 'modules/authModule/pages/settings/ChangePassword'

const UserSetting = () => {
    const user = useSelector(state => state.user.user)

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-start items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Kullanıcı Ayarları</h2>                
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4">
                <div className="col-span-1 bg-white p-4">
                    {user.login_type === 'standard'  ?
                    <ChangePassword />
                    : null }
                </div>
            </div>
            
        </div>
    )
}

export default UserSetting