import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProcurementRequestWorkflow } from 'stores/DrawersSlice' 
import EditWarehouseProcurementRequestsForm from 'modules/companyModule/pages/companies/settings/warehouses/EditWarehouseProcurementRequestsForm'

function EditWarehouseProcurementRequests({update, setUpdate, warehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenWarehouseProcurementRequestWorkflow)
    const setIsOpenCreate = () => dispatch(setIsOpenWarehouseProcurementRequestWorkflow())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Satın Alma Talebi Onay Sürecini Düzenle">
            <EditWarehouseProcurementRequestsForm 
                update={update} 
                setUpdate={setUpdate} 
                warehouse={warehouse}
            />
        </FormDrawer> 
    )
}

export default EditWarehouseProcurementRequests