import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierDetail } from 'stores/DrawersSlice' 
import DetailForm from 'modules/companyModule/pages/suppliers/details/DetailForm'

function SupplierDetail({supplierId, setLoading}) {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenSupplierDetail)
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const setIsOpenDetail = () => dispatch(setIsOpenSupplierDetail())

    const [supplier, setSupplier] = useState(null)
    const [documentList, setDocumentList] = useState([])

    useEffect(() => {
        const getSupplier = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/suppliers/${supplierId}`)
                .then(response => {
                    if(response.status === 200) {
                        setSupplier(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if(supplierId) getSupplier()
    }, [supplierId])

    useEffect(() => {
        const getDocumentList = async () => {
            setLoading(true)
            await SendRequest('GET', `api/supplier_document_lists`)
                .then(response => {
                    if(response.status === 200) {
                        setDocumentList(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getDocumentList()
    }, [])
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Tedarikçi Detayı" maxWidth="max-w-4xl">
            <DetailForm supplier={supplier} documentList={documentList} />
        </FormDrawer> 
    )
}

export default SupplierDetail