import { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import {ButtonNew, ButtonOk, ButtonSave, ButtonClose, ButtonNewSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, Radio, CheckboxSmall} from 'elements/inputs'
import {PhoneSmall} from 'elements/inputs/Phone'
import RadioTree from 'elements/inputs/RadioTree'
import DropdownTree from 'elements/inputs/DropdownTree'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenCompanyUserCreate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useUserInput, useDepartmentEditInput } from 'modules/companyModule/pages/companies/settings/users/InputArray'

const CreateForm = ({update, setUpdate, roles, departments, positions}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenCreate = useSelector((state) => state.drawers.isOpenCompanyUserCreate)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const departmentDefaults = {department_id: null, position_id: null}
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            is_social_user: false,
            name: null,
            email: null,
            phone: null,
            password: null,
            password_confirmation: null,
            roles: null,  
            departments: [departmentDefaults]     
        }
    })

    useEffect(() => {
        if(!isOpenCreate) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenCreate])

    const { 
        fields: fields, 
        append: append, 
        remove: remove, 
        } = useFieldArray({
        control,
        name: "departments"
    })

    const watchDepartments = watch("departments")
    const watchIsSocialUser = watch('is_social_user')

    const controlledDepartmentFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchDepartments[index]
        }
    })

    const userInputs = useUserInput(errors, getValues)
    const departmentInputs = useDepartmentEditInput(positions, departments)

    const handleForm = async (data) => {
        const filteredRoles = Object.keys(data.roles).filter(key => data.roles[key])
        const filteredData = {...data, roles: filteredRoles}
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/users`, filteredData)
                .then(res => {
                    if(res.status === 204) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenCompanyUserCreate())
    }

    return (
        <>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-6rem)]">
                    <div className='w-full'>
                        <div className="text-red-500 mt-4">
                            {error && <ServerError errorData={error} />}
                        </div>
                    </div>
                    <div className="w-full flex-1 overflow-y-auto">
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Kullanıcı Bilgileri</p>
                        <div className="grid grid-cols-12 gap-2">
                        {userInputs.map(input => {
                            return (
                                <Fragment key={input.key}>
                                    {(!watchIsSocialUser || ['userInput0', 'userInput3'].includes(input.key)) ?
                                        <div className={input.cols}>
                                            {input.type == 'select' ? 
                                                <SelectSmall register={register} {...input} /> :
                                                null
                                            }
                                            {input.type == 'text' ||  input.type == 'password'? 
                                                <InputSmall register={register} {...input} /> :
                                                null
                                            }
                                            {input.type == 'phone'? 
                                                <PhoneSmall register={register} {...input} /> :
                                                null
                                            }
                                            {input.type == 'radio_tree' ? 
                                                <RadioTree register={register} {...input} /> :
                                                null
                                            }
                                            {input.type == 'checkbox' ? 
                                                <CheckboxSmall register={register} {...input} /> :
                                                null
                                            }
                                        </div>
                                    : null}
                                    
                                </Fragment>
                                )
                        })}                
                        </div>
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Yetkiler</p>
                        <div className="grid grid-cols-12 gap-2 max-h-[13rem] overflow-y-auto p-2 border border-gray-300">
                            {roles.map(role => {
                                return (
                                    <div key={role.id} className="col-span-12 flex items-start justify-start gap-x-2">
                                    <CheckboxSmall 
                                        type="checkbox"
                                        name={`roles.${role.id}`}
                                        register={register}
                                        label={role.description}
                                        validator={{}}
                                    />
                                    </div>
                                )
                            })}
                        </div>
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Departmanlar</p>
                        <div>
                            <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                                <span className='text-[12px] line-clamp-1'>#</span>
                                </div>
                                <div className='col-span-6 flex flex-col gap-y-1 items-start'>
                                <span className='text-[12px] line-clamp-1'>Departman</span>
                                </div>
                                <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                                <span className='text-[12px] line-clamp-1'>Pozisyon</span>
                                </div>
                                <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                                <span className='text-[12px] line-clamp-1'></span>
                                </div>
                            </div>
                            {controlledDepartmentFields.map((field, index) => {
                                    return (
                                        <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${field.id}`}>
                                            <div className='col-span-1 mt-2'>
                                                <div>
                                                    {index+1}.
                                                </div>
                                            </div>
                                            <div className={departmentInputs[0].cols}>
                                                <DropdownTree       
                                                    {...departmentInputs[0]}
                                                    control={control}
                                                    name={`departments.${index}.${departmentInputs[0].nameLast}`} 
                                                    error = {errors?.departments?.[index]?.[departmentInputs[0].nameLast]?.message}
                                                    actions = {(e) => null}
                                                />
                                            </div>
                                            <div className={departmentInputs[1].cols}>
                                                <SelectSmall 
                                                    {...departmentInputs[1]}
                                                    register={register} 
                                                    name={`departments.${index}.${departmentInputs[1].nameLast}`} 
                                                    error={errors?.departments?.[index]?.[departmentInputs[1].nameLast]?.message} 
                                                />
                                                
                                            </div>
                                            <div className='col-span-1 mt-0.5'>
                                                <div>
                                                    {
                                                    index === 0 
                                                    ? <ButtonNewSmall label={null} addClass='w-full' type="button" onClick={() => append(departmentDefaults)} ></ButtonNewSmall>
                                                    : <ButtonDeleteSmall label={null} addClass='w-full' type="button" onClick={() => remove(index)} ></ButtonDeleteSmall>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <div className="w-full flex justify-end mt-5 gap-x-2">
                        <ButtonSave type="submit">
                        </ButtonSave>
                        <ButtonClose onClick={() => close()}>
                        </ButtonClose>
                    </div>
                </div>      
            </form> 
        }   
        {loading && <Loader />}
        </>
    )
}

export default CreateForm