import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import { FaBoxes } from 'react-icons/fa'
import ProductDemands from 'modules/companyModule/pages/home/warehouses/productDemands'
import Stocks from 'modules/companyModule/pages/home/warehouses/stocks'
import ProcuementRequests from 'modules/companyModule/pages/home/warehouses/procurementRequests'

import Header from 'modules/companyModule/pages/home/Header'

function WarehouseHome()  {

    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [warehouses, setWarehouses] = useState(null)
    const [selectedWarehouse, setSelectedWarehouse] = useState('')

    const userRoles = useSelector(state => state.user.user.roles)

    const auth = {
        warehouse_product_demand: userRoles.find(roles => roles.code === 'warehouse_product_demands') !== undefined ? true : false,
        warehouse_stock: userRoles.find(roles => roles.code === 'warehouse_stocks') !== undefined ? true : false,
        warehouse_procurement_request: userRoles.find(roles => roles.code === 'warehouse_procurement_request') !== undefined ? true : false,
    }

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/statistics/warehouses`)
                .then(response => {
                    if(response.status === 200) {
                        const selectArray = response.data.map(d => {
                            return {
                                key: d.id,
                                value: `${d.warehouse_name} (${d.warehouse_code})`
                            }
                        })
                        setWarehouses(selectArray)
                        if(response.data.length > 0 ) {
                            setSelectedWarehouse(response.data[0].id)
                        }
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getData()
    }, [updateData])

    const changeSelectedWarehouse = (e) => {
        if(e.target.value !== '') {
            setSelectedWarehouse(e.target.value)
        } else {
            setSelectedWarehouse('')
        }
    }

    const selectOptions = {
        name: 'warehouses',
        onChange: changeSelectedWarehouse,
        value: selectedWarehouse,
        label: 'Depo Seçiniz',
        options: warehouses
    }

    return (
        <>
        <div className="">
            {auth.warehouse_product_demand ? 
            <ProductDemands selectedWarehouse={selectedWarehouse} selectOptions={selectOptions} />         
            : null
            }  
        </div>
        <div className="">
            {auth.warehouse_procurement_request ? 
            <ProcuementRequests selectedWarehouse={selectedWarehouse} selectOptions={selectOptions} />         
            : null
            } 
        </div>
        <div className="">
            {auth.warehouse_stock ? 
            <Stocks selectedWarehouse={selectedWarehouse} selectOptions={selectOptions} />         
            : null
            }  
        </div>      
        </>
    )
}

export default WarehouseHome