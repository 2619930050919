import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {ButtonSave, ButtonOk} from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenCompanyUserStatusEdit } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

const DeleteForm = ({user, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
   
    const handleDelete = async () => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/users/${user.id}/toggle_status`)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        setStatus(false)
        setError(null)
        dispatch(setIsOpenCompanyUserStatusEdit())
    }

    const GetStatus = () => {
        if(user.is_active) return <span className="text-green-500 font-semibold">Aktif</span>
        return <span className="text-red-500 font-semibold">Pasif</span>
    }

    const GetReverseStatus = () => {
        if(!user.is_active) return <span className="text-green-500 font-semibold">aktif</span>
        return <span className="text-red-500 font-semibold">pasif</span>
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Kullanıcı durumu başarıyla güncellendi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <>
            <div className="">
                {(user !==null && Object.keys(user).length > 0) &&
                <>
                <p className="text-sm font-semibold text-gray-700 mb-2">Aşağıda detayları verilen kullanıcıyı <GetReverseStatus /> yapmak istediğinizden emin misiniz?</p>
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Ad Soyad:</td>
                            <td className="px-2 py-3">{user.name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Telefon:</td>
                            <td className="px-2 py-3">{user.phone}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">E-Posta: </td>
                            <td className="px-2 py-3">{user.email}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Departmanlar: </td>
                            <td className="px-2 py-3">
                                {(user.departments && Array.isArray(user.departments) && user.departments.length > 0) ?
                                    user.departments.map(department => {
                                        return <p key={department.id}>{department.department.department_name} {department.position.position_name}</p>
                                    })
                                : 'Tanımlı departman yok'}
                            </td>
                        </tr>                        
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Yetkiler: </td>
                            <td className="px-2 py-3">
                                {(user.roles && Array.isArray(user.roles) && user.roles.length > 0) ?
                                    user.roles.map(role => {
                                        return <p key={role.id}>{role.description}</p>
                                    })
                                : 'Tanımlı yetki yok'}
                            </td>
                        </tr>                        
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Durum: </td>
                            <td className="px-2 py-3"><GetStatus /></td>
                        </tr>                        
                    </tbody>
                </table>
                </>}
            </div>            
            <div className="w-full flex justify-end mt-5">
                <ButtonSave label={(user?.is_active ? 'Pasif' : 'Aktif') + ' Yap'} type="button" onClick={handleDelete}>
                </ButtonSave>
            </div>  
            </>       
        }   
        {loading && <Loader />}
        </>
    )
}

export default DeleteForm