function Explanation() {
    
    return (
        <div className="">
            <p className="text-justify text-gray-800">Aşağıdaki kurallara göre oluşturacağınız excel dosyasını yan tarafta bulunan dosya yükleme alanından yükleyebilirsiniz.
            Excel dosyasını yüklendikten sonra ön izleme oluşacak. Kontrol ettikten sonra bilgileri içeri aktarabilirsiniz.</p>
            <ul className="list-disc list-outside text-justify text-gray-800 mt-2">
                <li><span className="font-semibold">Tedarikçi Kişi/Firma İsmi:</span> Zorunlu Alan</li>
                <li><span className="font-semibold">Tedarikçi Vergi/TC No:</span> Zorunlu Alan. Şirket türü "Şahıs" ise 11 rakamdan oluşan TC kimlik numarası "Firma" ise 10 rakamdan oluşan verigi numarası</li>
                <li><span className="font-semibold">Tedarikçi E-posta:</span> Zorunlu Alan. Geçerli bir e-posta adresi olmalı.Firmaya davet bu e-posta üzerinden gidecektir.</li>
            </ul>
        </div>
    )
}

export default Explanation