import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import FindCategory from 'utils/FindCategory'
import {InputSmall, SelectSmall} from 'elements/inputs'
import DropdownTree from 'elements/inputs/DropdownTree'
import {ButtonFilter, ButtonReset} from 'elements/buttons/actionButtons'

function TableFilter({isFilterOpen, setIsFilterOpen, filters, setFilters, categories, setSearchParams, taxonomies}) {
    
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            search: null,
            is_seen: null,
            is_registered: null,
        }
    })

    const handleForm = async (data) => {
        setSearchParams('page=1')
        setIsFilterOpen(false)
        setFilters(data)
    }

    const handleReset = () => {
        reset()
        handleSubmit(handleForm)()
    }

    const booleanOptions = [
        {
            key: 1,
            value: 'Evet',
        },
        {
            key: 2,
            value: 'Hayır',
        },
    ]

    return (
        <div className={`flex-none border border-gray-200 bg-white overflow-hidden transition-[width] duration-300 ease-linear ${isFilterOpen ? 'w-full md:w-[250px] h-auto md:min-h-[500px] p-3' : 'w-0 h-0 p-0'}`}>
            <form onSubmit={handleSubmit(handleForm)}>
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12">
                        <InputSmall 
                            type='search'
                            name='search'
                            register={register}
                            label= 'Ara'
                            placeholder='Tedarikçi İsmi, Vergi No'
                            validator={{}}
                            error={errors?.search?.message}
                        />
                        <SelectSmall                                 
                            type='select'
                            name='is_seen'
                            register={register}
                            label='Görüldü'
                            placeholder='Görüldü'
                            validator={{}}
                            error={errors?.is_seen?.message}
                            data={booleanOptions}
                            defaultOption='Tümü'
                        />
                        <SelectSmall                                 
                            type='select'
                            name='is_registered'
                            register={register}
                            label='Kayıt Olundu'
                            placeholder='Kayıt Olundu'
                            validator={{}}
                            error={errors?.is_registered?.message}
                            data={booleanOptions}
                            defaultOption='Tümü'
                        />
                    </div>
                </div>
                <div className="w-full flex justify-end gap-x-2 mt-5">
                    <ButtonReset type="button" onClick={() => handleReset()}>
                    </ButtonReset>
                    <ButtonFilter type="submit">
                    </ButtonFilter>
                </div>
            </form>   
        </div>
    )
}

export default TableFilter