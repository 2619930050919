import { useState, useEffect } from 'react'
import { useOutletContext, useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' 
import { setIsOpenWarehouseProcurementRequestCreate } from 'stores/DrawersSlice'
import { ButtonFilter, ButtonNew, ButtonExcel, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import TableFilter from 'modules/companyModule/pages/warehouses/procurementRequests/TableFilter'
import Table from 'modules/companyModule/pages/warehouses/procurementRequests/Table'
import Create from 'modules/companyModule/pages/warehouses/procurementRequests/Create'
import Detail from 'modules/companyModule/pages/warehouses/procurementRequests/Detail'
import Cancel from 'modules/companyModule/pages/warehouses/procurementRequests/Cancel'
import Deliver from 'modules/companyModule/pages/warehouses/procurementRequests/Deliver'
import UpdateStatus from 'modules/companyModule/pages/warehouses/procurementRequests/UpdateStatus'

function WarehouseProcurementRequests() {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [selectedWarehouse, setSelectedWarehouse] = useOutletContext()
    const user = useSelector(state => state.user.user)

    const [products, setProducts] = useState([])
    const [expenditureTypes, setExpenditureTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null})
    const [searchParams, setSearchParams] = useSearchParams()

    const [procurementRequestForCopy, setProcurementRequestForCopy] = useState(null)
    const [procurementRequestForCancel, setProcurementRequestForCancel] = useState(null)
    const [procurementRequestForStatusUpdate, setProcurementRequestForStatusUpdate] = useState(null)
    const [procurementRequestForDetail, setProcurementRequestForDetail] = useState(null)
    const [procurementRequestForDeliver, setProcurementRequestForDeliver] = useState(null)

    const [procurementRequests, setProcurementRequests] = useState([])
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)

    let { search } = useLocation();

    useEffect(() => {
        const getProducts = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/products?no_pagination=true`)
                .then(response => {
                    if(response.status === 200) {
                        setProducts(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        const getExpenditureTypes = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/cost_types`)
                .then(response => {
                    if(response.status === 200) {
                        setExpenditureTypes(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProducts()
        getExpenditureTypes()
    }, [selectedWarehouse])

    useEffect(() => {
        const getProcurementRequests = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/procurement_requests?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setProcurementRequests(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProcurementRequests()
    }, [updateData, filters, search, selectedWarehouse])


    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800 font-semibold">Satın Alma Talepleri</h2>
                <ButtonNew label="Yeni Talep" onClick={() => {setProcurementRequestForCopy(null); dispatch(setIsOpenWarehouseProcurementRequestCreate(true))}} >
                </ButtonNew>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                            products={products}
                />

                <Table data={procurementRequests} 
                        actions={{
                            setProcurementRequestForCopy: setProcurementRequestForCopy,
                            setProcurementRequestForCancel: setProcurementRequestForCancel,
                            setProcurementRequestForStatusUpdate: setProcurementRequestForStatusUpdate,
                            setProcurementRequestForDetail: setProcurementRequestForDetail,
                            setProcurementRequestForDeliver: setProcurementRequestForDeliver
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            <div>
               
            </div>
            <Create 
                update={updateData} 
                setUpdate={setUpdateData} 
                products={products}
                expenditureTypes={expenditureTypes}
                procurementRequest={procurementRequestForCopy}
                selectedWarehouse={selectedWarehouse}
            />
            <Detail 
                procurementRequest={procurementRequestForDetail}
                selectedWarehouse={selectedWarehouse}
            />
            <Cancel 
                update={updateData} 
                setUpdate={setUpdateData} 
                procurementRequest={procurementRequestForCancel}
                selectedWarehouse={selectedWarehouse}
            />
            <Deliver 
                update={updateData} 
                setUpdate={setUpdateData} 
                procurementRequest={procurementRequestForDeliver}
                selectedWarehouse={selectedWarehouse}
            />
            <UpdateStatus 
                update={updateData} 
                setUpdate={setUpdateData} 
                procurementRequest={procurementRequestForStatusUpdate}
                selectedWarehouse={selectedWarehouse}
            />
            {loading && <Loader />}
        </div>
    )
}

export default WarehouseProcurementRequests