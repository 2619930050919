import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenProcurementStatusUpdate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useStatusInputs } from 'modules/companyModule/pages/procurementProcesses/procurements/InputArray'

const UpdateStatusForm = ({procurement, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            status: null,
        }
    })

    useEffect(() => {
        if(procurement !== null && Object.keys(procurement).length > 0) {
            setValue('status', procurement.status)
            setError(null)
            setStatus(false)
        }
    }, [procurement])

    const statusInput = useStatusInputs(errors)

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/procurements/${procurement.id}/update_status`, data)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenProcurementStatusUpdate(false))
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <div className="grid grid-cols-12 gap-2">
            {statusInput.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        {input.type == 'select' ? 
                            <SelectSmall register={register} {...input} /> :
                            <InputSmall register={register} {...input} />
                        }
                    </div>
                    )
            })}
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div> 
        </form> 
         
        {loading && <Loader />}
        </>
    )
}

export default UpdateStatusForm