import { useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { IconContext } from "react-icons"
import { HiChevronDown } from 'react-icons/hi'

function TableDropdown({children, title, icon = null}) {
    const [open, setOpen] = useState(false)
    const closeDropdown = () => {setOpen(false)}
    const ref = useDetectClickOutside({ onTriggered: closeDropdown })

    return (
        <div className="inline-block relative h-full flex items-center">
            <button onClick={(e) => {e.preventDefault(); e.stopPropagation(); setOpen(!open)}} className="flex items-center justify-between relative rounded cursor-pointer bg-gray-800 text-white py-2 px-2">
            <span className="flex items-center gap-x-1" >
                    <IconContext.Provider value={{ className: "h-3 w-3" }}>
                        {icon}
                    </IconContext.Provider>
                    <span className="inline">{title}</span>
                    <span className={open ? 'rotate-180' : 'rotate-0'}>
                    <IconContext.Provider value={{ className: "h-4 w-4" }}>
                        <HiChevronDown/>
                    </IconContext.Provider>
                    </span>
                    
                </span>
            </button>
            { open &&
                <div ref={ref} className="z-10 absolute bg-gray-800 right-0 origin-top-right mt-1 w-auto rounded-lg shadow-lg left-auto min-w-[150px] top-full">
                    {children}
                </div>
            }
            
        </div>
    )
}

export default TableDropdown