import { Controller } from 'react-hook-form'
import AsyncSelect from 'react-select/async'

function SelectWithSearch({dataArray, control, name, rules, error, actions, limitOptions = true, label = null}) {

    const styles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: 'auto',
            padding: '0.25rem .30rem',
            border: error ? '1px solid #ef4444' : '1px solid #9ca3af',
            borderRadius: '0.125rem',
            marginTop: '0.10rem'
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: '#9ca3af',
            margin: '0',
            padding: '0',
            height: '1rem',
            width: '1rem',
        }),
        indicatorsContainer: (baseStyles, state) => ({
            ...baseStyles,
            color: '#9ca3af',
            margin: '0',
            padding: '0',
            height: '1rem',
            width: '1rem',
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            color: '#9ca3af',
            margin: '0',
        }),
                                    
        input: (baseStyles, state) => ({
            ...baseStyles,
            outline: 'none',
            boxShadow: '0',
            padding: '0',
            margin: '0',
        }),
        valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            outline: 'none',
            boxShadow: '0',
            padding: '0'
        }),
        menuList: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0'
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0.3rem 0.7rem'
        }),
    }

    const filterProducts = (inputValue) => {
        if(limitOptions) {
            return dataArray.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            ).slice(0, 20)
        }
        return dataArray.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
    };

    const loadOptions = (inputValue, callback) => {
        callback(filterProducts(inputValue))
    }

    return (
        <>
            {label ? 
            <label htmlFor={name} className={['text-gray-700 text-xs'].join(' ')}>{label}</label>
            : null}
            <Controller 
                control={control}
                render={({field:{onChange, value, name, ref}}) => (
                    <AsyncSelect
                        inputRef={ref}
                        isClearable={false}
                        value={dataArray.find(c => c.value === value)} 
                        defaultOptions={dataArray.slice(0, 20)}
                        defaultInputValue=''
                        loadOptions={loadOptions}
                        placeholder = 'Seçiniz'
                        onChange = {(e) => {
                            onChange(e.value)
                            if(typeof actions === 'function') actions(e)                            
                        }}
                        styles={styles}
                    />
                )}
                name={name}
                rules={rules}
            />
            {<span className="text-xs text-red-600">{error}</span>}
        </>
    )
}

export default SelectWithSearch