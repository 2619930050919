import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonClose, ButtonNext, ButtonPrevious} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, CheckboxSmall} from 'elements/inputs'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import { useProcurementInput } from 'modules/companyModule/pages/procurementProcesses/procurements/InputArray'

function GeneralInfos({currentStep, setCurrentStep, procurementData, setProcurementData, costTypes}) {
    const dispatch = useDispatch()
    const isOpen = useSelector((state) => state.drawers.isOpenProcurementCreate)    
    
    const formDefaults = {
        title: procurementData?.title || null,
        business_area_id: procurementData?.business_area_id || null,
        cost_type_id: procurementData?.cost_type_id || null,
        proposal_type: procurementData?.proposal_type || null,            
        is_proposal_increase_not_allowed: procurementData?.is_proposal_increase_not_allowed || false,            
        is_proposal_cancel_not_allowed: procurementData?.is_proposal_cancel_not_allowed || false,            
    }
    
    
    const { register, handleSubmit, formState: { errors }, control } = useForm({
        defaultValues: formDefaults
    })

    const procurementInputs = useProcurementInput(errors, costTypes)

    const close = () => {
        dispatch(setIsOpenProcurementCreate())
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const handleForm = async (data) => {
        setProcurementData(prev => {
            return {...prev, ...data}
        })
        setCurrentStep(prev => prev+1)
    }    

    return (  
        <>      
        <form onSubmit={handleSubmit(handleForm, handleError)}>            
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-8rem)]">
                <div className="w-full overflow-y-auto px-0.5">
                    <div className="w-full lg:grid lg:grid-cols-12 gap-2 mt-2">
                    {procurementInputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                {input.type === 'select' ? 
                                    <SelectSmall register={register} {...input} /> :
                                    null
                                }
                                {input.type === 'text' ? 
                                    <InputSmall register={register} {...input} /> :
                                     null
                                }
                                {input.type === 'checkbox' ? 
                                    <CheckboxSmall register={register} {...input} /> :
                                    null
                                }
                            </div>
                            )
                    })}
                    </div> 
                </div>
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonClose onClick={() => close()}>
                    </ButtonClose>
                    <ButtonPrevious disabled={true} onClick={() => setCurrentStep(prev => prev-1)}>
                    </ButtonPrevious>
                    <ButtonNext type='submit'>
                    </ButtonNext>                  
                </div>         
            </div>
        </form> 
        </>
    )

}

export default GeneralInfos