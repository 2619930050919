import { Fragment } from 'react'
import { DateTime } from 'luxon'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

function ProposalInfoDetail({procurement}) {
    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy T')
    }

    const StatusDescription = ({proposal}) => {
        if(proposal.is_cancelled) return <span className='text-red-500 font-bold'>İptal Edildi</span>
        if(proposal.is_revised) return <span className='text-red-500 font-bold'>Revize Edildi</span>
        if(proposal.is_accepted) {
            if(proposal.total_price === proposal.accepted_price) {
                return <span className='text-green-500 font-bold'>Kabul Edildi</span>
            }
            return <span className='text-amber-500 font-bold'>Kısmi Kabul Edildi</span>
        }
        return <span className='text-blue-500 font-bold'>Süreç Devam Ediyor</span>
    }

    return (
        <div className="">
                {(procurement !==null && Object.keys(procurement).length > 0) &&
                <>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Talep Bilgileri</p>
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Firma:</td>
                            <td className="px-2 py-3">{procurement.company.company_name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Talep No:</td>
                            <td className="px-2 py-3">{procurement.procurement_no}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Talep Başlığı:</td>
                            <td className="px-2 py-3">{procurement.title}</td>
                        </tr>                        
                    </tbody>
                </table>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Teklifler</p>
                { (procurement.proposals && Array.isArray(procurement.proposals) && procurement.proposals.length > 0) ?
                    procurement.proposals.map((proposal, index) => {
                        return (
                            <Fragment key={proposal.id} >
                                <p className="mt-2 py-0.5 px-2 mb-2 flex items-center justify-between bg-blue-100 border-y border-blue-300 text-blue-500 font-bold">
                                    <span>Teklif {index + 1} ({formatDate(proposal.created_at)})</span>
                                    <StatusDescription proposal={proposal}/>
                                </p>
                                <table className="w-full">
                                    <thead>
                                        <tr className="border-b border-gray-300 text-left">
                                            <th className="px-1 py-0.5">Ürün</th>
                                            <th className="px-1 py-0.5">Miktar</th>
                                            <th className="px-1 py-0.5 lg:w-[33%]">Teklif Açıklaması</th>
                                            <th className="px-1 py-0.5">Fiyat</th>
                                            {proposal.is_accepted ? 
                                            <th className="px-1 py-0.5">Durum</th>
                                            : null
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {proposal.lines.map(line => {
                                            return (
                                                <tr key={line.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                                    <td className="px-1 py-0.5">{line.procurement_line.product.name}</td>
                                                    <td className="px-1 py-0.5">{line.procurement_line.quantity} {line.procurement_line.unit_code.description}</td>
                                                    <td className="px-1 py-0.5">{line.description_text}</td>
                                                    <td className="px-1 py-0.5">{FNWS(line.price)} {proposal.currency.code}</td>
                                                    {proposal.is_accepted ? 
                                                    <td className="px-1 py-0.5">
                                                        {line.is_accepted ? 
                                                        <span className='text-green-500 font-bold'>Kabul Edildi</span>
                                                        : ''}
                                                    </td>
                                                    : null
                                                    }
                                                </tr>
                                            )
                                        })}
                                        <tr className="border-b border-t border-gray-300 even:bg-gray-200 text-amber-600">
                                            <td className="px-1 py-0.5" colSpan={2}></td>
                                            <td className="px-1 py-0.5 font-bold">Toplam</td>
                                            <td className="px-1 py-0.5 font-bold">{proposal.total_price} {proposal.currency.code}</td>
                                            {proposal.is_accepted ? 
                                                <td className="px-1 py-0.5 font-bold">
                                                    {FNWS(proposal.accepted_price)} {proposal.currency.code}
                                                </td>
                                                : null
                                                }
                                        </tr>
                                    </tbody>
                                </table>
                                <p className="mt-2 pb-0.5 mb-2 text-gray-800 font-bold">Açıklamalar:</p>
                                <p className="whitespace-pre-line">{proposal.description || 'Açıklama yapılmadı'}</p>
                            </Fragment>
                        )
                    })
                    
                : null

                }
                
                </>}
            </div> 
    )
}

export default ProposalInfoDetail