import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import { setUserData, setStep } from 'stores/RegisterSlice'
import {InputSmall} from 'elements/inputs'
import {PhoneSmall} from 'elements/inputs/Phone'
import {ButtonBlue} from 'elements/buttons'
import {ServerError} from 'elements/serverMessages'
import {IoArrowForwardSharp} from "react-icons/io5"

const UserForm = () => {

    const userData = useSelector(state => state.register.user)
    const userError = useSelector(state => state.register.userError)

    const { register, handleSubmit, getValues, formState: { errors }, control } = useForm({
        defaultValues: {
            name: userData?.name || null,
            phone: userData?.phone || null,
            email: userData?.email || null,
            password: userData?.password || null,
            password_confirmation: userData?.password_confirmation || null
        }
    })

    const dispatch = useDispatch()

    const handleUserForm = async (data) => {
        dispatch(setUserData(data))
        dispatch(setStep(2))    
    }

    const userInputs = [
        {
            key: "userInput1",
            type: "text",
            name: "name",
            placeholder: "İsim-Soyisim",
            label: "İsim-Soyisim *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.name?.message,
            cols: 'col-span-12', 
        },
        {
            key: "userInput2",
            type: "phone",
            name: "phone",
            placeholder: "Telefon",
            label: "Telefon *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},
                pattern: {value: /^\+90 \((?!0|1)\d{3}\) \d{3}-\d{2}-\d{2}$/,
                            message: "Geçerli bir telefon numarası olmalı"}
            },
            error: errors?.phone?.message,
            cols: 'col-span-12',
        },
        {
            key: "userInput3",
            type: "text",
            name: "email",
            placeholder: "E-Posta",
            label: "E-Posta *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.email?.message,
            cols: 'col-span-12', 
        },
        {
            key: "userInput4",
            type: "password",
            name: "password",
            placeholder: "Şifre",
            label: "Şifre *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},                
            },
            error: errors?.password?.message,
            cols: 'col-span-6',
        },
        {
            key: "userInput5",
            type: "password",
            name: "password_confirmation",
            placeholder: "Şifre Tekrar",
            label: "Şifre Tekrar*",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},   
                validate: {
                    confirmation: v => v === getValues("password") || 'Girmiş olduğunuz şifreler eşleşmiyor'
                }              
            },
            error: errors?.password_confirmation?.message,
            cols: 'col-span-6',
        },
    ]

    return (
        <div className="px-4 pb-4">
            <div className="text-red-500">
            {userError && <ServerError errorData={userError} />}
            </div>
            <form onSubmit={handleSubmit(handleUserForm)}>
                <div className="grid grid-cols-12 gap-3">
                {userInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            {input.type === 'phone' ? 
                                <PhoneSmall 
                                    type={input.type}
                                    name={input.name}
                                    register={register}
                                    label={input.label}
                                    placeholder={input.placeholder}
                                    validator={input.validator}
                                    error={input.error}
                                />
                                :
                                <InputSmall 
                                    type={input.type}
                                    name={input.name}
                                    register={register}
                                    label={input.label}
                                    placeholder={input.placeholder}
                                    validator={input.validator}
                                    error={input.error}
                                />
                            }
                            
                        </div>
                        )
                })}
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonBlue type="submit">İleri <IoArrowForwardSharp/> </ButtonBlue>
                </div>                
            </form> 
        </div>
    )
}

export default UserForm