import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseTransferApprovalDetail } from 'stores/DrawersSlice' 
import DetailForm from 'modules/companyModule/pages/warehouses/transferApprovals/DetailForm'

function Detail({transfer}) {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenWarehouseTransferApprovalDetail)
    const setIsOpenDetail = () => dispatch(setIsOpenWarehouseTransferApprovalDetail())
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Ürün Talep Detayı">
            <DetailForm transfer={transfer} />
        </FormDrawer> 
    )
}

export default Detail