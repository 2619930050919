import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierUserStatusEdit } from 'stores/DrawersSlice' 
import DeleteForm from 'modules/supplierModule/pages/companies/settings/users/DeleteForm'

function Delete({user, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenDelete = useSelector(state => state.drawers.isOpenSupplierUserStatusEdit)
    const setIsOpenDelete = () => dispatch(setIsOpenSupplierUserStatusEdit())
    
    return(
        <FormDrawer isOpen={isOpenDelete} setIsOpen={setIsOpenDelete} title="Kullanıcı Durumunu Güncelle">
            <DeleteForm user={user} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Delete