import { Link } from "react-router-dom"

const LinkBlue = ({route, title}) => {
    
    return (
        <Link to={route} className="text-md text-sky-500 hover:text-sky-800 underline">
            {title}
        </Link>
    )
    
}

const LinkGray = ({route, title}) => {
    
    return (
        <Link to={route} className="text-md text-gray-700 hover:text-gray-800 underline">
            {title}
        </Link>
    )
    
}

export {
    LinkBlue,
    LinkGray
}