import { useSelector } from 'react-redux'
import { ProcurementPaymentTypes } from 'data'

export function useDescriptionInput(errors) {
    return [
        {
            key: "descriptionInput1",
            type: "textarea",
            name: "description",
            placeholder: "Genel Açıklamalar",
            label: "Genel Açıklamalar",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },
    ]
}

export function useLineInput(errors, proposalType) {

    return [
        {
            key: "lineInput1",
            type: "hidden",
            nameLast: "procurement_line_id",
            placeholder: "",
            label: null,
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            cols: 'col-span-2', 
        },
        {
            key: "lineInput2",
            type: "text",
            nameLast: "price",
            placeholder: "Fiyat",
            label: null,
            validator: {
                required: {value: proposalType === 'block', message: "Bu alan zorunludur."}, 
                pattern: {value: /^[0-9]\d*(,\d{1,2})?$/,
                            message: "12345,67 formatında bir sayı olmalı"},
                validate: {
                    positive: v => {
                        if(v === undefined || v === null || v === '') {
                            return true
                        }
                        if(parseFloat(v.toString().replace(",", ".")) <= 0) {
                            return 'Sıfırdan büyük olmalı'
                        }
                        return true
                    }
                }                 
            },
            cols: 'col-span-3', 
        },
        {
            key: "lineInput3",
            type: "text",
            nameLast: "description_text",
            placeholder: "Ürün Açıklaması",
            label: null,
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            cols: 'col-span-3', 
        },
        
    ]
}

export function usePaymentTermInput(errors, getValues) {
    const currencies = useSelector(state => state.sharedData.currenciesWithDesc)
    const paymentTypeArray = ProcurementPaymentTypes()

    return [
        {
            key: "paymentTermInput1",
            type: "select",
            name: "currency_id",
            placeholder: "Para Birimi",
            label: "Para Birimi *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.currency_id?.message,
            data: currencies,
            cols: 'col-span-6', 
        },  
        {
            key: "paymentTermInput2",
            type: "select",
            name: "payment_type",
            placeholder: "Ödeme Şekli",
            label: "Ödeme Şekli *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.payment_type?.message,
            data: paymentTypeArray,
            cols: 'col-span-4', 
        },  
        {
            key: "paymentTermInput3",
            type: "text",
            name: "deferral_days",
            placeholder: "Vade (Gün)",
            label: "Vade (Gün) *",
            validator: {
                required: {value: getValues('payment_type') !== 'deferred', message: 'Bu alan zorunudur.'}, 
                pattern: {value: /^\b([1-9]|[1-9][0-9]|[1-2][0-9]{2}|3[0-5][0-9]|36[0-5])\b$/,
                            message: "1-365 arasında bir tam sayı olmalı"},
                validate: {
                    positive: v => (v && parseFloat(v.toString().replace(",", ".")) > 0) || 'Sıfırdan büyük olmalı'
                }                 
            },
            error: errors?.deferral_days?.message,
            cols: 'col-span-2', 
        },    
    ]
}

