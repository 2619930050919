import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { ButtonClose, ButtonSave, ButtonNew} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import FormatNumber from 'utils/FormatNumber'
import { setIsOpenWarehouseProductStockCorrectionList } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

const StockCorrectionListForm = ({product, selectedWarehouse}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)    
    const isOpenForm = useSelector(state => state.drawers.isOpenWarehouseProductStockCorrectionList)
    
    const [stockCorrections, setStockCorrections] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(isOpenForm && product !==null && Object.keys(product).length > 0) {
            const getStockCorrections = async () => {
                setLoading(true)
                await SendRequest('GET', `api/companies/${companyId}/warehouses/${selectedWarehouse.id}/products/${product.id}/stock_corrections`)
                    .then(response => {
                        if(response.status === 200) {
                            setError(null)
                            setStockCorrections(response.data.data)                            
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setError(err.response.data)
                        setLoading(false)
                    })
            }
            getStockCorrections()
        } else {
            setStockCorrections(null)
        }       
    }, [product, isOpenForm])

    useEffect(() => {
        if(!isOpenForm) {
            setError(null)
            setStockCorrections(null)
        }        
    }, [isOpenForm])

    const close = () => {
        dispatch(setIsOpenWarehouseProductStockCorrectionList())
    }

    const handleError = (error, e) => {
        
    }

    const formatDate = (date) => {
        return DateTime.fromISO(date).toFormat('dd.LL.yyyy')
    }

    const formatCorrectionType = (correctionType) => {
        if(correctionType === 2) return <span className="text-emerald-500 font-semibold">Stok Fazlalığı</span>
        if(correctionType === 1) return <span className="text-red-500 font-semibold">Stok Eksikliği</span>
        return <span className="text-orange-500 font-semibold">Bilinmiyor</span>
    }

    const formatStatus = (status) => {
        if(status === false) return <span className="text-emerald-500 font-semibold">Aktif</span>
        if(status === true) return <span className="text-red-500 font-semibold">Revize Edilmiş</span>
        return <span className="text-orange-500 font-semibold">Bilinmiyor</span>
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {(product && Object.keys(product).length > 0) ?                
                <div>
                    {(stockCorrections !== null && Array.isArray(stockCorrections) && stockCorrections.length > 0) ?
                        stockCorrections.map(item => {
                            return (
                            <div key={item.id}>
                                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Tarih: {formatDate(item.correction_date)}</p>
                                <table className="w-full">
                                    <tbody>
                                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                            <td className="px-2 py-3">Düzeltme Türü:</td>
                                            <td className="px-2 py-3">{formatCorrectionType(item.correction_type)}</td>
                                        </tr>
                                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                                            <td className="px-2 py-3">Düzeltme Miktarı:</td>
                                            <td className="px-2 py-3">{FormatNumber(item.quantity)} {item.unit_code.description}</td>
                                        </tr>
                                        <tr className="border-b border-gray-300 even:bg-gray-200">
                                            <td className="px-2 py-3">Düzeltme Yapan Kullanıcı:</td>
                                            <td className="px-2 py-3"> {item.user.name} </td>
                                        </tr>
                                        <tr className="border-b border-gray-300 even:bg-gray-200">
                                            <td className="px-2 py-3">Durum:</td>
                                            <td className="px-2 py-3">{formatStatus(item.is_revised)}</td>
                                        </tr>
                                    </tbody>
                                </table>     
                            </div>
                            )
                        })
                    : 
                        <p className="text-red-600 text-base">Stok düzeltmesi yapılmamış</p>
                    }
                    <div className="w-full flex justify-end mt-5">
                        <ButtonClose onClick={() => close()}>
                        </ButtonClose>
                    </div>  
                </div> 
            
                : null }
            
        {loading && <Loader />}
        </>
    )
}

export default StockCorrectionListForm