import { utils, writeFileXLSX } from 'xlsx'
import { DateTime } from 'luxon'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

function ExportXslx(data, fileName = 'ekstre') {

    const productTitle = data.lines.reduce((acc, obj) => {
        return [...acc, obj.product.name + ' Fiyat', obj.product.name + ' Açıklama']
    }, [])

    const StatusCell = (proposal) => {
        if(proposal.is_accepted) {
            return "Kabul Edildi"
        }
        if(proposal.is_cancelled) {
            return "İptal Edildi"
        }
        if(proposal.is_revised) {
            return "Revize Edildi"
        }
        return "İşlem Yapılmadı"
                   
    }

    const LineCells = (proposal) => {
        return data.lines.reduce((acc, obj) => {
            return {
                ...acc, 
                ['price' + obj.id]: FNWS(proposal.lines.find(pl => pl.procurement_line_id === obj.id).price), 
                ['description' + obj.id]: proposal.lines.find(pl => pl.procurement_line_id === obj.id).description_text, 
            }
        }, {})
    }
    
    const array = data.proposals.map(item => {
        return {
            name: item.supplier.company_name,
            businessArea: item.supplier.business_area.name,
            taxNumber: item.supplier.tax_number,
            taxDepartment: item.supplier.tax_department,
            email: item.supplier.email,
            phone: item.supplier.phone,
            address: `${item.supplier.address} ${item.supplier.district.name}/${item.supplier.city.name}`,
            prposalDate: DateTime.fromISO(item.created_at).toFormat('dd.LL.yyyy T'),
            description: item.description,
            status: StatusCell(item),
            ...LineCells(item),
            totalPrice: FNWS(item.total_price),
        }
    })

    const header = [[
        "Firma", 
        "Sektör", 
        "Vergi No", 
        "Vergi Dairesi", 
        "E-Posta", 
        "Telefon", 
        "Adres", 
        "Teklif Tarihi", 
        "Açıklama", 
        "Durum",
        ...productTitle,
        "Toplam Fiyat" 
    ]]

    const exportXsl = () => {
        const ws = utils.json_to_sheet(array)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        utils.sheet_add_aoa(ws, header, {origin: "A1"})
        writeFileXLSX(wb, fileName+".xlsx")
    }

    exportXsl()
}

export default ExportXslx