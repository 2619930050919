import React from 'react'
// import { useSelector, connect } from 'react-redux' 
import Error from 'modules/commonModule/pages/error'
// import SendRequest from 'utils/SendRequest'


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorInfo: null }
    this.prevPath = null
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, errorInfo: {error, info} })
    // const data = {
    //   message: error?.message, 
    //   file_name: error?.fileName,
    //   line_number: error?.lineNumber,
    //   stack: error?.stack,
    //   component_stack: info?.componentStack,
    //   type: this.props.user?.type,
    //   user_id: this.props.user?.id,      
    //   company_id: this.props.company?.id,
    // }
    // SendRequest('POST', 'api/portal_errors', data)    
    // log the error or perform any necessary actions
  }

  componentDidUpdate() {
    if (window.location.pathname !== this.prevPath) {
        // if(this.state.hasError) {
        //     console.log(this.state.errorInfo)
        // }        
        this.setState({ hasError: false, errorInfo: null })
        this.prevPath = window.location.pathname
    }        
  }


  render() {
    if (this.state.hasError) {
      return <Error />
    }

    return this.props.children
  }
}

const mapStateToProps = state => {
  return {
      user: state.user.user,
      company: state.company.selectedCompany,
  }
}

export default ErrorBoundary

// export default connect(mapStateToProps)(ErrorBoundary)