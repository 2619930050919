import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierRemoveBlacklist } from 'stores/DrawersSlice' 
import RemoveBlacklistForm from 'modules/companyModule/pages/suppliers/RemoveBlacklistForm'

function RemoveBlacklist({supplier, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenRemoveBlacklist = useSelector(state => state.drawers.isOpenSupplierRemoveBlacklist)
    const setIsOpenRemoveBlacklist = () => dispatch(setIsOpenSupplierRemoveBlacklist())
    
    return(
        <FormDrawer isOpen={isOpenRemoveBlacklist} setIsOpen={setIsOpenRemoveBlacklist} title="Tedarikçiyi Kara Listeden Çıkar">
            <RemoveBlacklistForm supplier={supplier} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default RemoveBlacklist