import { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import {ButtonSave, ButtonClose, ButtonOk} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, CheckboxSmall} from 'elements/inputs'
import MultiSelectWithSearch from 'elements/inputs/MultiSelectWithSearch'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenProcurementIssue } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useIssueInputs, 
        useSupplierSelectionTypeInput, 
        useSupplierSelectionBusinessAreaInput, 
        useSupplierSelectionSupplierInput } 
        from 'modules/companyModule/pages/procurementProcesses/procurements/InputArray'

const IssueForm = ({procurement, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenEdit = useSelector(state => state.drawers.isOpenProcurementIssue)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [categorySelectArray, setCategorySelectArray] = useState([])
    const [taxonomies, setTaxonomies] = useState([])
    const [suppliers, setSuppliers] = useState([])
    
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            start_date: DateTime.local().toFormat("yyyy-LL-dd'T'T"),
            end_date: DateTime.local().plus({ days: 3 }).toFormat("yyyy-LL-dd'T'T"),
        }
    })    

    const enddateInput = useIssueInputs(errors)

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/procurements/${procurement.id}/issue`, data)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    useEffect(() => {
        if(!isOpenEdit) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenEdit])

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenProcurementIssue(false))
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
        <>
        <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
        <div className="w-full flex justify-end mt-5 gap-x-2">
            <ButtonOk onClick={() => close()}>
            </ButtonOk>
        </div>  
        </> 
        :
        <form onSubmit={handleSubmit(handleForm)}>
            <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Tarih Bilgiler</p>
            <div className="grid grid-cols-12 gap-2">
            {enddateInput.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        {input.type == 'select' ? 
                            <SelectSmall register={register} {...input} /> :
                            <InputSmall register={register} {...input} />
                        }
                    </div>
                    )
            })}
            </div>
            
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div> 
        </form> 
        }
        {loading && <Loader />}
        </>
    )
}

export default IssueForm