import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenMakeProposal } from 'stores/DrawersSlice' 
import MakeProposalForm from 'modules/supplierModule/pages/procurements/MakeProposalForm'

function MakeProposal({update, setUpdate, procurement}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenMakeProposal)
    const setIsOpenCreate = () => dispatch(setIsOpenMakeProposal())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Teklif Yap" maxWidth="max-w-6xl">
            <MakeProposalForm update={update} setUpdate={setUpdate} procurement={procurement} />
        </FormDrawer> 
    )
}

export default MakeProposal