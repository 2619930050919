import { DateTime } from 'luxon'
import TableLayout from 'elements/tables'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

const ProposalTable = ({data, progressPending, pagination, pageMeta}) => {

    const DateCell = ({date}) => {
        let parsedDate = DateTime.fromISO(date)
        
        return parsedDate.isValid ? 
                <p className="whitespace-nowrap">
                    <span>{parsedDate.toFormat('dd.LL.yyyy T')}</span>
                </p>
                : null
    }
    
    const columns = [
        {
            name: 'Firma',
            selector: (row) => {return row.supplier.company_name},
            headerMobileHidden: false,
        },
        {
            name: 'Talep No',
            selector: (row) => {return row.procurement.procurement_no},
            headerMobileHidden: false,
        },
        {
            name: 'Talep Başlığı',
            selector: (row) => {return row.procurement.title},
            headerMobileHidden: false,
        },
        {
            name: 'Satın Alma Sektörü',
            selector: (row) => {return row.procurement.business_area.name},
            headerMobileHidden: false,
        },
        {
            name: 'Teslim Tarihi',
            selector: (row) => {return <DateCell date={row.procurement.delivery_date} />},
            headerMobileHidden: false,
        },
        
        {
            name: 'Miktar',
            selector: (row) => {return FNWS(row.procurement_line.quantity) + ' ' + row.procurement_line.unit_code.description},
            headerMobileHidden: false,
        },
        {
            name: 'Tutar',
            selector: (row) => {return FNWS(row.price) + ' ' + row.procurement.currency.code},
            headerMobileHidden: false,
        },
        
    ];

    const mobileHeader = (row) => {return `${row.supplier.company_name} (${row.procurement_no})`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default ProposalTable;
