import { DateTime } from 'luxon'
import TableActions from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/TableActions'
import TableLayout from 'elements/tables'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

const AllProposals = ({data, procurementStatus, actions, progressPending}) => {

    const StatusCell = ({proposal}) => {
        if(proposal.is_accepted) {
            if(proposal.total_price === proposal.accepted_price) {
                return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Kabul Edildi.</span>
            }
            return <span className="py-0.5 px-2 rounded bg-amber-500 text-amber-50 whitespace-nowrap">Kısmi Kabul Edildi.</span>
        }
        if(proposal.is_cancelled) {
            return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">İptal Edildi</span>
        }
        if(proposal.is_revised) {
            return <span className="py-0.5 px-2 rounded bg-orange-500 text-orange-50 whitespace-nowrap">Revize Edildi</span>
        }
        return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">İşlem Yapılmadı</span>
                   
    }

    const columns = [
        {
            name: 'Firma',
            selector: (row) => {return row.supplier.company_name},
            headerMobileHidden: false,
        },
        {
            name: 'Teklif Tarihi',
            selector: (row) => {return DateTime.fromISO(row.created_at).toFormat('dd.LL.yyyy T')},
            headerMobileHidden: false,
        },
        {
            name: 'Teklif Tutarı',
            selector: (row) => {return `${FNWS(row.total_price)} ${row.currency.code}`},
            headerMobileHidden: false,
        },
        {
            name: 'Kabul Edilen Tutar',
            selector: (row) => {return row.accepted_price > 0 ? `${FNWS(row.accepted_price)} ${row.currency.code}` : ''},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return <StatusCell proposal={row} />},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row} />},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return `${row.supplier.company_name}`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={null} pageMeta={null} mobileHeader={mobileHeader} />
        </>
    );
};

export default AllProposals;
