import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {ButtonClose} from 'elements/buttons/actionButtons'
import { setIsOpenWarehouseTransferApprovalDetail } from 'stores/DrawersSlice' 
import DetailInfo from 'modules/companyModule/pages/warehouses/transferApprovals/DetailInfo'

const DetailForm = ({transfer}) => {
    const dispatch = useDispatch()
    
    
    
    const close = () => {
        dispatch(setIsOpenWarehouseTransferApprovalDetail())
    }

    return (
        <>       
            <DetailInfo transfer={transfer} />
            <div className="w-full flex justify-end mt-5">
                <ButtonClose type="button" onClick={close}>
                </ButtonClose>
            </div>               
        </>
    )
}

export default DetailForm