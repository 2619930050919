import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseEdit } from 'stores/DrawersSlice' 
import EditWarehouseForm from 'modules/companyModule/pages/companies/settings/warehouses/EditWarehouseForm'

function EditWarehouse({update, setUpdate, warehouse, setWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenWarehouseEdit)
    const setIsOpenCreate = () => dispatch(setIsOpenWarehouseEdit())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Depoyu Düzenle">
            <EditWarehouseForm 
                update={update} 
                setUpdate={setUpdate} 
                warehouse={warehouse}
                setWarehouse={setWarehouse}
            />
        </FormDrawer> 
    )
}

export default EditWarehouse