import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierInvitationCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/suppliers/invitations/CreateForm'

function Create({update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenSupplierInvitationCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenSupplierInvitationCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Tedarikçi Davet Et">
            <CreateForm update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Create