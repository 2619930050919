function Projects({projects}) {
    if(projects !== null && Array.isArray(projects)) {
        return (         
            <table className="w-full text-xs">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-1 text-left">Proje/Firma Adı</th>
                        <th className="py-1 px-1 text-left">Proje Açıklaması</th>
                        <th className="py-1 px-1 text-left">Projeye Başlanan Yıl</th>
                    </tr>
                </thead>
                <tbody>
                    {projects.length > 0 ?
                        projects.map(project => {
                            return (
                                <tr key={project.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">{project.project_name}</td>
                                    <td className="px-2 py-3">{project.description}</td>
                                    <td className="px-2 py-3">{project.year}</td>
                                </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan={3}>Proje bilgisi bulunamadı</td>
                        </tr>
                    }
                </tbody>
            </table>       
        )
    }
    return null
}

export default Projects