import { IconContext } from "react-icons"
import { NavLink } from 'react-router-dom'

function Link({title, icon, path}) {

    const baseClasses = "flex items-center h-12 rounded-lg overflow-hidden z-0 px-3 py-px mx-3 mb-0.5 text-slate-100 cursor-pointer hover:text-white focus:text-opacity-100 outline-none truncate transition-all duration-500 ease-in-out"
    const activeClasses = "bg-slate-700 bg-opacity-60"
    return (
        <li className="text-sm">
            <NavLink to={path} className={({isActive}) => `${baseClasses} ${isActive ? activeClasses : ''}`}>
                <IconContext.Provider value={{ className: "h-4 w-4" }}>
                <span className="mr-4">{icon}</span>
                </IconContext.Provider>
                <span className="mr-auto leading-none">{title}</span>
            </NavLink>
        </li>
    )
}

export default Link