import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, TextareaSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenSupplierProjectEdit } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useProjectInput } from 'modules/supplierModule/pages/companies/projects/InputArray'

const EditForm = ({project, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany?.id)
    const isOpenCreate = useSelector(state => state.drawers.isOpenSupplierProjectEdit)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            project_name: null,
            description: null,
            year: null,
        }
    })

    const inputs = useProjectInput(errors)

    useEffect(() => {
        if(project !== null && Object.keys(project).length > 0) {
            setValue('project_name', project.project_name)          
            setValue('description', project.description)          
            setValue('year', project.year)     
            setError(null)
            setStatus(false)
        } else {
            reset()
        }
    }, [project, isOpenCreate])

    useEffect(() => {
        if(!isOpenCreate) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenCreate])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/suppliers/${companyId}/projects/${project.id}`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenSupplierProjectEdit(false))
    }   

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <div className="grid grid-cols-12 gap-2">
            {inputs.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        {input.type == 'textarea' ? 
                            <TextareaSmall register={register} {...input} /> :
                            <InputSmall register={register} {...input} />
                        }
                    </div>
                    )
            })}                
            </div>
            <div className="w-full flex justify-end gap-x-1 mt-5">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose type="button" onClick={() => close()}>
                </ButtonClose>
            </div>                
        </form> 
        {loading && <Loader />}
        </>
    )
}

export default EditForm