import { useSelector, useDispatch } from 'react-redux'
import { MdMenu } from 'react-icons/md'
import { MdClose } from 'react-icons/md'
import { toggle } from 'stores/MobileMenuSlice'

function MobileMenuOpen() {
    const openSidemenu = useSelector((state) => state.mobileMenu.isOpen)
    const dispatch = useDispatch()

    return (
        <div>
            <button onClick={() => dispatch(toggle())} className="flex md:hidden items-center flex-shrink-0 px-4 cursor-pointer text-gray-900">
                {openSidemenu ? <MdClose size={'1.5em'}/> : <MdMenu size={'1.5em'}/>}
            </button>
        </div>
    )
}

export default MobileMenuOpen