import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom"
import {ServerError} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { login } from 'stores/UserSlice'
import marlaportLogo from 'assets/img/marlaport_logo.png'
import { ButtonRefresh } from "elements/buttons/actionButtons"

function SocialLogin() {
    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const [loginError, setLoginError] = useState(null)
    

    useEffect(() => {
        const handleLogin = async () => {
            await SendRequest('POST', `login/${params.provider}/callback${location.search}`)
                    .then(res => {
                        if (res.status === 204) {                
                            SendRequest('GET', 'api/user')
                            .then(res => {
                                dispatch(login({
                                    user: res.data.data, 
                                    authenticated: true, 
                                    emailVerified: res.data.data.verified, 
                                    userType: res.data.data.type
                                }))                  
                            })                
                        }
                    })
                    .catch(err => {
                        setLoginError(err.response.data)
                    })
        }
        if(location.search && params.provider) {
            setTimeout(() => {
                handleLogin()
            }, 3*1000)
            
        }
        
    }, [])

    return (
        <div className="w-full py-10 bg-slate-100 bg-no-repeat bg-cover px-2 flex flex-col justify-center items-center gap-y-4">
            <div>
                <img src={marlaportLogo} className="h-12" />
            </div>
            <div className="w-full bg-white shadow-lg rounded-lg md:max-w-lg lg:w-[28rem]">                
                <div className="w-full h-full flex flex-col justify-between gap-y-1">
                    <div className="px-4 pb-4">
                        {loginError ? 
                            <div>
                                <div className="text-red-500 mt-4">
                                    <ServerError errorData={loginError} />
                                </div>
                                <div className="flex justify-end">
                                    <ButtonRefresh label="Tekrar Dene" onClick={() => navigate('/login')} />
                                </div>
                            </div>
                            :
                            <p className="text-gray-700 p-2">Giriş yapılıyor lütfen bekleyin...</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SocialLogin