import { useSelector, useDispatch } from 'react-redux'
import { IconContext } from "react-icons"
import { FaUserAlt } from 'react-icons/fa'
import { FaCog } from 'react-icons/fa'
import { MdLogout } from 'react-icons/md'
import avatar from 'assets/img/avatar.png'
import NavbarDropdown from 'elements/dropdowns/NavbarDropdown'
import NavbarLink from 'elements/dropdowns/NavbarLink'
import NavbarForm from 'elements/dropdowns/NavbarForm'
import SendRequest from 'utils/SendRequest'
import { logout } from 'stores/UserSlice'
import { setCompanies, setSelectedCompany, setIsSelected } from 'stores/CompanySlice'

function UserMenu() {

    const userName = useSelector((state) => state.user.user.name)
    const dispatch = useDispatch()

    const handleLogout = async () => {
        await SendRequest('POST', 'logout')
            .then(res => {
                dispatch(logout())
                dispatch(setCompanies(null))
                dispatch(setSelectedCompany(null))
                dispatch(setIsSelected(null))
            })
            .catch(err => {
                console.log(err)
            })
    }

    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-4 w-4" }}>
                <NavbarLink path='/user-profile' title='Kullanıcı Bilgileri' icon={<FaUserAlt />}/>
                <NavbarLink path='/user-setting' title='Kullanıcı Ayarları' icon={<FaCog />}/>
                <NavbarForm action={handleLogout} title='Çıkış Yap' icon={<MdLogout />} borderTop={true}/>
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <NavbarDropdown title={userName} avatar={avatar}>
            <Content />
        </NavbarDropdown>
    )
}

export default UserMenu