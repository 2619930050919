import SupplierHome from 'modules/supplierModule/pages/home'
import SupplierProcurements from 'modules/supplierModule/pages/procurements'
import SupplierProposals from 'modules/supplierModule/pages/proposals'
import UserProfile from 'modules/authModule/pages/profile'
import UserSetting from 'modules/authModule/pages/settings'
import SupplierCompanySettings from 'modules/supplierModule/pages/companies/settings'
import SupplierUserSettings from 'modules/supplierModule/pages/companies/settings/users'

function SupplierRoutes()
{
    return [
        {
          index: true,
          element: <SupplierHome key={Date.now()} />
        },
        {
          path: 'supplier/procurements',
          element: <SupplierProcurements />
        },
        {
          path: 'supplier/proposals',
          element: <SupplierProposals />
        },
        {
          path: 'supplier/settings',
          element: <SupplierCompanySettings />,
          children: [
            {
              path: 'users',
              element: <SupplierUserSettings />
            },
          ]
        },
        {
          path: 'user-setting',
          element: <UserSetting />
        },
        {
          path: 'user-profile',
          element: <UserProfile />
        }
    ]
}

export default SupplierRoutes