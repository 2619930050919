import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductDemandDetail } from 'stores/DrawersSlice' 
import DetailForm from 'modules/companyModule/pages/productDemands/demands/DetailForm'

function Detail({productDemand}) {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenProductDemandDetail)
    const setIsOpenDetail = () => dispatch(setIsOpenProductDemandDetail())
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Ürün Talep Detayı" maxWidth="max-w-4xl">
            <DetailForm productDemand={productDemand} />
        </FormDrawer> 
    )
}

export default Detail