import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { DateTime } from 'luxon'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import FormatNumberWithSeperator from 'utils/FormatNumberWithSeperator'

function DetailInfo({product}) {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [loading, setLoading] = useState(false)

    const downloadTechSpec = () => {
        const getProducts = async () => {
            setLoading(true)
            await SendRequest('DOWNLOAD', `api/companies/${companyId}/products/${product.id}/download_technical_specification`)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${product.name}_tech_spec.pdf`)
                        document.body.appendChild(link)
                        link.click()
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProducts()
    }

    return (
        <div className="">
                {(product !==null && product !== undefined && Object.keys(product).length > 0) &&
                <>
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Ürün Adı:</td>
                            <td className="px-2 py-3">{product.name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Ürün Kodu:</td>
                            <td className="px-2 py-3">{product.product_code}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Barkod:</td>
                            <td className="px-2 py-3">{product.barcode}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Ürün Türü: </td>
                            <td className="px-2 py-3">{product.product_type.name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Birim: </td>
                            <td className="px-2 py-3">{product.unit.description}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Gider Türü: </td>
                            <td className="px-2 py-3">{product.cost_type?.cost_name || 'Belirlenmedi'}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Kategoriler: </td>
                            <td className="px-2 py-3 whitespace-pre-line">{product.taxonomies.map((category) => {
                                return `${category.taxonomy.taxonomy_name}: ${category.taxon.taxon_name}`
                            })
                            .join(', \r\n')}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Açıklama: </td>
                            <td className="px-2 py-3 whitespace-pre-line">{product.description}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Teknik Şartname: </td>
                            <td className="px-2 py-3">
                                {product.is_tech_spec_available ? 
                                <button type="button" className="text-md text-sky-500 hover:text-sky-800 underline" onClick={downloadTechSpec}>
                                    İndirmek için tıklayın.
                                </button>   
                                : 'Yüklenmedi'}
                            </td>
                        </tr>
                    </tbody>
                </table>
                </>}
                {loading && <Loader />}
            </div>  
    )
}

export default DetailInfo