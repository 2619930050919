import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {ButtonSave, ButtonClose} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall} from 'elements/inputs'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenProcurementRequestDepartmentUpdateProcurement } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

const UpdateProcurementForm = ({procurementRequest, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpen = useSelector(state => state.drawers.isOpenProcurementRequestWarehouseUpdateProcurement)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [procurements, setProcurements] = useState([])
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: {
            procurement_id: null,
        }
    })

    useEffect(() => {
        if(procurementRequest !== null && Object.keys(procurementRequest).length > 0) {
            if(procurementRequest.procurement_processes.length > 0) {
                setValue('procurement_id', procurementRequest.procurement_processes[0].procurement.id.toString())
                setError(null)
                setStatus(false)
            }
            
        }
    }, [procurementRequest])

    useEffect(() => {
        const getProcurements = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/procurements/procurements_for_procurement_requests`)
                .then(response => {
                    if(response.status === 200) {
                        let procurementArray = response.data.map(pr => {
                            return {key: pr.id, value: `${pr.procurement_no} (${pr.title})`}
                        })
                        setProcurements(procurementArray)               
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProcurements()
    }, [procurementRequest])

    useEffect(() => {
        if(!isOpen) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpen])

    const procurementInputs = [
        {
            key: "procurementInput1",
            type: "select",
            name: "procurement_id",
            placeholder: "Satın Alma İşlemi",
            label: "Satın Alma İşlemi *",
            validator: {
                  //               
            },
            error: errors?.procurement_id?.message,
            data: procurements,
            cols: 'col-span-12', 
            defaultOption: 'Tanımlanmadı'
        }, 
    ]

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/procurement_requests/departments/${procurementRequest.id}/update_procurement`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenProcurementRequestDepartmentUpdateProcurement(false))
    }

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm)}>
            <div className="grid grid-cols-12 gap-2">
            {procurementInputs.map(input => {
                return (
                    <div className={input.cols} key={input.key}>
                        {input.type == 'select' ? 
                            <SelectSmall register={register} {...input} /> :
                            <InputSmall register={register} {...input} />
                        }
                    </div>
                    )
            })}
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div> 
        </form> 
         
        {loading && <Loader />}
        </>
    )
}

export default UpdateProcurementForm