import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenPositionEdit } from 'stores/DrawersSlice' 
import EditPositionForm from 'modules/companyModule/pages/companies/settings/positions/EditPositionForm'

function EditPosition({update, setUpdate, position}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenPositionEdit)
    const setIsOpenCreate = () => dispatch(setIsOpenPositionEdit())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Pozisyonu Düzenle">
            <EditPositionForm 
                update={update} 
                setUpdate={setUpdate} 
                position={position}
            />
        </FormDrawer> 
    )
}

export default EditPosition