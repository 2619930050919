import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProcurementRequestDeliver } from 'stores/DrawersSlice' 
import DeliverForm from 'modules/companyModule/pages/warehouses/procurementRequests/DeliverForm'

function Deliver({procurementRequest, update, setUpdate, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCancel = useSelector(state => state.drawers.isOpenWarehouseProcurementRequestDeliver)
    const setIsOpenCancel = () => dispatch(setIsOpenWarehouseProcurementRequestDeliver())
    
    return(
        <FormDrawer isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} title="Satın Alma Talebini Teslim Al" maxWidth="max-w-6xl">
            <DeliverForm procurementRequest={procurementRequest} update={update} setUpdate={setUpdate} selectedWarehouse={selectedWarehouse} />
        </FormDrawer> 
    )
}

export default Deliver