import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray } from 'react-hook-form'
import {ButtonSave, ButtonClose, ButtonDeleteSmall, ButtonNewSmall, ButtonOk, ButtonEditSmall} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, Checkbox} from 'elements/inputs'
import SelectWithSearch from 'elements/inputs/SelectWithSearch'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenProcurementApprovalScenario } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'

const CostTypeScenarioForm = ({costType, users}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenEdit = useSelector(state => state.drawers.isOpenProcurementApprovalScenario)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)

    const formDefaults = {}
    const userDefaults = {user_id: "", approval_sequence: "1"}

    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue } = useForm({
        defaultValues: {formDefaults, users: [userDefaults]}
    })

    const { 
        fields: fields, 
        append: append, 
        remove: remove, 
        } = useFieldArray({
        control,
        name: "users"
    })

    const watchUsers = watch("users");
    const controlledUserFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchUsers[index]
        }
    })

    const userInputs = [
        {
            key: "procurementApprovalInput1",
            type: "select",
            nameLast: "user_id",
            placeholder: "Kullanıcı",
            label: null,
            validator: {},
            data: users.map(item => {
                return {value: item.id, label: item.name === '' ? item.email : item.name}
            }),
            cols: 'col-span-7', 
        },
        {
            key: "procurementApprovalInput2",
            type: "text",
            nameLast: "approval_sequence",
            placeholder: "Onay Sırası",
            label: null,
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^\b([0-9]|[1-9][0-9])\b$/,
                            message: "İki haneli sayı olmalı"},
                validate: {
                    positive: v => parseInt(v) > 0 || 'Sıfırdan büyük olmalı',
                    lessThan99: v => parseInt(v) < 100 || '99 ve daha az olmalı',
                }
            },
            cols: 'col-span-2', 
        },
    ]
    
    useEffect(() => {
        if(!isOpenEdit) {
            reset()
            setStatus(false)
            setError(null)
        }
    }, [isOpenEdit])

    useEffect(() => {
        if(isOpenEdit == true) {
            if(costType != null && Object.keys(costType).length > 0) {
                const getCostTypeUsers = async () => {
                    setLoading(true)
                    await SendRequest('GET', `api/companies/${companyId}/cost_types/${costType.id}/procurement_approval_users`)
                        .then(response => {
                            if(response.data.data.length > 0) {
                                let userItems = response.data.data.map(user => {
                                    return {user_id: user.approval_user.id, approval_sequence: user.approval_sequence}
                                })
                                reset({users: userItems})
                            } else {
                                reset({users: [userDefaults]})
                            }
                            setLoading(false)
                        })
                        .catch(err => {
                            setLoading(false)
                        })
                }    
                getCostTypeUsers()
            } else {
                reset({users: [userDefaults]})
            }
        }        
    }, [isOpenEdit])

    const handleForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/cost_types/${costType.id}/procurement_approval_users`, data)
                .then(res => {
                    if(res.status === 201) {
                        setStatus(true)
                        setError(null)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setStatus(false)
                    setLoading(false)
                })
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const close = () => {
        reset()
        setStatus(false)
        setError(null)
        dispatch(setIsOpenProcurementApprovalScenario())
    }

    return (
        <>
        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">{costType?.cost_name} İçin Onay Listesi</p>  
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status &&  <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />}
        <form onSubmit={handleSubmit(handleForm, handleError)}>
            <div>
                <div className='lg:grid lg:grid-cols-12 gap-2 pb-1 border-b border-gray-700 mt-4'>
                    <div className='col-span-1'></div>
                    <div className='col-span-7'>Onaylayacak Kullanıcı</div>
                    <div className='col-span-2'>Onay Sırası</div>
                    <div className='col-span-2'></div>
                </div>
            </div>
            <div>
            {controlledUserFields.map((field, index) => {
                    return (
                        <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${field.id}`}>
                            <div className='col-span-1 mt-2'>
                                <div>
                                    {index+1}.
                                </div>
                            </div>
                            <div className={userInputs[0].cols}>
                                <SelectWithSearch 
                                    dataArray={userInputs[0].data}
                                    control={control}
                                    name={`users.${index}.${userInputs[0].nameLast}`}
                                    rules={{...userInputs[0].validator}}
                                    error={errors?.users?.[index]?.[userInputs[0].nameLast]?.message} 
                                    actions={null}
                                />                                
                            </div>
                            <div className={userInputs[1].cols}>
                                <InputSmall 
                                    {...userInputs[1]}
                                    register={register} 
                                    name={`users.${index}.${userInputs[1].nameLast}`} 
                                    error={errors?.users?.[index]?.[userInputs[1].nameLast]?.message} 
                                />                              
                            </div>
                            <div className='col-span-2 mt-0.5'>
                                <div>
                                    {
                                    index === 0 
                                    ? <ButtonNewSmall label="Ekle" addClass='w-full' type="button" onClick={() => append(userDefaults)} ></ButtonNewSmall>
                                    : <ButtonDeleteSmall addClass='w-full' type="button" onClick={() => remove(index)} ></ButtonDeleteSmall>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonSave type="submit">
                </ButtonSave>
                <ButtonClose onClick={() => close()}>
                </ButtonClose>
            </div>                
        </form>   
        {loading && <Loader />}
        </>
    )
}

export default CostTypeScenarioForm