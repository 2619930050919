import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    authenticated: null,
    emailVerified: null,
    userType: null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload.user
            state.userType = action.payload.user?.type ?? null
            state.authenticated = action.payload.authenticated
            state.emailVerified = action.payload.emailVerified
        },
        logout: (state) => {
            state.user = null
            state.userType = null
            state.authenticated = false
            state.emailVerified = false
        },
        setUserData: (state, action) => {
            state.user = action.payload
        }
    }
})

export const {login, logout, setUserData} = userSlice.actions

export default userSlice.reducer