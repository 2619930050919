import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/procurementProcesses/procurements/create/Form'

function Create({update, setUpdate, users, procurement}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenProcurementCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenProcurementCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Teklif Toplama Oluştur" maxWidth="max-w-4xl">
            <CreateForm update={update} setUpdate={setUpdate} users={users} procurement={procurement} />
        </FormDrawer> 
    )
}

export default Create