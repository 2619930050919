import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenOrderForm } from 'stores/DrawersSlice' 
import OrderForm from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/OrderForm'

function Order({procurement, proposal}) {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenOrderForm)
    const setIsOpenDetail = () => dispatch(setIsOpenOrderForm())
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Sipariş Formu" maxWidth="max-w-[910px]">
            <OrderForm procurement={procurement} proposal={proposal} />
        </FormDrawer> 
    )
}

export default Order