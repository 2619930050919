
function AccountInfo({account}) {

    return (
        <div className="">
                {(account !==null && account !== undefined && Object.keys(account).length > 0) &&
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Banka Adı:</td>
                            <td className="px-2 py-3">{account.bank_name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Şube Adı:</td>
                            <td className="px-2 py-3">{account.branch_name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">IBAN:</td>
                            <td className="px-2 py-3">{account.iban}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Hesap Numarası:</td>
                            <td className="px-2 py-3">{account.account_no}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Para Birimi:</td>
                            <td className="px-2 py-3">{account.currency.description} ({account.currency.code})</td>
                        </tr>
                    </tbody>
                </table>}
            </div>  
    )
}

export default AccountInfo