import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementRequestDepartmentUpdateProcurement } from 'stores/DrawersSlice' 
import UpdateProcurementForm from 'modules/companyModule/pages/procurementProcesses/departmentRequests/UpdateProcurementForm'

function UpdateProcurement({procurementRequest, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenProcurementRequestDepartmentUpdateProcurement)
    const setIsOpen = () => dispatch(setIsOpenProcurementRequestDepartmentUpdateProcurement())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Satın Alma İşlemi Ekle">
            <UpdateProcurementForm procurementRequest={procurementRequest} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default UpdateProcurement