import { useSelector } from 'react-redux'
import { ProcurementSupplierSelectionTypes, 
        ProcurementProductTechSpecTypes, 
        ProcurementProposalTypes, 
        ProcurementPaymentTypes,
        ProcurementDeliveryTypes } from 'data'

export function useProcurementInput(errors, costTypes) {
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const costTypeArray = costTypes.map(ct => {
        return {key: ct.cost_type.id, value: ct.cost_type.cost_name}
    })

    const porposalTypeArray = ProcurementProposalTypes()
    
    return [
        {
            key: "procurementInput1",
            type: "text",
            name: "title",
            placeholder: "Talep Başlığı",
            label: "Talep Başlığı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.title?.message,
            cols: 'col-span-12', 
        },
        {
            key: "procurementInput2",
            type: "select",
            name: "business_area_id",
            placeholder: "Satın Alma Sektörü",
            label: "Satın Alma Sektörü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.business_area_id?.message,
            data: businessAreas,
            cols: 'col-span-6', 
        }, 
        {
            key: "procurementInput3",
            type: "select",
            name: "cost_type_id",
            placeholder: "Gider Türü",
            label: "Gider Türü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.cost_type_id?.message,
            data: costTypeArray,
            cols: 'col-span-6', 
        }, 
        {
            key: "procurementInput4",
            type: "select",
            name: "proposal_type",
            placeholder: "Teklif Toplama Türü",
            label: "Teklif Toplama Türü *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.proposal_type?.message,
            data: porposalTypeArray,
            cols: 'col-span-12', 
        },       
        {
            key: "procurementInput5",
            type: "checkbox",
            name: "is_proposal_increase_not_allowed",
            label: "Tedarikçi revize tekliflerinde fiyat arttıramasın.",
            validator: {},
            error: errors?.is_proposal_increase_not_allowed?.message,
            cols: 'col-span-12',
            addClass: 'pb-2 lg:pb-0',
        },
        {
            key: "procurementInput6",
            type: "checkbox",
            name: "is_proposal_cancel_not_allowed",
            label: "Tedarikçi verdiği teklifi iptal edemesin.",
            validator: {},
            error: errors?.is_proposal_cancel_not_allowed?.message,
            cols: 'col-span-12',
            addClass: 'pb-2 lg:pb-0',
        },
    ]
}

export function usePaymentTermInput(errors, getValues) {
    const currencies = useSelector(state => state.sharedData.currenciesWithDesc)
    const paymentTypeArray = ProcurementPaymentTypes()

    return [
        {
            key: "paymentTermInput1",
            type: "select",
            name: "currency_id",
            placeholder: "Para Birimi",
            label: "Para Birimi *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.currency_id?.message,
            data: currencies,
            cols: 'col-span-6', 
        },  
        {
            key: "paymentTermInput4",
            type: "checkbox",
            name: "is_currency_flexible",
            label: "Tedarikçi tarafından değiştirilebilir",
            validator: {},
            error: errors?.is_currency_flexible?.message,
            cols: 'col-span-6',
            addClass: 'lg:mt-6 pb-2 lg:pb-0',
        },
        {
            key: "paymentTermInput2",
            type: "select",
            name: "payment_type",
            placeholder: "Ödeme Şekli",
            label: "Ödeme Şekli *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.payment_type?.message,
            data: paymentTypeArray,
            cols: 'col-span-4', 
        },  
        {
            key: "paymentTermInput3",
            type: "text",
            name: "deferral_days",
            placeholder: "Vade (Gün)",
            label: "Vade (Gün) *",
            validator: {
                required: {value: getValues('payment_type') !== 'deferred', message: 'Bu alan zorunudur.'}, 
                pattern: {value: /^\b([1-9]|[1-9][0-9]|[1-2][0-9]{2}|3[0-5][0-9]|36[0-5])\b$/,
                            message: "1-365 arasında bir tam sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) > 0 || 'Sıfırdan büyük olmalı'
                }                 
            },
            error: errors?.deferral_days?.message,
            cols: 'col-span-2', 
        },        
        {
            key: "paymentTermInput5",
            type: "checkbox",
            name: "is_payment_type_flexible",
            label: "Tedarikçi tarafından değiştirilebilir",
            validator: {},
            error: errors?.is_payment_type_flexible?.message,
            cols: 'col-span-4',
            addClass: 'lg:mt-6',
        },
    ]
}

export function useDeliveryTermInput(errors) {
    const deliveryTypeArray = ProcurementDeliveryTypes()

    return [
        {
            key: "deliveryTermInput1",
            type: "select",
            name: "delivery_type",
            placeholder: "Sevkiyat Yöntemi",
            label: "Sevkiyat Yöntemi *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.delivery_type?.message,
            data: deliveryTypeArray,
            cols: 'col-span-6', 
        },  
        {
            key: "deliveryTermInput2",
            type: "datetime-local",
            name: "delivery_date",
            placeholder: "Teslim Tarihi",
            label: "Teslim Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.delivery_date?.message,
            cols: 'col-span-6', 
        },    
        {
            key: "deliveryTermInput3",
            type: "textarea",
            name: "delivery_address",
            placeholder: "Teslimat Adresi",
            label: "Teslimat Adresi *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.delivery_address?.message,
            cols: 'col-span-12', 
        },
    ]
}

export function useDescriptionInput(errors) {
    return [
        {
            key: "descriptionInput1",
            type: "textarea",
            name: "description",
            placeholder: "Genel Açıklamalar",
            label: "Genel Açıklamalar",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
            },
            error: errors?.description?.message,
            cols: 'col-span-12', 
        },
    ]
}

export function useLineInput(errors, products) {
    const techSpecTypeArray = ProcurementProductTechSpecTypes()

    const productArray = products.map(item => {
        return {key: item.id, value: item.name}
    })

    return [
        {
            key: "lineInput1",
            type: "select",
            nameLast: "product_id",
            placeholder: "Ürün",
            label: "Ürün *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: productArray,
            cols: 'col-span-8', 
        },        
                {
            key: "lineInput2",
            type: "text",
            nameLast: "quantity",
            placeholder: "Miktar",
            label: "Miktar *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]\d*(,\d{1,6})?$/,
                            message: "12345,6789 formatında bir sayı olmalı"},
                validate: {
                    positive: v => parseFloat(v.toString().replace(",", ".")) > 0 || 'Sıfırdan büyük olmalı'
                }
            },
            cols: 'col-span-2', 
        },
        {
            key: "lineInput3",
            type: "select",
            nameLast: "unit_code_id",
            placeholder: "Birim",
            label: "Birim *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: [],
            cols: 'col-span-2', 
        },
        {
            key: "lineInput4",
            type: "textarea",
            nameLast: "description_text",
            placeholder: "Ürün Açıklaması",
            label: "Ürün Açıklaması",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            cols: 'col-span-12', 
        },
        {
            key: "lineInput5",
            type: "select",
            nameLast: "tech_spec_type",
            placeholder: "Teknik Şartname",
            label: "Teknik Şartname *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            data: techSpecTypeArray,
            cols: 'col-span-6', 
        },
        {
            key: "lineInput6",
            type: "file",
            nameLast: "technical_specification_file",
            placeholder: "Dosya Seç",
            label: "Ürün Şartname Dosyası *",
            validator: {},
            notSelectedText: 'Dosya Seçilmedi',
        }, 
    ]
}

export function useStatusInputs(errors) {
    const statusArrayForUpdate = [
        {key: 'completed', value: 'Süreç Tamamlandı'}, 
        {key: 'archived', value: 'Arşivlendi'},
    ]
    
    return [
        {
            key: "statusInput1",
            type: "select",
            name: "status",
            placeholder: "Satın Alma Talep Durumu",
            label: "Satın Alma Talep Durumu *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.status?.message,
            data: statusArrayForUpdate,
            cols: 'col-span-12', 
        }, 
    ]
}

export function useEnddateInputs(errors) {
    return [
        {
            key: "enddateInput1",
            type: "datetime-local",
            name: "end_date",
            placeholder: "Teklif Sona Erme Tarihi",
            label: "Teklif Sona Erme Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.end_date?.message,
            cols: 'col-span-12', 
        },   
        {
            key: "enddateInput2",
            type: "datetime-local",
            name: "delivery_date",
            placeholder: "Teslim Tarihi",
            label: "Teslim Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.delivery_date?.message,
            cols: 'col-span-12', 
        },    
    ]
}

export function useIssueInputs(errors) {
    return [
        {
            key: "issueInput1",
            type: "datetime-local",
            name: "start_date",
            placeholder: "Teklif Yayın Tarihi",
            label: "Teklif Yayın Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.start_date?.message,
            cols: 'col-span-12', 
        },    
        {
            key: "issueInput2",
            type: "datetime-local",
            name: "end_date",
            placeholder: "Teklif Sona Erme Tarihi",
            label: "Teklif Sona Erme Tarihi *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
                            message: "Geçerli bir tarih-saat girmelisiniz."}
            },
            error: errors?.end_date?.message,
            cols: 'col-span-12', 
        },    
        // {
        //     key: "issueInput3",
        //     type: "datetime-local",
        //     name: "delivery_date",
        //     placeholder: "Teslim Tarihi",
        //     label: "Teslim Tarihi *",
        //     validator: {
        //         required: "Bu alan zorunludur.", 
        //         pattern: {value: /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])T[0-2]\d:[0-5]\d$/,
        //                     message: "Geçerli bir tarih-saat girmelisiniz."}
        //     },
        //     error: errors?.delivery_date?.message,
        //     cols: 'col-span-12', 
        // },    
    ]
}

export function useApprovalInput(error, users) {
    const userArray = users.map(item => {
        return {key: item.id, value: item.name}
    })

    return [
        {
            key: "approvalInput1",
            type: "select",
            nameLast: "user_id",
            placeholder: "Kullanıcı",
            label: null,
            validator: {},
            data: userArray,
            cols: 'col-span-10', 
        },
    ]
}

export function useTechSpecInputs(errors, techSpecFileAttributes, setTechSpecFileAttributes) {
    
    return [
        {
            key: "technicalSpecInput1",
            type: "file",
            name: "technical_specification_file",
            placeholder: "Dosya Seç",
            label: "Genel Şartname Dosyası *",
            validator: {
                required: "Bu alan zorunludur.",
                validate: (value) => {
                    if(value[0].type !== "application/pdf") {
                        return 'PDF dosyası seçmelisiniz'
                    }
                    return true
                },
                onChange: (e) => {
                    let files = e.target.files
                    if(files.length > 0) {
                        setTechSpecFileAttributes({
                            filename: files[0].name,
                            size: files[0].size,
                            type: files[0].type,
                        })
                    } else {
                        setTechSpecFileAttributes({
                            filename: null,
                            size: null,
                            type: null,
                        })
                    }
                }
            },
            error: errors?.technical_specification_file?.message,
            notSelectedText: 'Dosya Seçilmedi',
            fileAttributes: techSpecFileAttributes,
            cols: 'col-span-12', 
        }, 
    ]
}

export function useSupplierSelectionTypeInput(errors) {
    const typeArray = ProcurementSupplierSelectionTypes()
    
    return [
        {
            key: "supplierSelectionInput1",
            type: "select",
            name: "supplier_selection_type",
            placeholder: "Teklif Vermesi İstenilen Tedarikçiler",
            label: "Teklif Vermesi İstenilen Tedarikçiler *",
            validator: {
                required: "Bu alan zorunludur.",                 
            },
            error: errors?.supplier_selection_type?.message,
            data: typeArray,
            cols: 'col-span-12', 
        }, 
    ]
}

export function useSupplierSelectionBusinessAreaInput(errors) {
    const businessAreas = useSelector(state => state.sharedData.businessAreas)
    const baArray = businessAreas.map(item => {
        return {value: item.key, label: item.value}
    })
    
    return [
        {
            key: "businessAreaInput1",
            type: "multiselect",
            name: "supplier_business_areas",
            placeholder: "Seçiniz",
            label: "Sektör * (Birden fazla seçebilirsiniz) *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.supplier_business_areas?.message,
            limitOptions: false,
            data: baArray,
            actions: (e) => null,
            cols: 'col-span-12',
        }, 
    ]
}

export function useSupplierSelectionSupplierInput(errors, suppliers) {
    const baArray = suppliers.map(item => {
        return {value: item.id, label: item.company_name}
    })
    
    return [
        {
            key: "supplierInput1",
            type: "multiselect",
            name: "suppliers",
            placeholder: "Seçiniz",
            label: "Tedarikçiler * (Birden fazla seçebilirsiniz) *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.suppliers?.message,
            data: baArray,
            actions: (e) => null,
            cols: 'col-span-12',
        }, 
    ]
}