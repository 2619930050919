import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' 
import { ButtonOk, ButtonClose} from 'elements/buttons/actionButtons'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import DetailInfo from 'modules/companyModule/pages/procurementRequestApprovals/departmentRequests/DetailInfo'

function ApprovalWithToken() {
    const dispatch = useDispatch()
    let params = useParams()
    const [procurementRequest, setProcurementRequest] = useState([])
    const [update, setUpdate] = useState([])
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [error, setError] = useState(null)

    const approvalStatus = () => {
        if(procurementRequest !== null && procurementRequest?.approvals) {
            let thisApproval = procurementRequest.approvals.find(approval => approval.token === params.uuid)
            if(thisApproval) {
                if(thisApproval.is_accepted) return 'accepted'
                if(thisApproval.is_rejected) return 'rejected'
                return 'not_processed'
            }                        
        } 
        return null
    }

    useEffect(() => {
        const getProductDemand = async () => {
            setLoading(true)
            await SendRequest('GET', `api/department_procurement_request_approval_with_token/${params.uuid}`)
                .then(response => {
                    if(response.status === 200) {
                        setProcurementRequest(response.data.data)
                    }
                    setLoading(false)
                    setError(null)
                })
                .catch(err => {
                    setLoading(false)
                    setError(err.response.data)
                })
        }
        getProductDemand()
    }, [update])

    const handleApprove = async () => {
        setLoading(true)
        await SendRequest('POST', `api/department_procurement_request_approval_with_token/${params.uuid}/accept`)
                .then(res => {
                    if(res.status === 204) {
                        setSuccessMessage('Ürün talebi başarıyla onaylandı')
                        setUpdate(prev => !prev)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    const handleReject = async () => {
        setLoading(true)
        await SendRequest('POST', `api/department_procurement_request_approval_with_token/${params.uuid}/reject`)
                .then(res => {
                    if(res.status === 204) {
                        setSuccessMessage('Ürün talebi başarıyla reddedildi')
                        setUpdate(prev => !prev)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    return (
        <div className="w-full py-10 px-4">
            <div className="max-w-5xl lg:mx-auto">
                <div className="w-full h-full text-xs lg:text-sm">
                <div className="text-red-500 mt-4">
                    {error && <ServerError errorData={error} />}
                </div>
                <div className="text-green-500 mt-4">
                    {successMessage && <ServerSuccess message={successMessage} />}
                </div>
                {procurementRequest !== null  ? 
                <>
                    <DetailInfo procurementRequest={procurementRequest} downloadToken={params.uuid} /> 
                    {approvalStatus() == 'not_processed' ? 
                    <div className="w-full flex justify-end mt-5 gap-x-4">
                        <ButtonClose label="Reddet" type="button" onClick={handleReject}>
                        </ButtonClose>
                        <ButtonOk label="Onayla" type="button" onClick={handleApprove}>
                        </ButtonOk>
                    </div>  
                    : null}
                    {approvalStatus() == 'accepted' ? 
                    <div className="w-full flex justify-end mt-5 gap-x-4">
                        <span className="text-green-600 font-semibold">Talep daha önce onaylanmış</span>
                    </div>  
                    : null}
                    {approvalStatus() == 'rejected' ? 
                    <div className="w-full flex justify-end mt-5 gap-x-4">
                        <span className="text-red-600 font-semibold">Talep daha önce reddedilmiş</span>
                    </div>  
                    : null}
                </>
               
                : null }
                </div>
            </div>
        </div>
    )
}

export default ApprovalWithToken