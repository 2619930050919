import { NavLink } from 'react-router-dom'
import logo from 'assets/img/marlaport_logo.png'

function Logo() {
    return (
        <div className="flex items-center justify-between flex-wrap shadow-sm bg-white h-[60px]">
            <NavLink to="/" className="flex items-center text-white px-2 py-3 w-full">
                <div className="w-full flex font-medium items-center md:justify-center justify-start">
                    <img src={logo} className="h-10"/>    
                </div>
            </NavLink>
        </div>
    )
    
}

export default Logo