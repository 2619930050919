import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TreeNode } from 'react-organizational-chart'
import { ButtonEditSmall, ButtonNewSmall, ButtonNew, ButtonDetailSmall } from 'elements/buttons/actionButtons'
import { setIsOpenWarehouseCreate, 
        setIsOpenWarehouseEdit, 
        setIsOpenWarehouseAddUser, 
        setIsOpenWarehouseAddProductCategory,
        setIsOpenWarehouseProcurementRequestWorkflow,
        } from '../../../../../../stores/DrawersSlice'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import CreateWarehouse from 'modules/companyModule/pages/companies/settings/warehouses/CreateWarehouse'
import EditWarehouse from 'modules/companyModule/pages/companies/settings/warehouses/EditWarehouse'
import EditWarehouseUsers from 'modules/companyModule/pages/companies/settings/warehouses/EditWarehouseUsers'
import EditWarehouseCategory from 'modules/companyModule/pages/companies/settings/warehouses/EditWarehouseCategory'
import EditWarehouseProcurementRequests from 'modules/companyModule/pages/companies/settings/warehouses/EditWarehouseProcurementRequests'
import TableActions from 'modules/companyModule/pages/companies/settings/warehouses/TableActions'

const WarehouseSettings = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [warehouseForEdit, setWarehouseForEdit] = useState(null)
    const [warehouseForUser, setWarehouseForUser] = useState(null)
    const [warehouseForCategory, setWarehouseForCategory] = useState(null)
    const [warehouseForProcurementRequest, setWarehouseForProcurementRequest] = useState(null)
    const [loading, setLoading] = useState(false)
    

    useEffect(() => {
        const getDepartments = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/warehouses`)
                .then(response => {
                    if(response.status === 200) {
                        setWarehouses(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getDepartments()
    }, [updateData])

    const WarehouseCards = ({whs}) => {
        return whs.map(wh => {
            return (
                <div key={wh.id}>
                    <div className="px-2 py-1 my-1 border border-sky-200 bg-sky-50 min-w-[12rem]">
                        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-y-2">
                            <p className='lg:whitespace-nowrap font-semibold text-sm text-gray-700'>{wh.warehouse_name} ({wh.warehouse_code})</p>
                            <div className='w-full flex flex-col lg:flex-row justify-end gap-y-1 lg:gap-x-1'>
                                <TableActions 
                                    data={wh}
                                    actions = {{
                                        setWarehouseForEdit: setWarehouseForEdit,
                                        setWarehouseForUser: setWarehouseForUser,
                                        setWarehouseForCategory: setWarehouseForCategory,
                                        setWarehouseForProcurementRequest: setWarehouseForProcurementRequest,
                                    }}
                                />
                            </div>
                        </div>                        
                    </div>
                </div>
            )
        })
    }

    const data = []

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800">Depolar</h2>
                <ButtonNew label="Yeni Depo" onClick={() => {dispatch(setIsOpenWarehouseCreate(true))}}>
                </ButtonNew>
            </div>
            <div className="w-full pb-4">
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-2 md:gap-x-4">
                    <div className="col-span-1">
                    <WarehouseCards whs={warehouses} />
                    </div>
                    <div className="col-span-1">
                        
                    </div>
                </div>                 
            </div>

            <CreateWarehouse 
                update={updateData} 
                setUpdate={setUpdateData} 
            />
            <EditWarehouse 
                update={updateData} 
                setUpdate={setUpdateData} 
                warehouse={warehouseForEdit} 
                setWarehouse={setWarehouseForEdit} 
            />
            <EditWarehouseUsers 
                update={updateData} 
                setUpdate={setUpdateData} 
                warehouse={warehouseForUser}
            />
            <EditWarehouseProcurementRequests 
                update={updateData} 
                setUpdate={setUpdateData} 
                warehouse={warehouseForProcurementRequest}
            />
            <EditWarehouseCategory 
                update={updateData} 
                setUpdate={setUpdateData} 
                warehouse={warehouseForCategory}
            />
            
            {/* 
            
             */}
            {loading && <Loader />}
        </div>
    )
}

export default WarehouseSettings