import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import { GreenNormal, OrangeNormal, RedNormal, BlueNormal } from "elements/infoCards" 

function TopCards({updateData}) {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/statistics/suppliers`)
                .then(response => {
                    if(response.status === 200) {
                        setData(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getData()
    }, [updateData])

    const placeholderData = {
        approved: data?.approved || 0,
        waiting_approval: data?.waiting_approval || 0,
        blacklisted: data?.blacklisted || 0,
    }

    return (
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-4">
            <GreenNormal 
                title="Onaylı Tedarikçiler"
                content={`${placeholderData.approved} Adet`}
            />
            <OrangeNormal 
                title="Onaylanmayı Bekleyen Tedarikçiler"
                content={`${placeholderData.waiting_approval} Adet`}
            />
            <RedNormal 
                title="Kara Listede Olan Tedarikçiler"
                content={`${placeholderData.blacklisted} Adet`}
            />
        </div>
    )
}

export default TopCards