import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementIssue } from 'stores/DrawersSlice' 
import IssueForm from 'modules/companyModule/pages/procurementProcesses/procurements/IssueForm'

function Issue({procurement, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenEdit = useSelector(state => state.drawers.isOpenProcurementIssue)
    const setIsOpenEdit = () => dispatch(setIsOpenProcurementIssue())
    
    return(
        <FormDrawer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} title="Satın Alma Talebini Yayına Al">
            <IssueForm procurement={procurement} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Issue