import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierBankAccountEdit } from 'stores/DrawersSlice' 
import EditForm from 'modules/supplierModule/pages/companies/bankAccounts/EditForm'

function Edit({account, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenSupplierBankAccountEdit)
    const setIsOpen = () => dispatch(setIsOpenSupplierBankAccountEdit())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Banka Hesap Bilgilerini Düzenle">
            <EditForm account={account} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Edit