import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="bg-gradient-to-r from-sky-300 to-blue-200">
      <div className="w-full py-16 h-full flex items-center justify-center">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
          <div className="border-t border-gray-200 text-center pt-8 text-gray-600">
            <h1 className="text-9xl font-bold">404</h1>
            <h1 className="text-6xl font-medium py-8">Sayfa Bulunamadı</h1>
            <p className="text-2xl pb-8 px-12 font-medium">
                Aradığınız sayfa mevcut değil. Taşınmış veya silinmiş olabilir.
            </p>
            <Link to="/" className="bg-gradient-to-r from-sky-400 to-blue-500 hover:from-sky-500 hover:to-blue-400 text-white font-semibold px-6 py-3 rounded-md mr-6">
              ANA SAYFA
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
