import { useSelector } from "react-redux"

export function useSupplierInput(errors, businessAreas, cities, filteredDistricts, handleChangeCity) {
    const baArray = businessAreas.map(item => {
        return {value: item.key, label: item.value}
    })

    return [
        {
            key: "supplierInput1",
            type: "multiselect",
            name: "supplier.business_areas",
            placeholder: "Seçiniz",
            label: "Sektör * (Birden fazla seçebilirsiniz) *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.supplier?.business_areas?.message,
            limitOptions: false,
            data: baArray,
            actions: (e) => null,
            cols: 'col-span-12',
        },
        {
            key: "supplierInput2",
            type: "text",
            name: "supplier.company_name",
            placeholder: "Tedarikçi Adı/Ünvanı",
            label: "Tedarikçi Adı/Ünvanı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.supplier?.company_name?.message,
            cols: 'col-span-12', 
        },
        {
            key: "supplierInput3",
            type: "text",
            name: "supplier.tax_department",
            placeholder: "Vergi Dairesi",
            label: "Vergi Dairesi *",
            validator: {
                required: "Bu alan zorunludur.",
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.supplier?.tax_department?.message,
            cols: 'col-span-6',
        },
        {
            key: "supplierInput4",
            type: "text",
            name: "supplier.tax_number",
            placeholder: "Vergi No",
            label: "Vergi No *",
            validator: {
                required: "Bu alan zorunludur.", 
                pattern: {value: /^[0-9]{10,11}$/,
                            message: "10 veya 11 haneli rakam olmalı"},
            },
            error: errors?.supplier?.tax_number?.message,
            cols: 'col-span-6',
        },   
        {
            key: "supplierInput5",
            type: "select",
            name: "supplier.city_id",
            placeholder: "İl",
            label: "İl *",
            validator: {
                required: "Bu alan zorunludur.",
                onChange: (e) => handleChangeCity(e.target.value)
            },
            error: errors?.supplier?.city_id?.message,
            data: cities,
            cols: 'col-span-6',
        }, 
        {
            key: "supplierInput6",
            type: "select",
            name: "supplier.district_id",
            placeholder: "İlçe",
            label: "İlçe *",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.supplier?.district_id?.message,
            data: filteredDistricts,
            cols: 'col-span-6',
        },
        
        {
            key: "supplierInput7",
            type: "text",
            name: "supplier.address",
            placeholder: "Adres",
            label: "Adres *",
            validator: {
                required: "Bu alan zorunludur.",
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.supplier?.address?.message,
            cols: 'col-span-12',
        },
        {
            key: "supplierInput8",
            type: "phone",
            name: "supplier.phone",
            placeholder: "Telefon",
            label: "Telefon",
            validator: {
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^\+90 \((?!0|1)\d{3}\) \d{3}-\d{2}-\d{2}$/,
                            message: "Geçerli bir telefon numarası olmalı"}
            },
            error: errors?.supplier?.phone?.message,
            cols: 'col-span-6',
        },
        {
            key: "supplierInput9",
            type: "text",
            name: "supplier.email",
            placeholder: "E-Posta",
            label: "E-Posta",
            validator: {
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.supplier?.email?.message,
            cols: 'col-span-6',
        },        
    ]
}

export function useUserInput(errors, getValues) {
    
    return [
        {
            key: "userInput1",
            type: "text",
            name: "user.name",
            placeholder: "Kullanıcı Ad/Soyad",
            label: "Kullanıcı Ad/Soyad *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
            },
            error: errors?.user?.name?.message,
            cols: 'col-span-12', 
        },
        {
            key: "userInput2",
            type: "phone",
            name: "user.phone",
            placeholder: "Kullanıcı Telefon",
            label: "Kullanıcı Telefon *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 10, 
                    message: "En az 10 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},
                pattern: {value: /^\+90 \((?!0|1)\d{3}\) \d{3}-\d{2}-\d{2}$/,
                            message: "Geçerli bir telefon numarası olmalı"}
            },
            error: errors?.user?.phone?.message,
            cols: 'col-span-12', 
        },
        {
            key: "userInput3",
            type: "text",
            name: "user.email",
            placeholder: "Kullanıcı E-Posta",
            label: "Kullanıcı E-Posta *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 4, 
                    message: "En az 4 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"},
                pattern: {value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Geçerli bir e-posta adresi olmalı"}
            },
            error: errors?.user?.email?.message,
            cols: 'col-span-12', 
        },
        {
            key: "userInput4",
            type: "password",
            name: "user.password",
            placeholder: "Kullanıcı Şifre",
            label: "Kullanıcı Şifre *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},
            },
            error: errors?.user?.password?.message,
            cols: 'col-span-6', 
        },
        {
            key: "userInput5",
            type: "password",
            name: "user.password_confirmation",
            placeholder: "Şifre Tekrar",
            label: "Şifre Tekrar *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 8, 
                    message: "En az 8 karakter olmalı"}, 
                maxLength: {value: 25, 
                            message:"En fazla 25 karakter olmalı"},   
                validate: {
                    confirmation: v => v === getValues("user.password") || 'Girmiş olduğunuz şifreler eşleşmiyor'
                }  
            },
            error: errors?.user?.password_confirmation?.message,
            cols: 'col-span-6', 
        },
    ]
}
