import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProductStockTransferList } from 'stores/DrawersSlice' 
import StockTransferListForm from 'modules/companyModule/pages/warehouses/stocks/StockTransferListForm'

function StockTransferList({product, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenWarehouseProductStockTransferList)
    const setIsOpen = () => dispatch(setIsOpenWarehouseProductStockTransferList())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Stok Düzeltme Listesi" >
            <StockTransferListForm product={product} selectedWarehouse={selectedWarehouse} />
        </FormDrawer> 
    )
}

export default StockTransferList