import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {ButtonNew, ButtonCancel, ButtonOk, ButtonCancelAlt} from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import { setIsOpenProcurementProposalCancel } from 'stores/DrawersSlice' 
import DetailInfoForProposal from 'modules/companyModule/pages/procurementProcesses/commonParts/DetailInfoForProposal'
import Loader from 'elements/Loader'

const ProposalCancelForm = ({procurement, update, setUpdate}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const isOpenCancel = useSelector(state => state.drawers.isOpenProcurementProposalCancel)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)

    const [proposals, setProposals] = useState(null)

    const handleCancel = async () => {
        setLoading(true)
        await SendRequest('PUT', `api/companies/${companyId}/procurements/${procurement.id}/cancel_acceptance`)
                .then(res => {
                    if(res.status === 204) {
                        setStatus(true)
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }

    useEffect(() => {     
        if(!isOpenCancel) {
            setStatus(false)
            setError(null)
        }      
        if(isOpenCancel) {
            if(procurement && procurement.accepted_proposals) {
                const acceptedProposals = procurement.accepted_proposals.map(ap => {
                    return {...ap, lines: ap.accepted_lines}
                })
                setProposals(acceptedProposals)
            }
        } 

    }, [isOpenCancel])
    

    const close = () => {
        setStatus(false)
        setError(null)
        dispatch(setIsOpenProcurementProposalCancel())
    }

    return (
        <>
        {status ? 
            <>
            <ServerSuccess message='Teklif kabulü başarıyla iptal edildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-6rem)]">
                <div className='w-full'>
                    <div className="text-red-500 mt-4">
                        {error && <ServerError errorData={error} />}
                    </div>
                </div>
            <div className="w-full flex-1 overflow-y-auto px-1">
                <p className="text-sm font-semibold text-gray-700 mb-0.5">Aşağıda detayları verilen teklifi kabulünü iptal etmek istediğinizden emin misiniz? </p>
                <DetailInfoForProposal proposal={proposals} procurement={procurement} />
            </div>            
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonCancelAlt type="button" onClick={close}>
                </ButtonCancelAlt>
                <ButtonCancel label="Teklifi Kabulünü İptal Et" type="button" onClick={handleCancel}>
                </ButtonCancel>                
            </div>  
            </div>       
        }   
        {loading && <Loader />}
        </>
    )
}

export default ProposalCancelForm