import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import TableDropdown from 'elements/dropdowns/TableDropdown'
import TableButton from 'elements/dropdowns/TableButton'
import { IconContext } from "react-icons"
import { FaExchangeAlt, FaDeezer, FaEraser, FaSlidersH, FaTruckLoading, FaEdit } from 'react-icons/fa'
import { setIsOpenWarehouseProductInitialStock, 
        setIsOpenWarehouseProductStockCorrectionList, 
        setIsOpenWarehouseProductStockCorrectionAdd, 
        setIsOpenWarehouseProductStockTransferList,
        setIsOpenWarehouseProductStockTransferAdd } from '../../../../../stores/DrawersSlice'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const Content = () => {
        return (
            <div className="py-1">
                <IconContext.Provider value={{ className: "h-3 w-3" }}>
                <TableButton 
                    title='Başlangıç Stoğu' 
                    icon={<FaDeezer />} 
                    onClick={() => {
                        actions.setProductForInitialStock(data)
                        dispatch(setIsOpenWarehouseProductInitialStock(true))
                        }}
                />
                <TableButton 
                    title='Stok Düzeltme Listesi' 
                    icon={<FaEdit />} 
                    borderTop={true} 
                    onClick={() => {
                        actions.setProductForCorrectionList(data)
                        dispatch(setIsOpenWarehouseProductStockCorrectionList(true))
                        }}
                />
                <TableButton 
                    title='Stok Düzeltmesi Ekle' 
                    icon={<FaEraser />} 
                    onClick={() => {
                        actions.setProductForCorrectionAdd(data)
                        dispatch(setIsOpenWarehouseProductStockCorrectionAdd(true))
                        }}
                />
                <TableButton 
                    title='Stok Transfer Listesi' 
                    icon={<FaExchangeAlt />} 
                    borderTop={true} 
                    onClick={() => {
                        actions.setProductForTransferList(data)
                        dispatch(setIsOpenWarehouseProductStockTransferList(true))
                        }}
                />
                <TableButton 
                    title='Stok Transferi Ekle' 
                    icon={<FaTruckLoading />} 
                    onClick={() => {
                        actions.setProductForTransferAdd(data)
                        dispatch(setIsOpenWarehouseProductStockTransferAdd(true))
                        }}
                />
                </IconContext.Provider>
            </div>
        )
    }    

    return (
        <TableDropdown title='İşlemler' icon={<FaSlidersH />}>
            <Content />
        </TableDropdown>
    )
};

export default TableActions
