import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import { FaVoteYea } from 'react-icons/fa'
import TopCards from 'modules/companyModule/pages/home/productDemands/TopCards'
import ProductDemandLogs from 'modules/companyModule/pages/home/productDemands/ProductDemandLogs'
import LatestDemands from 'modules/companyModule/pages/home/productDemands/LatestDemands'
import StatusChart from 'modules/companyModule/pages/home/productDemands/StatusChart'
import Header from 'modules/companyModule/pages/home/Header'

const ProductDemandHome = () => {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/statistics/product_demands`)
                .then(response => {
                    if(response.status === 200) {
                        setData(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getData()
    }, [updateData])

    return (
        <div className="w-full">
            <Header title="Ürün Talebi" logo={<FaVoteYea />} />
            <div className="mb-2">
                <TopCards 
                    data={data}
                    update={updateData}
                    setUpdate={setUpdateData}  
                    loading={loading}                  
                />
            </div>
            <div>
                <StatusChart 
                    data={data}
                    update={updateData}
                    setUpdate={setUpdateData}  
                    loading={loading}                  
                />
            </div>            
        </div>
    )
}

export default ProductDemandHome