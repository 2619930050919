import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierProjectDelete } from 'stores/DrawersSlice' 
import DeleteForm from 'modules/supplierModule/pages/companies/projects/DeleteForm'

function Delete({project, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenSupplierProjectDelete)
    const setIsOpen = () => dispatch(setIsOpenSupplierProjectDelete())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Proje Bilgilerini Sil">
            <DeleteForm project={project} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Delete