import { DateTime } from 'luxon';
import TableActions from 'modules/companyModule/pages/suppliers/invitations/TableActions'
import TableLayout from 'elements/tables'

const Table = ({data, actions, progressPending, pagination, pageMeta}) => {

    const BooleanCell = ({value}) => {
        if(value) {
            return <span className="font-semibold text-green-600">Evet</span>
        }

        return <span className="font-semibold text-red-600">Hayır</span>
    }

    const DateCell = ({date}) => {
        let parsedDate = DateTime.fromISO(date)

        if(parsedDate.isValid) {
            return <span>{parsedDate.toFormat('dd.LL.yyyy T')}</span>
        }
        return <span className="font-semibold text-red-600">Hayır</span>
    }

    const columns = [
        {
            name: 'Şirket Adı',
            selector: (row) => {return row.supplier_name},
            headerMobileHidden: false,
        },
        {
            name: 'Vergi/Tc Kimlik No',
            selector: (row) => {return row.tax_number},
            headerMobileHidden: false,
        },
        {
            name: 'E-Posta',
            selector: (row) => {return row.supplier_email},
            headerMobileHidden: false,
        },
        {
            name: 'Görüldü',
            selector: (row) => {return <DateCell date={row.seen_at} /> },
            headerMobileHidden: false,
        }, 
        {
            name: 'Kayıt Olundu',
            selector: (row) => {return <DateCell date={row.registered_at} />},
            headerMobileHidden: false,
        }, 
        // {
        //     name: 'İşlemler',
        //     selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
        //     headerMobileHidden: true,
        // },
    ];

    const mobileHeader = (row) => {return row.supplier_name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
