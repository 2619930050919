import { useState, useEffect } from 'react'
import { useForm } from "react-hook-form"
import { useSelector } from 'react-redux'
import Table from 'modules/companyModule/pages/suppliers/uploads/Table'
import UploadForm from 'modules/companyModule/pages/suppliers/uploads/UploadForm'
import Explanation from 'modules/companyModule/pages/suppliers/uploads/Explanation'
import SendRequest from 'utils/SendRequest'
import { ButtonSave, ButtonReset } from 'elements/buttons/actionButtons'
import { usePartyValidation } from 'modules/companyModule/pages/suppliers/uploads/InputValidations'
import { UploadAlert, LoadingAlert, SuccessAlert, ErrorAlert } from 'modules/companyModule/pages/suppliers/uploads/Alerts'

function SupplierUploads(){
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const { register, control, handleSubmit, formState: { errors, isValid, isDirty, dirtyFields }, reset, trigger, setError, watch, getValues, clearErrors } = useForm({
      defaultValues: {
        suppliers: {}
      } 
    })

    const [data, setData] = useState(null)
    const [dataStatus, setDataStatus] = useState([])    
    const [loading, setLoading] = useState(false) 
    const [isErrorFound, setIsErrorFound] = useState(false) 
    const [isFinished, setIsFinishid] = useState(false) 
    const [loadCount, setLoadCount] = useState(0)

    const validation = usePartyValidation()
    
    useEffect(() => {
      register('suppliers')
    }, [register])

    useEffect(() => {
      if(data !== null && data.length > 0) {
        reset()
        clearErrors()
        setIsErrorFound(false)
        setIsFinishid(false)
        data.map((line, index) => {
          let formObject = {
            supplier_name: line.A,
            tax_number: String(line.B),
            supplier_email:line.C,            
          }
          Object.keys(formObject).map(key => {
            register(`suppliers.${index}.${key}`, {...validation[key], value: formObject[key]})
          })
        })
      }
    }, [data])

    const sendDataToServer = async (index) => {    
      return new Promise((resolve, reject) => {
        let newData = 'pending'
        let url = `api/companies/${companyId}/supplier_invitations`
        
        SendRequest('POST', url, getValues('suppliers.'+index))
            .then(res => {
              newData = 'success'
            })
            .catch(err => {
              newData = 'error'
            })
        
        if(index < data.length) {
          setTimeout(() => resolve(newData), 1000)
        } else {
          setTimeout(() => reject('error'), 1000)
        }
        
      })            
    }

    const saveData = async (index) => {
        if(index < dataStatus.length) {
          setDataStatus(prevState => {
            return [...prevState.slice(0,index),
            'processing',
            ...prevState.slice(index+1)]
          })
          await sendDataToServer(index).then(async (res) => {
            setDataStatus(prevState => {
              return [...prevState.slice(0,index),
              res,
              ...prevState.slice(index+1)]
            })
            setLoadCount(prevState => {
              return prevState + 1
            })
            saveData(index + 1)
          }).catch(err => {
            console.log(err)
            setLoading(false)
          })   
        } else {
          setLoading(false)
          setIsErrorFound(false)
          setIsFinishid(true)
        }     
    }

    const handleSend = async () => {
      if(dataStatus.length > 0) {
        setLoading(true)
        setLoadCount(1)
        await saveData(0)
      }
    }

    const handleError = (errors) => {
      setIsErrorFound(true)
      setIsFinishid(false)
    }

    const resetData = () => {
      setData(null)
      setDataStatus([])    
      setLoading(false) 
      setIsErrorFound(false) 
      setIsFinishid(false) 
      setLoadCount(0)
    }

    return (
      <div className="w-full mb-4">
          <h2 className="text-2xl text-gray-800 pb-2 mb-4 border-b border-gray-800 font-semibold">Toplu Tedarikçi Davet Etme</h2>
          {(!loading && !isFinished) && 
          <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-y-0 md:gap-x-4 mb-4'>
            <div className="col-span-1 p-6 bg-white border border-gray-400 rounded-lg">
              <UploadForm setData={setData} setDataStatus={setDataStatus} />
            </div>
            <div className="col-span-1 p-6 bg-white border border-gray-400 rounded-lg">
              <Explanation />
            </div>
          </div>    
          }     
          <div>
            {loading && 
              <LoadingAlert loadCount={loadCount} total={data?.length} />
            }
            {(!loading && !isFinished && !isErrorFound && data !== null && data.length > 0) && 
              <UploadAlert />
            }
            {(!loading && isErrorFound) && 
              <ErrorAlert />
            }    
            {(!loading && isFinished) && 
              <SuccessAlert />
            }    
          </div>     
          {(data !== null && data.length > 0) &&  
            <>
            <Table data={data} dataStatus={dataStatus} errors={errors} />
            {!loading && 
            <div className="flex justify-end items-center gap-x-3 mt-2">
              {isFinished && 
              <ButtonReset label="Yeniden Başla" onClick={resetData}/>
              }
              {!isFinished && 
              <ButtonSave label="Davetiye Gönder" onClick={handleSubmit(handleSend, handleError)} />
              } 
            </div>
            }
            {loading && 
            <div className="flex justify-end items-center gap-x-3 mt-2">
              <ButtonSave label="Aktarılıyor.." addClass='opacity-50' />
            </div>
            }
            </>
          }
             
      </div>
    )
  }
  
  export default SupplierUploads