import Popover from '@idui/react-popover'

function CustomPopover({children, content}) {
    const animation = {
        animate: {
          opacity: 1,
          scale: 1,
          display: 'block',
        },
        exit: {
          opacity: 0,
          display: 'none',
          scale: 0.9,
          transition: {
            duration: 0.1
          }
        },
        initial: {
          opacity: 0,
          display: 'none',
          scale: 0.9
        }
      }

    const className = '!bg-sky-100 !p-2 border border-sky-200'

    return (
        <Popover 
            content={content} 
            animation={animation}
            className={className} 
            placement='bottom' 
            arrowColor='#bae6fd'
            triggerContainerDisplay='inline'
        >
            {children}
        </Popover>
    )
}

export default CustomPopover