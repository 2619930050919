import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import { FaFolderPlus } from 'react-icons/fa'
import TopCards from 'modules/companyModule/pages/home/departmentProcurements/TopCards'
import StatusChart from 'modules/companyModule/pages/home/departmentProcurements/StatusChart'
import ProcurementRequestLogs from 'modules/companyModule/pages/home/departmentProcurements/ProcurementRequestLogs'
import LatestRequests from 'modules/companyModule/pages/home/departmentProcurements/LatestRequests'
import Header from 'modules/companyModule/pages/home/Header'

const DepartmentProcurementHome = () => {
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/statistics/department_procurement_requests`)
                .then(response => {
                    if(response.status === 200) {
                        setData(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getData()
    }, [updateData])

    return (
        <div className="w-full">
             <Header title="Departman Satın Alma Talebi" logo={<FaFolderPlus />} />
             <div className="mb-2">
                <TopCards 
                    data={data}
                    update={updateData}
                    setUpdate={setUpdateData}  
                    loading={loading}                  
                />
            </div>
            <div>
                <StatusChart 
                    data={data}
                    update={updateData}
                    setUpdate={setUpdateData}  
                    loading={loading}                  
                />
            </div>
        </div>
    )
}

export default DepartmentProcurementHome