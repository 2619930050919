import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import ProductInfo from 'modules/companyModule/pages/products/product/ProductInfo'
import ProposalSummary from 'modules/companyModule/pages/products/product/ProposalSummary'
import AcceptedProposals from 'modules/companyModule/pages/products/product/AcceptedProposals'
import Detail from 'modules/companyModule/pages/products/Detail'

function Product() {
    let params = useParams()
    const companyId = useSelector((state) => state.company.selectedCompany.id)
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getProduct = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/products/${params.id}`)
                .then(res => {
                    if(res.status === 200) {
                        setProduct(res.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        
        getProduct()            
    }, [])

    return (
        <>
            <div className="w-full mb-4">
                <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                    <h2 className="text-2xl text-gray-800 font-semibold">{product?.name} Ürün Bilgileri</h2>
                </div>
                <div className="w-full flex flex-col lg:flex-row gap-4">
                    <ProductInfo product={product} />
                    <ProposalSummary product={product} />
                </div>
                <div>
                    <AcceptedProposals product={product} />
                </div>
                <Detail product={product} />
                {loading && <Loader />}
            </div>
        </>
    )
}

export default Product