function StatusCell({is_active = false, is_approved = null, is_blacklisted = null}) {
    if(is_active === false) {
        return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Silinmiş</span>
    }
    if(is_blacklisted === true) {
        return <span className='py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap'>Kara Listede</span>
    }
    if(is_approved === false) {
        return <span className="py-0.5 px-2 rounded bg-orange-500 text-orange-50 whitespace-nowrap">Onay bekleniyor</span>
    }

    return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Onaylı</span>
    
}

export default StatusCell