import { FaChartBar } from 'react-icons/fa'
import { IconContext } from "react-icons"
import {default as FNWS} from 'utils/FormatNumberWithSeperator'
import Card from 'elements/cards'

function LineReport({procurement}) {
    const activeProposals = procurement.proposals.filter(proposal => {
        return proposal.is_revised === false && proposal.is_cancelled === false
    }).sort((a, b) => a.total_price - b.total_price)

    const bestPrice = (lineId) => {
        if(activeProposals.length > 0) {
            let proposalWithSelectedLine = activeProposals.reduce((acc, obj) => {
                const supplierName = obj.supplier.company_name
                const linePrice = obj.lines.find(line => {
                    return line.procurement_line.id === lineId
                }).price
                return [...acc, {supplierName: `(${supplierName})`, linePrice}]
            }, []).sort((a, b) => a.linePrice - b.linePrice)
            if(proposalWithSelectedLine) return proposalWithSelectedLine[0]
            return {supplierName: null, linePrice: 0}
        }
        return {supplierName: null, linePrice: 0}
    }

    const bestProposalPrice = (lineId) => {
        if(activeProposals.length > 0) {
            let foundLine = activeProposals[0].lines.find(line => {
                return line.procurement_line.id === lineId
            })
            if(foundLine) return foundLine.price
            return 0
        }
        return 0
    }

    const CardBody = () => {
        return (
            <table className="w-full">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-1">Ürün</th>
                        <th className="py-1 px-1">En İyi Teklif</th>
                        <th className="py-1 px-1">En İyi Teklifteki Tutar</th>
                        <th className="py-1 px-1">Fark</th>
                    </tr>
                </thead>
                <tbody>
                    {procurement.lines.map(line => {
                        return (
                            <tr key={line.id} className="border-b border-t border-gray-300 even:bg-gray-200 text-center">
                                <td className="px-2 py-3">{line.product.name} {line.description_text ? `(${line.description_text})` : null}</td>
                                <td className="px-2 py-3">{FNWS(bestPrice(line.id).linePrice)} {bestPrice(line.id).supplierName}</td>
                                <td className="px-2 py-3">{FNWS(bestProposalPrice(line.id))}</td>
                                <td className="px-2 py-3">{FNWS(bestProposalPrice(line.id) - bestPrice(line.id).linePrice)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    return (
        <Card title='Ürün Bazlı Karşılaştırma' icon={<FaChartBar />} >  
            <CardBody />
        </Card>
    )
}

export default LineReport