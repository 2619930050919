import logo from 'assets/img/favicon.png'

function Loader() {
    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-40 bg-sky-300 opacity-50 text-sky-800 font-semibold">
            <img src={logo} className="animate-loading" />
            <p className="text-2xl">Yükleniyor<span className="loading-dots"></span></p>
        </div>
    )
}

export default Loader