import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementDetail } from 'stores/DrawersSlice' 
import DetailForm from 'modules/companyModule/pages/procurementProcesses/procurements/DetailForm'

function Detail({procurement, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenProcurementDetail)
    const setIsOpenDetail = () => dispatch(setIsOpenProcurementDetail())
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Teklif Toplama Detayı" maxWidth="max-w-7xl">
            <DetailForm procurement={procurement} />
        </FormDrawer> 
    )
}

export default Detail