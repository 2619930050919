import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenDepartmentProcurementRequestDetail } from 'stores/DrawersSlice' 
import DetailForm from 'modules/companyModule/pages/departmentProcurementRequests/requests/DetailForm'

function Detail({procurementRequest}) {
    
    const dispatch = useDispatch()
    const isOpenDetail = useSelector(state => state.drawers.isOpenDepartmentProcurementRequestDetail)
    const setIsOpenDetail = () => dispatch(setIsOpenDepartmentProcurementRequestDetail())
    
    return(
        <FormDrawer isOpen={isOpenDetail} setIsOpen={setIsOpenDetail} title="Satın Alma Talep Detayı" maxWidth="max-w-4xl">
            <DetailForm procurementRequest={procurementRequest} />
        </FormDrawer> 
    )
}

export default Detail