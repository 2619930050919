import { IconContext } from "react-icons"
import { FaRegQuestionCircle, FaRegCheckCircle, FaExclamationTriangle } from 'react-icons/fa'
import { MdDownloading } from 'react-icons/md'

const Table = ({data, dataStatus, errors}) => {

    const StatusCell = ({status}) => {
        if(status === 'pending') {
            return (
                <IconContext.Provider value={{ className: "h-3.5 w-3.5 text-gray-700" }}>
                    <FaRegQuestionCircle />
                </IconContext.Provider>
            )
        }
        if(status === 'processing') {
            return (
                <IconContext.Provider value={{ className: "h-4 w-4 text-amber-700 inline-block" }}>
                    <MdDownloading />
                </IconContext.Provider>
            )
        }
        if(status === 'success') {
            return (
                <IconContext.Provider value={{ className: "h-3.5 w-3.5 text-emerald-700 inline-block" }}>
                    <FaRegCheckCircle />
                </IconContext.Provider>
            )
        }
        if(status === 'error') {
            return (
                <IconContext.Provider value={{ className: "h-3.5 w-3.5 text-red-700 inline-block" }}>
                    <FaExclamationTriangle />
                </IconContext.Provider>
            )
        }
    }

    return (
        <div className='w-full relative overflow-y-scroll'>
            <table className='w-full text-xs'>
                <thead className='text-gray-100'>
                    <tr className='bg-gray-700'>
                        <th className='px-2 py-2'>Tedarikçi Kişi/Firma İsmi</th>
                        <th className='px-2 py-2'>Tedarikçi Vergi/TC No</th>
                        <th className='px-2 py-2'>Tedarikçi E-posta</th>
                        <th className='px-2 py-2'>Durum</th>                    
                    </tr>
                </thead>
                <tbody>
                        {(data != null && data?.length > 0) ? data.map((line, index) => {
                            return (
                            <tr key={index} className='border-b border-gray-500 even:bg-gray-200 hover:bg-sky-300'>
                                <td className={`px-2 py-1 text-left ${errors?.suppliers?.[index]?.supplier.company_name && 'text-red-600'}`}>
                                    {(errors?.suppliers?.[index]?.supplier.company_name && (line.A === undefined || (line.A && line.A.trim() == '') )) 
                                    ? '[Boş olamaz]' 
                                    : line.A}
                                </td>
                                <td className={`px-2 py-1 text-left ${errors?.suppliers?.[index]?.supplier.business_area_id && 'text-red-600'}`}>
                                    {(errors?.suppliers?.[index]?.supplier.business_area_id && (line.B === undefined || (line.B && line.B.trim() == '') )) 
                                    ? '[Boş olamaz]' 
                                    : line.B}
                                </td>
                                <td className={`px-2 py-1 text-left ${errors?.suppliers?.[index]?.supplier.tax_department && 'text-red-600'}`}>
                                    {(errors?.suppliers?.[index]?.supplier.tax_department && (line.C === undefined || (line.C && line.C.trim() == '') )) 
                                    ? '[Boş olamaz]' 
                                    : line.C}
                                </td>                   
                                <td className='h-full px-2 py-1 text-center'>
                                    <StatusCell status={dataStatus[index]} />
                                </td>
                            </tr>)
                        })
                        : (<tr><td>Gösterilecek veri bulunamadı.</td></tr>)
                        }
                </tbody>          
            </table>
        </div>
    )
}

export default Table