import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { DateTime } from 'luxon'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import FormatNumberWithSeperator from 'utils/FormatNumberWithSeperator'

function DetailInfo({procurementRequest, downloadToken = null}) {
    const companyId = useSelector(state => state.company.selectedCompany?.id)
    const [loading, setLoading] = useState(false)

    const formatDate = (date) => {
        let parsedDate = DateTime.fromISO(date)
        if(parsedDate.isValid) return parsedDate.toFormat('dd.LL.yyyy T')
        return 'Belirlenmedi'
    }

    const formatStatus = (status) => {
        switch (status) {
            case 'rejected': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Reddedildi</span>
                break
            case 'delivery_rejected': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Depo Teslimi Reddedildi</span>
                break
            case 'waiting_approvals': 
                return <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">Onaylar Bekleniyor</span>
                break
            case 'waiting_procurement': 
                return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Satın Alınmayı Bekliyor</span>
                break
            case 'waiting_delivery': 
                return <span className="py-0.5 px-2 rounded bg-orange-500 text-orange-50 whitespace-nowrap">Teslim Alınmayı Bekliyor</span>
                break
            case 'procurement_in_progress': 
                return <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">Satın Alma Süreci Devam Ediyor</span>
                break
            case 'completed': 
                return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Süreç Tamamlandı</span>
                break
            case 'cancelled': 
                return <span className="py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap">İptal Edildi</span>
                break
            case 'archived': 
                return <span className="py-0.5 px-2 rounded bg-indigo-500 text-indigo-50 whitespace-nowrap">Arşivlendi</span>
                break
            default:
                return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">Bilinmiyor</span>
        }                   
    }

    const approvalStatus = (approval) => {
        if(approval.is_accepted) return <span className="text-emerald-500 font-semibold">Kabul Edildi</span>
        if(approval.is_rejected) return <span className="text-red-500 font-semibold">Reddedildi</span>
        return <span className="text-orange-500 font-semibold">Onay bekleniyor</span>
    }

    const downloadTechSpec = () => {
        const getProducts = async () => {
            let downloadUrl = ''
            if(downloadToken === null) {
                downloadUrl = `api/companies/${companyId}/warehouses/${procurementRequest.warehouse.id}/procurement_requests/${procurementRequest.id}/download_technical_specification`
            } else {
                downloadUrl = `api/warehouse_procurement_request_approval_with_token/${downloadToken}/download_technical_specification`
            }
            setLoading(true)
            await SendRequest('DOWNLOAD', downloadUrl)
                .then(response => {
                    if(response.status === 200) {
                        const temp = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = temp
                        link.setAttribute('download', `${procurementRequest.procurement_request_no}.pdf`)
                        document.body.appendChild(link)
                        link.click()
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProducts()
    }

    return (
        <div className="">
                {(procurementRequest !==null && procurementRequest !== undefined && Object.keys(procurementRequest).length > 0) &&
                <>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Talep No:</td>
                            <td className="px-2 py-3">{procurementRequest.procurement_request_no}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Departman:</td>
                            <td className="px-2 py-3">{procurementRequest.department.department_name}</td>
                        </tr>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Satın Alma Türü:</td>
                            <td className="px-2 py-3">{procurementRequest.procurement_type.scenario_name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Talebi Oluşturan: </td>
                            <td className="px-2 py-3">{procurementRequest.user.name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Oluşturulma Zamanı: </td>
                            <td className="px-2 py-3">{formatDate(procurementRequest.created_at)}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">İhtiyaç Zamanı: </td>
                            <td className="px-2 py-3">{formatDate(procurementRequest.requirement_date)}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Harcama Tutarı: </td>
                            <td className="px-2 py-3">{FormatNumberWithSeperator(procurementRequest.expenditure)} {procurementRequest.currency.code}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Gider Türü: </td>
                            <td className="px-2 py-3">{procurementRequest.expenditure_type.cost_name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Durum: </td>
                            <td className="px-2 py-3">{formatStatus(procurementRequest.status)}</td>
                        </tr>
                    </tbody>
                </table>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Talep Gerekçesi</p>
                <p className="whitespace-pre-line">{procurementRequest.description}</p>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Teknik Şartname</p>
                <p className="whitespace-pre-line">
                    <button type="button" className="text-md text-sky-500 hover:text-sky-800 underline" onClick={downloadTechSpec}>
                        İndirmek için tıklayın.
                    </button>                    
                </p>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-300 text-left">
                            <th className="px-1 py-0.5">Ürün</th>
                            <th className="px-1 py-0.5">Ürün Açıklaması</th>
                            <th className="px-1 py-0.5">Miktar</th>
                            <th className="px-1 py-0.5">Birim</th>
                        </tr>
                    </thead>
                    <tbody>
                        {procurementRequest.lines.map(line => {
                            return (
                                <tr key={line.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5">{line.product.name}</td>
                                    <td className="px-1 py-0.5">{line.description_text}</td>
                                    <td className="px-1 py-0.5">{FormatNumberWithSeperator(line.quantity, 6)}</td>
                                    <td className="px-1 py-0.5">{line.unit.description}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Onay Süreci</p>
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-300 text-left">
                            <th className="px-1 py-0.5">#</th>
                            <th className="px-1 py-0.5">Onaylayacak Yönetici</th>
                            <th className="px-1 py-0.5">Onay Sırası</th>
                            <th className="px-1 py-0.5">Durum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {procurementRequest.approvals
                            .sort((a, b) => a.approval_sequence - b.approval_sequence)
                            .map((approval, index) => {
                            return (
                                <tr key={approval.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5">{index + 1}</td>
                                    <td className="px-1 py-0.5">{approval.user.name}</td>
                                    <td className="px-1 py-0.5">{approval.approval_sequence}</td>
                                    <td className="px-1 py-0.5">{approvalStatus(approval)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </>}
                {loading && <Loader />}
            </div>  
    )
}

export default DetailInfo