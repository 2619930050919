import { useState, useEffect } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux' 
import { ButtonFilter, ButtonRefresh } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import Table from 'modules/companyModule/pages/procurementRequestApprovals/warehouseRequests/Table'
import TableFilter from 'modules/companyModule/pages/procurementRequestApprovals/warehouseRequests/TableFilter'
import Approve from 'modules/companyModule/pages/procurementRequestApprovals/warehouseRequests/Approve'
import Detail from 'modules/companyModule/pages/procurementRequestApprovals/warehouseRequests/Detail'
import Loader from 'elements/Loader'

function WarehouseProcurementRequestApprovals() {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [procurementRequests, setProcurementRequests] = useState([])
    const [procurementRequestForDetail, setProcurementRequestsForDetail] = useState(null)
    const [procurementRequestForAction, setProcurementRequestsForAction] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)

    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState({search: null, procurement_status: 'waiting_approvals', approval_status: 'notprocessed'})
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation()

    useEffect(() => {
        const getWarehouses = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/warehouses`)
                .then(response => {
                    if(response.status === 200) {
                        let warehouseArray = response.data.data.map(wh => {
                            return {key: wh.id, value: wh.warehouse_name}
                        })
                        setWarehouses(warehouseArray)
                        // console.log(response.data)
                        // setProcurementRequests(response.data.data)
                        // setPagination(response.data.meta.links)
                        // setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getWarehouses()
    }, [])

    useEffect(() => {
        const getProcurementRequesets = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/procurement_request_approvals/warehouses?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        // console.log(response.data)
                        setProcurementRequests(response.data.data)
                        setPagination(response.data.meta.links)
                        setPageMeta({total: response.data.meta.total, from: response.data.meta.from, to: response.data.meta.to, currentPage: response.data.meta.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProcurementRequesets()
    }, [updateData, filters, search])

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800 font-semibold">Depo Satın Alma Talepleri</h2>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonRefresh addClass='w-full md:w-auto' onClick={() => setUpdateData(prev => !prev)}>
                </ButtonRefresh>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            setSearchParams={setSearchParams}
                            warehouses={warehouses}
                />

                <Table data={procurementRequests} 
                        actions={{
                            setProcurementRequestsForDetail: setProcurementRequestsForDetail,
                            setProcurementRequestsForAction: setProcurementRequestsForAction,
                        }} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                    /> 

            </div>
            <Detail procurementRequest={procurementRequestForDetail} />
            <Approve update={updateData} setUpdate={setUpdateData} procurementRequest={procurementRequestForAction} />
            {loading && <Loader />}
        </div>
    )
}

export default WarehouseProcurementRequestApprovals