export function useTaxonInput(errors) {
    
    return [        
        {
            key: "taxonInput1",
            type: "text",
            name: "taxon_name",
            placeholder: "Sınıf Adı",
            label: "Sınıf Adı *",
            validator: {
                required: "Bu alan zorunludur.", 
                minLength: {value: 3, 
                    message: "En az 3 karakter olmalı"}, 
                maxLength: {value: 255, 
                            message:"En fazla 255 karakter olmalı"}
            },
            error: errors?.taxon_name?.message,
            cols: 'col-span-12',
        },
    ]
}

