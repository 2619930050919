import { GreenSmall, OrangeSmall, RedSmall, BlueSmall } from "elements/infoCards" 

function TopCards({data, update, setUpdate, loading}) {
   
    const placeholderData = {
        waiting_approvals: data?.waiting_approvals || 0,
        waiting_procurement: data?.waiting_procurement || 0,
        waiting_delivery: data?.waiting_delivery || 0,
        procurement_in_progress: data?.procurement_in_progress || 0,
    }

    return (
        <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-y-4 lg:gap-x-4">
            <GreenSmall 
                title="Onay Bekleyenler"
                content={`${placeholderData.waiting_approvals} Adet`}
            />
            <OrangeSmall 
                title="Satın Alınmayı Bekleyenler"
                content={`${placeholderData.waiting_procurement} Adet`}
            />
            <RedSmall 
                title="Satın Alım Sürecinde"
                content={`${placeholderData.procurement_in_progress} Adet`}
            />
            <BlueSmall 
                title="Teslim Edilmeyi Bekleyenler"
                content={`${placeholderData.waiting_delivery} Adet`}
            />
        </div>
    )
}

export default TopCards