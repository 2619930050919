import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierAddBlacklist } from 'stores/DrawersSlice' 
import AddBlacklistForm from 'modules/companyModule/pages/suppliers/AddBlacklistForm'

function AddBlacklist({supplier, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenAddBlacklist = useSelector(state => state.drawers.isOpenSupplierAddBlacklist)
    const setIsOpenAddBlacklist = () => dispatch(setIsOpenSupplierAddBlacklist())
    
    return(
        <FormDrawer isOpen={isOpenAddBlacklist} setIsOpen={setIsOpenAddBlacklist} title="Tedarikçiyi Kara Listeye Al">
            <AddBlacklistForm supplier={supplier} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default AddBlacklist