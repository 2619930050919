import { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import SendRequest from 'utils/SendRequest'
import {ButtonClose, ButtonNext, ButtonPrevious} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall, FileSmall, CheckboxSmall} from 'elements/inputs'
import MultiSelectWithSearch from 'elements/inputs/MultiSelectWithSearch'
import { setIsOpenProcurementCreate } from 'stores/DrawersSlice' 
import { useSupplierSelectionTypeInput, 
    useSupplierSelectionBusinessAreaInput, 
    useSupplierSelectionSupplierInput } from 'modules/companyModule/pages/procurementProcesses/procurements/InputArray'
import Loader from 'elements/Loader'

function SupplierSelection({currentStep, setCurrentStep, procurementData, setProcurementData}) {
    const dispatch = useDispatch()
    const isOpen = useSelector((state) => state.drawers.isOpenProcurementCreate) 
    const companyId = useSelector((state) => state.company.selectedCompany.id)    
    
    const formDefaults = {
        supplier_selection_type: procurementData?.supplier_selection_type || null,
        supplier_business_areas: procurementData?.supplier_business_areas || null,
        supplier_categories: procurementData?.supplier_categories || [],
        suppliers: procurementData?.suppliers || null,      
    }
    
    const [taxonomies, setTaxonomies] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [loading, setLoading] = useState(false)
    
    const { register, handleSubmit, formState: { errors }, control, setValue, watch } = useForm({
        defaultValues: formDefaults
    })

    const watchSupplierSelection = watch('supplier_selection_type')

    const supplierSelectionInput = useSupplierSelectionTypeInput(errors)
    const businessAreaInput = useSupplierSelectionBusinessAreaInput(errors)
    const supplierInput = useSupplierSelectionSupplierInput(errors, suppliers)

    useEffect(() => {
        const getTaxonomies = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/taxonomies?type=supplier`)
                .then(response => {
                    if(response.status === 200) {
                        setTaxonomies(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        } 
        if(watchSupplierSelection === 'suppliers_by_categories') getTaxonomies()
        
    }, [watchSupplierSelection])   

    const TaxonItemInput = ({item, taxonomyId, onChangeCheckbox}) => {
        return (
            <div className="flex items-start justify-start gap-x-2 mb-1">
                <input 
                    type="hidden"
                    name={`supplier_categories.${item.id}.taxonomyId`}
                    {...register(`supplier_categories.${item.id}.taxonomyId`, { value: taxonomyId })}
                />
                <CheckboxSmall 
                    type="checkbox"
                    name={`supplier_categories.${item.id}.selected`}
                    register={register}
                    label={item.taxon_name}
                    validator={{
                        onChange: (e) => onChangeCheckbox(item.id, e.target.checked)
                    }}
                />                                        
            </div>
        )
    }

    const TaxonList = ({taxa, taxonomyId}) => {
        const onChangeCheckbox = (id, isChecked, selectedTaxa) => {
            let processedTaxa = selectedTaxa ? selectedTaxa : taxa
            
            const selectedItem = processedTaxa.filter(t => t.id === id)
            setValue(`supplier_categories.${selectedItem[0].id}.selected`, isChecked)
            if(selectedItem && selectedItem?.[0] && selectedItem?.[0]?.all_sub_taxa) {
                selectedItem[0].all_sub_taxa.forEach(taxon => {
                    onChangeCheckbox(taxon.id, isChecked, selectedItem[0].all_sub_taxa)
                })
            }
        }        

        return taxa.sort((a,b) => a.taxon_name.localeCompare(b.taxon_name)).map(t => 
            { 
                return (
                <Fragment key={t.id}>
                    <TaxonItemInput item={t} taxonomyId={taxonomyId} onChangeCheckbox={onChangeCheckbox} />
                    {(t.all_sub_taxa && Array.isArray(t.all_sub_taxa) && t.all_sub_taxa.length > 0) ?
                    <div className={`ml-8`}>
                        <TaxonList taxa={t.all_sub_taxa} taxonomyId={taxonomyId} />
                    </div> 
                    : null}
                </Fragment>
                )
            })
        
    }

    useEffect(() => {
        const getSuppliers = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/suppliers?no_pagination=true`)
                .then(response => {
                    if(response.status === 200) {
                        setSuppliers(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }    
        if(watchSupplierSelection === 'suppliers_by_ids') getSuppliers()
        
    }, [watchSupplierSelection]) 

    const close = () => {
        dispatch(setIsOpenProcurementCreate())
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const handleForm = async (data) => {
        setProcurementData(prev => {
            return {...prev, ...data}
        })
        setCurrentStep(prev => prev+1)
    }    

    return (  
        <>      
        <form onSubmit={handleSubmit(handleForm, handleError)}>            
            <div className="flex flex-col items-start justify-between w-full h-[calc(100vh-8rem)]">
                <div className="w-full grow overflow-y-auto px-0.5">
                    <div className="grid grid-cols-12 gap-2 mt-2">
                        {supplierSelectionInput.map(input => {
                            return (
                                <div className={input.cols} key={input.key}>
                                    {input.type == 'select' ? 
                                        <SelectSmall register={register} {...input} /> :
                                        <InputSmall register={register} {...input} />
                                    }
                                </div>
                                )
                        })}
                    </div>
                        
                        
                    { ['all_suppliers_selected_business_areas', 'approved_suppliers_selected_business_areas']
                        .includes(watchSupplierSelection) ? 
                        <div className="grid grid-cols-12 gap-2 mt-2">
                        {businessAreaInput.map(input => {
                            return (
                                <div className={input.cols} key={input.key}>
                                    {input.type === 'multiselect' ? 
                                        <MultiSelectWithSearch 
                                            control={control}
                                            {...input}
                                        />
                                    : null
                                    }
                                </div>
                                )
                        })}
                        </div>
                    : null}
                        
                    {watchSupplierSelection === 'suppliers_by_categories' ? 
                    <>
                        <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Tedarikçi Kategorileri</p>
                        <div className="w-full flex-1 overflow-y-auto">
                            {(taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) ?
                                taxonomies.sort((a,b) => a.taxonomy_name.localeCompare(b.taxonomy_name)).map(taxonomy => {
                                    return (
                                        <div key={`taxonomy_${taxonomy.id}`} className='border border-gray-300 mb-2'>
                                            <div className="border-b border-gray-300 bg-gray-200 px-2 py-1 flex items-center justify-between">
                                                <p className='text-xs font-semibold'>{taxonomy.taxonomy_name}</p>
                                            </div>
                                            <div className='p-2 bg-white max-h-[13rem] overflow-y-auto'>
                                                <TaxonList taxa={taxonomy.taxa} taxonomyId={taxonomy.id} />
                                            </div>
                                        </div>
                                    )
                                })
                            : null
                            }
                        </div>
                    </>
                    : null}
                        
                    {watchSupplierSelection === 'suppliers_by_ids' ? 
                    <div className="grid grid-cols-12 gap-2 mt-2">
                        {supplierInput.map(input => {
                            return (
                                <div className={input.cols} key={input.key}>
                                    {input.type === 'multiselect' ? 
                                        <MultiSelectWithSearch 
                                            control={control}
                                            {...input}
                                        />
                                    : null
                                    }
                                </div>
                                )
                        })}
                    </div>
                    : null}
                </div>
                <div className="w-full flex justify-end mt-5 gap-x-2">
                    <ButtonClose onClick={() => close()}>
                    </ButtonClose>
                    <ButtonPrevious onClick={() => setCurrentStep(prev => prev-1)}>
                    </ButtonPrevious>
                    <ButtonNext type='submit'>
                    </ButtonNext>                  
                </div>         
            </div>
        </form> 
        {loading && <Loader />}
        </>
    )

}

export default SupplierSelection