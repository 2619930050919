import { useState } from 'react'
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from 'react-redux'
import { setTermsAndCondData, setStep } from 'stores/RegisterSlice'
import {ButtonBlue, ButtonWhite} from 'elements/buttons'
import {IoArrowForwardSharp, IoArrowBackSharp} from "react-icons/io5"
import {Checkbox} from 'elements/inputs'
import ServiceAgreement from 'modules/authModule/pages/register/termsAndConditions/ServiceAgreement'
import SupplierUserAgreement from 'modules/authModule/pages/register/termsAndConditions/SupplierUserAgreement'

const TermsAndConditionForm = () => {

    const moduleData = useSelector(state => state.register.termsAndCond)

    const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
        defaultValues: {
            service_terms: moduleData?.service_terms || false,
            supplier_terms: moduleData?.supplier_terms || false,
        }
    })

    const dispatch = useDispatch()

    const handleModuleForm = (data) => {
        dispatch(setTermsAndCondData(data))
        dispatch(setStep(5))    
    }

    const previous = () => {
        dispatch(setStep(2))
    }

    const tacInputs = [
        {
            key: "tacInput1",
            type: "checkbox",
            name: "service_terms",
            label: "* Marlaport Hizmet Sözleşmesini Okudum Kabul Ediyorum",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.service_terms?.message,
            cols: 'col-span-12',
        },
        {
            key: "tacInput2",
            type: "checkbox",
            name: "supplier_terms",
            label: "* Tedarikçi Kullanıcı Sözleşmesini Okudum Kabul Ediyorum",
            validator: {
                required: "Bu alan zorunludur.",
            },
            error: errors?.supplier_terms?.message,
            cols: 'col-span-12',
        },
        
    ]

    return (
        <div className="px-4 pb-4">
            <form onSubmit={handleSubmit(handleModuleForm)}>
                <div className="grid grid-cols-12 gap-px">
                    <div key={tacInputs[0].key} className={tacInputs[0].cols}>
                        <p className="text-sm text-gray-700 font-bold">Marlaport Hizmet Sözleşmesi</p>
                        <div className="max-h-[10rem] overflow-y-scroll border border-gray-300 bg-white">
                            <ServiceAgreement />
                        </div>                        
                        <Checkbox register={register} {...tacInputs[0]} addClass />
                    </div>
                    <div key={tacInputs[1].key} className={tacInputs[1].cols}>
                        <p className="text-sm text-gray-700 font-bold">Tedarikçi Kullanıcı Sözleşmesi</p>
                        <div className="max-h-[10rem] overflow-y-scroll border border-gray-300 bg-white">
                            <SupplierUserAgreement />
                        </div>                        
                        <Checkbox register={register} {...tacInputs[1]} />
                    </div>
                </div>
                <div className="w-full flex justify-between mt-5">
                    <ButtonWhite type="button" onClick={previous}> <IoArrowBackSharp/> Geri</ButtonWhite>
                    <ButtonBlue type="submit">İleri <IoArrowForwardSharp/> </ButtonBlue>
                </div>                
            </form> 
        </div>
    )
}

export default TermsAndConditionForm