import TableActions from 'modules/companyModule/pages/suppliers/TableActions'
import TableLayout from 'elements/tables'
import BusinessAreaCell from 'modules/companyModule/pages/suppliers/BusinessAreaCell'
import StatusCell from 'modules/companyModule/pages/suppliers/StatusCell'

const Table = ({data, actions, progressPending, pagination, pageMeta, categories = null}) => {

    const columns = [
        {
            name: 'Şirket Adı',
            selector: (row) => {return row.company_name},
            headerMobileHidden: false,
        },
        {
            name: 'Sektör',
            selector: (row) => {return <BusinessAreaCell businessAreas={row.business_areas} />},
            headerMobileHidden: false,
        },
        {
            name: 'Telefon',
            selector: (row) => {return row.phone},
            headerMobileHidden: false,
        },
        {
            name: 'E-Posta',
            selector: (row) => {return row.email},
            headerMobileHidden: false,
        },
        {
            name: 'İl',
            selector: (row) => {return `${row.city.name}`},
            headerMobileHidden: false,
        }, 
        {
            name: 'Durum',
            selector: (row) => {return <StatusCell is_active={row.is_active} is_approved={row.is_approved} is_blacklisted={row.is_blacklisted} />},
            headerMobileHidden: false,
        }, 
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return row.company_name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
