import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementEnddateUpdate } from 'stores/DrawersSlice' 
import UpdateEnddateForm from 'modules/companyModule/pages/procurementProcesses/procurements/UpdateEnddateForm'

function UpdateEnddate({procurement, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenEdit = useSelector(state => state.drawers.isOpenProcurementEnddateUpdate)
    const setIsOpenEdit = () => dispatch(setIsOpenProcurementEnddateUpdate())
    
    return(
        <FormDrawer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} title="Satın Alma Bitiş ve Teslim Tarihini Değiştir">
            <UpdateEnddateForm procurement={procurement} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default UpdateEnddate