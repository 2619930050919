import MobileMenuOpen from 'elements/navbar/MobileMenuOpen'
import UserMenu from 'elements/navbar/UserMenu'
import CompanyMenu from 'elements/navbar/CompanyMenu'
import Alerts from 'elements/navbar/Alerts'
import { useDispatch, useSelector } from 'react-redux'

function Navbar() {
    const userType = useSelector(state => state.user.userType)
    const userRoles = useSelector(state => state.user.user.roles)
    const isUserAuthForCompanySetting = userRoles.find(roles => roles.code === 'settings_companies') !== undefined ? true : false

    const isUserAuthForCompany = () => {
        if(userType === 'company' && userRoles.find(roles => roles.code === 'settings_companies') !== undefined) {
            return true
        }
        if(userType === 'supplier' && userRoles.find(roles => roles.code === 'supplier_settings_companies') !== undefined) {
            return true
        }
        return false
    }
    
    return (
        <nav className="bg-white shadow-sm z-10 md:z-10 h-[60px] sticky top-0">
            <div className="w-full mx-auto h-full">
                <div className="relative flex items-center justify-between h-full">
                    <MobileMenuOpen />
                    <div className="inset-y-0 right-0 items-center px-4 sm:static sm:inset-auto flex h-full">
                        {userType === 'company' ?
                        <Alerts />
                        : null }
                        {(isUserAuthForCompany()) ?
                        <CompanyMenu />
                        : null }
                        <UserMenu />
                    </div>
                </div>                
            </div>
        </nav>
    )
}

export default Navbar