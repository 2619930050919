import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpenCustomerCategoryCreate: false,
    isOpenCustomerCategoryEdit: false,
    isOpenSupplierCreate: false,
    isOpenSupplierEdit: false,
    isOpenSupplierDelete: false,
    isOpenSupplierDetail: false,
    isOpenSupplierApprove: false,
    isOpenSupplierAddBlacklist: false,
    isOpenSupplierRemoveBlacklist: false,
    isOpenSupplierInvitationCreate: false,
    isOpenSupplierInvitationDelete: false,
    isOpenProductCreate: false,
    isOpenProductEdit: false,
    isOpenProductDelete: false,
    isOpenProductCategoryCreate: false,
    isOpenProductCategoryEdit: false,
    isOpenProductDetail: false,
    isOpenDepartmentCreate: false,
    isOpenDepartmentEdit: false,
    isOpenDepartmentAddDemandAuth: false,
    isOpenDepartmentAddUsers: false,
    isOpenDepartmentProcurementScenario: false,
    isOpenPositionCreate: false,
    isOpenPositionEdit: false,
    isOpenCostTypeCreate: false,
    isOpenCostTypeEdit: false,
    isOpenWarehouseCreate: false,
    isOpenWarehouseEdit: false,
    isOpenWarehouseAddUser: false,
    isOpenWarehouseAddProductCategory: false,
    isOpenWarehouseProcurementRequestWorkflow: false,
    isOpenWarehouseDemandDetail: false,
    isOpenWarehouseDemandDeliver: false,
    isOpenWarehouseDemandReject: false,
    isOpenWarehouseProductInitialStock: false,
    isOpenWarehouseProductStockCorrectionList: false,
    isOpenWarehouseProductStockCorrectionAdd: false,
    isOpenWarehouseProductStockTransferList: false,
    isOpenWarehouseProductStockTransferAdd: false,
    isOpenWarehouseTransferApprovalDetail: false,
    isOpenWarehouseTransferApprovalApprove: false,
    isOpenWarehouseProcurementRequestCreate: false,
    isOpenWarehouseProcurementRequestCancel: false,
    isOpenWarehouseProcurementRequestStatusUpdate: false,
    isOpenWarehouseProcurementRequestDetail: false,
    isOpenWarehouseProcurementRequestApprovalDetail: false,
    isOpenWarehouseProcurementRequestApprovalApprove: false,
    isOpenWarehouseProcurementRequestDeliver: false,
    isOpenDepartmentProcurementRequestCreate: false,
    isOpenDepartmentProcurementRequestCancel: false,
    isOpenDepartmentProcurementRequestStatusUpdate: false,
    isOpenDepartmentProcurementRequestDetail: false,
    isOpenDepartmentProcurementRequestDeliver: false,
    isOpenDepartmentProcurementRequestApprovalDetail: false,
    isOpenDepartmentProcurementRequestApprovalApprove: false,
    isOpenProcurementCreate: false,
    isOpenProcurementDetail: false,
    isOpenProcurementStatusUpdate: false,
    isOpenProcurementEnddateUpdate: false,
    isOpenProcurementIssue: false,
    isOpenProcurementCancel: false,
    isOpenProcurementApproval: false,
    isOpenProcurementProposalDetail: false,
    isOpenProcurementProposalAccept: false,
    isOpenProcurementProposalCancel: false,
    isOpenProcurementApprovalScenario: false,
    isOpenProcurementUserCostType: false,
    isOpenProcurementRequestWarehouseDetail: false,
    isOpenProcurementRequestWarehouseUpdateProcurement: false,
    isOpenProcurementRequestDepartmentDetail: false,
    isOpenProcurementRequestDepartmentUpdateProcurement: false,
    isOpenProductDemandCreate: false,
    isOpenProductDemandCancel: false,
    isOpenProductDemandStatusUpdate: false,
    isOpenProductDemandDetail: false,
    isOpenProductDemandApproval: false,
    isOpenProductDemandDeliveryApproval: false,
    isOpenMakeProposal: false,
    isOpenSupplierProposalInfo: false,
    isOpenSupplierProposalCancel: false,
    isOpenSupplierCompanyEdit: false,
    isOpenUserProfileEdit: false,
    isOpenCompanyCompanyEdit: false,
    isOpenCompanyUserCreate: false,
    isOpenCompanyUserRoleEdit: false,
    isOpenCompanyUserStatusEdit: false,
    isOpenCompanyUserDepartmentEdit: false,
    isOpenSupplierUserCreate: false,
    isOpenSupplierUserRoleEdit: false,
    isOpenSupplierUserStatusEdit: false,
    isOpenSupplierContactCreate: false,
    isOpenSupplierContactEdit: false,
    isOpenSupplierContactDelete: false,
    isOpenSupplierBankAccountCreate: false,
    isOpenSupplierBankAccountEdit: false,
    isOpenSupplierBankAccountDelete: false,
    isOpenSupplierReferenceCreate: false,
    isOpenSupplierReferenceEdit: false,
    isOpenSupplierReferenceDelete: false,
    isOpenSupplierProjectCreate: false,
    isOpenSupplierProjectEdit: false,
    isOpenSupplierProjectDelete: false,
    isOpenSupplierInfoForm: false,
    isOpenOrderForm: false,
}

export const drawersSlice = createSlice({
    name: 'drawers',
    initialState,
    reducers: {
        setIsOpenCustomerCategoryCreate: (state, action) => {
            state.isOpenCustomerCategoryCreate = action.payload
        },
        setIsOpenCustomerCategoryEdit: (state, action) => {
            state.isOpenCustomerCategoryEdit = action.payload
        },
        setIsOpenSupplierCreate: (state, action) => {
            state.isOpenSupplierCreate = action.payload
        },
        setIsOpenSupplierEdit: (state, action) => {
            state.isOpenSupplierEdit = action.payload
        },
        setIsOpenSupplierDelete: (state, action) => {
            state.isOpenSupplierDelete = action.payload
        },
        setIsOpenSupplierDetail: (state, action) => {
            state.isOpenSupplierDetail = action.payload
        },
        setIsOpenSupplierApprove: (state, action) => {
            state.isOpenSupplierApprove = action.payload
        },
        setIsOpenSupplierAddBlacklist: (state, action) => {
            state.isOpenSupplierAddBlacklist = action.payload
        },
        setIsOpenSupplierRemoveBlacklist: (state, action) => {
            state.isOpenSupplierRemoveBlacklist = action.payload
        },
        setIsOpenSupplierInvitationCreate: (state, action) => {
            state.isOpenSupplierInvitationCreate = action.payload
        },
        setIsOpenSupplierInvitationDelete: (state, action) => {
            state.isOpenSupplierInvitationDelete = action.payload
        },
        setIsOpenProductCreate: (state, action) => {
            state.isOpenProductCreate = action.payload
        },
        setIsOpenProductEdit: (state, action) => {
            state.isOpenProductEdit = action.payload
        },
        setIsOpenProductDelete: (state, action) => {
            state.isOpenProductDelete = action.payload
        },
        setIsOpenProductCategoryCreate: (state, action) => {
            state.isOpenProductCategoryCreate = action.payload
        },
        setIsOpenProductCategoryEdit: (state, action) => {
            state.isOpenProductCategoryEdit = action.payload
        },
        setIsOpenProductDetail: (state, action) => {
            state.isOpenProductDetail = action.payload
        },
        setIsOpenDepartmentCreate: (state, action) => {
            state.isOpenDepartmentCreate = action.payload
        },
        setIsOpenDepartmentEdit: (state, action) => {
            state.isOpenDepartmentEdit = action.payload
        },
        setIsOpenDepartmentAddDemandAuth: (state, action) => {
            state.isOpenDepartmentAddDemandAuth = action.payload
        },
        setIsOpenDepartmentAddUsers: (state, action) => {
            state.isOpenDepartmentAddUsers = action.payload
        },
        setIsOpenDepartmentProcurementScenario: (state, action) => {
            state.isOpenDepartmentProcurementScenario = action.payload
        },
        setIsOpenPositionCreate: (state, action) => {
            state.isOpenPositionCreate = action.payload
        },
        setIsOpenPositionEdit: (state, action) => {
            state.isOpenPositionEdit = action.payload
        },
        setIsOpenCostTypeCreate: (state, action) => {
            state.isOpenCostTypeCreate = action.payload
        },
        setIsOpenCostTypeEdit: (state, action) => {
            state.isOpenCostTypeEdit = action.payload
        },
        setIsOpenWarehouseCreate: (state, action) => {
            state.isOpenWarehouseCreate = action.payload
        },
        setIsOpenWarehouseEdit: (state, action) => {
            state.isOpenWarehouseEdit = action.payload
        },
        setIsOpenWarehouseAddUser: (state, action) => {
            state.isOpenWarehouseAddUser = action.payload
        },
        setIsOpenWarehouseAddProductCategory: (state, action) => {
            state.isOpenWarehouseAddProductCategory = action.payload
        },
        setIsOpenWarehouseProcurementRequestWorkflow: (state, action) => {
            state.isOpenWarehouseProcurementRequestWorkflow = action.payload
        },
        setIsOpenWarehouseDemandDetail: (state, action) => {
            state.isOpenWarehouseDemandDetail = action.payload
        },
        setIsOpenWarehouseDemandDeliver: (state, action) => {
            state.isOpenWarehouseDemandDeliver = action.payload
        },
        setIsOpenWarehouseDemandReject: (state, action) => {
            state.isOpenWarehouseDemandReject = action.payload
        },
        setIsOpenWarehouseProductInitialStock: (state, action) => {
            state.isOpenWarehouseProductInitialStock = action.payload
        },
        setIsOpenWarehouseProductStockCorrectionList: (state, action) => {
            state.isOpenWarehouseProductStockCorrectionList = action.payload
        },
        setIsOpenWarehouseProductStockCorrectionAdd: (state, action) => {
            state.isOpenWarehouseProductStockCorrectionAdd = action.payload
        },
        setIsOpenWarehouseProductStockTransferList: (state, action) => {
            state.isOpenWarehouseProductStockTransferList = action.payload
        },
        setIsOpenWarehouseProductStockTransferAdd: (state, action) => {
            state.isOpenWarehouseProductStockTransferAdd = action.payload
        },
        setIsOpenWarehouseTransferApprovalDetail: (state, action) => {
            state.isOpenWarehouseTransferApprovalDetail = action.payload
        },
        setIsOpenWarehouseTransferApprovalApprove: (state, action) => {
            state.isOpenWarehouseTransferApprovalApprove = action.payload
        },
        setIsOpenWarehouseProcurementRequestCreate: (state, action) => {
            state.isOpenWarehouseProcurementRequestCreate = action.payload
        },
        setIsOpenWarehouseProcurementRequestCancel: (state, action) => {
            state.isOpenWarehouseProcurementRequestCancel = action.payload
        },
        setIsOpenWarehouseProcurementRequestStatusUpdate: (state, action) => {
            state.isOpenWarehouseProcurementRequestStatusUpdate = action.payload
        },
        setIsOpenWarehouseProcurementRequestDetail: (state, action) => {
            state.isOpenWarehouseProcurementRequestDetail = action.payload
        },
        setIsOpenWarehouseProcurementRequestApprovalDetail: (state, action) => {
            state.isOpenWarehouseProcurementRequestApprovalDetail = action.payload
        },
        setIsOpenWarehouseProcurementRequestApprovalApprove: (state, action) => {
            state.isOpenWarehouseProcurementRequestApprovalApprove = action.payload
        },
        setIsOpenWarehouseProcurementRequestDeliver: (state, action) => {
            state.isOpenWarehouseProcurementRequestDeliver = action.payload
        },
        setIsOpenDepartmentProcurementRequestCreate: (state, action) => {
            state.isOpenDepartmentProcurementRequestCreate = action.payload
        },
        setIsOpenDepartmentProcurementRequestCancel: (state, action) => {
            state.isOpenDepartmentProcurementRequestCancel = action.payload
        },
        setIsOpenDepartmentProcurementRequestStatusUpdate: (state, action) => {
            state.isOpenDepartmentProcurementRequestStatusUpdate = action.payload
        },
        setIsOpenDepartmentProcurementRequestDetail: (state, action) => {
            state.isOpenDepartmentProcurementRequestDetail = action.payload
        },
        setIsOpenDepartmentProcurementRequestDeliver: (state, action) => {
            state.isOpenDepartmentProcurementRequestDeliver = action.payload
        },
        setIsOpenDepartmentProcurementRequestApprovalDetail: (state, action) => {
            state.isOpenDepartmentProcurementRequestApprovalDetail = action.payload
        },
        setIsOpenDepartmentProcurementRequestApprovalApprove: (state, action) => {
            state.isOpenDepartmentProcurementRequestApprovalApprove = action.payload
        },
        setIsOpenProcurementCreate: (state, action) => {
            state.isOpenProcurementCreate = action.payload
        },
        setIsOpenProcurementDetail: (state, action) => {
            state.isOpenProcurementDetail = action.payload
        },
        setIsOpenProcurementStatusUpdate: (state, action) => {
            state.isOpenProcurementStatusUpdate = action.payload
        },
        setIsOpenProcurementEnddateUpdate: (state, action) => {
            state.isOpenProcurementEnddateUpdate = action.payload
        },
        setIsOpenProcurementIssue: (state, action) => {
            state.isOpenProcurementIssue = action.payload
        },
        setIsOpenProcurementCancel: (state, action) => {
            state.isOpenProcurementCancel = action.payload
        },
        setIsOpenProcurementApproval: (state, action) => {
            state.isOpenProcurementApproval = action.payload
        },
        setIsOpenProcurementProposalDetail: (state, action) => {
            state.isOpenProcurementProposalDetail = action.payload
        },
        setIsOpenProcurementProposalAccept: (state, action) => {
            state.isOpenProcurementProposalAccept = action.payload
        },
        setIsOpenProcurementProposalCancel: (state, action) => {
            state.isOpenProcurementProposalCancel = action.payload
        },
        setIsOpenProcurementApprovalScenario: (state, action) => {
            state.isOpenProcurementApprovalScenario = action.payload
        },
        setIsOpenProcurementUserCostType: (state, action) => {
            state.isOpenProcurementUserCostType = action.payload
        },
        setIsOpenProcurementRequestWarehouseDetail: (state, action) => {
            state.isOpenProcurementRequestWarehouseDetail = action.payload
        },
        setIsOpenProcurementRequestWarehouseUpdateProcurement: (state, action) => {
            state.isOpenProcurementRequestWarehouseUpdateProcurement = action.payload
        },
        setIsOpenProcurementRequestDepartmentDetail: (state, action) => {
            state.isOpenProcurementRequestDepartmentDetail = action.payload
        },
        setIsOpenProcurementRequestDepartmentUpdateProcurement: (state, action) => {
            state.isOpenProcurementRequestDepartmentUpdateProcurement = action.payload
        },
        setIsOpenProductDemandCreate: (state, action) => {
            state.isOpenProductDemandCreate = action.payload
        },
        setIsOpenProductDemandCancel: (state, action) => {
            state.isOpenProductDemandCancel = action.payload
        },
        setIsOpenProductDemandStatusUpdate: (state, action) => {
            state.isOpenProductDemandStatusUpdate = action.payload
        },
        setIsOpenProductDemandDetail: (state, action) => {
            state.isOpenProductDemandDetail = action.payload
        },
        setIsOpenProductDemandApproval: (state, action) => {
            state.isOpenProductDemandApproval = action.payload
        },
        setIsOpenProductDemandDeliveryApproval: (state, action) => {
            state.isOpenProductDemandDeliveryApproval = action.payload
        },
        setIsOpenMakeProposal: (state, action) => {
            state.isOpenMakeProposal = action.payload
        },
        setIsOpenSupplierProposalInfo: (state, action) => {
            state.isOpenSupplierProposalInfo = action.payload
        },
        setIsOpenSupplierProposalCancel: (state, action) => {
            state.isOpenSupplierProposalCancel = action.payload
        },
        setIsOpenSupplierCompanyEdit: (state, action) => {
            state.isOpenSupplierCompanyEdit = action.payload
        },
        setIsOpenUserProfileEdit: (state, action) => {
            state.isOpenUserProfileEdit = action.payload
        },
        setIsOpenCompanyCompanyEdit: (state, action) => {
            state.isOpenCompanyCompanyEdit = action.payload
        },
        setIsOpenCompanyUserCreate: (state, action) => {
            state.isOpenCompanyUserCreate = action.payload
        },
        setIsOpenCompanyUserRoleEdit: (state, action) => {
            state.isOpenCompanyUserRoleEdit = action.payload
        },
        setIsOpenCompanyUserStatusEdit: (state, action) => {
            state.isOpenCompanyUserStatusEdit = action.payload
        },
        setIsOpenCompanyUserDepartmentEdit: (state, action) => {
            state.isOpenCompanyUserDepartmentEdit = action.payload
        },
        setIsOpenSupplierUserCreate: (state, action) => {
            state.isOpenSupplierUserCreate = action.payload
        },
        setIsOpenSupplierUserRoleEdit: (state, action) => {
            state.isOpenSupplierUserRoleEdit = action.payload
        },
        setIsOpenSupplierUserStatusEdit: (state, action) => {
            state.isOpenSupplierUserStatusEdit = action.payload
        },
        setIsOpenSupplierContactCreate: (state, action) => {
            state.isOpenSupplierContactCreate = action.payload
        },
        setIsOpenSupplierContactEdit: (state, action) => {
            state.isOpenSupplierContactEdit = action.payload
        },
        setIsOpenSupplierContactDelete: (state, action) => {
            state.isOpenSupplierContactDelete = action.payload
        },
        setIsOpenSupplierBankAccountCreate: (state, action) => {
            state.isOpenSupplierBankAccountCreate = action.payload
        },
        setIsOpenSupplierBankAccountEdit: (state, action) => {
            state.isOpenSupplierBankAccountEdit = action.payload
        },
        setIsOpenSupplierBankAccountDelete: (state, action) => {
            state.isOpenSupplierBankAccountDelete = action.payload
        },
        setIsOpenSupplierReferenceCreate: (state, action) => {
            state.isOpenSupplierReferenceCreate = action.payload
        },
        setIsOpenSupplierReferenceEdit: (state, action) => {
            state.isOpenSupplierReferenceEdit = action.payload
        },
        setIsOpenSupplierReferenceDelete: (state, action) => {
            state.isOpenSupplierReferenceDelete = action.payload
        },
        setIsOpenSupplierProjectCreate: (state, action) => {
            state.isOpenSupplierProjectCreate = action.payload
        },
        setIsOpenSupplierProjectEdit: (state, action) => {
            state.isOpenSupplierProjectEdit = action.payload
        },
        setIsOpenSupplierProjectDelete: (state, action) => {
            state.isOpenSupplierProjectDelete = action.payload
        },
        setIsOpenSupplierInfoForm: (state, action) => {
            state.isOpenSupplierInfoForm = action.payload
        },
        setIsOpenOrderForm: (state, action) => {
            state.isOpenOrderForm = action.payload
        },
    }
})

export const {
                setIsOpenCustomerCategoryCreate,
                setIsOpenCustomerCategoryEdit,
                setIsOpenSupplierCreate, 
                setIsOpenSupplierEdit, 
                setIsOpenSupplierDelete,
                setIsOpenSupplierDetail,
                setIsOpenSupplierApprove,
                setIsOpenSupplierAddBlacklist,
                setIsOpenSupplierRemoveBlacklist,
                setIsOpenSupplierInvitationCreate, 
                setIsOpenSupplierInvitationDelete,
                setIsOpenProductCreate, 
                setIsOpenProductEdit, 
                setIsOpenProductDelete, 
                setIsOpenProductCategoryCreate,
                setIsOpenProductCategoryEdit,
                setIsOpenProductDetail,
                setIsOpenDepartmentCreate,
                setIsOpenDepartmentEdit,
                setIsOpenDepartmentAddDemandAuth,
                setIsOpenDepartmentAddUsers,
                setIsOpenDepartmentProcurementScenario,
                setIsOpenPositionCreate,
                setIsOpenPositionEdit,
                setIsOpenCostTypeCreate,
                setIsOpenCostTypeEdit,
                setIsOpenWarehouseCreate,
                setIsOpenWarehouseEdit,
                setIsOpenWarehouseAddUser,
                setIsOpenWarehouseAddProductCategory,
                setIsOpenWarehouseProcurementRequestWorkflow,
                setIsOpenWarehouseDemandDetail,
                setIsOpenWarehouseDemandDeliver,
                setIsOpenWarehouseDemandReject,
                setIsOpenWarehouseProductInitialStock,
                setIsOpenWarehouseProductStockCorrectionList,
                setIsOpenWarehouseProductStockCorrectionAdd,
                setIsOpenWarehouseProductStockTransferList,
                setIsOpenWarehouseProductStockTransferAdd,
                setIsOpenWarehouseTransferApprovalDetail,
                setIsOpenWarehouseTransferApprovalApprove,
                setIsOpenWarehouseProcurementRequestCreate,
                setIsOpenWarehouseProcurementRequestCancel,
                setIsOpenWarehouseProcurementRequestStatusUpdate,
                setIsOpenWarehouseProcurementRequestDetail,
                setIsOpenWarehouseProcurementRequestApprovalDetail,
                setIsOpenWarehouseProcurementRequestApprovalApprove,
                setIsOpenWarehouseProcurementRequestDeliver,
                setIsOpenDepartmentProcurementRequestCreate,
                setIsOpenDepartmentProcurementRequestCancel,
                setIsOpenDepartmentProcurementRequestStatusUpdate,
                setIsOpenDepartmentProcurementRequestDetail,
                setIsOpenDepartmentProcurementRequestDeliver,
                setIsOpenDepartmentProcurementRequestApprovalDetail,
                setIsOpenDepartmentProcurementRequestApprovalApprove,
                setIsOpenProcurementCreate,
                setIsOpenProcurementDetail,
                setIsOpenProcurementStatusUpdate,
                setIsOpenProcurementEnddateUpdate,
                setIsOpenProcurementIssue,
                setIsOpenProcurementCancel,
                setIsOpenProcurementApproval,
                setIsOpenProcurementProposalDetail,
                setIsOpenProcurementProposalAccept,
                setIsOpenProcurementProposalCancel,
                setIsOpenProcurementApprovalScenario,
                setIsOpenProcurementUserCostType,
                setIsOpenProcurementRequestWarehouseDetail,
                setIsOpenProcurementRequestWarehouseUpdateProcurement,
                setIsOpenProcurementRequestDepartmentDetail,
                setIsOpenProcurementRequestDepartmentUpdateProcurement,
                setIsOpenProductDemandCreate,
                setIsOpenProductDemandCancel,
                setIsOpenProductDemandStatusUpdate,
                setIsOpenProductDemandDetail,
                setIsOpenProductDemandApproval,
                setIsOpenProductDemandDeliveryApproval,
                setIsOpenMakeProposal,
                setIsOpenSupplierProposalInfo,
                setIsOpenSupplierProposalCancel,
                setIsOpenSupplierCompanyEdit,
                setIsOpenUserProfileEdit,
                setIsOpenCompanyCompanyEdit,
                setIsOpenCompanyUserCreate,
                setIsOpenCompanyUserRoleEdit,
                setIsOpenCompanyUserStatusEdit,
                setIsOpenCompanyUserDepartmentEdit,
                setIsOpenSupplierUserCreate,
                setIsOpenSupplierUserRoleEdit,
                setIsOpenSupplierUserStatusEdit,
                setIsOpenSupplierContactCreate,
                setIsOpenSupplierContactEdit,
                setIsOpenSupplierContactDelete,
                setIsOpenSupplierBankAccountCreate,
                setIsOpenSupplierBankAccountEdit,
                setIsOpenSupplierBankAccountDelete,
                setIsOpenSupplierReferenceCreate,
                setIsOpenSupplierReferenceEdit,
                setIsOpenSupplierReferenceDelete,
                setIsOpenSupplierProjectCreate,
                setIsOpenSupplierProjectEdit,
                setIsOpenSupplierProjectDelete,
                setIsOpenSupplierInfoForm,
                setIsOpenOrderForm,
            } = drawersSlice.actions

export default drawersSlice.reducer