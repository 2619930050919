import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ButtonEditSmall, ButtonNewSmall, ButtonNew } from 'elements/buttons/actionButtons'
import { setIsOpenCostTypeCreate, setIsOpenCostTypeEdit } from 'stores/DrawersSlice'
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import CreateCostType from 'modules/companyModule/pages/companies/settings/costTypes/CreateCostType'
import EditCostType from 'modules/companyModule/pages/companies/settings/costTypes/EditCostType'

const CostTypeSettings = () => {
    const dispatch = useDispatch()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [updateData, setUpdateData] = useState(false)
    const [costTypes, setCostTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const [costTypeForEdit, setCostTypeForEdit] = useState(null)

    useEffect(() => {
        const getCostTypes = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/cost_types`)
                .then(response => {
                    if(response.status === 200) {
                        setCostTypes(response.data.data.sort((a,b) => a.cost_name.localeCompare(b.cost_name)))
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getCostTypes()
    }, [updateData])

    const CostTypeCard = ({costType}) => {
        return (
        <div className='px-2 py-1 my-1 border border-sky-200 bg-sky-50 min-w-[12rem]'>
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-y-2">
                <div className="flex items-end justify-start gap-x-2">
                    <p className='lg:whitespace-nowrap font-semibold text-sm text-gray-700'>{costType.cost_name}</p>
                </div>                
                <div className='w-full flex flex-col lg:flex-row justify-end gap-y-1 lg:gap-x-1'>
                    <ButtonEditSmall onClick={() => {setCostTypeForEdit(costType); dispatch(setIsOpenCostTypeEdit(true))}} />
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-lg text-gray-800">Gider Türleri</h2>
                <ButtonNew label="Yeni Gider Türü" onClick={() => {dispatch(setIsOpenCostTypeCreate(true))}}>
                </ButtonNew>
            </div>
            <div className="w-full overflow-scroll pb-4">
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-y-2 md:gap-x-4">
                    <div className="col-span-1">
                        {costTypes.map(ct  => {
                            return (
                                <div className="mb-2" key={ct.id}>
                                    <CostTypeCard costType={ct} />
                                </div>
                            )
                        })}  
                    </div>
                    <div className="col-span-1">
                        
                    </div>
                </div>  
                           
            </div>
            <CreateCostType 
                update={updateData} 
                setUpdate={setUpdateData} 
            />
            <EditCostType 
                update={updateData} 
                setUpdate={setUpdateData} 
                costType={costTypeForEdit}
            />
            {loading && <Loader />}
        </div>
    )
}

export default CostTypeSettings