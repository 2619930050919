import TableActions from 'modules/companyModule/pages/reports/businessAreas/TableActions'
import TableLayout from 'elements/tables'
import {default as FNWS} from 'utils/FormatNumberWithSeperator'

const Table = ({data, progressPending, pagination, pageMeta}) => {

    const columns = [
        {
            name: 'Sektör',
            selector: (row) => {return row.business_area.name},
            headerMobileHidden: false,
        },
        {
            name: 'Tamamlanan Satın Alma',
            selector: (row) => {return row.total_procurements},
            headerMobileHidden: false,
        },
        {
            name: 'Tamamlanan Satın Alma Tutarı',
            selector: (row) => {return FNWS(row.total_proposal_price) + ' ' + row.currency.code},
            headerMobileHidden: false,
        },        
    ];

    const mobileHeader = (row) => {return row.business_area.name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
