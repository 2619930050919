import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierDelete } from 'stores/DrawersSlice' 
import DeleteForm from 'modules/companyModule/pages/suppliers/DeleteForm'

function Delete({supplier, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenDelete = useSelector(state => state.drawers.isOpenSupplierDelete)
    const setIsOpenDelete = () => dispatch(setIsOpenSupplierDelete())
    
    return(
        <FormDrawer isOpen={isOpenDelete} setIsOpen={setIsOpenDelete} title="Tedarikçiyi Sil">
            <DeleteForm supplier={supplier} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Delete