import { useSearchParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { DateTime } from 'luxon'
import { ButtonFilter, ButtonExcel } from 'elements/buttons/actionButtons'
import SendRequest from 'utils/SendRequest'
import Table from 'modules/companyModule/pages/reports/products/Table'
import TableFilter from 'modules/companyModule/pages/reports/products/TableFilter'
import ExportXslx from 'modules/companyModule/pages/reports/products/ExportXslx'
import Loader from 'elements/Loader'

function Suppliers(){
    const dispatch = useDispatch()
    const [suppliers, setSuppliers] = useState([])
    const [categories, setCategories] = useState([])
    const [pagination, setPagination] = useState(null)
    const [pageMeta, setPageMeta] = useState(null)
    const [loading, setLoading] = useState(false)

    const companyId = useSelector(state => state.company.selectedCompany.id)

    const initalFilter = {
        start_date: DateTime.now().minus({ months: 1 }).toISODate(),
        end_date: DateTime.now().toISODate(),
    }
        
    const [updateData, setUpdateData] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filters, setFilters] = useState(initalFilter)
    const [searchParams, setSearchParams] = useSearchParams()

    let { search } = useLocation();

    useEffect(() => {
        const getSuppliers = async () => {
            let page = searchParams.get("page")
            let filterParam = '&' + new URLSearchParams(filters).toString()
            
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/reports/products?page=${page}${filterParam}`)
                .then(response => {
                    if(response.status === 200) {
                        setSuppliers(response.data.data)
                        setPagination(response.data.links)
                        setPageMeta({total: response.data.total, from: response.data.from, to: response.data.to, currentPage: response.data.current_page})
                
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getSuppliers()
    }, [updateData, filters, search])

    useEffect(() => {
        const getCategories = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/product_categories`)
                .then(response => {
                    if(response.status === 200) {
                        setCategories(response.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }    
        getCategories()
    }, [])

    const handleExport = async () => {
        const getSupplierList = async () => {
            
            return new Promise(async (resolve) => {
                let filterParam = '&no_pagination=true&' + new URLSearchParams(filters).toString()
                           
                await SendRequest('GET', `api/companies/${companyId}/reports/products?${filterParam}`)
                    .then(response => {
                        if(response.status === 200) {
                           resolve(response.data) 
                        } else {
                            resolve(null)
                        }                       
                    })
                    .catch(err => {
                        resolve(null)
                    })
            })
        }
        setLoading(true)
        await getSupplierList()
                .then(result => {
                    if(result !== null) {
                        ExportXslx(result, categories, `Ürün Raporu ${dateCell(filters.start_date)} - ${dateCell(filters.end_date)}`)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })        
    }

    const dateCell = (date) => {
        let parsedDate = DateTime.fromISO(date)
        
        return parsedDate.isValid ? parsedDate.toFormat('dd.LL.yyyy') : null
    }

    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">
                    Ürün/Hizmet Raporu ({dateCell(filters.start_date)} - {dateCell(filters.end_date)})
                </h2>
            </div>
            <div className="w-full flex items-center gap-x-2 justify-between mb-2">
                <ButtonFilter addClass='w-full md:w-auto' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                </ButtonFilter>
                <ButtonExcel addClass='w-full md:w-auto' onClick={handleExport}>
                </ButtonExcel>
            </div>
            <div className={`flex flex-col md:flex-row  ${isFilterOpen ? 'gap-y-4 gap-x-0 md:gap-x-4 md:gap-y-0' : ''}`}>
                <TableFilter isFilterOpen={isFilterOpen} 
                            setIsFilterOpen={setIsFilterOpen} 
                            filters={filters} 
                            setFilters={setFilters} 
                            categories={categories}
                            setSearchParams={setSearchParams}
                />

                <Table data={suppliers} 
                        actions={{}} 
                        progressPending={loading}
                        pagination={pagination}
                        pageMeta={pageMeta}
                        categories={categories}
                    /> 

            </div>
            {loading && <Loader />}
        </div>
    )
  }
  
  export default Suppliers