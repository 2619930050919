import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { IconContext } from "react-icons"
import { HiChevronDown } from 'react-icons/hi'
import { AiOutlineRightCircle } from 'react-icons/ai'
import AnimateHeight from 'react-animate-height'

function LinkWithSubmenu({title, icon, items}) {
    const [openSubmenu, setOpenSubmenu] = useState(false)
    const [height, setHeight] = useState(0)

    const baseClasses = "flex items-center overflow-hidden z-0 pl-8 pr-3 py-px -ml-px h-8 text-slate-100 cursor-pointer hover:text-white focus:text-opacity-100 outline-none ease-in-out transition-all duration-150 truncate"
    const activeClasses = "bg-slate-500 bg-opacity-60"

    return (
        <li className={`relative text-sm rounded-lg transition duration-500 ease-in-out overflow-hidden z-0 mx-3 mb-0.5 ${openSubmenu ? 'bg-slate-700 bg-opacity-60' : ''}`}>
            <button onClick={() => {setOpenSubmenu(!openSubmenu); setHeight(height === 0 ? 'auto' : 0)}} className={`w-full flex items-center justify-between px-3 py-px h-12 text-slate-100 hover:text-white focus:text-opacity-100 outline-none ease-in-out transition-all duration-150 ease-in-out truncate ${openSubmenu === true ? 'border-b border-slate-500' : ''} `}>
                <div className="flex items-center">
                    <IconContext.Provider value={{ className: "h-4 w-4" }}>
                    <span className="mr-4">{icon}</span>
                    </IconContext.Provider>
                    <span className="mr-auto leading-none">{title}</span>
                </div>
                <span className={`transition ease-in duration-150 transform ${openSubmenu === true ? 'rotate-180' : 'rotate-0'}`}>
                <IconContext.Provider value={{ className: "h-5 w-5" }}>
                    <HiChevronDown/>
                </IconContext.Provider>
                </span>                                        
            </button>
            <AnimateHeight
                duration={500}
                height={height}
            >
                <div>
                    <ul className="overflow-hidden">
                        {Array.isArray(items) && items.map(item => {
                            return (
                                <li key={item.id} className="text-xs">
                                    <NavLink end to={item.path} className={({isActive}) => `${baseClasses} ${isActive ? activeClasses : ''}`}>
                                        <IconContext.Provider value={{ className: "h-3 w-3 mr-1" }}>
                                            <AiOutlineRightCircle/>
                                        </IconContext.Provider>
                                        {item.title}
                                    </NavLink>
                                </li>
                            )
                        })}                        
                    </ul>
                </div>
            </AnimateHeight>
        </li>
    )
}

export default LinkWithSubmenu