import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {ButtonClose} from 'elements/buttons/actionButtons'
import { setIsOpenProductDemandDetail } from 'stores/DrawersSlice' 
import DetailInfo from 'modules/companyModule/pages/productDemands/demands/DetailInfo'

const DetailForm = ({productDemand}) => {
    const dispatch = useDispatch()
    
    
    
    const close = () => {
        dispatch(setIsOpenProductDemandDetail())
    }

    return (
        <>       
            <DetailInfo productDemand={productDemand} />
            <div className="w-full flex justify-end mt-5">
                <ButtonClose type="button" onClick={close}>
                </ButtonClose>
            </div>               
        </>
    )
}

export default DetailForm