import { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FaClipboardCheck, FaClipboardList, FaClipboard } from 'react-icons/fa'
import { IconContext } from "react-icons"
import SendRequest from 'utils/SendRequest'
import Loader from 'elements/Loader'
import { ButtonRefresh, ButtonExcel } from 'elements/buttons/actionButtons'
import ProcurementSummaryInfo from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/ProcurementSummaryInfo'
import ProposalSummary from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/ProposalSummary'
import BestProposals from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/BestProposals'
import LineReport from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/LineReport'
import AllProposals from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/AllProposals'
import TopNTable from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/TopNTable'
import Detail from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/Detail'
import Accept from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/Accept'
import SupplierDetail from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/SupplierDetail'
import ExportXslx from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/ExportXslx'
import Actions from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/Actions'
import {default as ProcurementDetail} from 'modules/companyModule/pages/procurementProcesses/procurements/Detail'
import UpdateStatus from 'modules/companyModule/pages/procurementProcesses/procurements/UpdateStatus'
import UpdateEnddate from 'modules/companyModule/pages/procurementProcesses/procurements/UpdateEnddate'
import Issue from 'modules/companyModule/pages/procurementProcesses/procurements/Issue'
import Cancel from 'modules/companyModule/pages/procurementProcesses/procurements/Cancel'
import ProposalCancel from './ProposalCancel'
import { activeProposals } from './utils'

const Home = () => {
    let params = useParams()
    const companyId = useSelector(state => state.company.selectedCompany.id)
    const [procurement, setProcurement] = useState(null)
    const [proposalForDetail, setProposalForDetail] = useState(null)
    const [proposalForAction, setProposalForAction] = useState(null)
    const [supplierIdForDetail, setSupplierIdForDetail] = useState(null)
    const [acceptProposalData, setAcceptProposalData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [updateData, setUpdateData] = useState(false)
    const [activeTab, setActiveTab] = useState(2)
    const [numberOfSupplierForTable, setNumberOfSupplierForTable] = useState(5)
    const dispatch = useDispatch()
    useEffect(() => {
        const getProcurement = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/procurements/${params.id}`)
                .then(response => {
                    if(response.status === 200) {
                        const apCount = activeProposals(response.data.data).length
                        setProcurement(response.data.data)
                        if(apCount < 5) {
                            setNumberOfSupplierForTable(apCount)
                        }
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getProcurement()
    }, [updateData])

    const handleExport = async () => {
        ExportXslx(procurement, 'Teklifler')      
    }

    const tabClasses = {
        common: "inline-flex items-center justify-center gap-x-1 px-4 py-2",
        natural: "border-b-2 border-transparent hover:text-gray-800 hover:border-gray-400",
        active: "text-blue-600 border-b-2 border-blue-600"
    }
    
    return (
        <div className="w-full mb-4">
            <div className="pb-2 mb-4 border-b border-gray-800 flex justify-between items-center">
                <h2 className="text-2xl text-gray-800 font-semibold">Teklif Toplama Detayları</h2>
                <ButtonRefresh onClick={() => {setUpdateData(prev => !prev)}} >
                </ButtonRefresh>
            </div>
            {procurement ? 
                <Fragment>
                    <div className="w-full flex flex-col lg:flex-row gap-4 mb-4 lg:mb-4 mt-4 ">
                        <ProcurementSummaryInfo procurement={procurement} />
                        <ProposalSummary procurement={procurement}/>
                        <Actions data={procurement}/>
                    </div>
                    <div className="border-b border-gray-400 mb-2">
                        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-700">
                            {/* <li className="me-2">
                                <button className={`${tabClasses.common} ${activeTab === 1 ? tabClasses.active : tabClasses.natural}`} onClick={() => setActiveTab(1)}>
                                    <IconContext.Provider value={{ className: "h-4 w-4" }}>
                                        <FaClipboard />
                                    </IconContext.Provider>
                                    <span>
                                        Özet
                                    </span>
                                </button>
                            </li> */}
                            <li className="me-2">
                                <button className={`${tabClasses.common} ${activeTab === 2 ? tabClasses.active : tabClasses.natural}`} onClick={() => setActiveTab(2)}>
                                    <IconContext.Provider value={{ className: "h-4 w-4" }}>
                                        <FaClipboardCheck />
                                    </IconContext.Provider>
                                    <span>
                                        En İyi {numberOfSupplierForTable} Teklif
                                    </span>
                                </button>
                            </li>
                            <li className="me-2">
                                <button className={`${tabClasses.common} ${activeTab === 3 ? tabClasses.active : tabClasses.natural}`} onClick={() => setActiveTab(3)}>
                                    <IconContext.Provider value={{ className: "h-4 w-4" }}>
                                        <FaClipboardList />
                                    </IconContext.Provider>
                                    <span>
                                        Tüm Teklifler
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    {activeTab === 1 ? 
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-x-8">
                        <div className="col-span-1">
                            <BestProposals proposals={procurement.proposals} 
                                procurementStatus={procurement.status}
                                actions={{
                                    setProposalForDetail: setProposalForDetail,
                                    setProposalForAction: setProposalForAction,
                                }} />
                        </div>
                        <div className="col-span-1">
                            <LineReport procurement={procurement} />
                        </div>
                    </div>       
                    : null }
                    {activeTab === 2 ?              
                    <div className="mt-4">
                        <TopNTable 
                            procurement={procurement} 
                            datalength={numberOfSupplierForTable} 
                            setDatalength = {setNumberOfSupplierForTable}
                            setLoading={setLoading}
                            supplierIdForDetail={supplierIdForDetail}
                            setSupplierIdForDetail={setSupplierIdForDetail}
                            proposalData={acceptProposalData} 
                            setProposalData={setAcceptProposalData}
                        />
                    </div>
                    : null}
                    {activeTab === 3 ? 
                    <Fragment> 
                    {/* <div className="pb-2 my-4 border-b border-gray-800 flex justify-between items-center">
                        <h2 className="text-xl text-gray-800 font-semibold">Tüm Teklifler</h2>
                        <ButtonExcel onClick={handleExport}>
                        </ButtonExcel>
                    </div> */}
                    <div>
                        <AllProposals data={procurement.proposals} 
                            procurementStatus={procurement.status}
                            actions={{
                                setProposalForDetail: setProposalForDetail,
                                setProposalForAction: setProposalForAction,
                                setSupplierIdForDetail: setSupplierIdForDetail
                            }} />
                    </div>
                    </Fragment>
                    : null}
                </Fragment>
            : null }
            <Detail proposal={proposalForDetail} procurement={procurement} />
            <Accept proposalData={acceptProposalData} setProposalData={setAcceptProposalData} procurement={procurement} update={updateData} setUpdate={setUpdateData} />
            <ProposalCancel procurement={procurement} update={updateData} setUpdate={setUpdateData} />
            <UpdateStatus update={updateData} setUpdate={setUpdateData} procurement={procurement} />
            <UpdateEnddate update={updateData} setUpdate={setUpdateData} procurement={procurement} />
            <Issue update={updateData} setUpdate={setUpdateData} procurement={procurement} />
            <Cancel update={updateData} setUpdate={setUpdateData} procurement={procurement} />
            <ProcurementDetail procurement={procurement} />
            <SupplierDetail supplierId={supplierIdForDetail} setLoading={setLoading} />
            
            {loading && <Loader />}
        </div>
    )
}

export default Home