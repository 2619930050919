import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProcurementProposalAccept } from 'stores/DrawersSlice' 
import AcceptForm from 'modules/companyModule/pages/procurementProcesses/procurements/procurement/AcceptForm'

function Accept({procurement, proposalData, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenAccept = useSelector(state => state.drawers.isOpenProcurementProposalAccept)
    const setIsOpenAccept = () => dispatch(setIsOpenProcurementProposalAccept())
    
    return(
        <FormDrawer isOpen={isOpenAccept} setIsOpen={setIsOpenAccept} title="Teklifi Onayla" maxWidth="max-w-4xl">
            <AcceptForm procurement={procurement} proposalData={proposalData} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Accept