import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenPositionCreate } from 'stores/DrawersSlice' 
import CreatePositionForm from 'modules/companyModule/pages/companies/settings/positions/CreatePositionForm'

function CreatePosition({update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenPositionCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenPositionCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Pozisyon Ekle">
            <CreatePositionForm update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default CreatePosition