import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenProductDemandCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/productDemands/demands/CreateForm'

function Create({update, setUpdate, productDemand}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenProductDemandCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenProductDemandCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Ürün Talebi Ekle" maxWidth="max-w-6xl">
            <CreateForm update={update} setUpdate={setUpdate} productDemand={productDemand} />
        </FormDrawer> 
    )
}

export default Create