import { useContactTypes } from 'data'

function ContactUsers({users}) {
    const types = useContactTypes()
    if(users !== null && Array.isArray(users)) {
        return (         
            <table className="w-full text-xs">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="py-1 px-1 text-left">Yetkili Türü</th>
                        <th className="py-1 px-1">Ad Soyad</th>
                        <th className="py-1 px-1">Görevi</th>
                        <th className="py-1 px-1">Cep Tel.</th>
                        <th className="py-1 px-1">İş Tel.</th>
                        <th className="py-1 px-1">E-Posta</th>
                    </tr>
                </thead>
                <tbody>
                    {users.length > 0 ?
                        users.map(contact => {
                            return (
                                <tr key={contact.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-2 py-3">
                                        {types.find(type => type.key === contact.type)?.value}
                                    </td>
                                    <td className="px-2 py-3 text-center">{contact.name}</td>
                                    <td className="px-2 py-3 text-center">{contact.position}</td>
                                    <td className="px-2 py-3 text-center">{contact.mobile_phone}</td>
                                    <td className="px-2 py-3 text-center">{contact.office_phone}</td>
                                    <td className="px-2 py-3 text-center">{contact.email}</td>
                                </tr>
                            )
                        })
                        :
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3" colSpan={5}>Yetkili Kişi bulunamadı</td>
                        </tr>
                    }
                </tbody>
            </table>       
        )
    }
    return null
}

export default ContactUsers