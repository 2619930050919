import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenSupplierContactEdit } from 'stores/DrawersSlice' 
import EditForm from 'modules/supplierModule/pages/companies/contacts/EditForm'

function Edit({contact, update, setUpdate}) {
    
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.drawers.isOpenSupplierContactEdit)
    const setIsOpen = () => dispatch(setIsOpenSupplierContactEdit())
    
    return(
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Yetkili Kişi Bilgilerini Düzenle">
            <EditForm contact={contact} update={update} setUpdate={setUpdate} />
        </FormDrawer> 
    )
}

export default Edit