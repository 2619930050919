function CompanyTable({company}) {
    if(company !== null) {
        return (         
            <table className="w-full text-sm">
                <tbody>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Şirket Adı:</td>
                        <td className="px-2 py-3">{company.company_name}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Şirket Kodu:</td>
                        <td className="px-2 py-3">{company.company_code}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Sektör:</td>
                        <td className="px-2 py-3">{company.business_area.name}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Vergi/TC No:</td>
                        <td className="px-2 py-3">{company.tax_number}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Vergi Dairesi:</td>
                        <td className="px-2 py-3">{company.tax_department}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Adres:</td>
                        <td className="px-2 py-3">{company.address} {company.district.name}/{company.city.name} </td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">Telefon:</td>
                        <td className="px-2 py-3">{company.phone}</td>
                    </tr>
                    <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                        <td className="px-2 py-3 font-semibold">E-Posta:</td>
                        <td className="px-2 py-3">{company.email}</td>
                    </tr>
                </tbody>
            </table>       
        )
    }
    return null
    
}

export default CompanyTable