import React from 'react'
import { useDispatch } from 'react-redux' 
import { useNavigate } from 'react-router-dom'
import {ButtonEditSmall, ButtonDetailSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'

const TableActions = ({rowId, actions, data}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    return (
        <div className='w-full flex items-center justify-center gap-x-3'>
            <ButtonDetailSmall onClick={() => navigate('/company/products/'+data.id)}>
            </ButtonDetailSmall>
        </div>
    )
};

export default TableActions
