import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseProcurementRequestCreate } from 'stores/DrawersSlice' 
import CreateForm from 'modules/companyModule/pages/warehouses/procurementRequests/CreateForm'

function Create({update, setUpdate, products, expenditureTypes, procurementRequest, selectedWarehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenWarehouseProcurementRequestCreate)
    const setIsOpenCreate = () => dispatch(setIsOpenWarehouseProcurementRequestCreate())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Satın Alma Talebi Ekle" maxWidth="max-w-6xl">
            <CreateForm 
                update={update} 
                setUpdate={setUpdate} 
                products={products} 
                expenditureTypes={expenditureTypes} 
                procurementRequest={procurementRequest} 
                selectedWarehouse={selectedWarehouse} 
            />
        </FormDrawer> 
    )
}

export default Create