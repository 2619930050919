import TableActions from 'modules/companyModule/pages/companies/settings/users/TableActions'
import TableLayout from 'elements/tables'
import FindCategory from 'utils/FindCategory'
import { useSelector } from 'react-redux'

const Table = ({data, actions, progressPending, pagination, pageMeta}) => {

    const StatusCell = ({isActive}) => {
        if(isActive) return <span className="text-green-500 font-semibold">Aktif</span>
        return <span className="text-red-500 font-semibold">Pasif</span>
    }

    const DepartmentCell = ({departments}) => {
        if(departments.length > 0) {
            return departments.map(d => {
                return <span className="block whitespace-nowrap" key={d.id}> {d.department.department_name} ({d.position.position_name}) </span>
            })
             
        } else {
            return <span className="text-red-600 font-semibold whitespace-nowrap">Belirlenmedi</span>
        }
        
    }

    const columns = [
        {
            name: 'Ad Soyad',
            selector: (row) => {return row.name},
            headerMobileHidden: false,
        },
        {
            name: 'E-Posta',
            selector: (row) => {return row.email},
            headerMobileHidden: false,
        },
        {
            name: 'Telefon',
            selector: (row) => {return row.phone},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return <StatusCell isActive={row.is_active} />},
            headerMobileHidden: false,
        },
        {
            name: 'Departmanlar',
            selector: (row) => {return <DepartmentCell departments={row.departments} />},
            headerMobileHidden: false,
        },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return row.name}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
