import { useDispatch, useSelector } from 'react-redux'
import {ButtonClose} from 'elements/buttons/actionButtons'
import { setIsOpenSupplierProposalInfo } from 'stores/DrawersSlice' 
import ProposalInfoDetail from 'modules/supplierModule/pages/procurements/ProposalInfoDetail'

const ProposalInfoForm = ({procurement}) => {
    const dispatch = useDispatch()
    
    const close = () => {
        dispatch(setIsOpenSupplierProposalInfo())
    }

    

    return (
        <>       
            <ProposalInfoDetail procurement={procurement} />
            <div className="w-full flex justify-end mt-5">
                <ButtonClose type="button" onClick={close}>
                </ButtonClose>
            </div>               
        </>
    )
}

export default ProposalInfoForm