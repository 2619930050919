import { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {InputFloatingLabel} from 'elements/inputs'
import {ButtonBlue} from 'elements/buttons'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import {LinkGray, LinkBlue} from 'elements/links'
import Loader from 'elements/Loader'

const VerifyEmailForm = () => {

    const dispatch = useDispatch()
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {}
    })

    const handleUserForm = async (data) => {
        setLoading(true)
        await SendRequest('POST', `email/verification-notification`, data)
                .then(res => {
                    if(res.status === 200) {
                        setStatus(res.data.status)
                        setError(null)
                    }                    
                })
                .catch(err => {
                    setError(err.response.data) 
                    setStatus(null)                   
                })
                .finally(() => {
                    setLoading(false)
                })
    }

    return (
        <div className="px-4 pb-4">
            <div className='mt-4'>
                <p className='text-gray-700 text-sm'>
                    Sistemi kullanmaya başlamak için e-posta adresinizi doğrulamanız gerekmektedir.
                </p>
                <p className='text-gray-700 text-sm'>
                    Kayıt olurken kullanmış olduğunuz e-posta adresine gelen ileti ile e-posta adresinizi doğrulayabilirsiniz.
                </p>
                <p className='text-gray-700 text-sm'>
                    Eğer e-posta gelmediyse aşağıda yer alan butonu kullanarak tekrar doğrulama e-postasını gönderebilirsiniz. 
                </p>
            </div>
            <div className="text-red-500 mt-12">
                {error && <ServerError errorData={error} />}
            </div>
            {status &&  <ServerSuccess message={status} />}
            <form onSubmit={handleSubmit(handleUserForm)}>
                <div className="grid grid-cols-12 gap-3">
                
                </div>
                <div className="w-full flex justify-end mt-5">
                    <ButtonBlue type="submit">E-Posta Doğrulama Linkini Gönder</ButtonBlue>
                </div>                
            </form> 
            <div className="mt-16 text-center">
                <p className="text-black-700">Giriş yapmak için <LinkBlue route="/login" title="tıklayın." /> </p>   
            </div>
            {loading && <Loader />}
        </div>
    )

}

export default VerifyEmailForm