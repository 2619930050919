import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useLocation } from 'react-router-dom'
import { setStep, setUserError, setCompanyError, reset } from 'stores/RegisterSlice'
import { login } from 'stores/UserSlice'
import {IoArrowForwardSharp, IoArrowBackSharp} from "react-icons/io5"
import { FaRegUserCircle } from 'react-icons/fa'
import { TiBusinessCard } from 'react-icons/ti'
import { BsUiChecksGrid, BsCardChecklist } from 'react-icons/bs'
import {ButtonBlue, ButtonWhite} from 'elements/buttons'
import SendRequest from 'utils/SendRequest'
import  { CompanyType } from 'data'

const Confirmation = ({cities, districts, businessAreas}) => {
    const dispatch = useDispatch()

    const userData = useSelector(state => state.register.user)
    const companyData = useSelector(state => state.register.company)
    const buyerData = useSelector(state => state.register.buyer)
    const tacData = useSelector(state => state.register.termsAndCond)

    const [searchParams, setSearchParams] = useSearchParams()

    const previous = () => {
        dispatch(setStep(4))
    }
    
    const baString = companyData.business_areas.map(ba => {
        return businessAreas.find(item => item.key === parseInt(ba))?.value
    }).join(', ')

    const handleRegister = async () => {        
        const code = searchParams.get("code")
        if(userData != null && companyData != null && code != null) {
            const formData = {
                user: userData,
                company: companyData,
                buyer: {invitation_code: code},
                terms_and_conditions: tacData,
            }
            await SendRequest('POST', 'register/supplier', formData)
                .then(res => {
                    if(res.status === 204) {
                        SendRequest('GET', 'api/user')
                                    .then(res => {
                                        dispatch(reset())
                                        dispatch(login({
                                            user: res.data.data, 
                                            authenticated: true, 
                                            emailVerified: res.data.data.verified, 
                                            userType: res.data.data.type
                                        }))  
                                    })
                                    .catch(err => console.log(err))                        
                    }
                })
                .catch(err => {
                    dispatch(setStep(1))
                    dispatch(setUserError(err.response.data))
                })
        }
    }

    return (
        <div className="px-4 pb-4 overflow-y-scroll">
            <div>
                <div>
                    <h4 className="text-lg flex justify-start items-center gap-x-2 text-red-800 px-2 py-1 border-b border-red-600"><FaRegUserCircle size={'1em'}/>Kullanıcı Bilgileri</h4>
                    <table className="w-full mt-2 text-gray-700 text-xs">
                        <tbody>
                            <tr className="border-y border-gray-300">
                                <td className="py-1 pr-3">Ad-Soyad:</td>
                                <td className="py-1 pl-3">{userData.name}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                                <td className="py-1 pr-3">Telefon:</td>
                                <td className="py-1 pl-3">{userData.phone}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                                <td className="py-1 pr-3">E-Posta:</td>
                                <td className="py-1 pl-3">{userData.email}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                                <td className="py-1 pr-3">Şifre:</td>
                                <td className="py-1 pl-3">{'*'.repeat(userData.password.length)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h4 className="text-lg flex justify-start items-center gap-x-2 text-red-800 px-2 py-1 mt-2 border-b border-red-600"><TiBusinessCard size={'1em'}/>Firma Bilgileri</h4>
                    <table className="w-full mt-2 text-gray-700 text-xs">
                        <tbody>
                            <tr className="border-y border-gray-300">
                                <td className="py-1 pr-3">Sektör:</td>
                                <td className="py-1 pl-3">{baString}</td>
                            </tr>
                            <tr className="border-y border-gray-300">
                                <td className="py-1 pr-3">Firma Adı:</td>
                                <td className="py-1 pl-3">{companyData.company_name}</td>
                            </tr>
                            <tr className="border-y border-gray-300">
                                <td className="py-1 pr-3">Firma Türü:</td>
                                <td className="py-1 pl-3">{CompanyType().filter(ct => ct.key.toString() === companyData.company_type)[0].value}</td>
                            </tr>
                            {companyData.company_type === '1' ? 
                                <tr className="border-b border-gray-300">
                                    <td className="py-1 pr-3">TC Kimlik No:</td>
                                    <td className="py-1 pl-3">{companyData.identity_number}</td>
                                </tr>
                            : null}
                            <tr className="border-b border-gray-300">
                                <td className="py-1 pr-3">Vergi No:</td>
                                <td className="py-1 pl-3">{companyData.tax_number}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                                <td className="py-1 pr-3">Vergi Dairesi:</td>
                                <td className="py-1 pl-3">{companyData.tax_department}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                                <td className="py-1 pr-3">Adres:</td>
                                <td className="py-1 pl-3">{`${companyData.address} ${districts.find(item => item.key === parseInt(companyData.district_id))?.value}/${cities.find(item => item.key === parseInt(companyData.city_id))?.value} Tükiye`}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                                <td className="py-1 pr-3">Telefon:</td>
                                <td className="py-1 pl-3">{companyData.phone}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                                <td className="py-1 pr-3">E-Posta:</td>
                                <td className="py-1 pl-3">{companyData.email}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div>
                    <h4 className="text-lg flex justify-start items-center gap-x-2 text-red-800 px-2 py-1 mt-2 border-b border-red-600"><BsUiChecksGrid size={'1em'}/>Alıcı Firma Bilgileri</h4>
                    <table className="w-full mt-2 text-gray-700 text-sm">
                        <tbody>
                            <tr className="border-y border-gray-300">
                                <td className="py-1 pr-3">Tedarikçi Türü:</td>
                                <td className="py-1 pl-3">{buyerData.supplier_type === 'general_supplier' ? 'Genel Teklif Verme' : 'Firma Davetiyle Kayıt'}</td>
                            </tr>
                            {buyerData.supplier_type === 'company_supplier' && 
                            <tr className="border-y border-gray-300">
                                <td className="py-1 pr-3">Tedarikçi Olunacak Firma Kodu:</td>
                                <td className="py-1 pl-3">{buyerData.buyer_code}</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div> */}
                <div>
                    <h4 className="text-lg flex justify-start items-center gap-x-2 text-red-800 px-2 py-1 mt-2 border-b border-red-600"><BsCardChecklist size={'1em'}/>Kabul Edilen Sözleşmeler</h4>
                    <table className="w-full mt-2 text-gray-700 text-xs">
                        <tbody>
                            <tr className="border-y border-gray-300">
                                <td className="py-1 pr-3">Marlaport Hizmet Sözleşmesi:</td>
                                <td className="py-1 pl-3">{tacData.service_terms ? 'Kabul edildi' : 'Kabul Edilmedi'}</td>
                            </tr>
                            <tr className="border-y border-gray-300">
                                <td className="py-1 pr-3">Tedarikçi Kullanıcı Sözleşmesi:</td>
                                <td className="py-1 pl-3">{tacData.supplier_terms ? 'Kabul edildi' : 'Kabul Edilmedi'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="w-full flex justify-between mt-5">
                    <ButtonWhite type="button" onClick={previous}> <IoArrowBackSharp/> Geri</ButtonWhite>
                    <ButtonBlue type="button" onClick={handleRegister}>Tamamla <IoArrowForwardSharp/> </ButtonBlue>
                </div> 
                
            </div>
        </div>
        )
}

export default Confirmation