import { DateTime } from 'luxon'

function DetailInfo({productDemand}) {
    const formatDate = (date) => {
        let parsedDate = DateTime.fromISO(date)
        if(parsedDate.isValid) return parsedDate.toFormat('dd.LL.yyyy T')
        return 'Belirlenmedi'
    }

    const formatStatus = (status) => {
        switch (status) {
            case 'rejected': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Reddedildi</span>
                break
            case 'delivery_rejected': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Depo Teslimi Reddedildi</span>
                break
            case 'rejected_by_warehouse': 
                return <span className="py-0.5 px-2 rounded bg-red-500 text-red-50 whitespace-nowrap">Depo Tarafından Reddedildi</span>
                break
            case 'waiting_approvals': 
                return <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">Onaylar Bekleniyor</span>
                break
            case 'waiting_delivery_approval': 
                return <span className="py-0.5 px-2 rounded bg-yellow-500 text-yellow-50 whitespace-nowrap">Teslim Onayı Bekleniyor</span>
                break
            case 'waiting_delivery': 
                return <span className="py-0.5 px-2 rounded bg-teal-500 text-teal-50 whitespace-nowrap">Teslim Alınmayı Bekliyor</span>
                break
            case 'completed': 
                return <span className="py-0.5 px-2 rounded bg-emerald-500 text-emerald-50 whitespace-nowrap">Teslim Alındı</span>
                break
            case 'cancelled': 
                return <span className="py-0.5 px-2 rounded bg-rose-500 text-rose-50 whitespace-nowrap">İptal Edildi</span>
                break
            case 'archived': 
                return <span className="py-0.5 px-2 rounded bg-indigo-500 text-indigo-50 whitespace-nowrap">Arşivlendi</span>
                break
            default:
                return <span className="py-0.5 px-2 rounded bg-gray-500 text-gray-50 whitespace-nowrap">Bilinmiyor</span>
        }                   
    }

    const approvalStatus = (approval) => {
        if(approval.is_accepted) return <span className="text-emerald-500 font-semibold">Kabul Edildi</span>
        if(approval.is_rejected) return <span className="text-red-500 font-semibold">Reddedildi</span>
        return <span className="text-orange-500 font-semibold">Onay bekleniyor</span>
    }

    const approvalWarehouseStatus = (approval) => {
        if(approval.is_approved) return <span className="text-emerald-500 font-semibold">Kabul Edildi</span>
        if(approval.is_rejected) return <span className="text-red-500 font-semibold">Reddedildi</span>
        return <span className="text-orange-500 font-semibold">Onay bekleniyor</span>
    }

    const deliveryLineItem = (lineId) => {
        const deliveryLine = productDemand.delivery.lines.filter(l => l.product_demand_line_id == lineId)
        if(deliveryLine.length > 0) {
            return (
                <>
                <td className="px-1 py-0.5">
                    {deliveryLine[0].quantity} {deliveryLine[0].unit_code.description}                    
                </td>
                <td className="px-1 py-0.5">
                {deliveryLine[0].description_text}
                </td>
                </>
            )
        }
        return null
        
    }

    return (
        <div className="">
                {(productDemand !==null && Object.keys(productDemand).length > 0) &&
                <>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                <table className="w-full">
                    <tbody>
                        <tr className="border-b border-t border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Talep No:</td>
                            <td className="px-2 py-3">{productDemand.demand_no}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Departman: </td>
                            <td className="px-2 py-3">{productDemand.department.department_name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Talebi Oluşturan: </td>
                            <td className="px-2 py-3">{productDemand.user.name}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Oluşturulma Zamanı: </td>
                            <td className="px-2 py-3">{formatDate(productDemand.created_at)}</td>
                        </tr>
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Durum: </td>
                            <td className="px-2 py-3">{formatStatus(productDemand.status)}</td>
                        </tr>
                        {productDemand.status === 'rejected_by_warehouse' ?
                        <tr className="border-b border-gray-300 even:bg-gray-200">
                            <td className="px-2 py-3">Reddeden: </td>
                            <td className="px-2 py-3">
                                {productDemand.rejected_warehouse?.warehouse_name} ({productDemand.rejected_warehouse_user?.name})
                            </td>
                        </tr> :
                        null
                        }
                        {productDemand.delivery != null ? 
                        <>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Teslim Zamanı: </td>
                                <td className="px-2 py-3">
                                    {formatDate(productDemand.delivery?.delivery_date)}
                                </td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">İşlem Yapan Depo: </td>
                                <td className="px-2 py-3">
                                    {productDemand.delivery?.warehouse.warehouse_name}
                                </td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Teslim Eden Depo Kullanıcısı: </td>
                                <td className="px-2 py-3">
                                    {productDemand.delivery?.user.name}
                                </td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Teslim Açıklaması: </td>
                                <td className="px-2 py-3">
                                    {productDemand.delivery?.description}
                                </td>
                            </tr>
                            <tr className="border-b border-gray-300 even:bg-gray-200">
                                <td className="px-2 py-3">Teslim Durumu: </td>
                                <td className="px-2 py-3">
                                    {approvalWarehouseStatus(productDemand.delivery)}
                                </td>
                            </tr>
                        </>
                    
                        : null}
                    </tbody>
                </table>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-300 text-left">
                            <th className="px-1 py-0.5">Ürün</th>
                            <th className="px-1 py-0.5">Ürün Açıklaması</th>
                            <th className="px-1 py-0.5">Miktar</th>
                            <th className="px-1 py-0.5">Birim</th>
                            {productDemand.delivery != null ? 
                            <>
                            <th className="px-1 py-0.5">Teslim Edilen Miktar</th>
                            <th className="px-1 py-0.5">Teslim Notu</th>
                            </>
                            : null }
                        </tr>
                    </thead>
                    <tbody>
                        {productDemand.lines.map(line => {
                            return (
                                <tr key={line.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5">{line.product.name}</td>
                                    <td className="px-1 py-0.5">{line.description_text}</td>
                                    <td className="px-1 py-0.5">{line.quantity}</td>
                                    <td className="px-1 py-0.5">{line.unit_code.description}</td>
                                    {productDemand.delivery != null ? 
                                    deliveryLineItem(line.id)
                                    : null }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Açıklamalar</p>
                <p className="whitespace-pre-line">{productDemand.description}</p>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Onay Süreci</p>
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-300 text-left">
                            <th className="px-1 py-0.5">#</th>
                            <th className="px-1 py-0.5">Onaylayacak Yönetici</th>
                            <th className="px-1 py-0.5">Durum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productDemand.approvals.map((approval, index) => {
                            return (
                                <tr key={approval.id} className="border-b border-t border-gray-300 even:bg-gray-200">
                                    <td className="px-1 py-0.5">{index + 1}</td>
                                    <td className="px-1 py-0.5">{approval.user.name}</td>
                                    <td className="px-1 py-0.5">{approvalStatus(approval)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </>}
            </div>  
    )
}

export default DetailInfo