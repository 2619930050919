import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenWarehouseAddUser } from 'stores/DrawersSlice' 
import EditWarehouseUsersForm from 'modules/companyModule/pages/companies/settings/warehouses/EditWarehouseUsersForm'

function EditWarehouseUsers({update, setUpdate, warehouse}) {
    
    const dispatch = useDispatch()
    const isOpenCreate = useSelector(state => state.drawers.isOpenWarehouseAddUser)
    const setIsOpenCreate = () => dispatch(setIsOpenWarehouseAddUser())
    
    return(
        <FormDrawer isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} title="Depo Yetkililerini Düzenle">
            <EditWarehouseUsersForm 
                update={update} 
                setUpdate={setUpdate} 
                warehouse={warehouse}
            />
        </FormDrawer> 
    )
}

export default EditWarehouseUsers