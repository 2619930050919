import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { DateTime } from 'luxon'
import {ButtonNew, ButtonOk, ButtonSave, ButtonNewSmall, ButtonDeleteSmall} from 'elements/buttons/actionButtons'
import {InputSmall, SelectSmall, TextareaSmall, FileSmall} from 'elements/inputs'
import SelectWithSearch from 'elements/inputs/SelectWithSearch'
import {ServerError, ServerSuccess} from 'elements/serverMessages'
import SendRequest from 'utils/SendRequest'
import { setIsOpenDepartmentProcurementRequestCreate } from 'stores/DrawersSlice' 
import Loader from 'elements/Loader'
import { useProcurementInput, useDescriptionInput, useLineInput, useTechnicalSpecInputs } from 'modules/companyModule/pages/departmentProcurementRequests/requests/InputArray'

const CreateForm = ({update, setUpdate, procurementRequest}) => {
    const dispatch = useDispatch()
    const companyId = useSelector((state) => state.company.selectedCompany.id)    
    const isOpenForm = useSelector(state => state.drawers.isOpenDepartmentProcurementRequestCreate)
    const linesDefaults = {
        product_id: null,
        description_text: null,
        quantity: null,
        unit_code_id: null,
    }
    const formDefaults = {
        department_id: null,
        procurement_type_id: null,
        requirement_date: DateTime.local().plus({ days: 10 }).toFormat("yyyy-LL-dd"),
        expenditure: null,
        currency_id: '1',
        expenditure_type_id: null,
        description: null,
        technical_specification_file: null,
        lines: [linesDefaults],
    }
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [technicalSpecificationFileAttributes, setTechnicalSpecificationFileAttributes] = useState({filename: null, size: null, type: null})
    const [approvalData, setApprovalData] = useState(null)
    const [products, setProducts] = useState([])  
    const [expenditureTypes, setExpenditureTypes] = useState([])  
    const [scenarios, setScenarios] = useState([])  
    const [unitCodes, setUnitCodes] = useState([])
    const { register, handleSubmit, formState: { errors }, reset, watch, control, setValue, getValues } = useForm({
        defaultValues: formDefaults
    })

    const { 
        fields: lineFields, 
        append: lineAppend, 
        remove: lineRemove, 
        } = useFieldArray({
        control,
        name: "lines"
    })

    const watchLines = watch("lines");
    const controlledItemFields = lineFields.map((field, index) => {
        return {
            ...field,
            ...watchLines[index]
        }
    })

    const watchDepartment = watch('department_id')
    const watchScenario = watch('procurement_type_id')

    const procurementInputs = useProcurementInput(errors, expenditureTypes, scenarios)
    const descirptionInputs = useDescriptionInput(errors)
    const lineInputs = useLineInput(errors, products)
    const techSpecInputs = useTechnicalSpecInputs(errors, technicalSpecificationFileAttributes, setTechnicalSpecificationFileAttributes)

    useEffect(() => {
        if(watchDepartment !== null && watchDepartment !== '') {
            const getProducts = async () => {
                setLoading(true)
                let categories = []
                await SendRequest('GET', `api/companies/${companyId}/departments/${watchDepartment}/products?no_pagination=true`)
                    .then(response => {                    
                        if(response.status === 200) {
                            setProducts(response.data.data)
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
            const getScenarios = async () => {
                setLoading(true)
                await SendRequest('GET', `api/companies/${companyId}/departments/${watchDepartment}/department_procurement_request_approval_scenarios`)
                    .then(response => {
                        if(response.status === 200) {
                            setScenarios(response.data.data)
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
            getProducts()
            getScenarios()
        }
       
    }, [watchDepartment])

    useEffect(() => {
        const getExpenditureTypes = async () => {
            setLoading(true)
            await SendRequest('GET', `api/companies/${companyId}/cost_types`)
                .then(response => {
                    if(response.status === 200) {
                        setExpenditureTypes(response.data.data)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        getExpenditureTypes()
    }, [])

    useEffect(() => {
        if(watchScenario) {
            let selectedScenario = scenarios.filter(s => s.id.toString() === watchScenario.toString())
            if(selectedScenario.length > 0) {
                let approvalUsers = selectedScenario[0].approval_users.map(user => {
                    return {
                        id: user.id,
                        approval_sequence: user.approval_sequence,
                        user_name: user.user.name,
                        departments: user.user?.departments.map(dep => {
                            return <p key={dep.id}>{dep.department.department_name} - {dep.position.position_name}</p>
                        }),
                    }
                }).sort((a, b) => a.approval_sequence - b.approval_sequence)
                setApprovalData(approvalUsers)
            } else {
                setApprovalData(null)
            }            
        } else {
            setApprovalData(null)
        }
        
    }, [watchScenario])

    useEffect(() => {
        let unitCodeArray = []
        if(products && products.length > 0) {
            lineFields.forEach((line, index) => {
                let product = products.find(p => {
                    return p.id == line.product_id
                })
                if(product) {
                    unitCodeArray.push([{
                        key: product.unit.id,
                        value: `${product.unit.description} (${product.unit.code})`
                    }])
                } else {
                    unitCodeArray.push([])
                }         
            })
            setUnitCodes(unitCodeArray)
        }
        
    }, [lineFields, products]) 

    useEffect(() => {
        unitCodes.forEach((uc, index) => {
            if(uc && Array.isArray(uc) && uc.length > 0) {
                setValue(`lines.${index}.unit_code_id`, uc[0].key, { shouldDirty: true })
            }            
        })
    }, [unitCodes])

    useEffect(() => {
        if(!isOpenForm) {
            reset()
            setError(null)
            setStatus(false)
            setTechnicalSpecificationFileAttributes({filename: null, size: null, type: null})
        }        
        if(isOpenForm) {
            if(procurementRequest !==null && Object.keys(procurementRequest).length > 0) {
                let defaults = {
                    department_id: procurementRequest.department.id,
                    procurement_type_id: procurementRequest.procurement_type.id,
                    requirement_date: DateTime.local().plus({ days: 10 }).toFormat("yyyy-LL-dd"),
                    expenditure: procurementRequest.expenditure,
                    currency_id: procurementRequest.currency.id.toString(),
                    expenditure_type_id: procurementRequest.expenditure_type.id.toString(),
                    description: procurementRequest.description,
                    technical_specification_file: null,
                }
                let lineItems = procurementRequest.lines.map(line => {
                    return {
                        product_id: line.product.id,
                        description_text: line.description_text,
                        quantity: line.quantity,
                        unit_code_id: line.unit.id.toString(),
                    }
                })
                reset({...defaults, lines : lineItems})
                
            } else {
                reset(formDefaults)
            }
        }
    }, [isOpenForm, procurementRequest])

    const handleForm = async (data) => {
        const formData = new FormData()
        Object.keys(data).forEach((key) => {
            if(data[key] instanceof FileList) {
                formData.append(key, data[key][0])
            } else if (Array.isArray(data[key])) {
                data[key].forEach((item, index) => {
                    Object.keys(item).forEach((itemkey) => {
                        formData.append(`${key}[${index}][${itemkey}]`, item[itemkey])
                    })
                    
                })
            } else {
              formData.append(key, data[key])
            }
        })
        setLoading(true)
        await SendRequest('POST', `api/companies/${companyId}/department_procurement_requests`, formData)
                .then(res => {
                    if(res.status === 204) {
                        reset()
                        setStatus(true)
                        setError(null)
                        setTechnicalSpecificationFileAttributes({filename: null, size: null, type: null})
                        setApprovalData(null)
                        setProducts([])
                        setScenarios([])
                        setUnitCodes([])
                        setUpdate(!update)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setError(err.response.data)
                    setLoading(false)
                })
    }    

    const redo = () => {
        reset()
        setError(null)
        setStatus(false)
        setTechnicalSpecificationFileAttributes({filename: null, size: null, type: null})
        setApprovalData(null)
        setProducts([])
        setScenarios([])
        setUnitCodes([])
    }

    const close = () => {
       dispatch(setIsOpenDepartmentProcurementRequestCreate())
    }

    const handleError = (error, e) => {
        console.log(error)
    }

    const handleLineProductChange = async (e, index) => {
        let product = products.find(p => {
            return p.id == e.value
        })
        
        setUnitCodes(prev => {
            let newCodes = [...prev]
            newCodes[index] = [{
                key: product.unit.id,
                value: `${product.unit.description} (${product.unit.code})`
            }]
            return newCodes
        })
    }

    const productArray = products.map(item => {
        return {value: item.id, label: item.name}
    })

    return (
        <>
        <div className="text-red-500 mt-4">
            {error && <ServerError errorData={error} />}
        </div>
        {status ? 
            <>
            <ServerSuccess message='Bilgiler başarıyla kaydedildi.' />
            <div className="w-full flex justify-end mt-5 gap-x-2">
                <ButtonNew onClick={() => redo()}>
                </ButtonNew>
                <ButtonOk onClick={() => close()}>
                </ButtonOk>
            </div>  
            </>
            :
            <form onSubmit={handleSubmit(handleForm, handleError)}>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Genel Bilgiler</p>
                <div className="grid grid-cols-12 gap-2">
                {procurementInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            {input.type == 'select' ? 
                                <SelectSmall register={register} {...input} /> :
                                <InputSmall register={register} {...input} />
                            }
                        </div>
                        )
                })}
                </div>  
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Ürünler</p>   
                <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                    <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Ürün</span>
                    </div>
                    <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Ürün Açıklaması</span>
                    </div>
                    <div className='col-span-2 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Miktar</span>
                    </div>
                    <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Birim</span>
                    </div>
                </div>
                {controlledItemFields.map((field, index) => {
                    return (
                        <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`lineInput${index}`}>
                            <div className={lineInputs[0].cols}>
                                <SelectWithSearch 
                                    dataArray={productArray}
                                    control={control}
                                    name={`lines.${index}.${lineInputs[0].nameLast}`}
                                    rules={{...lineInputs[0].validator}}
                                    error={errors?.lines?.[index]?.[lineInputs[0].nameLast]?.message}
                                    actions={(e) => handleLineProductChange(e, index)}
                                />
                                
                            </div>
                            <div className={lineInputs[1].cols}>
                                <InputSmall 
                                    {...lineInputs[1]}
                                    register={register} 
                                    name={`lines.${index}.${lineInputs[1].nameLast}`} 
                                    error={errors?.lines?.[index]?.[lineInputs[1].nameLast]?.message} 
                                />
                            </div>
                            <div className={lineInputs[2].cols}>
                                <InputSmall 
                                    {...lineInputs[2]}
                                    register={register} 
                                    name={`lines.${index}.${lineInputs[2].nameLast}`} 
                                    error={errors?.lines?.[index]?.[lineInputs[2].nameLast]?.message} 
                                 />
                            </div>
                            <div className={lineInputs[3].cols}>
                                <SelectSmall 
                                    {...lineInputs[3]}
                                    data={unitCodes?.[index]}
                                    register={register} 
                                    name={`lines.${index}.${lineInputs[3].nameLast}`} 
                                    error={errors?.lines?.[index]?.[lineInputs[3].nameLast]?.message} 
                                />
                            </div>                            
                            <div className='col-span-1 mt-0.5'>
                                <div>
                                    {
                                    index === 0 
                                    ? <ButtonNewSmall label="Ekle" addClass='w-full' type="button" onClick={() => lineAppend(linesDefaults)} ></ButtonNewSmall>
                                    : <ButtonDeleteSmall addClass='w-full' type="button" onClick={() => lineRemove(index)} ></ButtonDeleteSmall>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    )
                })} 
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Açıklamalar</p>
                <div className="grid grid-cols-12 gap-2">
                {descirptionInputs.map(input => {
                    return (
                        <div className={input.cols} key={input.key}>
                            <TextareaSmall register={register} {...input} rows="5" /> 
                        </div>
                        )
                })}
                </div>     
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Teknik Şartname</p>
                <div className="grid grid-cols-12 gap-2">
                    {techSpecInputs.map(input => {
                        return (
                            <div className={input.cols} key={input.key}>
                                <FileSmall register={register} {...input} /> 
                            </div>
                            )
                    })}
                    
                </div>
                <p className="mt-2 pb-0.5 mb-2 border-b border-rose-700 text-rose-700 font-bold">Onay Listesi</p>   
                <div className='hidden lg:grid lg:grid-cols-12 gap-2 mt-2 border-b border-gray-700 text-gray-700 text-sm'>
                    <div className='col-span-1 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>#</span>
                    </div>
                    <div className='col-span-3 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Onay Sırası</span>
                    </div>
                    <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Onaylayacak Yönetici</span>
                    </div>
                    <div className='col-span-4 flex flex-col gap-y-1 items-start'>
                    <span className='text-[12px] line-clamp-1'>Pozisyon</span>
                    </div>
                </div>          
                {approvalData != null ? 
                approvalData.map((user, index) => {
                    return (
                        <div className='lg:grid lg:grid-cols-12 gap-2 mt-2' key={`approval${user.id}`}>
                            <div className='col-span-1'>
                                {index+1}.
                            </div>
                            <div className='col-span-3'>
                                {user.approval_sequence}
                            </div>
                            <div className="col-span-4">
                                {user.user_name}
                            </div>
                            <div className="col-span-4">
                                {user.departments} 
                            </div>
                        </div>
                    )
                })
                : null}
                <div className="w-full flex justify-end mt-5">
                    <ButtonSave type="submit">
                    </ButtonSave>
                </div>          
            </form> 
        }   
        {loading && <Loader />}
        </>
    )
}

export default CreateForm