import TableActions from 'modules/companyModule/pages/procurementProcesses/procurements/TableActions'
import TableLayout from 'elements/tables'
import FormatNumberWithSeperator from 'utils/FormatNumberWithSeperator'
import { TableStatus } from 'modules/companyModule/pages/procurementProcesses/commonParts/Status'
import LinesCell from 'modules/companyModule/pages/procurementProcesses/commonParts/LinesCell'

const Table = ({data, actions, progressPending, pagination, pageMeta}) => {

    const BestProposalCell = ({proposals, currency}) => {
        let activeProposals = proposals.filter(proposal => {
            return proposal.is_revised === false && proposal.is_cancelled === false
        })
        if(activeProposals.length > 0) {
            return <span className="font-bold">{FormatNumberWithSeperator(Math.min(...activeProposals.map(p => p.total_price))) + ' ' + currency}</span>
        }
        return 'Teklif Yok'
    }

    const ActiveProposalsCountCell = ({proposals}) => {
        let activeProposals = proposals.filter(proposal => {
            return proposal.is_revised === false && proposal.is_cancelled === false
        })
        if(activeProposals.length > 0) {
            return activeProposals.length
        }
        return 'Teklif Yok'
    }
    
    const columns = [
        {
            name: 'Talep No',
            selector: (row) => {return row.procurement_no},
            headerMobileHidden: false,
        },
        {
            name: 'Başlık',
            selector: (row) => {return row.title},
            headerMobileHidden: false,
        },
        {
            name: 'Sektör',
            selector: (row) => {return row.business_area.name},
            headerMobileHidden: false,
        },
        {
            name: 'Durum',
            selector: (row) => {return <TableStatus status={row.status} approvals={row.approvals} />},
            headerMobileHidden: false,
        },
        {
            name: 'Ürün Sayısı',
            selector: (row) => {return <LinesCell lines={row.lines} />},
            headerMobileHidden: false,
        },
        {
            name: 'Gelen Teklif Sayısı',
            selector: (row) => {return <ActiveProposalsCountCell proposals={row.proposals} />},
            headerMobileHidden: false,
        },
        // {
        //     name: <span className="lg:text-yellow-300">EN İYİ TEKLİF</span>,
        //     selector: (row) => {return <BestProposalCell proposals={row.proposals} currency={row.currency.code} />},
        //     headerMobileHidden: false,
        // },
        {
            name: 'İşlemler',
            selector: (row) => {return <TableActions rowId={row.id} actions={actions} data={row}/>},
            headerMobileHidden: true,
        },
    ];

    const mobileHeader = (row) => {return `${row.title} (${row.procurement_no})`}

    return (
        <>
        <TableLayout columns={columns} data={data} progressPending={progressPending} pagination={pagination} pageMeta={pageMeta} mobileHeader={mobileHeader} />
        </>
    );
};

export default Table;
