import { useDispatch, useSelector } from 'react-redux'
import FormDrawer from 'elements/drawers/Form'
import { setIsOpenCompanyUserDepartmentEdit } from 'stores/DrawersSlice' 
import EditDepartmentForm from 'modules/companyModule/pages/companies/settings/users/EditDepartmentForm'

function EditDepartment({user, update, setUpdate, roles, departments, positions}) {
    
    const dispatch = useDispatch()
    const isOpenEdit = useSelector(state => state.drawers.isOpenCompanyUserDepartmentEdit)
    const setIsOpenEdit = () => dispatch(setIsOpenCompanyUserDepartmentEdit())
    
    return(
        <FormDrawer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} title="Kullanıcı Departman Bilgilerini Düzenle">
            <EditDepartmentForm 
                user={user} 
                update={update} 
                setUpdate={setUpdate} 
                roles={roles} 
                departments={departments}
                positions={positions}
            />
        </FormDrawer> 
    )
}

export default EditDepartment